import {EditorInfo, EntityGrid, EntityGridItem, InlineButton, Plate} from '@tehzor/ui-components';
import {PresaleCheckDocuments} from '../../components/PresaleCheckDocuments';
import {PresaleCheckDescriptionMobile} from '../PresaleCheckDescriptionMobile';
import {MobileEntityDocs} from '@src/components/EntityDocs';
import {useTranslation} from 'react-i18next';
import {IPresaleCheck} from '@tehzor/tools/interfaces/presaleChecks/IPresaleCheck';
import {useEditablePresaleCheckAttachmentsDialog} from '@/features/PresaleCheck';
import {usePresaleChecksPermissions} from '@/entities/PresaleCheck/model/hooks/usePresaleChecksPermissions';
import {ExpandableMobileInfo} from '@src/components/ExpandableMobileInfo';

interface IPresaleCheckMobileInfoProps {
	objectId: string;
	presaleCheck: IPresaleCheck;
}

const attachmentsIcon = <i className="tz-attach-20" />;
const editIcon = <i className="tz-edit-16" />;
const modifiedIcon = <i className="tz-modified-20" />;
const createdIcon = <i className="tz-created-20" />;

export const PresaleCheckInfoMobile = ({objectId, presaleCheck}: IPresaleCheckMobileInfoProps) => {
	const {t} = useTranslation();

	// Диалог редактирования вложений
	const [editingAttachmentsDialog, openEditAttachmentsDialog] =
		useEditablePresaleCheckAttachmentsDialog(objectId, presaleCheck.id, presaleCheck);
	const permissions = usePresaleChecksPermissions(objectId);
	return (
		<Plate>
			<EntityGrid withBorders>
				<PresaleCheckDescriptionMobile
					objectId={objectId}
					presaleCheck={presaleCheck.id}
					value={presaleCheck.description}
					canEdit={permissions.canEdit}
				/>

				<EntityGridItem
					label={t('presaleChecksPage.info.documents')}
					icon={attachmentsIcon}
					fullRow
					buttons={
						permissions.canEdit ? (
							<InlineButton
								type="accent"
								leftIcon={editIcon}
								onClick={openEditAttachmentsDialog}
							/>
						) : null
					}
				>
					<PresaleCheckDocuments presaleCheck={presaleCheck} />
				</EntityGridItem>
			</EntityGrid>
			<ExpandableMobileInfo>
				<EntityGrid withBorders>
					<EditorInfo
						icon={createdIcon}
						label={t('presaleChecksPage.info.created')}
						date={presaleCheck.createdAt}
						user={presaleCheck.createdBy}
						fullRow
					/>
					{presaleCheck.createdAt !== presaleCheck.modifiedAt && (
						<EditorInfo
							icon={modifiedIcon}
							label={t('presaleChecksPage.info.modified')}
							date={presaleCheck.modifiedAt}
							user={presaleCheck.modifiedBy}
							fullRow
						/>
					)}
					<MobileEntityDocs id={presaleCheck.id} />
				</EntityGrid>
			</ExpandableMobileInfo>
			{permissions.canEdit && editingAttachmentsDialog}
		</Plate>
	);
};
