import {useCallback} from 'react';
import {Outlet} from 'react-router';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import {extractStructuresSchemaPageSettings} from '@/entities/Structures/model/slice/selectors';
import useAppSelector from '@src/core/hooks/useAppSelector';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {IStructuresFiltersState, structuresActions} from '@/entities/Structures';
import {MobileEntitiesFilters} from '@/entities/MobileEntitiesFilters';
import {useTargetObjectIdsHaveStructures} from '@src/core/hooks/queries/objects/hooks';

export const StructuresFiltersRoutingPage = () => {
	const {objectId} = useStrictParams<{objectId: string}>();
	const {schemaStructureTypeId, filters} = useAppSelector(s =>
		extractStructuresSchemaPageSettings(s, objectId)
	);

	const dispatch = useAppDispatch();
	const {changeFilters, clearFilters} = structuresActions;
	const targetObjects = useTargetObjectIdsHaveStructures({
		objectId,
		filter: schemaStructureTypeId ? {type: schemaStructureTypeId} : undefined
	});
	const applyFilters = useCallback(
		(value: IStructuresFiltersState) => {
			if (objectId) {
				dispatch(
					changeFilters({
						objectsIds: [...targetObjects, objectId],
						filters: value
					})
				);
			}
		},
		[objectId, targetObjects]
	);

	const onClear = useCallback(() => {
		dispatch(
			clearFilters({
				objectsIds: [...targetObjects, objectId]
			})
		);
	}, [objectId, targetObjects]);

	return (
		<MobileEntitiesFilters
			objectId={objectId}
			filters={filters}
			onApply={applyFilters}
			onClear={onClear}
			fullClear
		>
			<Outlet />
		</MobileEntitiesFilters>
	);
};
