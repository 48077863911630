import {memo, useCallback, useMemo, useState} from 'react';
import {makeObjectsTreeData} from '@src/utils/makeObjectsTreeData';
import {useUpdateEffect} from 'react-use';
import {makeFilterLabel} from '@src/components/EntitiesFilters/utils/makeFilterLabel';
import {FilterButton, Toggle, TreeSelect} from '@tehzor/ui-components';
import {useEntitiesFiltersCtx} from '../utils/entitiesFiltersCtx';
import SelectSearch, {
	treeFilter
} from '@tehzor/ui-components/src/components/inputs/select/SelectSearch';
import {useObjectsAsArrayByIds, useTargetObjects} from '@src/core/hooks/queries/objects/hooks';
import {useMainAndSubCompaniesAsArray} from '@src/core/hooks/queries/companies/hooks';
import {useTranslation} from 'react-i18next';
import {TranslatedSelectPopup} from '@src/components/TranslatedSelectPopup';

export interface INestedObjectsFilterProps {
	label?: string;
	filterName?: string;
	value?: string[];
	className?: string;
	objectId?: string;
	excludeDescendantsValue?: boolean;
}

export const NestedObjectsFilter = memo((props: INestedObjectsFilterProps) => {
	const {
		label,
		value,
		className,
		filterName = 'objects',
		objectId,
		excludeDescendantsValue
	} = props;
	const {t} = useTranslation();
	const translatedLabel = label ?? t('entitiesFilters.objectsFilter.label');
	const {dispatch} = useEntitiesFiltersCtx();
	const [selectedObjects, setSelectedObjects] = useState(value);
	const [expandedObjects, setExpandedObjects] = useState<string[] | undefined>([]);
	const [search, setSearch] = useState('');
	const [excludeDescendantsObjects, setExcludeDescendantsObjects] =
		useState(excludeDescendantsValue);

	const clearSearch = useCallback(() => setSearch(''), []);
	const {data: nestedObjects} = useTargetObjects(objectId, true);
	const objects = useObjectsAsArrayByIds(nestedObjects);
	const {data} = useMainAndSubCompaniesAsArray();
	const companies = data?.mainCompanies;
	const treeData = useMemo(() => {
		if (!objects) return [];
		return makeObjectsTreeData(objects, companies);
	}, [objects, companies]);

	const {filteredData, expanded} = useMemo(
		() => treeFilter(treeData, 'content', search),
		[search, treeData]
	);

	useUpdateEffect(() => {
		setExpandedObjects(expanded?.map(item => item.id));
	}, [expanded]);

	const handleApply = useCallback(() => {
		if (objectId) {
			dispatch({
				[filterName]: selectedObjects,
				excludeDescendantsObjects: excludeDescendantsObjects
			});
		}

		clearSearch();
	}, [selectedObjects, clearSearch, filterName, excludeDescendantsObjects, dispatch, objectId]);

	const handleExpand = useCallback((v?: string[]) => {
		setExpandedObjects(v);
	}, []);

	const handleClear = useCallback(() => {
		setSelectedObjects(undefined);
		setExcludeDescendantsObjects(undefined);
		clearSearch();
	}, [clearSearch]);

	const handleFullClear = useCallback(() => {
		setSelectedObjects([]);
		setExpandedObjects([]);
		setExcludeDescendantsObjects(false);
		dispatch({
			[filterName]: undefined,
			excludeDescendantsObjects: undefined
		});
		clearSearch();
	}, [clearSearch, filterName, dispatch]);

	const handleToggleChange = () => {
		setExcludeDescendantsObjects(!excludeDescendantsObjects);
		const currentValue = !excludeDescendantsObjects;
		if (objectId) {
			currentValue
				? setSelectedObjects([objectId])
				: setSelectedObjects([...(nestedObjects || [])]);
		}
	};
	const handleCancel = useCallback(() => {
		setSelectedObjects(value);
		setExcludeDescendantsObjects(excludeDescendantsValue);
		clearSearch();
	}, [value, clearSearch, excludeDescendantsValue]);

	const handleSelectAll = useCallback(() => {
		clearSearch();
		if (objects && companies) {
			const allObjects = objects.map(obj => obj.id);
			const allCompanies = companies.map(comp => comp.id);
			setSelectedObjects([...allObjects, ...allCompanies]);
		}
	}, [objects, companies, clearSearch]);

	useUpdateEffect(() => {
		setExpandedObjects([]);
		setSelectedObjects(value);
		setExcludeDescendantsObjects(excludeDescendantsValue);
	}, [value, excludeDescendantsValue]);

	return (
		<>
			<TranslatedSelectPopup
				onCancel={handleCancel}
				onApply={handleApply}
				onClear={handleClear}
				className={className}
				clearButton={!!selectedObjects?.length}
				footer
				count={selectedObjects?.length}
				onSelectAll={handleSelectAll}
				search={
					<SelectSearch
						value={search}
						placeholder={t('selectSearch.placeholder')}
						onChange={setSearch}
						type="popup"
					/>
				}
				trigger={
					<FilterButton
						className="entities-filters__item"
						label={makeFilterLabel(translatedLabel, value, treeData)}
						active={!!value?.length}
						onClear={handleFullClear}
					/>
				}
			>
				<>
					<Toggle
						checked={excludeDescendantsObjects}
						onChange={handleToggleChange}
						className={{
							root: 'entities-filters__toggle-root',
							content: 'entities-filters__toggle-content'
						}}
					>
						{t('entitiesFilters.objectsFilter.exclude')}
					</Toggle>

					<TreeSelect
						data={[...filteredData]}
						multiple
						value={selectedObjects}
						onChange={setSelectedObjects}
						expandedValue={expandedObjects}
						onExpand={handleExpand}
					/>
				</>
			</TranslatedSelectPopup>
		</>
	);
});
