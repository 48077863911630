import * as React from 'react';
import useAsyncFn from 'react-use/lib/useAsyncFn';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {useDeleteLegal} from '@src/core/hooks/mutations/legals/useDeleteLegal';
import {useTranslation} from 'react-i18next';
import {useTranslatedConfirmDialog} from '@src/core/hooks/translations/useTranslatedConfirmDialog';

export function useLegalDelete(legalId?: string): [React.ReactNode, () => void] {
	const {t} = useTranslation();
	const {goBack} = useChangePath();
	const {mutateAsync: deleteLegal} = useDeleteLegal();

	const [deleteDialog, getDeleteConfirmation] = useTranslatedConfirmDialog({
		title: t('useConfirmDialog.legal.deleteTitle'),
		message: t('useConfirmDialog.legal.deleteTitle')
	});

	const [, handleDelete] = useAsyncFn(async () => {
		if ((await getDeleteConfirmation()) && legalId) {
			await deleteLegal({legalId});
			goBack();
		}
	}, [legalId]);

	return [deleteDialog, handleDelete];
}
