import SelectionActions from '../selection/SelectionActions';
import {IProblemEntity} from '@src/interfaces/IProblemEntity';
import {ExportDialog} from '@src/components/ExportDialog';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {useTranslation} from 'react-i18next';
import {usePermissions} from '@src/pages/ProblemsPage/utils/usePermissions';
import {extractProblemsPageSettings} from '@src/store/modules/settings/pages/problems/selectors';
import useToggle from 'react-use/lib/useToggle';
import {memo, useCallback, useMemo} from 'react';
import IExportTemplate, {
	ExportTemplatesDestinationId
} from '@tehzor/tools/interfaces/IExportTemplate';
import {useAppUpdateMenuItem} from '@src/components/AppUpdateData';
import {IconMenu, MenuItem, UploadingLoader} from '@tehzor/ui-components';
import {useExportProblems} from '@src/core/hooks/mutations/problems/useExportProblems';
import {useIsMutating} from '@tanstack/react-query';
import {problemsQueryKeys} from '@src/api/cache/problems/keys';
import {useOnlineManager} from '@src/core/hooks/useOnlineManager';

interface IMobileRightButtonsProps {
	objectId?: string;
	selectedEntities?: IProblemEntity[];
	selectedClearing: () => void;
}

const availableDestinations = [ExportTemplatesDestinationId.PROBLEMS];
const exportIcon = <i className="tz-export-24" />;

export const MobileRightButtons = memo(
	({objectId = 'all', selectedEntities, selectedClearing}: IMobileRightButtonsProps) => {
		const {t} = useTranslation();
		const {filters, sort, selectedRows} = useAppSelector(s =>
			extractProblemsPageSettings(s, objectId)
		);
		const [isOpen, toggleOpen] = useToggle(false);
		const user = useAppSelector(s => s.auth.profile);
		const permissions = usePermissions(user, objectId);
		// Кнопка обновления данных приложения
		const updateData = useAppUpdateMenuItem();
		const {mutateAsync: exportProblems} = useExportProblems();

		const handleExport = useCallback(
			async (template: IExportTemplate, email?: string, createDocument?: boolean) => {
				await exportProblems({
					templateId: template.id,
					objectId,
					createDocument,
					email,
					filters,
					sort,
					selected: selectedRows
				});
			},
			[exportProblems, objectId, filters, sort, selectedRows, toggleOpen]
		);

		const isMutating = useIsMutating({mutationKey: problemsQueryKeys.add()});
		const isOnline = useOnlineManager();

		const showSelectionActions = selectedEntities && selectedEntities.length > 0;
		const showUploadingLoader = !!isMutating && isOnline;

		const items = useMemo(() => {
			const baseItems = [updateData];
			if (permissions.canExport) {
				baseItems.push(
					<MenuItem
						key="export"
						icon={exportIcon}
						onClick={toggleOpen}
					>
						{t('exportButton.label')}
					</MenuItem>
				);
			}
			return baseItems;
		}, [updateData, permissions.canExport, t, toggleOpen]);

		return (
			<div className="problems-page__mobile-right-buttons">
				{showSelectionActions ? (
					<SelectionActions
						selectedEntities={selectedEntities}
						selectedClearing={selectedClearing}
					/>
				) : null}
				{showUploadingLoader && <UploadingLoader />}
				<IconMenu>{items}</IconMenu>
				{permissions.canExport && (
					<ExportDialog
						pageKey="problems"
						destinations={availableDestinations}
						isOpen={isOpen}
						onExport={handleExport}
						onClose={toggleOpen}
					/>
				)}
			</div>
		);
	}
);
MobileRightButtons.displayName = 'MobileRightButtons';
