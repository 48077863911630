import {useQuery, UseQueryOptions} from '@tanstack/react-query';
import IError from '@tehzor/tools/interfaces/IError';
import {IGetChecksResponse} from '@src/api/backend/checks';
import {checksQueryKeys} from '@src/api/cache/checks/keys';
import {
	extractAllLatestChecksAsArray,
	extractLatestCheckId,
	extractLatestChecksAsArray
} from './selectors';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractUserId} from '@src/store/modules/auth/profile/selectors';
import {useCallback} from 'react';
import IListCheck from '@tehzor/tools/interfaces/checks/IListCheck';
import {useLocalChecks} from '@src/core/hooks/queries/checks/hooks';
import {useChecksPermissions} from '@src/core/hooks/permissions/useChecksPermissions';

export const useFetchLatestUserChecks = <T = IGetChecksResponse>(
	select?: (data: IGetChecksResponse) => T,
	options?: UseQueryOptions<IGetChecksResponse, IError, T>
) => {
	const permissions = useChecksPermissions();
	return useQuery<IGetChecksResponse, IError, T>({
		queryKey: checksQueryKeys.latest(),
		meta: {
			error: 'при загрузке последних проверок'
		},
		enabled: permissions.canView,
		select,
		...options
	});
};

export const useExtractAllLatestChecksAsArray = () =>
	useFetchLatestUserChecks(extractAllLatestChecksAsArray);

export const useExtractLatestChecksAsArray = (
	objectId: string,
	spaceId?: string,
	options?: UseQueryOptions<IGetChecksResponse, IError, IListCheck[]>
) => {
	const userId = useAppSelector(extractUserId);
	const selector = useCallback(
		(data: IGetChecksResponse) => extractLatestChecksAsArray(data, userId, objectId, spaceId),
		[userId, objectId, spaceId]
	);
	return useFetchLatestUserChecks(selector, options);
};

export const useExtractLatestCheckId = (
	objectId: string,
	spaceId?: string,
	options?: UseQueryOptions<IGetChecksResponse, IError, string>
) => {
	const userId = useAppSelector(extractUserId);
	const {data: localChecks} = useLocalChecks(objectId);
	const selector = useCallback(
		(data: IGetChecksResponse) =>
			extractLatestCheckId(data, userId, objectId, spaceId, localChecks),
		[userId, objectId, spaceId, localChecks]
	);
	return useFetchLatestUserChecks(selector, options);
};
