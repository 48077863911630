import {Column} from 'react-table';
import {ICustomField} from '@tehzor/tools/interfaces/fields';
import {NameCell} from '../../ui/cells/NameCell/NameCell';
import {CreatedCell} from '../../ui/cells/CreatedCell/CreatedCell';
import {CompanyCell} from '../../ui/cells/CompanyCell/CompanyCell';
import {TypeCell} from '../../ui/cells/TypeCell/TypeCell';
import {ValuesCell} from '../../ui/cells/ValuesCell/ValuesCell';
import {IconCell} from '../../ui/cells/IconCell/IconCell';
import {ModifiedCell} from '../../ui/cells/ModifiedCell/ModifiedCell';
import {DeleteCell} from '../../ui/cells/DeleteCell/DeleteCell';
import {KeyCell} from '../../ui/cells/KeyCell/KeyCell';

export const getDesktopColumns = (): Array<Column<ICustomField>> => [
	{
		id: 'name',
		Header: 'Наименование поля',
		Cell: NameCell
	},
	{
		id: 'icon',
		Header: '',
		Cell: IconCell,
		width: 70
	},
	{
		id: 'key',
		Header: 'Ключ',
		Cell: KeyCell
	},
	{
		id: 'company',
		Header: 'Компания',
		Cell: CompanyCell
	},
	{
		id: 'valueType',
		Header: 'Тип данных',
		Cell: ValuesCell
	},
	{
		id: 'entityType',
		Header: 'Тип сущности',
		Cell: TypeCell
	},
	{
		id: 'createdAt',
		Header: 'Добавлено',
		Cell: CreatedCell
	},
	{
		id: 'modifiedAt',
		Header: 'Изменено',
		Cell: ModifiedCell
	},
	{
		id: 'delete',
		Cell: DeleteCell,
		Header: '',
		width: 70,
		disableResizing: true,
		isNonClickable: true
	}
];
