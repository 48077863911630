import {IconMenu, MenuItem} from '@tehzor/ui-components';
import {useAsyncFn} from 'react-use';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {ISpace} from '@tehzor/tools/interfaces/spaces/ISpace';
import {useDeleteSpace} from '@src/core/hooks/mutations/spaces/useDeleteSpace';
import {useEditableSpaceDialog} from '@src/components/EditableSpaceDialog/hooks/useEditableSpaceDialog';
import {useAppUpdateMenuItem} from '@src/components/AppUpdateData';
import {useTranslation} from 'react-i18next';
import {useTranslatedConfirmDialog} from '@src/core/hooks/translations/useTranslatedConfirmDialog';

interface IMobileMenuProps {
	space: ISpace;
	canDeleteSpace?: boolean;
	canEditSpace?: boolean;
}

const editIcon = <i className="tz-edit" />;
const deleteIcon = <i className="tz-delete" />;

const MobileMenu = (props: IMobileMenuProps) => {
	const {space, canDeleteSpace, canEditSpace} = props;
	const {goBack} = useChangePath();
	const {t} = useTranslation();

	const {mutateAsync: deleteSpace} = useDeleteSpace();

	const [deleteDialog, getDeleteConfirmation] = useTranslatedConfirmDialog({
		title: t('spacePage.actionsMenu.deleteDialog.title'),
		message: t('spacePage.actionsMenu.deleteDialog.message', {number: space.name})
	});

	const [, handleDeleteClick] = useAsyncFn(async () => {
		if (await getDeleteConfirmation()) {
			await deleteSpace({objectId: space.objectId, spaceId: space.id});
			goBack();
		}
	}, [space.objectId, space.id]);

	// Диалог редактирования помещения
	const [editDialog, openEditDialog] = useEditableSpaceDialog(space.objectId, space.id);
	// Кнопка обновления данных приложения
	const updateData = useAppUpdateMenuItem();

	const items = [];

	items.push(updateData);

	if (canEditSpace) {
		items.push(
			<MenuItem
				key="edit"
				icon={editIcon}
				onClick={openEditDialog}
			>
				{t('spacePage.actionsMenu.editButton.text')}
			</MenuItem>
		);
	}
	if (canDeleteSpace) {
		items.push(
			<MenuItem
				key="delete"
				icon={deleteIcon}
				onClick={handleDeleteClick}
			>
				{t('spacePage.actionsMenu.deleteButton.text')}
			</MenuItem>
		);
	}

	return items.length ? (
		<>
			<IconMenu>{items}</IconMenu>

			{canDeleteSpace && deleteDialog}
			{canEditSpace && editDialog}
		</>
	) : null;
};

export default MobileMenu;
