import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {structuresActions} from '@/entities/Structures';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {useStructuresSchemaTypes} from '@src/core/hooks/queries/structureTypes/hooks';
import {useEffect} from 'react';
import {extractStructuresSchemaPageSettings} from '@/entities/Structures/model/slice/selectors';

export const useActiveSchemaStructuresType = (
	objectId: string,
	targetObjects: string[],
	isSingleSchema: boolean
) => {
	const dispatch = useAppDispatch();
	const {changeSchemaStructureType} = structuresActions;
	const {schemaStructureTypeId} = useAppSelector(state =>
		extractStructuresSchemaPageSettings(state, objectId)
	);
	const schemaStructureTypeIds = useAppSelector(state =>
		targetObjects.map(id => {
			const result = extractStructuresSchemaPageSettings(state, id);
			return result.schemaStructureTypeId;
		})
	);

	const {data: schemaStructuresTypes} = useStructuresSchemaTypes(objectId, targetObjects);
	const areAllStructuresTypesSameAsParent = schemaStructureTypeIds.every(
		id => id === schemaStructureTypeId
	);

	const isSchemaStructureInvalid =
		!schemaStructureTypeId ||
		!schemaStructuresTypes?.some(({id}) => id === schemaStructureTypeId) ||
		!areAllStructuresTypesSameAsParent;
	useEffect(() => {
		if (!schemaStructuresTypes) return;
		if (isSchemaStructureInvalid) {
			if (!schemaStructuresTypes[0]) {
				return;
			}
			dispatch(
				changeSchemaStructureType({
					structureTypeId: schemaStructuresTypes[0].id,
					objectIds: targetObjects
				})
			);
		}
	}, [
		objectId,
		dispatch,
		schemaStructuresTypes,
		schemaStructureTypeId,
		changeSchemaStructureType,
		isSingleSchema,
		isSchemaStructureInvalid,
		targetObjects
	]);
};
