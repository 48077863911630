import IPermission from '@tehzor/tools/interfaces/IPermission';
import IProblemReply from '@tehzor/tools/interfaces/IProblemReply';
import {IUserRole, UserRoleScopes} from '@tehzor/tools/interfaces/IUser';
import ICheck from '@tehzor/tools/interfaces/checks/ICheck';
import IInspection from '@tehzor/tools/interfaces/inspections/IInspection';
import {ILinkedProblem} from '@tehzor/tools/interfaces/problems/ILinkedProblem';
import {IBriefUser} from '@tehzor/tools/interfaces/users/IBriefUser';
import {WorkAcceptanceStatusIds} from '@tehzor/tools/interfaces/workAcceptances/IWorkAcceptanceStatus';
import {findPermission} from '@tehzor/tools/utils/findPermission';

/**
 * Проверяет типовые ограничения checksCreatedByUser, checksProblemsHavePerformerUser, checksProblemsHaveInspectorUser
 *
 * @param userId id пользователя
 * @param userRoles роли пользователя
 * @param permissionId id полномочия
 * @param objectId id объекта
 * @param createdBy автор проверки
 * @param problems список нарушений проверки
 */
export const testCheckRestrictions = (
	userId: string,
	userRoles: IUserRole[],
	permissionId: string,
	objectId: string,
	createdBy?: ICheck['createdBy'],
	problems?: ILinkedProblem[]
) => {
	const p = findPermission(userRoles, permissionId, UserRoleScopes.OBJECT, objectId);
	if (!p) {
		return false;
	}
	if (!p.restrictions) {
		return true;
	}

	return (
		(!!p.restrictions.checksCreatedByUser && createdBy && userId === createdBy.id) ||
		(!!p.restrictions.checksProblemsHavePerformerUser &&
			problems &&
			problems.some(item => item.performers && item.performers.includes(userId))) ||
		(!!p.restrictions.checksProblemsHaveInspectorUser &&
			problems &&
			problems.some(item => item.inspectors && item.inspectors.includes(userId)))
	);
};

/**
 * Проверяет наличие вложенных ограничений
 *
 * @param userRoles роли пользователя
 * @param permissionId id полномочия
 * @param objectId id объекта
 */
export const testCheckRestrictionsList = (
	userRoles: IUserRole[],
	permissionId: string,
	objectId: string
) => {
	const p = findPermission(userRoles, permissionId, UserRoleScopes.OBJECT, objectId);
	if (p?.restrictions) {
		return {...(p.restrictions as {[key: string]: boolean})};
	}
	return undefined;
};

/**
 * Проверяет наличие вложенных ограничений для приемки работ
 *
 * @param userRoles роли пользователя
 * @param permissionId id полномочия
 * @param objectId id объекта
 */
export const testWorkAcceptanceRestrictionsList = (
	userRoles: IUserRole[],
	permissionId: string,
	objectId: string
) => {
	const p = findPermission(userRoles, permissionId, UserRoleScopes.OBJECT, objectId);
	if (p?.restrictions) {
		return {
			...(p.restrictions as {[key: string]: boolean} & {
				availableStatuses: WorkAcceptanceStatusIds[];
			})
		};
	}
	return undefined;
};

/**
 * Проверяет типовые ограничения work-acceptances-created-by-user, work-acceptances-have-acceptor-user
 *
 * @param userId id пользователя
 * @param userRoles роли пользователя
 * @param permissionId id полномочия
 * @param objectId id объекта
 * @param createdBy автор приёмки работ
 * @param acceptors проверяющие приёмки работ
 * @param acceptorsActiveGroupLeader лидер активной группы ответственных
 * @param watchers наблюдатели приёмки работ
 * @param submitters передающие приёмки работ
 */
export const testWorkAcceptanceRestrictions = (
	userId: string,
	userRoles: IUserRole[],
	permissionId: string,
	objectId: string,
	createdBy?: ILinkedProblem['createdBy'],
	acceptors?: string[],
	acceptorsActiveGroupLeader?: string,
	watchers?: string[],
	submitters?: string[],
) => {
	const p = findPermission(userRoles, permissionId, UserRoleScopes.OBJECT, objectId);
	if (!p) {
		return false;
	}
	if (!p.restrictions) {
		return true;
	}
	return (
		(!!p.restrictions['work-acceptances-created-by-user'] &&
			createdBy &&
			userId === createdBy) ||
		(!!p.restrictions['work-acceptances-have-acceptor-user'] &&
			acceptors &&
			acceptors.includes(userId)) ||
		(!!p.restrictions['work-acceptances-have-leader-in-active-group'] &&
			!!acceptorsActiveGroupLeader &&
			userId === acceptorsActiveGroupLeader) ||
		(!!p.restrictions['work-acceptances-have-watcher-user'] &&
			watchers &&
			watchers.includes(userId)) ||
		(!!p.restrictions['work-acceptances-have-submitter-user'] &&
			submitters &&
			submitters.includes(userId))
	);
};

/**
 * Проверяет типовые ограничения internalAcceptancesCreatedByUser, internalAcceptancesProblemsHavePerformerUser, internalAcceptancesProblemsHaveInspectorUser
 *
 * @param userId id пользователя
 * @param userRoles роли пользователя
 * @param permissionId id полномочия
 * @param objectId id объекта
 * @param createdBy автор
 * @param problems список нарушений
 */
export const testInternalAcceptanceRestrictions = (
	userId: string,
	userRoles: IUserRole[],
	permissionId: string,
	objectId: string,
	createdBy?: ICheck['createdBy'],
	problems?: ILinkedProblem[]
) => {
	const p = findPermission(userRoles, permissionId, UserRoleScopes.OBJECT, objectId);
	if (!p) {
		return false;
	}
	if (!p.restrictions) {
		return true;
	}
	return (
		(!!p.restrictions.internalAcceptancesCreatedByUser &&
			createdBy &&
			userId === createdBy.id) ||
		(!!p.restrictions.internalAcceptancesProblemsHavePerformerUser &&
			problems &&
			problems.some(item => item.performers && item.performers.includes(userId))) ||
		(!!p.restrictions.internalAcceptancesProblemsHaveInspectorUser &&
			problems &&
			problems.some(item => item.inspectors && item.inspectors.includes(userId)))
	);
};

/**
 * Проверяет наличие вложенных ограничений
 *
 * @param userRoles роли пользователя
 * @param permissionId id полномочия
 * @param objectId id объекта
 */
export const testInternalAcceptanceRestrictionsList = (
	userRoles: IUserRole[],
	permissionId: string,
	objectId: string
) => {
	const p = findPermission(userRoles, permissionId, UserRoleScopes.OBJECT, objectId);
	if (p?.restrictions) {
		return {...(p.restrictions as {[key: string]: boolean})};
	}
	return undefined;
};

/**
 * Проверяет типовые ограничения нарушений
 *
 * @param userId id пользователя
 * @param userRoles роли пользователя
 * @param permissionId id полномочия
 * @param objectId id объекта
 * @param problemCreatedBy автор нарушения
 * @param performers исполнители
 * @param performersActiveGroupLeader лидер активной группы исполнителей
 * @param inspectors проверяющие
 * @param inspectorsActiveGroupLeader лидер активной группы проверяющих
 * @param watchers наблюдатели
 */
export const testProblemRestrictions = (
	userId: string,
	userRoles: IUserRole[],
	permissionId: string,
	objectId?: string,
	problemCreatedBy?: ILinkedProblem['createdBy'],
	performers?: string[],
	performersActiveGroupLeader?: string,
	inspectors?: string[],
	inspectorsActiveGroupLeader?: string,
	watchers?: string[]
): boolean => {
	const p = findPermission(userRoles, permissionId, UserRoleScopes.OBJECT, objectId);
	if (!p) {
		return false;
	}
	if (!p.restrictions) {
		return true;
	}

	return !!(
		(!!p.restrictions.problemsCreatedByUser &&
			problemCreatedBy &&
			userId === problemCreatedBy) ||
		(!!p.restrictions.problemsHavePerformerUser && performers && performers.includes(userId)) ||
		(!!p.restrictions.problemsHaveLeaderInPerformersActiveGroup &&
			performersActiveGroupLeader &&
			performersActiveGroupLeader === userId) ||
		(!!p.restrictions.problemsHaveInspectorUser && inspectors && inspectors.includes(userId)) ||
		(!!p.restrictions.problemsHaveLeaderInInspectorsActiveGroup &&
			inspectorsActiveGroupLeader &&
			inspectorsActiveGroupLeader === userId) ||
		(!!p.restrictions.problemsHaveWatcherUser && watchers && watchers.includes(userId))
	);
};

/**
 * Проверяет типовые ограничения createdByUser, haveResponsibleUser
 *
 * @param userId id пользователя
 * @param userRoles роли пользователя
 * @param permissionId id полномочия
 * @param objectId id объекта
 * @param createdBy автор нарушения
 * @param respUsers ответственные за нарушение
 */
export const testEntityRestrictions = (
	userId: string,
	userRoles: IUserRole[],
	permissionId: string,
	objectId: string,
	createdBy?: IBriefUser,
	performers?: string[],
	inspectors?: string[],
	watchers?: string[]
) => {
	const p = findPermission(userRoles, permissionId, UserRoleScopes.OBJECT, objectId);
	if (p) {
		return !p.restrictions
			? true
			: (!!p.restrictions.createdByUser && createdBy && userId === createdBy.id) ||
					(!!p.restrictions.havePerformerUser &&
						performers &&
						performers.includes(userId)) ||
					(!!p.restrictions.haveInspectorUser &&
						inspectors &&
						inspectors.includes(userId)) ||
					(!!p.restrictions.haveWatcherUser && watchers && watchers.includes(userId));
	}
	return false;
};

const isFieldDisabled = (
	acc: Record<string, boolean>,
	el: string,
	permission: IPermission,
	userId: string,
	problemCreatedBy?: ILinkedProblem['createdBy'],
	problemPerformers?: string[],
	performersActiveGroupLeader?: string,
	problemInspectors?: string[],
	inspectorsActiveGroupLeader?: string,
	problemWatchers?: string[],
	saving?: boolean,
	creating?: boolean
): Record<string, boolean> => {
	acc[el] = saving
		? false
		: permission.fields &&
		  permission.fields[el] &&
		  Object.values(permission.fields[el]).includes(true) &&
		  !creating
		? permission.fields[el].allProblemsDisabled
			? false
			: (!!permission.fields[el].problemsCreatedByUser &&
					!!problemCreatedBy &&
					userId === problemCreatedBy) ||
			  (!!permission.fields[el].problemsHaveWatcherUser &&
					problemWatchers &&
					problemWatchers.includes(userId)) ||
			  (!!permission.fields[el].problemsHavePerformerUser &&
					!!problemPerformers &&
					problemPerformers.includes(userId)) ||
			  (!!permission.fields[el].problemsHaveLeaderInPerformersActiveGroup &&
					performersActiveGroupLeader === userId) ||
			  (!!permission.fields[el].problemsHaveInspectorUser &&
					problemInspectors &&
					problemInspectors.includes(userId)) ||
			  (!!permission.fields[el].problemsHaveLeaderInInspectorsActiveGroup &&
					inspectorsActiveGroupLeader === userId)
		: true;

	return acc;
};

export const testProblemFieldsRestrictions = (
	userId: string,
	userRoles: IUserRole[],
	permissionId: string,
	objectId: string,
	problemCreatedBy?: ILinkedProblem['createdBy'],
	problemPerformers?: string[],
	performersActiveGroupLeader?: string,
	problemInspectors?: string[],
	inspectorsActiveGroupLeader?: string,
	problemWatchers?: string[],
	saving?: boolean,
	creating?: boolean
): Record<string, boolean> | undefined => {
	const permission = findPermission(userRoles, permissionId, UserRoleScopes.OBJECT, objectId);

	if (!permission) {
		return undefined;
	}

	const fields: string[] = [
		'description',
		'plannedFixDate',
		'critical',
		'categoryId',
		'reason',
		'location',
		'floor',
		'performers',
		'inspectors',
		'prescription',
		'attachments',
		'problemTags',
		'contractId',
		'documentsCount'
	];

	return fields.reduce(
		(acc: Record<string, boolean>, el: string) =>
			isFieldDisabled(
				acc,
				el,
				permission,
				userId,
				problemCreatedBy,
				problemPerformers,
				performersActiveGroupLeader,
				problemInspectors,
				inspectorsActiveGroupLeader,
				problemWatchers,
				saving,
				creating
			),
		{} as Record<string, boolean>
	);
};

/**
 * Проверяет типовые ограничения problemCommentsCreatedByUser у комментария
 *
 * @param userId id пользователя
 * @param userRoles роли пользователя
 * @param permissionId id полномочия
 * @param objectId id объекта
 * @param createdBy автор комментария
 */
export const testProblemCommentRestrictions = (
	userId: string,
	userRoles: IUserRole[],
	permissionId: string,
	objectId: string,
	createdBy?: IBriefUser
) => {
	const p = findPermission(userRoles, permissionId, UserRoleScopes.OBJECT, objectId);
	if (!p) {
		return false;
	}
	if (!p.restrictions) {
		return true;
	}

	return !!p.restrictions.problemCommentsCreatedByUser && createdBy && userId === createdBy.id;
};

/**
 * Проверяет типовые ограничения у комментария
 *
 * @param userId id пользователя
 * @param userRoles роли пользователя
 * @param permissionId id полномочия
 * @param objectId id объекта
 * @param createdBy автор комментария
 */
export const testCommentRestrictions = (
	userId: string,
	userRoles: IUserRole[],
	permissionId: string,
	objectId: string,
	createdBy?: IBriefUser
) => {
	const p = findPermission(userRoles, permissionId, UserRoleScopes.OBJECT, objectId);
	if (p) {
		return !p.restrictions
			? true
			: !!p.restrictions.commentsCreatedByUser && createdBy && userId === createdBy.id;
	}
	return false;
};

/**
 * Проверяет типовые ограничения reportCreatedBy, problemCreatedBy, replyCreatedBy
 *
 * @param userId id пользователя
 * @param userRoles роли пользователя
 * @param permissionId id полномочия
 * @param objectId id объекта
 * @param problemCreatedBy автор нарушения
 * @param replyCreatedBy автор ответа
 */
export const testProblemReplyRestrictions = (
	userId: string,
	userRoles: IUserRole[],
	permissionId: string,
	objectId: string,
	problemCreatedBy?: ILinkedProblem['createdBy'],
	replyCreatedBy?: IProblemReply['createdBy']
) => {
	const p = findPermission(userRoles, permissionId, UserRoleScopes.OBJECT, objectId);
	if (!p) {
		return false;
	}
	if (!p.restrictions) {
		return true;
	}

	return (
		(!!p.restrictions.problemsCreatedByUser &&
			problemCreatedBy &&
			userId === problemCreatedBy) ||
		(!!p.restrictions.problemsRepliesCreatedByUser &&
			replyCreatedBy &&
			userId === replyCreatedBy.id)
	);
};

/**
 * Проверяет типовые ограничения inspectionsCreatedByUser
 *
 * @param userId id пользователя
 * @param userRoles роли пользователя
 * @param permissionId id полномочия
 * @param objectId id объекта
 * @param inspectionCreatedBy автор осмотра
 */
export const testInspectionRestrictions = (
	userId: string,
	userRoles: IUserRole[],
	permissionId: string,
	objectId: string,
	inspectionCreatedBy?: IInspection['createdBy'],
	performers?: string[],
	performersActiveGroupLeader?: string,
	watchers?: string[]
) => {
	const p = findPermission(userRoles, permissionId, UserRoleScopes.OBJECT, objectId);
	if (!p) {
		return false;
	}
	if (!p.restrictions) {
		return true;
	}

	return (
		(!!p.restrictions.inspectionsCreatedByUser &&
			inspectionCreatedBy &&
			userId === inspectionCreatedBy.id) ||
		(!!p.restrictions.inspectionsHavePerformerUser &&
			performers &&
			performers.includes(userId)) ||
		(!!p.restrictions.inspectionsHaveLeaderInPerformersActiveGroup &&
			performersActiveGroupLeader &&
			performersActiveGroupLeader === userId) ||
		(!!p.restrictions.inspectionsHaveWatcherUser && watchers && watchers.includes(userId))
	);
};

/**
 * Проверяет типовое ограничение taskCreatedByUser
 *
 * @param userId id пользователя
 * @param userRoles роли пользователя
 * @param permissionId id полномочия
 * @param objectId id объекта
 * @param taskCreatedBy автор задачи
 */

interface ITestTaskRestrictionsProps {
	userId: string;
	userRoles: IUserRole[];
	permissionId: string;
	objectId?: string;
	createdBy?: string;
	respUsers?: string[];
	watchers?: string[];
	activeGroupLeader?: string;
}
export const testTaskRestrictions = ({
	userId,
	userRoles,
	permissionId,
	objectId,
	createdBy,
	respUsers,
	watchers,
	activeGroupLeader
}: ITestTaskRestrictionsProps) => {
	const permission = findPermission(userRoles, permissionId, UserRoleScopes.OBJECT, objectId);
	if (!permission) {
		return false;
	}

	if (!permission.restrictions) {
		return true;
	}

	const {restrictions} = permission;

	if (restrictions.tasksCreatedByUser && createdBy && createdBy === userId) {
		return true;
	}
	if (restrictions.tasksHaveResponsibleUser && respUsers?.includes(userId)) {
		return true;
	}
	if (restrictions.tasksHaveWatcherUser && watchers?.includes(userId)) {
		return true;
	}
	if (
		restrictions.tasksHaveLeaderInActiveGroup &&
		activeGroupLeader &&
		activeGroupLeader === userId
	) {
		return true;
	}
	return false;
};
