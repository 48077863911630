import {memo} from 'react';
import {IconButton} from '@tehzor/ui-components';
import {CellProps} from 'react-table';
import {useAsyncFn} from 'react-use';
import {IPreparedLegal} from '@src/pages/LegalsPage/interfaces/IPreparedLegal';
import {useDeleteLegal} from '@src/core/hooks/mutations/legals/useDeleteLegal';
import {useTranslatedConfirmDialog} from '@src/core/hooks/translations/useTranslatedConfirmDialog';
import {useTranslation} from 'react-i18next';

const deleteIcon = <i className="tz-delete" />;

export const DeleteCell = memo(({row}: CellProps<IPreparedLegal>) => {
	const {t} = useTranslation();
	const canDelete = row.original.canDelete;
	const {mutateAsync: deleteLegal} = useDeleteLegal();

	const [deleteDialog, getDeleteConfirmation] = useTranslatedConfirmDialog({
		title: t('useConfirmDialog.legal.deleteTitle'),
		message: t('useConfirmDialog.legal.deleteTitle')
	});

	const [, handleDelete] = useAsyncFn(async () => {
		if (await getDeleteConfirmation()) {
			await deleteLegal({legalId: row.original.id});
		}
	}, [row.original]);

	return canDelete ? (
		<>
			<IconButton
				className="legals__cell-delete"
				type="inline-red-accent"
				onClick={handleDelete}
			>
				{deleteIcon}
			</IconButton>
			{deleteDialog}
		</>
	) : null;
});
