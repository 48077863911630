import {StatusSelect} from '@tehzor/ui-components';
import {IListProblem} from '@tehzor/tools/interfaces/problems/IListProblem';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {useProblemStatusPermissions} from '@src/core/hooks/permissions/useProblemStatusPermissions';
import {ProblemStatusId} from '@tehzor/tools/interfaces/problems/IProblemStatus';
import {editProblemStatus} from '@src/store/modules/pages/problem/actions';
import ClickPreventWrap from '../ClickPreventWrap';
import {queryClient} from '@src/api/QueryClient';
import {objectsQueryKeys} from '@src/api/cache/objects/keys';
import {useProblemStatuses} from '@src/core/hooks/queries/problemStatuses/hooks';
import {useTranslatedDictionary} from '@src/core/hooks/translations/useTranslatedDictionary';
import {ProblemReopenedTimes} from '@src/components/ProblemReopenedTimes';
import './ProblemStatusCell.less';
import {useCallback} from 'react';

interface IProblemStatusProps {
	problem: Omit<
		IListProblem,
		'createdBy' | 'modifiedBy' | 'activeGroupLeader' | 'initialGroupLeader'
	>;
}
const style = {maxWidth: '100%'};
export const ProblemStatus = ({problem}: IProblemStatusProps) => {
	const {id, objectId, status, reopenedTimes} = problem;
	const {data: statuses} = useProblemStatuses();
	const translatedProblemStatuses = useTranslatedDictionary('problemStatuses', statuses);
	const dispatch = useAppDispatch();
	const permissions = useProblemStatusPermissions(objectId, problem);

	const handleChange = useCallback(
		async (v: ProblemStatusId) => {
			await dispatch(editProblemStatus(objectId, id, v));
			await queryClient.refetchQueries({queryKey: [...objectsQueryKeys.stats(), [objectId]]});
		},
		[objectId, id]
	);

	return (
		<ClickPreventWrap>
			<div className="problem-status-cell">
				<StatusSelect
					style={style}
					statuses={translatedProblemStatuses}
					availableStatuses={permissions.availableStatuses}
					disabled={!permissions.canEditStatus}
					value={status}
					onChange={handleChange}
				/>
				{!!reopenedTimes && <ProblemReopenedTimes reopenedTimes={reopenedTimes} />}
			</div>
		</ClickPreventWrap>
	);
};
