import CreatedByFilterPage from '@src/pages/filters/CreatedByFilterPage';
import CreationDateFilterPage from '@src/pages/filters/CreationDateFilterPage';
import {ModifiedDateFilterPage} from '@src/pages/filters/ModifiedDateFilterPage';
import {ObjectsFilterPage} from '@src/pages/filters/ObjectsFilterPage';
import {OwnerAcceptancesFiltersPage} from '@src/pages/filters/OwnerAcceptancesFiltersPage/OwnerAcceptancesFiltersPage';
import ResolutionsOwnersFilterPage from '@src/pages/filters/ResolutionsOwnersFilterPage';
import {SpaceOwnersFilterPage} from '@src/pages/filters/SpaceOwnersFilterPage';
import StatusesOwnersFilterPage from '@src/pages/filters/StatusesOwnersFilterPage';

export const ownerAcceptancesFilterRoutes = [
	{
		path: 'objects',
		element: <ObjectsFilterPage />
	},
	{
		path: 'spaceowners',
		element: <SpaceOwnersFilterPage />
	},
	{
		path: 'statuses',
		element: <StatusesOwnersFilterPage />
	},
	{
		path: 'resolutions',
		element: <ResolutionsOwnersFilterPage />
	},
	{
		path: 'createdBy',
		element: <CreatedByFilterPage />
	},
	{
		path: 'creationdate',
		element: <CreationDateFilterPage />
	},
	{
		path: 'modifieddate',
		element: <ModifiedDateFilterPage />
	},
	{
		path: '',
		element: <OwnerAcceptancesFiltersPage />
	}
];
