import {queryClient} from '@src/api/QueryClient';
import {useMutation} from '@tanstack/react-query';
import {ISavingStructure} from '@tehzor/tools/interfaces/structures/ISavingStructure';
import {makeStructureEditRequest} from '@/shared/api/structure/editStructure';
import {structuresQueryKeys} from '../../../Structures/model/cache/keys/index';
import {addErrorToast, addSuccessToast} from '@tehzor/ui-components';
import {useTranslation} from 'react-i18next';

interface IEditStructureRequestData {
	objectId: string;
	structureId: string;
	fields: ISavingStructure;
}

export const useEditStructure = () => {
	const {t} = useTranslation();

	return useMutation({
		mutationFn: ({objectId, structureId, fields}: IEditStructureRequestData) =>
			makeStructureEditRequest(objectId, structureId, fields),
		mutationKey: structuresQueryKeys.edit(),
		onSuccess: () => {
			addSuccessToast({title: t('toast.editStructure.success.title')});
			void queryClient.invalidateQueries({queryKey: structuresQueryKeys.list()});
			void queryClient.invalidateQueries({queryKey: structuresQueryKeys.one()});
		},
		onError: () => addErrorToast({title: t('toast.editStructure.error.title')})
	});
};
