import {MobileEntityAdding} from './EntityAdding.mobile';
import {useProblemsPermissions} from '@src/core/hooks/permissions/useProblemsPermissions';
import {useInspectionsAddingPermissions} from '@src/core/hooks/permissions/useInspectionsAddingPermissions';
import {useOwnerAcceptancesPermissions} from '@src/core/hooks/permissions/useOwnerAcceptancesPermissions';

interface IMobileActionsProps {
	objectId?: string;
}

export const ActionsMobile = ({objectId}: IMobileActionsProps) => {
	const permissions = useOwnerAcceptancesPermissions(objectId);
	const problemsPermissions = useProblemsPermissions(objectId);
	const inspectionsPermissions = useInspectionsAddingPermissions(objectId);

	return (
		<>
			{objectId !== undefined &&
				permissions.canAdd &&
				(problemsPermissions.canAdd || inspectionsPermissions.canAdd) && (
					<MobileEntityAdding objectId={objectId} />
				)}
		</>
	);
};
