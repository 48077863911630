import {ICustomField, IFieldSetting} from '@tehzor/tools/interfaces/fields';
import {IPreparedField} from '@/features/Fields';
import {getPreparedCustomField} from '@/features/Fields/utils/getPreparedCustomField';

export const prepareCustomFields = (
	fields: ICustomField[],
	fieldsSettings: IFieldSetting[],
	setId: string
): IPreparedField[] =>
	fields.map(field => {
		const fieldSetting = fieldsSettings.find(({fieldId}) => fieldId === field.id);
		return getPreparedCustomField(setId, field, fieldSetting);
	});
