import {useContext} from 'react';
import {CellProps} from 'react-table';
import {IconButton} from '@tehzor/ui-components';
import {useAsyncFn} from 'react-use';
import {IEnrichedProblemTagsSet} from '@tehzor/tools/interfaces/problemTagsSets/IEnrichedProblemTagsSet';
import {useProblemTagsSetPermissions} from '@src/core/hooks/permissions/useProblemTagsSetPermissions';
import {DispatchActionCtx} from '../DispatchActionCtx';
import {useTranslatedConfirmDialog} from '@src/core/hooks/translations/useTranslatedConfirmDialog';
import {useTranslation} from 'react-i18next';

const deleteIcon = <i className="tz-delete" />;

const DeleteCell = ({row}: CellProps<IEnrichedProblemTagsSet>) => {
	const {t} = useTranslation();
	const perms = useProblemTagsSetPermissions();
	const dispatchAction = useContext(DispatchActionCtx);

	const [deleteDialog, getDeleteConfirmation] = useTranslatedConfirmDialog({
		title: t('useConfirmDialog.categorySet.deleteTitle'),
		message: t('useConfirmDialog.categorySet.deleteMessageId', {id: row.original.name})
	});

	const [, handleDelete] = useAsyncFn(async () => {
		if (await getDeleteConfirmation()) {
			dispatchAction({type: 'delete', payload: row.original});
		}
	}, [row.original, dispatchAction]);

	return perms.canDelete && row.original.allObjectsAvailable ? (
		<>
			<IconButton
				type="inline-red-accent"
				onClick={handleDelete}
			>
				{deleteIcon}
			</IconButton>
			{deleteDialog}
		</>
	) : null;
};

export default DeleteCell;
