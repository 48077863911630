import {Dispatch, ReactNode, RefObject, SetStateAction} from 'react';
import AddingInspection, {IAddingInspectionRefProps} from '@src/components/AddingInspection';
import AddingProblem, {IAddingProblemRefProps} from '@src/components/AddingProblem';
import {ISavingInspection} from '@src/interfaces/saving/ISavingInspection';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {IEntitySelectProps} from '@tehzor/ui-components/src/components/containers/EntitySelectContainer/components/EntitySelect/EntitySelect';
import {EntityTypeSelect} from '../../components/EntityTypeSelect';
import styles from '../../components/AddingPresaleCheckDialog/AddingPresaleCheckDialog.module.less';
import {useSpaceLocation} from '../useSpaceLocation/useSpaceLocation';
import {ISavingProblem} from '@src/interfaces/saving/ISavingProblem';
import {useTranslation} from 'react-i18next';
import {ProcessIds} from '@tehzor/tools/interfaces/process/ProcessId';
import {IPresaleCheck} from '@tehzor/tools/interfaces/presaleChecks/IPresaleCheck';
import {IPresaleCheckAddingEntityType} from '../../model/slice/presaleCheckAdding/interfaces';
import {usePresaleCheck} from '@/entities/PresaleCheck';
import {useExtractLatestPresaleChecksAsArray} from '@/entities/PresaleCheck/model/cache/queries/PresaleChecksLatest/hooks/useExtractLatestPresaleChecksAsArray';
import {useLatestPresaleChecks} from '../useLatestPresaleChecks/useLatestPresaleChecks';

interface IUsePresaleCheckMapsProps {
	objectId: string;
	presaleCheckId?: string;
	stage: ObjectStageIds;
	processId: ProcessIds;
	links?: IPresaleCheck['links'];
	availableTypes: IPresaleCheckAddingEntityType[];
	type?: IPresaleCheckAddingEntityType;
	addingEntityRef: RefObject<IAddingProblemRefProps | IAddingInspectionRefProps>;
	problemToCopyId?: string;

	saving: boolean;
	setSaving: Dispatch<SetStateAction<boolean>>;
	selectedPresaleCheckId?: string;
	setSelectedPresaleCheckId: Dispatch<SetStateAction<string | undefined>>;

	defaultProblemData?: ISavingProblem;
	defaultInspectionData?: ISavingInspection;

	onClose: () => void;
}

export const usePresaleCheckMaps = (
	props: IUsePresaleCheckMapsProps
): [
	Record<ObjectStageIds, Record<ProcessIds, ReactNode>>,
	Record<ObjectStageIds, Record<ProcessIds, IEntitySelectProps>>
] => {
	const {
		objectId,
		presaleCheckId,
		stage,
		processId,
		links,
		availableTypes,
		type,
		addingEntityRef,
		problemToCopyId,
		saving,
		setSaving,
		selectedPresaleCheckId,
		setSelectedPresaleCheckId,
		onClose
	} = props;
	const {t} = useTranslation();
	const contentMap = {} as Record<ObjectStageIds, Record<ProcessIds, ReactNode>>;
	const entitiesSelectMapProps = {} as Record<
		ObjectStageIds,
		Record<ProcessIds, IEntitySelectProps>
	>;

	const [defaultProblemData, defaultInspectionData, isLoading] = useSpaceLocation(
		objectId,
		selectedPresaleCheckId,
		props.defaultProblemData,
		props.defaultInspectionData
	);

	const {data: latestPresaleChecks} = useExtractLatestPresaleChecksAsArray(
		objectId,
		links?.spaceId
	);

	useLatestPresaleChecks(objectId, setSelectedPresaleCheckId, selectedPresaleCheckId, links);

	contentMap[stage] = {} as Record<ProcessIds, ReactNode>;
	contentMap[stage][processId] = (
		<>
			<div className={styles.dialogTitle}>
				{type !== undefined && (
					<EntityTypeSelect
						type={type}
						types={availableTypes}
						disabled={saving}
					/>
				)}
			</div>
			{type === 'problem' ? (
				<AddingProblem
					objectId={objectId}
					links={links}
					isLoading={isLoading}
					stage={stage}
					processId={processId}
					defaultData={problemToCopyId ? props.defaultProblemData : defaultProblemData}
					saving={saving}
					onClose={onClose}
					setSaving={setSaving}
					ref={addingEntityRef}
					problemToCopyId={problemToCopyId}
				/>
			) : type === 'inspection' ? (
				<AddingInspection
					objectId={objectId}
					links={links}
					stage={stage}
					processId={processId}
					isLoading={isLoading}
					defaultData={defaultInspectionData}
					saving={saving}
					onClose={onClose}
					setSaving={setSaving}
					ref={addingEntityRef}
				/>
			) : null}
		</>
	);

	const {data: presaleCheck} = usePresaleCheck(presaleCheckId);

	entitiesSelectMapProps[stage] = {} as Record<ProcessIds, IEntitySelectProps>;
	entitiesSelectMapProps[stage][processId] = {
		name: t('addingPresaleCheckDialog.usePresaleCheckMaps.building.name'),
		newName: t('addingPresaleCheckDialog.usePresaleCheckMaps.building.newName'),
		selected: selectedPresaleCheckId,
		onEntityChange: setSelectedPresaleCheckId,
		withoutNew: !!presaleCheck,
		entities: presaleCheck ? [presaleCheck] : latestPresaleChecks,
		localEntities: []
	};
	return [contentMap, entitiesSelectMapProps];
};
