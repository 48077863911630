import {IGetSpacesResponse} from '@src/api/backend/spaces';
import {spacesQueryKeys} from '@src/api/cache/spaces/keys';
import {useQueries, useQuery, UseQueryResult, useSuspenseQuery} from '@tanstack/react-query';
import {useCallback} from 'react';
import {SpaceTypeId} from '@tehzor/tools/interfaces/spaces/ISpaceType';
import {
	extractSpacesAsArray,
	extractSpacesAsArrayWithFilteredByObject,
	extractSpacesData
} from './selectors';
import {IListSpace} from '@tehzor/tools/interfaces/spaces/IListSpace';
import {getCachedSpacesByObject} from '@src/store/persistentStorage/offlineActions/spaces';
import {useObject} from '../objects/hooks';
import {useExtractSpaceTypesAllIds} from '@src/core/hooks/queries/spaceTypes/hooks';
import {useSpaceIndicatorsAsMap} from '../spaceIndicatorsSets/hooks';
import {useSpaceStatuses} from '../spaceStatuses/hooks';
import useAppSelector from '../../useAppSelector';
import {ISpacesFiltersState} from '../../../../store/modules/settings/pages/spaces/reducers/byPage';
import {extractSpacesPageSettings} from '../../../../store/modules/settings/pages/spaces/selectors';

export const useSpacesSchemaQuery = <TResult = IGetSpacesResponse>(
	objectId: string,
	spaceTypeId?: string,
	select?: (data: IGetSpacesResponse) => TResult
) => {
	const params: Record<string, unknown> = {objects: [objectId]};
	if (spaceTypeId) {
		params.types = [spaceTypeId];
	}
	return useSuspenseQuery({
		queryKey: [...spacesQueryKeys.list(), params, undefined, null, 100000],
		initialData: getCachedSpacesByObject(objectId),
		select,
		staleTime: Infinity
	});
};

const useSpacesSchemaQueries = <TResult = IGetSpacesResponse, TCombinedResult = TResult>(
	objects?: string[],
	select?: (data: IGetSpacesResponse) => TResult,
	combine?: (result: Array<UseQueryResult<TResult>>) => TCombinedResult
) =>
	useQueries<IGetSpacesResponse[], TCombinedResult>({
		queries:
			objects?.map(objectId => ({
				queryKey: [
					...spacesQueryKeys.list(),
					{objects: [objectId]},
					undefined,
					null,
					100000
				],
				initialData: getCachedSpacesByObject(objectId),
				select,
				staleTime: Infinity,
				suspense: true
			})) || [],
		combine
	});

export const useSpacesForObjectsAsArray = (objects?: string[]) => {
	const combine = useCallback(
		(results: Array<UseQueryResult<IListSpace[]>>) =>
			results.map(result => result.data).flatMap(data => data),
		[]
	);
	return useSpacesSchemaQueries(objects, extractSpacesAsArray, combine);
};

export const useSpacesForObjectsAsMap = (objects?: string[]) => {
	const combine = useCallback((result: Array<UseQueryResult<Record<string, IListSpace>>>) => {
		const combinedData = {} as Record<string, IListSpace>;
		result.forEach(queryResult => {
			if (queryResult.data) {
				Object.assign(combinedData, queryResult.data);
			}
		});
		return combinedData;
	}, []);
	return useSpacesSchemaQueries(objects, extractSpacesData, combine);
};

export const useSpacesAsArray = (objectId: string) =>
	useSpacesSchemaQuery(objectId, undefined, extractSpacesAsArray);

export const useSpacesAsArrayWithFilteredByObject = (
	objectId: string,
	spaceTypeId: string | undefined
) => {
	const {data: spaceStatuses} = useSpaceStatuses();
	const {data: object} = useObject(objectId);
	const {data: indicators} = useSpaceIndicatorsAsMap(object);
	const extractorCallback = useCallback(
		(response: IGetSpacesResponse) =>
			extractSpacesAsArrayWithFilteredByObject(response, indicators, spaceStatuses),
		[indicators, spaceStatuses]
	);

	return useSpacesSchemaQuery(objectId, spaceTypeId, extractorCallback);
};

export const useSpacesGroupedByTypes = (objects?: string[]) => {
	const {data: spaceTypes} = useExtractSpaceTypesAllIds();
	const spaces = useSpacesForObjectsAsArray(objects);
	const spacesGroupedByTypes = {} as Record<string, Record<string, IListSpace[]>>;
	if (spaceTypes) {
		for (const type of spaceTypes) {
			let haveSpaces = false;

			const spacesGroupedByObjects = objects?.reduce(
				(acc: Record<string, IListSpace[]>, objectId: string) => {
					const filteredSpaces = spaces.filter(
						space =>
							space?.objectId === objectId && space?.type === (type as SpaceTypeId)
					);
					haveSpaces = haveSpaces || !!filteredSpaces.length;
					acc[objectId] = filteredSpaces as IListSpace[];

					return acc;
				},
				{}
			);

			if (haveSpaces && spacesGroupedByObjects) {
				spacesGroupedByTypes[type] = spacesGroupedByObjects;
			}
		}
	}
	return spacesGroupedByTypes;
};

export const useSpacesData = (objectId: string) =>
	useSpacesSchemaQuery(objectId, undefined, extractSpacesData);

export const useSpacesListData = <TResult = IGetSpacesResponse>(
	objectId: string,
	filters?: ISpacesFiltersState,
	select?: (data: IGetSpacesResponse) => TResult
) => {
	const {
		filters: settingsFilters,
		sort,
		pageSize,
		listOffset
	} = useAppSelector(s => extractSpacesPageSettings(s, objectId));
	const allFilters = {...settingsFilters, ...filters};
	return useQuery({
		queryKey: [...spacesQueryKeys.list(), allFilters, sort, listOffset, pageSize],
		initialData: getCachedSpacesByObject(objectId, pageSize, allFilters, listOffset),
		staleTime: Infinity,
		select,
		meta: {
			error: 'при загрузке списка помещений'
		}
	});
};

const defaultSpacesList = {
	byId: {},
	allIds: [],
	offset: 0,
	total: 0,
	limit: 0
};

export const useSpacesList = (objectId: string, filters?: ISpacesFiltersState) => {
	const {data} = useSpacesListData(objectId, filters);
	return data || defaultSpacesList;
};

export const useSpacesListAsArray = (objectId: string, filters?: ISpacesFiltersState) =>
	useSpacesListData(objectId, filters, extractSpacesAsArray);
