import {httpRequests} from '@tehzor/tools/api/httpRequests';
import INormalizedData from '@/shared/types/INormalizedData';
import {IFieldSettingsSet} from '@tehzor/tools/interfaces/fields';

export type IGetListFieldSettingsSetsResponse = INormalizedData<IFieldSettingsSet>;

/**
 * Возвращает список наборов настроек полей
 */
export const requestGetListFieldSettingsSets = async () => {
	const res = await httpRequests.withToken.get<IGetListFieldSettingsSetsResponse>(
		'field-settings-sets'
	);

	return res.data;
};
