import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {addErrorToast, addSuccessToast} from '@tehzor/ui-components';
import {t} from 'i18next';

const request = () => ({type: types.DELETE_REQUEST});

const success = (objectId: string, checkId: string, quietly?: boolean) => {
	if (!quietly) {
		addSuccessToast({title: t('toast.deleteCheck.success.title')});
	}

	return {
		type: types.DELETE_SUCCESS,
		payload: {
			objectId,
			checkId
		}
	};
};

const failure = (error: IError) => {
	addErrorToast({title: t('toast.deleteCheck.error.title')});
	return {
		type: types.DELETE_FAILURE,
		payload: {...error}
	};
};

export const deleteCheckActions = {request, success, failure};
