import {memo} from 'react';
import {CellProps} from 'react-table';
import {format} from 'date-fns';
import {dateTimeCommaSeparatedFormat} from '@tehzor/tools/utils/dateFormats';
import styles from './CreatedCell.module.less';
import {ICustomField} from '@tehzor/tools/interfaces/fields';

export const CreatedCell = memo(({row}: CellProps<ICustomField>) => {
	const {createdAt, createdBy} = row.original;

	return (
		<div className={styles.wrapper}>
			{createdAt && (
				<p className={styles.title}>{format(createdAt, dateTimeCommaSeparatedFormat)}</p>
			)}
			{createdBy && <p className={styles.title}>{createdBy.fullName}</p>}
		</div>
	);
});
