import {Route, Routes} from 'react-router-dom';
import {LoadingPanel} from '@tehzor/ui-components';
import {CheckLists} from '@src/pages/CheckListPage/components/list';
import {useCheckListBreadcrumbs} from '@src/core/hooks/breadcrumbs/useCheckListBreadcrumbs';
import AppBreadcrumbs from '@src/components/AppBreadcrumbs';
import {ListDetailMobile} from './detail/ListDetail/ListDetail.mobile';
import {MobileItemDetail} from './detail/ItemDetail/ItemDetail.mobile';

interface IMobileProps {
	loading: boolean;
}

export const Mobile = ({loading}: IMobileProps) => {
	const breadcrumbs = useCheckListBreadcrumbs();
	return (
		<div className="page-cont check-list-page check-list-page__m-container">
			<AppBreadcrumbs
				className="check-list-page__m-lists__breadcrumbs"
				items={breadcrumbs}
			/>
			<LoadingPanel active={loading}>
				<Routes>
					<Route
						path=":listId"
						element={<ListDetailMobile />}
					/>
					<Route
						path=":listId/items/:itemId"
						element={<MobileItemDetail />}
					/>
					<Route
						path="/"
						element={<CheckLists />}
					/>
				</Routes>
			</LoadingPanel>
		</div>
	);
};
