import {createMutation} from 'react-query-kit';
import {IPresaleCheck} from '@tehzor/tools/interfaces/presaleChecks/IPresaleCheck';
import {presaleChecksQueryKeys} from '../../keys';
import {IAddPresaleCheckParams} from '@/features/PresaleCheck';

export const useAddPresaleCheckMutation = createMutation<IPresaleCheck, IAddPresaleCheckParams>({
	mutationKey: presaleChecksQueryKeys.add(),
	meta: {
		entity: 'presaleCheck',
		type: 'adding'
	}
});
