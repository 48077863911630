import {
	EntitiesTable,
	Pagination,
	PaginationAndSize,
	PaginationPageSize,
	Plate
} from '@tehzor/ui-components';
import {useColumns} from '../model/hooks/useTableColumns';
import useAppSelector from '../../../../../core/hooks/useAppSelector';
import {memo, useCallback, useMemo} from 'react';
import {pageSizes} from '@/shared/constants/pageSizes';
import {useIsTablet} from '@tehzor/ui-components/src/utils/mediaQueries';
import {extractUserRoles} from '@src/store/modules/auth/profile/selectors';
import {useFieldSettingsSetsArray} from '@src/core/hooks/queries/fieldSettingsSets/hooks';
import {usePaginate} from '@/shared/hooks/usePaginate';
import {useChangePage} from '@/shared/hooks/useChangePage';
import {
	convertFieldsSettingsSets,
	extractFieldsSettingsSetsPageSettings,
	fieldsSettingsSetsAction
} from '@/entities/FieldsSettingsSets';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {IPreparedFieldSettingsSet} from '@/shared/types/IPreparedFieldsSettingsSets';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {SelectionRow} from '@/widgets/FieldsSettingsSetsTable/ui/SelectionRow/SelectionRow';
import styles from './FieldsSettingsSetsTable.module.less';

export const FieldsSettingsSetsTable = memo(() => {
	const dispatch = useAppDispatch();
	const {pushPath} = useChangePath();
	const settings = useAppSelector(extractFieldsSettingsSetsPageSettings);
	const {data: fieldsArray} = useFieldSettingsSetsArray();
	const {data: fieldsSetsData, currentPage, pagesCount} = usePaginate(settings, fieldsArray);
	const roles = useAppSelector(extractUserRoles);
	const preparedFieldSettingsSets = useMemo(
		() => convertFieldsSettingsSets(fieldsSetsData, roles),
		[fieldsSetsData, roles]
	);

	const {changeOffset, changePageSize, changeSelectedRows} = fieldsSettingsSetsAction;
	const handleSelectedRowsChange = useCallback(
		(value: string[]) => {
			dispatch(changeSelectedRows(value));
		},
		[dispatch, changeSelectedRows]
	);

	const isTablet = useIsTablet();
	const columns = useColumns(isTablet);
	const {handlePageChange, handlePageSizeChange} = useChangePage({
		settings,
		changeOffsetAction: changeOffset,
		changePageSizeAction: changePageSize
	});

	const handleRowClick = useCallback(
		(item: IPreparedFieldSettingsSet) => {
			pushPath(`/manage/fields/fields-settings-set/${item.id}`);
		},
		[pushPath]
	);

	return (
		<>
			<Plate withoutPadding>
				<EntitiesTable
					columns={columns}
					data={preparedFieldSettingsSets}
					selectable
					selectedRows={settings.selectedRows}
					onRowClick={handleRowClick}
					headVisible={isTablet}
					onSelectedRowsChange={handleSelectedRowsChange}
					renderSelectionRow={props => <SelectionRow {...props} />}
					rowProps={() => ({
						className: styles.deleteCellHover
					})}
					rowWithHover
				/>
			</Plate>
			<PaginationAndSize
				pagination={
					<Pagination
						className={styles.containerPagination}
						pageCount={pagesCount}
						forcePage={currentPage}
						pageRangeDisplayed={3}
						marginPagesDisplayed={1}
						onPageChange={handlePageChange}
					/>
				}
				pageSize={
					<PaginationPageSize
						className={styles.containerPageSize}
						pageSize={settings.pageSize}
						pageSizeOptions={pageSizes}
						onPageSizeChange={handlePageSizeChange}
					/>
				}
			/>
		</>
	);
});
