import {useCallback} from 'react';
import {IPresaleCheck} from '@tehzor/tools/interfaces/presaleChecks/IPresaleCheck';
import {extractLocalSpacePresaleChecks} from '../selectors/extractLocalSpacePresaleChecks';
import {useLocalPresaleChecks} from './useLocalPresaleChecks';

export const useExtractLocalSpacePresaleChecks = (objectId: string, spaceId: string) => {
	const selector = useCallback(
		(data: IPresaleCheck[]) => extractLocalSpacePresaleChecks(data, spaceId),
		[spaceId]
	);
	return useLocalPresaleChecks(objectId, selector);
};
