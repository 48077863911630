import useAppSelector from '@src/core/hooks/useAppSelector';
import {builtInFieldsActions, extractBuiltInFieldsPageSettings} from '@/entities/Fields';
import {useIsTablet} from '@/shared/hooks/dimensions/useIsDesktop/useIsDesktop';
import {useCallback, useMemo} from 'react';
import {getDesktopColumns} from '../model/utils/getDesktopColumns';
import {getMobileColumns} from '../model/utils/getMobileColumns';
import {EntitiesTable, Pagination, PaginationAndSize, Plate} from '@tehzor/ui-components';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import styles from './BuiltInFieldsTable.module.less';
import {TranslatedPaginationPageSize} from '@src/components/TranslatedPaginationPageSize';
import {pageSizes} from '@/shared/constants/pageSizes';
import {usePaginateBuiltInFields} from '@/features/Fields/hooks/usePaginate/usePaginateBuiltInFields';

export const BuiltInFieldTable = () => {
	const dispatch = useAppDispatch();
	const isTablet = useIsTablet();
	const {pageSize, offset} = useAppSelector(s => extractBuiltInFieldsPageSettings(s));
	const {changeOffset, changePageSize} = builtInFieldsActions;
	const {data: paginateBuiltInFields, pagesCount, currentPage} = usePaginateBuiltInFields();

	const columns = useMemo(
		() => (!isTablet ? getMobileColumns() : getDesktopColumns()),
		[isTablet]
	);

	const handlePageChange = useCallback(
		({selected}: {selected: number}) => {
			const newOffset = selected * pageSize;
			if (offset !== newOffset) {
				dispatch(changeOffset(newOffset));
			}
		},
		[pageSize, offset, dispatch, changeOffset]
	);

	const handlePageSizeChange = useCallback(
		(value: number) => {
			const newOffset = Math.floor(offset / value);
			dispatch(changePageSize(value));
			dispatch(changeOffset(newOffset));
		},
		[offset, dispatch, changePageSize, changeOffset]
	);

	return (
		<>
			<Plate withoutPadding>
				<EntitiesTable
					columns={columns}
					data={paginateBuiltInFields}
					headVisible={isTablet}
				/>
			</Plate>
			<PaginationAndSize
				pagination={
					<Pagination
						className={styles.containerPagination}
						pageCount={pagesCount}
						forcePage={currentPage}
						pageRangeDisplayed={3}
						marginPagesDisplayed={1}
						onPageChange={handlePageChange}
					/>
				}
				pageSize={
					<TranslatedPaginationPageSize
						className={styles.containerPageSize}
						pageSize={pageSize}
						pageSizeOptions={pageSizes}
						onPageSizeChange={handlePageSizeChange}
					/>
				}
			/>
		</>
	);
};
