import {memo} from 'react';
import {CellProps} from 'react-table';
import {IPreparedFieldSettingsSet} from '@/shared/types/IPreparedFieldsSettingsSets';
import {dictionaryKeys} from '@src/constants/translations/dictionaryKeys';
import {useTranslatedDictionaryByIds} from '@/shared/hooks/useTranslatedDictionaryByIds';

export const FieldStagesCell = memo(({row}: CellProps<IPreparedFieldSettingsSet>) => {
	const {stageIds} = row.original;
	const preparedStageName = useTranslatedDictionaryByIds(dictionaryKeys.objectStages, stageIds);

	return (
		<div>
			{preparedStageName.map((item, i) => (
				<div key={i}>{item}</div>
			))}
		</div>
	);
});
