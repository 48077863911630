import {IAddingInspectionRefProps} from '@src/components/AddingInspection';
import {IAddingProblemRefProps} from '@src/components/AddingProblem';
import IInspection from '@tehzor/tools/interfaces/inspections/IInspection';
import {IProblem} from '@tehzor/tools/interfaces/problems/IProblem';
import {Dispatch, MutableRefObject, RefObject, SetStateAction} from 'react';
import {IPresaleCheck} from '@tehzor/tools/interfaces/presaleChecks/IPresaleCheck';

interface ISavePresaleCheckProps {
	addingEntityRef: RefObject<IAddingProblemRefProps | IAddingInspectionRefProps>;
	selectedPresaleCheckId?: string;
	localPresaleChecksIds: string[];
	online: boolean;
	addNewPresaleCheck: () => IPresaleCheck | undefined;
	updateLatest: (presaleCheckId?: string) => void;
	setSaving: Dispatch<SetStateAction<boolean>>;
	isFastAddingActive: boolean;
	success: MutableRefObject<boolean>;
	onClose: () => void;
}

export const savePresaleCheck = async ({
	addingEntityRef,
	selectedPresaleCheckId,
	localPresaleChecksIds,
	online,
	updateLatest,
	addNewPresaleCheck,
	setSaving,
	success,
	onClose,
	isFastAddingActive
}: ISavePresaleCheckProps) => {
	if (addingEntityRef.current) {
		let presaleCheckId = selectedPresaleCheckId;
		const extraLinks: IProblem['links'] | IInspection['links'] = {presaleCheckId};
		const savingData = await addingEntityRef.current.getSavingData(true);
		if (!savingData) {
			return;
		}
		if (addingEntityRef.current.saveCache && savingData) {
			if (presaleCheckId === undefined) {
				setSaving(true);
				const presaleCheck = addNewPresaleCheck();
				presaleCheckId = presaleCheck?.id;
				if (!presaleCheck || !presaleCheckId) {
					return;
				}
				extraLinks.presaleCheckId = presaleCheckId;
				await addingEntityRef.current.saveCache(savingData, extraLinks);
			} else if (presaleCheckId && localPresaleChecksIds.includes(presaleCheckId)) {
				extraLinks.presaleCheckId = presaleCheckId;
				await addingEntityRef.current.saveCache(savingData, extraLinks);
			} else {
				const cachedData = await addingEntityRef.current.saveCache(savingData, extraLinks);
				if (cachedData) {
					await addingEntityRef.current.save(savingData, extraLinks, cachedData.cacheKey);
				}
			}
		}
		if (presaleCheckId && online) {
			void updateLatest();
		}
	} else {
		if (isFastAddingActive) return;
		onClose();
	}
	success.current = true;
	if (success.current) {
		setSaving(false);
		if (isFastAddingActive) return;
		onClose();
	}
};
