import {makeObjectEditRequest} from '@src/api/backend/object';
import {objectsQueryKeys} from '@src/api/cache/objects/keys';
import {queryClient} from '@src/api/QueryClient';
import {ISavingObject} from '@src/interfaces/saving/ISavingObject';
import {useMutation} from '@tanstack/react-query';
import {addErrorToast} from '@tehzor/ui-components';
import {useTranslation} from 'react-i18next';

interface IEditObjectRequestData {
	objectId: string;
	object: ISavingObject;
}

export const useEditObject = () => {
	const {t} = useTranslation();

	return useMutation({
		mutationKey: objectsQueryKeys.edit(),
		mutationFn: ({objectId, object}: IEditObjectRequestData) =>
			makeObjectEditRequest(objectId, object),
		onSuccess: () => {
			void queryClient.invalidateQueries({queryKey: objectsQueryKeys.list()});
		},
		onError: () => addErrorToast({title: t('toast.editObject.error.title')})
	});
};
