import {memo, useCallback} from 'react';
import {Button, ISelectionRowProps} from '@tehzor/ui-components';
import {SelectionRowActions} from '../SelectionRowActions/SelectionRowActions';
import {ICustomField} from '@tehzor/tools/interfaces/fields';

export const SelectionRow = memo(({toggleAllRowsSelected}: ISelectionRowProps<ICustomField>) => {
	const handleClear = useCallback(() => {
		toggleAllRowsSelected(false);
	}, [toggleAllRowsSelected]);

	return (
		<>
			<SelectionRowActions />
			<Button
				label="Сбросить"
				type="cancel"
				onClick={handleClear}
			/>
		</>
	);
});
