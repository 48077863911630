import {useMutation} from '@tanstack/react-query';
import {makeExportTemplateAddRequest} from '@src/api/backend/exportTemplates/add';
import {ISavingExportTemplate} from '@src/interfaces/saving/ISavingExportTemplate';
import {queryClient} from '@src/api/QueryClient';
import {exportTemplatesQueryKeys} from '@src/api/cache/exportTemplates/keys';
import {addErrorToast, addSuccessToast} from '@tehzor/ui-components';
import {useTranslation} from 'react-i18next';

export const useAddExportTemplate = () => {
	const {t} = useTranslation();

	return useMutation({
		mutationKey: exportTemplatesQueryKeys.add(),
		mutationFn: (fields: ISavingExportTemplate) => makeExportTemplateAddRequest(fields),
		onSuccess: () => {
			void queryClient.invalidateQueries({queryKey: exportTemplatesQueryKeys.list()});
			addSuccessToast({title: t('toast.addExportTemplate.success.title')});
		},
		onError: () => {
			addErrorToast({title: t('toast.addExportTemplate.error.title')});
		}
	});
};
