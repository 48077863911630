import {memo} from 'react';
import {CellProps} from 'react-table';
import {useTranslatedDictionaryById} from '@src/core/hooks/translations/useTranslatedDictionaryById';
import {dictionaryKeys} from '@src/constants/translations/dictionaryKeys';
import {useTranslation} from 'react-i18next';
import {IPreparedWarrantyClaimEntity} from '../../interfaces/IPreparedWarrantyClaimEntity';

export const SpaceCell = memo(({row}: CellProps<IPreparedWarrantyClaimEntity>) => {
	const claim = row.original;
	const spaceType = useTranslatedDictionaryById(
		dictionaryKeys.spaceTypesSingular,
		claim.data.space?.type
	);
	const {t} = useTranslation();

	return (
		<div className="warranty-claims-table__space-cell">
			{claim.data.space?.name && claim.data.space.type && (
				<div>{`${claim.data.space?.name} ${spaceType}${
					claim.data.space?.floor ? ',' : ''
				}`}</div>
			)}
			{claim.data.space?.floor && (
				<div>
					{`${claim.data.space?.floor} ${t(
						'warrantyClaimsPage.table.columns.spaceCell.floor'
					)}`}
				</div>
			)}
		</div>
	);
});
