import {memo} from 'react';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {StructuresDisplayMode} from '@src/interfaces/StructuresDisplayMode';
import {StructuresTable} from '@/widgets/StructuresTable';
import {StructuresSchemaDesktop, StructuresSchemaMobile} from '@/widgets/StructuresSchema';
import {extractStructuresPageSettings} from '@/entities/Structures';
import {useIsDesktop} from '@tehzor/ui-components/src/utils/mediaQueries';

interface IMainProps {
	objectId: string;
	spaceId: string | undefined;
}

export const Main = memo(({objectId, spaceId}: IMainProps) => {
	const {displayMode} = useAppSelector(s => extractStructuresPageSettings(s, objectId));
	const isDesktop = useIsDesktop();

	if (displayMode === StructuresDisplayMode.LIST) {
		return (
			<StructuresTable
				objectId={objectId}
				spaceId={spaceId}
			/>
		);
	}

	if (displayMode === StructuresDisplayMode.SCHEMA) {
		return isDesktop ? (
			<StructuresSchemaDesktop objectId={objectId} />
		) : (
			<StructuresSchemaMobile objectId={objectId} />
		);
	}

	return null;
});
