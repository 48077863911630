import {ProcessIds} from '@tehzor/tools/interfaces/process/ProcessId';
import {useTranslation} from 'react-i18next';
import './CheckListProcess.less';

interface ICheckListProcessProps {
	processId: ProcessIds;
}

export const CheckListProcess = ({processId}: ICheckListProcessProps) => {
	const {t} = useTranslation();
	const processName = t(`processes.${processId}`);

	return <div className="check-list-process">{processName}</div>;
};
