import {useCallback} from 'react';
import {FloatingActionButton, IconButton} from '@tehzor/ui-components';
import {useAddingCheckDialog} from '@src/components/AddingCheckDialog/hooks/useAddingCheckDialog';
import ICheck from '@tehzor/tools/interfaces/checks/ICheck';
import {useSpaceLocationForEntities} from '@src/core/hooks/spaces/useSpaceLocationForEntities';
import {BeforeDesktop, Desktop} from '@tehzor/ui-components/src/utils/mediaQueries';
import {useProblemsPermissions} from '@src/core/hooks/permissions/useProblemsPermissions';
import {useInspectionsAddingPermissions} from '@src/core/hooks/permissions/useInspectionsAddingPermissions';
import {useAggregateEntityPermissions} from '@src/core/hooks/permissions/useAggregateEntityPermissions';
import {useObject} from '@src/core/hooks/queries/objects/hooks';
import {queryClient} from '@src/api/QueryClient';
import {spacesQueryKeys} from '@src/api/cache/spaces/keys';
import {useTranslation} from 'react-i18next';

interface IEntityAddingProps {
	objectId: string;
	check: ICheck;
}

const mobileAddIcon = <i className="tz-plus-16" />;
const desktopAddIcon = <i className="tz-plus-20" />;

const EntityAdding = ({objectId, check}: IEntityAddingProps) => {
	const {t} = useTranslation();

	const {data: object} = useObject(objectId);

	const defaultData = useSpaceLocationForEntities(check.objectId, check.links?.spaceId);
	const [dialog, open] = useAddingCheckDialog({
		objectId,
		checkId: check.id,
		links: check.links,
		stage: object?.stage,
		defaultProblemData: defaultData,
		defaultInspectionData: defaultData,
		createdBy: check.createdBy?.id
	});

	const handleClick = useCallback(() => {
		if (check.links?.spaceId) {
			void queryClient.invalidateQueries({
				queryKey: spacesQueryKeys.detail(check.links.spaceId)
			});
		}
		open();
	}, [check, open]);

	const problemsPermissions = useProblemsPermissions(objectId);
	const checkProblemPermissions = useAggregateEntityPermissions(objectId, 'checkProblemsAdd', {
		createdBy: check.createdBy?.id
	});
	const inspectionsPermissions = useInspectionsAddingPermissions(objectId);
	const checkInspectionPermissions = useAggregateEntityPermissions(
		objectId,
		'checkInspectionsAdd',
		{createdBy: check.createdBy?.id}
	);

	return (problemsPermissions.canAdd && checkProblemPermissions.canAdd) ||
		(inspectionsPermissions.canAdd && checkInspectionPermissions.canAdd) ? (
		<>
			<BeforeDesktop>
				<FloatingActionButton
					icon={mobileAddIcon}
					label={t('checksPage.actions.add')}
					minifyOnScroll
					scrollContainer="scroll-container"
					onClick={handleClick}
				/>
			</BeforeDesktop>
			<Desktop>
				<IconButton
					type="accent-blue"
					onClick={handleClick}
				>
					{desktopAddIcon}
				</IconButton>
			</Desktop>
			{dialog}
		</>
	) : null;
};

export default EntityAdding;
