import {queryClient} from '@src/api/QueryClient';
import {requestEditWorkingGroup} from '@src/api/backend/workingGroup';
import {responsibilityRulesQueryKeys} from '@src/api/cache/responsibilityRules/keys';
import {workingGroupsQueryKeys} from '@src/api/cache/workingGroups/keys';
import {useMutation} from '@tanstack/react-query';
import {ISavingWorkingGroup} from '@tehzor/tools/interfaces/workingGroups/ISavingWorkingGroup';
import {addErrorToast, addSuccessToast} from '@tehzor/ui-components';
import {useTranslation} from 'react-i18next';

interface IEditWorkingGroup {
	groupId: string;
	fields: ISavingWorkingGroup;
	toastMessage: messageTypes;
}

export enum messageTypes {
	editValues = 'toast.editWorkingGroup.success.title.editValues',
	addPerformer = 'toast.editWorkingGroup.success.title.addPerformer',
	deletePerformer = 'toast.editWorkingGroup.success.title.deletePerformer'
}

export const useEditWorkingGroup = () => {
	const {t} = useTranslation();

	return useMutation({
		mutationKey: workingGroupsQueryKeys.edit(),
		mutationFn: (params: IEditWorkingGroup) => {
			const {groupId, fields} = params;
			return requestEditWorkingGroup(groupId, fields);
		},
		onSuccess: (data, params) => {
			void queryClient.invalidateQueries({queryKey: workingGroupsQueryKeys.list()});
			void queryClient.invalidateQueries({queryKey: responsibilityRulesQueryKeys.list()});

			if (params?.toastMessage) {
				addSuccessToast({title: t(params.toastMessage)});
			} else {
				addSuccessToast({title: t(messageTypes.editValues)});
			}
		},
		onError: () => {
			addErrorToast({title: t('toast.editWorkingGroup.error.title')});
		}
	});
};
