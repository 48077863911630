import {queryClient} from '@src/api/QueryClient';

import {
	IDeleteCategoriesSetResponse,
	IGetCategoriesSetsResponse,
	requestDeleteCategoriesSet
} from '@src/api/backend/categoriesSets';
import {categoriesQueryKeys} from '@src/api/cache/categories/keys';
import {useMutation} from '@tanstack/react-query';
import IError from '@tehzor/tools/interfaces/IError';
import {addErrorToast, addSuccessToast} from '@tehzor/ui-components';
import {useTranslation} from 'react-i18next';

export const useDeleteCategoriesSet = () => {
	const {t} = useTranslation();

	return useMutation<IDeleteCategoriesSetResponse, IError, string>({
		mutationFn: (categoriesSetId: string) => requestDeleteCategoriesSet(categoriesSetId),

		onSuccess: (_, categoriesSetId) => {
			queryClient.setQueryData<IGetCategoriesSetsResponse>(
				categoriesQueryKeys.list(),
				oldData => {
					if (oldData) {
						const newAllIds = oldData.allIds.filter(id => categoriesSetId !== id);
						const newById = newAllIds.reduce((prev, current) => {
							prev[current] = oldData.byId[current];
							return prev;
						}, {});

						return {
							allIds: newAllIds,
							byId: newById
						};
					}
					return oldData;
				}
			);
			addSuccessToast({title: t('toast.deleteCategoriesSet.success.title')});
		},
		onError: () => {
			addErrorToast({title: t('toast.deleteCategoriesSet.error.title')});
		}
	});
};
