import DesktopEntityAdding from './EntityAdding.desktop';
import {useInternalAcceptancesPermissions} from '@src/core/hooks/permissions/useInternalAcceptancesPermissions';
import DesktopInternalAcceptancesExport from './InternalAcceptancesExport.desktop';
import {useProblemsPermissions} from '@src/core/hooks/permissions/useProblemsPermissions';
import {useInspectionsAddingPermissions} from '@src/core/hooks/permissions/useInspectionsAddingPermissions';

interface IDesktopActionsProps {
	objectId?: string;
}

const ActionsDesktop = ({objectId}: IDesktopActionsProps) => {
	const permissions = useInternalAcceptancesPermissions(objectId);
	const problemsPermissions = useProblemsPermissions(objectId);
	const inspectionsPermissions = useInspectionsAddingPermissions(objectId);

	return (
		<>
			{objectId !== undefined &&
				permissions.canAdd &&
				(problemsPermissions.canAdd || inspectionsPermissions.canAdd) && (
					<DesktopEntityAdding objectId={objectId} />
				)}
			{permissions.canExport && <DesktopInternalAcceptancesExport objectId={objectId} />}
		</>
	);
};

export default ActionsDesktop;
