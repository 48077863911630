import CellWrap from '@src/components/tableCells/CellWrap';
import WarrantyClaimInfoCell from '@src/components/tableCells/WarrantyClaimInfoMobileCell';
import CheckInfoMobileCell from '@src/components/tableCells/CheckInfoMobileCell';
import OwnerAcceptanceInfoMobileCell from '@src/components/tableCells/OwnerAcceptanceInfoMobileCell';
import InspectionInfoMobileCell from '@src/components/tableCells/InspectionInfoMobileCell';
import {ProblemInfoCellWrap} from '@src/components/tableCells/ProblemInfoCellWrap';
import ProblemInfoMobileCell from '@src/components/tableCells/ProblemInfoMobileCell';
import InternalAcceptanceInfoMobileCell from '@src/components/tableCells/InternalAcceptanceInfoMobileCell';
import WorkAcceptanceMobileCell from '@src/components/tableCells/WorkAcceptanceMobileCell';
import {useMemo} from 'react';
import {useSpaceProblemRepliesAsArray} from '@src/core/hooks/queries/space';
import {Column} from 'react-table';
import {ISpaceEntity} from '@src/interfaces/ISpaceEntity';
import {PresaleCheckInfoMobileCell} from '@src/components/tableCells/PresaleCheckInfoMobileCell';

export const useMobileColumns = (objectId: string, spaceId: string) => {
	const {data: replies} = useSpaceProblemRepliesAsArray(objectId, spaceId);

	return useMemo<Array<Column<ISpaceEntity>>>(
		() => [
			{
				id: 'info',
				Cell: CellWrap({
					check: CheckInfoMobileCell,
					'presale-check': PresaleCheckInfoMobileCell,
					'internal-acceptance': InternalAcceptanceInfoMobileCell,
					'owner-acceptance': OwnerAcceptanceInfoMobileCell,
					'warranty-claim': WarrantyClaimInfoCell,
					inspection: InspectionInfoMobileCell,
					problem: ProblemInfoCellWrap(ProblemInfoMobileCell, undefined, replies),
					'work-acceptance': WorkAcceptanceMobileCell,
					'local-check': CheckInfoMobileCell,
					'local-presale-check': PresaleCheckInfoMobileCell,
					'local-internal-acceptance': InternalAcceptanceInfoMobileCell,
					'local-owner-acceptance': OwnerAcceptanceInfoMobileCell,
					'local-warranty-claim': WarrantyClaimInfoCell,
					'local-problem': ProblemInfoCellWrap(ProblemInfoMobileCell, undefined, replies),
					'local-inspection': InspectionInfoMobileCell,
					'local-work-acceptance': WorkAcceptanceMobileCell
				})
			}
		],
		[replies]
	);
};
