import {extractFieldSettingsListArray} from './extractFieldSettingsListArray';
import {IGetListFieldSettingsResponse} from '@/shared/api/fieldSettings/getFieldSettingsList';

export const extractFieldSettingsListBySetIds = (
	data: IGetListFieldSettingsResponse,
	setIds?: string[]
) => {
	if (!setIds?.length) return undefined;
	const array = extractFieldSettingsListArray(data);
	return array?.filter(setting => setIds.includes(setting.fieldsSettingsSetId));
};
