import './PresaleCheckInfoMobileCell.less';
import {TableRowSelect} from '@tehzor/ui-components';
import ClickPreventWrap from '../ClickPreventWrap';
import {CellProps} from 'react-table';
import {PresaleCheckInfo} from '@src/components/tableCells/PresaleCheckInfo';
import {IPresaleCheck} from '@tehzor/tools/interfaces/presaleChecks/IPresaleCheck';

const PresaleCheckInfoMobileCell = ({row}: CellProps<{data: IPresaleCheck}>) => (
	<div className="m-presale-check-info-cell">
		<div className="m-presale-check-info-cell__header">
			<PresaleCheckInfo data={row.original.data} />

			<ClickPreventWrap style={{marginLeft: '8px'}}>
				<TableRowSelect
					{...row.getToggleRowSelectedProps()}
					style={{marginTop: '12px'}}
				/>
			</ClickPreventWrap>
		</div>
	</div>
);

export default PresaleCheckInfoMobileCell;
