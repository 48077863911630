import {useQueryClient} from '@tanstack/react-query';
import {fieldSettingsQueryKeys} from '@/entities/FieldSettings';
import {requestGetListFieldSettings} from '@/shared/api/fieldSettings/getFieldSettingsList';

export const useFieldSettingsQueryDefaults = () => {
	const queryClient = useQueryClient();

	// Получение списка настроек полей
	queryClient.setQueryDefaults(fieldSettingsQueryKeys.lists(), {
		networkMode: 'offlineFirst',
		queryFn: requestGetListFieldSettings,
		staleTime: 1000 * 60 * 60 * 24 * 7 * 2, // 2 недели
		gcTime: 1000 * 60 * 60 * 24 * 7 * 2 // 2 недели
	});
};
