import {makeObjectDeleteRequest} from '@src/api/backend/object';
import {objectsQueryKeys} from '@src/api/cache/objects/keys';
import {queryClient} from '@src/api/QueryClient';
import {useMutation} from '@tanstack/react-query';
import {addErrorToast} from '@tehzor/ui-components';
import {useTranslation} from 'react-i18next';

export const useDeleteObject = () => {
	const {t} = useTranslation();

	return useMutation({
		mutationFn: (id: string) => makeObjectDeleteRequest(id),
		mutationKey: objectsQueryKeys.delete(),
		onSuccess: () => {
			void queryClient.invalidateQueries({queryKey: objectsQueryKeys.list()});
		},
		onError: () => addErrorToast({title: t('toast.deleteObject.error.title')})
	});
};
