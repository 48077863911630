import {useCallback, useState, memo} from 'react';
import './EditableMeterDialog.less';
import {ActionButtons, Button, Dialog} from '@tehzor/ui-components';
import {useEditableMeter} from '../EditableMeter/hooks/useEditableMeter';
import {IMeter} from '@tehzor/tools/interfaces/meters/IMeter';
import {useEditMeter} from '@src/core/hooks/mutations/meters/useEditMeter';
import {IMeterConsumption} from '@tehzor/tools/interfaces/meterConsumptions/IMeterConsumption';
import {useTranslation} from 'react-i18next';
import {useTranslatedConfirmDialog} from '@src/core/hooks/translations/useTranslatedConfirmDialog';

interface IEditableMeterDialogProps {
	meter: IMeter;
	meterConsumptions: IMeterConsumption[] | undefined;
	isOpen: boolean;
	onClose: () => void;
}

/**
 * Окно редактирования прибора учета
 */
export const EditableMeterDialog = memo(
	({meter, meterConsumptions, isOpen, onClose}: IEditableMeterDialogProps) => {
		const {t} = useTranslation();
		const [saving, setSaving] = useState(false);
		const [fields, getSavingData, reset, isBlocking] = useEditableMeter(
			meter,
			meterConsumptions,
			saving
		);

		const editMeter = useEditMeter();

		const save = useCallback(() => {
			if (!meter) {
				return;
			}
			setSaving(true);
			const savingData = getSavingData();
			if (savingData) {
				savingData.id = meter.id;
				try {
					editMeter(savingData);
					onClose();
				} catch (error) {
					setSaving(false);
					throw error;
				}
			} else {
				onClose();
			}
			setSaving(false);
		}, [meter, getSavingData]);

		const [closingDialog, getClosingConfirmation] = useTranslatedConfirmDialog();

		const handleClose = useCallback(async () => {
			if (!isBlocking || (await getClosingConfirmation())) {
				onClose();
			}
		}, [isBlocking, onClose]);

		return (
			<Dialog
				className="editable-meter-dialog"
				isOpen={isOpen}
				title={t('components.editableMeterDialog.dialog.title')}
				footer={
					<ActionButtons>
						<Button
							type="cancel"
							label={t('components.editableMeterDialog.dialog.button.cancel.label')}
							disabled={saving}
							onClick={handleClose}
						/>
						<Button
							type="accent-blue"
							label={t('components.editableMeterDialog.dialog.button.accept.label')}
							disabled={saving}
							onClick={save}
						/>
					</ActionButtons>
				}
				fullScreenOnTablet
				onRequestClose={handleClose}
				onAfterClose={reset}
			>
				{fields}
				{closingDialog}
			</Dialog>
		);
	}
);
