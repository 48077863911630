import {useEffect, useTransition} from 'react';
import {TabLink, Tabs} from '@tehzor/ui-components';
import {extractSpacesPageSettings} from '@src/store/modules/settings/pages/spaces/selectors';
import {changeSchemaView, togglePending} from '@src/store/modules/settings/pages/spaces/actions';
import useAppSelector from '@src/core/hooks/useAppSelector';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {SpacesSchemaVariants} from '@src/interfaces/SpacesSchemaVariants';
import {useUpdateEffect} from 'react-use';
import {useCheckListPermissions} from '@src/core/hooks/permissions/useCheckListPermissions';
import {useTranslation} from 'react-i18next';
import {ProcessIds} from '@tehzor/tools/interfaces/process/ProcessId';

const tabsIndexes = {
	[SpacesSchemaVariants.PROBLEMS]: 0,
	[SpacesSchemaVariants.CHECK_LISTS]: 1,
	[SpacesSchemaVariants.WORK_ACCEPTANCES]: 2,
	[SpacesSchemaVariants.REPORTS]: 3
};
const tabsKeys = [
	SpacesSchemaVariants.PROBLEMS,
	SpacesSchemaVariants.CHECK_LISTS,
	SpacesSchemaVariants.WORK_ACCEPTANCES,
	SpacesSchemaVariants.REPORTS
];

interface IDesktopSchemaViewTabsProps {
	objectId: string;
}

const classNames = {
	root: 'spaces-page__plate-header-tabs',
	links: 'spaces-page__plate-header-tabs-links'
};

export const DesktopSchemaViewTabs = ({objectId}: IDesktopSchemaViewTabsProps) => {
	const {t} = useTranslation();

	const pageSettings = useAppSelector(s => extractSpacesPageSettings(s, objectId));
	const dispatch = useAppDispatch();
	const [isPending, startTransition] = useTransition();
	const permissions = useCheckListPermissions(objectId);

	// Если в настройках сохранён недоступный вариант, то сбрасываем на нарушения
	useEffect(() => {
		if (!permissions.canView && pageSettings.schemaView !== SpacesSchemaVariants.PROBLEMS) {
			dispatch(changeSchemaView(objectId, SpacesSchemaVariants.PROBLEMS));
		}
	}, [permissions.canView]);

	useUpdateEffect(() => {
		dispatch(togglePending(isPending));
	}, [isPending]);

	const handleChange = (index: number) => {
		startTransition(() => {
			dispatch(changeSchemaView(objectId, tabsKeys[index]));
		});
	};

	// Отключаем табы в зависимости от выбранного процеса:
	const getDisabledTabs = (): number[] => {
		const tabs: number[] = [];

		if (pageSettings.processId === ProcessIds.ACCEPTANCE_CONTROL) {
			tabs.push(tabsIndexes[SpacesSchemaVariants.CHECK_LISTS]);
		}
		if (pageSettings.processId && pageSettings.processId !== ProcessIds.ACCEPTANCE_CONTROL) {
			tabs.push(tabsIndexes[SpacesSchemaVariants.WORK_ACCEPTANCES]);
		}

		return tabs;
	};

	const tabs = [
		<TabLink label={t('spacesPage.schemaViewTabs.tabLink.label.defects')} />,
		<TabLink
			label={t('spacesPage.schemaViewTabs.tabLink.label.checkLists')}
			disabled={pageSettings.processId === ProcessIds.ACCEPTANCE_CONTROL}
		/>,
		<TabLink
			label={t('spacesPage.schemaViewTabs.tabLink.label.workAcceptances')}
			disabled={
				pageSettings.processId && pageSettings.processId !== ProcessIds.ACCEPTANCE_CONTROL
			}
		/>,
		<TabLink label={t('spacesPage.schemaViewTabs.tabLink.label.reports')} />
	];

	return permissions.canView ? (
		<Tabs
			className={classNames}
			links={tabs}
			activeTab={tabsIndexes[pageSettings.schemaView]}
			onActiveTabChange={handleChange}
			disabledTabs={getDisabledTabs()}
		/>
	) : null;
};
