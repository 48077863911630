import {IEnrichedTask} from '@tehzor/tools/interfaces/tasks/IEnrichedTask';
import {CellProps} from 'react-table';
import {usePlan} from '@src/core/hooks/queries/plans/hooks';

const LocationMobileCell = ({row}: Pick<CellProps<IEnrichedTask>, 'row'>) => {
	const {planId, floor, objectId, object} = row.original;
	const {data: plan} = usePlan(objectId, planId);
	const location = [];
	if (object) {
		location.push(object.name);
	}
	if (plan) {
		location.push(`План ${plan.name}`);
	}

	if (floor) {
		location.push(`Этаж ${floor}`);
	}

	const locationText = location.join(', ');
	return (
		<div className="tasks-page__m-table-location">{location.length > 0 && locationText}</div>
	);
};

export default LocationMobileCell;
