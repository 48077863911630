import {useQuery} from '@tanstack/react-query';
import {IOfflineJournalEntity} from '../../interfaces/IOfflineJournalEntity';
import {useMutationErrors} from '../useMutationErrors';
import {useCallback} from 'react';
import {extractLocalPresaleChecksJournal} from './selector';
import {IPresaleCheck} from '@tehzor/tools/interfaces/presaleChecks/IPresaleCheck';
import {presaleChecksQueryKeys} from '@/entities/PresaleCheck';

export const useLocalPresaleChecksJournal = () => {
	const errorsMap = useMutationErrors({mutationKey: presaleChecksQueryKeys.add()});
	const selector = useCallback(
		(presaleChecks: IPresaleCheck[]) =>
			extractLocalPresaleChecksJournal(presaleChecks, errorsMap),
		[errorsMap]
	);
	const {data} = useQuery<IPresaleCheck[], unknown, IOfflineJournalEntity[]>({
		queryKey: presaleChecksQueryKeys.localList(),
		select: selector
	});
	return data || [];
};
