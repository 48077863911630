import {
	EditableFieldLabel,
	Select2,
	SelectOption,
	SelectPopup,
	TextFieldWithForwardedRef
} from '@tehzor/ui-components';
import * as React from 'react';
import {useFieldValueTypesAsArray} from '@/entities/Fields';
import {IValueType} from '@tehzor/tools/interfaces/fields';

interface IFieldValueTypeSelectProps {
	value?: string;
	className?: string;
	style?: React.CSSProperties;
	label?: string;
	errorMessage?: string;
	onChange: (valueType: IValueType) => void;
	disabled?: boolean;
	hasError?: boolean;
}

export const FieldValueTypeSelect = ({
	value,
	className,
	style,
	label,
	errorMessage,
	onChange,
	disabled,
	hasError
}: IFieldValueTypeSelectProps) => {
	const {data: fieldValueTypes} = useFieldValueTypesAsArray();

	const handleChange = (v: string) => {
		const valueType = fieldValueTypes?.find(({id}) => id === v);
		if (!valueType) return;
		onChange(valueType);
	};

	const fieldValue = fieldValueTypes?.find(({id}) => id === value)?.name;

	return (
		<div
			className={className}
			style={style}
		>
			<EditableFieldLabel>{label}</EditableFieldLabel>
			<SelectPopup
				noHeader
				trigger={
					<TextFieldWithForwardedRef
						elementType="div"
						value={fieldValue}
						icon={<i className="tz-simple-arrow-20" />}
						error={hasError ? errorMessage : undefined}
						disabled={disabled}
					/>
				}
			>
				<Select2
					value={value}
					onChange={handleChange}
				>
					{fieldValueTypes?.map(valueType => (
						<SelectOption
							key={valueType.id}
							itemKey={valueType.id}
							content={valueType.name}
							inputType="radio"
						/>
					))}
				</Select2>
			</SelectPopup>
		</div>
	);
};
