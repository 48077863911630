import {useMemo} from 'react';
import {useObject, useTargetObjects} from '@src/core/hooks/queries/objects/hooks';
import {useSpaceIndicatorsAsMap} from '@src/core/hooks/queries/spaceIndicatorsSets/hooks';
import {useSpaceStatuses} from '@src/core/hooks/queries/spaceStatuses/hooks';
import {useSpacesListAsArray} from '../../../core/hooks/queries/spaces/hooks';
import {enrichSpaces} from '../../../core/hooks/queries/spaces/selectors';

export function useEnrichedList(objectId: string) {
	const {data: targetObjects} = useTargetObjects(objectId);
	const {data: spaces} = useSpacesListAsArray(objectId, {objects: targetObjects});
	const {data: spaceStatuses} = useSpaceStatuses();
	const {data: object} = useObject(objectId);
	const {data: spaceIndicators} = useSpaceIndicatorsAsMap(object);
	return useMemo(
		() =>
			spaceIndicators && spaceStatuses && spaces
				? enrichSpaces(spaces, spaceStatuses.byId, spaceIndicators.byId)
				: [],
		[spaces, spaceStatuses?.byId, spaceIndicators?.byId]
	);
}
