import {
	IDeleteManyCategoriesResponse,
	requestDeleteManyCategories
} from '@src/api/backend/categories';
import {useMutation} from '@tanstack/react-query';
import IError from '@tehzor/tools/interfaces/IError';
import {addErrorToast} from '@tehzor/ui-components';
import {useTranslation} from 'react-i18next';

export const useDeleteCategories = () => {
	const {t} = useTranslation();
	return useMutation<IDeleteManyCategoriesResponse, IError, string>({
		mutationFn: (categoriesSetId: string) => requestDeleteManyCategories(categoriesSetId),
		onError: () => {
			addErrorToast({title: t('toast.deleteCategories.error.title')});
		}
	});
};
