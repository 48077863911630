import {IProblemEntity} from '@src/interfaces/IProblemEntity';
import SelectedEntitiesDeletion from './SelectedEntitiesDeletion';
import SelectedEntityCopying from './SelectedEntityCopying';

interface ISelectionsActionsProps {
	selectedEntities: IProblemEntity[];
	selectedClearing: () => void;
}

const SelectionActions = ({selectedEntities, selectedClearing}: ISelectionsActionsProps) => {
	const canDelete = selectedEntities.every(item => item.data.canDelete);
	return (
		<>
			{selectedEntities.length === 1 &&
				selectedEntities[0].data.canCopy &&
				selectedEntities[0].type !== 'local_problem' && (
					<SelectedEntityCopying selectedEntity={selectedEntities[0]} />
				)}
			{canDelete && (
				<SelectedEntitiesDeletion
					selectedEntities={selectedEntities}
					selectedClearing={selectedClearing}
				/>
			)}
		</>
	);
};

export default SelectionActions;
