import {IGetListProblemsResponse} from '@src/api/backend/problems';
import {PresaleCheckEntitiesVisibility} from '../../../../slice/pageSettings/interfaces';
import {extractPresaleCheckProblemsArray} from './extractPresaleCheckProblemsArray';

export const extractPresaleCheckProblemsEntities = (
	data: IGetListProblemsResponse,
	visibility: PresaleCheckEntitiesVisibility[]
) => {
	const problems = extractPresaleCheckProblemsArray(data);
	if (visibility.includes('problems') && problems?.length) {
		return problems.map(item => ({
			id: `p_${item.id}`,
			type: 'problem',
			data: item
		}));
	}
	return [];
};
