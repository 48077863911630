import {IconMenu, MenuItem} from '@tehzor/ui-components';
import {useDeletePresaleCheckConfirmDialog} from '../../hooks/useDeletePresaleCheckConfirmDialog';
import {IPermissions} from '@/entities/PresaleCheck/model/hooks/usePresaleCheckPermissions';

import {useTranslation} from 'react-i18next';
import {IPresaleCheck} from '@tehzor/tools/interfaces/presaleChecks/IPresaleCheck';

interface IMobileMenuProps {
	objectId: string;
	presaleCheck: IPresaleCheck;
	permissions: IPermissions;
}

const deleteIcon = <i className="tz-delete" />;

export const ActionsMenuMobile = ({objectId, presaleCheck, permissions}: IMobileMenuProps) => {
	const {t} = useTranslation();
	const [deleteDialog, handleDeleteClick] = useDeletePresaleCheckConfirmDialog(
		objectId,
		presaleCheck
	);

	const items = [];

	if (permissions.canDelete) {
		items.push(
			<MenuItem
				key="delete"
				icon={deleteIcon}
				onClick={handleDeleteClick}
			>
				{t('presaleChecksPage.actions.menu.delete')}
			</MenuItem>
		);
	}

	return items.length ? (
		<>
			<IconMenu>{items}</IconMenu>
			{permissions.canDelete && deleteDialog}
		</>
	) : null;
};
