import {memo} from 'react';
import {CellProps} from 'react-table';
import {Indicators} from '../Indicators';
import {IPreparedWarrantyClaimEntity} from '../../interfaces/IPreparedWarrantyClaimEntity';

export const NumberAndStatsCell = memo(({row}: CellProps<IPreparedWarrantyClaimEntity>) => {
	const claim = row.original;

	return (
		<div className="warranty-claims-table__stats-cell">
			<div className="warranty-claims-table__stats-cell-number">
				№{claim.data.number ?? claim.data.localNumber}
			</div>
			<Indicators claim={claim.data} />
		</div>
	);
});
