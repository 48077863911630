import {ISavingPresaleCheck} from '@src/interfaces/saving/ISavingPresaleCheck';
import {httpRequests} from '@tehzor/tools/api/httpRequests';
import {IPresaleCheck} from '@tehzor/tools/interfaces/presaleChecks/IPresaleCheck';

/**
 * Изменяет проверку
 *
 * @param objectId id объекта
 * @param presaleCheckId id предпродажной проверки
 * @param fields данные проверки
 */
export const requestEditPresaleCheck = async (
	objectId: string,
	presaleCheckId: string,
	fields: ISavingPresaleCheck
) => {
	const response = await httpRequests.withToken.put<IPresaleCheck>(
		`presale-checks/${presaleCheckId}`,
		{
			objectId,
			...fields
		}
	);

	return response.data;
};
