import {IGetCheckListsResponse} from '@src/api/backend/checkLists';
import {IGetObjectsResponse} from '@src/api/backend/objects';
import {ISpacesPageSettingsState} from '@src/store/modules/settings/pages/spaces/reducers/byPage';
import {ICheckList} from '@tehzor/tools/interfaces/checkLists/ICheckList';
import {SpaceTypeId} from '@tehzor/tools/interfaces/spaces/ISpaceType';
import {handleSort} from '@src/core/hooks/queries/checkLists/utils/handleSort';
import {ICheckListsPageSettingsState} from '@src/store/modules/settings/pages/manage/checkLists/reducers';
import {ProcessIds} from '@tehzor/tools/interfaces/process/ProcessId';

export const extractCheckListsMap = (data: IGetCheckListsResponse) => data.byId;

export const extractCheckListsAsArray = (data: IGetCheckListsResponse) =>
	data.allIds.map(id => data.byId[id]);

export const extractCheckListById = (data: IGetCheckListsResponse, checkListId?: string) => {
	if (!checkListId) return undefined;
	return data.byId[checkListId];
};

export const extractCheckListsAsArrayByObjectId = (
	data: IGetCheckListsResponse,
	objectId?: string,
	objectsData?: IGetObjectsResponse
) => {
	if (!objectId) return undefined;

	const checkListsArray = extractCheckListsAsArray(data);
	return checkListsArray.filter(checklist => {
		if (checklist.objects && checklist.objects.length > 0) {
			const finalChecklistObjectIds = new Set();
			for (const checklistObjectId of checklist.objects) {
				const parentId = objectsData
					? objectsData.byId[checklistObjectId]?.parentId
					: undefined;

				if (parentId) {
					finalChecklistObjectIds.add(parentId);
				}

				finalChecklistObjectIds.add(checklistObjectId);
			}

			return finalChecklistObjectIds.has(objectId);
		}

		return true;
	});
};

export const extractCheckListsAsArrayFilteredBySelectedSpaceType = (
	data: IGetCheckListsResponse,
	objectId?: string,
	objectsData?: IGetObjectsResponse,
	spacesPageSettings?: ISpacesPageSettingsState
) => {
	const checkListsArrayByObjectId = extractCheckListsAsArrayByObjectId(
		data,
		objectId,
		objectsData
	);
	if (!checkListsArrayByObjectId || !spacesPageSettings) return undefined;

	const {stage, type} = spacesPageSettings;

	let result: ICheckList[] = [...checkListsArrayByObjectId];

	if (type) {
		result = result.filter(checkList => checkList.spaceTypes?.includes(type as SpaceTypeId));
	}

	if (stage) {
		result = result.filter(checkList => checkList.stage === stage);
	}

	return result;
};

export const extractCheckListsAsArrayForWorkAcceptances = (
	data: IGetCheckListsResponse,
	objectId?: string,
	objectsData?: IGetObjectsResponse
) => {
	const checkListsArrayByObjectId = extractCheckListsAsArrayByObjectId(
		data,
		objectId,
		objectsData
	);
	return checkListsArrayByObjectId?.filter(
		checkList => checkList.processId === ProcessIds.ACCEPTANCE_CONTROL
	);
};

export const extractCheckListsAsArrayFilteredByStructureType = (
	data: IGetCheckListsResponse,
	objectId?: string,
	objectsData?: IGetObjectsResponse,
	type?: string
) => {
	const checkListsArrayByObjectId = extractCheckListsAsArrayByObjectId(
		data,
		objectId,
		objectsData
	);
	return checkListsArrayByObjectId?.filter(
		checkList => checkList.structureType && checkList.structureType === type
	);
};

export const extractCheckListsForPage = (
	data: IGetCheckListsResponse,
	settings: ICheckListsPageSettingsState
) => {
	const {sort} = settings;
	const checkListsAsArray = extractCheckListsAsArray(data);
	return handleSort(checkListsAsArray, sort);
};
