import {ReactNode} from 'react';
import classNames from 'classnames';
import styles from './Trigger.module.less';
import {InlineButton} from '@tehzor/ui-components';

interface ISpaceTypeMenuButtonProps {
	label?: ReactNode;
	isOpen?: boolean;
	disabled?: boolean;
	onClick?: () => void;
}

const iconArrow = <i className="tz-simple-arrow-16" />;

export const Trigger = ({label, isOpen, disabled, onClick}: ISpaceTypeMenuButtonProps) => (
	<InlineButton
		labelClassName={styles.label}
		className={styles.wrapper}
		rightIconClassName={classNames(styles.icon, isOpen && styles.iconOpen)}
		label={label}
		rightIcon={iconArrow}
		disabled={disabled}
		onClick={onClick}
	/>
);
