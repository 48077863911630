import {memo} from 'react';
import {ICheckList} from '@tehzor/tools/interfaces/checkLists/ICheckList';
import classNames from 'classnames';
import {useTranslation} from 'react-i18next';
import {ProcessGroup} from '@src/pages/CheckListPage/components/ProcessGroup/ProcessGroup';
import {stageColorsMap} from '@src/constants/entities/stageColorsMap';

interface ICheckListGroupProps {
	groupedCheckLists: Record<string, Record<string, ICheckList[]>>;
	mobileView?: boolean;
}

export const CheckListGroup = memo(({groupedCheckLists, mobileView}: ICheckListGroupProps) => {
	const {t} = useTranslation();

	const groupedCheckListsArray = Object.entries(groupedCheckLists);

	const stageGroupClass = classNames({
		'check-list-page-list__stage-group': !mobileView,
		'check-list-page__m-list__stage-group': mobileView
	});

	const stageGroupHeaderClass = classNames({
		'check-list-page-list__stage-group__header': !mobileView,
		'check-list-page__m-list__stage-group__header': mobileView
	});

	return (
		<>
			{groupedCheckListsArray.map(([stageId, processes], stageIndex) => (
				<div className={stageGroupClass}>
					<p className={stageGroupHeaderClass}>{t(`objectStages.${stageId}`)}</p>
					{Object.entries(processes).map(([processId, checkLists], processIndex) => (
						<ProcessGroup
							key={processId}
							color={stageColorsMap.get(stageId)}
							processId={processId}
							checkLists={checkLists}
							mobileView={mobileView}
							isFirstProcess={stageIndex === 0 && processIndex === 0}
						/>
					))}
					{stageIndex !== groupedCheckListsArray.length - 1 && (
						<hr className="check-list-page-list__divider" />
					)}
				</div>
			))}
		</>
	);
});
