import {QueryFunctionContext, useQueryClient} from '@tanstack/react-query';
import {requestGetListFieldSettingsSets} from '@/shared/api/fieldsSettingsSets';
import {requestGetFieldsSettingsSet} from '@/shared/api/fieldsSettingsSets/getFieldsSettingsSet';
import {
	fieldSettingsSetsQueryKeys,
	IFieldSettingsSetsDetailsKey
} from '@/entities/FieldsSettingsSets';

export const useFieldSettingsSetsQueryDefaults = () => {
	const queryClient = useQueryClient();

	// Получение списка наборов настроек полей
	queryClient.setQueryDefaults(fieldSettingsSetsQueryKeys.lists(), {
		networkMode: 'offlineFirst',
		queryFn: requestGetListFieldSettingsSets,
		staleTime: 1000 * 60 * 60 * 24 * 7 * 2, // 2 недели
		gcTime: 1000 * 60 * 60 * 24 * 7 * 2 // 2 недели
	});

	queryClient.setQueryDefaults(fieldSettingsSetsQueryKeys.details(), {
		queryFn: async ({queryKey}: QueryFunctionContext<IFieldSettingsSetsDetailsKey>) =>
			requestGetFieldsSettingsSet(queryKey[2]),
		staleTime: 1000 * 60 * 5, // 5 минут
		gcTime: 1000 * 60 * 60 * 24 // 1 День
	});
};
