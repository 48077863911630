import {Toggle} from '@tehzor/ui-components';
import {IPreparedField, useFieldsIsUpdating} from '@/features/Fields';
import {CellContext} from '@tanstack/react-table';
import {useEditFieldSettings} from '@/entities/FieldSettings';

export const FieldCopyableCell = ({row}: CellContext<IPreparedField, IPreparedField>) => {
	const field = row.original;
	const {mutateAsync: editFieldSettings} = useEditFieldSettings();
	const {isUpdating} = useFieldsIsUpdating();

	const handleCopyableChange = async () => {
		if (isUpdating) return;
		if (field.settingsId && field.canBeCopied) {
			await editFieldSettings({settingId: field.settingsId, copyable: !field.copyable});
		}
	};
	return (
		<div>
			<Toggle
				disabled={!field.canBeCopied || isUpdating}
				checked={field.copyable}
				onChange={handleCopyableChange}
			/>
		</div>
	);
};
