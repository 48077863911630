import {Reducer, useReducer} from 'react';
import {
	errorsFns,
	IEditableWorkAcceptanceState,
	IEditableWorkAcceptanceAction,
	init
} from './state';
import {createReducer} from '@tehzor/tools/core/states/editableEntityState';
import {ISavingWorkAcceptance} from '@src/interfaces/saving/ISavingWorkAcceptance';

export const useEditableWorkAcceptanceState = (workAcceptance?: ISavingWorkAcceptance) =>
	useReducer<
		Reducer<IEditableWorkAcceptanceState, IEditableWorkAcceptanceAction>,
		ISavingWorkAcceptance | undefined
	>(createReducer(init, errorsFns), workAcceptance, init);
