import {useMemo} from 'react';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {useObject} from '@src/core/hooks/queries/objects/hooks';
import {useExtractCheckListsAsArray} from '@src/core/hooks/queries/checkLists/hooks';
import {WorkAcceptanceFrontTypeId} from '@tehzor/tools/interfaces/workAcceptances/IWorkAcceptanceFrontType';
import {ProcessIds} from '@tehzor/tools/interfaces/process/ProcessId';
import {useSpace} from '@src/core/hooks/queries/space';

/**
 * Возвращает доступные чек-листы помещения для определённых условий (объект, помещение, стадия, процесс)
 *
 * @param objectId id объекта
 * @param spaceId id помещения
 * @param stage стадия
 * @param processId процесс чек листа
 */

export function useSpaceCheckLists(
	objectId: string,
	spaceId: string | undefined,
	stage: ObjectStageIds | 'all' = 'all',
	processId: ProcessIds | 'all' = 'all'
) {
	const {data: object} = useObject(objectId);
	const {data: space} = useSpace(spaceId, objectId);
	const {data: lists} = useExtractCheckListsAsArray();

	return useMemo(() => {
		if (!object || !lists) {
			return [];
		}

		// Фильтруем общий список чек листов:
		return lists.filter(checklist => {
			// У чек-листа должна совпадать компания
			if (object.companyId !== checklist.companyId) {
				return false;
			}
			// Должны совпадать объекты (если указаны)
			if (checklist.objects?.length && !checklist.objects.includes(object.id)) {
				return false;
			}
			// Должна совпадать выбранная стадия
			if (stage !== 'all' && checklist.stage !== stage) {
				return false;
			}
			// Должен совпадать выбранный процесс
			if (processId !== 'all' && checklist.processId !== processId) {
				return false;
			}

			// Проверка дополнительных условий для чек-листов помещений
			if (
				// На странице помещений, переданный processId не может быть равен приёмочному контролю
				processId !== ProcessIds.ACCEPTANCE_CONTROL &&
				(checklist.processId !== ProcessIds.ACCEPTANCE_CONTROL ||
					checklist.frontType === WorkAcceptanceFrontTypeId.SPACES)
			) {
				if (!space) {
					return false;
				}
				// Должен совпадать тип помещения чек листа (если указан) с текущим помещением
				if (checklist.spaceTypes?.length && !checklist.spaceTypes.includes(space.type)) {
					return false;
				}
				// Если указан тип отделки у помещения
				if (space.typeDecoration) {
					return (
						// Возвращаем чек-листы без типов отделки
						!checklist.typeDecoration?.length ||
						// и чек-листы с типом отделки помещения
						checklist.typeDecoration.includes(space.typeDecoration)
					);
				}
				// Если не указан тип отделки у помещения, то у чек-листа также не должно быть типа отделки
				return !checklist.typeDecoration?.length;
			}
			return false;
		});
	}, [object, space, lists, stage, processId]);
}
