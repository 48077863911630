import {useMutation} from '@tanstack/react-query';
import {IExportResponse} from '@tehzor/tools/interfaces/IExportResponse';
import {warrantyClaimsQueryKeys} from '@src/api/cache/warrantyClaims/keys';
import {makeWarrantyClaimExportRequest} from '@src/api/backend/warrantyClaim';
import {addExportToast} from '@/shared/lib/Toasts/ExportToast/addExportToast';
import {addErrorToast, addWarningToast} from '@tehzor/ui-components';
import {useTranslation} from 'react-i18next';

interface IExportWarrantyClaimParams {
	templateId: string;
	createDocument: boolean | undefined;
	email: string | undefined;
	objectId: string;
	claimId: string;
	problems?: string[];
}

export const useExportWarrantyClaim = () => {
	const {t} = useTranslation();

	return useMutation<IExportResponse, Error, IExportWarrantyClaimParams>({
		mutationKey: warrantyClaimsQueryKeys.exportOne(),
		mutationFn: params => {
			const {templateId, createDocument, email, objectId, claimId, problems} = params;
			return makeWarrantyClaimExportRequest(
				templateId,
				createDocument,
				email,
				objectId,
				claimId,
				problems
			);
		},
		onSuccess: response => {
			if (response.warnings?.itemsLimit !== undefined) {
				addWarningToast({
					title: `${t('toast.export.warning.title')} (${String(
						response.warnings.itemsLimit
					)})`
				});
			}
			if (response.link) {
				addExportToast({
					title: t('toast.export.success.title'),
					url: response.link,
					fileName: response.file.name,
					document: response.document
				});
			}
		},
		onError: () => {
			addErrorToast({title: t('toast.export.error.title')});
		}
	});
};
