import useScrollRestoration from '@src/core/hooks/useScrollRestoration';
import {useIsDesktop} from '@tehzor/ui-components/src/utils/mediaQueries';
import {useEffect} from 'react';
import {useParams} from 'react-router-dom';
import './DocumentPage.less';
import {Desktop} from './components/Desktop';
import {PageBreadcrumbs} from './components/PageBreadcrumbs';
import {useAppHeader} from '@src/components/AppHeader/hooks/useAppHeader';
import {useDocumentsPermissions} from '@src/core/hooks/permissions/useDocumentsPermissions';
import {useDocument} from '@src/core/hooks/queries/documents/hooks';
import {formDocumentTitle} from '@tehzor/tools/utils/formDocumentTitle';
import {addErrorToast} from '@tehzor/ui-components';
import {useTranslation} from 'react-i18next';

export const DocumentPage = () => {
	useScrollRestoration();
	const {t} = useTranslation();
	const {documentId} = useParams<{documentId: string}>();
	const {data: document} = useDocument(documentId);
	useAppHeader(
		{
			title: document ? formDocumentTitle(document) : undefined,
			showBackBtn: true
		},
		[document]
	);
	const isDesktop = useIsDesktop();

	const {canView} = useDocumentsPermissions();

	useEffect(() => {
		if (!canView) {
			addErrorToast({title: t('toast.canView.error.title')});
		}
	}, [canView]);

	if (!canView) {
		return null;
	}

	return (
		<div className="page-cont document-page">
			<PageBreadcrumbs document={document} />

			{isDesktop ? <Desktop document={document} /> : <Desktop document={document} />}
		</div>
	);
};
