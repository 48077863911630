import * as React from 'react';
import useAsyncFn from 'react-use/lib/useAsyncFn';
import {useUpdateContent} from '@src/components/EditableAuthorizedPerson/hooks/useUpdateContent';
import {useTranslation} from 'react-i18next';
import {useTranslatedConfirmDialog} from '@src/core/hooks/translations/useTranslatedConfirmDialog';

export function useAuthorizedPersonDelete(
	authorizedPersonId: string
): [React.ReactNode, () => void] {
	const {t} = useTranslation();
	const {removeAuthorizedPerson} = useUpdateContent({});

	const [deleteDialog, getDeleteConfirmation] = useTranslatedConfirmDialog({
		title: t('useConfirmDialog.authorizedPerson.deleteTitle'),
		message: t('useConfirmDialog.authorizedPerson.deleteMessage')
	});

	const [, handleDelete] = useAsyncFn(async () => {
		if (await getDeleteConfirmation()) {
			await removeAuthorizedPerson({authorizedPersonId});
		}
	}, [authorizedPersonId]);

	return [deleteDialog, handleDelete];
}
