import useAppSelector from '@src/core/hooks/useAppSelector';
import {useStructureProblems} from './useStructureProblems';
import {IGetListProblemsResponse} from '@src/api/backend/problems';
import {useCallback} from 'react';
import {extractStructureProblemsEntity} from '../selectors/extractStructureProblemsEntity';

export const useStructureProblemsEntities = (objectId: string, structureId: string) => {
	const visibility = useAppSelector(state => state.settings.pages.structure.entitiesVisibility);
	const select = useCallback(
		(data: IGetListProblemsResponse) => extractStructureProblemsEntity(data, visibility),
		[visibility]
	);
	return useStructureProblems(objectId, structureId, select);
};
