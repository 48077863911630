import ChecksFiltersPage from '@src/pages/filters/ChecksFiltersPage';
import CreatedByFilterPage from '@src/pages/filters/CreatedByFilterPage';
import CreationDateFilterPage from '@src/pages/filters/CreationDateFilterPage';
import {ObjectsFilterPage} from '@src/pages/filters/ObjectsFilterPage';

export const checksFilterRoutes = [
	{path: 'objects', element: <ObjectsFilterPage />},
	{
		path: 'createdBy',
		element: <CreatedByFilterPage />
	},
	{
		path: 'creationdate',
		element: <CreationDateFilterPage />
	},
	{
		path: '',
		element: <ChecksFiltersPage />
	}
];
