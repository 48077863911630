export type IFieldSettingsSetsDetailsKey = [] & {
	0: string;
	1: string;
	2: string;
};

export const fieldSettingsSetsQueryKeys = {
	all: () => ['field-settings-sets'],
	one: () => ['field-settings-set'],
	lists: () => [...fieldSettingsSetsQueryKeys.all(), 'list'],
	list: () => [...fieldSettingsSetsQueryKeys.lists()],
	localLists: () => [...fieldSettingsSetsQueryKeys.all(), 'local-list'],
	localList: () => [...fieldSettingsSetsQueryKeys.localLists()],
	delete: () => [...fieldSettingsSetsQueryKeys.all(), 'delete'],
	details: () => [...fieldSettingsSetsQueryKeys.one(), 'detail'],
	detail: (setId: string) => [...fieldSettingsSetsQueryKeys.one(), 'detail', setId],
	edit: () => [...fieldSettingsSetsQueryKeys.one(), 'edit'],
	add: () => [...fieldSettingsSetsQueryKeys.one(), 'add']
};
