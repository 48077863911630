import {BaseSkeleton} from '@tehzor/ui-components';
import styles from './Main.module.less';

export const MainSkeleton = () => (
	<div className={styles.wrapper}>
		<BaseSkeleton
			width="100%"
			height="70vh"
		/>
		<BaseSkeleton
			width="100%"
			height="70vh"
		/>
	</div>
);
