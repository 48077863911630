import {
	DndContext,
	DndList,
	IDndEvent,
	IDndRenderItemProps,
	InlineButton
} from '@tehzor/ui-components';
import {Dispatch, useCallback, useMemo} from 'react';
import styles from './FieldValuesCreator.module.less';

import {IEditableEntityAction} from '@tehzor/tools/core/states/editableEntityState';
import {RenderItem} from './components/RenderItem/RenderItem';
import {IValueItem} from './interfaces';

interface IFieldValuesCreatorProps<S, E> {
	values?: string[];
	editingDispatch: Dispatch<IEditableEntityAction<S, E>>;
	field: keyof S;
}

const iconPlus = <i className="tz-plus-20" />;

export const FieldValuesCreator = <S, E>({
	values,
	editingDispatch,
	field
}: IFieldValuesCreatorProps<S, E>) => {
	const preparedValues = useMemo(() => {
		if (!values) return [];
		return values.map((value, i) => ({
			id: String(Math.floor(Math.random() * 10000)),
			order: i,
			value
		}));
	}, [values]);

	const addValueHandler = useCallback(() => {
		if (!values) {
			editingDispatch({type: 'update', field, value: ['']});
		} else {
			editingDispatch({type: 'add-in-array', field, value: ['']});
		}
	}, [editingDispatch, field, values]);

	const changeValueHandler = useCallback(
		(value: string, index: number) => {
			editingDispatch({type: 'update-array-item', field, value, index});
		},
		[editingDispatch, field]
	);

	const deleteValueHandler = useCallback(
		(index: number) => {
			editingDispatch({type: 'delete-array-item', field, index: [index]});
		},
		[editingDispatch, field]
	);

	const dragEndHandler = useCallback(
		({items}: IDndEvent<IValueItem>) => {
			const newOrderValues = items.map(({value}) => value);
			editingDispatch({type: 'update', field, value: newOrderValues});
		},
		[editingDispatch, field]
	);

	const renderItem = useCallback(
		({item}: IDndRenderItemProps<IValueItem>) => (
			<RenderItem
				onSave={changeValueHandler}
				onDelete={deleteValueHandler}
				item={item}
			/>
		),
		[changeValueHandler, deleteValueHandler]
	);

	return (
		<div>
			<p>Поля для выбора:</p>
			<DndContext selector=".overlay">
				<DndList
					className={{
						item: styles.item
					}}
					items={preparedValues}
					renderItem={renderItem}
					onDragEnd={dragEndHandler}
					container="values-items-list"
					draggableIds={preparedValues.map(value => value.id)}
				/>
			</DndContext>
			<InlineButton
				type="accent"
				leftIcon={iconPlus}
				label="Добавить поле"
				onClick={addValueHandler}
			/>
		</div>
	);
};
