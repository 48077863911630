import {IWorkAcceptance} from '@tehzor/tools/interfaces/workAcceptances/IWorkAcceptance';
import {wsConnector} from '../wsConnector';

export type IEditWorkAcceptanceSubmittersResponse = IWorkAcceptance;

/**
 * Изменяет передающих приёмки работ
 *
 * @param objectId id объекта
 * @param workAcceptanceId id приёмки работ
 * @param submitters пользователи
 * @param submittersActiveGroup текущая группа
 */
export const makeWorkAcceptanceSubmittersEditRequest = (
	objectId: string,
	workAcceptanceId: string,
	submitters?: string[],
	submittersActiveGroup?: string
) =>
	wsConnector.sendAuthorizedRequest<IEditWorkAcceptanceSubmittersResponse>(
		'editWorkAcceptanceSubmitters',
		{
			objectId,
			workAcceptanceId,
			submitters,
			submittersActiveGroup
		}
	);
