import {forwardRef, memo, Ref, useCallback, useEffect, useImperativeHandle} from 'react';
import {useEditableWarrantyClaim} from '@src/components/EditableWarrantyClaim/hooks/useEditableWarrantyClaim';
import {ISavingWarrantyClaim} from '@src/interfaces/saving/ISavingWarrantyClaim';
import {IWarrantyClaim} from '@tehzor/tools/interfaces/warrantyClaims/IWarrantyClaim';
import {AddingEntities} from '@src/api/mutations';
import {useAddWarrantyClaim} from '@src/core/hooks/mutations/warrantyClaims/useAddWarrantyClaim';
import {useObject} from '@src/core/hooks/queries/objects/hooks';
import {useTranslatedConfirmDialog} from '@src/core/hooks/translations/useTranslatedConfirmDialog';

interface IAddingWarrantyClaimProps {
	objectId: string;
	spaceId?: string;
	links?: IWarrantyClaim['links'];
	saving: boolean;

	onClose: () => void;
	setSaving: (s: boolean) => void;
}

export interface IAddingWarrantyClaimRefProps {
	getSavingData: (useLocalFiles?: boolean) => Promise<ISavingWarrantyClaim | undefined>;
	save: (savingData?: ISavingWarrantyClaim) => Promise<string | undefined | void> | string | void;
	saveCache?: (
		savingData?: ISavingWarrantyClaim,
		extraLinks?: IWarrantyClaim['links']
	) => Promise<
		| {
				cacheKey: string;
				type: AddingEntities;
				links: IWarrantyClaim['links'];
		  }
		| undefined
	>;
	cancel: () => void;
}

const AddingWarrantyClaim = (
	{spaceId, objectId, links, saving, onClose, setSaving}: IAddingWarrantyClaimProps,
	ref?: Ref<IAddingWarrantyClaimRefProps>
) => {
	const {data: object} = useObject(objectId);
	const addWarrantyClaim = useAddWarrantyClaim(object);
	const [fields, getSavingData, , isBlocking] = useEditableWarrantyClaim(
		objectId,
		spaceId,
		undefined,
		saving
	);

	useEffect(() => () => setSaving(false), [setSaving]);

	const save = useCallback(
		async (savingData?: ISavingWarrantyClaim) => {
			let id: string | undefined;

			setSaving(true);
			if (savingData) {
				try {
					const warrantyClaim = await addWarrantyClaim({
						objectId,
						links,
						fields: savingData
					});
					id = warrantyClaim.id;
				} catch (error) {
					setSaving(false);
					throw error;
				}
			}
			return id;
		},
		[objectId, links, addWarrantyClaim, setSaving]
	);

	const [closingDialog, getClosingConfirmation] = useTranslatedConfirmDialog();

	const cancel = useCallback(async () => {
		if (!isBlocking || (await getClosingConfirmation())) {
			onClose();
		}
	}, [getClosingConfirmation, isBlocking, onClose]);

	useImperativeHandle(ref, () => ({save, cancel, getSavingData}), [save, cancel, getSavingData]);

	return (
		<>
			{fields}
			{closingDialog}
		</>
	);
};

export default memo(
	forwardRef<IAddingWarrantyClaimRefProps, IAddingWarrantyClaimProps>(AddingWarrantyClaim)
);
