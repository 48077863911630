import {getScrollContainer} from '@tehzor/tools/utils/getScrollContainer';
import {RefObject, useEffect, useRef, useState} from 'react';

export const useStickyBrowserScrollBar = (
	tableWrapperRef: RefObject<HTMLDivElement | null>,
	scrollbarRef: RefObject<HTMLDivElement | null>
) => {
	const [isCustomScrollbarVisible, setIsCustomScrollbarVisible] = useState(true);
	const customScrollWidth = useRef(0);

	// обработка прокрутки и определение видимости кастомного скролла

	useEffect(() => {
		const handler = () => {
			const table = tableWrapperRef.current?.children[0]?.children[0];
			const containerRect = tableWrapperRef.current?.children[0].getBoundingClientRect();
			const viewportHeight = window.innerHeight;

			if (!containerRect || !table) return;

			customScrollWidth.current = table.getBoundingClientRect().width;
			setIsCustomScrollbarVisible(containerRect.bottom > viewportHeight);
		};

		const scrollContainer = getScrollContainer('scroll-container');
		if (!scrollContainer) return;

		scrollContainer.addEventListener('scroll', handler);
		handler();

		return () => scrollContainer.removeEventListener('scroll', handler);
	}, [tableWrapperRef]);

	// синхронизация прокрутки между табличным и кастомным скроллом

	useEffect(() => {
		const tableWrapper = tableWrapperRef.current?.children[0];
		const horizontalScrollbar = scrollbarRef.current;
		if (!tableWrapper || !horizontalScrollbar) return;

		const syncScroll = () => {
			horizontalScrollbar.scrollLeft = tableWrapper.scrollLeft;
		};

		const syncScrollBack = () => {
			tableWrapper.scrollLeft = horizontalScrollbar.scrollLeft;
		};

		tableWrapper.addEventListener('scroll', syncScroll);
		horizontalScrollbar.addEventListener('scroll', syncScrollBack);

		return () => {
			tableWrapper.removeEventListener('scroll', syncScroll);
			horizontalScrollbar.removeEventListener('scroll', syncScrollBack);
		};
	}, [tableWrapperRef, scrollbarRef]);

	return {isCustomScrollbarVisible, customScrollWidth: customScrollWidth.current};
};
