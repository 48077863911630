import {useCallback} from 'react';
import {ExportDialog} from '@src/components/ExportDialog';
import IExportTemplate, {
	ExportTemplatesDestinationId
} from '@tehzor/tools/interfaces/IExportTemplate';
import {Button, IconButton} from '@tehzor/ui-components';
import useToggle from 'react-use/lib/useToggle';
import {useIsDesktop} from '@tehzor/ui-components/src/utils/mediaQueries';
import {useExportCheckListsMutation} from '@src/core/hooks/mutations/checkLists/useExportCheckListsMutation';
import {useTranslation} from 'react-i18next';

const availableDestinations = [ExportTemplatesDestinationId.CHECKLISTS];

interface ICheckListsExportDialog {
	objectId: string;
	spaceId: string;
	checkListsIds: string[];
}

const CheckListsExportDialog = (props: ICheckListsExportDialog) => {
	const {t} = useTranslation();
	const {objectId, spaceId, checkListsIds} = props;
	const [isOpen, toggleOpen] = useToggle(false);
	const {mutate: exportCheckLists} = useExportCheckListsMutation();

	const exportLists = useCallback(
		(template: IExportTemplate, email?: string, createDocument?: boolean) => {
			exportCheckLists({
				templateId: template.id,
				email,
				createDocument,
				selected: [{spaceId, objectId, checkListsIds}]
			});
		},
		[exportCheckLists, objectId, spaceId, checkListsIds, toggleOpen]
	);
	const isDesktop = useIsDesktop();

	return (
		<>
			{isDesktop ? (
				<Button
					type="accent-blue"
					leftIcon={<i className="tz-export-20" />}
					label={t('checkListPage.actions.checkListsExportDialog.button.label')}
					onClick={toggleOpen}
				/>
			) : (
				<IconButton onClick={toggleOpen}>
					<i className="tz-export-24" />
				</IconButton>
			)}
			<ExportDialog
				pageKey="check-lists"
				destinations={availableDestinations}
				isOpen={isOpen}
				onExport={exportLists}
				onClose={toggleOpen}
			/>
		</>
	);
};

export default CheckListsExportDialog;
