import {useMemo} from 'react';
import {formSpaceLink} from '@tehzor/tools/utils/links';
import {formSpaceTitle} from '@tehzor/tools/utils/formSpaceTitle';
import {useObjectsData} from '@src/core/hooks/queries/objects/hooks';
import {IBreadcrumb} from '@tehzor/ui-components/src/components/navigation/Breadcrumbs';
import {useTranslation} from 'react-i18next';
import {formPresaleCheckLink} from '@tehzor/tools/utils/links/formPresaleCheckLink';
import {formObjectsChain} from '@src/core/hooks/breadcrumbs/useObjectBreadcrumbs';
import {useExtractSpaceTypesById} from '@src/core/hooks/queries/spaceTypes/hooks';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {usePresaleCheck} from '../cache/queries/PresaleCheck/hooks/usePresaleCheck';

/**
 * Хук для хлебных крошек от "всех объектов" до предпродажной проверки
 *
 * @param objectId id конечного объекта
 * @param presaleCheckId id предпродажной проверки
 */
export const usePresaleCheckBreadcrumbs = (objectId: string, presaleCheckId: string) => {
	const {t} = useTranslation();
	const objects = useObjectsData();
	const {data: spaceTypes} = useExtractSpaceTypesById();
	const {altNamesVisible} = useAppSelector(s => s.settings.pages.spaces.shared);

	const {data: presaleCheck} = usePresaleCheck(presaleCheckId);

	return useMemo(() => {
		const items: IBreadcrumb[] = objects
			? formObjectsChain(objects.byId, objectId, 'problems', t)
			: [];
		if (!presaleCheck) return items;

		if (presaleCheck.space) {
			items.push({
				label: formSpaceTitle(
					presaleCheck.space.name,
					presaleCheck.space.altName,
					spaceTypes?.[presaleCheck.space.type],
					altNamesVisible
				),
				url: formSpaceLink(objectId, presaleCheck.space.id),
				inactive: false
			});
		} else {
			items.push({
				label: `${t('usePresaleCheckBreadcrumbs.pluralName')}`,
				url: `/objects/${objectId}/presale-checks`,
				inactive: false
			});
		}
		items.push({
			label: `${t('usePresaleCheckBreadcrumbs.singularName')} №${presaleCheck.number}`,
			url: formPresaleCheckLink(objectId, presaleCheck.id),
			inactive: false
		});
		return items;
	}, [objectId, presaleCheck, objects, spaceTypes, t, altNamesVisible]);
}
