import {useMemo} from 'react';
import {SelectionActionsPresaleCheck} from '../SelectionActionsPresaleCheck';
import {ActionsMobile} from '../ActionsMobile';
import {IPresaleCheck} from '@tehzor/tools/interfaces/presaleChecks/IPresaleCheck';
import {usePresaleCheckEntities} from '@/entities/PresaleCheck';

interface IMobileRightButtonsProps {
	objectId: string;
	presaleCheckId: string;
	presaleCheck?: IPresaleCheck;
	selectedRows: string[];
}

export const RightButtonsMobile = ({
	objectId,
	presaleCheckId,
	presaleCheck,
	selectedRows
}: IMobileRightButtonsProps) => {
	const entities = usePresaleCheckEntities(objectId, presaleCheckId);

	const selectedEntities = useMemo(
		() => entities.filter(item => selectedRows?.includes(item.id)),
		[selectedRows, entities]
	);

	if (!presaleCheck) {
		return null;
	}
	if (selectedRows && selectedRows.length > 0) {
		return (
			<SelectionActionsPresaleCheck
				objectId={objectId}
				selectedEntities={selectedEntities}
				presaleCheck={presaleCheck}
				presaleCheckId={presaleCheckId}
			/>
		);
	}
	return (
		<ActionsMobile
			objectId={objectId}
			presaleCheck={presaleCheck}
		/>
	);
};
