import {useChangePath} from '@src/core/hooks/useChangePath';
import ICheck from '@tehzor/tools/interfaces/checks/ICheck';
import * as React from 'react';
import {useCallback} from 'react';
import {useDeleteCheck} from '@src/core/hooks/mutations/checks/useDeleteCheck';
import {useTranslation} from 'react-i18next';
import {useTranslatedConfirmDialog} from '@src/core/hooks/translations/useTranslatedConfirmDialog';

export function useDeleteCheckConfirmDialog(
	objectId: string,
	check: ICheck
): [React.ReactNode, () => void] {
	const {t} = useTranslation();

	const {goBack} = useChangePath();

	const {mutate: deleteCheck} = useDeleteCheck();
	const [deleteDialog, getDeleteConfirmation] = useTranslatedConfirmDialog({
		title: t('checksPage.actions.menu.delete.dialogTitle'),
		message: `${t('checksPage.actions.menu.delete.dialogBody')} №${check.number}?`
	});

	const handleDelete = useCallback(async () => {
		if (await getDeleteConfirmation()) {
			deleteCheck({objectId, checkId: check.id});
			goBack();
		}
	}, [getDeleteConfirmation, deleteCheck, objectId, check.id, goBack]);

	return [deleteDialog, handleDelete];
}
