import {useMemo} from 'react';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {testWorkAcceptanceRestrictions} from '@src/utils/testRestrictions';
import {IWorkAcceptance} from '@tehzor/tools/interfaces/workAcceptances/IWorkAcceptance';

export interface IPermissions {
	canViewWorkAcceptances?: boolean;
}

/**
 * Возвращает полномочия для текущего пользователя
 *
 * @param objectId id объекта
 * @param workAcceptance приёмка работ
 */
export const usePermissions = (
	objectId: string,
	workAcceptance?: IWorkAcceptance
): IPermissions => {
	const user = useAppSelector(s => s.auth.profile);

	return useMemo(() => {
		if (!user || !user.roles) {
			return {};
		}
		return {
			canViewWorkAcceptances: testWorkAcceptanceRestrictions(
				user.id,
				user.roles,
				'work-acceptances-view',
				objectId,
				workAcceptance?.createdBy?.id,
				workAcceptance?.acceptors,
				workAcceptance?.acceptorsActiveGroupLeader,
				workAcceptance?.watchers,
				workAcceptance?.submitters
			),
			canEdit: testWorkAcceptanceRestrictions(
				user.id,
				user.roles,
				'work-acceptances-edit',
				objectId,
				workAcceptance?.createdBy?.id,
				workAcceptance?.acceptors,
				workAcceptance?.acceptorsActiveGroupLeader,
				workAcceptance?.watchers,
				workAcceptance?.submitters
			)
		};
	}, [objectId, user]);
};
