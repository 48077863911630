import {httpRequests} from '@tehzor/tools/api/httpRequests';
import {ISavingStructure} from '@tehzor/tools/interfaces/structures/ISavingStructure';
import {IStructure} from '@tehzor/tools/interfaces/structures/IStructure';

export type IAddStructureResponse = IStructure;

/**
 * Добавляет структуру
 *
 * @param objectId id объекта
 * @param fields данные структуры
 */
export const makeStructureAddRequest = async (objectId: string, fields: ISavingStructure) => {
	const response = await httpRequests.withToken.post<IAddStructureResponse>('structures', {
		objectId,
		...fields
	});
	return response.data;
};
