import {useMemo, CSSProperties} from 'react';
import {changeEntitiesVisibility} from '@/entities/PresaleCheck';
import VisibilityFilter from '@src/components/VisibilityFilter';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {useTranslation} from 'react-i18next';
import {extractPresaleCheckVisibility} from '@/entities/PresaleCheck';
import {useIsDesktop} from '@tehzor/ui-components/src/utils/mediaQueries';

interface IEntitiesVisibilityProps {
	className?: string;
	style?: CSSProperties;
}

export const EntitiesVisibility = ({className, style}: IEntitiesVisibilityProps) => {
	const {t} = useTranslation();
	const visibility = useAppSelector(extractPresaleCheckVisibility);
	const isDesktop = useIsDesktop();

	const visibilityTitles = useMemo(
		() => ({
			problems: t('presaleChecksPage.actions.visibility.problems'),
			inspections: t('presaleChecksPage.actions.visibility.inspections')
		}),
		[t]
	);

	return (
		<VisibilityFilter
			className={className}
			style={style}
			value={visibility}
			titlesMap={visibilityTitles}
			onChange={changeEntitiesVisibility}
			isMobileLabel={!isDesktop}
		/>
	);
};
