import {Reducer, useReducer} from 'react';
import {errorsFns, IEditablePresaleCheckAction, IEditablePresaleCheckState, init} from './state';
import {createReducer} from '@tehzor/tools/core/states/editableEntityState';
import {IPresaleCheck} from '@tehzor/tools/interfaces/presaleChecks/IPresaleCheck';

export const useEditablePresaleCheckState = (presaleCheck?: IPresaleCheck) =>
	useReducer<
		Reducer<IEditablePresaleCheckState, IEditablePresaleCheckAction>,
		IPresaleCheck | undefined
	>(createReducer(init, errorsFns), presaleCheck, init);
