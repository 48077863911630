import {memo} from 'react';
import ILocation from '@tehzor/tools/interfaces/ILocation';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {useGlobalAddingEntityDialog} from '@src/components/GlobalAddingEntityDialog/hooks/useGlobalAddingEntityDialog';
import {useChecksPermissions} from '@src/core/hooks/permissions/useChecksPermissions';
import {useOwnerAcceptancesPermissions} from '@src/core/hooks/permissions/useOwnerAcceptancesPermissions';
import {useWarrantyClaimsPermissions} from '@src/core/hooks/permissions/useWarrantyClaimsPermissions';
import {useInternalAcceptancesPermissions} from '@src/core/hooks/permissions/useInternalAcceptancesPermissions';
import {Button, FloatingActionButton, MenuItem} from '@tehzor/ui-components';
import {useSpacesPermissions} from '@src/core/hooks/permissions/useSpacesPermissions';
import {useAddingSpaceTaskDialog} from '@src/pages/SpacePage/hooks/useAddingSpaceTaskDialog';
import {ISpace} from '@tehzor/tools/interfaces/spaces/ISpace';
import MenuLayer from '@tehzor/ui-components/src/components/menu/MenuLayer';
import {BeforeDesktop, Desktop} from '@tehzor/ui-components/src/utils/mediaQueries';
import {useTranslation} from 'react-i18next';
import {useStagesAndProcessesMap} from '@src/core/hooks/processes/useProcessesMap';
import {usePresaleChecksPermissions} from '@/entities/PresaleCheck/model/hooks/usePresaleChecksPermissions';

interface IOwnerAcceptanceAddingProps {
	objectId: string;
	spaceId?: string;
	stage: ObjectStageIds;
	space?: ISpace;
	defaultData?: {
		planId?: string | null;
		location?: ILocation | null;
		floor?: string | null;
	};
}

const plusIcon = <i className="tz-plus-20" />;
const problemIcon = <i className="tz-problem-24" />;
const taskIcon = <i className="tz-task-24" />;

export const GlobalEntityAdding = memo(
	({objectId, spaceId, stage, defaultData, space}: IOwnerAcceptanceAddingProps) => {
		const checksPermissions = useChecksPermissions(objectId, 'space');
		const ownersAcceptancesPermissions = useOwnerAcceptancesPermissions(objectId, 'space');
		const warrantyClaimPermissions = useWarrantyClaimsPermissions(objectId, 'space');
		const internalAcceptancePermissions = useInternalAcceptancesPermissions(objectId, 'space');
		const presaleChecksPermissions = usePresaleChecksPermissions(objectId, 'space');

		const spacePermissions = useSpacesPermissions(objectId);
		const stagesAndProcessesMap = useStagesAndProcessesMap();
		const defaultProcessId = stagesAndProcessesMap[stage][0];
		const {t} = useTranslation();

		const permissions = {
			checks: checksPermissions.canAdd,
			acceptances: ownersAcceptancesPermissions.canAdd,
			claims: warrantyClaimPermissions.canAdd,
			internalAcceptances: internalAcceptancePermissions.canAdd,
			presaleChecks: presaleChecksPermissions.canAdd
		};

		const [addingTaskDialog, openAddingTaskDialog] = useAddingSpaceTaskDialog(space);

		const [dialog, openDialog] = useGlobalAddingEntityDialog(
			objectId,
			spaceId,
			{spaceId},
			stage,
			defaultProcessId,
			permissions,
			defaultData
		);

		const items = [];

		if (
			checksPermissions.canAdd ||
			ownersAcceptancesPermissions.canAdd ||
			warrantyClaimPermissions.canAdd ||
			internalAcceptancePermissions.canAdd ||
			presaleChecksPermissions.canAdd
		) {
			items.push(
				<MenuItem
					key="add-problem"
					icon={problemIcon}
					onClick={openDialog}
				>
					{t('spacePage.addButton.addProblem')}
				</MenuItem>
			);
		}
		if (spacePermissions.canAddTask) {
			items.push(
				<MenuItem
					key="add-task"
					icon={taskIcon}
					onClick={openAddingTaskDialog}
				>
					{t('spacePage.addButton.addTask')}
				</MenuItem>
			);
		}

		return (
			<>
				<BeforeDesktop>
					<MenuLayer
						trigger={
							<FloatingActionButton
								icon={<i className="tz-plus-16" />}
								label={t('spacePage.addButton.label')}
								minifyOnScroll
								scrollContainer="scroll-container"
							/>
						}
					>
						{items}
					</MenuLayer>
				</BeforeDesktop>
				<Desktop>
					<MenuLayer
						popupArrowVisible
						popupProps={{placement: 'bottom-end'}}
						trigger={
							<Button
								type="accent-blue"
								label={t('spacePage.addButton.label')}
								leftIcon={plusIcon}
							/>
						}
					>
						{items}
					</MenuLayer>
				</Desktop>
				{(checksPermissions.canAdd ||
					ownersAcceptancesPermissions.canAdd ||
					warrantyClaimPermissions.canAdd ||
					internalAcceptancePermissions.canAdd ||
					presaleChecksPermissions.canAdd) &&
					dialog}
				{spacePermissions.canAddTask && addingTaskDialog}
			</>
		);
	}
);
