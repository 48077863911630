import {memo} from 'react';
import {IconButton} from '@tehzor/ui-components';
import {IPreparedLegal} from '../../interfaces/IPreparedLegal';
import {useDeleteLegals} from '@src/core/hooks/mutations/legals/useDeleteLegals';
import {useTranslatedConfirmDialog} from '@src/core/hooks/translations/useTranslatedConfirmDialog';
import {useTranslation} from 'react-i18next';

interface ISelectionActionsProps {
	selectedEntities: IPreparedLegal[];
	onClear: () => void;
}

const deleteIcon = <i className="tz-delete" />;

export const LegalsRowActions = memo(({selectedEntities, onClear}: ISelectionActionsProps) => {
	const {t} = useTranslation();
	const canDelete = selectedEntities.every(item => item.canDelete);
	const {deleteLegals} = useDeleteLegals();

	const [deleteDialog, getDeleteConfirmation] = useTranslatedConfirmDialog({
		title: t('useConfirmDialog.legals.deleteTitle'),
		message: t('useConfirmDialog.legals.deleteMessage')
	});

	const handleDelete = async () => {
		if (await getDeleteConfirmation()) {
			await deleteLegals(selectedEntities);
		}
		onClear();
	};

	if (!canDelete) return null;

	return (
		<>
			<IconButton onClick={handleDelete}>{deleteIcon}</IconButton>

			{deleteDialog}
		</>
	);
});
