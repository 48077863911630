import {ReactNode} from 'react';

export enum ProcessIds {
	OPERATIONAL_CONTROL = 'operational-control',
	ACCEPTANCE_CONTROL = 'acceptance-control',
	INCOMING_MATERIALS_CONTROL = 'incoming-materials-control',
	INCOMING_DOCUMENTATION_CONTROL = 'incoming-documentation-control',
	GEODETIC_CONTROL = 'geodetic-control',
	LABORATORY_CONTROL = 'laboratory-control',
	LABOR_PROTECTION_CONTROL = 'labor-protection-control',
	INTERNAL_ACCEPTANCE = 'internal-acceptance',
	PRESALE_CONTROL = 'presale-control',
	UNITS_HANDOVER = 'units-handover',
	OBJECT_HANDOVER = 'object-handover',
	WARRANTY_SERVICE = 'warranty-service',
	TECHNICAL_SERVICE = 'technical-service',
	AUDIT = 'audit'
}

export interface IProcess {
	id: ProcessIds;
	name: string;
	iconName: string;
	color: string;
	backgroundColor?: string;
}

export interface IProcessWithIcon extends Omit<IProcess, 'iconName'> {
	icon: ReactNode;
	entityIcon: ReactNode;
}
