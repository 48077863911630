import {makeSpaceTypeDecorationSetRequest} from '@src/api/backend/spaceTypeDecorationSets';
import {spaceTypeDecorationSetsQueryKeys} from '@src/api/cache/spaceTypeDecorationsSets/keys';
import {queryClient} from '@src/api/QueryClient';
import {useMutation} from '@tanstack/react-query';
import {ISavingSpaceTypeDecorationSet} from '@tehzor/tools/interfaces/spaceTypeDecorationSets/ISavingSpaceTypeDecorationSet';
import {addErrorToast} from '@tehzor/ui-components';
import {useTranslation} from 'react-i18next';

export const useAddSpaceTypeDecorationSet = () => {
	const {t} = useTranslation();

	return useMutation({
		mutationFn: (params: ISavingSpaceTypeDecorationSet) =>
			makeSpaceTypeDecorationSetRequest(params),
		mutationKey: spaceTypeDecorationSetsQueryKeys.add(),
		onSuccess: () =>
			queryClient.invalidateQueries({
				queryKey: spaceTypeDecorationSetsQueryKeys.lists()
			}),
		onError: () => addErrorToast({title: t('toast.addSpaceTypeDecorationSet.error.title')})
	});
};
