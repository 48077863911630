import {Column} from 'react-table';
import {IPreparedFieldSettingsSet} from '@/shared/types/IPreparedFieldsSettingsSets';
import {MobileCell} from '../../ui/cells/MobileCell/MobileCell';

export const getMobileColumns = (): Array<Column<IPreparedFieldSettingsSet>> => [
	{
		id: 'object',
		Cell: MobileCell
	}
];
