import {Query, QueryFunctionContext, useQueryClient} from '@tanstack/react-query';
import {
	IPresaleCheckDetailQueryKey,
	presaleChecksQueryKeys,
	IPresaleCheckInspectionsQueryKey,
	IPresaleCheckProblemsQueryKey,
	IPresaleCheckProblemRepliesQueryKey,
	IPresaleChecksListQueryKey
} from '../keys';
import {requestGetPresaleCheck} from '@/shared/api/presaleChecks/getPresaleCheck';
import {requestGetPresaleChecks} from '@/shared/api/presaleChecks/getPresaleChecks';
import {requestListInspections} from '@src/api/backend/inspections';
import {requestListProblems} from '@src/api/backend/problems';
import {requestComments} from '@src/api/backend/comments';
import {ICommentTypeId} from '@tehzor/tools/interfaces/comments/ICommentType';
import {getDateDifference} from '@tehzor/tools/utils/dates/getDateDifference';
import {endOfDay} from 'date-fns';
import {IPresaleCheck} from '@tehzor/tools/interfaces/presaleChecks/IPresaleCheck';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractOfflineModeAvailability} from '@src/store/modules/settings/offlineMode/selectors/availability';

export const usePresaleChecksQueryDefaults = () => {
	const queryClient = useQueryClient();
	const isOfflineModeAvailable = useAppSelector(extractOfflineModeAvailability);

	queryClient.setQueryDefaults(presaleChecksQueryKeys.list(), {
		queryFn: async ({queryKey}: QueryFunctionContext<IPresaleChecksListQueryKey>) => {
			const [, , objectId] = queryKey;
			return requestGetPresaleChecks(
				{
					objectIds: [objectId]
				},
				{createdAt: false},
				0,
				1000
			);
		},
		staleTime: 1000 * 60 * 5, // 5 минут
		gcTime: 1000 * 60 * 60 * 24 // 1 День
	});

	queryClient.setQueryDefaults(presaleChecksQueryKeys.latest(), {
		queryFn: () => {
			const date = new Date();
			date.setHours(0, 0, 0, 0);
			return requestGetPresaleChecks({createdAtFrom: date}, {createdAt: false}, 0, 100);
		},
		staleTime: 1000 * 60 * 5, // 5 минут
		gcTime: getDateDifference(new Date(), endOfDay(new Date())) // до конца дня
	});

	queryClient.setQueryDefaults(presaleChecksQueryKeys.details(), {
		queryFn: async ({queryKey}: QueryFunctionContext<IPresaleCheckDetailQueryKey>) => {
			const [, , presaleCheckId] = queryKey;
			return requestGetPresaleCheck(presaleCheckId);
		},
		meta: {
			persist: isOfflineModeAvailable
		},
		staleTime: 1000 * 60 * 5, // 5 минут
		gcTime: 1000 * 60 * 60 * 24 // 1 День
	});

	queryClient.setQueryDefaults(presaleChecksQueryKeys.localList(), {
		queryFn: ({queryKey}) => {
			const [, , objectId] = queryKey;
			const localPresaleChecks = queryClient.getQueriesData<IPresaleCheck>({
				queryKey: presaleChecksQueryKeys.details(),
				predicate: (query: Query<IPresaleCheck>) => {
					const {data} = query.state;
					if (data?.transferStatus) {
						if (!objectId || objectId === 'all') {
							return true;
						}
						if (objectId === data.objectId) {
							return true;
						}
					}
					return false;
				}
			});
			return localPresaleChecks.map(([, presaleCheck]) => presaleCheck);
		},
		staleTime: 0,
		gcTime: 0,
		networkMode: 'offlineFirst',
		enabled: isOfflineModeAvailable
	});

	queryClient.setQueryDefaults(presaleChecksQueryKeys.inspections(), {
		queryFn: async ({queryKey}: QueryFunctionContext<IPresaleCheckInspectionsQueryKey>) => {
			const [, , objectId, presaleCheckId] = queryKey;
			return requestListInspections(
				{
					objects: [objectId],
					presaleChecks: [presaleCheckId]
				},
				{createdAt: false}
			);
		},
		staleTime: 1000 * 60 * 5, // 5 минут
		gcTime: 1000 * 60 * 5 // 5 минут
	});

	queryClient.setQueryDefaults(presaleChecksQueryKeys.problems(), {
		queryFn: async ({queryKey}: QueryFunctionContext<IPresaleCheckProblemsQueryKey>) => {
			const [, , objectId, presaleCheckId] = queryKey;
			return requestListProblems(
				{
					objects: [objectId],
					presaleChecks: [presaleCheckId]
				},
				{createdAt: false}
			);
		},
		staleTime: 1000 * 60 * 5, // 5 минут
		gcTime: 1000 * 60 * 5 // 5 минут
	});

	queryClient.setQueryDefaults(presaleChecksQueryKeys.problemReplies(), {
		queryFn: async ({queryKey}: QueryFunctionContext<IPresaleCheckProblemRepliesQueryKey>) => {
			const [, , objectId, presaleCheckId] = queryKey;

			return requestComments(
				{
					commentTypeId: ICommentTypeId.PROBLEM_COMMENT,
					objects: [objectId],
					presaleChecks: [presaleCheckId],
					official: true
				},
				{createdAt: false},
				0,
				1000
			);
		},
		staleTime: 1000 * 60 * 5, // 5 минут
		gcTime: 1000 * 60 * 5 // 5 минут
	});
};
