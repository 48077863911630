import {createContext, PropsWithChildren, useCallback} from 'react';
import {ITableContextMenuAction} from '@tehzor/tools/interfaces/table/ITableContextMenuAction';
import {IEnrichedProblemTagsSet} from '@tehzor/tools/interfaces/problemTagsSets/IEnrichedProblemTagsSet';
import {useDeleteProblemTagsSet} from '@src/core/hooks/mutations/problemTagsSets/useDeleteProblemTagsSet';
import {useTranslation} from 'react-i18next';
import {useTranslatedConfirmDialog} from '@src/core/hooks/translations/useTranslatedConfirmDialog';

export const DispatchActionCtx = createContext<
	(action: ITableContextMenuAction<IEnrichedProblemTagsSet>) => void
>(() => ({}));

export const MenuActionCtxProvider = ({
	children,
	reloadList
}: PropsWithChildren<{reloadList: () => Promise<any>}>) => {
	const {t} = useTranslation();
	const {mutate: deleteProblemTagsSet} = useDeleteProblemTagsSet();
	const [deleteDialog, getDeleteConfirmation] = useTranslatedConfirmDialog({
		title: t('useConfirmDialog.tagsSet.deleteTitle'),
		message: t('useConfirmDialog.data.deleteMessage')
	});

	const handleContextMenuAction = useCallback(
		async (action: ITableContextMenuAction<IEnrichedProblemTagsSet>) => {
			if (action.type === 'delete' && (await getDeleteConfirmation())) {
				deleteProblemTagsSet(action.payload.id);
				await reloadList();
			}
		},
		[deleteProblemTagsSet, getDeleteConfirmation, reloadList]
	);

	return (
		<DispatchActionCtx.Provider value={handleContextMenuAction}>
			{children}
			{deleteDialog}
		</DispatchActionCtx.Provider>
	);
};
