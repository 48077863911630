import {memo, useEffect, useMemo, useState} from 'react';
import {IChanges} from '@src/components/EntitiesFilters/utils/EntitiesFiltersProvider';
import {useFilterPageTransition} from '../utils/pagesRouting';
import {MobileFilter} from '@tehzor/ui-components';
import {filterEmptyTypeDecorationSets} from '@src/components/editableFields/SpaceTypeDecorationTreeSelect/utils/makeTreeData';
import {flatFilter} from '@tehzor/ui-components/src/components/inputs/select/SelectSearch';
import {useTranslation} from 'react-i18next';
import {TranslatedSelectSearch} from '@src/components/TranslatedSelectSearch';
import {useSpaceTypeDecorationsForSetsAsArray} from '@src/core/hooks/queries/spaceTypeDecorations/hooks';
import {useExtractSpaceTypeDecorationSetsForObjectAsArray} from '@src/core/hooks/queries/SpaceTypeDecorationSets/hooks';
import {findExtraElement} from '../utils/findExtraElement';

interface ISpaceTypesFilterMobileProps {
	objectId?: string;
	value?: string[];
	entity: string;

	onChange: (changes: IChanges) => void;
}

export const SpaceTypeDecorationFilterMobile = memo(
	({objectId, value, entity, onChange}: ISpaceTypesFilterMobileProps) => {
		const {t} = useTranslation();
		const [selected, setSelected] = useState([] as string[]);
		const [search, setSearch] = useState('');
		const {data: typeDecorationsSets} =
			useExtractSpaceTypeDecorationSetsForObjectAsArray(objectId);
		const typeDecorationSetIds = typeDecorationsSets?.map(set => set.id) || [];

		const {data: typeDecorationsAsArray} =
			useSpaceTypeDecorationsForSetsAsArray(typeDecorationSetIds);

		const notEmptyTypeDecorationsAndSets = useMemo(() => {
			if (!typeDecorationsSets) return null;

			const notEmptySets = filterEmptyTypeDecorationSets(
				typeDecorationsAsArray,
				typeDecorationsSets
			);
			if (!typeDecorationsAsArray) return notEmptySets;
			return [...notEmptySets, ...typeDecorationsAsArray];
		}, [typeDecorationsAsArray, typeDecorationsSets]);

		const filteredElements = useMemo(() => {
			if (!notEmptyTypeDecorationsAndSets) return null;
			return flatFilter(notEmptyTypeDecorationsAndSets, 'name', search);
		}, [notEmptyTypeDecorationsAndSets, search]);

		useEffect(() => {
			setSelected(value || []);
		}, [value]);

		const handleChange = (v: string[] | undefined) => {
			let typeDecoration = [] as string[];
			const {id: extraElement, isAdded} = findExtraElement(selected, v || []);

			let isSet = false;
			isSet = typeDecorationSetIds.includes(extraElement);
			const typeDecorationsAsArrayIds = typeDecorationsAsArray?.map(decor => decor.id) || [];
			const currentSetId = isSet
				? extraElement
				: typeDecorationsAsArray?.find(decor => decor.id === extraElement)
						?.typeDecorationSetId;

			if (v && typeDecorationsAsArray && currentSetId) {
				if (!isSet) {
					typeDecoration = v;
					if (
						typeDecorationsAsArrayIds.length === v.length &&
						!v.includes(currentSetId)
					) {
						typeDecoration.push(currentSetId);
					}
					if (v.length <= typeDecorationsAsArrayIds.length && v.includes(currentSetId)) {
						typeDecoration = v.filter(id => id !== currentSetId);
					}
				}

				if (isSet && isAdded) {
					typeDecoration = [...typeDecorationsAsArrayIds, currentSetId];
				}
			}
			setSelected(typeDecoration);
			onChange({typeDecoration});
		};

		const handleExpand = useFilterPageTransition(objectId, entity, 'typeDecoration');

		if (!filteredElements) return null;

		return (
			<MobileFilter
				label={t('spacesFiltersPage.spaceTypeDecorationFilterMobile.mobileFilter.label')}
				elements={filteredElements}
				selected={selected}
				onChange={handleChange}
				onExpand={handleExpand}
				linkBtnLabel={t(
					'spacesFiltersPage.spaceTypeDecorationFilterMobile.mobileFilter.onExpandButtonLabel'
				)}
				search={
					<TranslatedSelectSearch
						value={search}
						onChange={setSearch}
						placeholder={t(
							'spacesFiltersPage.spaceTypeDecorationFilterMobile.mobileFilter.selectSearch.placeholder'
						)}
					/>
				}
			/>
		);
	}
);
