import {IconMenu, MenuItem} from '@tehzor/ui-components';
import useAsyncFn from 'react-use/lib/useAsyncFn';
import {deleteProblem} from '@src/store/modules/pages/problem/actions';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {useEditableProblemDialog} from '@src/components/EditableProblemDialog/hooks/useEditableProblemDialog';
import {useProblemHistoryDialog} from '@src/components/ProblemHistoryDialog/hooks/useProblemHistoryDialog';
import {useAppUpdateMenuItem} from '@src/components/AppUpdateData';
import {useIsDesktop} from '@tehzor/ui-components/src/utils/mediaQueries';
import {IPermissions} from '../hooks/usePermissions';
import {IPreparedProblem} from '../interfaces/IPreparedProblem';
import {objectsQueryKeys} from '@src/api/cache/objects/keys';
import {queryClient} from '@src/api/QueryClient';
import {useTranslation} from 'react-i18next';
import {useTranslatedConfirmDialog} from '@src/core/hooks/translations/useTranslatedConfirmDialog';

interface IMenuProps {
	objectId: string;
	problem: IPreparedProblem;
	permissions: IPermissions;
}

const historyIcon = <i className="tz-history-24" />;
const editIcon = <i className="tz-edit" />;
const deleteIcon = <i className="tz-delete" />;

const Menu = ({objectId, problem, permissions}: IMenuProps) => {
	const {goBack} = useChangePath();
	const dispatch = useAppDispatch();
	const isDesktop = useIsDesktop();
	const {t} = useTranslation();
	// Диалог добавления/редактирования ответа
	const [problemDialog, openEditDialog] = useEditableProblemDialog(objectId, problem);

	// Диалог истории изменений
	const [historyDialog, openHistoryDialog] = useProblemHistoryDialog(objectId, problem.id);
	const [deleteDialog, getDeleteConfirmation] = useTranslatedConfirmDialog({
		title: t('problemPage.menu.deleteConfirmDialog.title'),
		message: `${t('problemPage.menu.deleteConfirmDialog.text')} №${problem?.number}?`
	});

	// Кнопка обновления данных приложения
	const updateData = useAppUpdateMenuItem();

	const [, handleDeleteClick] = useAsyncFn(async () => {
		if (await getDeleteConfirmation()) {
			await dispatch(deleteProblem(objectId, problem.id));
			await queryClient.refetchQueries({queryKey: [...objectsQueryKeys.stats(), [objectId]]});
			goBack();
		}
	}, [objectId, problem.id]);

	const items = [];
	if (!isDesktop) {
		items.push(updateData);
	}

	if (permissions.canViewHistory) {
		items.push(
			<MenuItem
				key="history"
				icon={historyIcon}
				onClick={openHistoryDialog}
			>
				{t('problemPage.menu.button.history')}
			</MenuItem>
		);
	}

	if (permissions.canEdit) {
		items.push(
			<MenuItem
				key="edit"
				icon={editIcon}
				onClick={openEditDialog}
			>
				{t('problemPage.menu.button.edit')}
			</MenuItem>
		);
	}
	if (permissions.canDelete) {
		items.push(
			<MenuItem
				key="delete"
				icon={deleteIcon}
				onClick={handleDeleteClick}
			>
				{t('problemPage.menu.button.delete')}
			</MenuItem>
		);
	}

	return items.length ? (
		<>
			<IconMenu>{items}</IconMenu>

			{permissions.canEdit && problemDialog}
			{permissions.canDelete && deleteDialog}
			{permissions.canViewHistory && historyDialog}
		</>
	) : null;
};

export default Menu;
