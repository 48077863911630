import IUser from '@tehzor/tools/interfaces/IUser';
import {IBriefUser} from '@tehzor/tools/interfaces/users/IBriefUser';
import {OfflineDataTransferStatus} from '@tehzor/tools/contracts/dataTransferWebWorker/interfaces/IOfflineDataTransferStatuses';
import {IPresaleCheck} from '@tehzor/tools/interfaces/presaleChecks/IPresaleCheck';
import {IObject} from '@tehzor/tools/interfaces/objects/IObject';

export const toPresaleCheck = (
	user: IUser,
	objectId: string,
	links: IPresaleCheck['links'] | undefined,
	key: string,
	nextNumber: number,
	object?: IObject
): IPresaleCheck => {
	const briefUser: IBriefUser = {
		id: user.id,
		fullName: user.fullName,
		displayName: user.displayName
	};

	const newPresaleCheck: IPresaleCheck = {
		id: key,
		localNumber: `АВТ-${nextNumber}`,
		objectId,
		links,
		transferStatus: OfflineDataTransferStatus.SAVED_LOCAL,
		createdAt: Number(key),
		createdBy: briefUser,
		modifiedAt: Number(key),
		modifiedBy: briefUser
	};
	if (object) {
		Object.assign(newPresaleCheck, {object: {id: object.id, name: object.name}});
	}
	return newPresaleCheck;
};
