import {useIsFetching, useQuery} from '@tanstack/react-query';
import IError from '@tehzor/tools/interfaces/IError';
import {problemTagsQueryKeys} from '@src/api/cache/problemTags/keys';
import {IGetProblemTagsResponse} from '@src/api/backend/problemTags';
import {
	extractProblemTagsAsArray,
	extractProblemTagsById,
	extractProblemTagsForSetAsArray,
	extractProblemTagsForSetsAsArray
} from './selectors';
import {useCallback} from 'react';
import {useProblemTagsSetPermissions} from '@src/core/hooks/permissions/useProblemTagsSetPermissions';

export const useProblemTags = <T = IGetProblemTagsResponse>(
	select?: (data: IGetProblemTagsResponse) => T
) => {
	const permissions = useProblemTagsSetPermissions();
	return useQuery<IGetProblemTagsResponse, IError, T>({
		queryKey: problemTagsQueryKeys.list(),
		meta: {
			error: 'при загрузке меток нарушений'
		},
		enabled: permissions.canView,
		select
	});
};

export const useProblemTagsIsLoading = () =>
	!!useIsFetching({queryKey: problemTagsQueryKeys.list()});

export const useExtractProblemTagsById = () => useProblemTags(extractProblemTagsById);

export const useExtractProblemTagsAsArray = () => useProblemTags(extractProblemTagsAsArray);

export const useExtractProblemTagsForSetAsArray = (problemTagsSetId?: string) => {
	const selector = useCallback(
		(data: IGetProblemTagsResponse) => extractProblemTagsForSetAsArray(data, problemTagsSetId),
		[problemTagsSetId]
	);
	return useProblemTags(selector);
};

export const useExtractProblemTagsForSetsAsArray = (problemTagsSetIds: string[]) => {
	const selector = useCallback(
		(data: IGetProblemTagsResponse) =>
			extractProblemTagsForSetsAsArray(data, problemTagsSetIds),
		[problemTagsSetIds]
	);
	return useProblemTags(selector);
};
