import {queryClient} from '@src/api/QueryClient';
import {deleteMutation} from '@src/cache/utils/deleteMutation';
import {deleteLinkedLocalProblems} from '../problem/deleteLinkedLocalProblems';
import {deleteLinkedLocalInspections} from '../inspection/deleteLinkedLocalInspections';
import {presaleChecksQueryKeys} from '@/entities/PresaleCheck';

export const deleteLocalPresaleCheck = async (presaleCheckId: string) => {
	queryClient.removeQueries({queryKey: presaleChecksQueryKeys.detail(presaleCheckId)});
	await queryClient.refetchQueries({
		queryKey: presaleChecksQueryKeys.localList()
	});

	await deleteLinkedLocalProblems('presaleCheckId', presaleCheckId);
	await deleteLinkedLocalInspections('presaleCheckId', presaleCheckId);
	deleteMutation(presaleCheckId);
};
