import useAppSelector from '@src/core/hooks/useAppSelector';
import {useCallback} from 'react';
import {extractStructureInspectionsEntity} from '../selectors/extractStructureInspectionsEntity';
import {useStructureInspections} from './useStructureInspections';
import {IGetListInspectionsResponse} from '@src/api/backend/inspections';

export const useStructureInspectionsEntities = (objectId: string, structureId: string) => {
	const visibility = useAppSelector(state => state.settings.pages.structure.entitiesVisibility);
	const select = useCallback(
		(data: IGetListInspectionsResponse) => extractStructureInspectionsEntity(data, visibility),
		[visibility]
	);
	return useStructureInspections(objectId, structureId, select);
};
