import {createContext, PropsWithChildren, useState} from 'react';
import {ITableContextMenuAction} from '@tehzor/tools/interfaces/table/ITableContextMenuAction';
import useAsyncFn from 'react-use/lib/useAsyncFn';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {deletePlan} from '@src/store/modules/entities/plan/actions';
import IPlan from '@tehzor/tools/interfaces/plans/IPlan';
import {usePlanMovingDialog} from '../hooks/usePlanMovingDialog';
import {useTranslatedConfirmDialog} from '@src/core/hooks/translations/useTranslatedConfirmDialog';
import {useTranslation} from 'react-i18next';

export const DispatchActionCtx = createContext<(action: ITableContextMenuAction<IPlan>) => void>(
	() => ({})
);

export const MenuActionCtxProvider = ({children}: PropsWithChildren) => {
	const {t} = useTranslation();
	const dispatch = useAppDispatch();
	const [deleteDialog, getDeleteConfirmation] = useTranslatedConfirmDialog({
		title: t('useConfirmDialog.plan.deleteTitle'),
		message: t('useConfirmDialog.plan.deleteMessage')
	});

	const [plan, setPlan] = useState<IPlan>();
	const [copyDialog, setCopyDialogOpen] = usePlanMovingDialog(plan);

	const [, handleContextMenuAction] = useAsyncFn(
		async (action: ITableContextMenuAction<IPlan>) => {
			if (action.type === 'delete' && (await getDeleteConfirmation())) {
				await dispatch(deletePlan(action.payload.objectId, action.payload.id));
			}
			if (action.type === 'copy') {
				setPlan(action.payload);
				setCopyDialogOpen(true);
			}
		}
	);

	return (
		<DispatchActionCtx.Provider value={handleContextMenuAction}>
			{children}
			{deleteDialog}
			{plan && copyDialog}
		</DispatchActionCtx.Provider>
	);
};
