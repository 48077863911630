import {IUserRole} from '@tehzor/tools/interfaces/IUser';
import {hasPermission} from '@tehzor/tools/utils/findPermission';

type unknownFunc = () => unknown;

export interface IResourceMapType {
	[permission: string]: Array<{actions: unknownFunc[]; offline?: boolean}> | undefined;
	all?: Array<{actions: unknownFunc[]; offline?: boolean}>;
}

export const getResources = (
	resourceMap: IResourceMapType,
	roles: IUserRole[],
	networkStatus = true,
	isOfflineModeAvailable?: boolean
) => {
	const promises: Array<() => unknown> = [];

	for (const permission of Object.keys(resourceMap)) {
		const resourceData = resourceMap[permission];
		const resources = resourceData?.reduce((acc: unknownFunc[], item) => {
			if (!networkStatus && item.offline && isOfflineModeAvailable) {
				acc.push(...item.actions);
			}
			if (networkStatus) {
				acc.push(...item.actions);
			}
			return acc;
		}, []);
		if (hasPermission(roles, permission) || permission === 'all') {
			promises.push(...(resources || []));
		}
	}

	return promises;
};
