export enum offlineJournalActionType {
	PROBLEM_ADD = 'Добавление нарушения из реестра',
	PROBLEM_SPACE_ADD = 'Добавление нарушения по помещению',
	PROBLEM_CHECKLIST_ADD = 'Добавление нарушения из чек-листа',
	INSPECTION_ADD = 'Добавление осмотра из реестра',
	INSPECTION_SPACE_ADD = 'Добавление осмотра по помещению',
	INSPECTION_CHECKLIST_ADD = 'Добавление осмотра из чек-листа',
	CHECK_ADD = 'Добавление проверки',
	PRESALE_CHECK_ADD = 'Добавление предпродажной проверки',
	INTERNAL_ACCEPTANCE_ADD = 'Добавление внутренней приёмки',
	OWNER_ACCEPTANCE_ADD = 'Добавление передачи собственнику',
	WARRANTY_CLAIM_ADD = 'Добавление гарантийного обращения',
	SPACE_OWNER_ADD = 'Добавление собственника помещения',
	CHECK_LIST_STATUS_CHANGE = 'Изменение статуса чек-листа',
	CHECK_ITEM_STATUS_CHANGE = 'Изменение статуса категории чек-листа',
	SPACE_INDICATORS_CHANGE = 'Изменение индикатора помещения',
	SPACE_STATUS_CHANGE = 'Изменение статуса помещения'
}
