import {useCallback, useState} from 'react';
import {ActionButtons, Button, Dialog} from '@tehzor/ui-components';
import './AddingRespUserDialog.less';
import {useEditableRespUser} from '@src/components/EditableRespUser/hooks/useEditableRespUser';
import {IAddingRespUser} from '@tehzor/tools/interfaces/users/IAddingRespUser';
import {useObjectStagesMap} from '@src/core/hooks/queries/objectStages/hooks';
import {useCategories} from '@src/core/hooks/queries/categories/hook';
import {useTranslation} from 'react-i18next';
import {useAddRespUser} from '@src/core/hooks/mutations/users/useAddRespUser';
import {useTranslatedConfirmDialog} from '@src/core/hooks/translations/useTranslatedConfirmDialog';

interface IAddingProblemResponsibleDialogProps {
	isOpen: boolean;
	objectId: string;
	defaultData?: IAddingRespUser;

	close(): void;
}

export const AddingRespUserDialog = ({
	isOpen,
	close,
	objectId,
	defaultData
}: IAddingProblemResponsibleDialogProps) => {
	const {t} = useTranslation();
	const [saving, setSaving] = useState(false);
	const {data: stages} = useObjectStagesMap();
	const {data: categoriesAll} = useCategories();
	const [respUserFields, getSavingData, reset, isBlocking] = useEditableRespUser({
		saving,
		objectId,
		defaultData
	});
	const {mutateAsync: addResponsible} = useAddRespUser();

	const handleSave = useCallback(async () => {
		setSaving(true);
		const savingData = getSavingData();
		try {
			if (savingData) {
				const {categories, ...rest} = savingData;
				// Временный код для отправки Имен вместо Id на почту
				const sendingData: IAddingRespUser = {...rest};

				if (categories && categoriesAll) {
					sendingData.categories = categories.map(id => categoriesAll.byId[id]?.name);
				}

				await addResponsible({...sendingData, objectId});

				reset();
				close();
			}
		} finally {
			setSaving(false);
		}
	}, [getSavingData, categoriesAll, stages]);

	const [closingDialog, getClosingConfirmation] = useTranslatedConfirmDialog();

	const cancel = useCallback(async () => {
		if (!isBlocking || (await getClosingConfirmation())) {
			close();
		}
	}, [isBlocking, close]);

	return (
		<Dialog
			className={{root: 'adding-resp-user', content: 'adding-resp-user__container'}}
			isOpen={isOpen}
			title={t('addingRespUserDialog.dialog.title')}
			footer={
				<ActionButtons>
					<Button
						type="cancel"
						label={t('actionButtons.button.cancel')}
						disabled={saving}
						onClick={cancel}
					/>
					<Button
						type="accent-blue"
						label={t('actionButtons.button.save')}
						disabled={saving}
						onClick={handleSave}
					/>
				</ActionButtons>
			}
			fullScreenOnTablet
			onRequestClose={cancel}
			onAfterClose={reset}
		>
			{respUserFields}
			{closingDialog}
		</Dialog>
	);
};
