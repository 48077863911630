import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractCheckRecordByItemId} from '@src/store/modules/pages/checkLists/selectors/records';
import {ReactNode, useCallback, useMemo} from 'react';
import {useAddingCheckDialog} from '@src/components/AddingCheckDialog/hooks/useAddingCheckDialog';
import {checkChangedRecords} from '@src/store/modules/pages/checkLists/actions/records/checkChangedRecords';
import {formSpaceLocationForEntities} from '@src/utils/formSpaceLocationForEntities';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {useAddingInternalAcceptanceDialog} from '@src/components/AddingInternalAcceptanceDialog/hooks/useAddingInternalAcceptanceDialog';
import {transformLocations} from '../utils/transformLocations';
import {useExtractCheckListById} from '@src/core/hooks/queries/checkLists/hooks';
import {useExtractCheckItemById} from '@src/core/hooks/queries/checkItems/hooks';
import {useShape} from '@src/core/hooks/queries/plans/hooks';
import {useSpace} from '@src/core/hooks/queries/space';
import {LastEntityAddedFromCheckList} from '@src/interfaces/LastEntityAddedFromCheckList';
import {checkListsActions} from '@src/store/modules/settings/pages/checkLists/slice';
import {onlineManager} from '@tanstack/react-query';
import {useAddingPresaleCheckDialog} from '@/features/PresaleCheck/hooks/useAddingPresaleCheckDialog';
import {ProcessIds} from '@tehzor/tools/interfaces/process/ProcessId';

export const useAddingCheckListInspectionDialog = (itemId: string): [ReactNode, () => void] => {
	const {objectId, workAcceptanceId, spaceId, listId} = useStrictParams<{
		objectId: string;
		spaceId?: string;
		workAcceptanceId?: string;
		stage: ObjectStageIds;
		listId: string;
		itemId: string;
	}>();

	const {data: space} = useSpace(spaceId, objectId);
	const [planId, sectorId] = transformLocations(space?.locations);
	const {data: sector} = useShape(objectId, planId, sectorId);
	const {data: checkList} = useExtractCheckListById(listId);
	const {data: checkItem} = useExtractCheckItemById(itemId);
	const checkRecord = useAppSelector(s => extractCheckRecordByItemId(s, itemId));
	const dispatch = useAppDispatch();
	const {changeLastAddedEntity} = checkListsActions;

	const [addingCheckDialog, openCheckDialog] = useAddingCheckDialog({
		objectId,
		links: {
			spaceId,
			checkItemId: itemId,
			checkListId: listId,
			checkRecordId: checkRecord?.id,
			workAcceptanceId
		},
		stage: checkList?.stage,
		types: ['inspection'],
		defaultInspectionData: space ? formSpaceLocationForEntities(space, sector) : undefined,
		onSuccess: () => {
			void dispatch(
				changeLastAddedEntity({lastAddedEntity: LastEntityAddedFromCheckList.INSPECTION})
			);
			if (checkItem && onlineManager.isOnline()) {
				void dispatch(checkChangedRecords([checkItem.checkListId], objectId, spaceId));
			}
		}
	});

	const handleOpenCheckDialog = useCallback(() => {
		openCheckDialog();
	}, [openCheckDialog]);

	const [addingInternalAcceptanceDialog, openInternalAcceptanceDialog] =
		useAddingInternalAcceptanceDialog({
			objectId,
			links: {
				spaceId,
				checkItemId: itemId,
				checkListId: listId,
				checkRecordId: checkRecord?.id
			},
			stage: checkList?.stage,
			types: ['inspection'],
			defaultInspectionData: space ? formSpaceLocationForEntities(space, sector) : undefined,
			onSuccess: () => {
				void dispatch(
					changeLastAddedEntity({
						lastAddedEntity: LastEntityAddedFromCheckList.INSPECTION
					})
				);
				if (checkItem && onlineManager.isOnline()) {
					void dispatch(checkChangedRecords([checkItem.checkListId], objectId, spaceId));
				}
			}
		});

	const handleOpenInternalAcceptanceDialog = useCallback(() => {
		openInternalAcceptanceDialog();
	}, [openInternalAcceptanceDialog]);

	const [addingPresaleCheckDialog, openPresaleCheckDialog] = useAddingPresaleCheckDialog({
		objectId,
		links: {
			spaceId,
			checkItemId: itemId,
			checkListId: listId,
			checkRecordId: checkRecord?.id
		},
		stage: checkList?.stage,
		types: ['inspection'],
		defaultProblemData: space ? formSpaceLocationForEntities(space, sector) : undefined,
		onSuccess: () => {
			void dispatch(
				changeLastAddedEntity({lastAddedEntity: LastEntityAddedFromCheckList.INSPECTION})
			);
			if (checkItem && onlineManager.isOnline()) {
				void dispatch(checkChangedRecords([checkItem.checkListId], objectId, spaceId));
			}
		}
	});

	const handleOpenPresaleCheckDialog = useCallback(() => {
		openPresaleCheckDialog();
	}, [openPresaleCheckDialog]);

	const [dialog, handleOpenDialog] = useMemo(() => {
		if (
			checkList?.stage === ObjectStageIds.ACCEPTANCE &&
			checkList?.processId === ProcessIds.PRESALE_CONTROL
		) {
			return [addingPresaleCheckDialog, handleOpenPresaleCheckDialog];
		}
		if (checkList?.stage === ObjectStageIds.ACCEPTANCE) {
			return [addingInternalAcceptanceDialog, handleOpenInternalAcceptanceDialog];
		}
		return [addingCheckDialog, handleOpenCheckDialog];
	}, [
		checkList?.stage,
		checkList?.processId,
		addingInternalAcceptanceDialog,
		handleOpenInternalAcceptanceDialog,
		addingCheckDialog,
		handleOpenCheckDialog,
		addingPresaleCheckDialog,
		handleOpenPresaleCheckDialog
	]);
	return [dialog, handleOpenDialog];
};
