import {IconMenu, MenuItem} from '@tehzor/ui-components';
import useAsyncFn from 'react-use/lib/useAsyncFn';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {IOwnerAcceptance} from '@tehzor/tools/interfaces/ownerAcceptances/IOwnerAcceptance';
import {useOwnerAcceptancesPermissions} from '@src/core/hooks/permissions/useOwnerAcceptancesPermissions';
import {useDeleteOwnerAcceptance} from '@src/core/hooks/mutations/ownerAcceptances/useDeleteOwnerAcceptance';
import {useTranslation} from 'react-i18next';
import {useTranslatedConfirmDialog} from '@src/core/hooks/translations/useTranslatedConfirmDialog';

interface IDesktopMenuProps {
	ownerAcceptance: IOwnerAcceptance;
}

const DesktopMenu = ({ownerAcceptance}: IDesktopMenuProps) => {
	const {t} = useTranslation();
	const {goBack} = useChangePath();
	const permissions = useOwnerAcceptancesPermissions(ownerAcceptance.objectId);

	const [deleteDialog, getDeleteConfirmation] = useTranslatedConfirmDialog({
		title: t('ownerAcceptancePage.actions.menu.useConfirmDialog.title'),
		message: `${t('ownerAcceptancePage.actions.menu.useConfirmDialog.message')} №${
			ownerAcceptance.number
		}?`
	});

	const {mutateAsync: deleteOwnerAcceptance} = useDeleteOwnerAcceptance();

	const [, handleDeleteClick] = useAsyncFn(async () => {
		if (await getDeleteConfirmation()) {
			await deleteOwnerAcceptance({
				objectId: ownerAcceptance.objectId,
				acceptanceId: ownerAcceptance.id
			});
			goBack();
		}
	}, [ownerAcceptance.objectId, ownerAcceptance.id]);

	const items = [];

	if (permissions.canDelete) {
		items.push(
			<MenuItem
				key="delete"
				icon={<i className="tz-delete" />}
				onClick={handleDeleteClick}
			>
				{t('ownerAcceptancePage.actions.menu.menuItem.delete.text')}
			</MenuItem>
		);
	}

	return items.length ? (
		<>
			<IconMenu>{items}</IconMenu>
			{permissions.canDelete && deleteDialog}
		</>
	) : null;
};

export default DesktopMenu;
