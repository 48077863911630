import {memo, useCallback} from 'react';
import {useObject} from '@src/core/hooks/queries/objects/hooks';
import {BoardItem} from '@/widgets/StructuresSchema/ui/BoardItem/BoardItem';
import {formStructureLink} from '@tehzor/tools/utils/links';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {useIsDesktop} from '@/shared/hooks/dimensions/useIsDesktop/useIsDesktop';
import {Skeleton} from '@/widgets/StructuresSchema/ui/ObjectBoard/Skeleton/Skeleton';
import {useSchemaItemsByWorkAcceptancesAndProblems} from '@/widgets/StructuresSchema/model/hooks/useSchemaItemsByWorkAcceptancesAndProblems';
import {useMeasure} from 'react-use';
import {Board} from '@tehzor/ui-components';

interface IObjectBoardProps {
	objectId: string;
	isMultipleObjects?: boolean;
}

export const ObjectBoard = memo(({objectId, isMultipleObjects}: IObjectBoardProps) => {
	const isDesktop = useIsDesktop();
	const {pushPath} = useChangePath();
	const {data: object} = useObject(objectId);

	const {
		data: boardItems,
		isLoading,
		total
	} = useSchemaItemsByWorkAcceptancesAndProblems(objectId);

	const goToStructure = useCallback(
		(structureId: string) => pushPath(formStructureLink(objectId, structureId)),
		[objectId, pushPath]
	);

	const goToObject = useCallback(() => {
		if (isMultipleObjects) {
			pushPath(`/objects/${objectId}/structures`);
		}
	}, [objectId, pushPath]);

	const [ref, {width}] = useMeasure<HTMLDivElement>();

	return (
		<Board
			ref={ref}
			title={object?.name}
			counter={total}
			showHeader={isDesktop}
			onTitleClick={isMultipleObjects ? goToObject : undefined}
		>
			{isLoading ? (
				<Skeleton width={width} />
			) : (
				boardItems &&
				boardItems.map(([key, value], i) => (
					<BoardItem
						title={key}
						diagrams={value}
						onClick={goToStructure}
						key={i}
					/>
				))
			)}
		</Board>
	);
});
