import {useMutation} from '@tanstack/react-query';
import {tasksQueryKeys} from '@src/api/cache/tasks/keys';
import {
	IMakeTaskStatusEditRequestData,
	makeTaskStatusEditRequest
} from '@src/api/backend/task/editTaskStatus';
import {queryClient} from '@src/api/QueryClient';
import {addErrorToast} from '@tehzor/ui-components';
import {useTranslation} from 'react-i18next';

export const useEditTaskStatus = () => {
	const {t} = useTranslation();

	return useMutation({
		mutationKey: tasksQueryKeys.edit(),
		mutationFn: (data: IMakeTaskStatusEditRequestData) => makeTaskStatusEditRequest(data),
		onError: () => addErrorToast({title: t('toast.editTaskStatus.error.title')}),
		onSuccess: data => {
			queryClient.setQueryData(tasksQueryKeys.detail(data.id), task =>
				task ? {...task, ...data} : data
			);
			void queryClient.invalidateQueries({queryKey: tasksQueryKeys.all()});
		}
	});
};
