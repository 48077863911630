export enum WorkingGroupTypeId {
	PERFORMERS = 'performers',
	INSPECTORS = 'inspectors',
	ACCEPTORS = 'acceptors',
	SUBMITTERS = 'submitters',
	ALL = 'all'
}

export interface IWorkingGroupType {
	id: WorkingGroupTypeId;
	name: string;
}
