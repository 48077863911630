import {oneArgIsTrue} from '@/shared/utils/oneArgIsTrue';
import {fieldSettingsQueryKeys} from '@/entities/FieldSettings';
import {useCallback} from 'react';
import {prepareCustomFields} from '../../utils/prepareCustomFields';
import {useQueries, UseQueryResult} from '@tanstack/react-query';
import {ICustomField, IFieldSetting} from '@tehzor/tools/interfaces/fields';
import {IGetListFieldSettingsResponse} from '@/shared/api/fieldSettings/getFieldSettingsList';
import {extractFieldSettingsListBySetIds} from '@/entities/FieldSettings/model/cache/queries/selectors/extractFieldSettingsListBySetIds';
import {extractCustomFieldsAsArray, fieldsQueryKeys} from '@/entities/Fields';

export const usePreparedCustomFields = (setId: string) => {
	const settingsSelector = useCallback(
		(data: IGetListFieldSettingsResponse) => extractFieldSettingsListBySetIds(data, [setId]),
		[setId]
	);

	const combine = useCallback(
		(data: [UseQueryResult<ICustomField[]>, UseQueryResult<IFieldSetting[] | undefined>]) => {
			const [fieldsData, fieldsSettingsData] = data;
			const {data: fields, isLoading: fieldsIsLoading} = fieldsData;
			const {data: fieldsSettings, isLoading: fieldsSettingsIsLoading} = fieldsSettingsData;
			const preparedCustomFields =
				!fields || !fieldsSettings
					? undefined
					: prepareCustomFields(fields, fieldsSettings, setId);
			return {
				data: preparedCustomFields,
				isLoading: oneArgIsTrue(fieldsIsLoading, fieldsSettingsIsLoading)
			};
		},
		[setId]
	);

	return useQueries({
		queries: [
			{
				queryKey: fieldsQueryKeys.customLists(),
				select: extractCustomFieldsAsArray
			},
			{
				queryKey: fieldSettingsQueryKeys.list(),
				select: settingsSelector
			}
		],
		combine
	});
};
