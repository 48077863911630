import {forwardRef, Ref, useCallback, useImperativeHandle} from 'react';
import {useEditableMeterConsumption} from '@src/components/EditableMeterConsumption/hooks/useEditableMeterConsumption';
import {useAddMeterConsumption} from '@src/core/hooks/mutations/meterConsumptions/useAddMeterConsumption';
import {useTranslatedConfirmDialog} from '@src/core/hooks/translations/useTranslatedConfirmDialog';

interface IAddingMeterConsumptionProps {
	companyId?: string;
	meterId?: string;
	saving: boolean;

	onClose: () => void;
	setSaving: (s: boolean) => void;
}

export interface IAddingMeterConsumptionRefProps {
	save: () => void;
	cancel: () => void;
}

export const AddingMeterConsumption = forwardRef<
	IAddingMeterConsumptionRefProps,
	IAddingMeterConsumptionProps
>((props: IAddingMeterConsumptionProps, ref?: Ref<IAddingMeterConsumptionRefProps>) => {
	const {companyId, meterId, saving, onClose, setSaving} = props;
	const addMeterConsumption = useAddMeterConsumption();

	const [fields, getSavingData, , isBlocking] = useEditableMeterConsumption(undefined, saving);

	const save = useCallback(() => {
		setSaving(true);
		if (companyId && meterId) {
			const savingData = getSavingData();
			if (savingData) {
				try {
					savingData.meterId = meterId;
					savingData.companyId = companyId;
					addMeterConsumption(savingData);
					onClose();
				} catch (error) {
					setSaving(false);
					throw error;
				}
			}
		}
		setSaving(false);
	}, [companyId, meterId, getSavingData]);

	const [closingDialog, getClosingConfirmation] = useTranslatedConfirmDialog();

	const cancel = useCallback(async () => {
		if (!isBlocking || (await getClosingConfirmation())) {
			onClose();
		}
	}, [isBlocking, onClose]);

	useImperativeHandle(ref, () => ({save, cancel}), [save, cancel]);

	return (
		<>
			{fields}
			{closingDialog}
		</>
	);
});
