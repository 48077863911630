import {IBuiltinField, IFieldSetting} from '@tehzor/tools/interfaces/fields';
import {IPreparedField} from '@/features/Fields';
import {getPreparedBuiltInField} from './getPreparedBuiltInField';

export const prepareBuiltInField = (
	fields: IBuiltinField[],
	fieldsSettings: IFieldSetting[],
	setId: string
): IPreparedField[] =>
	fields.map(field => {
		const fieldSetting = fieldsSettings.find(({fieldId}) => fieldId === field.id);
		return getPreparedBuiltInField(setId, field, fieldSetting);
	});
