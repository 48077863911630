import {memo} from 'react';
import {CellProps} from 'react-table';
import styles from './TypeCell.module.less';
import {IBuiltinField} from '@tehzor/tools/interfaces/fields';
import {useFieldEntityTypeById} from '@/entities/Fields';

export const TypeCell = memo(({row}: CellProps<IBuiltinField>) => {
	const {data: entityType} = useFieldEntityTypeById(row.original.entityTypeId);

	if (!entityType) return null;

	return <p className={styles.title}>{entityType.name}</p>;
});
