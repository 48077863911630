import {httpRequests} from '@tehzor/tools/api/httpRequests';
import {IStructure} from '@tehzor/tools/interfaces/structures/IStructure';

export type IGetStructureResponse = IStructure;

/**
 * Получает структуру по id
 *
 * @param objectId id объекта
 * @param structureId id структуры
 */

export const requestStructure = async (objectId: string, structureId: string) => {
	const response = await httpRequests.withToken.get<IGetStructureResponse>(
		`structures/${structureId}`,
		{params: {objectId}}
	);
	return response.data;
};
