import {memo, ReactNode} from 'react';
import {Button} from '@tehzor/ui-components';
import {useFiltersActive} from '../hooks/useFiltersActive';
import {useTranslation} from 'react-i18next';
import styles from './EntitiesFilters.module.less';
import {useEntitiesFiltersCtx} from '../hooks/useEntitiesFiltersCtx';

interface IEntitiesFiltersProps {
	objectId: string;
	children: ReactNode;
}

export const EntitiesFilters = memo(({children, objectId}: IEntitiesFiltersProps) => {
	const {t} = useTranslation();
	const {clear, state} = useEntitiesFiltersCtx();
	const isFiltersActive = useFiltersActive(objectId, state);
	return (
		<div className={styles.wrapper}>
			{children}
			{isFiltersActive && (
				<Button
					type="cancel"
					label={t('entitiesFilters.cancelButton.label')}
					onClick={clear}
				/>
			)}
		</div>
	);
});
