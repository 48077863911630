import {
	IConvertCustomFieldDate,
	IConvertCustomFieldSelect,
	ICustomFieldSetting,
	ValueTypeId
} from '@tehzor/tools/interfaces/fields';
import {memo} from 'react';
import {useEntitiesFiltersCtx} from '@src/components/EntitiesFilters/utils/entitiesFiltersCtx';
import {ICustomFiltersData} from '@tehzor/tools/interfaces/fields/ICustomFiltersValues';
import {convertCustomFields} from '@src/components/CustomFilters/helpers/convertCustomFields';
import {CustomFilterSelectMobile} from '@src/components/CustomFilters/Components/mobile/CustomFilterSelectMobile';
import {CustomFilterDateMobile} from '@src/components/CustomFilters/Components/mobile/CustomFilterDateMobile';
import {useCustomFiltersHandlers} from '@src/components/CustomFilters/hooks/useCustomFiltersHandlers';
import {useCustomFieldsMap} from '@/entities/Fields';

interface ICustomFiltersProps {
	settings: Record<string, ICustomFieldSetting>;
	filtersData?: ICustomFiltersData;
	entity: string;
	objectId: string;
}

export const CustomFiltersMobile = memo(
	({settings, filtersData, entity, objectId}: ICustomFiltersProps) => {
		const {change} = useEntitiesFiltersCtx();
		const {data: customFields} = useCustomFieldsMap();
		const convertedFields = convertCustomFields(settings, customFields);

		const [onChange, onClear] = useCustomFiltersHandlers(change, filtersData);

		const renderFilters = () =>
			convertedFields.map(fieldData => {
				const filterData = filtersData?.[fieldData.key];
				switch (fieldData.valueTypeId) {
					case ValueTypeId.SELECT:
						return (
							<CustomFilterSelectMobile
								objectId={objectId}
								entity={entity}
								fieldData={fieldData as IConvertCustomFieldSelect}
								filterValue={
									filterData && filterData.valueTypeId === ValueTypeId.SELECT
										? filterData.value
										: undefined
								}
								onChange={onChange}
								onClear={onClear}
								key={fieldData.id}
							/>
						);
					case ValueTypeId.DATE:
						return (
							<CustomFilterDateMobile
								objectId={objectId}
								entity={entity}
								fieldData={fieldData as IConvertCustomFieldDate}
								filterValue={
									filterData && filterData.valueTypeId === ValueTypeId.DATE
										? filterData.value
										: undefined
								}
								key={fieldData.id}
							/>
						);
					default:
						return null;
				}
			});

		return <>{renderFilters().map(filter => filter)}</>;
	}
);
