import {requestGetFieldEntityTypes, requestGetFieldValueTypes} from '@src/api/backend/fieldTypes';
import {useQueryClient} from '@tanstack/react-query';
import {requestGetCustomFieldsList} from '@/shared/api/fields/getCustomFieldsList';
import {requestGetBuiltinFieldsList} from '@/shared/api/fields/getBuiltInFieldsList';
import {fieldsQueryKeys} from '@/entities/Fields';

export const useFieldsQueryDefaults = () => {
	const queryClient = useQueryClient();

	// Получение списка пользовательских полей
	queryClient.setQueryDefaults(fieldsQueryKeys.customLists(), {
		networkMode: 'offlineFirst',
		queryFn: requestGetCustomFieldsList,
		staleTime: 1000 * 60 * 60 * 24 * 7 * 2, // 2 недели
		gcTime: 1000 * 60 * 60 * 24 * 7 * 2 // 2 недели
	});

	// Получение списка встроенных полей
	queryClient.setQueryDefaults(fieldsQueryKeys.builtinLists(), {
		networkMode: 'offlineFirst',
		queryFn: requestGetBuiltinFieldsList,
		staleTime: 1000 * 60 * 60 * 24 * 7 * 2, // 2 недели
		gcTime: 1000 * 60 * 60 * 24 * 7 * 2 // 2 недели
	});

	// Получение списка типов сущностей для полей
	queryClient.setQueryDefaults(fieldsQueryKeys.types.entityTypes(), {
		networkMode: 'offlineFirst',
		queryFn: requestGetFieldEntityTypes,
		staleTime: 1000 * 60 * 60 * 24 * 7 * 2, // 2 недели
		gcTime: 1000 * 60 * 60 * 24 * 7 * 2 // 2 недели
	});

	// Получение списка типов значений для полей
	queryClient.setQueryDefaults(fieldsQueryKeys.types.valueTypes(), {
		networkMode: 'offlineFirst',
		queryFn: requestGetFieldValueTypes,
		staleTime: 1000 * 60 * 60 * 24 * 7 * 2, // 2 недели
		gcTime: 1000 * 60 * 60 * 24 * 7 * 2 // 2 недели
	});
};
