import {PresaleCheckPage} from './PresaleCheckPage';
import {useStrictParams} from '@src/core/hooks/useStrictParams';

/**
 * Обёртка над страницей предпродажной проверки,
 * необходимо для того чтобы при переходе от одной проверки к другой отрабатывали все методы жизненного цикла
 *
 * @constructor
 */
export const PresaleCheckPageWrap = () => {
	const {presaleCheckId} = useStrictParams<{presaleCheckId: string}>();

	return <PresaleCheckPage key={presaleCheckId} />;
};
