import {convertSortParam} from '@tehzor/tools/utils/convertSortParam';
import {wsConnector} from '../wsConnector';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {IListWorkAcceptance} from '@tehzor/tools/interfaces/workAcceptances/IListWorkAcceptance';

export interface IGetWorkAcceptancesResponse extends INormalizedData<IListWorkAcceptance> {
	offset: number;
	limit: number;
	total: number;
}

/**
 * Возвращает пагинированный список приёмок работ
 */
export const requestPaginateWorkAcceptances = (
	filters?: Record<string, unknown>,
	sort?: Record<string, boolean>,
	offset?: number,
	limit?: number,
	objectId?: string
) => {
	return wsConnector.sendAuthorizedRequest<IGetWorkAcceptancesResponse>('getWorkAcceptances', {
		filters,
		sort: convertSortParam(sort),
		offset,
		limit,
		objectId
	});
};
