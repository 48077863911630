import {IGetListProblemsResponse} from '@src/api/backend/problems';
import {StructureEntitiesVisibility} from '@src/store/modules/settings/pages/structure/reducers/entitiesVisibility';
import {IStructureEntity} from '@src/interfaces/IStructureEntity';
import {extractStructureProblems} from './extractStructureProblems';
import {convert} from '../../../../utils/convertEntity';

/**
 * Извлекает нарушения с учётом фильтра
 */

export const extractStructureProblemsEntity = (
	data: IGetListProblemsResponse,
	visibility: StructureEntitiesVisibility[]
) => {
	const result: IStructureEntity[] = [];
	const problems = extractStructureProblems(data);
	const problemsVisible = visibility.includes('problems');
	if (problemsVisible) {
		for (const problem of problems) {
			if (problem.links) {
				result.push(convert(problem, 'problem'));
			}
		}
	}

	return result;
};
