import {checkListsQueryKeys} from '@src/api/cache/checkLists/keys';
import {checksQueryKeys} from '@src/api/cache/checks/keys';
import {inspectionsQueryKeys} from '@src/api/cache/inspections/keys';
import {internalAcceptancesQueryKeys} from '@src/api/cache/internalAcceptances/keys';
import {problemsQueryKeys} from '@src/api/cache/problems/keys';
import {oneArgIsTrue} from '@/shared/utils/oneArgIsTrue';
import {useExportMutating} from '@src/components/Processing/hooks/useExportMutating';
import {ProcessingIcon} from '@tehzor/ui-components';

export const Processing = () => {
	const checkListsExport = useExportMutating(checkListsQueryKeys.export());
	const checkListExport = useExportMutating(checkListsQueryKeys.exportOne());
	const checkExport = useExportMutating(checksQueryKeys.exportOne());
	const checksExport = useExportMutating(checksQueryKeys.export());
	const inspectionsExport = useExportMutating(inspectionsQueryKeys.export());
	const inspectionExport = useExportMutating(inspectionsQueryKeys.exportOne());
	const internalAcceptancesExport = useExportMutating(internalAcceptancesQueryKeys.export());
	const internalAcceptanceExport = useExportMutating(internalAcceptancesQueryKeys.exportOne());
	const problemsExport = useExportMutating(problemsQueryKeys.export());

	const inProcess = oneArgIsTrue(
		checkListsExport,
		checkListExport,
		checkExport,
		checksExport,
		inspectionsExport,
		inspectionExport,
		internalAcceptancesExport,
		internalAcceptanceExport,
		problemsExport
	);

	if (!inProcess) return null;

	return <ProcessingIcon />;
};
