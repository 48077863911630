import {UseQueryOptions} from '@tanstack/react-query';
import IError from '@tehzor/tools/interfaces/IError';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractUserId} from '@src/store/modules/auth/profile/selectors';
import {useCallback} from 'react';
import {useFetchLatestUserPresaleChecks} from './useFetchLatestUserPresaleChecks';
import {IGetPresaleChecksResponse} from '@/shared/api/presaleChecks/getPresaleChecks';
import {extractLatestPresaleChecksAsArray} from '../selectors/extractLatestPresaleChecksAsArray';
import {IPresaleCheck} from '@tehzor/tools/interfaces/presaleChecks/IPresaleCheck';

export const useExtractLatestPresaleChecksAsArray = (
	objectId: string,
	spaceId?: string,
	options?: UseQueryOptions<IGetPresaleChecksResponse, IError, IPresaleCheck[]>
) => {
	const userId = useAppSelector(extractUserId);
	const selector = useCallback(
		(data: IGetPresaleChecksResponse) =>
			extractLatestPresaleChecksAsArray(data, userId, objectId, spaceId),
		[userId, objectId, spaceId]
	);
	return useFetchLatestUserPresaleChecks(selector, options);
};
