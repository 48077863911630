import {ICheckRecord} from '@tehzor/tools/interfaces/checkRecords/ICheckRecord';
import {useCallback, useMemo, useState, useEffect} from 'react';
import * as React from 'react';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {useDebounce} from 'react-use';
import {CheckRecordStatusId} from '@tehzor/tools/interfaces/checkRecords/ICheckRecordStatus';
import {preparePercent} from '@src/pages/CheckListPage/utils/preparePercent';
import {TextField} from '@tehzor/ui-components';
import {changePercents} from '@src/store/modules/pages/checkLists/actions/records/changePercents';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {useIsBeforeLargeMobile} from '@tehzor/ui-components/src/utils/mediaQueries';

const arrowIcon = <i className="tz-simple-arrow-16" />;

export const usePercentInput = (
	record: ICheckRecord | undefined,
	objectId: string,
	stage?: ObjectStageIds,
	disabled?: boolean
): [string | undefined, React.ReactElement] => {
	const dispatch = useAppDispatch();
	const [percent, setPercent] = useState<string | undefined>('0');
	const [changed, setChanged] = useState<boolean>(false);
	const isMobile = useIsBeforeLargeMobile();

	useEffect(() => {
		if (record && record.percent) {
			setPercent(record.percent.toString());
		}
	}, [record, setPercent]);

	const handleInputChange = useCallback((value: string) => {
		if ((!isNaN(parseInt(value)) && value.length <= 2) || parseInt(value) === 100) {
			setPercent(value);
			setChanged(true);
		} else if (value === '') {
			setPercent(undefined);
			setChanged(true);
		}
	}, []);

	const handleInputIncrement = useCallback(() => {
		setPercent(val => {
			if (val && !isNaN(parseInt(val)) && parseInt(val) < 100) {
				return `${parseInt(val) + 10}`;
			}
			return val;
		});
		setChanged(true);
	}, []);

	const handleInputDecrement = useCallback(() => {
		setPercent(val => {
			if (val && !isNaN(parseInt(val)) && parseInt(val) > 0) {
				return `${parseInt(val) - 10}`;
			}
			return val;
		});
		setChanged(true);
	}, []);

	useDebounce(
		() => {
			if (record?.status === CheckRecordStatusId.PARTIALLY_ACCEPTED && changed && stage) {
				void dispatch(changePercents(objectId, record.id, stage, preparePercent(percent)));
			}
		},
		1000,
		[percent]
	);

	const handleDecrement = disabled ? undefined : handleInputDecrement;
	const handleIncrement = disabled ? undefined : handleInputIncrement;

	const mobilePercentInput = useMemo(
		() => (
			<>
				<button
					type="button"
					onClick={handleDecrement}
				>
					{arrowIcon}
				</button>

				<TextField
					elementType="div"
					value={percent}
					disabled={disabled}
				/>

				<button
					type="button"
					onClick={handleIncrement}
				>
					{arrowIcon}
				</button>
			</>
		),
		[handleIncrement, handleDecrement, percent, disabled]
	);

	const desktopPercentInput = useMemo(
		() => (
			<TextField
				elementType="input"
				value={percent}
				onChange={handleInputChange}
				disabled={disabled}
			/>
		),
		[percent, handleInputChange, disabled]
	);

	return [percent, isMobile ? mobilePercentInput : desktopPercentInput];
};
