import {useMemo} from 'react';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {hasPermission} from '@tehzor/tools/utils/findPermission';
import {UserRoleScopes} from '@tehzor/tools/interfaces/IUser';
import {
	testWorkAcceptanceRestrictions,
	testWorkAcceptanceRestrictionsList
} from '@src/utils/testRestrictions';
import {WorkAcceptanceStatusIds} from '@tehzor/tools/interfaces/workAcceptances/IWorkAcceptanceStatus';
import {IWorkAcceptance} from '@tehzor/tools/interfaces/workAcceptances/IWorkAcceptance';

export interface IPermissions {
	canAdd?: boolean;
	canEdit?: boolean;
	canEditResult?: boolean;
	canEditStatus?: boolean;
	canDelete?: boolean;
	canExport?: boolean;
	canMove?: boolean;
	canViewHistory?: boolean;
	canEditStatusRestrictions?: {[key: string]: boolean} & {
		availableStatuses: WorkAcceptanceStatusIds[];
	};
}

/**
 * Возвращает полномочия для приёмок работ
 *
 * @param objectId id объекта
 * @param workAcceptance приёмка работ
 */
export const useWorkAcceptancesPermissions = (
	objectId: string,
	workAcceptance?: IWorkAcceptance
): IPermissions => {
	const user = useAppSelector(s => s.auth.profile);

	return useMemo(() => {
		if (!user || !user.roles) {
			return {};
		}
		return {
			canAdd: hasPermission(
				user.roles,
				'work-acceptances-add',
				UserRoleScopes.OBJECT,
				objectId
			),
			canEdit: testWorkAcceptanceRestrictions(
				user.id,
				user.roles,
				'work-acceptances-edit',
				objectId,
				workAcceptance?.createdBy?.id,
				workAcceptance?.acceptors,
				workAcceptance?.acceptorsActiveGroupLeader,
				workAcceptance?.watchers,
				workAcceptance?.submitters
			),
			canEditResult: testWorkAcceptanceRestrictions(
				user.id,
				user.roles,
				'work-acceptances-result-edit',
				objectId,
				workAcceptance?.createdBy?.id,
				workAcceptance?.acceptors,
				workAcceptance?.acceptorsActiveGroupLeader,
				workAcceptance?.watchers,
				workAcceptance?.submitters
			),
			canEditStatus: hasPermission(
				user.roles,
				'work-acceptances-status-edit',
				UserRoleScopes.OBJECT,
				objectId
			),
			canDelete: hasPermission(
				user.roles,
				'work-acceptances-delete',
				UserRoleScopes.OBJECT,
				objectId
			),
			canExport: hasPermission(
				user.roles,
				'work-acceptances-export',
				UserRoleScopes.OBJECT,
				objectId
			),
			canViewHistory: hasPermission(
				user.roles,
				'work-acceptances-history-view',
				UserRoleScopes.OBJECT,
				objectId
			),
			canEditStatusRestrictions: testWorkAcceptanceRestrictionsList(
				user.roles,
				'work-acceptances-status-edit',
				objectId
			)
		};
	}, [
		objectId,
		user,
		workAcceptance?.acceptors,
		workAcceptance?.acceptorsActiveGroupLeader,
		workAcceptance?.createdBy?.id,
		workAcceptance?.watchers
	]);
};
