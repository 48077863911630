import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {createApiAction} from '@src/store/middlewares/api';
import {requestLastReply, IGetLastReplyResponse} from '@src/api/backend/reply';
import {AppThunkAction} from '@src/store/interfaces/thunk';
import IComment from '@tehzor/tools/interfaces/comments/IComment';
import {ICommentTypeId} from '@tehzor/tools/interfaces/comments/ICommentType';
import {addErrorToast} from '@tehzor/ui-components';
import {t} from 'i18next';

export interface IGetLastProblemReplyPayload {
	problemId: string;
	reply: IComment | undefined;
}

const request = () => ({type: types.GET_REQUEST});

const success = (response: IGetLastReplyResponse, problemId: string) => ({
	type: types.GET_SUCCESS,
	payload: {
		problemId,
		reply: response
	} as IGetLastProblemReplyPayload
});

const failure = (error: IError) => {
	addErrorToast({title: t('toast.getLastProblemReply.error.title')});
	return {
		type: types.GET_FAILURE,
		payload: error
	};
};

/**
 * Возвращает последний ответ на нарушение
 *
 * @param objectId id объекта
 * @param problemId id нарушения
 * @param commentTypeId тип коментария
 */
export const getLastProblemReply =
	(
		objectId: string,
		problemId: string,
		commentTypeId: ICommentTypeId
	): AppThunkAction<Promise<IGetLastReplyResponse>> =>
	(dispatch, getState) => {
		const data = getState().entities.problemsLastReplies;
		if (data.hasOwnProperty(problemId)) {
			return Promise.resolve(data[problemId]);
		}
		return dispatch(
			createApiAction<IGetLastReplyResponse>(
				request,
				res => success(res, problemId),
				failure,
				() => requestLastReply(objectId, problemId, commentTypeId)
			)
		);
	};
