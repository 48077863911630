import {createContext, PropsWithChildren, useCallback} from 'react';
import {ITableContextMenuAction} from '@tehzor/tools/interfaces/table/ITableContextMenuAction';
import {IEnrichedWorkingGroup} from '@tehzor/tools/interfaces/workingGroups/IEnrichedWorkingGroup';
import {useDeleteWorkingGroup} from '@src/core/hooks/mutations/workingGroups/useDeleteWorkingGroup';
import {useTranslation} from 'react-i18next';
import {useTranslatedConfirmDialog} from '@src/core/hooks/translations/useTranslatedConfirmDialog';

export const DispatchActionCtx = createContext<
	(action: ITableContextMenuAction<IEnrichedWorkingGroup>) => void
>(() => ({}));

export const MenuActionCtxProvider = ({
	children,
	reloadList
}: PropsWithChildren<{reloadList: () => Promise<void>}>) => {
	const {t} = useTranslation();
	const {mutateAsync: deleteWorkingGroup} = useDeleteWorkingGroup();
	const [deleteDialog, getDeleteConfirmation] = useTranslatedConfirmDialog({
		title: t('useConfirmDialog.workGroup.deleteMessage'),
		message: t('useConfirmDialog.data.deleteMessage')
	});

	const handleContextMenuAction = useCallback(
		async (action: ITableContextMenuAction<IEnrichedWorkingGroup>) => {
			if (action.type === 'delete' && (await getDeleteConfirmation())) {
				await deleteWorkingGroup(action.payload.id);
				await reloadList();
			}
		},
		[reloadList]
	);

	return (
		<DispatchActionCtx.Provider value={handleContextMenuAction}>
			{children}
			{deleteDialog}
		</DispatchActionCtx.Provider>
	);
};
