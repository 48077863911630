import {useCallback, useState, ReactNode} from 'react';
import {AddingPresaleCheckDialog} from '../../components/AddingPresaleCheckDialog';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {ISavingProblem} from '@src/interfaces/saving/ISavingProblem';
import {ISavingInspection} from '@src/interfaces/saving/ISavingInspection';
import {useTranslation} from 'react-i18next';
import {IPresaleCheck} from '@tehzor/tools/interfaces/presaleChecks/IPresaleCheck';
import {IPresaleCheckAddingEntityType} from '../../model/slice/presaleCheckAdding/interfaces';

interface IAddingPresaleCheckDialogProps {
	objectId: string;
	presaleCheckId?: string;
	links?: IPresaleCheck['links'];
	stage?: ObjectStageIds;
	types?: IPresaleCheckAddingEntityType[];
	defaultProblemData?: ISavingProblem;
	defaultInspectionData?: ISavingInspection;
	onSuccess?: () => void | Promise<void>;
	problemToCopyId?: string;
	createdBy?: string;
	title?: string;
}

export const useAddingPresaleCheckDialog = ({
	objectId,
	presaleCheckId,
	links,
	stage,
	types,
	defaultProblemData,
	defaultInspectionData,
	onSuccess,
	problemToCopyId,
	createdBy,
	title
}: IAddingPresaleCheckDialogProps): [ReactNode, () => void] => {
	const {t} = useTranslation();
	const [isOpen, setOpen] = useState<boolean>(false);

	const open = useCallback(() => {
		setOpen(true);
	}, []);

	const close = useCallback(() => {
		setOpen(false);
	}, []);

	const dialog = stage ? (
		<AddingPresaleCheckDialog
			title={title ?? t('useAddingPresaleCheckDialog.title')}
			objectId={objectId}
			presaleCheckId={presaleCheckId}
			links={links}
			types={types}
			defaultProblemData={defaultProblemData}
			defaultInspectionData={defaultInspectionData}
			isOpen={isOpen}
			onClose={close}
			onSuccess={onSuccess}
			problemToCopyId={problemToCopyId}
			createdBy={createdBy}
		/>
	) : null;

	return [dialog, open];
};
