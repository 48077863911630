import {ProblemIndicator} from '@tehzor/ui-components';
import {useProblemsCounts} from '@src/core/hooks/useProblemsCounts';
import {useProblemStatuses} from '@src/core/hooks/queries/problemStatuses/hooks';
import {IPreparedClaim} from '@src/pages/WarrantyClaimsPage/interfaces/IPreparedClaim';
import {ProcessIds} from "@tehzor/tools/interfaces/process/ProcessId";

interface IIndicatorsProps {
	claim: IPreparedClaim;
}

export const Indicators = (props: IIndicatorsProps) => {
	const {claim} = props;
	const problemsCount = useProblemsCounts(claim.problemsStatsData?.problems?.warranty?.[ProcessIds.WARRANTY_SERVICE]);
	const {data: problemStatuses} = useProblemStatuses();

	return (
		<div className="warranty-claims-table__stats-cell-stats">
			{problemsCount &&
				problemStatuses &&
				problemsCount.map(({status, count}) =>
					count > 0 ? (
						<ProblemIndicator
							key={status}
							statuses={problemStatuses}
							status={status}
							count={count}
						/>
					) : null
				)}
		</div>
	);
};
