import {IGetCustomFieldsResponse} from '@/shared/api/fields/getCustomFieldsList';
import {useFieldsPermissions} from '@/shared/hooks/permissions/useFieldsPermissions';
import {useQuery} from '@tanstack/react-query';
import IError from '@tehzor/tools/interfaces/IError';
import {fieldsQueryKeys} from '../../../keys/index';

export const useCustomFields = <T = IGetCustomFieldsResponse>(
	select?: (data: IGetCustomFieldsResponse) => T
) => {
	const permissions = useFieldsPermissions();

	return useQuery<IGetCustomFieldsResponse, IError, T>({
		queryKey: fieldsQueryKeys.customLists(),
		meta: {
			error: 'при загрузке списка пользовательских полей'
		},
		select,
		enabled: permissions.canViewFields
	});
};
