import {longDateTimeFormat} from '@tehzor/tools/utils/dateFormats';
import declination from '@tehzor/tools/utils/declination';
import {Dialog, EntityGridItem, InlineButton, UserAvatar} from '@tehzor/ui-components';
import {format} from 'date-fns';
import {useCallback, useMemo, useState} from 'react';
import {IPreparedProblem} from '../../interfaces/IPreparedProblem';
import {useTranslation} from 'react-i18next';
import {useSortedProblemViewsAsArray} from '@src/core/hooks/queries/problemViews/hooks';
import {useDateFnsLocale} from '@src/core/hooks/translations/useDateFnsLocales';

interface IProblemViewsProps {
	problem: IPreparedProblem;
	objectId: string;
	canSeeViews: boolean;
}

const icon = <i className="tz-eye-20" />;

export const ProblemViews = ({problem, objectId, canSeeViews}: IProblemViewsProps) => {
	const {t} = useTranslation();
	const [isOpen, setOpen] = useState(false);
	const dateFnsLocale = useDateFnsLocale();
	const words = [
		t('problemPage.info.problemViews.dialog.title.words.1'),
		t('problemPage.info.problemViews.dialog.title.words.2'),
		t('problemPage.info.problemViews.dialog.title.words.5')
	];

	const open = useCallback(() => setOpen(true), []);
	const close = useCallback(() => setOpen(false), []);

	const problemViewsArgs = {
		objectId,
		problemId: problem.id,
		canSeeViews
	};
	const {data: views} = useSortedProblemViewsAsArray(problemViewsArgs);
	const title = useMemo(() => {
		const latestView = views?.length
			? views.reduce((latest, view) => {
					if (view.createdAt > latest.createdAt) {
						return view;
					}
					return latest;
			  })
			: undefined;

		if (views && views.length <= 1) {
			return latestView?.user.displayName;
		}

		const numberOfViews = views ? views.length - 1 : 0;

		return `${latestView?.user.displayName} ${t(
			'problemPage.info.problemViews.button.title.and'
		)} ${numberOfViews} ${declination(numberOfViews, words)}`;
	}, [views]);

	const trigger = (
		<InlineButton
			onClick={open}
			type="accent"
			label={title}
		/>
	);

	const content = views
		? views.map(view => (
				<div
					className="problem-page__views-user"
					key={view.id}
				>
					<UserAvatar
						user={view.user}
						size="40"
					/>
					<div className="problem-page__views-user__container">
						<div className="problem-page__views-user__container-title">
							<div className="problem-page__views-user__container-title-name">
								{view.user.displayName}
							</div>
							<div className="problem-page__views-user__container-title-date">
								{format(view.createdAt, longDateTimeFormat, {
									locale: dateFnsLocale
								})}
							</div>
						</div>
						<div className="problem-page__views-user__container-position">
							{view.user.position}
						</div>
					</div>
				</div>
		  ))
		: null;

	const dialog = (
		<Dialog
			className="problem-page__views-dialog"
			isOpen={isOpen}
			title={t('problemPage.info.problemViews.dialog.title', {
				number: problem.number || problem.localNumber
			})}
			onRequestClose={close}
		>
			{content}
		</Dialog>
	);

	return views?.length ? (
		<>
			<EntityGridItem
				className="problem-page__views"
				icon={icon}
				inline
				fullRow
			>
				{trigger}{' '}
				{views.length > 1
					? t('problemPage.info.problemViews.children.many')
					: t('problemPage.info.problemViews.children.one')}
			</EntityGridItem>

			{dialog}
		</>
	) : null;
};
