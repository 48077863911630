import {useMemo} from 'react';
import {IPresaleCheckEntity} from '@src/interfaces/IPresaleCheckEntity';
import {usePresaleCheckProblemsEntities} from '../../PresaleCheckProblems/hooks/usePresaleCheckProblemsEntities';
import {usePresaleCheckInspectionsEntities} from '../../PresaleCheckInspections/hooks/usePresaleCheckInspectionsEntities';

export const usePresaleCheckEntities = (objectId: string, presaleCheckId: string) => {
	const {data: problems} = usePresaleCheckProblemsEntities(objectId, presaleCheckId);
	const {data: inspections} = usePresaleCheckInspectionsEntities(objectId, presaleCheckId);

	return useMemo(
		() =>
			[...(problems || []), ...(inspections || [])].sort(
				(a, b) => (b.data.createdAt ?? 0) - (a.data.createdAt ?? 0)
			) as IPresaleCheckEntity[],
		[problems, inspections]
	);
};
