import {IPresaleCheckEntity} from '@src/interfaces/IPresaleCheckEntity';
import CellWrap from '@src/components/tableCells/CellWrap';
import {PresaleCheckProblemInfoMobileCell} from '../../Cells/PresaleCheckProblemInfoMobileCell';
import {ProblemInfoCellWrap} from '@src/components/tableCells/ProblemInfoCellWrap';
import InspectionInfoMobileCell from '@src/components/tableCells/InspectionInfoMobileCell';
import {Column} from 'react-table';
import IComment from '@tehzor/tools/interfaces/comments/IComment';

interface IPresaleCheckColumnsMobileProps {
	replies: Record<string, IComment> | undefined;
}

export const PresaleCheckColumnsMobile = ({
	replies
}: IPresaleCheckColumnsMobileProps): Array<Column<IPresaleCheckEntity>> => {
	return [
		{
			id: 'info',
			Cell: CellWrap({
				problem: ProblemInfoCellWrap(PresaleCheckProblemInfoMobileCell, undefined, replies),
				inspection: InspectionInfoMobileCell
			})
		}
	];
};
