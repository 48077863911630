import {Dispatch, memo, useCallback} from 'react';
import IObjectFieldSetting from '@tehzor/tools/interfaces/objects/IObjectFieldSetting';
import Text from '@src/components/editableFields/Text';
import CompanySelect from '@src/components/editableFields/CompanySelect';
import './EditableWorkingGroup.less';
import {
	IEditableWorkingGroupAction,
	IEditableWorkingGroupState
} from '@src/core/hooks/states/useEditableWorkingGroupState';
import {ObjectsSelect} from '../editableFields/ObjectsSelect';
import {LeaderSelect} from '../editableFields/LeaderSelect';
import {WorkingGroupSelect} from '../editableFields/WorkingGroupSelect';
import {IWorkingGroup} from '@tehzor/tools/interfaces/workingGroups/IWorkingGroup';
import {isPropEdited} from '@tehzor/tools/core/states/editableEntityState';
import {WorkingGroupTypeSelect} from '../editableFields/WorkingGroupTypeSelect/WorkingGroupTypeSelect';
import {ObjectStagesMultipleSelect} from '@src/components/ObjectStagesMultipleSelect';
import {WorkingGroupTypeId} from '@tehzor/tools/interfaces/workingGroups/IWorkingGroupType';
import {WorkAcceptanceFrontTypeSelect} from '../editableFields/WorkAcceptanceFrontTypeSelect';
import {WorkAcceptanceTypesSelect} from '../editableFields/WorkAcceptanceTypesSelect';

interface IEditableWorkingGroupProps {
	groupId?: string;
	editingState: IEditableWorkingGroupState;
	editingDispatch: Dispatch<IEditableWorkingGroupAction>;
	fieldsSettings: Record<string, IObjectFieldSetting>;
	disabled?: boolean;
	workingGroup?: IWorkingGroup;
}

export const EditableWorkingGroup = memo((props: IEditableWorkingGroupProps) => {
	const {groupId, editingState, editingDispatch, fieldsSettings, disabled, workingGroup} = props;

	const onStagesChange = useCallback(
		(v?: string[], required?: boolean) => {
			editingDispatch({type: 'update', field: 'stages', value: v});
			if (required) {
				editingDispatch({type: 'update-error', field: 'stages'});
			}
		},
		[editingDispatch]
	);

	const onParentObjectChange = useCallback(
		(v?: string | null, required?: boolean) => {
			editingDispatch({type: 'update', field: 'parentId', value: v});
			if (required) {
				editingDispatch({type: 'update-error', field: 'parentId'});
			}
		},
		[editingDispatch]
	);

	return (
		<div className="editable-working-group ">
			{fieldsSettings.name !== undefined && (
				<Text
					field="name"
					label="Название группы"
					value={editingState.name}
					editingDispatch={editingDispatch}
					required={fieldsSettings.name.isRequired}
					disabled={disabled}
					hasError={editingState.errors.name}
				/>
			)}

			{fieldsSettings.type !== undefined && (
				<div>
					<WorkingGroupTypeSelect
						field="type"
						label="Тип группы"
						value={editingState.type}
						editingDispatch={editingDispatch}
						required={fieldsSettings.type.isRequired}
						disabled={disabled}
						hasError={editingState.errors.type}
					/>
				</div>
			)}

			{fieldsSettings.workAcceptanceType !== undefined &&
				(editingState.type === WorkingGroupTypeId.ACCEPTORS ||
					editingState.type === WorkingGroupTypeId.SUBMITTERS) && (
					<div>
						<WorkAcceptanceTypesSelect
							field="workAcceptanceType"
							label="Типы приёмки работ"
							value={editingState.workAcceptanceType}
							editingDispatch={editingDispatch}
							required={fieldsSettings.workAcceptanceType.isRequired}
							disabled={disabled}
							hasError={editingState.errors.workAcceptanceType}
						/>
					</div>
				)}

			{fieldsSettings.workAcceptanceFrontType !== undefined &&
				(editingState.type === WorkingGroupTypeId.ACCEPTORS ||
					editingState.type === WorkingGroupTypeId.SUBMITTERS) && (
					<div>
						<WorkAcceptanceFrontTypeSelect
							field="workAcceptanceFrontType"
							label="Тип фронта приёмки работ"
							value={editingState.workAcceptanceFrontType}
							editingDispatch={editingDispatch}
							required={fieldsSettings.workAcceptanceFrontType.isRequired}
							disabled={disabled}
							hasError={editingState.errors.workAcceptanceFrontType}
						/>
					</div>
				)}

			{editingState.type && fieldsSettings.parentId !== undefined && (
				<div>
					<WorkingGroupSelect
						label="Родительская группа"
						value={editingState.parentId}
						editingStages={editingState.stages}
						originalStages={workingGroup?.stages}
						onChange={onParentObjectChange}
						required={fieldsSettings.parentId.isRequired}
						disabled={disabled}
						hasError={editingState.errors.parentId}
						excludedGroup={groupId}
						workingGroupType={editingState.type}
					/>
					{workingGroup &&
						isPropEdited('parentId', editingState, workingGroup) &&
						editingState.parentId !== null && (
							<div className="editable-working-group__warning">
								Изменения приведут к удалению правил в родительской группе
							</div>
						)}
				</div>
			)}

			{fieldsSettings.companyId !== undefined && (
				<div>
					<CompanySelect
						field="companyId"
						label="Головная компания"
						value={editingState.companyId}
						editingDispatch={editingDispatch}
						required={fieldsSettings.companyId.isRequired}
						disabled={disabled}
						hasError={editingState.errors.companyId}
					/>
					{workingGroup && isPropEdited('companyId', editingState, workingGroup) && (
						<div className="editable-working-group__warning">
							Изменения приведут к удалению связанных с объектами компании правил
						</div>
					)}
				</div>
			)}

			{fieldsSettings.leader !== undefined && (
				<LeaderSelect
					field="leader"
					companyId={editingState.companyId}
					label="Руководитель группы"
					value={editingState.leader}
					editingDispatch={editingDispatch}
					required={fieldsSettings.leader.isRequired}
					disabled={disabled}
					hasError={editingState.errors.leader}
				/>
			)}
			{fieldsSettings.objects !== undefined && (
				<div>
					<ObjectsSelect
						field="objects"
						label="Объекты"
						value={editingState.objects}
						companyId={editingState.companyId}
						editingDispatch={editingDispatch}
						required={fieldsSettings.objects.isRequired}
						disabled={disabled}
						hasError={editingState.errors.objects}
					/>
					{workingGroup && isPropEdited('objects', editingState, workingGroup) && (
						<div className="editable-working-group__warning">
							Изменения приведут к удалению правил связанных с удаленными объектами
						</div>
					)}
				</div>
			)}
			{fieldsSettings.stages !== undefined && (
				<ObjectStagesMultipleSelect
					label="Стадия"
					errorMessage="Выберите стадию"
					value={editingState.stages}
					onChange={onStagesChange}
					required={fieldsSettings.stages.isRequired}
					disabled={disabled}
					hasError={editingState.errors.stages}
				/>
			)}
		</div>
	);
});
