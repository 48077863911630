import {Column} from 'react-table';
import {MobileWarrantyClaimCell} from './MobileWarrantyClaimCell';
import {IPreparedWarrantyClaimEntity} from '../../interfaces/IPreparedWarrantyClaimEntity';

export const getMobileColumns = (): Array<Column<IPreparedWarrantyClaimEntity>> => [
	{
		id: 'mobile-cell',
		Cell: MobileWarrantyClaimCell,
		className: 'warranty-claims-table__mobile-cell',
		isNonClickable: false
	}
];
