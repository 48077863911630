import {useMemo} from 'react';
import {useSpacesAsArray} from '@src/core/hooks/queries/spaces/hooks';
import {WorkAcceptanceFrontItem} from '@src/components/WorkAcceptanceFrontItem/WorkAcceptanceFrontItem';
import ClickPreventWrap from '@src/components/tableCells/ClickPreventWrap';
import {WorkAcceptanceFrontTypeId} from '@tehzor/tools/interfaces/workAcceptances/IWorkAcceptanceFrontType';
import {IListSpace} from '@tehzor/tools/interfaces/spaces/IListSpace';
import './WorkAcceptanceScope.less';

interface IWorkAcceptanceFrontProps {
	item: IListSpace;
	objectId: string;
	frontType: WorkAcceptanceFrontTypeId;
}

const WorkAcceptanceFront = ({item, objectId, frontType}: IWorkAcceptanceFrontProps) => (
	<ClickPreventWrap>
		<WorkAcceptanceFrontItem
			item={item}
			objectId={objectId}
			frontType={frontType}
		/>
	</ClickPreventWrap>
);

interface IWorkAcceptanceScopeProps {
	objectId: string;
	spaceIds: string[] | undefined;
	frontType: WorkAcceptanceFrontTypeId;
}

export const WorkAcceptanceScope = ({objectId, spaceIds, frontType}: IWorkAcceptanceScopeProps) => {
	const {data: spaces} = useSpacesAsArray(objectId);

	const workAcceptanceFront = useMemo(
		() =>
			frontType === WorkAcceptanceFrontTypeId.SPACES
				? spaces.filter(space => spaceIds?.includes(space.id))
				: undefined,
		[spaces, spaceIds, frontType]
	);

	return (
		<div className="work-acceptance-info-cell__scopes">
			{workAcceptanceFront &&
				workAcceptanceFront.slice(0, 3).map(item => (
					<WorkAcceptanceFront
						key={item.id}
						item={item}
						objectId={objectId}
						frontType={frontType}
					/>
				))}
			{workAcceptanceFront && workAcceptanceFront?.length > 3 && (
				<div className="work-acceptances-page__d-table__cell-rest">
					+{workAcceptanceFront.length - 3}
				</div>
			)}
		</div>
	);
};
