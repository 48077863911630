import {TabContainer} from '@/shared/ui/EntitiesPage/TabContainer/TabContainer';
import {Tabs} from '@tehzor/ui-components';
import {CentralBarDesktop} from '@/shared/ui/EntitiesPage/CentralBarDesktop/CentralBarDesktop';
import {Tab} from '@/shared/ui/EntitiesPage/Tab/Tab';
import {fieldsPagesLinks} from '@/shared/constants/fieldsPages/fieldsPagesLinks';
import {useFieldsPageNavigation} from '@/pages/FieldsPage/model/hooks/useFieldsPageNavigation';

export const FieldsCentralBar = () => {
	const [navigate, currentLinkIndex] = useFieldsPageNavigation();
	const links = fieldsPagesLinks.map(link => (
		<Tab
			label={link.label}
			key={link.id}
		/>
	));

	return (
		<CentralBarDesktop>
			<TabContainer>
				<Tabs
					links={links}
					activeTab={currentLinkIndex}
					onActiveTabChange={navigate}
				/>
			</TabContainer>
		</CentralBarDesktop>
	);
};
