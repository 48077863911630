import {useCallback, useMemo, useState} from 'react';
import {useLocation} from 'react-router-dom';
import {useAppHeader} from '@src/components/AppHeader/hooks/useAppHeader';
import {LinkButton, TreeSelect} from '@tehzor/ui-components';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {isLocalStateEqual} from '@src/components/MobileEntitiesFilters/utils/isLocalStateEqual';
import {useRouting} from '@src/components/MobileEntitiesFilters/utils/pagesRouting';
import {useEntitiesFiltersCtx} from '@src/components/EntitiesFilters/utils/entitiesFiltersCtx';
import {treeFilter} from '@tehzor/ui-components/src/components/inputs/select/SelectSearch';
import {useUpdateEffect} from 'react-use';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import {useExtractAllCategoriesAsArray} from '@src/core/hooks/queries/categories/hook';
import {useExtractCategoriesSetsAsArray} from '@src/core/hooks/queries/categorySets/hook';
import {useTranslation} from 'react-i18next';
import {TranslatedFilterPage} from '@src/components/TranslatedFilterPage';
import {TranslatedSelectSearch} from '@src/components/TranslatedSelectSearch';
import {makeTreeData} from '@src/core/hooks/queries/categorySets/utils/makeTreeData';

const CategoriesSetFilterPage = () => {
	const {goBack} = useChangePath();
	const {objectId, entity} = useStrictParams<{objectId?: string; entity: string}>();
	const {t} = useTranslation();

	const {state, change} = useEntitiesFiltersCtx<{categories?: string[]}>();
	const [selected, setSelected] = useState(state.categories);
	const [expanded, setExpanded] = useState<string[] | undefined>([]);
	const [search, setSearch] = useState('');

	const {data: allCategories} = useExtractAllCategoriesAsArray();
	const {data: categoriesSets} = useExtractCategoriesSetsAsArray({objectId: objectId || 'all'});

	const treeData = useMemo(
		() => makeTreeData(categoriesSets, allCategories),
		[categoriesSets, allCategories]
	);

	const {filteredData, expanded: expandedValue} = useMemo(
		() => treeFilter(treeData, 'content', search),
		[search, treeData]
	);

	useUpdateEffect(() => {
		setExpanded(expandedValue?.map(item => item.id));
	}, [expandedValue]);

	const {canGoBack} = useLocation().state as {canGoBack?: boolean};

	const handleApply = useCallback(() => {
		change({categories: selected});
		goBack();
	}, [selected, change]);

	const handleClear = useCallback(() => {
		setSelected(undefined);
	}, []);

	useAppHeader(
		{
			title: t('entitiesFilters.CategoriesSetFilter.label'),
			showBackBtn: true,
			mobileRightButtons: selected?.length ? (
				<LinkButton
					label={t('entitiesFilters.cancelButton.label')}
					onClick={handleClear}
				/>
			) : null
		},
		[selected]
	);

	useRouting(!canGoBack, objectId, entity);

	return (
		<TranslatedFilterPage
			applyDisabled={isLocalStateEqual(state.categories, selected)}
			onApplyClick={handleApply}
			search={
				<TranslatedSelectSearch
					value={search}
					onChange={setSearch}
				/>
			}
		>
			<TreeSelect
				data={filteredData}
				multiple
				value={selected}
				onChange={setSelected}
				expandedValue={expanded}
				onExpand={setExpanded}
			/>
		</TranslatedFilterPage>
	);
};

export default CategoriesSetFilterPage;
