import {StructuresCounter} from '@/pages/StructuresPage/components/StructuresCounter/StructuresCounter';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {StructuresDisplayMode} from '@src/interfaces/StructuresDisplayMode';
import {TabLink, Tabs} from '@tehzor/ui-components';
import styles from './CentralBarDesctop.module.less';
import {extractStructuresPageSettings} from '@/entities/Structures';
import {DisplayModeSwitchDesktop} from '../../components/DisplayModeSwitchDesktop/DisplayModeSwitchDesktop';
import {CentralBarDesktop} from '@/shared/ui/EntitiesPage/CentralBarDesktop/CentralBarDesktop';
import {useTranslation} from 'react-i18next';

interface ICentralBarDesktopProps {
	objectId: string;
	spaceId: string;
}

export const StructuresCentralBarDesktop = ({objectId, spaceId}: ICentralBarDesktopProps) => {
	const {displayMode} = useAppSelector(s => extractStructuresPageSettings(s, objectId));
	const {t} = useTranslation();
	const tabs = [
		<TabLink
			className={styles.tabLink}
			label={t('structuresPage.tabs.label.workAcceptances')}
		/>
	];

	return (
		<CentralBarDesktop>
			{displayMode === StructuresDisplayMode.LIST ? (
				<StructuresCounter
					objectId={objectId}
					spaceId={spaceId}
				/>
			) : (
				<div className={styles.tabContainer}>
					<Tabs
						links={tabs}
						activeTab={0}
					/>
				</div>
			)}
			<DisplayModeSwitchDesktop objectId={objectId} />
		</CentralBarDesktop>
	);
};
