import {IState} from '@src/store/modules';
import * as types from '../constants/problemsData';
import IError from '@tehzor/tools/interfaces/IError';
import {ApiAction, createApiAction} from '@src/store/middlewares/api';
import checkLoaded from '@tehzor/tools/core/checkLoaded';
import {IEntityProblemsData} from '@tehzor/tools/interfaces/entititesComputedData/IEntityProblemsData';
import {IWorkAcceptancesStatsDataRequest} from '@src/interfaces/IWorkAcceptancesStatsData';
import {requestWorkAcceptancesProblemsData} from '@src/api/backend/workAcceptances/problemsData';
import {addErrorToast} from '@tehzor/ui-components';
import {t} from 'i18next';

const request = (requestData: IWorkAcceptancesStatsDataRequest) => ({
	type: types.GET_REQUEST,
	payload: requestData
});

const success = (response: Record<string, IEntityProblemsData>) => ({
	type: types.GET_SUCCESS,
	payload: response
});

const failure = (error: IError) => {
	addErrorToast({title: t('toast.getWorkAcceptancesProblemsData.error.title')});
	return {
		type: types.GET_FAILURE,
		payload: error
	};
};

/**
 * Получает статистику нарушений для приемок работ
 *
 * @param requestData данные запроса
 */
export const getWorkAcceptancesProblemsData = (requestData: IWorkAcceptancesStatsDataRequest) =>
	checkLoaded<IState, Record<string, IEntityProblemsData>, ApiAction>(
		s => s.pages.workAcceptances.problemsData,
		createApiAction(
			() => request(requestData),
			success,
			failure,
			() => requestWorkAcceptancesProblemsData(requestData)
		)
	);
