import {ReactNode} from 'react';
import styles from './TabContainer.module.less';

interface ITabContainerProps {
	children: ReactNode;
}

export const TabContainer = ({children}: ITabContainerProps) => (
	<div className={styles.wrapper}>{children}</div>
);
