import {useToggle} from 'react-use';
import {ReactNode} from 'react';
import styles from './LegendPopup.module.less';
import {IconButton} from '../buttons';
import {PopupPlate} from '../containers';
import {ModalDialog} from '../dialogs';

interface ILegendPopupProps {
	children: ReactNode;
	isDesktop?: boolean;
}

export const LegendPopup = ({children, isDesktop = true}: ILegendPopupProps) => {
	const [isOpen, setIsOpen] = useToggle(false);

	if (isDesktop) {
		return (
			<PopupPlate
				trigger={({ref}) => (
					<IconButton ref={ref} type="common" active={isOpen} onClick={setIsOpen}>
						<i className="tz-info-24" />
					</IconButton>
				)}
				arrowVisible
				isOpen={isOpen}
				placement="bottom-end"
				onOutsideClick={setIsOpen}
				onDisappear={setIsOpen}
			>
				<div className={styles.wrapper}>{children}</div>
			</PopupPlate>
		);
	}

	return (
		<>
			<IconButton
				className={styles.mobileIcon}
				type="common"
				active={isOpen}
				onClick={setIsOpen}
			>
				<i className="tz-info-24" />
			</IconButton>
			<ModalDialog
				className={{body: styles.wrapper}}
				open={isOpen}
				onRequestClose={setIsOpen}
			>
				{children}
			</ModalDialog>
		</>
	);
};
