import {queryClient} from '@src/api/QueryClient';
import {
	IEditWorkAcceptanceSubmittersResponse,
	makeWorkAcceptanceSubmittersEditRequest
} from '@src/api/backend/workAcceptance';
import {workAcceptancesQueryKeys} from '@src/api/cache/workAcceptances/keys';
import {createApiAction} from '@src/store/middlewares/api';
import IError from '@tehzor/tools/interfaces/IError';
import * as types from '../constants';
import {delegationHistoryQueryKeys} from '@src/api/cache/delegationHistory/keys';
import {WorkingGroupTypeId} from '@tehzor/tools/interfaces/workingGroups/IWorkingGroupType';
import {addErrorToast} from '@tehzor/ui-components';
import {t} from 'i18next';

export type IEditWorkAcceptanceResponsiblePayload = IEditWorkAcceptanceSubmittersResponse;

const request = () => ({type: types.EDIT_SUBMITTERS_REQUEST});

const success = (response: IEditWorkAcceptanceSubmittersResponse) => {
	void queryClient.invalidateQueries({queryKey: workAcceptancesQueryKeys.lists()});
	void queryClient.invalidateQueries({queryKey: workAcceptancesQueryKeys.paginate()});
	void queryClient.invalidateQueries({queryKey: workAcceptancesQueryKeys.details()});
	void queryClient.invalidateQueries({
		queryKey: delegationHistoryQueryKeys.histories({
			objectId: response.objectId,
			workAcceptanceId: response.id,
			workingGroupType: WorkingGroupTypeId.SUBMITTERS
		})
	});
	return {
		type: types.EDIT_SUBMITTERS_SUCCESS,
		payload: response
	};
};

const failure = (error: IError) => {
	addErrorToast({title: t('toast.editWorkAcceptanceSubmitters.error.title')});
	return {
		type: types.EDIT_SUBMITTERS_FAILURE,
		payload: error
	};
};

/**
 * Изменяет передающих приемки работ
 *
 * @param objectId id объекта
 * @param workAcceptanceId id приемки работ
 * @param submitters пользователи
 * @param activeGroup текущая группа
 */
export const editWorkAcceptanceSubmitters = (
	objectId: string,
	workAcceptanceId: string,
	submitters?: string[],
	activeGroup?: string
) =>
	createApiAction<IEditWorkAcceptanceSubmittersResponse>(request, success, failure, () =>
		makeWorkAcceptanceSubmittersEditRequest(objectId, workAcceptanceId, submitters, activeGroup)
	);
