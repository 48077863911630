interface Args {
	objectId: string;
	stage?: string;
	processId?: string;
	spaceId?: string;
	workAcceptanceId?: string;
}
/**
 * Формирует ссылку на список чек-листов
 *
 * @param objectId id объекта
 * @param spaceId id помещения
 * @param stage стадия объекта или помещения
 * @param processId процесс
 * @param workAcceptanceId
 */
export const formCheckListsLink = ({
	objectId,
	spaceId,
	stage,
	processId,
	workAcceptanceId
}: Args): string | undefined => {
	if (spaceId) {
		return `/objects/${objectId}/spaces/${spaceId}/check-lists/${stage}/${processId}`;
	}
	if (workAcceptanceId) {
		return `/objects/${objectId}/work-acceptances/${workAcceptanceId}/check-lists/${stage}`;
	}
	return undefined;
};
