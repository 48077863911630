import {useCallback, useState} from 'react';
import './EditableStructureDialog.less';
import {ActionButtons, Button, Dialog} from '@tehzor/ui-components';
import {useEditableStructure} from '@src/components/EditableStructure/hooks/useEditableStructure';
import {useTranslation} from 'react-i18next';
import {useAddStructure, useEditStructure, useStructure} from '@/entities/Structure';
import {useTranslatedConfirmDialog} from '@src/core/hooks/translations/useTranslatedConfirmDialog';

interface IEditableStructureDialogProps {
	objectId: string;
	structureId?: string;
	isOpen: boolean;

	onClose: () => void;
}

/**
 * Окно редактирования структуры
 */
export const EditableStructureDialog = ({
	objectId,
	structureId,
	isOpen,
	onClose
}: IEditableStructureDialogProps) => {
	const {data: structure} = useStructure(objectId);
	const [saving, setSaving] = useState(false);
	const [fields, getSavingData, reset, isBlocking] = useEditableStructure(
		objectId,
		structureId ? structure : undefined,
		saving
	);
	const {t} = useTranslation();
	const {mutateAsync: editStructure} = useEditStructure();
	const {mutateAsync: addStructure} = useAddStructure();

	const save = useCallback(async () => {
		setSaving(true);
		const savingData = await getSavingData();

		if (savingData && structureId) {
			try {
				await editStructure({objectId, structureId, fields: savingData});
				onClose();
			} catch (error) {
				console.log(error);
			}
		}
		if (savingData && !structureId) {
			try {
				await addStructure({objectId, fields: savingData});
				onClose();
			} catch (error) {
				console.log(error);
			}
		}
		setSaving(false);
	}, [objectId, structureId, getSavingData, onClose, editStructure, addStructure]);

	const [closingDialog, getClosingConfirmation] = useTranslatedConfirmDialog();

	const handleClose = useCallback(async () => {
		if (!isBlocking || (await getClosingConfirmation())) {
			onClose();
		}
	}, [isBlocking, onClose, getClosingConfirmation]);

	return (
		<Dialog
			className="editable-structure-dialog"
			isOpen={isOpen}
			title={
				structureId
					? t('editableStructureDialog.title.editing')
					: t('editableStructureDialog.title.adding')
			}
			footer={
				<ActionButtons>
					<Button
						type="cancel"
						label={t('editableStructureDialog.button.cancel')}
						disabled={saving}
						onClick={handleClose}
					/>
					<Button
						type="accent-blue"
						label={t('editableStructureDialog.button.accent')}
						disabled={saving}
						onClick={save}
					/>
				</ActionButtons>
			}
			fullScreenOnTablet
			onRequestClose={handleClose}
			onAfterClose={reset}
		>
			{fields}
			{closingDialog}
		</Dialog>
	);
};
