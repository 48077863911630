import {useCallback, useEffect, useMemo, useState} from 'react';
import {
	ActionButtons,
	Button,
	Dialog,
	EditableFieldLabel,
	TextField,
	addErrorToast
} from '@tehzor/ui-components';
import DependencySelect from '@src/components/EditableCheckList/components/DependencySelect';
import {Tree} from 'array-to-tree';
import {
	ISavingCheckItem,
	ISavingCheckItemDraftType
} from '@tehzor/tools/interfaces/checkItems/ISavingCheckItem';
import {isEqual} from 'lodash';
import {useTranslation} from 'react-i18next';

interface IEditableCheckItemDialogProps {
	isOpen: boolean;
	items: ISavingCheckItem[];
	itemsLeaf: Tree<ISavingCheckItem>;
	onChange: (index: number, itemData: ISavingCheckItem) => void;

	close(): void;
}

export const EditableCheckItemDialog = ({
	items,
	itemsLeaf,
	isOpen,
	onChange,
	close
}: IEditableCheckItemDialogProps) => {
	const {t} = useTranslation();
	const currentIndex = useMemo(
		() => items?.map(item => item.id).indexOf(itemsLeaf.id),
		[items, itemsLeaf.id]
	);

	const availableDependencies = useMemo(() => {
		const filteredItems =
			items?.filter(
				item =>
					item.name !== '' &&
					item.id !== itemsLeaf.id &&
					item.parentId === itemsLeaf.parentId
			) || [];

		const depsArray: string[] = [];

		filteredItems.forEach(item => {
			const hasCircularDependency =
				item.dependencies &&
				(item.dependencies.includes(itemsLeaf.id) || depsArray.includes(item.id));

			if (hasCircularDependency) {
				depsArray.push(item.id);

				const nextDeps = filteredItems.filter(
					nextItem => nextItem.dependencies?.includes(item.id)
				);

				nextDeps.forEach(nextDep => {
					depsArray.push(nextDep.id);
				});
			}
		});

		return filteredItems.filter(item => !depsArray.includes(item.id));
	}, [items, itemsLeaf.id, itemsLeaf.parentId]);

	const availableDependenciesIds = availableDependencies.map(el => el.id);

	const existingError = itemsLeaf.dependencies?.some(d => !availableDependenciesIds.includes(d));

	if (existingError) {
		addErrorToast({
			title: t('toast.categoryCyclicDependencies.error.title')
		});
	}

	const [text, setText] = useState(itemsLeaf.name);
	const [description, setDescription] = useState(itemsLeaf.description);
	const [deps, setDeps] = useState<string[] | undefined>(itemsLeaf.dependencies);

	useEffect(() => {
		setDeps(itemsLeaf.dependencies);
	}, [itemsLeaf]);

	const siblingsNames = items
		.filter(el => el.name && el.id !== itemsLeaf.id && el.parentId === itemsLeaf.parentId)
		.map(i => i.name);
	const nameIsForbidden = siblingsNames.includes(text);

	const isEdited =
		itemsLeaf.name !== text ||
		itemsLeaf.description !== description ||
		!isEqual(itemsLeaf.dependencies, deps);

	const handleSelectChange = useCallback(
		(value: string[]) => {
			setDeps(value);
		},
		[setDeps]
	);

	const handleSave = useCallback(() => {
		if (currentIndex !== undefined) {
			const {children, ...data} = itemsLeaf;
			onChange(currentIndex, {
				...data,
				name: text,
				description,
				dependencies: deps,
				isDraft: true,
				draftType: data.draftType || ISavingCheckItemDraftType.EDITING_DRAFT
			});
		}

		close();
	}, [currentIndex, close, itemsLeaf, onChange, text, description, deps]);

	return (
		<Dialog
			isOpen={isOpen}
			className="editable-check-items__item-editing-dialog"
			title="Редактирование категории"
			footer={
				<ActionButtons>
					<Button
						type="cancel"
						label="Отменить"
						onClick={close}
					/>
					<Button
						type="accent-blue"
						label="Сохранить"
						onClick={handleSave}
						disabled={!isEdited || nameIsForbidden || !text || existingError}
					/>
				</ActionButtons>
			}
			fullScreenOnTablet
			onRequestClose={close}
		>
			<div>
				<EditableFieldLabel>Название</EditableFieldLabel>

				<TextField
					value={text}
					className="editable-check-items__item-editing-dialog-input"
					elementType="textarea"
					elementProps={{autoFocus: true, minRows: 2}}
					onChange={setText}
					error={
						nameIsForbidden
							? 'Название совпадает с названием одной из соседних категорий'
							: undefined
					}
				/>
				<EditableFieldLabel>Описание</EditableFieldLabel>

				<TextField
					value={description}
					className="editable-check-items__item-editing-dialog-input"
					elementType="textarea"
					elementProps={{minRows: 2}}
					onChange={setDescription}
				/>
			</div>

			<DependencySelect
				label="Зависимость на категории"
				className="editable-check-items__item-editing-dialog-select"
				value={deps}
				dependencies={availableDependencies}
				onChange={handleSelectChange}
				disabled={availableDependencies.length === 0}
			/>
		</Dialog>
	);
};
