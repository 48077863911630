import {useQuery, UseQueryOptions} from '@tanstack/react-query';
import IError from '@tehzor/tools/interfaces/IError';
import {presaleChecksQueryKeys} from '../../../keys';
import {IPresaleCheck} from '@tehzor/tools/interfaces/presaleChecks/IPresaleCheck';

export const useFetchLatestPresaleCheck = (
	presaleCheckId: string,
	objectId: string,
	options?: Partial<UseQueryOptions<IPresaleCheck, IError>>
) =>
	useQuery<IPresaleCheck, IError>({
		queryKey: [...presaleChecksQueryKeys.detail(presaleCheckId), objectId],
		staleTime: Infinity,
		meta: {
			error: 'при загрузке последней предпродажной проверки'
		},
		...options
	});
