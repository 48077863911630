import {RefObject, useCallback, useEffect, useRef, useState} from 'react';
import './GlobalAddingEntityDialog.less';
import {ModalDialog} from '@tehzor/ui-components';
import {ISavingProblem} from '@src/interfaces/saving/ISavingProblem';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {IAddingProblemRefProps} from '../AddingProblem';
import {IAddingInspectionRefProps} from '../AddingInspection';
import {ICheckAddingEntityType} from '@src/store/modules/settings/checkAdding/reducers/entityType';
import useAppSelector from '@src/core/hooks/useAppSelector';
import EntitySelectContainer from '@tehzor/ui-components/src/components/containers/EntitySelectContainer/EntitySelectContainer';
import {IInternalAcceptanceAddingEntityType} from '@src/store/modules/settings/internalAcceptanceAdding/reducers/entityType';
import {useEntitiesMaps} from './hooks/useEntitiesMaps';
import {IAddingAcceptanceRefProps} from '../AddingOwnerAcptDialog/components/AddingAcceptance';
import {IAddingWarrantyClaimRefProps} from '../AddingWarrantyClaimDialog/components/AddingWarrantyClaim';
import {ISavingInspection} from '@src/interfaces/saving/ISavingInspection';
import {saveCheck} from '../AddingCheckDialog/utils/saveCheck';
import {saveInternalAcceptance} from '../AddingInternalAcceptanceDialog/utils/saveInternalAcceptance';
import {saveOwnerAcceptance} from '../AddingOwnerAcptDialog/utils/saveOwnerAcceptance';
import {saveClaim} from '../AddingWarrantyClaimDialog/utils/saveClaim';
import {AddingOwnerAcceptanceView} from '../AddingOwnerAcptDialog/AddingOwnerAcptDialog';
import {AddingWarrantyClaimView} from '../AddingWarrantyClaimDialog/AddingWarrantyClaimDialog';
import {IEntitySelectProps} from '@tehzor/ui-components/src/components/containers/EntitySelectContainer/components/EntitySelect/EntitySelect';
import {useAddCheck} from '@src/core/hooks/mutations/checks/useAddCheck';
import {useLocalChecks} from '@src/core/hooks/queries/checks/hooks';
import {useAddInternalAcceptance} from '@src/core/hooks/mutations/InternalAcceptances/useAddInternalAcceptance';
import {useLocalOwnerAcceptances} from '@src/core/hooks/queries/ownerAcceptances/useGetOwnerAcceptances';
import {useObject} from '@src/core/hooks/queries/objects/hooks';
import {ownerAcceptancesQueryKeys} from '@src/api/cache/ownerAcceptances/keys';
import {useLocalInternalAcceptances} from '@src/core/hooks/queries/internalAcceptances/hooks';
import {useLocalWarrantyClaims} from '@src/core/hooks/queries/warrantyClaims/hooks';
import {useQueryClient} from '@tanstack/react-query';
import {checksQueryKeys} from '@src/api/cache/checks/keys';
import {internalAcceptancesQueryKeys} from '@src/api/cache/internalAcceptances/keys';
import {warrantyClaimsQueryKeys} from '@src/api/cache/warrantyClaims/keys';
import {useTranslation} from 'react-i18next';
import {useTranslatedObjectStagesArray} from '@src/core/hooks/translations/useTranslatedObjectStagesArray';
import {ProcessIds} from '@tehzor/tools/interfaces/process/ProcessId';
import {
	useEntitySelectContainerSubSubTitle,
	useEntitySelectContainerSubTitle
} from '@src/core/hooks/getEntitySelectContainerSubTitle';
import {useSpace} from '@src/core/hooks/queries/space';
import {useAvailableTypes as useAvailableCheckTypes} from '../AddingCheckDialog/hooks/useAvailableTypes';
import {useEntityType as useEntityCheckType} from '../AddingCheckDialog/hooks/useEntityType';
import {useAvailableTypes as useAvailableAcceptanceTypes} from '../AddingInternalAcceptanceDialog/hooks/useAvailableTypes';
import {useEntityType as useEntityAcceptanceType} from '../AddingInternalAcceptanceDialog/hooks/useEntityType';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {extractFastAdding} from '@src/store/modules/settings/modalDialog/selectors';
import {ModalDialogButtons} from '../ModalDialogButtons';
import {modalDialogActions} from '@src/store/modules/settings/modalDialog/slice';
import {useAddPresaleCheck} from '@/features/PresaleCheck/model/hooks/useAddPresaleCheck';
import {savePresaleCheck} from '@/features/PresaleCheck/utils/savePresaleCheck';
import {presaleChecksQueryKeys} from '@/entities/PresaleCheck';
import {useLocalPresaleChecks} from '@/entities/PresaleCheck/model/cache/queries/PresaleChecksLocal/hooks/useLocalPresaleChecks';
import {
	IPresaleCheckAddingEntityType,
	useAvailablePresaleCheckTypes,
	useEntityPresaleCheckType
} from '@/features/PresaleCheck';

export type IGlobalAddingRef = RefObject<IGlobalAddingRefProps>;

export type IGlobalAddingRefProps =
	| IAddingProblemRefProps
	| IAddingInspectionRefProps
	| IAddingAcceptanceRefProps
	| IAddingWarrantyClaimRefProps;

export type IGlobalAddingType =
	| ICheckAddingEntityType
	| IInternalAcceptanceAddingEntityType
	| IPresaleCheckAddingEntityType;

export interface IGlobalAddingPermissions {
	checks?: boolean;
	presaleChecks?: boolean;
	internalAcceptances?: boolean;
	acceptances?: boolean;
	claims?: boolean;
}

export interface IGlobalAddingLinks {
	spaceId?: string;
	structureId?: string;
	workAcceptanceId?: string;
	checkListId?: string;
	checkItemId?: string;
	checkRecordId?: string;
}

interface IGlobalAddingEntityDialogProps {
	objectId: string;
	spaceId?: string;
	links?: IGlobalAddingLinks;
	stage: ObjectStageIds;
	processId: ProcessIds;
	permissions: IGlobalAddingPermissions;
	defaultProblemData?: ISavingProblem;
	defaultInspectionData?: ISavingInspection;
	isOpen: boolean;
	types?: IGlobalAddingType[];
	onClose: () => void;
	onSuccess?: () => void | Promise<void>;
}

const getDefaultSelectedStage = (
	defaultStage: ObjectStageIds,
	entitiesSelectMapProps: Record<ObjectStageIds, Record<ProcessIds, IEntitySelectProps>>
): ObjectStageIds => {
	if (entitiesSelectMapProps?.[defaultStage]) {
		return defaultStage;
	}

	const mapKeys = Object.keys(entitiesSelectMapProps) as ObjectStageIds[];
	return mapKeys[0];
};

const getDefaultSelectedProcess = (
	defaultStage: ObjectStageIds,
	defaultProcess: ProcessIds,
	entitiesSelectMapProps: Record<ObjectStageIds, Record<ProcessIds, IEntitySelectProps>>
): ProcessIds => {
	if (defaultProcess === ProcessIds.ACCEPTANCE_CONTROL) {
		return defaultProcess;
	}
	if (entitiesSelectMapProps?.[defaultStage]?.[defaultProcess]) {
		return defaultProcess;
	}

	const mapKeys = Object.keys(entitiesSelectMapProps) as ObjectStageIds[];
	return Object.keys(entitiesSelectMapProps[mapKeys[0]])[0] as ProcessIds;
};

const GlobalAddingEntityDialog = (props: IGlobalAddingEntityDialogProps) => {
	const {
		objectId,
		spaceId,
		links,
		stage,
		processId,
		permissions,
		defaultProblemData,
		defaultInspectionData,
		isOpen,
		types,
		onClose,
		onSuccess
	} = props;
	const {t} = useTranslation();
	const dispatch = useAppDispatch();
	const {resetFastAddingMode} = modalDialogActions;

	const isFastAddingActive = useAppSelector(extractFastAdding);
	const stages = useTranslatedObjectStagesArray();
	const {data: object} = useObject(objectId);
	const {data: space} = useSpace(links?.spaceId, objectId);

	const addingEntityRef = useRef<IGlobalAddingRefProps>(null);
	const [selectedStage, setSelectedStage] = useState(stage);
	const [selectedProcessId, setSelectedProcessId] = useState(processId);
	const queryClient = useQueryClient();

	const [selectedCheckId, setSelectedCheckId] = useState<string | undefined>();
	const [selectedPresaleCheckId, setSelectedPresaleCheckId] = useState<string | undefined>();

	const [selectedInternalAcceptanceId, setSelectedInternalAcceptanceId] = useState<
		string | undefined
	>();
	const [selectedOwnerAcceptanceId, setSelectedOwnerAcceptanceId] = useState<
		string | undefined
	>();
	const [selectedWarrantyClaimId, setSelectedWarrantyClaimId] = useState<string | undefined>();

	const [ownerAcceptanceView, setOwnerAcceptanceView] = useState<AddingOwnerAcceptanceView>();
	const [warrantyClaimView, setWarrantyClaimView] = useState<AddingWarrantyClaimView>();

	const localOwnerAcceptances = useLocalOwnerAcceptances();
	const localOwnerAcceptancesIds = localOwnerAcceptances.map(acp => acp.id);
	const {data: localWarrantyClaims} = useLocalWarrantyClaims();
	const localWarrantyClaimsIds = localWarrantyClaims?.map(claim => claim.id);
	const {data: localChecks} = useLocalChecks();
	const {data: localInternalAcceptances} = useLocalInternalAcceptances();
	const {data: localPresaleChecks} = useLocalPresaleChecks();
	const online = useAppSelector(s => s.offlineMode.networkStatus);
	const [saving, setSaving] = useState(false);
	const success = useRef<boolean>(false);

	const getSubtitle = useEntitySelectContainerSubTitle();
	const getSubSubtitle = useEntitySelectContainerSubSubTitle();

	const [contentMap, entitiesSelectMapProps] = useEntitiesMaps({
		objectId,
		spaceId,
		links,
		permissions,
		addingEntityRef,
		defaultProblemData,
		defaultInspectionData,
		types,
		saving,
		setSaving,
		selectedCheckId,
		selectedPresaleCheckId,
		selectedInternalAcceptanceId,
		selectedOwnerAcceptanceId,
		selectedWarrantyClaimId,
		setSelectedCheckId,
		setSelectedInternalAcceptanceId,
		setSelectedOwnerAcceptanceId,
		setSelectedWarrantyClaimId,
		setSelectedPresaleCheckId,
		ownerAcceptanceView,
		setOwnerAcceptanceView,
		warrantyClaimView,
		setWarrantyClaimView,
		onClose
	});

	useEffect(() => {
		setSelectedStage(getDefaultSelectedStage(stage, entitiesSelectMapProps));
		setSelectedProcessId(getDefaultSelectedProcess(stage, processId, entitiesSelectMapProps));
	}, [stage, processId]);

	const reset = useCallback(() => {
		setSelectedStage(getDefaultSelectedStage(stage, entitiesSelectMapProps));
		setSelectedProcessId(getDefaultSelectedProcess(stage, processId, entitiesSelectMapProps));
	}, [stage, entitiesSelectMapProps, processId]);

	const handleClose = () => {
		onClose();
		dispatch(resetFastAddingMode());
	};
	const handleAfterClose = useCallback(() => {
		if (success.current && onSuccess) {
			success.current = false;
			void onSuccess();
			reset();
		}
	}, [onSuccess, reset]);

	const addCheck = useAddCheck(object);
	const addInternalAcceptance = useAddInternalAcceptance(object);

	const addPresaleCheck = useAddPresaleCheck(object);

	const availableCheckTypes = useAvailableCheckTypes(objectId, types);
	const checkType = useEntityCheckType(objectId, availableCheckTypes);
	const availableAcceptanceTypes = useAvailableAcceptanceTypes(objectId, types);
	const acceptanceType = useEntityAcceptanceType(objectId, availableAcceptanceTypes);
	const availablePresaleCheckTypes = useAvailablePresaleCheckTypes(objectId, types);
	const presaleCheckType = useEntityPresaleCheckType(objectId, availablePresaleCheckTypes);
	const isProblemType =
		(checkType === 'problem' && selectedStage === ObjectStageIds.BUILDING) ||
		(acceptanceType === 'problem' &&
			selectedStage === ObjectStageIds.ACCEPTANCE &&
			selectedProcessId === ProcessIds.INTERNAL_ACCEPTANCE) ||
		(ownerAcceptanceView === 'problem' && selectedStage === ObjectStageIds.TRANSFER) ||
		(warrantyClaimView === 'problem' && selectedStage === ObjectStageIds.WARRANTY) ||
		(presaleCheckType === 'problem' &&
			selectedStage === ObjectStageIds.ACCEPTANCE &&
			selectedProcessId === ProcessIds.PRESALE_CONTROL);

	const handleSave = useCallback(async () => {
		const localChecksIds = localChecks?.map(check => check.id) || [];
		if (permissions.checks && selectedProcessId === ProcessIds.OPERATIONAL_CONTROL) {
			const addNewCheck = () =>
				addCheck({
					objectId,
					links,
					stage: selectedStage
				});
			const updateLatest = async () => {
				if (online && selectedCheckId && !localChecksIds.includes(selectedCheckId)) {
					await queryClient.invalidateQueries({
						queryKey: [...checksQueryKeys.detail(selectedCheckId), objectId]
					});
				}
			};

			await saveCheck({
				addingEntityRef,
				selectedCheckId,
				localChecksIds,
				online,
				updateLatest,
				addNewCheck,
				setSaving,
				success,
				onClose,
				isFastAddingActive
			});

			return;
		}

		if (
			(permissions.internalAcceptances &&
				selectedProcessId === ProcessIds.INTERNAL_ACCEPTANCE) ||
			(!permissions.checks &&
				permissions.internalAcceptances &&
				selectedStage === ObjectStageIds.BUILDING)
		) {
			const localInternalAcceptancesIds =
				localInternalAcceptances?.map(acpt => acpt.id) || [];
			const addNewInternalAcceptance = () =>
				addInternalAcceptance({
					objectId,
					links,
					stage: selectedStage
				});
			const updateLatest = async () => {
				if (
					online &&
					selectedInternalAcceptanceId &&
					!localInternalAcceptancesIds.includes(selectedInternalAcceptanceId)
				) {
					await queryClient.invalidateQueries({
						queryKey: [
							...internalAcceptancesQueryKeys.detail(selectedInternalAcceptanceId),
							objectId
						]
					});
				}
			};

			await saveInternalAcceptance({
				addingEntityRef,
				selectedInternalAcceptanceId,
				localInternalAcceptancesIds,
				online,
				updateLatest,
				addNewInternalAcceptance,
				setSaving,
				success,
				onClose,
				isFastAddingActive
			});
			return;
		}

		if (permissions.acceptances && selectedProcessId === ProcessIds.UNITS_HANDOVER) {
			const updateLatest = async () => {
				if (
					online &&
					selectedOwnerAcceptanceId &&
					!localOwnerAcceptancesIds.includes(selectedOwnerAcceptanceId)
				) {
					await queryClient.invalidateQueries({
						queryKey: ownerAcceptancesQueryKeys.detail(selectedOwnerAcceptanceId)
					});
				}
			};
			await saveOwnerAcceptance({
				addingEntityRef,
				localOwnerAcceptancesIds,
				view: ownerAcceptanceView,
				online,
				updateLatest,
				selectedOwnerAcceptanceId,
				success,
				onClose,
				setSaving,
				isFastAddingActive
			});
			return;
		}

		if (permissions.presaleChecks && selectedProcessId === ProcessIds.PRESALE_CONTROL) {
			const localPresaleChecksIds =
				localPresaleChecks?.map(presaleCheck => presaleCheck.id) || [];
			const addNewPresaleCheck = () =>
				addPresaleCheck({
					objectId,
					links
				});

			const updateLatest = async () => {
				if (
					online &&
					selectedPresaleCheckId &&
					!localPresaleChecksIds.includes(selectedPresaleCheckId)
				) {
					await queryClient.invalidateQueries({
						queryKey: [
							...presaleChecksQueryKeys.detail(selectedPresaleCheckId),
							objectId
						]
					});
				}
			};

			await savePresaleCheck({
				addingEntityRef,
				selectedPresaleCheckId,
				localPresaleChecksIds,
				online,
				updateLatest,
				addNewPresaleCheck,
				setSaving,
				success,
				onClose,
				isFastAddingActive
			});

			return;
		}

		if (permissions.claims && selectedProcessId === ProcessIds.WARRANTY_SERVICE) {
			const updateLatest = async () => {
				if (
					online &&
					selectedWarrantyClaimId &&
					localWarrantyClaimsIds?.includes(selectedWarrantyClaimId)
				) {
					await queryClient.invalidateQueries({
						queryKey: [
							...warrantyClaimsQueryKeys.detail(selectedWarrantyClaimId),
							objectId
						]
					});
				}
			};

			await saveClaim({
				addingEntityRef,
				localWarrantyClaimsIds,
				view: warrantyClaimView,
				online,
				updateLatest,
				selectedWarrantyClaimId,
				success,
				onClose,
				setSaving,
				isFastAddingActive
			});
		}
	}, [
		localChecks,
		permissions.checks,
		permissions.internalAcceptances,
		permissions.acceptances,
		permissions.presaleChecks,
		permissions.claims,
		selectedProcessId,
		selectedStage,
		selectedCheckId,
		online,
		onClose,
		isFastAddingActive,
		addCheck,
		objectId,
		links,
		queryClient,
		localInternalAcceptances,
		selectedInternalAcceptanceId,
		addInternalAcceptance,
		localOwnerAcceptancesIds,
		ownerAcceptanceView,
		selectedOwnerAcceptanceId,
		localPresaleChecks,
		selectedPresaleCheckId,
		addPresaleCheck,
		localWarrantyClaimsIds,
		warrantyClaimView,
		selectedWarrantyClaimId
	]);

	const handleCancel = useCallback(() => {
		dispatch(resetFastAddingMode());
		if (addingEntityRef.current) {
			addingEntityRef.current.cancel();
		} else {
			close();
		}
	}, [dispatch, resetFastAddingMode]);

	const handleSelectedStage = (stageValue: ObjectStageIds) => {
		setSelectedStage(stageValue);
		dispatch(resetFastAddingMode());
	};

	return (
		<ModalDialog
			dataTestId="GlobalAddingEntityDialog"
			open={isOpen}
			className={{
				root: 'adding-entity-dialog',
				header: 'adding-entity-dialog__header',
				body: 'adding-entity-dialog__body'
			}}
			fullScreenOnTablet
			onRequestClose={handleClose}
			onAfterClose={handleAfterClose}
			title={t('globalAddingEntityDialog.title')}
			disableScrollBlock
			footer={
				<ModalDialogButtons
					saving={saving}
					handleCancel={handleCancel}
					handleSave={handleSave}
					isProblemType={isProblemType}
				/>
			}
		>
			{stages && (
				<EntitySelectContainer
					contentMap={contentMap}
					entitiesSelectMapProps={entitiesSelectMapProps}
					stages={stages}
					selectedStage={selectedStage}
					selectedProcess={selectedProcessId}
					onSelectStage={handleSelectedStage}
					onSelectProcess={setSelectedProcessId}
					getEntitySelectContainerSubTitle={getSubtitle}
					getEntitySelectContainerSubSubTitle={getSubSubtitle}
					stagesSelectDialogTitle={t(
						'globalAddingEntityDialog.entitySelectContainer.processSelectDialogTitle'
					)}
					stagesSelectDialogSaveLabel={t(
						'globalAddingEntityDialog.entitySelectContainer.stagesSelectDialogSaveLabel'
					)}
					stagesSelectDialogCancelLabel={t(
						'globalAddingEntityDialog.entitySelectContainer.stagesSelectDialogCancelLabel'
					)}
					stageTitle={t(
						'globalAddingEntityDialog.entitySelectContainer.processSelectDialogStageTitle'
					)}
					objectName={object?.name}
					spaceTypeId={space?.type}
					spaceName={space?.name}
				/>
			)}
		</ModalDialog>
	);
};

export default GlobalAddingEntityDialog;
