import {useMutation} from '@tanstack/react-query';
import {queryClient} from '@src/api/QueryClient';
import {makeSpaceAddRequest} from '@src/api/backend/space';
import {spacesQueryKeys} from '@src/api/cache/spaces/keys';
import IError from '@tehzor/tools/interfaces/IError';
import {ISpace} from '@tehzor/tools/interfaces/spaces/ISpace';
import {ISavingSpace} from '@src/interfaces/saving/ISavingSpace';
import {addErrorToast, addSuccessToast} from '@tehzor/ui-components';
import {useTranslation} from 'react-i18next';

export const useAddSpace = () => {
	const {t} = useTranslation();

	return useMutation<ISpace, IError, {objectId: string; fields: ISavingSpace}>({
		mutationKey: spacesQueryKeys.add(),
		mutationFn: ({objectId, fields}: {objectId: string; fields: ISavingSpace}) =>
			makeSpaceAddRequest(objectId, fields),
		onSuccess: () => {
			void queryClient.invalidateQueries({queryKey: spacesQueryKeys.list()});
			addSuccessToast({title: t('toast.addSpace.success.title')});
		},
		onError: () => addErrorToast({title: t('toast.addSpace.error.title')})
	});
};
