import {Suspense, useCallback, useState} from 'react';
import * as React from 'react';
import {MovingInternalAcceptanceDialog} from '../MovingInternalAcceptanceDialog';
import {ILinkedInternalAcceptance} from '@tehzor/tools/interfaces/internalAcceptances/ILinkedInternalAcceptance';

export function useMovingInternalAcceptanceDialog(
	objectId: string,
	internalAcceptance: ILinkedInternalAcceptance
): [React.ReactNode, () => void] {
	const [isOpen, setOpen] = useState<boolean>(false);

	const open = useCallback(() => {
		setOpen(true);
	}, []);

	const close = useCallback(() => {
		setOpen(false);
	}, []);

	const dialog = (
		<Suspense>
			<MovingInternalAcceptanceDialog
				objectId={objectId}
				internalAcceptance={internalAcceptance}
				isOpen={isOpen}
				onClose={close}
			/>
		</Suspense>
	);

	return [dialog, open];
}
