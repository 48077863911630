import {EntityTypeId} from '@tehzor/tools/interfaces/fields';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {IGetListFieldSettingsSetsResponse} from '@/shared/api/fieldsSettingsSets/getFieldsSettingsSets';

export const extractFieldSettingsSetsListMap = (data: IGetListFieldSettingsSetsResponse) =>
	data.byId;

export const extractFieldSettingsSetsListArray = (data: IGetListFieldSettingsSetsResponse) =>
	data.allIds.map(id => data.byId[id]);

export const extractFieldSettingsSetById = (data: IGetListFieldSettingsSetsResponse, id: string) =>
	data.byId[id];

export const extractFieldSettingsSetsByObjectId = (
	data: IGetListFieldSettingsSetsResponse,
	entityTypeId: EntityTypeId,
	companyId?: string,
	objectId?: string,
	stage?: ObjectStageIds
) => {
	const sets = extractFieldSettingsSetsListArray(data);

	return sets?.filter(set => {
		const hasEntity = set.entityType.id === entityTypeId;
		const hasCompanyId = companyId ? set.companyId === companyId : true;
		const hasObjectId =
			objectId && !!set.objectIds.length ? set.objectIds.includes(objectId) : true;
		const hasStages = stage ? set.stageIds.includes(stage) : true;

		return hasCompanyId && hasEntity && hasObjectId && hasStages;
	});
};
