import {useQuery} from '@tanstack/react-query';
import {presaleChecksQueryKeys} from '../../../keys';
import {IGetPresaleChecksResponse} from '@/shared/api/presaleChecks/getPresaleChecks';
import {usePresaleChecksPermissions} from '../../../../hooks/usePresaleChecksPermissions';

export const usePresaleChecks = <T = IGetPresaleChecksResponse>(
	objectId: string,
	select?: (data: IGetPresaleChecksResponse) => T
) => {
	const permission = usePresaleChecksPermissions();

	return useQuery({
		queryKey: [...presaleChecksQueryKeys.list(), objectId],
		meta: {
			error: 'при загрузке предпродажных проверок'
		},
		select,
		enabled: permission.canView
	});
};
