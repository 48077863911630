import {httpRequests} from '@tehzor/tools/api/httpRequests';

/**
 * Генерирует помещения из планов
 *
 * @param objectId id объекта
 * @param plans массив выбранных планов, по которым будут генерироваться помещения
 * @param withSpacesPlans флаг для определения необходимости генерации планов помещений
 */
export const makeGenerateSpacesRequest = async (
	objectId: string,
	withSpacesPlans: boolean,
	plans?: string[]
) => {
	const response = await httpRequests.withToken.post<void>('/spaces/generate', {
		objectId,
		withSpacesPlans,
		plans
	});
	return response.data;
};
