import {IGetListProblemsResponse} from '@src/api/backend/problems';
import {useQuery} from '@tanstack/react-query';
import {presaleChecksQueryKeys} from '../../../keys';

export const usePresaleCheckProblems = <T = IGetListProblemsResponse>(
	objectId: string,
	presaleCheckId: string,
	select?: (data: IGetListProblemsResponse) => T
) =>
	useQuery({
		queryKey: presaleChecksQueryKeys.presaleCheckProblems(objectId, presaleCheckId),
		select
	});
