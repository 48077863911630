import {IPreparedSpaceStatusesSet} from '@src/pages/manage/SpaceStatusesSetsPage/interfaces/IPreparedSpaceStatusesSet';
import {IconButton} from '@tehzor/ui-components';
import {useContext} from 'react';
import {CellProps} from 'react-table';
import {useAsyncFn} from 'react-use';
import {DispatchActionCtx} from '../SpaceStatusesSetsTable';
import {useTranslation} from 'react-i18next';
import {useTranslatedConfirmDialog} from '@src/core/hooks/translations/useTranslatedConfirmDialog';

const deleteIcon = <i className="tz-delete" />;

export const DeleteCell = ({row}: CellProps<IPreparedSpaceStatusesSet>) => {
	const canDelete = row.original.canDelete;

	const {t} = useTranslation();
	const dispatchAction = useContext(DispatchActionCtx);

	const [deleteDialog, getDeleteConfirmation] = useTranslatedConfirmDialog({
		title: t('useConfirmDialog.categorySet.deleteTitle'),
		message: t('useConfirmDialog.categorySet.deleteResetMessage')
	});

	const [, handleDelete] = useAsyncFn(async () => {
		if (await getDeleteConfirmation()) {
			dispatchAction({type: 'delete', payload: row.original});
		}
	}, [dispatchAction, row.original]);

	return canDelete ? (
		<>
			<IconButton
				className="space-statuses-sets__cell-delete"
				type="inline-red-accent"
				onClick={handleDelete}
			>
				{deleteIcon}
			</IconButton>
			{deleteDialog}
		</>
	) : null;
};
