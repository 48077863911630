import {queryClient} from '@src/api/QueryClient';
import {IGetCheckItemsResponse} from '@src/api/backend/checkItems';
import {
	IHardDeleteCheckItemResponse,
	makeCheckItemHardDeleteRequest
} from '@src/api/backend/checkItems/hard-delete';
import {checkItemsQueryKeys} from '@src/api/cache/checkItems/keys';
import {useMutation} from '@tanstack/react-query';
import IError from '@tehzor/tools/interfaces/IError';
import {useCallback} from 'react';
import {addErrorToast, addSuccessToast} from '@tehzor/ui-components';
import {useTranslation} from 'react-i18next';

interface ICheckItemHardDeleteParams {
	checkListId: string;
	id: string;
}

export const useHardDeleteCheckItem = () => {
	const {t} = useTranslation();
	const mutation = useMutation<IHardDeleteCheckItemResponse, IError, ICheckItemHardDeleteParams>({
		mutationFn: (params: ICheckItemHardDeleteParams) =>
			makeCheckItemHardDeleteRequest(params.checkListId, params.id),

		onSuccess: data => {
			queryClient.setQueryData<IGetCheckItemsResponse>(
				checkItemsQueryKeys.list(),
				oldData => {
					if (oldData) {
						const newAllIds = oldData.allIds.filter(
							id => !data.deletedIds.includes(id)
						);
						const newById = newAllIds.reduce((prev, current) => {
							prev[current] =
								data.editedItems.allIds.includes(current) &&
								data.editedItems.byId[current]
									? data.editedItems.byId[current]
									: oldData.byId[current];
							return prev;
						}, {});

						return {
							allIds: newAllIds,
							byId: newById
						};
					}
					return oldData;
				}
			);
			addSuccessToast({title: t('toast.hardDeleteCheckItem.success.title')});
		},
		onError: () => {
			addErrorToast({title: t('toast.hardDeleteCheckItem.error.title')});
		}
	});
	/**
	 * Возвращает функцию для софт удаления категории внутри чек-листа
	 *
	 * @param item данные новой категории
	 */
	return useCallback(
		(params: ICheckItemHardDeleteParams) => {
			mutation.mutate(params);
		},
		[mutation]
	);
};
