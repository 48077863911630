import {useMemo} from 'react';
import {useStructuresBreadcrumbs} from './useStructuresBreadcrumbs';
import {formStructureLink} from '@tehzor/tools/utils/links';
import {useStructure} from '@/entities/Structure';

/**
 * Хук для хлебных крошек от "всех объектов" до структуры
 *
 * @param objectId id конечного объекта
 * @param structureId id структуры
 */
export function useStructureBreadcrumbs(objectId: string, structureId: string) {
	const res = useStructuresBreadcrumbs(objectId);
	const {data: structure} = useStructure(objectId);

	return useMemo(
		() =>
			res.concat({
				label: structure ? structure.name : '',
				url: formStructureLink(objectId, structureId),
				inactive: false
			}),
		[objectId, res, structure, structureId]
	);
}
