import {memo, useCallback} from 'react';
import {CellProps} from 'react-table';
import {ICustomField} from '@tehzor/tools/interfaces/fields';
import {useFieldsPermissions} from '@/shared/hooks/permissions/useFieldsPermissions';
import {useDeleteCustomFiled} from '@/entities/Fields';
import {IconButton} from '@tehzor/ui-components';
import styles from '../../CustomFieldsTable.module.less';
import {useTranslatedConfirmDialog} from '@src/core/hooks/translations/useTranslatedConfirmDialog';
import {useTranslation} from 'react-i18next';

const deleteIcon = <i className="tz-delete" />;

export const DeleteCell = memo(({row}: CellProps<ICustomField>) => {
	const {id} = row.original;
	const {t} = useTranslation();
	const {mutateAsync: deleteCustomField} = useDeleteCustomFiled();
	const {canDeleteFields} = useFieldsPermissions();
	const [deleteDialog, getDeleteConfirmation] = useTranslatedConfirmDialog({
		title: t('useConfirmDialog.customField.deleteTitle'),
		message: t('useConfirmDialog.customField.deleteMessage')
	});
	const handleDelete = useCallback(async () => {
		if (canDeleteFields && (await getDeleteConfirmation())) {
			await deleteCustomField(id);
		}
	}, [canDeleteFields, deleteCustomField, getDeleteConfirmation, id]);

	if (!canDeleteFields) return null;
	return (
		<div className={styles.deleteCell}>
			<IconButton
				type="inline-red-accent"
				onClick={handleDelete}
			>
				{deleteIcon}
			</IconButton>
			{deleteDialog}
		</div>
	);
});
