import {useTranslation} from 'react-i18next';
import {FilterMenu} from '@tehzor/ui-components';

export const ProcessFilter = () => {
	const {t} = useTranslation();

	return (
		<FilterMenu
			label={t('structuresPage.processFilter.label')}
			disabled
		/>
	);
};
