import {httpRequests} from '@tehzor/tools/api/httpRequests';
import {IObjectsWithStructuresRequest} from '@tehzor/tools/interfaces/objects/IObjectsWithStructuresRequest';

export const getTargetObjectIdsHaveStructures = async ({
	objectId,
	filter,
	includingParentObjectId = false
}: IObjectsWithStructuresRequest) => {
	const response = await httpRequests.withToken.post<string[]>('/objects/ids-with-structures', {
		objectId,
		filter,
		includingParentObjectId
	});

	return response.data;
};
