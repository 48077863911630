import {LinkButton} from '@tehzor/ui-components';
import {useCallback} from 'react';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {useTranslation} from 'react-i18next';
import {usePresaleCheck} from '@/entities/PresaleCheck';
import {formPresaleCheckLink} from '@tehzor/tools/utils/links/formPresaleCheckLink';

export const PresaleCheckEntityLink = ({presaleCheckId, objectId}: Record<string, string>) => {
	const {pushPath} = useChangePath();
	const {data: presaleCheck} = usePresaleCheck(presaleCheckId);

	const {t} = useTranslation();

	const handleClick = useCallback(() => {
		pushPath(formPresaleCheckLink(objectId, presaleCheckId));
	}, [presaleCheckId, objectId]);

	if (!presaleCheck) {
		return null;
	}

	return (
		<LinkButton
			className="entity-links__link"
			label={`${t('components.entityLinks.presaleCheckEntityLink.label')} №${
				presaleCheck.number
			}`}
			leftIcon={<i className="tz-external-link-16" />}
			onClick={handleClick}
			type="filled"
		/>
	);
};
