import {useStrictParams} from '@src/core/hooks/useStrictParams';
import {useFieldsSettingsSet} from '@/entities/FieldsSettingsSets';
import {Main} from './Main/Main';
import {MainSkeleton} from './Main/MainSkeleton';
import {Wrapper} from '@/shared/ui/EntitiesPage/Wrapper/Wrapper';
import {Controls} from '@/shared/ui/EntitiesPage/Controls/Controls';
import {TopBar} from '@/shared/ui/EntitiesPage/TopBar/TopBar';
import {useAppHeader} from '@src/components/AppHeader/hooks/useAppHeader';
import {ActionsMenu} from '../components/ActionsMenu/ActionsMenu';
import {getFieldsSettingsSetPageBreadcrumbs} from '../model/utils/getFieldsSettingsSetPageBreadcrumbs';

interface IFieldsSettingsSetPageProps {
	isAddPage?: boolean;
}

export const FieldsSettingsSetPage = ({isAddPage}: IFieldsSettingsSetPageProps) => {
	const {fieldsSettingsSetId} = useStrictParams<{fieldsSettingsSetId: string}>();
	const {data: fieldsSettingsSet, isFetching} = useFieldsSettingsSet({
		setId: fieldsSettingsSetId
	});

	useAppHeader({
		title: isAddPage ? 'Создание набора настроек полей' : 'Набор настроек полей',
		mobileRightButtons: fieldsSettingsSet && <ActionsMenu setId={fieldsSettingsSet.id} />,
		showBackBtn: true
	});

	const breadcrumbs = getFieldsSettingsSetPageBreadcrumbs(fieldsSettingsSet);

	return (
		<Wrapper>
			<Controls>
				<TopBar breadcrumbs={breadcrumbs}>
					{fieldsSettingsSet && <ActionsMenu setId={fieldsSettingsSet.id} />}
				</TopBar>
			</Controls>
			{isFetching ? <MainSkeleton /> : <Main fieldsSettingsSet={fieldsSettingsSet} />}
		</Wrapper>
	);
};
