import {useFieldEntityTypes} from './useFieldEntityTypes';
import {extractFieldsEntityTypeById} from '../selectors/extractFieldsEntityTypeById';
import {useCallback} from 'react';
import {IGetFieldEntityTypesResponse} from '@src/api/backend/fieldTypes';

export const useFieldEntityTypeById = (entityTypeId: string) => {
	const selector = useCallback(
		(data: IGetFieldEntityTypesResponse) => extractFieldsEntityTypeById(data, entityTypeId),
		[entityTypeId]
	);

	return useFieldEntityTypes(selector);
};
