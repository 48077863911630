import {IconMenu, MenuItem} from '@tehzor/ui-components';
import {ILinkedWarrantyClaim} from '@tehzor/tools/interfaces/warrantyClaims/ILinkedWarrantyClaim';
import {useMovingWarrantyClaimDialog} from '@src/components/MovingWarrantyClaimDialog/hooks/useMovingWarrantyClaimDialog';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {useAppUpdateMenuItem} from '@src/components/AppUpdateData';
import {useEditWarrantyClaimDialog} from '../../hooks/useEditWarrantyClaimDialog';
import {useTranslation} from 'react-i18next';
import {useCallback} from 'react';
import {useDeleteWarrantyClaim} from '@src/core/hooks/mutations/warrantyClaims/useDeleteWarrantyClaim';
import {useTranslatedConfirmDialog} from '@src/core/hooks/translations/useTranslatedConfirmDialog';

interface IMobileMenuProps {
	objectId: string;
	warrantyClaim: ILinkedWarrantyClaim;
	canDeleteWarrantyClaim?: boolean;
	canMoveWarrantyClaim?: boolean;
	canEditWarrantyClaim?: boolean;
}

const MobileMenu = ({
	warrantyClaim,
	canDeleteWarrantyClaim,
	canMoveWarrantyClaim,
	canEditWarrantyClaim
}: IMobileMenuProps) => {
	const {t} = useTranslation();
	const {goBack} = useChangePath();

	const {mutate: deleteWarrantyClaim} = useDeleteWarrantyClaim();
	const [deleteDialog, getDeleteConfirmation] = useTranslatedConfirmDialog({
		title: t('useConfirmDialog.warrantyClaim.deleteTitle'),
		message: t('useConfirmDialog.warrantyClaim.deleteMessage', {id: warrantyClaim.number})
	});

	const [warrantyClaimDialog, openWarrantyClaimDialog] =
		useEditWarrantyClaimDialog(warrantyClaim);

	// Кнопка обновления данных приложения
	const updateData = useAppUpdateMenuItem();

	const handleDeleteClick = useCallback(async () => {
		if (await getDeleteConfirmation()) {
			deleteWarrantyClaim({
				objectId: warrantyClaim.objectId,
				warrantyClaimId: warrantyClaim.id
			});
			goBack();
		}
	}, [
		getDeleteConfirmation,
		deleteWarrantyClaim,
		warrantyClaim.objectId,
		warrantyClaim.id,
		goBack
	]);

	// Диалог переноса обращения
	const [moveDialog, openMoveDialog] = useMovingWarrantyClaimDialog(
		warrantyClaim.objectId,
		warrantyClaim
	);

	const items = [];

	items.push(updateData);

	if (canMoveWarrantyClaim) {
		items.push(
			<MenuItem
				key="move"
				icon={<i className="tz-long-arrow-24" />}
				onClick={openMoveDialog}
			>
				{t('warrantyClaimPage.actions.menu.menuItem.move')}
			</MenuItem>
		);
	}

	if (canEditWarrantyClaim) {
		items.push(
			<MenuItem
				key="edit"
				icon={<i className="tz-edit-20" />}
				onClick={openWarrantyClaimDialog}
			>
				{t('warrantyClaimPage.actions.menu.menuItem.edit')}
			</MenuItem>
		);
	}

	if (canDeleteWarrantyClaim) {
		items.push(
			<MenuItem
				key="delete"
				icon={<i className="tz-delete" />}
				onClick={handleDeleteClick}
			>
				{t('warrantyClaimPage.actions.menu.menuItem.delete')}
			</MenuItem>
		);
	}

	return items.length ? (
		<>
			<IconMenu>{items}</IconMenu>

			{canDeleteWarrantyClaim && deleteDialog}
			{canMoveWarrantyClaim && moveDialog}
			{canEditWarrantyClaim && warrantyClaimDialog}
		</>
	) : null;
};

export default MobileMenu;
