import AppBreadcrumbs from '@src/components/AppBreadcrumbs';
import {useSpaceStatusesSetBreadcrumbs} from '@src/core/hooks/breadcrumbs/useSpaceStatusesSetBreadcrumbs';
import {useSpaceStatusesSetPermissions} from '@src/core/hooks/permissions/useSpaceStatusesSetsPermissions';
import {useExtractSpaceStatusesSetsForPage} from '@src/core/hooks/queries/spaceStatusesSets/hooks';
import useScrollRestoration from '@src/core/hooks/useScrollRestoration';
import {addErrorToast, LoadingPanel} from '@tehzor/ui-components';
import {IBreadcrumb} from '@tehzor/ui-components/src/components/navigation/Breadcrumbs';
import {useEffect} from 'react';
import {AddButton} from './components/actions/AddButton';
import {SpaceStatusesSetsFilters} from './components/SpaceStatusesSetsFilters';
import {SpaceStatusesSetsPageHeader} from './components/SpaceStatusesSetsPageHeader';
import {SpaceStatusesSetsTable} from './components/table/SpaceStatusesSetsTable';
import './SpaceStatusesSetsPage.less';
import {useSpaceStatuses} from '@src/core/hooks/queries/spaceStatuses/hooks';
import {useTranslation} from 'react-i18next';

export const SpaceStatusesSetsPage = () => {
	useScrollRestoration();
	const {t} = useTranslation();

	const {canView, canAdd} = useSpaceStatusesSetPermissions();

	useEffect(() => {
		if (!canView) {
			addErrorToast({title: t('toast.canView.error.title')});
		}
	}, [canView]);

	const breadcrumbs: IBreadcrumb[] = useSpaceStatusesSetBreadcrumbs();

	const {data: setsData} = useExtractSpaceStatusesSetsForPage();

	const {isLoading} = useSpaceStatuses();

	if (!canView) {
		return null;
	}
	return (
		<LoadingPanel active={isLoading}>
			<div className="space-statuses-sets">
				<AppBreadcrumbs
					className="space-statuses-sets__breadcrumbs"
					items={breadcrumbs}
				>
					<AddButton canAdd={canAdd} />
				</AppBreadcrumbs>
				<SpaceStatusesSetsFilters />
				<SpaceStatusesSetsPageHeader total={setsData?.total ?? 0} />
				{setsData && <SpaceStatusesSetsTable {...setsData} />}
			</div>
		</LoadingPanel>
	);
};
