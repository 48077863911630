import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {IEditSpaceIndicatorsResponse} from '@src/api/backend/space/editIndicators';
import {addErrorToast} from '@tehzor/ui-components';
import {queryClient} from '../../../../../api/QueryClient';
import {spacesQueryKeys} from '../../../../../api/cache/spaces/keys';
import {t} from 'i18next';

export type IEditSpaceIndicatorsPayload = IEditSpaceIndicatorsResponse;

const request = () => ({type: types.EDIT_INDICATORS_REQUEST});

const success = (response: IEditSpaceIndicatorsResponse, pageObjectId: string) => {
	void queryClient.invalidateQueries({queryKey: spacesQueryKeys.list()});
	return {
		type: types.EDIT_INDICATORS_SUCCESS,
		payload: {...response, pageObjectId}
	};
};

const failure = (error: IError) => {
	addErrorToast({title: t('toast.editSpaceIndicators.error.title')});
	return {
		type: types.EDIT_INDICATORS_FAILURE,
		payload: error
	};
};

export const editSpaceIndicatorsActions = {request, failure, success};
