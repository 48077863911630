import {makeSpaceOwnerAddRequest, makeSpaceOwnerEditRequest} from '@src/api/backend/spaceOwner';
import {useQueryClient} from '@tanstack/react-query';
import {ISpaceOwner} from '@tehzor/tools/interfaces/spaceOwners/ISpaceOwner';
import {ISavingSpaceOwner} from '@tehzor/tools/interfaces/spaceOwners/ISavingSpaceOwner';
import {spaceOwnersQueryKeys} from '../keys';
import {ownerAcceptancesQueryKeys} from '../../ownerAcceptances/keys';
import {useAddOwnerAcceptanceMutation} from '../../ownerAcceptances/mutations';
import {IListOwnerAcceptanceSavingData} from '@src/core/hooks/mutations/ownerAcceptances/utils/convertToLocalSave';
import {addErrorToast} from '@tehzor/ui-components';
import {useTranslation} from 'react-i18next';

export interface IAddSpaceOwnerParams {
	companyId: string;
	fields: ISavingSpaceOwner;
	key: string;
}

export interface IEditSpaceOwnerParams {
	spaceOwnerId: string;
	companyId: string;
	fields: ISavingSpaceOwner;
}

/**
 * Хук для предачи дефолтной функции мутации в QueryClient
 *  - Дефолтная функия нужна для того, чтобы не указывть её в самом хуке мутации явно
 *  - Если после запуска приложения в кэше будет лежать незаврешенная мутация для этого ключа,
 * 		то для повтра мутации будет использована mutationFn отсюда
 */
export const useSpaceOwnersMutationDefaults = () => {
	const queryClient = useQueryClient();
	const addOwnerAcceptance = useAddOwnerAcceptanceMutation();
	const {t} = useTranslation();

	queryClient.setMutationDefaults(spaceOwnersQueryKeys.add(), {
		onMutate: (variables: IAddSpaceOwnerParams) => ({variables}),
		mutationFn: async (params: IAddSpaceOwnerParams) => {
			const {companyId, fields} = params;
			return makeSpaceOwnerAddRequest(companyId, fields);
		},
		scope: {
			id: 'space-owner'
		},
		onSuccess: async (newSpaceOwner: ISpaceOwner, {key}: IAddSpaceOwnerParams) => {
			queryClient.removeQueries({queryKey: ownerAcceptancesQueryKeys.savingData(key)});
			const ownerAcceptancesQueryData = queryClient
				.getQueriesData({queryKey: ownerAcceptancesQueryKeys.savingDatas()})
				.filter(([, i]) => !!i)
				.map(([, data]) => data) as IListOwnerAcceptanceSavingData[];

			if (ownerAcceptancesQueryData.length) {
				ownerAcceptancesQueryData.forEach(query => {
					if (query.spaceOwnerId === key) {
						addOwnerAcceptance.mutate({
							objectId: query.objectId,
							links: query.links,
							key: query.id,
							fields: {...query.fields, spaceOwnerId: newSpaceOwner.id}
						});
					}
				});
			}

			// Удаляем кешированные данные локального собственника
			queryClient.removeQueries({queryKey: spaceOwnersQueryKeys.detail(key)});

			await queryClient.refetchQueries({
				queryKey: spaceOwnersQueryKeys.localList()
			});
			await queryClient.invalidateQueries({queryKey: spaceOwnersQueryKeys.list()});
		},
		onError: () => {
			addErrorToast({title: t('toast.addSpaceOwner.error.title')});
		},
		retry: 1,
		retryDelay: 10 * 1000
	});

	queryClient.setMutationDefaults(spaceOwnersQueryKeys.edit(), {
		mutationFn: async ({spaceOwnerId, fields}: IEditSpaceOwnerParams) =>
			makeSpaceOwnerEditRequest(spaceOwnerId, fields),
		onError: () => {
			addErrorToast({title: t('toast.editSpaceOwner.error.title')});
		},
		onSuccess: async (data: ISpaceOwner, {spaceOwnerId}: IEditSpaceOwnerParams) => {
			await queryClient.invalidateQueries({
				queryKey: spaceOwnersQueryKeys.detail(spaceOwnerId)
			});
			await queryClient.invalidateQueries({
				queryKey: spaceOwnersQueryKeys.list()
			});
		}
	});
};
