import {useCallback} from 'react';
import {InspectionsPage} from './InspectionsPage';
import {useObjectAppHeader} from '@src/components/AppHeader/hooks/useObjectAppHeader';
import {useObjectSectionsMenu} from '@src/core/hooks/useObjectSectionsMenu';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractInspectionsPageSettings} from '@src/store/modules/settings/pages/inspections/selectors';
import EntitiesFiltersProvider from '@src/components/EntitiesFilters/utils/EntitiesFiltersProvider';
import ActionsMobile from './components/actions/Actions.mobile';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import {inspectionsActions} from '@src/store/modules/settings/pages/inspections/slice';
import {IInspectionsFiltersState} from '@src/store/modules/settings/pages/inspections/interfaces';
import {useTargetObjects} from '@src/core/hooks/queries/objects/hooks';

const InspectionsPageWrap = () => {
	const dispatch = useAppDispatch();
	const {objectId} = useStrictParams<{objectId: string}>();
	const {data: descendants} = useTargetObjects(objectId, false);

	const sectionsMenu = useObjectSectionsMenu(objectId);

	useObjectAppHeader(objectId, {
		sectionsMenu,
		mobileRightButtons: <ActionsMobile objectId={objectId} />
	});

	const {filters} = useAppSelector(s => extractInspectionsPageSettings(s, objectId));
	const {changeFilters, changeOffset, clearFilters} = inspectionsActions;

	const applyFilters = useCallback(
		(value: IInspectionsFiltersState) => {
			dispatch(changeFilters({objectId, filters: value}));
			dispatch(changeOffset({objectId, offset: 0}));
		},
		[objectId, dispatch, changeFilters, changeOffset]
	);

	const onClear = useCallback(() => {
		dispatch(clearFilters({objectId, isDescendants: !!descendants?.length}));
	}, [objectId, dispatch, clearFilters]);

	return (
		<EntitiesFiltersProvider
			objectId={objectId}
			filters={filters}
			onApply={applyFilters}
			onClear={onClear}
		>
			<InspectionsPage
				key={objectId}
				className={sectionsMenu.length ? 'page-cont_extra-top-margin' : undefined}
			/>
		</EntitiesFiltersProvider>
	);
};

export default InspectionsPageWrap;
