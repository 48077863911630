import {
	IEditableEntityAction,
	IEditableEntityState,
	isEntityEdited
} from '@tehzor/tools/core/states/editableEntityState';
import ILocation from '@tehzor/tools/interfaces/ILocation';
import {ISavingWorkAcceptance} from '@src/interfaces/saving/ISavingWorkAcceptance';
import {IWorkAcceptance} from '@tehzor/tools/interfaces/workAcceptances/IWorkAcceptance';
import {isEqual} from 'lodash';
import {WorkAcceptanceTypeId} from '@tehzor/tools/interfaces/workAcceptances/IWorkAcceptanceType';
import {WorkAcceptanceFrontTypeId} from '@tehzor/tools/interfaces/workAcceptances/IWorkAcceptanceFrontType';
import IAttachment from '@tehzor/tools/interfaces/IAttachment';
import IObjectFieldSetting from '@tehzor/tools/interfaces/objects/IObjectFieldSetting';
import {IRuleParams} from '@tehzor/tools/utils/responsibilityRules/IRuleParams';

export type IEditableWorkAcceptanceState = IEditableEntityState<{
	description: string;
	categoryId?: string | null;
	acceptanceDate?: number;
	acceptors: string[];
	acceptorsActiveGroup?: string;
	submitters: string[];
	submittersActiveGroup?: string;
	structureIds: string[];
	spaceIds: string[];
	type?: WorkAcceptanceTypeId;
	frontType?: WorkAcceptanceFrontTypeId;
	locations: Record<string, {planId: string; location: ILocation}>;
	workScope?: string;
	unit?: string;
	unitValue?: string;
	unitPlan?: string;
	unitPlanValue?: string;
	contractorLegalEntityId?: string;
	attachments: IAttachment[];
	initialRuleParams?: IRuleParams;
}>;

export type IEditableWorkAcceptanceAction = IEditableEntityAction<
	IEditableWorkAcceptanceState,
	ISavingWorkAcceptance
>;

const makeEmptyState = (): IEditableWorkAcceptanceState => ({
	description: '',
	categoryId: undefined,
	acceptanceDate: undefined,
	acceptors: [],
	acceptorsActiveGroup: undefined,
	submitters: [],
	submittersActiveGroup: undefined,
	initialRuleParams: undefined,
	structureIds: [],
	spaceIds: [],
	locations: {},
	workScope: undefined,
	unitPlan: undefined,
	unitPlanValue: '',
	unit: undefined,
	unitValue: '',
	contractorLegalEntityId: '',
	type: undefined,
	frontType: undefined,
	attachments: [],
	customFields: {},
	errors: {
		description: false,
		categoryId: false,
		acceptanceDate: false,
		acceptors: false,
		acceptorsActiveGroup: false,
		submitters: false,
		submittersActiveGroup: false,
		initialRuleParams: false,
		structureIds: false,
		locations: false,
		spaceIds: false,
		type: false,
		frontType: false,
		workScope: false,
		unitPlan: false,
		unitPlanValue: false,
		unit: false,
		unitValue: false,
		contractorLegalEntityId: false,
		customFields: {}
	}
});

const locationsFromWorkAcceptance = (
	workAcceptance?: IWorkAcceptance
): Record<string, {planId: string; location: ILocation}> => {
	const workAcceptanceFront =
		workAcceptance?.frontType === WorkAcceptanceFrontTypeId.SPACES
			? workAcceptance.spaces
			: workAcceptance?.frontType === WorkAcceptanceFrontTypeId.STRUCTURES
			? workAcceptance.structures
			: undefined;

	if (!workAcceptanceFront) {
		return {};
	}

	return workAcceptanceFront.reduce(
		(acc: Record<string, {planId: string; location: ILocation}>, item) => {
			if (item.id && item.planId && item.location) {
				acc[item.id] = {planId: item.planId, location: item.location};
			}
			return acc;
		},
		{}
	);
};

export const init = (original?: IWorkAcceptance): IEditableWorkAcceptanceState => {
	const empty = makeEmptyState();
	const locations = locationsFromWorkAcceptance(original);

	const spaceIds = original?.spaces?.map(space => space.id);
	const structureIds = original?.structures?.map(structure => structure.id);

	return original
		? {
				categoryId: original.categoryId ?? undefined,
				description: original.description ?? '',
				acceptanceDate: original.acceptanceDate ?? undefined,
				acceptors: original.acceptors ?? [],
				acceptorsActiveGroup: original.acceptorsActiveGroup ?? undefined,
				submitters: original.submitters ?? [],
				submittersActiveGroup: original.submittersActiveGroup ?? undefined,
				initialRuleParams: {spaceIds, structureIds, categoryId: original.categoryId},
				structureIds: structureIds ?? [],
				workScope: original.workScope ?? undefined,
				unitPlan: original.planPhysicalWorkScope?.unitId ?? undefined,
				unitPlanValue: original.planPhysicalWorkScope?.value ?? '',
				unit: original.physicalWorkScope?.unitId ?? undefined,
				unitValue: original.physicalWorkScope?.value ?? '',
				contractorLegalEntityId: original.contractorLegalEntity?.id ?? '',
				spaceIds: spaceIds ?? [],
				locations,
				type: original.type,
				frontType: original.frontType,
				errors: empty.errors,
				attachments: original.attachments ?? [],
				customFields: original.customFields
		  }
		: {...empty};
};

const isDescriptionEdited = (state: IEditableWorkAcceptanceState, original?: IWorkAcceptance) =>
	original?.description ? original.description !== state.description : !!state.description;

const isTypeEdited = (state: IEditableWorkAcceptanceState, original?: IWorkAcceptance) =>
	original?.type ? original.type !== state.type : !!state.type;

const isFrontTypeEdited = (state: IEditableWorkAcceptanceState, original?: IWorkAcceptance) =>
	original?.frontType ? original.frontType !== state.frontType : !!state.frontType;

const isCategoryEdited = (state: IEditableWorkAcceptanceState, original?: IWorkAcceptance) =>
	original?.categoryId ? original.categoryId !== state.categoryId : !!state.categoryId;

const isAcceptanceDateEdited = (state: IEditableWorkAcceptanceState, original?: IWorkAcceptance) =>
	original?.acceptanceDate
		? original.acceptanceDate !== state.acceptanceDate
		: !!state.acceptanceDate;

const areAcceptorsEdited = (state: IEditableWorkAcceptanceState, original?: IWorkAcceptance) =>
	original?.acceptors
		? original.acceptors.length !== state.acceptors.length ||
		  original.acceptors.some(id => !state.acceptors.includes(id))
		: !!state.acceptors.length;

const areSubmittersEdited = (state: IEditableWorkAcceptanceState, original?: IWorkAcceptance) =>
	original?.submitters
		? original.submitters.length !== state.submitters.length ||
		  original.submitters.some(id => !state.submitters.includes(id))
		: !!state.submitters.length;

const isAcceptorsActiveGroupEdited = (
	state: IEditableWorkAcceptanceState,
	original?: IWorkAcceptance
) =>
	original?.acceptorsActiveGroup
		? original.acceptorsActiveGroup !== state.acceptorsActiveGroup
		: !!state.acceptorsActiveGroup;

const isSubmittersActiveGroupEdited = (
	state: IEditableWorkAcceptanceState,
	original?: IWorkAcceptance
) =>
	original?.submittersActiveGroup
		? original.submittersActiveGroup !== state.submittersActiveGroup
		: !!state.submittersActiveGroup;

const areSpacesEdited = (state: IEditableWorkAcceptanceState, original?: IWorkAcceptance) =>
	original?.spaces
		? original.spaces.length !== state.spaceIds.length ||
		  original.spaces.some(space => !state.spaceIds.includes(space.id))
		: !!state.spaceIds.length;

const areStructuresEdited = (state: IEditableWorkAcceptanceState, original?: IWorkAcceptance) =>
	original?.structures
		? original.structures.length !== state.structureIds.length ||
		  original.structures.some(structure => !state.structureIds.includes(structure.id))
		: !!state.structureIds.length;

const areLocationsEdited = (state: IEditableWorkAcceptanceState, original?: IWorkAcceptance) =>
	!isEqual(locationsFromWorkAcceptance(original), state.locations);

const isWorkScopeEdited = (state: IEditableWorkAcceptanceState, original?: IWorkAcceptance) =>
	original?.workScope ? original.workScope !== state.workScope : !!state.workScope;

const isUnitEdited = (state: IEditableWorkAcceptanceState, original?: IWorkAcceptance) =>
	original?.physicalWorkScope?.unitId
		? original.physicalWorkScope.unitId !== state.unit
		: !!state.unit;

const isUnitValueEdited = (state: IEditableWorkAcceptanceState, original?: IWorkAcceptance) =>
	original?.physicalWorkScope?.value
		? original.physicalWorkScope.value !== state.unitValue
		: !!state.unitValue;

const isUnitPlanEdited = (state: IEditableWorkAcceptanceState, original?: IWorkAcceptance) =>
	original?.planPhysicalWorkScope?.unitId
		? original.planPhysicalWorkScope.unitId !== state.unitPlan
		: !!state.unitPlan;

const isUnitPlanValueEdited = (state: IEditableWorkAcceptanceState, original?: IWorkAcceptance) =>
	original?.planPhysicalWorkScope?.value
		? original.planPhysicalWorkScope.value !== state.unitPlanValue
		: !!state.unitPlanValue;

const areAttachmentsEdited = (state: IEditableWorkAcceptanceState, original?: IWorkAcceptance) =>
	original?.attachments ? original.attachments.length !== state.attachments.length : false;

const areCustomFieldsEdited = (state: IEditableWorkAcceptanceState, original?: IWorkAcceptance) =>
	!isEqual(state.customFields, original?.customFields);

const isContractorLegalEntityIdEdited = (
	state: IEditableWorkAcceptanceState,
	original?: IWorkAcceptance
) =>
	original?.contractorLegalEntity?.id
		? original.contractorLegalEntity.id !== state.contractorLegalEntityId
		: !!state.contractorLegalEntityId;
/**
 * Возвращает значение, показывающее были ли отредактированы поля
 *
 * @param state состояние
 * @param original изначальные данные
 */
export const isEdited = (
	state: IEditableWorkAcceptanceState,
	original?: IWorkAcceptance
): boolean =>
	isEntityEdited(
		state,
		original,
		isDescriptionEdited,
		isTypeEdited,
		isFrontTypeEdited,
		isCategoryEdited,
		isAcceptanceDateEdited,
		areAcceptorsEdited,
		isAcceptorsActiveGroupEdited,
		areSubmittersEdited,
		isSubmittersActiveGroupEdited,
		areStructuresEdited,
		areSpacesEdited,
		areLocationsEdited,
		isContractorLegalEntityIdEdited,
		isWorkScopeEdited,
		isUnitPlanEdited,
		isUnitPlanValueEdited,
		isUnitEdited,
		isUnitValueEdited,
		areAttachmentsEdited,
		areCustomFieldsEdited
	);

/**
 * Функции проверки полей на ошибки
 */

export const errorsFns = {
	description: (state: IEditableWorkAcceptanceState) => !state.description,
	categoryId: (state: IEditableWorkAcceptanceState) => !state.categoryId,
	acceptanceDate: (state: IEditableWorkAcceptanceState) => !state.acceptanceDate,
	acceptors: (state: IEditableWorkAcceptanceState) => !state.acceptors.length,
	acceptorsActiveGroup: (state: IEditableWorkAcceptanceState) => !state.acceptorsActiveGroup,
	submitters: (state: IEditableWorkAcceptanceState) => !state.submitters.length,
	submittersActiveGroup: (state: IEditableWorkAcceptanceState) => !state.submittersActiveGroup,
	structureIds: (state: IEditableWorkAcceptanceState) => !state.structureIds.length,
	spaceIds: (state: IEditableWorkAcceptanceState) => !state.spaceIds.length,
	type: (state: IEditableWorkAcceptanceState) => !state.type,
	frontType: (state: IEditableWorkAcceptanceState) => !state.frontType,
	contractorLegalEntityId: (state: IEditableWorkAcceptanceState) =>
		!state.contractorLegalEntityId,
	workScope: (state: IEditableWorkAcceptanceState) => !state.workScope,
	attachments: (state: IEditableWorkAcceptanceState) => !state.attachments.length,
	customFields: (state: IEditableWorkAcceptanceState, key?: string) => {
		if (!key) {
			return false;
		}
		const value = state.customFields?.[key];
		if (Array.isArray(value)) {
			return !value.length;
		}
		return !value;
	}
};

export const hasAttachmentsError = (
	state: IEditableWorkAcceptanceState,
	settings: {[k: string]: IObjectFieldSetting}
) =>
	(settings.attachments?.isRequired || settings.attachments?.required) &&
	errorsFns.attachments(state);

/**
 * Конвертирует данные в формат, пригодный для отправки на сервер
 *
 * @param state состояние
 * @param original изначальные данные
 * @param onlyEdited возвращать только изменённые поля
 */
export const convertToSave = (
	state: IEditableWorkAcceptanceState,
	original?: IWorkAcceptance,
	onlyEdited?: boolean
): ISavingWorkAcceptance => {
	if (!onlyEdited) {
		return {
			description: state.description,
			categoryId: state.categoryId,
			acceptanceDate: state.acceptanceDate,
			acceptors: state.acceptors,
			acceptorsActiveGroup: state.acceptorsActiveGroup,
			submitters: state.submitters,
			submittersActiveGroup: state.submittersActiveGroup,
			type: state.type,
			frontType: state.frontType,
			structures: state.structureIds.map(id => ({
				id,
				planId: state.locations[id]?.planId,
				location: state.locations[id]?.location
			})),
			spaces: state.spaceIds.map(id => ({
				id,
				planId: state.locations[id]?.planId,
				location: state.locations[id]?.location
			})),
			attachments: state.attachments,
			customFields: state.customFields
		};
	}
	const acceptance: ISavingWorkAcceptance = {};
	if (isDescriptionEdited(state, original)) {
		acceptance.description = state.description;
	}
	if (isCategoryEdited(state, original)) {
		acceptance.categoryId = state.categoryId;
	}
	if (isAcceptanceDateEdited(state, original)) {
		acceptance.acceptanceDate = state.acceptanceDate;
	}
	if (areAcceptorsEdited(state, original) || isAcceptorsActiveGroupEdited(state, original)) {
		acceptance.acceptors = state.acceptors;
		acceptance.acceptorsActiveGroup = state.acceptorsActiveGroup;
	}
	if (areSubmittersEdited(state, original) || isSubmittersActiveGroupEdited(state, original)) {
		acceptance.submitters = state.submitters;
		acceptance.submittersActiveGroup = state.submittersActiveGroup;
	}
	if (isWorkScopeEdited(state, original)) {
		acceptance.workScope = state.workScope;
	}
	if (isUnitPlanEdited(state, original)) {
		acceptance.planPhysicalWorkScope = {
			...original?.planPhysicalWorkScope,
			unitId: state.unit
		};
	}
	if (isUnitPlanValueEdited(state, original)) {
		acceptance.planPhysicalWorkScope = {
			unitId: state.unitPlan,
			...original?.planPhysicalWorkScope,
			value: state.unitPlanValue
		};
	}
	if (isUnitEdited(state, original)) {
		acceptance.physicalWorkScope = {
			...original?.physicalWorkScope,
			unitId: state.unit
		};
	}
	if (isUnitValueEdited(state, original)) {
		acceptance.physicalWorkScope = {
			unitId: state.unit,
			...original?.physicalWorkScope,
			value: state.unitValue
		};
	}
	if (areStructuresEdited(state, original) || areLocationsEdited(state, original)) {
		acceptance.structures = state.structureIds.map(id => ({
			id,
			planId: state.locations[id]?.planId,
			location: state.locations[id]?.location
		}));
	}
	if (areSpacesEdited(state, original) || areLocationsEdited(state, original)) {
		acceptance.spaces = state.spaceIds.map(id => ({
			id,
			planId: state.locations[id]?.planId,
			location: state.locations[id]?.location
		}));
	}
	if (isTypeEdited(state, original)) {
		acceptance.type = state.type;
	}
	if (isFrontTypeEdited(state, original)) {
		acceptance.frontType = state.frontType;
	}
	if (isContractorLegalEntityIdEdited(state, original)) {
		acceptance.contractorLegalEntityId = state.contractorLegalEntityId;
	}
	if (areAttachmentsEdited(state, original)) {
		acceptance.attachments = state.attachments.map(item => ({id: item.id}));
	}
	if (areCustomFieldsEdited(state, original)) {
		acceptance.customFields = state.customFields;
	}
	return acceptance;
};
