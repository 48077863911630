import {Dispatch, useCallback, useMemo, useState} from 'react';
import * as React from 'react';
import {IEditableEntityAction} from '@tehzor/tools/core/states/editableEntityState';
import {
	EditableFieldLabel,
	SelectPopup,
	TextFieldWithForwardedRef,
	TreeSelect
} from '@tehzor/ui-components';
import {useUpdateEffect} from 'react-use';
import {makeTreeDataStructure} from './utils/makeTreeData';
import ILocation from '@tehzor/tools/interfaces/ILocation';
import './StructureTreeSelect.less';
import {StructureLocationSelect} from './StructureLocationSelect';
import {useExtractStructureTypesAsArray} from '@src/core/hooks/queries/structureTypes/hooks';
import {useTranslation} from 'react-i18next';
import {useStructuresList, useStructuresListAsArray} from '@src/fsd/src/entities/Structures';

interface IStructureTreeSelectProps<S, E> {
	objectId: string;
	className?: string;
	style?: React.CSSProperties;
	field: keyof S;
	label?: string;
	value?: string[];
	locations?: Record<string, {planId: string; location: ILocation}>;
	editingDispatch: Dispatch<IEditableEntityAction<S, E>>;
	required?: boolean;
	disabled?: boolean;
	hasError?: boolean;
}

const arrowIcon = <i className="tz-simple-arrow-20" />;
export const StructureTreeSelect = <
	S extends {structureIds?: string[]; locations?: ILocation | null},
	E
>({
	objectId,
	className,
	style,
	field = 'structureIds',
	label = 'Структуры',
	value,
	locations,
	editingDispatch,
	required,
	disabled,
	hasError
}: IStructureTreeSelectProps<S, E>) => {
	const {t} = useTranslation();
	const [selectedObjects, setSelectedObjects] = useState(value);
	const [expandedObjects, setExpandedObjects] = useState<string[] | undefined>([]);
	const {data: structuresData} = useStructuresList(objectId);
	const {data: structures} = useStructuresListAsArray(objectId);
	const {data: structureTypes} = useExtractStructureTypesAsArray();

	const treeData = useMemo(() => {
		if (!structures || !structureTypes) return [];

		return makeTreeDataStructure(structures, structureTypes).map(item => ({
			...item,
			content: t(`structureTypes.${item.id}`, {defaultValue: item.content as string})
		}));
	}, [structures, structureTypes, t]);

	const locationByStructure = useMemo(
		() =>
			locations
				? value?.map(id => ({
						structureId: id,
						location: locations ? locations[id] : undefined
				  }))
				: undefined,
		[locations, value]
	);

	const handleChange = useCallback(() => {
		editingDispatch({type: 'update', field, value: selectedObjects});
		if (required) {
			editingDispatch({type: 'update-error', field});
		}
	}, [required, selectedObjects, field, editingDispatch]);

	const handleClear = useCallback(() => {
		setSelectedObjects([]);
	}, []);

	const handleCancel = useCallback(() => {
		setSelectedObjects(value);
	}, [value]);

	const handleExpand = useCallback((v?: string[]) => {
		setExpandedObjects(v);
	}, []);

	useUpdateEffect(() => {
		setSelectedObjects(value);
	}, [value]);

	const title =
		structuresData?.byId && value && value?.length > 0
			? value.length > 1
				? `${structuresData.byId[value[0]]?.name} + ${value.length - 1}`
				: `${structuresData.byId[value[0]]?.name}`
			: undefined;

	const trigger = (
		<TextFieldWithForwardedRef
			elementType="div"
			value={title}
			icon={arrowIcon}
			error={required && hasError ? 'Выберите структуры' : undefined}
			disabled={disabled}
		/>
	);

	return (
		<div
			className={`${className} structure-tree-select_front-select-area`}
			style={style}
		>
			<EditableFieldLabel>{label}</EditableFieldLabel>
			{structuresData && treeData && (
				<SelectPopup
					trigger={trigger}
					clearButton={!!selectedObjects?.length}
					footer
					noHeader
					count={selectedObjects?.length}
					onApply={handleChange}
					onCancel={handleCancel}
					onClear={handleClear}
					translationsProps={{
						selectClearAllBtnLabel: t(
							'components.editableFields.structuresTreeSelect.selectClearAllBtnLabel'
						),
						selectApplyBtnLabel: t(
							'components.editableFields.structuresTreeSelect.selectApplyBtnLabel'
						),
						selectCancelBtnLabel: t(
							'components.editableFields.structuresTreeSelect.selectCancelBtnLabel'
						),
						selectClearBtnLabel: t(
							'components.editableFields.structuresTreeSelect.selectClearBtnLabel'
						),
						selectSelectAllBtnLabel: t(
							'components.editableFields.structuresTreeSelect.selectSelectAllBtnLabel'
						),
						selectSelectedCountLabel: t(
							'components.editableFields.structuresTreeSelect.selectSelectedCountLabel'
						)
					}}
				>
					<TreeSelect
						data={treeData}
						multiple
						value={selectedObjects}
						onChange={setSelectedObjects}
						expandedValue={expandedObjects}
						onExpand={handleExpand}
					/>
				</SelectPopup>
			)}
			{locations && !!locationByStructure?.length && (
				<div className="structure-tree-select_locations">
					<EditableFieldLabel className="structure-tree-select_locations_label">
						{t('components.editableFields.locationsTreeSelect.label')}
					</EditableFieldLabel>
					<div className="structure-tree-select structure-tree-select_location-select-area structure-tree-select__info-grid">
						{locationByStructure &&
							locationByStructure.map(structureEl => (
								<StructureLocationSelect
									locations={locations}
									objectId={objectId}
									structureEl={structureEl}
									editingDispatch={editingDispatch}
								/>
							))}
					</div>
				</div>
			)}
		</div>
	);
};
