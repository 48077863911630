import {
	IEditableEntityAction,
	IEditableEntityState,
	isEntityEdited
} from '@tehzor/tools/core/states/editableEntityState';
import IAttachment from '@tehzor/tools/interfaces/IAttachment';
import IObjectFieldSetting from '@tehzor/tools/interfaces/objects/IObjectFieldSetting';
import {IPresaleCheck} from '@tehzor/tools/interfaces/presaleChecks/IPresaleCheck';
import {ISavingPresaleCheck} from '@src/interfaces/saving/ISavingPresaleCheck';

export type IEditablePresaleCheckState = IEditableEntityState<{
	description?: string | null;
	attachments: IAttachment[];
}>;

export type IEditablePresaleCheckAction = IEditableEntityAction<
	IEditablePresaleCheckState,
	IPresaleCheck
>;

const makeEmptyState = (): IEditablePresaleCheckState => ({
	description: undefined,
	attachments: [],
	errors: {
		description: false,
		attachments: false
	}
});

export const init = (presaleCheck?: IPresaleCheck): IEditablePresaleCheckState => {
	const empty = makeEmptyState();
	return presaleCheck
		? {
				description: presaleCheck.description || undefined,
				attachments: presaleCheck.attachments || empty.attachments,
				errors: empty.errors
		  }
		: empty;
};

const isDescriptionEdited = (state: IEditablePresaleCheckState, original?: IPresaleCheck) =>
	original?.description ? original.description !== state.description : !!state.description;

const areAttachmentsEdited = (state: IEditablePresaleCheckState, original?: IPresaleCheck) =>
	original?.attachments ? original.attachments.length !== state.attachments.length : false;

/**
 * Возвращает значение, показывающее были ли отредактированы поля
 *
 * @param state состояние
 * @param original изначальные данные
 */
export const isEdited = (state: IEditablePresaleCheckState, original?: IPresaleCheck): boolean =>
	isEntityEdited(state, original, isDescriptionEdited, areAttachmentsEdited);

/**
 * Функции проверки полей на ошибки
 */
export const errorsFns = {
	description: (state: IEditablePresaleCheckState) => !state.description,
	attachments: (state: IEditablePresaleCheckState) => !state.attachments.length
};

/**
 * Проверяет, есть ли ошибка в сохраненных вложениях
 *
 * @param state состояние
 * @param settings настройки полей
 */
export const hasAttachmentsError = (
	state: IEditablePresaleCheckState,
	settings: {[k: string]: IObjectFieldSetting}
) => settings.attachments?.isRequired && errorsFns.attachments(state);

/**
 * Конвертирует данные в формат, пригодный для отправки на сервер
 *
 * @param state состояние
 * @param original изначальные данные
 * @param onlyEdited возвращать только изменённые поля
 */
export const convertToSave = (
	state: IEditablePresaleCheckState,
	original?: IPresaleCheck,
	onlyEdited?: boolean
): ISavingPresaleCheck => {
	if (!onlyEdited) {
		return {
			description: state.description,
			attachments: state.attachments
		};
	}
	const presaleCheck: ISavingPresaleCheck = {};
	if (isDescriptionEdited(state, original)) {
		presaleCheck.description = state.description;
	}

	presaleCheck.attachments = state.attachments?.map(item => ({id: item.id}));

	return presaleCheck;
};
