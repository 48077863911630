import {combineReducers} from 'redux';
import check from './check/reducers';
import problemsLastReplies from './problemsLastReplies/reducers';
import workAcceptancesLastReplies from './workAcceptancesLastReplies/reducers';
import inspection from './inspection/reducers';
import warrantyClaim from './warrantyClaim/reducers';
import {internalAcceptance} from './internalAcceptance/reducers';
import devices from './devices/reducers';
import {workAcceptance} from './workAcceptance/reducers';

export const entities = combineReducers({
	check,
	devices,
	problemsLastReplies,
	inspection,
	warrantyClaim,
	internalAcceptance,
	workAcceptance,
	workAcceptancesLastReplies
});
