import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {createApiAction} from '@src/store/middlewares/api';
import {
	IAddInternalAcceptanceResponse,
	makeInternalAcceptanceAddRequest
} from '@src/api/backend/internalAcceptance';
import {IInternalAcceptance} from '@tehzor/tools/interfaces/internalAcceptances/IInternalAcceptance';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {addErrorToast} from '@tehzor/ui-components';
import {t} from 'i18next';

export type IAddInternalAcceptancePayload = IAddInternalAcceptanceResponse;

const request = () => ({type: types.ADD_REQUEST});

const success = (response: IAddInternalAcceptanceResponse) => ({
	type: types.ADD_SUCCESS,
	payload: response
});

const failure = (error: IError) => {
	addErrorToast({title: t('toast.addInternalAcceptance.error.title')});
	return {
		type: types.ADD_FAILURE,
		payload: error
	};
};

export const addInternalAcceptanceActions = {request, success, failure};

/**
 * Добавляет внутреннюю приёмку
 *
 * @param objectId id объекта
 * @param links ссылки на другие сущности
 * @param stage стадия объекта
 */
export const addInternalAcceptance = (
	objectId: string,
	links: IInternalAcceptance['links'] | undefined,
	stage?: ObjectStageIds
) =>
	createApiAction<IAddInternalAcceptanceResponse>(request, success, failure, () =>
		makeInternalAcceptanceAddRequest(objectId, links, stage)
	);
