import {memo, useCallback, useMemo, useState} from 'react';
import {useUpdateEffect} from 'react-use';
import {FilterButton, TreeSelect} from '@tehzor/ui-components';
import {makeFilterLabel} from '@src/components/EntitiesFilters/utils/makeFilterLabel';
import {useEntitiesFiltersCtx} from '@src/components/EntitiesFilters/utils/entitiesFiltersCtx';
import SelectSearch, {
	treeFilter
} from '@tehzor/ui-components/src/components/inputs/select/SelectSearch';
import {useExtractAllCategoriesAsArray} from '@src/core/hooks/queries/categories/hook';
import {useExtractCategoriesSetsAsArray} from '@src/core/hooks/queries/categorySets/hook';
import {TranslatedSelectPopup} from '@src/components/TranslatedSelectPopup';
import {useTranslation} from 'react-i18next';
import {makeTreeData} from '@src/core/hooks/queries/categorySets/utils/makeTreeData';

interface ICategoriesSetFilterProps {
	objectId: string;
	categories?: string[];
	label?: string;
}

export const CategoriesSetFilter = memo(
	({objectId, categories, label}: ICategoriesSetFilterProps) => {
		const {t} = useTranslation();
		const translatedLabel = label ?? t('entitiesFilters.CategoriesSetFilter.label');

		const {dispatch} = useEntitiesFiltersCtx();
		const [selectedCategories, setSelectedCategories] = useState(categories);
		const [expandedCategories, setExpandedCategories] = useState<string[] | undefined>([]);
		const [search, setSearch] = useState('');

		const {data: allCategories} = useExtractAllCategoriesAsArray();
		const {data: categoriesSets} = useExtractCategoriesSetsAsArray({objectId});
		const clearSearch = useCallback(() => setSearch(''), []);

		const treeData = useMemo(
			() => makeTreeData(categoriesSets, allCategories),
			[categoriesSets, allCategories]
		);

		const {filteredData, expanded} = useMemo(
			() => treeFilter(treeData, 'content', search),
			[search, treeData]
		);

		useUpdateEffect(() => {
			setExpandedCategories(expanded?.map(item => item.id));
		}, [expanded]);

		const handleApply = useCallback(() => {
			dispatch({categories: selectedCategories});
			clearSearch();
		}, [selectedCategories, dispatch, clearSearch]);

		const handleClear = useCallback(() => {
			setSelectedCategories([]);
			clearSearch();
		}, [clearSearch]);

		const handleFullClear = useCallback(() => {
			dispatch({categories: undefined});
			setExpandedCategories([]);
			setSelectedCategories([]);
			clearSearch();
		}, [dispatch, clearSearch]);

		const handleCancel = useCallback(() => {
			setSelectedCategories(categories);
			clearSearch();
		}, [categories, clearSearch]);

		const handleSelectAll = useCallback(() => {
			clearSearch();
			const allCats =
				allCategories &&
				categoriesSets?.map(set => allCategories[set.id].map(cat => cat.id)).flat();
			setSelectedCategories(allCats);
		}, [categoriesSets, allCategories, clearSearch]);

		useUpdateEffect(() => {
			setExpandedCategories([]);
			setSelectedCategories(categories);
		}, [categories]);

		return (
			<TranslatedSelectPopup
				onCancel={handleCancel}
				onApply={handleApply}
				onClear={handleClear}
				clearButton={!!selectedCategories?.length}
				count={selectedCategories?.length}
				onSelectAll={handleSelectAll}
				footer
				search={
					<SelectSearch
						value={search}
						placeholder={t('selectSearch.placeholder')}
						onChange={setSearch}
						type="popup"
					/>
				}
				trigger={
					<FilterButton
						className="entities-filters__item"
						label={makeFilterLabel(translatedLabel, categories, treeData)}
						active={!!categories?.length}
						onClear={handleFullClear}
					/>
				}
			>
				<TreeSelect
					data={filteredData}
					multiple
					value={selectedCategories}
					onChange={setSelectedCategories}
					expandedValue={expandedCategories}
					onExpand={setExpandedCategories}
				/>
			</TranslatedSelectPopup>
		);
	}
);
