import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {createApiAction} from '@src/store/middlewares/api';
import {
	IEditWorkAcceptanceResponse,
	makeWorkAcceptanceEditRequest
} from '@src/api/backend/workAcceptance';
import {ISavingWorkAcceptance} from '@src/interfaces/saving/ISavingWorkAcceptance';
import {queryClient} from '@src/api/QueryClient';
import {workAcceptancesQueryKeys} from '@src/api/cache/workAcceptances/keys';
import {makeWorkAcceptanceResultEditRequest} from '@src/api/backend/workAcceptance/editResult';
import {delegationHistoryQueryKeys} from '@src/api/cache/delegationHistory/keys';
import {WorkingGroupTypeId} from '@tehzor/tools/interfaces/workingGroups/IWorkingGroupType';
import {structuresQueryKeys} from '@src/fsd/src/entities/Structures';
import {addErrorToast} from '@tehzor/ui-components';
import {t} from 'i18next';
import {workAcceptanceStoriesQueryKeys} from '@src/api/cache/workAcceptanceStories/keys';

const request = () => ({type: types.EDIT_REQUEST});

const success = (response: IEditWorkAcceptanceResponse) => {
	void queryClient.invalidateQueries({queryKey: workAcceptancesQueryKeys.lists()});
	void queryClient.invalidateQueries({queryKey: workAcceptancesQueryKeys.paginate()});
	void queryClient.invalidateQueries({queryKey: workAcceptancesQueryKeys.details()});
	void queryClient.invalidateQueries({
		queryKey: delegationHistoryQueryKeys.histories({
			objectId: response.objectId,
			workAcceptanceId: response.id,
			workingGroupType: WorkingGroupTypeId.ACCEPTORS
		})
	});
	void queryClient.invalidateQueries({queryKey: structuresQueryKeys.workAcceptancesStatsAll()});

	void queryClient.invalidateQueries({
		queryKey: delegationHistoryQueryKeys.histories({
			objectId: response.objectId,
			workAcceptanceId: response.id,
			workingGroupType: WorkingGroupTypeId.SUBMITTERS
		})
	});

	void queryClient.invalidateQueries({
		queryKey: workAcceptanceStoriesQueryKeys.list(response.objectId, response.id)
	});

	return {
		type: types.EDIT_SUCCESS,
		payload: response
	};
};

const failure = (error: IError) => {
	addErrorToast({title: t('toast.editWorkAcceptance.error.title')});
	return {
		type: types.EDIT_FAILURE,
		payload: error
	};
};

/**
 * Изменяет приёмку работ
 */
export const editWorkAcceptance = (
	objectId: string,
	workAcceptanceId: string,
	data: ISavingWorkAcceptance
) =>
	createApiAction<IEditWorkAcceptanceResponse>(request, success, failure, () =>
		makeWorkAcceptanceEditRequest(objectId, workAcceptanceId, data)
	);

/**
 * Изменяет результат приёмки работ.
 * Изменение результата оставляем тут, т.к. сущность одна и та же, экшены одни и те же
 * При переносе на React Query, будет разделено на два хука
 */
export const editWorkAcceptanceResult = (
	objectId: string,
	workAcceptanceId: string,
	data: ISavingWorkAcceptance
) =>
	createApiAction<IEditWorkAcceptanceResponse>(request, success, failure, () =>
		makeWorkAcceptanceResultEditRequest(objectId, workAcceptanceId, data)
	);
