import {useQueryClient} from '@tanstack/react-query';
import {presaleChecksQueryKeys} from '@/entities/PresaleCheck';
import {spacesQueryKeys} from '@src/api/cache/spaces/keys';
import {ISavingPresaleCheck} from '@src/interfaces/saving/ISavingPresaleCheck';
import {IPresaleCheck} from '@tehzor/tools/interfaces/presaleChecks/IPresaleCheck';
import {useAddChildren} from '@src/api/cache/hooks/useAddChildren';
import {IAddCheckParams} from '@src/api/cache/checks/defaults/useChecksMutationDefaults';
import {requestDeletePresaleCheck} from '@/shared/api/presaleChecks/deletePresaleCheck';
import {requestEditPresaleCheck} from '@/shared/api/presaleChecks/editPresaleCheck';
import {requestAddPresaleCheck} from '@/shared/api/presaleChecks/addPresaleCheck';
import {addErrorToast, addSuccessToast} from '@tehzor/ui-components';
import {useTranslation} from 'react-i18next';

export interface IDeletePresaleCheckParams {
	presaleCheckId: string;
	objectId: string;
}

export interface IEditPresaleCheckParams {
	presaleCheckId: string;
	objectId: string;
	fields: ISavingPresaleCheck;
}

export interface IAddPresaleCheckParams {
	key: string;
	objectId: string;
	links: IPresaleCheck['links'] | undefined;
}

export const usePresaleChecksMutationDefaults = () => {
	const {t} = useTranslation();
	const queryClient = useQueryClient();
	const [addProblems, addInspections] = useAddChildren();

	queryClient.setMutationDefaults(presaleChecksQueryKeys.add(), {
		mutationFn: async (params: IAddPresaleCheckParams) => {
			const {objectId, links, key} = params;

			return requestAddPresaleCheck(objectId, links, key);
		},
		onSuccess: async (newPresaleCheck: IPresaleCheck, {key}: IAddCheckParams) => {
			queryClient.removeQueries({queryKey: presaleChecksQueryKeys.detail(key)});
			addProblems(key, newPresaleCheck.id, 'presaleCheckId');
			addInspections(key, newPresaleCheck.id, 'presaleCheckId');
			await queryClient.invalidateQueries({queryKey: presaleChecksQueryKeys.list()});

			await queryClient.refetchQueries({
				queryKey: presaleChecksQueryKeys.localList()
			});
			await queryClient.invalidateQueries({
				queryKey: presaleChecksQueryKeys.latest()
			});
			await queryClient.invalidateQueries({
				queryKey: spacesQueryKeys.presaleChecks()
			});
		}
	});

	queryClient.setMutationDefaults(presaleChecksQueryKeys.delete(), {
		onMutate: (params: IDeletePresaleCheckParams) => ({variables: params}),
		mutationFn: async (params: IDeletePresaleCheckParams) => {
			const {objectId, presaleCheckId} = params;
			return requestDeletePresaleCheck(objectId, presaleCheckId);
		},
		onSuccess: () => {
			addSuccessToast({title: t('toast.deletePresaleChecks.success.title')});
			void queryClient.invalidateQueries({
				queryKey: [...spacesQueryKeys.presaleChecks()]
			});
		},
		onError: () => addErrorToast({title: t('toast.deletePresaleChecks.error.title')})
	});

	queryClient.setMutationDefaults(presaleChecksQueryKeys.edit(), {
		onMutate: (params: IEditPresaleCheckParams) => ({variables: params}),
		mutationFn: async (params: IEditPresaleCheckParams) => {
			const {objectId, presaleCheckId, fields} = params;
			return requestEditPresaleCheck(objectId, presaleCheckId, fields);
		},
		onSuccess: () => {
			addSuccessToast({title: t('toast.editPresaleChecks.success.title')});
			void queryClient.invalidateQueries({
				queryKey: [...presaleChecksQueryKeys.details()]
			});
			void queryClient.invalidateQueries({
				queryKey: [...spacesQueryKeys.presaleChecks()]
			});
		},
		onError: () => addErrorToast({title: t('toast.editPresaleChecks.error.title')})
	});
};
