import {addSuccessToast} from '@tehzor/ui-components';
import {ILinkToastProps, LinkToast} from '@/shared/lib/Toasts/LinkToast/LinkToast';

export const addLinkToast = (toastProps: Omit<ILinkToastProps, 'toastId'>) => {
	const toastId = `toast-${toastProps.link}`;
	addSuccessToast({
		toastNode: (
			<LinkToast
				toastId={toastId}
				{...toastProps}
			/>
		),
		options: {
			type: 'success',
			toastId
		}
	});
};
