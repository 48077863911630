import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import HttpBackend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

export const languages = [
	{key: 'ru', name: 'Ru'},
	{key: 'en', name: 'En'},
	{key: 'kk', name: 'Kk'},
	{key: 'tr', name: 'Tr'}
];

void i18n
	.use(HttpBackend)
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		debug: process.env.NODE_ENV === 'development',
		fallbackLng: 'en',
		supportedLngs: languages.map(l => l.key),
		ns: ['common'],
		defaultNS: 'common',
		interpolation: {
			escapeValue: false
		},
		backend: {
			loadPath: '/locales/{{lng}}/{{ns}}.json'
		}
	});

export default i18n;
