import {IPresaleCheck} from '@tehzor/tools/interfaces/presaleChecks/IPresaleCheck';
import {ActionsMenuMobile} from '../ActionsMenuMobile';
import {usePresaleChecksPermissions} from '@/entities/PresaleCheck/model/hooks/usePresaleChecksPermissions';

interface IPresaleCheckMobileActionsProps {
	objectId: string;
	presaleCheck: IPresaleCheck;
}

export const ActionsMobile = ({objectId, presaleCheck}: IPresaleCheckMobileActionsProps) => {
	const permissions = usePresaleChecksPermissions(objectId);

	return (
		<ActionsMenuMobile
			objectId={objectId}
			presaleCheck={presaleCheck}
			permissions={permissions}
		/>
	);
};
