import {useFieldsPermissions} from '@/shared/hooks/permissions/useFieldsPermissions';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {
	extractFieldsSettingsSetsPageSettings,
	fieldsSettingsSetsAction
} from '@/entities/FieldsSettingsSets';
import {useDeleteFieldsSettingsSetsDialog} from '@/features/FieldsSettingsSets';
import {useCallback} from 'react';
import {IconButton} from '@tehzor/ui-components';

const deleteIcon = <i className="tz-delete" />;
export const DeleteSettingsSetsButton = () => {
	const dispatch = useAppDispatch();
	const {selectedRows} = useAppSelector(s => extractFieldsSettingsSetsPageSettings(s));
	const {changeSelectedRows} = fieldsSettingsSetsAction;
	const {deleteDialog, handleDelete} = useDeleteFieldsSettingsSetsDialog(selectedRows);
	const {canDeleteSettingsSet} = useFieldsPermissions();

	const deleteSettingsSetsHandler = useCallback(async () => {
		const deletedSetIds = await handleDelete();
		const setIds = selectedRows.filter(id => !deletedSetIds.includes(id));
		dispatch(changeSelectedRows(setIds));
	}, [handleDelete, changeSelectedRows, dispatch, selectedRows]);

	if (!canDeleteSettingsSet) return null;
	return (
		<>
			<IconButton onClick={deleteSettingsSetsHandler}>{deleteIcon}</IconButton>
			{deleteDialog}
		</>
	);
};
