import {EntityGridItem, StatusSelect} from '@tehzor/ui-components';
import useAsyncFn from 'react-use/lib/useAsyncFn';
import {IPopupBasicProps} from '@tehzor/ui-components/src/components/containers/Popup';
import {TaskStatusId} from '@tehzor/tools/interfaces/tasks/ITaskStatus';
import {IEnrichedTask} from '@tehzor/tools/interfaces/tasks/IEnrichedTask';
import {useTasksPermissions} from '@src/core/hooks/permissions/useTasksPermissions';
import {useTaskStatuses} from '@src/core/hooks/queries/taskStatuses/hook';
import {useEditTaskStatus} from '@src/core/hooks/mutations/task/useEditTaskStatus';
import {useTranslation} from 'react-i18next';
import {useTranslatedDictionary} from '@src/core/hooks/translations/useTranslatedDictionary';
import {dictionaryKeys} from '@src/constants/translations/dictionaryKeys';

const popupProps: IPopupBasicProps = {placement: 'bottom-end', preferX: 'left'};

interface IStatusProps {
	task: IEnrichedTask;
}

const statusIcon = <i className="tz-status-20" />;

export const TaskStatusGrid = ({task}: IStatusProps) => {
	const {t} = useTranslation();
	const {data: statuses} = useTaskStatuses();
	const translatedStatuses = useTranslatedDictionary(dictionaryKeys.taskStatuses, statuses);

	const {mutateAsync: editTaskStatus} = useEditTaskStatus();
	const permissions = useTasksPermissions({
		objectId: task?.objectId,
		activeGroupLeader: task?.activeGroupLeader?.id,
		respUsers: task?.respUsers?.map(user => user.id),
		createdBy: task?.createdBy?.id,
		watchers: task?.watchers
	});

	const [, handleChange] = useAsyncFn(
		async (statusId: TaskStatusId) => {
			await editTaskStatus({taskId: task.id, statusId});
		},
		[task]
	);

	const availableStatuses = Object.values(TaskStatusId);

	return (
		<EntityGridItem
			className="task-view-page__status"
			icon={statusIcon}
			label={t('taskViewPage.taskStatusGrid.title')}
			inline
			fullRow
		>
			<StatusSelect
				statuses={translatedStatuses}
				availableStatuses={availableStatuses}
				disabled={!permissions.canEditStatus}
				value={task?.status?.id}
				popupProps={popupProps}
				onChange={handleChange}
			/>
		</EntityGridItem>
	);
};
