import {PrivateRoute} from '@src/containers/PrivateRoute';
import {FieldsPage} from '@/pages/FieldsPage';
import {RouteObject} from 'react-router-dom';
import {MobileFieldSettingsEditPage} from '@/pages/MobileFieldSettingsEditPage';
import {FieldsSettingsSetPage} from '@/pages/FieldsSettingsSetPage';
import {MobileCustomFieldEditPage} from '@/pages/MobileCustomFieldEditPage';
import {fieldsPagesRoutes} from '@/app/routes/fieldsPagesRoutes';

export const manageFieldsRoutes: RouteObject[] = [
	{
		element: <PrivateRoute element={<FieldsPage />} />,
		children: fieldsPagesRoutes
	},
	{
		path: 'fields-settings-set/add',
		element: <PrivateRoute element={<FieldsSettingsSetPage isAddPage />} />
	},
	{
		path: 'fields-settings-set/:fieldsSettingsSetId',
		element: <PrivateRoute element={<FieldsSettingsSetPage />} />
	},
	{
		path: 'fields-settings-set/:setId/edit-custom-field-settings/:fieldId',
		element: <PrivateRoute element={<MobileFieldSettingsEditPage isCustom />} />
	},
	{
		path: 'fields-settings-set/:setId/edit-builtin-field-settings/:fieldId',
		element: <PrivateRoute element={<MobileFieldSettingsEditPage isCustom={false} />} />
	},
	{
		path: 'custom-field/edit/:fieldId',
		element: <PrivateRoute element={<MobileCustomFieldEditPage isEditing />} />
	},
	{
		path: 'custom-field/add',
		element: <PrivateRoute element={<MobileCustomFieldEditPage />} />
	}
];
