import {FloatingActionButton} from '@tehzor/ui-components';
import {useAddingCheckDialog} from '@src/components/AddingCheckDialog/hooks/useAddingCheckDialog';
import {useProblemsPermissions} from '@src/core/hooks/permissions/useProblemsPermissions';
import {useInspectionsAddingPermissions} from '@src/core/hooks/permissions/useInspectionsAddingPermissions';
import {useChecksPermissions} from '@src/core/hooks/permissions/useChecksPermissions';
import {useObject} from '@src/core/hooks/queries/objects/hooks';
import {useTranslation} from 'react-i18next';

interface IMobileEntityAddingProps {
	objectId: string;
}

const plusIcon = <i className="tz-plus-16" />;

const MobileEntityAdding = ({objectId}: IMobileEntityAddingProps) => {
	const {t} = useTranslation();
	const permissions = useChecksPermissions(objectId);
	const problemsPermissions = useProblemsPermissions(objectId);
	const inspectionsPermissions = useInspectionsAddingPermissions(objectId);
	const {data: object} = useObject(objectId);

	const [dialog, open] = useAddingCheckDialog({
		objectId,
		stage: object?.stage
	});

	if (permissions.canAdd && (problemsPermissions.canAdd || inspectionsPermissions.canAdd)) {
		return (
			<>
				<FloatingActionButton
					icon={plusIcon}
					label={t('checksPage.entityAdding.addButton')}
					minifyOnScroll
					scrollContainer="scroll-container"
					onClick={open}
				/>
				{dialog}
			</>
		);
	}
	return null;
};

export default MobileEntityAdding;
