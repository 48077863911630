import {memo, ReactElement, useCallback, useMemo} from 'react';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {MenuItem} from '@tehzor/ui-components';
import {IMenuItemProps} from '@tehzor/ui-components/src/components/menu/MenuItem';
import classNames from 'classnames';
import styles from './EntityTypeSelect.module.less';
import MenuLayer from '@tehzor/ui-components/src/components/menu/MenuLayer';
import {useTranslation} from 'react-i18next';
import {IDialogMenuTriggerFnProps} from '@tehzor/ui-components/src/components/menu/DialogMenu';
import {IPopupMenuTriggerFnProps} from '@tehzor/ui-components/src/components/menu/PopupMenu';
import {IPresaleCheckAddingEntityType} from '../../model/slice/presaleCheckAdding/interfaces';
import {presaleCheckAddingActions} from '../../model/slice/presaleCheckAdding/slice';

interface IEntityTypeSelectProps {
	type: IPresaleCheckAddingEntityType;
	types: IPresaleCheckAddingEntityType[];
	disabled: boolean;
}

export const EntityTypeSelect = memo(({type, types, disabled}: IEntityTypeSelectProps) => {
	const {t} = useTranslation();
	const dispatch = useAppDispatch();
	const changeType = (value: IPresaleCheckAddingEntityType) => {
		if (!disabled) {
			dispatch(presaleCheckAddingActions.changePresaleCheckAddingEntityType(value));
		}
	};

	const entityTypeMap = useMemo<Record<IPresaleCheckAddingEntityType, string>>(
		() => ({
			problem: t('addingPresaleCheckGlobal.entityTypeSelect.entityTypeMap.problem'),
			inspection: t('addingPresaleCheckGlobal.entityTypeSelect.entityTypeMap.inspection')
		}),
		[t]
	);

	const handleTrigger = useCallback(
		({isOpen, toggle, ref}: IDialogMenuTriggerFnProps | IPopupMenuTriggerFnProps) => (
			<div
				data-testid="EntityTypeSelect"
				className={classNames(styles.entityTypeSelect, {
					[styles.disabled]: disabled
				})}
				onClick={!disabled ? toggle : undefined}
				ref={ref}
			>
				{entityTypeMap[type]}{' '}
				{!disabled && (
					<i
						className={classNames('tz-simple-arrow-20', styles.tzSimpleArrow20, {
							[styles.isOpen]: isOpen
						})}
					/>
				)}
			</div>
		),
		[disabled, entityTypeMap, type]
	);

	const menuContent: Array<ReactElement<IMenuItemProps>> = [];
	for (const item of types) {
		menuContent.push(
			<MenuItem
				key={item}
				onClick={() => changeType(item)}
				selected={type === item}
			>
				{entityTypeMap[item]}
			</MenuItem>
		);
	}

	return menuContent.length > 1 ? (
		<MenuLayer trigger={handleTrigger}>{menuContent}</MenuLayer>
	) : (
		<div
			data-testid="EntityTypeSelect"
			className={classNames(styles.entityTypeSelect, styles.disabled)}
		>
			{entityTypeMap[type]}
		</div>
	);
});
