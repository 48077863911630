import {useQuery, UseQueryOptions} from '@tanstack/react-query';
import IError from '@tehzor/tools/interfaces/IError';
import {
	extractAllLatestInternalAcceptancesAsArray,
	extractLatestInternalAcceptanceId,
	extractLatestInternalAcceptancesAsArray
} from './selectors';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractUserId} from '@src/store/modules/auth/profile/selectors';
import {useCallback} from 'react';
import {internalAcceptancesQueryKeys} from '@src/api/cache/internalAcceptances/keys';
import {IGetInternalAcceptancesResponse} from '@src/api/backend/internalAcceptances';
import {IListInternalAcceptance} from '@tehzor/tools/interfaces/internalAcceptances/IListInternalAcceptance';
import {useLocalInternalAcceptances} from '@src/core/hooks/queries/internalAcceptances/hooks';
import {useInternalAcceptancesPermissions} from '@src/core/hooks/permissions/useInternalAcceptancesPermissions';

export const useFetchLatestInternalAcceptances = <T = IGetInternalAcceptancesResponse>(
	select?: (data: IGetInternalAcceptancesResponse) => T,
	options?: UseQueryOptions<IGetInternalAcceptancesResponse, IError, T>
) => {
	const permissions = useInternalAcceptancesPermissions();
	return useQuery<IGetInternalAcceptancesResponse, IError, T>({
		queryKey: internalAcceptancesQueryKeys.latest(),
		meta: {
			error: 'при загрузке последних внутренних приёмок'
		},
		enabled: permissions.canView,
		select,
		...options
	});
};

export const useExtractAllLatestInternalAcceptancesAsArray = () =>
	useFetchLatestInternalAcceptances(extractAllLatestInternalAcceptancesAsArray);

export const useExtractLatestInternalAcceptancesAsArray = (
	objectId: string,
	spaceId?: string,
	options?: UseQueryOptions<IGetInternalAcceptancesResponse, IError, IListInternalAcceptance[]>
) => {
	const userId = useAppSelector(extractUserId);
	const selector = useCallback(
		(data: IGetInternalAcceptancesResponse) =>
			extractLatestInternalAcceptancesAsArray(data, userId, objectId, spaceId),
		[userId, objectId, spaceId]
	);
	return useFetchLatestInternalAcceptances(selector, options);
};

export const useExtractLatestInternalAcceptanceId = (
	objectId: string,
	spaceId?: string,
	options?: UseQueryOptions<IGetInternalAcceptancesResponse, IError, string>
) => {
	const userId = useAppSelector(extractUserId);
	const {data: localInternalAcceptances} = useLocalInternalAcceptances();
	const selector = useCallback(
		(data: IGetInternalAcceptancesResponse) =>
			extractLatestInternalAcceptanceId(
				data,
				userId,
				objectId,
				spaceId,
				localInternalAcceptances
			),
		[userId, objectId, spaceId, localInternalAcceptances]
	);
	return useFetchLatestInternalAcceptances(selector, options);
};
