import {memo} from 'react';
import {CellProps} from 'react-table';
import {format} from 'date-fns';
import {dateTimeCommaSeparatedFormat} from '@tehzor/tools/utils/dateFormats';
import styles from './ModifiedCell.module.less';
import {ICustomField} from '@tehzor/tools/interfaces/fields';

export const ModifiedCell = memo(({row}: CellProps<ICustomField>) => {
	const {modifiedAt, modifiedBy} = row.original;

	return (
		<div className={styles.wrapper}>
			{modifiedAt && (
				<p className={styles.title}>{format(modifiedAt, dateTimeCommaSeparatedFormat)}</p>
			)}
			{modifiedBy && <p className={styles.title}>{modifiedBy.fullName}</p>}
		</div>
	);
});
