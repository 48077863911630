import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {createApiAction} from '@src/store/middlewares/api';
import {requestLastReply, IGetLastReplyResponse} from '@src/api/backend/reply';
import {AppThunkAction} from '@src/store/interfaces/thunk';
import IComment from '@tehzor/tools/interfaces/comments/IComment';
import {ICommentTypeId} from '@tehzor/tools/interfaces/comments/ICommentType';
import {addErrorToast} from '@tehzor/ui-components';
import {t} from 'i18next';

export interface IGetLastWorkAcceptanceReplyPayload {
	workAcceptanceId: string;
	reply: IComment | undefined;
}

const request = () => ({type: types.GET_REQUEST});

const success = (response: IGetLastReplyResponse, workAcceptanceId: string) => ({
	type: types.GET_SUCCESS,
	payload: {
		workAcceptanceId,
		reply: response
	} as IGetLastWorkAcceptanceReplyPayload
});

const failure = (error: IError) => {
	addErrorToast({title: t('toast.getLastWorkAcceptanceReply.error.title')});
	return {
		type: types.GET_FAILURE,
		payload: error
	};
};

/**
 * Возвращает последний ответ на приемку работ
 *
 * @param objectId id объекта
 * @param workAcceptanceId id приемки работ
 */
export const getLastWorkAcceptanceReply =
	(objectId: string, workAcceptanceId: string): AppThunkAction<Promise<IGetLastReplyResponse>> =>
	(dispatch, getState) => {
		const data = getState().entities.workAcceptancesLastReplies;
		if (data.hasOwnProperty(workAcceptanceId)) {
			return Promise.resolve(data[workAcceptanceId]);
		}
		return dispatch(
			createApiAction<IGetLastReplyResponse>(
				request,
				res => success(res, workAcceptanceId),
				failure,
				() =>
					requestLastReply(
						objectId,
						workAcceptanceId,
						ICommentTypeId.WORK_ACCEPTANCE_COMMENT
					)
			)
		);
	};
