import {extractAllLatestPresaleChecksAsArray} from './extractAllLatestPresaleChecksAsArray';
import {IGetPresaleChecksResponse} from '@/shared/api/presaleChecks/getPresaleChecks';

export const extractLatestPresaleChecksAsArray = (
	data: IGetPresaleChecksResponse,
	userId: string,
	objectId: string,
	spaceId?: string
) => {
	const arr = extractAllLatestPresaleChecksAsArray(data);

	return arr.filter(
		item =>
			item.objectId === objectId &&
			item.createdBy?.id === userId &&
			(!spaceId || spaceId === item.links?.spaceId)
	);
};
