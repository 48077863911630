import {useMemo} from 'react';
import {useProblemsPermissions} from '@src/core/hooks/permissions/useProblemsPermissions';
import {useInspectionsAddingPermissions} from '@src/core/hooks/permissions/useInspectionsAddingPermissions';
import {IWorkAcceptanceAddingEntityType} from '@src/store/modules/settings/workAcceptanceAdding/interfaces';

const defaultTypes: IWorkAcceptanceAddingEntityType[] = ['problem', 'inspection'];

export function useAvailableTypes(
	objectId: string,
	types: IWorkAcceptanceAddingEntityType[] = defaultTypes
) {
	const problemsPermissions = useProblemsPermissions(objectId);
	const inspectionsPermissions = useInspectionsAddingPermissions(objectId);

	return useMemo<IWorkAcceptanceAddingEntityType[]>(() => {
		const permissions = {
			problem: problemsPermissions.canAdd,
			inspection: inspectionsPermissions.canAdd
		};
		return types.filter(t => permissions[t]);
	}, [types, problemsPermissions, inspectionsPermissions]);
}
