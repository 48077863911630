import {wsConnector} from '../wsConnector';
import {IInternalAcceptance} from '@tehzor/tools/interfaces/internalAcceptances/IInternalAcceptance';

export type IMoveInternalAcceptanceResponse = IInternalAcceptance;

/**
 * Перемещает внутреннюю приёмку
 */
export const makeInternalAcceptanceMoveRequest = async (
	objectId: string,
	internalAcceptanceId: string,
	toObjectId: string,
	toSpaceId?: string
) =>
	wsConnector.sendAuthorizedRequest<IMoveInternalAcceptanceResponse>('moveInternalAcceptance', {
		objectId,
		internalAcceptanceId,
		toObjectId,
		toSpaceId
	});
