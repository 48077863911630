import {useCallback} from 'react';
import {useDeleteFieldsSettingsSet} from '@/entities/FieldsSettingsSets';
import {useTranslation} from 'react-i18next';
import {useTranslatedConfirmDialog} from '@src/core/hooks/translations/useTranslatedConfirmDialog';

export const useDeleteFieldsSettingsSetsDialog = (setIds: string[]) => {
	const {mutateAsync: deleteFieldsSettingSet} = useDeleteFieldsSettingsSet();
	const {t} = useTranslation();
	const [deleteDialog, getDeleteConfirmation] = useTranslatedConfirmDialog({
		title: t('useConfirmDialog.fieldsSettingsSet.deleteTitle'),
		message: t('useConfirmDialog.fieldsSettingsSet.deleteMessage')
	});

	const handleDelete = useCallback(async () => {
		const deletedSetsIds = [];
		if (await getDeleteConfirmation()) {
			for (const setId of setIds) {
				const deletedSet = await deleteFieldsSettingSet(setId);
				deletedSetsIds.push(deletedSet.id);
			}
		}
		return deletedSetsIds;
	}, [setIds, getDeleteConfirmation, deleteFieldsSettingSet]);

	return {deleteDialog, handleDelete};
};
