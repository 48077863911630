import {useMutation} from '@tanstack/react-query';
import {queryClient} from '@src/api/QueryClient';
import {fieldsQueryKeys} from '../keys/index';
import {ISavingCustomField} from '@tehzor/tools/interfaces/fields/ISavingCustomFieldt';
import {addCustomFieldRequest} from '@/shared/api/fields/addCustomField';
import {addErrorToast} from '@tehzor/ui-components';
import {useTranslation} from 'react-i18next';

export const useAddCustomFiled = () => {
	const {t} = useTranslation();

	return useMutation({
		mutationKey: fieldsQueryKeys.add(),
		mutationFn: (data: ISavingCustomField) => addCustomFieldRequest(data),
		onError: () => addErrorToast({title: t('toast.addCustomFiled.error.title')}),
		onSuccess: () => {
			void queryClient.invalidateQueries({queryKey: fieldsQueryKeys.all()});
		}
	});
};
