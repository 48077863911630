import {ActionButtons, Button, Dialog} from '@tehzor/ui-components';
import {ICustomField} from '@tehzor/tools/interfaces/fields';
import {useEditCustomFieldForm} from '@/features/Fields';

interface IEditingCustomFieldDialogProps {
	field?: ICustomField;
	isOpen: boolean;
	close: () => void;
}

export const EditingCustomFieldDialog = ({
	field,
	isOpen,
	close
}: IEditingCustomFieldDialogProps) => {
	const [form, handleSave, handleClose, saving] = useEditCustomFieldForm({
		field,
		onClose: close,
		onSave: close
	});

	return (
		<Dialog
			isOpen={isOpen}
			onRequestClose={handleClose}
			title={field ? 'Редактировать поле' : 'Добавить поле'}
			footer={
				<ActionButtons>
					<Button
						type="cancel"
						label="Отменить"
						disabled={saving}
						onClick={handleClose}
					/>
					<Button
						type="accent-blue"
						label="Сохранить"
						disabled={saving}
						onClick={handleSave}
					/>
				</ActionButtons>
			}
		>
			{form}
		</Dialog>
	);
};
