import {createContext, Dispatch, PropsWithChildren, useCallback} from 'react';
import {ITableContextMenuAction} from '@tehzor/tools/interfaces/table/ITableContextMenuAction';
import {IPreparedPerformer} from '../interfaces/IPreparedPerformer';
import {
	IEditableWorkingGroupAction,
	IEditableWorkingGroupState
} from '@src/core/hooks/states/useEditableWorkingGroupState';
import {
	messageTypes,
	useEditWorkingGroup
} from '@src/core/hooks/mutations/workingGroups/useEditWorkingGroup';
import {addSuccessToast} from '@tehzor/ui-components';
import {useTranslation} from 'react-i18next';
import {useTranslatedConfirmDialog} from '@src/core/hooks/translations/useTranslatedConfirmDialog';

export const DispatchActionCtx = createContext<
	(action: ITableContextMenuAction<IPreparedPerformer>) => void
>(() => ({}));

export const MenuActionCtxProvider = ({
	children,
	editingState,
	editingDispatch,
	workingGroupId
}: PropsWithChildren<{
	editingState: IEditableWorkingGroupState;
	editingDispatch: Dispatch<IEditableWorkingGroupAction>;
	workingGroupId?: string;
}>) => {
	const {t} = useTranslation();
	const [deleteDialog, getDeleteConfirmation] = useTranslatedConfirmDialog({
		title: t('useConfirmDialog.workingGroupPerformer.deleteTitle'),
		message: t('useConfirmDialog.workingGroup.deleteMessage')
	});
	const {mutateAsync: editWorkingGroup} = useEditWorkingGroup();
	const handleContextMenuAction = useCallback(
		async (action: ITableContextMenuAction<IPreparedPerformer>) => {
			if (action.type === 'delete' && (await getDeleteConfirmation())) {
				// удаляем исполнителя из группы
				if (editingState.performers?.includes(action.payload.id)) {
					if (workingGroupId) {
						const editParams = {
							groupId: workingGroupId,
							fields: {
								performers: editingState.performers.filter(
									id => id !== action.payload.id
								)
							},
							toastMessage: messageTypes.deletePerformer
						};
						await editWorkingGroup(editParams);

						editingDispatch({
							type: 'delete-array-item',
							field: 'performers',
							index: [action.payload.index]
						});
						addSuccessToast({title: t('toast.deletePerformer.success.title')});
					} else {
						editingDispatch({
							type: 'delete-array-item',
							field: 'performers',
							index: [action.payload.index]
						});
					}
				}
			}
		},
		[editingState.performers, editingDispatch]
	);

	return (
		<DispatchActionCtx.Provider value={handleContextMenuAction}>
			{children}
			{deleteDialog}
		</DispatchActionCtx.Provider>
	);
};
