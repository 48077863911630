import {makeProblemsExportRequest} from '@src/api/backend/problems';
import {problemsQueryKeys} from '@src/api/cache/problems/keys';
import {addExportToast} from '@/shared/lib/Toasts/ExportToast/addExportToast';
import {useMutation} from '@tanstack/react-query';
import {IExportResponse} from '@tehzor/tools/interfaces/IExportResponse';
import {addErrorToast, addWarningToast} from '@tehzor/ui-components';
import {queryClient} from '@src/api/QueryClient';
import {documentsQueryKeys} from '@src/api/cache/documents/keys';
import {problemsDocumentsStatsQueryKeys} from '@src/api/cache/problemsDocumentsStats/keys';
import {useTranslation} from 'react-i18next';

interface IExportProblemsParams {
	templateId: string;
	objectId: string;
	createDocument: boolean | undefined;
	email: string | undefined;
	filters: Record<string, unknown> | undefined;
	sort: Record<string, boolean> | undefined;
	selected?: string[] | undefined;
}

/**
 * Экспортирует список нарушений
 * {mutate, mutateAsync}
 * @param templateId id шаблона экспорта
 * @param objectId id объекта
 * @param createDocument флаг создания документа
 * @param email email для отправки
 * @param filters фильтры
 * @param sort сортировка
 * @param selected выбранные элементы
 */

export const useExportProblems = () => {
	const {t} = useTranslation();

	return useMutation<IExportResponse, Error, IExportProblemsParams>({
		mutationKey: problemsQueryKeys.export(),
		mutationFn: params => {
			const {templateId, objectId, createDocument, email, filters, sort, selected} = params;
			return makeProblemsExportRequest(
				templateId,
				objectId,
				createDocument,
				email,
				filters,
				sort,
				selected
			);
		},
		onSuccess: (response, params) => {
			if (response.warnings?.itemsLimit !== undefined) {
				addWarningToast({
					title: `${t('toast.export.warning.title')} (${String(
						response.warnings.itemsLimit
					)})`
				});
			}
			if (response.link) {
				addExportToast({
					title: params.email
						? t('toast.export.send.success.title')
						: t('toast.export.success.title'),
					url: response.link,
					fileName: response.file.name,
					document: response.document
				});
			}
			void queryClient.invalidateQueries({queryKey: documentsQueryKeys.lists()});
			void queryClient.invalidateQueries({queryKey: problemsDocumentsStatsQueryKeys.lists()});
		},
		onError: error => {
			addErrorToast({title: t('toast.export.error.title')});
			console.error('export error:', error);
		}
	});
};
