import {IListStructure} from '@tehzor/tools/interfaces/structures/IListStructure';
import {IEntityProblemsData} from '@tehzor/tools/interfaces/entititesComputedData/IEntityProblemsData';
import {IListWorkAcceptance} from '@tehzor/tools/interfaces/workAcceptances/IListWorkAcceptance';
import {IEntityWorkAcceptancesData} from '@tehzor/tools/interfaces/entititesComputedData/IEntityWorkAcceptancesData';

export const convertStructures = (
	structures: IListStructure[],
	problemsStats: Record<string, IEntityProblemsData> | undefined,
	workAcceptancesRecord?: Record<string, IListWorkAcceptance[]>,
	workAcceptancesStats?: Record<string, IEntityWorkAcceptancesData> | undefined
): IListStructure[] => {
	return structures.map(structure => ({
		...structure,
		problems: problemsStats?.[structure.id],
		workAcceptances: workAcceptancesRecord ? workAcceptancesRecord[structure.id] : [],
		workAcceptancesData: workAcceptancesStats?.[structure.id]
	}));
};
