import {BaseSkeleton} from '@tehzor/ui-components';
import {useIsDesktop} from '@/shared/hooks/dimensions/useIsDesktop/useIsDesktop';
import styles from './Skeleton.module.less';

interface ISkeletonProps {
	width?: number;
}

export const Skeleton = ({width = 350}: ISkeletonProps) => {
	const isDesktop = useIsDesktop();
	return (
		<div className={styles.wrapper}>
			<BaseSkeleton
				width={isDesktop ? width : '100%'}
				height={700}
			/>
		</div>
	);
};
