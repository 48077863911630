import {useMutation} from '@tanstack/react-query';
import {makeGetExportTemplateRequest} from '@src/api/backend/exportTemplates/getOne';
import saveOrOpenBlob from '@tehzor/tools/utils/saveOrOpenBlob';
import {addErrorToast, addWarningToast} from '@tehzor/ui-components';
import {useTranslation} from 'react-i18next';

export const useDownloadExportTemplate = () => {
	const {t} = useTranslation();

	return useMutation({
		mutationFn: (templateId: string) => makeGetExportTemplateRequest(templateId),
		onError: () => {
			addErrorToast({title: t('toast.downloadExportTemplate.error.title')});
		},
		onSuccess: response => {
			saveOrOpenBlob(response.fileContent, response.fileName);
			if (response.warnings?.itemsLimit !== undefined) {
				addWarningToast({
					title: `${t('toast.downloadExportTemplate.warning.title')} (${String(
						response.warnings.itemsLimit
					)})`
				});
			}
		}
	});
};
