export enum SelectedEntityType {
	PROBLEM = 'problem',
	INSPECTION = 'inspection',
	CHECK = 'check',
	PRESALE_CHECK = 'presale-check',
	LOCAL_CHECK = 'local-check',
	LOCAL_PRESALE_CHECK = 'local-presale-check',
	INTERNAL_ACCEPTANCE = 'internal-acceptance',
	LOCAL_INTERNAL_ACCEPTANCE = 'local-internal-acceptance',
	WARRANTY_CLAIM = 'warranty-claim',
	LOCAL_WARRANTY_CLAIM = 'local-warranty-claim',
	OWNER_ACCEPTANCE = 'owner-acceptance',
	LOCAL_OWNER_ACCEPTANCE = 'local-owner-acceptance',
	WORK_ACCEPTANCE = 'work-acceptance'
}
