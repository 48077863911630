import {TFunction} from 'i18next';
import {IEntitySettings} from '../../../core/hooks/settings/useEntitySettings';
import {ColumnWithSettings} from '../../../core/hooks/settings/useColumnsWithSettings/interfaces';
import {NumberAndStatsCell} from '../components/table/NumberAndStatsCell';
import {getCustomColumns} from '../../../utils/getCustomColumns';
import {StatusCell} from '../components/table/StatusCell';
import {SpaceCell} from '../components/table/SpaceCell';
import OwnerCell from '../components/table/OwnerCell';
import {CreatedCell} from '../components/table/CreatedCell';
import {ModifiedCell} from '../components/table/ModifiedCell';
import {MenuCell} from '../components/table/MenuCell';
import {IPreparedWarrantyClaimEntity} from '../interfaces/IPreparedWarrantyClaimEntity';

export const getDesktopColumns = (
	tFunc: TFunction,
	settings?: IEntitySettings
): Array<ColumnWithSettings<IPreparedWarrantyClaimEntity>> => {
	let columns: Array<ColumnWithSettings<IPreparedWarrantyClaimEntity>> = [
		{
			id: 'number',
			Header: '№',
			accessor: claim => claim.data.number ?? claim.data.localNumber,
			Cell: NumberAndStatsCell,
			minWidth: 130,
			width: 130,
			customizeSettings: {
				controlled: true
			}
		},
		{
			id: 'status',
			Header: tFunc('warrantyClaimsPage.table.columns.status.header'),
			Cell: StatusCell,
			accessor: claim => claim.data.status,
			minWidth: 130,
			width: 130,
			customizeSettings: {
				controlled: true
			}
		},
		{
			id: 'object',
			Header: tFunc('warrantyClaimsPage.table.columns.object.header'),
			accessor: row => row.data.object?.name,
			width: 130,
			customizeSettings: {
				controlled: true
			}
		},
		{
			id: 'space',
			Header: tFunc('warrantyClaimsPage.table.columns.spaceCell.header'),
			Cell: SpaceCell,
			width: 130,
			customizeSettings: {
				controlled: true
			}
		},
		{
			id: 'registrationDate',
			Header: tFunc('warrantyClaimsPage.table.columns.registrationDate.header'),
			accessor: row => row.data.registrationDate,
			width: 130,
			sortDescFirst: true,
			customizeSettings: {
				controlled: true
			}
		},
		{
			id: 'spaceOwner',
			Header: tFunc('warrantyClaimsPage.table.columns.spaceOwner.header'),
			accessor: row => row.data.spaceOwner?.name,
			Cell: OwnerCell,
			width: 130,
			customizeSettings: {
				controlled: true
			}
		},
		{
			id: 'createdAt',
			Header: tFunc('warrantyClaimsPage.table.columns.createdAt.header'),
			Cell: CreatedCell,
			accessor: claim => claim.data.createdAt,
			width: 130,
			sortDescFirst: true,
			customizeSettings: {
				controlled: true
			}
		},
		{
			id: 'modifiedAt',
			Header: tFunc('warrantyClaimsPage.table.columns.modifiedAt.header'),
			Cell: ModifiedCell,
			accessor: claim => claim.data.modifiedAt,
			width: 130,
			sortDescFirst: true,
			customizeSettings: {
				controlled: true
			}
		}
	];

	if (settings?.custom) {
		const customColumns = getCustomColumns<IPreparedWarrantyClaimEntity>(settings.custom);
		columns = [...columns, ...customColumns];
	}

	columns.push({
		id: '_menu',
		Cell: MenuCell,
		className: 'warranty-claims-table__menu-cell',
		width: 60,
		defaultCanSort: false,
		disableResizing: true,
		isNonClickable: true,
		customizeSettings: {
			controlled: false
		}
	});

	return columns;
};
