import DesktopEntityAdding from './EntityAdding.desktop';
import {useChecksPermissions} from '@src/core/hooks/permissions/useChecksPermissions';
import DesktopChecksExport from './ChecksExport.desktop';
import {useProblemsPermissions} from '@src/core/hooks/permissions/useProblemsPermissions';
import {useInspectionsAddingPermissions} from '@src/core/hooks/permissions/useInspectionsAddingPermissions';

interface IDesktopActionsProps {
	objectId?: string;
}

const ActionsDesktop = ({objectId}: IDesktopActionsProps) => {
	const permissions = useChecksPermissions(objectId);
	const problemsPermissions = useProblemsPermissions(objectId);
	const inspectionsPermissions = useInspectionsAddingPermissions(objectId);

	return (
		<>
			{objectId !== undefined &&
				permissions.canAdd &&
				(problemsPermissions.canAdd || inspectionsPermissions.canAdd) && (
					<DesktopEntityAdding objectId={objectId} />
				)}
			{permissions.canExport && <DesktopChecksExport objectId={objectId} />}
		</>
	);
};

export default ActionsDesktop;
