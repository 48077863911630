import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {httpRequests} from '@tehzor/tools/api/httpRequests';
import {IDocumentType} from '@tehzor/tools/interfaces/documents/IDocument';

export type IGetDocumentTypesResponse = INormalizedData<IDocumentType>;

/**
 * Отправляет запрос на получение списка типов документов
 */
export const requestDocumentTypes = async (): Promise<IGetDocumentTypesResponse> => {
	const response = await httpRequests.withToken.get<IGetDocumentTypesResponse>('document-types');
	return response.data;
};
