import {useCallback, useState} from 'react';
import * as React from 'react';
import IExportTemplate, {
	ExportTemplatesDestinationId
} from '@tehzor/tools/interfaces/IExportTemplate';
import {ExportDialog} from '@src/components/ExportDialog';
import {useExportProblemReplies} from '@src/core/hooks/mutations/problemReplies/useExportProblemReplies';

const availableDestinations = [ExportTemplatesDestinationId.PROBLEM_REPLY];

export function useReplyExport(objectId: string): [React.ReactNode, (id: string) => void] {
	const [exportingId, setExportingId] = useState<string>();
	const [isOpen, setOpen] = useState<boolean>(false);
	const {mutate: exportProblemReply} = useExportProblemReplies();

	const openDialog = useCallback((id: string) => {
		setExportingId(id);
		setOpen(true);
	}, []);

	const closeDialog = useCallback(() => {
		setExportingId(undefined);
		setOpen(false);
	}, []);

	const handleExport = useCallback(
		(template: IExportTemplate, email?: string, createDocument?: boolean) => {
			if (exportingId) {
				exportProblemReply({
					templateId: template.id,
					createDocument,
					email,
					objectId,
					commentId: exportingId
				});
			}
		},
		[objectId, exportingId]
	);

	const dialog = (
		<ExportDialog
			pageKey="problemReply"
			destinations={availableDestinations}
			isOpen={isOpen}
			onExport={handleExport}
			onClose={closeDialog}
		/>
	);

	return [dialog, openDialog];
}
