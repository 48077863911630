import {Dispatch} from 'react';
import {useUpdateEffect} from 'react-use';
import {
	IEditableCheckListAction,
	IEditableCheckListState
} from '@src/core/hooks/states/useEditableCheckListState';

export const useFormFieldsReset = (
	editingState: IEditableCheckListState,
	editingDispatch: Dispatch<IEditableCheckListAction>
) => {
	// Сброс объекта, если изменилась компания
	useUpdateEffect(() => {
		editingDispatch({type: 'update', field: 'objects', value: []});
		editingDispatch({type: 'update', field: 'typeDecoration', value: []});
	}, [editingState.companyId]);

	// Сброс процесса, если изменилась стадия
	useUpdateEffect(() => {
		editingDispatch({type: 'update', field: 'processId', value: null});
	}, [editingState.stage]);

	// Сброс полей, если изменился процесс
	useUpdateEffect(() => {
		editingDispatch({type: 'update', field: 'spaceTypes', value: []});
		editingDispatch({type: 'update', field: 'typeDecoration', value: []});
		editingDispatch({type: 'update', field: 'frontType', value: null});
		editingDispatch({type: 'update', field: 'categories', value: []});
		editingDispatch({type: 'update', field: 'structureType', value: null});
	}, [editingState.processId]);

	// Сброс полей, если изменился тип фронта
	useUpdateEffect(() => {
		editingDispatch({type: 'update', field: 'structureType', value: null});
		editingDispatch({type: 'update', field: 'spaceTypes', value: []});
		editingDispatch({type: 'update', field: 'typeDecoration', value: []});
	}, [editingState.frontType]);

	// Сброс полей, если изменился объект
	useUpdateEffect(() => {
		editingDispatch({type: 'update', field: 'typeDecoration', value: []});
	}, [editingState.objects]);

	// Сброс полей, если изменился тип помещения
	useUpdateEffect(() => {
		editingDispatch({type: 'update', field: 'typeDecoration', value: []});
	}, [editingState.spaceTypes]);

	// Сброс полей, если изменился тип структуры
	useUpdateEffect(() => {
		editingDispatch({type: 'update', field: 'typeDecoration', value: []});
	}, [editingState.structureType]);
};
