import {IconMenu, MenuItem} from '@tehzor/ui-components';
import {IInternalAcceptance} from '@tehzor/tools/interfaces/internalAcceptances/IInternalAcceptance';
import {useInternalAcceptanceDeleteConfirmDialog} from '../../hooks/useInternalAcceptanceDeleteConfirmDialog';
import {useTranslation} from 'react-i18next';
import {useMovingInternalAcceptanceDialog} from '@src/components/MovingInternalAcceptanceDialog/hooks/useMovingInternalAcceptanceDialog';
import {IPermissions} from '@src/core/hooks/permissions/useInternalAcceptancePermissions';

interface IMobileMenuProps {
	objectId: string;
	internalAcceptance: IInternalAcceptance;
	permissions: IPermissions;
}

const arrowIcon = <i className="tz-long-arrow-24" />;
const deleteIcon = <i className="tz-delete" />;

const MobileMenu = ({objectId, internalAcceptance, permissions}: IMobileMenuProps) => {
	const {t} = useTranslation();
	const [deleteDialog, handleDeleteClick] = useInternalAcceptanceDeleteConfirmDialog(
		objectId,
		internalAcceptance
	);

	const [moveDialog, openMoveDialog] = useMovingInternalAcceptanceDialog(
		internalAcceptance.objectId,
		internalAcceptance
	);

	const items = [];
	if (permissions.canMove && permissions.canProblemsMove) {
		items.push(
			<MenuItem
				key="move"
				icon={arrowIcon}
				onClick={openMoveDialog}
			>
				{t('internalAcceptancePage.actions.menu.move')}
			</MenuItem>
		);
	}

	if (permissions.canDelete) {
		items.push(
			<MenuItem
				key="delete"
				icon={deleteIcon}
				onClick={handleDeleteClick}
			>
				{t('useConfirmDialog.internalAcceptance.deleteTitle')}
			</MenuItem>
		);
	}

	return items.length ? (
		<>
			<IconMenu>{items}</IconMenu>

			{permissions.canDelete && deleteDialog}
			{permissions.canMove && permissions.canProblemsMove && moveDialog}
		</>
	) : null;
};

export default MobileMenu;
