import {useCallback} from 'react';
import {ActionButtons, Button, Dialog} from '@tehzor/ui-components';
import {IPresaleCheck} from '@tehzor/tools/interfaces/presaleChecks/IPresaleCheck';
import {useTranslation} from 'react-i18next';
import {useEditPresaleCheck} from '../../model/hooks/useEditPresaleCheck';
import {useEditablePresaleCheckAttachments} from '../../hooks/useEditablePresaleCheckAttachments';
import {useTranslatedConfirmDialog} from '@src/core/hooks/translations/useTranslatedConfirmDialog';

interface IEditablePresaleCheckAttachmentsDialogProps {
	objectId: string;
	presaleCheckId: string;
	presaleCheck: IPresaleCheck;
	isOpen: boolean;
	onClose: () => void;
}

/**
 * Окно редактирования вложений предпродажной проверки
 */
export const EditablePresaleCheckAttachmentsDialog = ({
	objectId,
	presaleCheckId,
	presaleCheck,
	isOpen,
	onClose
}: IEditablePresaleCheckAttachmentsDialogProps) => {
	const {t} = useTranslation();
	const {mutateAsync: editPresaleCheck, isPending: saving} = useEditPresaleCheck();

	const {fields, getSavingData, reset, isBlocking, isSaveDisabled} =
		useEditablePresaleCheckAttachments(objectId, presaleCheck, saving);

	const save = useCallback(async () => {
		if (presaleCheckId) {
			const savingData = await getSavingData();
			if (savingData) {
				try {
					await editPresaleCheck({
						objectId,
						presaleCheckId,
						fields: savingData
					});
					onClose();
				} catch (error) {
					console.log(error);
				}
			}
		}
	}, [presaleCheckId, getSavingData, objectId, onClose]);

	const [closingDialog, getClosingConfirmation] = useTranslatedConfirmDialog();

	const handleClose = useCallback(async () => {
		if (!isBlocking || (await getClosingConfirmation())) {
			onClose();
		}
	}, [isBlocking, onClose, getClosingConfirmation]);

	return (
		<Dialog
			isOpen={isOpen}
			title={t('editablePresaleCheckAttachmentsDialog.title')}
			footer={
				<ActionButtons>
					<Button
						type="cancel"
						label={t('editablePresaleCheckAttachmentsDialog.cancel')}
						disabled={saving}
						onClick={handleClose}
					/>
					<Button
						type="accent-blue"
						label={t('editablePresaleCheckAttachmentsDialog.save')}
						disabled={isSaveDisabled || saving}
						onClick={save}
					/>
				</ActionButtons>
			}
			fullScreenOnTablet
			onRequestClose={handleClose}
			onAfterClose={reset}
		>
			{fields}
			{closingDialog}
		</Dialog>
	);
};
