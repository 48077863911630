import {useMemo} from 'react';
import {useProblemsPermissions} from '@src/core/hooks/permissions/useProblemsPermissions';
import {useInspectionsAddingPermissions} from '@src/core/hooks/permissions/useInspectionsAddingPermissions';
import {
	IParamAggregateEntityPermissions,
	useAggregateEntityPermissions
} from '@src/core/hooks/permissions/useAggregateEntityPermissions';
import {IPresaleCheckAddingEntityType} from '../../model/slice/presaleCheckAdding/interfaces';

const defaultTypes: IPresaleCheckAddingEntityType[] = ['problem', 'inspection'];

export const useAvailablePresaleCheckTypes = (
	objectId: string,
	types: IPresaleCheckAddingEntityType[] = defaultTypes,
	param?: IParamAggregateEntityPermissions
) => {
	const problemsPermissions = useProblemsPermissions(objectId);
	const presaleCheckProblemPermissions = useAggregateEntityPermissions(
		objectId,
		'presale-check-problems-add',
		param
	);
	const inspectionsPermissions = useInspectionsAddingPermissions(objectId);
	const presaleCheckInspectionPermissions = useAggregateEntityPermissions(
		objectId,
		'presale-check-inspections-add',
		param
	);

	return useMemo<IPresaleCheckAddingEntityType[]>(() => {
		const permissions = {
			problem: problemsPermissions.canAdd && presaleCheckProblemPermissions.canAdd,
			inspection: inspectionsPermissions.canAdd && presaleCheckInspectionPermissions.canAdd
		};
		return types.filter(t => permissions[t]);
	}, [types, problemsPermissions, inspectionsPermissions]);
};
