import {useCallback} from 'react';
import ProblemsPage from './ProblemsPage';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {IProblemsFiltersState} from '@src/store/modules/settings/pages/problems/interfaces';
import {problemsActions} from '@src/store/modules/settings/pages/problems/slice';
import {extractProblemsPageSettings} from '@src/store/modules/settings/pages/problems/selectors';
import EntitiesFiltersProvider from '@src/components/EntitiesFilters/utils/EntitiesFiltersProvider';
import {ProblemSearchField} from '@src/constants/filters/searchFields';
/**
 * Обёртка над страницей нарушений
 * необходима для того чтобы при переходе от одной страницы к другой отрабатывали все методы жизненного цикла
 *
 * @constructor
 */
const AllProblemsPageWrap = (props: object) => {
	const dispatch = useAppDispatch();
	const {changeFilters, changeOffset, clearFilters, changeSearchBy} = problemsActions;
	const {filters} = useAppSelector(s => extractProblemsPageSettings(s, 'all'));

	const applyFilters = useCallback(
		(value: IProblemsFiltersState) => {
			dispatch(changeFilters({objectId: 'all', filters: value}));
			dispatch(changeOffset({objectId: 'all', offset: 0}));
		},
		[changeFilters, changeOffset, dispatch]
	);

	const onClear = useCallback(() => {
		dispatch(clearFilters({objectId: 'all'}));
		dispatch(changeSearchBy({objectId: 'all', searchBy: ProblemSearchField.Description}));
	}, [clearFilters, dispatch]);

	return (
		<EntitiesFiltersProvider
			objectId="all"
			onApply={applyFilters}
			filters={filters}
			onClear={onClear}
		>
			<ProblemsPage
				key="all-problems"
				{...props}
			/>
		</EntitiesFiltersProvider>
	);
};

export default AllProblemsPageWrap;
