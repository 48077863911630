import {useMutation} from '@tanstack/react-query';
import {fieldSettingsQueryKeys} from '../keys/index';
import {queryClient} from '@src/api/QueryClient';
import {
	IEditFieldSettingsRequest,
	requestEditFieldSettings
} from '@/shared/api/fieldSettings/editFieldSettings';
import {addErrorToast} from '@tehzor/ui-components';
import {useTranslation} from 'react-i18next';

export const useEditFieldSettings = () => {
	const {t} = useTranslation();

	return useMutation({
		mutationKey: fieldSettingsQueryKeys.edit(),
		mutationFn: (data: IEditFieldSettingsRequest) => requestEditFieldSettings(data),
		onError: () => addErrorToast({title: t('toast.editFieldSettings.error.title')}),
		onSuccess: () => {
			void queryClient.invalidateQueries({queryKey: fieldSettingsQueryKeys.all()});
		}
	});
};
