import React, {useCallback} from 'react';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {MenuItem, MultipleMenu} from '@tehzor/ui-components';
import {IMultipleMenuProps} from '@tehzor/ui-components/src/components/menu/MultipleMenu';
import {ICommentVariants} from '@tehzor/tools/interfaces/comments/ICommentType';
import {useTranslation} from 'react-i18next';
import {TFunction} from 'i18next';

const sortFn = (a: string, b: string) => (a > b ? -1 : 1);

const getVisibilityLabel = (
	visibility: ICommentVariants[],
	t: TFunction<'translation', undefined>
) => {
	let label = t('commentTitle.selectOption');
	if (visibility.length === 2) {
		label = t('commentTitle.replyAndComments');
	} else if (visibility.includes(ICommentVariants.COMMENTS)) {
		label = t('commentTitle.comments');
	} else if (visibility.includes(ICommentVariants.REPLIES)) {
		label = t('commentTitle.reply');
	}
	return label;
};

/**
 * Формирует меню и отображаемое значение для фильтра видимости комментариев или ответов сущности
 */
export function useVisibilityFilterMenu(
	visibility: ICommentVariants[],
	changeCommentsVisibility: (value: ICommentVariants[]) => {
		type: string;
		payload: ICommentVariants[];
	}
): [React.ReactElement<IMultipleMenuProps>, string] {
	const dispatch = useAppDispatch();
	const {t} = useTranslation();

	const handleChange = useCallback(
		(keys: ICommentVariants[]) => {
			dispatch(changeCommentsVisibility(keys.sort(sortFn)));
		},
		[changeCommentsVisibility, dispatch]
	);

	const label = getVisibilityLabel(visibility, t);

	const menu = (
		<MultipleMenu
			value={visibility}
			onChange={handleChange}
		>
			<MenuItem itemKey={ICommentVariants.REPLIES}>{t('commentTitle.reply')}</MenuItem>
			<MenuItem itemKey={ICommentVariants.COMMENTS}>{t('commentTitle.comments')}</MenuItem>
		</MultipleMenu>
	);

	return [menu, label];
}
