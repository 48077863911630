import {makeSpacesExportRequest} from '@src/api/backend/spaces';
import {IExportSpacesParams} from '@src/api/cache/spaces/defaults/useSpacesMutationDefaults';
import {spacesQueryKeys} from '@src/api/cache/spaces/keys';
import {useMutation} from '@tanstack/react-query';
import {IExportResponse} from '@tehzor/tools/interfaces/IExportResponse';
import {addErrorToast} from '@tehzor/ui-components';
import {addExportToast} from '@/shared/lib/Toasts/ExportToast/addExportToast';
import {useTranslation} from 'react-i18next';

export const useExportSpacesMutation = (objectId: string) => {
	const {t} = useTranslation();

	return useMutation({
		mutationKey: spacesQueryKeys.exportById(objectId),
		mutationFn: ({templateId, createDocument, email}: IExportSpacesParams) =>
			makeSpacesExportRequest(templateId, objectId, createDocument, email),
		onSuccess: (response: IExportResponse, {email}: IExportSpacesParams) => {
			if (response.link) {
				addExportToast({
					title: email
						? t('toast.export.send.success.title')
						: t('toast.export.success.title'),
					url: response.link,
					fileName: response.file.name,
					document: response.document
				});
			}
		},
		onError: () => {
			addErrorToast({title: t('toast.export.error.title')});
		}
	});
};
