import {IEntityProblemsData} from '@tehzor/tools/interfaces/entititesComputedData/IEntityProblemsData';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {ProcessIds} from '@tehzor/tools/interfaces/process/ProcessId';

export const getProblemsDataByProcess = (
	problemsStats: IEntityProblemsData,
	stage?: ObjectStageIds,
	processId?: ProcessIds
): Record<string, {count: number; critical: boolean}> | undefined => {
	const problems = problemsStats.problems;

	if (stage && processId) {
		return problems?.[stage]?.[processId];
	}

	return problems
		? Object.values(problems).reduce<Record<string, {count: number; critical: boolean}>>(
				(prev, stageItem) => {
					for (const processItem of Object.values(stageItem)) {
						for (const status in processItem) {
							if (processItem.hasOwnProperty(status)) {
								if (prev[status]) {
									prev[status].count += processItem[status].count;
									prev[status].critical =
										prev[status].critical || processItem[status].critical;
								} else {
									prev = {
										...prev,
										[status]: {
											count: processItem[status].count,
											critical: processItem[status].critical
										}
									};
								}
							}
						}
					}
					return prev;
				},
				{}
		  )
		: undefined;
};
