import {
	IInspectionsFiltersState,
	IInspectionsSortState
} from '@src/store/modules/settings/pages/inspections/interfaces';

export type IInspectionStoriesQueryKey = [] & {
	0: string;
	1: string;
	2: string; // objectId
	3: string; // inspectionId
};

export type IInspectionsListQueryKey = [] & {
	0: string; // inspections
	1: string; // list
	2: string; // objectId
	3: IInspectionsFiltersState; // filters
	4: IInspectionsSortState; // sort
	5: number; // offset
	6: number; // pageSize
};

export const inspectionsQueryKeys = {
	all: () => ['inspections'],
	one: () => ['inspection'],
	list: () => [...inspectionsQueryKeys.all(), 'list'],
	localList: () => [...inspectionsQueryKeys.all(), 'local-list'],
	add: () => [...inspectionsQueryKeys.one(), 'add'],
	savingDatas: () => [...inspectionsQueryKeys.one(), 'saving-data'],
	savingData: (key: string) => [...inspectionsQueryKeys.one(), 'saving-data', key],
	details: () => [...inspectionsQueryKeys.one(), 'detail'],
	detail: (id: string) => [...inspectionsQueryKeys.one(), 'detail', id],
	edit: () => [...inspectionsQueryKeys.one(), 'edit'],
	stories: () => [...inspectionsQueryKeys.one(), 'story'],
	story: (objectId: string, inspectionId: string) => [
		...inspectionsQueryKeys.stories(),
		objectId,
		inspectionId
	],
	export: () => [...inspectionsQueryKeys.all(), 'export'],
	exportOne: () => [...inspectionsQueryKeys.one(), 'export']
};
