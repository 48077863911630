import {useMutation} from '@tanstack/react-query';
import {queryClient} from '@src/api/QueryClient';
import {fieldSettingsSetsQueryKeys} from '../keys/index';
import {
	IMaceEditFieldsSettingsSetRequest,
	requestEditFieldsSettingsSet
} from '@/shared/api/fieldsSettingsSets/editFieldsSettingsSet';
import {addErrorToast} from '@tehzor/ui-components';
import {useTranslation} from 'react-i18next';

export const useEditFieldsSettingsSet = () => {
	const {t} = useTranslation();

	return useMutation({
		mutationKey: fieldSettingsSetsQueryKeys.edit(),
		mutationFn: (data: IMaceEditFieldsSettingsSetRequest) => requestEditFieldsSettingsSet(data),
		onError: () => addErrorToast({title: t('toast.editFieldsSettingsSet.error.title')}),
		onSuccess: data => {
			queryClient.setQueryData(fieldSettingsSetsQueryKeys.detail(data.id), set =>
				set ? {...set, ...data} : data
			);
			void queryClient.invalidateQueries({queryKey: fieldSettingsSetsQueryKeys.all()});
		}
	});
};
