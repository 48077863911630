import {IPresaleCheckEntity} from '@src/interfaces/IPresaleCheckEntity';
import {Column} from 'react-table';
import {PresaleCheckColumnsDesktop} from '../ui/Columns/PresaleCheckColumnsDesktop';
import {PresaleCheckColumnsMobile} from '../ui/Columns/PresaleCheckColumnsMobile';
import {usePresaleCheckProblemCommentsByProblem} from '@/entities/PresaleCheck';

interface IUsePresaleCheckColumnsProps {
	isDesktop: boolean;
	objectId: string;
	presaleCheckId: string;
}

export const usePresaleCheckEntitiesColumns = ({
	isDesktop,
	objectId,
	presaleCheckId
}: IUsePresaleCheckColumnsProps): Array<Column<IPresaleCheckEntity>> => {
	const {data: replies} = usePresaleCheckProblemCommentsByProblem(presaleCheckId, objectId);

	if (isDesktop) {
		return PresaleCheckColumnsDesktop({replies});
	} else {
		return PresaleCheckColumnsMobile({replies});
	}
};
