import {useStrictParams} from '@src/core/hooks/useStrictParams';
import {useCustomFieldById} from '@/entities/Fields';
import {useEditCustomFieldForm} from '@/features/Fields';
import {useAppHeader} from '@src/components/AppHeader/hooks/useAppHeader';
import {Button, IconButton} from '@tehzor/ui-components';
import styles from './MobileCustomFieldEditPage.module.less';
import {useChangePath} from '@src/core/hooks/useChangePath';

interface IMobileCustomFieldEditPageProps {
	isEditing?: boolean;
}

const iconClose = <i className="tz-close-20" />;

export const MobileCustomFieldEditPage = ({isEditing = false}: IMobileCustomFieldEditPageProps) => {
	const {goBack} = useChangePath();
	const {fieldId} = useStrictParams<{fieldId: string}>();

	const {data: field} = useCustomFieldById(fieldId);

	const [form, handleSave, handleClose, saving] = useEditCustomFieldForm({
		field,
		onSave: goBack,
		onClose: goBack
	});

	useAppHeader(
		{
			title: isEditing ? 'Редактировать поле' : 'Добавить поле',
			mobileLeftButton: false,
			mobileRightButtons: <IconButton onClick={handleClose}>{iconClose}</IconButton>
		},
		[handleClose, field]
	);

	return (
		<div className={styles.wrapper}>
			{form}
			<div className={styles.bar}>
				<Button
					type="cancel"
					disabled={saving}
					label="Отменить"
					onClick={handleClose}
				/>
				<Button
					type="accent-blue"
					disabled={saving}
					label="Сохранить"
					onClick={handleSave}
				/>
			</div>
		</div>
	);
};
