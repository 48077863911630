import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {IWorkAcceptanceStatus} from '@tehzor/tools/interfaces/workAcceptances/IWorkAcceptanceStatus';
import {IConvertedWorkAcceptancesData} from '@tehzor/tools/interfaces/structures/IConvertedWorkAcceptancesData';
import {IEntityWorkAcceptancesData} from '@tehzor/tools/interfaces/entititesComputedData/IEntityWorkAcceptancesData';

export const convertWorkAcceptancesData = (
	workAcceptancesStats: IEntityWorkAcceptancesData | undefined,
	workAcceptanceStatuses: INormalizedData<IWorkAcceptanceStatus>
): IConvertedWorkAcceptancesData[] => {
	if (!workAcceptancesStats || typeof workAcceptancesStats !== 'object') {
		return [];
	}

	const result = [];

	for (const statusId of Object.keys(workAcceptancesStats)) {
		const workAcceptancesStatsCount = workAcceptancesStats[statusId]?.count;
		if (workAcceptancesStatsCount) {
			result.push({
				key: statusId,
				value: workAcceptancesStatsCount,
				color: workAcceptanceStatuses.byId[statusId]?.color || '#00000000'
			});
		}
	}

	return result;
};
