import {useMutation} from '@tanstack/react-query';
import {internalAcceptancesQueryKeys} from '@src/api/cache/internalAcceptances/keys';
import {makeInternalAcceptanceExportRequest} from '@src/api/backend/internalAcceptance';
import {addExportToast} from '@/shared/lib/Toasts/ExportToast/addExportToast';
import {addErrorToast, addWarningToast} from '@tehzor/ui-components';
import {useTranslation} from 'react-i18next';

interface IExportInternalAcceptanceParams {
	templateId: string;
	objectId: string;
	internalAcceptanceId: string;
	problems?: string[];
	inspections?: string[];
	createDocument?: boolean;
	email?: string;
}

export const useExportInternalAcceptance = () => {
	const {t} = useTranslation();

	return useMutation({
		mutationKey: internalAcceptancesQueryKeys.exportOne(),
		mutationFn: (params: IExportInternalAcceptanceParams) => {
			const {
				templateId,
				objectId,
				internalAcceptanceId,
				problems,
				inspections,
				createDocument,
				email
			} = params;
			return makeInternalAcceptanceExportRequest(
				templateId,
				objectId,
				internalAcceptanceId,
				problems,
				inspections,
				createDocument,
				email
			);
		},
		onSuccess: data => {
			if (data.warnings?.itemsLimit !== undefined) {
				addWarningToast({
					title: `${t('toast.export.warning.title')} (${String(
						data.warnings.itemsLimit
					)})`
				});
			}
			if (data.link) {
				addExportToast({
					title: t('toast.export.success.title'),
					url: data.link,
					fileName: data.file.name,
					document: data.document
				});
			}
		},
		onError: () => {
			addErrorToast({title: t('toast.export.error.title')});
		}
	});
};
