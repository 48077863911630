import {memo} from 'react';
import * as React from 'react';
import {Button} from '@tehzor/ui-components';
import './EntitiesFilters.less';
import {useFiltersActive} from './utils/useFiltersActive';
import {useIsDesktop} from '@tehzor/ui-components/src/utils/mediaQueries';
import {MobileFiltersLinkBtn} from '@src/components/MobileFiltersLinkBtn';
import classNames from 'classnames';
import {useEntitiesFiltersCtx} from './utils/entitiesFiltersCtx';
import {useTranslation} from 'react-i18next';

interface IEntitiesFiltersProps {
	className?: string;
	children: React.ReactNode;
	objectId?: string;
	desktopOnly?: boolean;
	entity: string;
	isDescendants?: boolean;
}

const EntitiesFilters = (props: IEntitiesFiltersProps) => {
	const {className, children, objectId = 'all', entity, desktopOnly, isDescendants} = props;
	const {clear, state} = useEntitiesFiltersCtx();
	const {t} = useTranslation();
	const isFiltersActive = useFiltersActive(objectId, state, isDescendants);
	const isDesktop = useIsDesktop();
	// TODO Вынести это отсюда после переделки страниц, где используется фильтр
	if (!isDesktop && !desktopOnly) {
		return (
			<MobileFiltersLinkBtn
				state={state}
				objectId={objectId}
				entity={entity}
				isDescendants={isDescendants}
			/>
		);
	}
	return (
		<div className={classNames('entities-filters', className)}>
			{children}

			{isFiltersActive ? (
				<Button
					type="cancel"
					label={t('entitiesFilters.cancelButton.label')}
					onClick={clear}
				/>
			) : null}
		</div>
	);
};

export default memo(EntitiesFilters);
