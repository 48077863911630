import {IPreparedField} from '@/features/Fields';
import {useMemo, useState} from 'react';
import {useUpdateEffect} from 'react-use';
import styles from '@/widgets/FieldsSettingsTable/ui/FieldsSettingsTable.module.less';
import {getEnabledFields} from '../../model/utils/getEnabledFields';
import {getDisabledFields} from '../../model/utils/getDisabledFields';
import {SettingsTable} from '../SettingsTable/SettingsTable';

interface IFieldsTableProps {
	setId: string;
	fields: IPreparedField[];
	isBuiltIn?: boolean;
}

export const FieldsTable = ({setId, fields, isBuiltIn = false}: IFieldsTableProps) => {
	const [enabledFields, setEnabledFields] = useState(() => getEnabledFields(fields));
	const disabledField = useMemo(() => getDisabledFields(fields), [fields]);

	useUpdateEffect(() => {
		setEnabledFields(getEnabledFields(fields));
	}, [fields]);

	return (
		<div className={styles.container}>
			<SettingsTable
				dndContainerId="fieldsWithSettings"
				title="Активные поля"
				setId={setId}
				fields={enabledFields}
				onChangeOrder={setEnabledFields}
				isBuiltIn={isBuiltIn}
				withSettings
			/>
			<SettingsTable
				dndContainerId="fieldsWithoutSettings"
				title="Неактивные поля"
				setId={setId}
				fields={disabledField}
				isBuiltIn={isBuiltIn}
			/>
		</div>
	);
};
