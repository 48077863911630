import {memo} from 'react';
import {CellProps} from 'react-table';
import styles from './MobileCell.module.less';
import {IBuiltinField} from '@tehzor/tools/interfaces/fields';
import {useFieldEntityTypeById} from '@/entities/Fields';

export const MobileCell = memo(({row}: CellProps<IBuiltinField>) => {
	const {name, key, entityTypeId} = row.original;

	const {data: entityType} = useFieldEntityTypeById(entityTypeId);

	return (
		<div className={styles.wrapper}>
			<div className={styles.header}>
				<p className={styles.title}>{name}</p>
			</div>
			<div className={styles.info}>
				<p className={styles.infoItem}>
					Ключ: <span>{key}</span>
				</p>
				{entityType && (
					<p className={styles.infoItem}>
						Тип сущности: <span>{entityType.name}</span>
					</p>
				)}
			</div>
		</div>
	);
});
