export const customIcons: string[] = [
	'tz-custom-rhombus-20',
	'tz-custom-socket-20',
	'tz-custom-lamp-20',
	'tz-custom-shovel-20',
	'tz-custom-exclamation-point-20',
	'tz-custom-rectangles-20',
	'tz-custom-block-20',
	'tz-custom-snowflake-20',
	'tz-custom-shield-20',
	'tz-custom-triangle-20',
	'tz-custom-hexagon-20',
	'tz-custom-polygon-20'
];
