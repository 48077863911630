import useAppDispatch from '@src/core/hooks/useAppDispatch';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {editProfile} from '@src/store/modules/auth/profile/actions';
import {extractUserProfile} from '@src/store/modules/auth/profile/selectors';
import {useCallback, useEffect} from 'react';
import {closeToast, ToastsContainersIds} from '@tehzor/ui-components';
import {addEmailNotificationsToast} from './utils/addEmailNotificationsToast';
import {useTranslation} from 'react-i18next';

export const EmailNotificationsCheck = () => {
	const {t} = useTranslation();
	const dispatch = useAppDispatch();
	const {subscribed} = useAppSelector(extractUserProfile);

	const handleChangeSubscribe = useCallback(
		async (toastId: number | string, value: boolean) => {
			await dispatch(editProfile({subscribed: value}));
			closeToast({id: toastId, containerId: ToastsContainersIds.MAIN_STACKED});
		},
		[dispatch]
	);

	useEffect(() => {
		if (subscribed === undefined) {
			addEmailNotificationsToast(handleChangeSubscribe, t);
		}
	}, [t, subscribed, handleChangeSubscribe]);

	return null;
};
