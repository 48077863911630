import IError from '@tehzor/tools/interfaces/IError';
import {createApiAction} from '@src/store/middlewares/api';
import {AppThunkDispatch} from '@src/store/interfaces/thunk';
import * as types from '../../constants/records';
import {ICheckRecord} from '@tehzor/tools/interfaces/checkRecords/ICheckRecord';
import {ICheckItem} from '@tehzor/tools/interfaces/checkItems/ICheckItem';
import {CheckRecordStatusId} from '@tehzor/tools/interfaces/checkRecords/ICheckRecordStatus';
import {ICheckList} from '@tehzor/tools/interfaces/checkLists/ICheckList';
import {makeCheckRecordEditStatusRequest} from '@src/api/backend/checkRecord/editStatus';
import {checkChangedProblems} from '@src/store/modules/pages/checkLists/actions';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {addErrorToast} from '@tehzor/ui-components';
import {t} from 'i18next';

export type IChangeStateCheckRecordPayload = ICheckRecord[];

const request = () => ({
	type: types.CHANGE_STATUS_REQUEST
});

const success =
	(response: ICheckRecord[], spaceId: string | undefined, workAcceptanceId: string | undefined) =>
	(dispatch: AppThunkDispatch) => {
		if (response.length) {
			void dispatch(
				checkChangedProblems(
					response.map(({links}) => links.checkListId),
					spaceId,
					workAcceptanceId
				)
			);
		}

		return dispatch({
			type: types.CHANGE_STATUS_SUCCESS,
			payload: response
		});
	};

const failure = (error: IError) => {
	if (error.statusCode !== 401) {
		addErrorToast({title: t('toast.changeItemState.error.title')});
	}
	return {
		type: types.CHANGE_STATUS_FAILURE,
		payload: {...error}
	};
};

/**
 * Изменяет запись по категории
 *
 * @param item текущая категория
 * @param objectId id объекта
 * @param spaceId id помещения
 * @param stage стадия
 * @param status новый статус
 */
export const changeItemState = (
	item: ICheckItem,
	objectId: string,
	spaceId: string | undefined,
	workAcceptanceId: string | undefined,
	stage: ObjectStageIds,
	status: CheckRecordStatusId
) =>
	createApiAction<ICheckRecord[]>(
		request,
		(res: ICheckRecord[]) => success(res, spaceId, workAcceptanceId),
		failure,
		() =>
			makeCheckRecordEditStatusRequest(
				objectId,
				{spaceId, workAcceptanceId, checkListId: item.checkListId, checkItemId: item.id},
				stage,
				status
			)
	);

export const changeCheckStateActions = {request, success, failure};

/**
 * Изменяет запись по чек-листу
 *
 * @param list текущий чек-лист
 * @param objectId id объекта
 * @param spaceId id помещения
 * @param workAcceptanceId id приёмки работ
 * @param stage стадия
 * @param status новый статус
 */
export const changeListState = (
	list: ICheckList,
	objectId: string,
	spaceId: string | undefined,
	workAcceptanceId: string | undefined,
	stage: ObjectStageIds,
	status: CheckRecordStatusId
) =>
	createApiAction<ICheckRecord[]>(
		request,
		(res: ICheckRecord[]) => success(res, spaceId, workAcceptanceId),
		failure,
		() =>
			makeCheckRecordEditStatusRequest(
				objectId,
				{spaceId, workAcceptanceId, checkListId: list.id},
				stage,
				status
			)
	);
