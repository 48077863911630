import {useMutation} from '@tanstack/react-query';
import {queryClient} from '@src/api/QueryClient';
import {makeSpaceDeleteRequest} from '@src/api/backend/space';
import {spacesQueryKeys} from '@src/api/cache/spaces/keys';
import IError from '@tehzor/tools/interfaces/IError';
import {addErrorToast, addSuccessToast} from '@tehzor/ui-components';
import {useTranslation} from 'react-i18next';

export const useDeleteSpace = () => {
	const {t} = useTranslation();

	return useMutation<void, IError, {objectId: string; spaceId: string}>({
		mutationKey: spacesQueryKeys.delete(),
		mutationFn: ({objectId, spaceId}: {objectId: string; spaceId: string}) =>
			makeSpaceDeleteRequest(objectId, spaceId),
		onSuccess: () => {
			void queryClient.invalidateQueries({queryKey: spacesQueryKeys.list()});
			addSuccessToast({title: t('toast.deleteSpace.success.title')});
		},
		onError: () => addErrorToast({title: t('toast.deleteSpace.error.title')})
	});
};
