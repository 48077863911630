import {memo, useCallback} from 'react';
import {CellProps} from 'react-table';
import {IPreparedFieldSettingsSet} from '@/shared/types/IPreparedFieldsSettingsSets';
import {useFieldsPermissions} from '@/shared/hooks/permissions/useFieldsPermissions';
import {useDeleteFieldsSettingsSet} from '@/entities/FieldsSettingsSets';
import {IconButton} from '@tehzor/ui-components';
import styles from '../../FieldsSettingsSetsTable.module.less';
import {useTranslatedConfirmDialog} from '@src/core/hooks/translations/useTranslatedConfirmDialog';
import {useTranslation} from 'react-i18next';

const deleteIcon = <i className="tz-delete" />;

export const DeleteCell = memo(({row}: CellProps<IPreparedFieldSettingsSet>) => {
	const {id} = row.original;
	const {t} = useTranslation();
	const {mutateAsync: deleteFieldsSettingsSet} = useDeleteFieldsSettingsSet();
	const {canDeleteSettingsSet} = useFieldsPermissions();
	const [deleteDialog, getDeleteConfirmation] = useTranslatedConfirmDialog({
		title: t('useConfirmDialog.fieldSettingsSet.deleteTitle'),
		message: t('useConfirmDialog.fieldSettingsSet.deleteMessage')
	});

	const handleDelete = useCallback(async () => {
		if (canDeleteSettingsSet && (await getDeleteConfirmation())) {
			await deleteFieldsSettingsSet(id);
		}
	}, [canDeleteSettingsSet, deleteFieldsSettingsSet, getDeleteConfirmation, id]);

	if (!canDeleteSettingsSet) return null;

	return (
		<div className={styles.deleteCell}>
			<IconButton
				type="inline-red-accent"
				onClick={handleDelete}
			>
				{deleteIcon}
			</IconButton>
			{deleteDialog}
		</div>
	);
});
