import {useDateFnsLocale} from '@src/core/hooks/translations/useDateFnsLocales';
import {Comment} from '@tehzor/ui-components/src/components/entities/Comment';
import {ICommentProps} from '@tehzor/ui-components/src/components/entities/Comment/Comment';
import {useTranslation} from 'react-i18next';

type ITranslatedProblemCommentProps = ICommentProps;

export const TranslatedComment = (props: ITranslatedProblemCommentProps) => {
	const {t} = useTranslation();
	const locale = useDateFnsLocale();
	const translations = {
		addCommentBtnLabel: t('components.comment.addComment.button.label'),
		replyTitle: t('components.comment.reply.title'),
		createdLabel: t('components.comment.created'),
		modifiedLabel: t('components.comment.modified')
	};

	return (
		<Comment
			translationsProps={translations}
			dateOptions={{locale}}
			{...props}
		/>
	);
};
