import {useStrictParams} from '@src/core/hooks/useStrictParams';
import {useCheckItemIndex} from '@src/pages/CheckListPage/hooks/useCheckItemIndex';
import {useCallback, useMemo} from 'react';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {formCheckItemLink} from '@tehzor/tools/utils/links';
import {EntityInfoLink} from '@tehzor/ui-components';
import {ITask} from '@tehzor/tools/interfaces/tasks/ITask';
import {CellProps} from 'react-table';
import {TaskInfoMobile} from '@src/pages/CheckListPage/components/detail/TaskInfoMobileCell/TaskInfoMobileCell';
import {useExtractCheckItemById} from '@src/core/hooks/queries/checkItems/hooks';
import {useEnrichedTask} from '@src/core/hooks/queries/task/enrichedTask/hooks';

export const CheckListTaskCellWrap = (props: CellProps<{data: ITask}>) => {
	const {pushPath} = useChangePath();
	const {itemId} = useStrictParams<{itemId: string}>();

	const {row} = props;
	const {data} = row.original;
	const {objectId, links} = data;
	const {data: enrichedTask} = useEnrichedTask(data.id);

	const {data: item} = useExtractCheckItemById(links?.checkItemId);
	const currentIndex = useCheckItemIndex(item);

	const checkItemLink = useMemo(() => {
		if (!links?.checkItemId || !links.checkListId || !enrichedTask?.object?.stage) {
			return undefined;
		}

		return formCheckItemLink({
			itemId: links?.checkItemId,
			objectId,
			listId: links?.checkListId,
			spaceId: links?.spaceId,
			stage: enrichedTask.object.stage
		});
	}, [
		links?.checkItemId,
		links?.checkListId,
		links?.spaceId,
		enrichedTask?.object?.stage,
		objectId
	]);

	const navigate = useCallback(() => {
		if (checkItemLink) {
			pushPath(checkItemLink);
		}
	}, [pushPath, checkItemLink]);

	return (
		<TaskInfoMobile
			task={data}
			depth={row.depth}
			subTitle={
				links?.checkItemId !== itemId ? (
					<EntityInfoLink
						label={`${currentIndex} ${item?.name}`}
						onClick={navigate}
						disabled={!checkItemLink}
					/>
				) : undefined
			}
			getToggleRowSelectedProps={row.getToggleRowSelectedProps}
		/>
	);
};
