import {useMemo} from 'react';
import {Button} from '@tehzor/ui-components';
import {ICheckAddingEntityType} from '@src/store/modules/settings/checkAdding/reducers/entityType';
import {useGlobalAddingEntityDialog} from '@src/components/GlobalAddingEntityDialog/hooks/useGlobalAddingEntityDialog';
import {useChecksPermissions} from '@src/core/hooks/permissions/useChecksPermissions';
import {useInternalAcceptancesPermissions} from '@src/core/hooks/permissions/useInternalAcceptancesPermissions';
import {useObject} from '@src/core/hooks/queries/objects/hooks';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {useTranslation} from 'react-i18next';
import {useStagesAndProcessesMap} from '@src/core/hooks/processes/useProcessesMap';
import {usePresaleChecksPermissions} from '@/entities/PresaleCheck/model/hooks/usePresaleChecksPermissions';

const types: ICheckAddingEntityType[] = ['inspection'];

interface IInspectionAddingDesktopProps {
	objectId: string;
}

const plusIcon = <i className="tz-plus-20" />;

export const InspectionAddingDesktop = ({objectId}: IInspectionAddingDesktopProps) => {
	const {t} = useTranslation();
	const stagesAndProcessesMap = useStagesAndProcessesMap();
	const {data: object} = useObject(objectId);

	const checksPermissions = useChecksPermissions(objectId);
	const internalAcceptancePermissions = useInternalAcceptancesPermissions(objectId);
	const presaleChecksPermissions = usePresaleChecksPermissions(objectId);

	const permissions = useMemo(
		() => ({
			checks: checksPermissions.canAdd,
			internalAcceptances: internalAcceptancePermissions.canAdd,
			presaleChecks: presaleChecksPermissions.canAdd
		}),
		[checksPermissions, internalAcceptancePermissions, presaleChecksPermissions]
	);
	const defaultStageId = object?.stage || ObjectStageIds.BUILDING;
	const defaultProcessId = stagesAndProcessesMap[defaultStageId][0];

	const [globalDialog, openGlobalDialog] = useGlobalAddingEntityDialog(
		objectId,
		undefined,
		undefined,
		defaultStageId,
		defaultProcessId,
		permissions,
		undefined,
		undefined,
		types
	);

	return (
		<>
			<Button
				type="accent-blue"
				leftIcon={plusIcon}
				label={t('addButton.label')}
				onClick={openGlobalDialog}
			/>
			{globalDialog}
		</>
	);
};
