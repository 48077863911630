import {IGetListInspectionsResponse} from '@src/api/backend/inspections';
import {IStructureEntity} from '@src/interfaces/IStructureEntity';
import {useQuery} from '@tanstack/react-query';
import {structuresQueryKeys} from '../../../../../Structures/model/cache/keys/index';

export const useStructureInspections = (
	objectId: string,
	structureId: string,
	select: (data: IGetListInspectionsResponse) => IStructureEntity[]
) =>
	useQuery({
		queryKey: structuresQueryKeys.inspectionsById(objectId, structureId),
		meta: {
			error: 'при загрузке осмотров'
		},
		select,
		enabled: !!structureId && !!objectId
	});
