import {memo, useCallback, useMemo} from 'react';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {ICheckItem} from '@tehzor/tools/interfaces/checkItems/ICheckItem';
import './CheckListItems.less';
import {
	formCheckItemLink,
	formCheckListLink,
	formWorkAcceptanceCheckItemLink
} from '@tehzor/tools/utils/links';
import {useIsDesktop} from '@tehzor/ui-components/src/utils/mediaQueries';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import {Plate} from '@tehzor/ui-components';
import useScrollRestoration from '@src/core/hooks/useScrollRestoration';
import {
	useExtractCheckItemsAsTree,
	useExtractTreeCheckItemById
} from '@src/core/hooks/queries/checkItems/hooks';
import {useExtractCheckListById} from '@src/core/hooks/queries/checkLists/hooks';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractCheckRecordsAsArray} from '@src/store/modules/pages/checkLists/selectors/records';
import {useEntityAddingPermission} from '../../../hooks/useEntityAddingPermission';
import {getAcceptedItems} from '../../../utils/getAcceptedCheckListItems';
import {CheckListDesktop} from '@src/pages/CheckListPage/components/CheckListDesktop/CheckListDesktop';
import {CheckListItem} from './components/CheckListItem';
import {EmptyPlate} from '../../detail/EmptyPlate/EmptyPlate';
import {findIdInTreeItems} from '../../../utils/findIdInTreeItems';
import {useScrollToId} from '@src/core/hooks/useScrollToId';
import {useEffectOnce} from 'react-use';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {useTranslation} from 'react-i18next';
import {isDisabledCheckItem} from '@src/pages/CheckListPage/components/list/CheckListItems/utils/isDisabledCheckItem';

interface ICheckListItemsProps {
	withoutHeader?: boolean;
	mode: 'detail' | 'main';
}

const checkListIcon = <i className="tz-check-list-categories-20" />;

export const CheckListItems = memo((props: ICheckListItemsProps) => {
	const {withoutHeader, mode} = props;
	const {t} = useTranslation();
	useScrollRestoration();
	const {pushPath, replacePath} = useChangePath();
	const params = useStrictParams<{
		objectId: string;
		stage: ObjectStageIds;
		listId: string;
		spaceId: string;
		itemId: string;
		workAcceptanceId?: string;
	}>();
	const isDesktop = useIsDesktop();
	const records = useAppSelector(extractCheckRecordsAsArray);
	const {data: checkList} = useExtractCheckListById(params.listId);
	const {data: items} = useExtractCheckItemsAsTree(params.listId);
	const {data: siblings} = useExtractTreeCheckItemById(params.listId, params.itemId);
	const canAddEntity = useEntityAddingPermission(params.objectId, params.listId);

	const {triggerScrollToId} = useScrollToId(params.itemId);

	useEffectOnce(() => {
		if (params.itemId) {
			triggerScrollToId(true, {behavior: 'smooth', delay: 1500});
		}
	});

	const handleClick = useCallback(
		(item: ICheckItem) => {
			const navigate = isDesktop ? replacePath : pushPath;
			const isNotEqualId = params.listId !== item.checkListId || params.itemId !== item.id;

			if (!isNotEqualId) return;

			let link;
			if (params.workAcceptanceId) {
				link = formWorkAcceptanceCheckItemLink({
					...params,
					listId: item.checkListId,
					itemId: item.id,
					workAcceptanceId: params.workAcceptanceId
				});
			} else {
				link = formCheckItemLink({
					...params,
					listId: item.checkListId,
					itemId: item.id
				});
			}

			if (link) {
				navigate(link);
			}
		},
		[isDesktop, params, pushPath, replacePath]
	);

	const acceptedItemsIds = useMemo(
		() => getAcceptedItems(records, params.listId),
		[params.listId, records]
	);

	const handleOpen = useCallback(() => {
		if (checkList) {
			const checkListLink = formCheckListLink({...params, listId: checkList?.id});
			if (params.itemId && checkListLink) {
				replacePath(checkListLink);
			}
		}
	}, [checkList, params, replacePath]);

	if (!checkList) return null;
	return (
		<Plate withoutPadding>
			{!withoutHeader && (
				<CheckListDesktop
					onClick={handleOpen}
					checkList={checkList}
				/>
			)}
			<div className="check-list-items">
				{mode === 'main' && (
					<>
						{items?.map(item => (
							<CheckListItem
								key={item.id}
								mode="main"
								stage={checkList.stage}
								item={item}
								onClick={handleClick}
								containScrollTarget={findIdInTreeItems(item, params.itemId)}
								canAddEntity={canAddEntity}
								showDivider
								acceptedItemsIds={acceptedItemsIds}
								disabled={isDisabledCheckItem(item, acceptedItemsIds)}
							/>
						))}
						{!items?.length && (
							<EmptyPlate
								mode="main"
								text={t('checkListPage.checkListItems.emptyPlate.categories.text')}
								icon={checkListIcon}
							/>
						)}
					</>
				)}
				{mode === 'detail' && (
					<>
						{siblings?.children?.map(item => {
							const itemContainScrollTarget = findIdInTreeItems(item, params.itemId);
							return (
								<CheckListItem
									mode="main"
									item={item}
									stage={checkList.stage}
									containScrollTarget={itemContainScrollTarget}
									onClick={handleClick}
									canAddEntity={canAddEntity}
									showDivider
									acceptedItemsIds={acceptedItemsIds}
								/>
							);
						})}
						{!siblings?.children?.length && (
							<EmptyPlate
								mode="main"
								text={t(
									'checkListPage.checkListItems.emptyPlate.subcategories.text'
								)}
								icon={checkListIcon}
							/>
						)}
					</>
				)}
			</div>
		</Plate>
	);
});

CheckListItems.displayName = 'CheckListItems';
