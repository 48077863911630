import {IPreparedStructuresData} from '../../../model/interfaces';
import {ObjectBoard} from '../ObjectBoard';

interface IMobileObjectBoardProps {
	objectId: string;
	isSingleSchema?: boolean;
	preparedStructuresData: IPreparedStructuresData;
}

export const MobileObjectBoard = (props: IMobileObjectBoardProps) => <ObjectBoard {...props} />;
