import {memo} from 'react';
import {IconButton} from '@tehzor/ui-components';

interface IClearSelectionRowsButtonProps {
	onClear: () => void;
}

const closeIcon = <i className="tz-close-24" />;
export const ClearSelectionRowsButton = memo(({onClear}: IClearSelectionRowsButtonProps) => (
	<IconButton onClick={onClear}>{closeIcon}</IconButton>
));
