import IComment from '@tehzor/tools/interfaces/comments/IComment';
import {IGetPresaleCheckProblemRepliesPayload} from '../hooks/usePresaleCheckProblemsReplies';
import {extractPresaleCheckProblemRepliesAsArray} from './extractPresaleCheckProblemRepliesAsArray';

export const extractPresaleCheckProblemCommentsByProblem = (
	data: IGetPresaleCheckProblemRepliesPayload
) => {
	const array = extractPresaleCheckProblemRepliesAsArray(data);

	if (!array) return undefined;

	return array.reduce<Record<string, IComment>>((prev, item) => {
		if (item.links?.problemId && !prev[item.links.problemId]) {
			prev[item.links.problemId] = item;
		}
		return prev;
	}, {});
};
