import {EntityInfo, EntityInfoDateWithCountFiles} from '@tehzor/ui-components';
import {IPresaleCheck} from '@tehzor/tools/interfaces/presaleChecks/IPresaleCheck';
import {useTranslation} from 'react-i18next';
import {useDateFnsLocale} from '@src/core/hooks/translations/useDateFnsLocales';

interface IPresaleCheckInfoProps {
	data: IPresaleCheck;
}

const presaleCheckIcon = <i className="tz-presale-check-28" />;

export const PresaleCheckInfo = ({data}: IPresaleCheckInfoProps) => {
	const {t} = useTranslation();
	const dateFnsLocale = useDateFnsLocale();

	return (
		<EntityInfo
			title={`${t('spacePage.desktopTable.presaleCheckInfo.title')} №${
				data.number ?? data.localNumber
			}`}
			subTitle={
				<EntityInfoDateWithCountFiles
					labelFrom={t(
						'components.tableCell.info.entityInfoDateWithCountFiles.labelFrom'
					)}
					locale={dateFnsLocale}
					data={data}
				/>
			}
			icon={presaleCheckIcon}
			iconColor="#FF8B04"
			iconSize={44}
			backgroundColor="#FFEEDB"
		/>
	);
};
