import {useCallback} from 'react';
import {ExportDialog} from '@src/components/ExportDialog';
import IExportTemplate, {
	ExportTemplatesDestinationId
} from '@tehzor/tools/interfaces/IExportTemplate';
import {IconButton} from '@tehzor/ui-components';
import useToggle from 'react-use/lib/useToggle';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractProblemsPageSettings} from '@src/store/modules/settings/pages/problems/selectors';
import {useIsDesktop} from '@tehzor/ui-components/src/utils/mediaQueries';
import {useExportProblems} from '@src/core/hooks/mutations/problems/useExportProblems';

const availableDestinations = [ExportTemplatesDestinationId.PROBLEMS];

interface IProblemsExportDialog {
	objectId?: string;
}

const ProblemsExportDialog = ({objectId = 'all'}: IProblemsExportDialog) => {
	const {filters, sort, selectedRows} = useAppSelector(s =>
		extractProblemsPageSettings(s, objectId)
	);
	const [isOpen, toggleOpen] = useToggle(false);
	const {mutateAsync: exportProblems} = useExportProblems();

	const handleExport = useCallback(
		async (template: IExportTemplate, email?: string, createDocument?: boolean) => {
			await exportProblems({
				templateId: template.id,
				objectId,
				createDocument,
				email,
				filters,
				sort,
				selected: selectedRows
			});
		},
		[exportProblems, objectId, filters, sort, selectedRows, toggleOpen]
	);

	const isDesktop = useIsDesktop();
	return (
		<>
			{!isDesktop ? (
				<IconButton onClick={toggleOpen}>
					<i className="tz-export-24" />
				</IconButton>
			) : (
				<IconButton
					type="accent-blue"
					onClick={toggleOpen}
				>
					<i className="tz-export-20" />
				</IconButton>
			)}

			<ExportDialog
				pageKey="problems"
				destinations={availableDestinations}
				isOpen={isOpen}
				onExport={handleExport}
				onClose={toggleOpen}
			/>
		</>
	);
};

export default ProblemsExportDialog;
