import {makeCheckListExportRequest} from '@src/api/backend/checkList/export';
import {useMutation} from '@tanstack/react-query';
import IError from '@tehzor/tools/interfaces/IError';
import {IExportResponse} from '@tehzor/tools/interfaces/IExportResponse';
import {addExportToast} from '@/shared/lib/Toasts/ExportToast/addExportToast';
import {addErrorToast} from '@tehzor/ui-components';
import {checkListsQueryKeys} from '@src/api/cache/checkLists/keys';
import {useTranslation} from 'react-i18next';

interface IExportCheckParams {
	templateId: string;
	objectId: string;
	spaceId: string;
	checkListId: string;
	problems?: string[];
	createDocument?: boolean;
	email?: string;
}

export const useExportCheckList = () => {
	const {t} = useTranslation();

	return useMutation<IExportResponse, IError, IExportCheckParams>({
		mutationKey: checkListsQueryKeys.exportOne(),
		mutationFn: (params: IExportCheckParams) => {
			const {templateId, objectId, spaceId, checkListId, problems, createDocument, email} =
				params;
			return makeCheckListExportRequest(
				templateId,
				objectId,
				spaceId,
				checkListId,
				problems,
				createDocument,
				email
			);
		},
		onSuccess: (response: IExportResponse, params: IExportCheckParams) => {
			addExportToast({
				title: params.email
					? t('toast.export.send.success.title')
					: t('toast.export.success.title'),
				url: response.link,
				fileName: response.file.name,
				document: response.document
			});
		},
		onError: () => {
			addErrorToast({title: t('toast.export.error.title')});
		}
	});
};
