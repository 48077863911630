import {
	IChecksFiltersState,
	IChecksSortState
} from '@src/store/modules/settings/pages/checks/interfaces';

export type IChecksListQueryKey = [] & {
	0: string;
	1: string;
	2: IChecksFiltersState;
	3: IChecksSortState;
	4: number;
	5: number;
};

export type ICheckInspectionsQueryKey = [] & {
	0: string;
	1: string;
	2: string;
	3: string;
};

export type ICheckProblemsQueryKey = [] & {
	0: string; // check
	1: string; // problems
	2: string; // objectId
	3: string; // checkId
};

export type ICheckDetailQueryKey = [] & {
	0: string;
	1: string;
	2: string; // checkId
	3: string; // objectId
};

export const checksQueryKeys = {
	all: () => ['checks'],
	one: () => ['check'],
	latest: () => ['latest-checks'],
	list: () => [...checksQueryKeys.all(), 'list'],
	localList: () => [...checksQueryKeys.all(), 'local-list'],
	add: () => [...checksQueryKeys.one(), 'add'],
	details: () => [...checksQueryKeys.one(), 'detail'],
	detail: (checkId: string) => [...checksQueryKeys.details(), checkId],
	delete: () => [...checksQueryKeys.one(), 'delete'] as const,
	edit: () => [...checksQueryKeys.one(), 'edit'],
	move: () => [...checksQueryKeys.one(), 'move'],
	inspections: () => [...checksQueryKeys.one(), 'inspections'],
	checkInspections: (objectId: string, checkId: string) => [
		...checksQueryKeys.inspections(),
		objectId,
		checkId
	],
	problems: () => [...checksQueryKeys.one(), 'problems'],
	checkProblems: (objectId: string, checkId: string) => [
		...checksQueryKeys.problems(),
		objectId,
		checkId
	],
	export: () => [...checksQueryKeys.all(), 'export'],
	exportOne: () => [...checksQueryKeys.one(), 'export']
};
