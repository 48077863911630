import {CellProps} from 'react-table';
import {TableRowSelect} from '@tehzor/ui-components';
import ClickPreventWrap from '@src/components/tableCells/ClickPreventWrap';
import {StatusCell} from './StatusCell';
import {Indicators} from '../Indicators';
import {useTranslation} from 'react-i18next';
import {useTranslatedDictionaryById} from '@src/core/hooks/translations/useTranslatedDictionaryById';
import {dictionaryKeys} from '@src/constants/translations/dictionaryKeys';
import {useParams} from 'react-router';
import {IPreparedWarrantyClaimEntity} from '../../interfaces/IPreparedWarrantyClaimEntity';

export const MobileWarrantyClaimCell = (props: CellProps<IPreparedWarrantyClaimEntity>) => {
	const {objectId} = useParams<{objectId: string}>();
	const {row} = props;
	const warrantyClaim = row.original.data;
	const {spaceOwner, replyEmail, replyPhone, claimerName} = warrantyClaim;
	const {t} = useTranslation();
	const spaceType = useTranslatedDictionaryById(
		dictionaryKeys.spaceTypesSingular,
		warrantyClaim.space?.type
	);

	return (
		<div className="warranty-claims-table__mobile-cell-content">
			<ClickPreventWrap>
				<TableRowSelect {...row.getToggleRowSelectedProps()} />
			</ClickPreventWrap>
			<div className="warranty-claims-table__mobile-cell-content-list">
				<div className="warranty-claims-table__mobile-cell-content-list-number">
					№{warrantyClaim.number ?? warrantyClaim.localNumber}
				</div>
				{warrantyClaim.registrationDate && (
					<div className="warranty-claims-table__mobile-cell-content-list-registration-date">
						{t('warrantyClaimPage.info.entityGridItem.registrationDate.label')}
						{': '}
						<span className="warranty-claims-table__mobile-cell-content-list-registration-date-text">
							{warrantyClaim.registrationDate}
						</span>
					</div>
				)}
				<div className="warranty-claims-table__mobile-cell-content-list-space-owner">
					<span className="warranty-claims-table__mobile-cell-content-list-space-owner-title">
						{t('warrantyClaimPage.info.entityGridItem.spaceOwners.label')}
						{': '}
					</span>
					{spaceOwner && (claimerName === undefined || claimerName === '') ? (
						<span>
							{spaceOwner.name}
							{replyEmail ? ', ' : ''}
						</span>
					) : null}
					{claimerName && (
						<span>
							{claimerName}
							{replyEmail ? ', ' : ''}
						</span>
					)}
					{replyEmail && (
						<span>
							{replyEmail}
							{replyPhone ? ', ' : ''}
						</span>
					)}
					{replyPhone && <span>{replyPhone}</span>}
				</div>
				<div className="warranty-claims-table__mobile-cell-content-list-status">
					<StatusCell {...props} />
				</div>
				<div className="warranty-claims-table__mobile-cell-content-list-space">
					{warrantyClaim.space?.name && warrantyClaim.space.type && (
						<span>{`${warrantyClaim.space?.name} ${spaceType}`}</span>
					)}
					{warrantyClaim.space?.floor && (
						<span>
							{`, ${warrantyClaim.space?.floor} ${t(
								'warrantyClaimsPage.table.columns.spaceCell.floor'
							)}`}
						</span>
					)}
				</div>
				{!objectId && (
					<div className="warranty-claims-table__mobile-cell-content-list-object">
						{warrantyClaim?.object?.name}
					</div>
				)}
				<Indicators claim={warrantyClaim} />
			</div>
		</div>
	);
};
