import {Toggle} from '@tehzor/ui-components';
import {
	useAddFieldSettings,
	useDeleteFieldSettings,
	useFieldSettingsBySetIds
} from '@/entities/FieldSettings';
import {IPreparedField, useFieldsIsUpdating} from '@/features/Fields';
import styles from './Toggles.module.less';

interface IFieldEnabledToggleProps {
	setId: string;
	field: IPreparedField;
}

export const FieldEnabledToggle = ({setId, field}: IFieldEnabledToggleProps) => {
	const {data: settingsList} = useFieldSettingsBySetIds([setId]);
	const {mutateAsync: addFieldSettings} = useAddFieldSettings();
	const {mutateAsync: deleteFieldSettings} = useDeleteFieldSettings();
	const {isUpdating} = useFieldsIsUpdating();

	const handleEnabledChange = async () => {
		if (!field || isUpdating) return;
		if (field.settingsId) {
			await deleteFieldSettings(field.settingsId);
			return;
		}
		if (setId && settingsList) {
			await addFieldSettings({
				setId,
				fieldId: field.id,
				order: settingsList.length,
				canBeCopied: field.canBeCopied || false
			});
		}
	};

	return (
		<div className={styles.wrapper}>
			<p className={styles.title}>Включить</p>
			<Toggle
				checked={field.enabled}
				onChange={handleEnabledChange}
			/>
		</div>
	);
};
