import {memo, useTransition} from 'react';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractSpacesPageSettings} from '@src/store/modules/settings/pages/spaces/selectors';
import {
	changeProcess,
	changeStage,
	clearFilters,
	togglePending
} from '@src/store/modules/settings/pages/spaces/actions';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {EntityInfo, FilterMenu, Menu, MenuItem} from '@tehzor/ui-components';
import classNames from 'classnames';
import {useObject} from '@src/core/hooks/queries/objects/hooks';
import {useUpdateEffect} from 'react-use';
import {isEmpty} from 'lodash';
import {SpacesSchemaVariants} from '@src/interfaces/SpacesSchemaVariants';
import {useTranslation} from 'react-i18next';
import {useTranslatedObjectStagesArray} from '@src/core/hooks/translations/useTranslatedObjectStagesArray';
import {ProcessIds} from '@tehzor/tools/interfaces/process/ProcessId';
import {FilterMenuLabel} from '@src/pages/SpacesPage/components/filters/FilterMenuLabel/FilterMenuLabel';
import {
	SomeObjectStageIds,
	SomeProcessIds
} from '@src/store/modules/settings/pages/spaces/interfaces';
import styles from './ProcessVisibilityFilter.module.less';

interface ProcessVisibilityFilterProps {
	objectId: string;
	className?: string;
}

export const ProcessVisibilityFilter = memo(
	({className, objectId}: ProcessVisibilityFilterProps) => {
		const {t} = useTranslation();
		const dispatch = useAppDispatch();
		const {data: object} = useObject(objectId);
		const {
			schemaView,
			processId: pageProcessId,
			filters
		} = useAppSelector(s => extractSpacesPageSettings(s, objectId));
		const translatedObjectStagesArray = useTranslatedObjectStagesArray();
		const [isPending, startTransition] = useTransition();

		const processId =
			schemaView === SpacesSchemaVariants.WORK_ACCEPTANCES
				? ProcessIds.ACCEPTANCE_CONTROL
				: pageProcessId;

		const handleChange = (v: string) => {
			// Костыль для определения статуса и процесса одновременно,
			// для сохранения обратной совместимости компонентов Menu и MenuItem
			const [stage, process] = v.split(':') as [SomeObjectStageIds, SomeProcessIds];

			startTransition(() => {
				dispatch(changeProcess(objectId, process));
				dispatch(changeStage(objectId, stage));
			});
			if (!isEmpty(filters)) {
				dispatch(clearFilters(objectId));
			}
		};

		useUpdateEffect(() => {
			dispatch(togglePending(isPending));
		}, [isPending]);

		const isFilterMenuDisabled = schemaView === SpacesSchemaVariants.WORK_ACCEPTANCES;

		const disableMenuItem = (id: ProcessIds) =>
			schemaView === SpacesSchemaVariants.CHECK_LISTS && id === ProcessIds.ACCEPTANCE_CONTROL;

		return (
			<FilterMenu
				className={{
					root: classNames(styles.root, className),
					label: styles.label
				}}
				label={
					<FilterMenuLabel
						fieldLabel={t('processes.process')}
						selectedOption={t(`processes.${processId || 'all'}`)}
						disabled={isFilterMenuDisabled}
					/>
				}
				active
				disabled={isFilterMenuDisabled}
			>
				<Menu
					value={processId || 'all'}
					onChange={handleChange}
					className={styles.menu}
				>
					<>
						<MenuItem
							itemKey="all:all"
							selected={!processId}
						>
							{t('processes.all')}
						</MenuItem>
						<hr className={styles.divider} />
						{translatedObjectStagesArray.map(
							({name: stageName, id: stageKey, processes}) => (
								<div
									key={stageKey}
									className={styles.group}
								>
									<div className={styles.stageTitle}>
										<p>{stageName}</p>
										<p>
											{object?.stage === stageKey &&
												`(${t(
													'spacesPage.stageVisibilityFilter.filterMenu.menu.menuItem.current.children'
												)})`}
										</p>
									</div>
									{processes.map(({id, name, iconName, color}) => (
										<MenuItem
											key={`${stageKey}:${id}`}
											itemKey={`${stageKey}:${id}`}
											icon={
												<EntityInfo
													iconColor={
														disableMenuItem(id) ? '#AEB5BD' : color
													}
													iconSize={32}
													icon={<i className={`tz-${iconName}-16`} />}
												/>
											}
											selected={id === processId}
											disabled={disableMenuItem(id)}
										>
											{name}
										</MenuItem>
									))}
								</div>
							)
						)}
					</>
				</Menu>
			</FilterMenu>
		);
	}
);
