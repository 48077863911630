import {replacePointName} from './replacePointName';

/**
 * Возвращает отмеченные точки в виде строки
 *
 * @param points точки
 * @param translatedPoint переведенное название точки
 */
export const getPointsDisplayValue = (
	points?: Array<{name: string}>,
	translatedPoint = 'Точка'
): string[] => {
	if (!points || !Array.isArray(points) || !points.length) {
		return [];
	}
	return points.map(item => replacePointName(item.name, translatedPoint));
};
