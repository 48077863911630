/**
 * Проверят не пустой ли фильтр и можно ли сбросить значения
 *
 * @param filtersState фильтр
 * @param objectId id объекта
 * @param nestedObjects наличие вложенных объектов

 */
export function isCleanable<T extends Record<string, unknown>>(
	filtersState: T,
	objectId?: string,
	key: keyof T = 'objects',
	nestedObjects?: boolean
): boolean {
	if (!objectId || nestedObjects) {
		return !(
			Object.values(filtersState).length === 0 ||
			Object.values(filtersState).every(
				el => el === undefined || (Array.isArray(el) && el.length === 0)
			)
		);
	}
	const {[key]: item, ...rest} = filtersState;
	return !(
		Object.values(rest).length === 0 ||
		Object.values(rest).every(el => el === undefined || (Array.isArray(el) && el.length === 0))
	);
}
