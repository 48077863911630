import {queryClient} from '@src/api/QueryClient';
import {useMutation} from '@tanstack/react-query';
import {makeStructureDeleteRequest} from '@/shared/api/structure/deleteStructure';
import {structuresQueryKeys} from '../../../Structures/model/cache/keys/index';
import {addErrorToast, addSuccessToast} from '@tehzor/ui-components';
import {useTranslation} from 'react-i18next';

interface IDeleteStructureRequestData {
	objectId: string;
	structureId: string;
}

export const useDeleteStructure = () => {
	const {t} = useTranslation();

	return useMutation({
		mutationFn: ({objectId, structureId}: IDeleteStructureRequestData) =>
			makeStructureDeleteRequest(objectId, structureId),
		mutationKey: structuresQueryKeys.delete(),
		onSuccess: () => {
			addSuccessToast({title: t('toast.deleteStructure.success.title')});
			void queryClient.invalidateQueries({queryKey: structuresQueryKeys.list()});
		},
		onError: () => addErrorToast({title: t('toast.deleteStructure.error.title')})
	});
};
