import {requestCheckLists} from '@src/api/backend/checkLists';
import {useQueryClient} from '@tanstack/react-query';
import {checkListsQueryKeys} from '../keys';

export const useCheckListsQueryDefaults = () => {
	const queryClient = useQueryClient();
	queryClient.setQueryDefaults(checkListsQueryKeys.list(), {
		networkMode: 'offlineFirst',
		queryFn: requestCheckLists,
		staleTime: 1000 * 60 * 60 * 24 * 7, // 1 неделя
		gcTime: 1000 * 60 * 60 * 24 * 7 // 1 неделя
	});
};
