import {IMutationErrorsMap} from '@src/interfaces/IMutationErrorsMap';
import {IOfflineJournalEntity} from '../../interfaces/IOfflineJournalEntity';
import {offlineModeTransferTitles} from '@src/core/offlineMode/utils/offlineModeTransferTitles';
import {offlineJournalActionType} from '../../enums/actionsTypes';
import {IPresaleCheck} from '@tehzor/tools/interfaces/presaleChecks/IPresaleCheck';

export const extractLocalPresaleChecksJournal = (
	presaleChecks: IPresaleCheck[],
	errorsMap: IMutationErrorsMap
) =>
	presaleChecks.map(
		presaleCheck =>
			({
				entity: {
					entityId: presaleCheck.id,
					entityLocalNumber: presaleCheck.localNumber,
					entityType: offlineJournalActionType.PRESALE_CHECK_ADD
				},
				object: {
					objectId: presaleCheck.object?.id,
					objectName: presaleCheck.object?.name
				},
				transfer: {
					status: presaleCheck.transferStatus,
					statusMessage: presaleCheck.transferStatus
						? offlineModeTransferTitles[presaleCheck.transferStatus]
						: ''
				},
				createdAt: presaleCheck.createdAt,
				error: errorsMap[presaleCheck.id]
			} as IOfflineJournalEntity)
	);
