import {createContext, PropsWithChildren} from 'react';
import {ITableContextMenuAction} from '@tehzor/tools/interfaces/table/ITableContextMenuAction';
import useAsyncFn from 'react-use/lib/useAsyncFn';
import {IPreparedExportTemplate} from '../interfaces/IPreparedExportTemplate';
import {useDeleteExportTemplate} from '@src/core/hooks/mutations/exportTemplates/useDeleteExportTemplate';
import {useTranslatedConfirmDialog} from '@src/core/hooks/translations/useTranslatedConfirmDialog';
import {useTranslation} from 'react-i18next';

export const DispatchActionCtx = createContext<
	(action: ITableContextMenuAction<IPreparedExportTemplate>) => void
>(() => ({}));

export const MenuActionCtxProvider = ({children}: PropsWithChildren) => {
	const {t} = useTranslation();
	const [deleteDialog, getDeleteConfirmation] = useTranslatedConfirmDialog({
		title: t('useConfirmDialog.template.deleteTitle'),
		message: ''
	});
	const {mutateAsync: deleteExportTemplate} = useDeleteExportTemplate();

	const [, handleContextMenuAction] = useAsyncFn(
		async (action: ITableContextMenuAction<IPreparedExportTemplate>) => {
			if (action.type === 'delete' && (await getDeleteConfirmation())) {
				await deleteExportTemplate(action.payload.id);
			}
		}
	);

	return (
		<DispatchActionCtx.Provider value={handleContextMenuAction}>
			{children}
			{deleteDialog}
		</DispatchActionCtx.Provider>
	);
};
