import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {IBuiltInFieldsPageSettingsState} from './interfaces';

export const getInitialStateForPage = (): IBuiltInFieldsPageSettingsState => ({
	pageSize: 20,
	offset: 0
});

const builtInFieldsSlice = createSlice({
	name: 'builtInFields',
	initialState: getInitialStateForPage,
	reducers: {
		changeOffset(state, action: PayloadAction<number>) {
			state.offset = action.payload;
		},
		changePageSize(state, action: PayloadAction<number>) {
			state.pageSize = action.payload;
		}
	}
});

export const {actions: builtInFieldsActions, reducer: builtInFieldsReducer} = builtInFieldsSlice;
