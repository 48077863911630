import {ICheckList} from '@tehzor/tools/interfaces/checkLists/ICheckList';

export const getCheckListsWithStagesAndProcesses = (
	checkLists: ICheckList[]
): Record<string, Record<string, ICheckList[]>> =>
	checkLists.reduce(
		(obj, ch) => {
			// Если объект для данной стадии еще не создан, инициализируем его
			if (!obj[ch.stage]) obj[ch.stage] = {};

			// Если объект для данного processId еще не создан, инициализируем массив
			if (!obj[ch.stage][ch.processId]) obj[ch.stage][ch.processId] = [];

			// Добавляем чек-лист в соответствующий массив по стадии и processId
			obj[ch.stage][ch.processId].push(ch);

			return obj;
		},
		{} as Record<string, Record<string, ICheckList[]>>
	);
