import {useMutation} from '@tanstack/react-query';
import {makeCheckListsExportRequest} from '@src/api/backend/checkLists';
import {addExportToast} from '@/shared/lib/Toasts/ExportToast/addExportToast';
import {addErrorToast, addWarningToast} from '@tehzor/ui-components';
import {checkListsQueryKeys} from '@src/api/cache/checkLists/keys';
import {useTranslation} from 'react-i18next';

interface ISelected {
	spaceId?: string;
	workAcceptanceId?: string;
	objectId: string;
	checkListsIds: string[];
}

interface IExportCheckParams {
	templateId: string;
	createDocument: boolean | undefined;
	email: string | undefined;
	selected: ISelected[];
}

export const useExportCheckListsMutation = () => {
	const {t} = useTranslation();

	return useMutation({
		mutationKey: checkListsQueryKeys.export(),
		mutationFn: (params: IExportCheckParams) => {
			const {selected, templateId, email, createDocument} = params;
			return makeCheckListsExportRequest(templateId, createDocument, email, selected);
		},
		onSuccess: (data, params) => {
			if (data.warnings?.itemsLimit !== undefined) {
				addWarningToast({
					title: `${t('toast.export.warning.title')} (${String(
						data.warnings.itemsLimit
					)})`
				});
			}
			if (data.link) {
				addExportToast({
					title: params.email
						? t('toast.export.send.success.title')
						: t('toast.export.success.title'),
					url: data.link,
					fileName: data.file.name,
					document: data.document
				});
			}
		},
		onError: () => {
			addErrorToast({title: t('toast.export.error.title')});
		}
	});
};
