import {IDeleteSpaceStatusesSetResponse} from '@src/api/backend/spaceStatusesSets/delete';
import {useAppUpdateMenuItem} from '@src/components/AppUpdateData';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {IconMenu, MenuItem} from '@tehzor/ui-components';
import {useIsLargeTablet} from '@tehzor/ui-components/src/utils/mediaQueries';
import classNames from 'classnames';
import {useCallback} from 'react';
import {useTranslatedConfirmDialog} from '@src/core/hooks/translations/useTranslatedConfirmDialog';
import {useTranslation} from 'react-i18next';

interface IMenuProps {
	spaceStatusesSetId: string;
	canDelete?: boolean;
	removeSpaceStatusesSet?: (id: string) => Promise<IDeleteSpaceStatusesSetResponse | undefined>;
}

const deleteIcon = <i className={classNames('tz-delete', 'editable-space-statuses-set__icon')} />;

export const Menu = ({spaceStatusesSetId, canDelete, removeSpaceStatusesSet}: IMenuProps) => {
	const {t} = useTranslation();
	const {goBack} = useChangePath();
	const [deleteDialog, getDeleteConfirmation] = useTranslatedConfirmDialog({
		title: t('useConfirmDialog.categorySet.deleteTitle'),
		message: t('useConfirmDialog.categorySet.deleteResetMessage')
	});

	const handleDelete = useCallback(async () => {
		if (removeSpaceStatusesSet && (await getDeleteConfirmation())) {
			await removeSpaceStatusesSet(spaceStatusesSetId);
			goBack();
		}
	}, [removeSpaceStatusesSet, spaceStatusesSetId]);

	const updateData = useAppUpdateMenuItem();
	const isLargeTablet = useIsLargeTablet();

	const menuItems = [];

	if (!isLargeTablet) {
		menuItems.push(updateData);
	}
	if (canDelete) {
		menuItems.push(
			<MenuItem
				icon={deleteIcon}
				onClick={handleDelete}
				key="delete"
			>
				Удалить
			</MenuItem>
		);
	}

	return (
		<>
			<IconMenu>{menuItems}</IconMenu>
			{deleteDialog}
		</>
	);
};
