import {EditorInfo, Plate} from '@tehzor/ui-components';
import {IFieldSettingsSet} from '@tehzor/tools/interfaces/fields';
import styles from './EditingFieldsSettingsSet.module.less';
import {FormEditFieldsSettingsSet} from '@/features/FieldsSettingsSets';

interface IEditingFieldsSettingsSetProps {
	fieldsSettingsSet?: IFieldSettingsSet;
}

export const EditingFieldsSettingsSet = ({fieldsSettingsSet}: IEditingFieldsSettingsSetProps) => (
	<Plate
		className={{
			content: styles.wrapper
		}}
	>
		<FormEditFieldsSettingsSet fieldsSettingsSet={fieldsSettingsSet} />
		{fieldsSettingsSet && (
			<div className={styles.info}>
				<EditorInfo
					className={{
						root: styles.infoItem,
						content: styles.infoItemContent
					}}
					user={fieldsSettingsSet?.createdBy}
					date={fieldsSettingsSet?.createdAt}
					label="Создано"
					fullRow
				/>
				{fieldsSettingsSet?.modifiedBy && (
					<EditorInfo
						className={{
							root: styles.infoItem,
							content: styles.infoItemContent
						}}
						user={fieldsSettingsSet?.modifiedBy}
						date={fieldsSettingsSet?.modifiedAt}
						label="Изменено"
						fullRow
					/>
				)}
			</div>
		)}
	</Plate>
);
