import {memo, useCallback, useMemo} from 'react';
import {
	DesktopHeader,
	DesktopHeaderMenu,
	MenuItem,
	MenuSeparator,
	NotificationsButton,
	UserMenu
} from '@tehzor/ui-components';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {IHeaderSectionsMenuItem} from '@src/interfaces/IHeaderSectionsMenuItem';
import {useLocation} from 'react-router-dom';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {toggleNotificationsVisible} from '@src/actions/settings/notifications';
import {OfflineModeButton} from '@src/components/OfflineModeButton';
import {useAppUpdateMenuItem} from '@src/components/AppUpdateData';
import {extractOfflineModeAvailability} from '@src/store/modules/settings/offlineMode/selectors/availability';
import {useTranslation} from 'react-i18next';
import {useTranslatedConfirmDialog} from '@src/core/hooks/translations/useTranslatedConfirmDialog';
import {Processing} from '@src/components/Processing/Processing';

interface IDesktopAppHeaderProps {
	title?: string;
	backBtnVisible?: boolean;
	sectionsMenu?: IHeaderSectionsMenuItem[];
}

const exitIcon = <i className="tz-exit" />;
const userIcon = <i className="tz-user-24" />;

const DesktopAppHeader = ({title, backBtnVisible, sectionsMenu}: IDesktopAppHeaderProps) => {
	const {i18n, t} = useTranslation();

	const location = useLocation();
	const {goBack, replacePath, pushPath} = useChangePath();
	const user = useAppSelector(s => s.auth.profile);
	const isOfflineModeAvailable = useAppSelector(extractOfflineModeAvailability);
	const notificationsCount = useAppSelector(s => s.notifications.messages.total);
	const dispatch = useAppDispatch();

	const goToMainPage = useCallback(() => {
		if (location.pathname !== '/') {
			pushPath('/');
		}
	}, []);

	const goToProfile = useCallback(() => {
		if (location.pathname !== '/profile') {
			pushPath('/profile');
		}
	}, []);

	const [exitDialog, getExitConfirmation] = useTranslatedConfirmDialog({
		message: t('header.exitConfirmation'),
		title: ''
	});

	const toggleNotifications = useCallback(() => {
		dispatch(toggleNotificationsVisible());
	}, []);

	const logout = useCallback(async () => {
		if (await getExitConfirmation()) {
			replacePath('/logout');
		}
	}, []);

	const updateData = useAppUpdateMenuItem();

	const userMenu = useMemo(
		() => (
			<UserMenu
				user={user}
				avatarSize="34"
			>
				<MenuItem
					dataTestId="MenuItem"
					icon={userIcon}
					onClick={goToProfile}
				>
					{t('header.profile')}
				</MenuItem>
				{updateData}
				<MenuSeparator />
				<MenuItem
					dataTestId="MenuItem"
					icon={exitIcon}
					onClick={logout}
				>
					{t('header.exit')}
				</MenuItem>
			</UserMenu>
		),
		[goToProfile, logout, updateData, user, i18n.language]
	);

	return (
		<>
			<DesktopHeader
				className="app-header"
				title={title}
				sectionsMenu={
					!!sectionsMenu && (
						<DesktopHeaderMenu
							items={sectionsMenu}
							value={location.pathname}
							onChange={replacePath}
						/>
					)
				}
				indicators={
					<>
						<Processing />
						{isOfflineModeAvailable && <OfflineModeButton />}
						{user.activated && (
							<NotificationsButton
								count={notificationsCount}
								onClick={toggleNotifications}
							/>
						)}
					</>
				}
				userMenu={userMenu}
				onLogoClick={goToMainPage}
				onBackBtnClick={backBtnVisible ? goBack : undefined}
			/>

			{exitDialog}
		</>
	);
};

export default memo(DesktopAppHeader);
