import {useMemo} from 'react';
import {IPaginate, IPaginateSettings} from '@/shared/types/IPaginate';

export const usePaginate = <T>(settings: IPaginateSettings, arr?: T[]): IPaginate<T> =>
	useMemo(() => {
		const {offset, pageSize} = settings;
		const currentPage = Math.floor(offset / pageSize);
		const paginatedArr = !arr
			? []
			: arr.slice(currentPage * pageSize, currentPage * pageSize + pageSize);
		const pagesCount = Math.ceil((arr ? arr.length : 0) / pageSize);
		const total = arr ? arr.length : 0;

		return {
			data: paginatedArr,
			pagesCount,
			currentPage,
			total
		};
	}, [arr, settings]);
