import {requestObjects, requestObjectStats} from '@src/api/backend/objects';
import {QueryFunctionContext, useQueryClient} from '@tanstack/react-query';
import {IObjectsQueryKey, objectsQueryKeys} from '../keys';
import {getTargetObjectIdsHaveStructures} from '@src/api/backend/objects/getObjectIdsHaveStructures';

export const useObjectsQueryDefaults = () => {
	const queryClient = useQueryClient();

	queryClient.setQueryDefaults(objectsQueryKeys.list(), {
		networkMode: 'offlineFirst',
		queryFn: requestObjects,
		staleTime: 1000 * 60 * 60 * 24 * 7, // 1 неделя
		gcTime: 1000 * 60 * 60 * 24 * 7 // 1 неделя
	});

	queryClient.setQueryDefaults(objectsQueryKeys.stats(), {
		queryFn: ({queryKey}: QueryFunctionContext<IObjectsQueryKey>) =>
			requestObjectStats(queryKey[2]),
		staleTime: 1000 * 60 * 60 * 24 * 7, // 1 неделя
		gcTime: 1000 * 60 * 60 * 24 * 7 // 1 неделя
	});

	queryClient.setQueryDefaults(objectsQueryKeys.objectsWithStructuresList(), {
		queryFn: ({queryKey}: QueryFunctionContext<IObjectsQueryKey>) => {
			const [, , , objectId, filter, includingParentObjectId] = queryKey;
			return getTargetObjectIdsHaveStructures({objectId, filter, includingParentObjectId});
		},
		staleTime: 1000 * 60 * 60 * 24 * 7, // 1 неделя
		gcTime: 1000 * 60 * 60 * 24 * 7 // 1 неделя
	});
};
