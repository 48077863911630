import {CellProps} from 'react-table';
import {IPreparedField} from '@/features/Fields';
import styles from './MobileCell.module.less';
import classNames from 'classnames';
import {IconMenu, MenuItem} from '@tehzor/ui-components';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {useCallback} from 'react';
import {useTranslation} from 'react-i18next';

const iconEdit = <i className="tz-edit-20" />;
export const MobileCell = ({row}: CellProps<IPreparedField>) => {
	const {pushPath} = useChangePath();
	const {t} = useTranslation();
	const {name, required, enabled, valueTypeName, copyable, icon, order, id, setId, isCustom} =
		row.original;

	const toEditSettings = useCallback(() => {
		if (isCustom) {
			pushPath(
				`/manage/fields/fields-settings-set/${setId}/edit-custom-field-settings/${id}`
			);
		} else {
			pushPath(
				`/manage/fields/fields-settings-set/${setId}/edit-builtin-field-settings/${id}`
			);
		}
	}, [pushPath, id, setId, isCustom]);

	const items = [
		<MenuItem
			key="edit"
			icon={iconEdit}
			onClick={toEditSettings}
		>
			Редактировать
		</MenuItem>
	];

	return (
		<div className={styles.wrapper}>
			{enabled && <p className={styles.order}>{order}.</p>}
			<div className={styles.container}>
				<p className={styles.title}>{name}</p>
				{isCustom && <p className={styles.subTitle}>{valueTypeName}</p>}
				<p className={styles.infoItem}>
					Включено: <span>{enabled ? t('dialog.yes') : t('dialog.no')}</span>
				</p>
				<p className={styles.infoItem}>
					Обязательно: <span>{required ? t('dialog.yes') : t('dialog.no')}</span>
				</p>
				<p className={styles.infoItem}>
					Возможность копирования:{' '}
					<span>{copyable ? t('dialog.yes') : t('dialog.no')}</span>
				</p>
				{icon && isCustom && <i className={classNames(styles.icon, icon)} />}
			</div>
			<IconMenu
				className={styles.menu}
				type="common"
			>
				{items}
			</IconMenu>
		</div>
	);
};
