import {ICheckItem} from '@tehzor/tools/interfaces/checkItems/ICheckItem';
import {Fragment, memo, useCallback, useTransition} from 'react';
import classNames from 'classnames';
import {ExpandablePanel, LinkButton} from '@tehzor/ui-components';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {Tree} from 'array-to-tree';
import {useToggle} from 'react-use';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import {
	extractCheckRecordByItemId,
	extractCheckRecordsIsChanging
} from '@src/store/modules/pages/checkLists/selectors/records';
import ClickPreventWrap from '@src/components/tableCells/ClickPreventWrap';
import {useChangeCheckRecord} from '@src/core/hooks/mutations/checkRecords/useChangeCheckRecord';
import {getCheckItemsLabel} from '../../../../utils/getCheckItemsLabel';
import {ItemBullet} from './ItemBullet';
import {RecordStatusSelect} from '@src/components/CheckRecordStatusSelect/RecordStatusSelect';
import {ItemHeader} from './ItemHeader';
import {ItemActions} from './ItemActions';
import {useCheckListStatusPermission} from '@src/core/hooks/permissions/useCheckListStatusPermission';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {Indicators} from '../../../Indicators/Indicators';
import {useExtractCheckListById} from '@src/core/hooks/queries/checkLists/hooks';
import {IState} from '@src/store/modules';
import {isDisabledCheckItem} from '@src/pages/CheckListPage/components/list/CheckListItems/utils/isDisabledCheckItem';
import {ProcessIds} from '@tehzor/tools/interfaces/process/ProcessId';

interface ICheckListItemProps {
	item: Tree<ICheckItem>;
	mode: 'detail' | 'main';
	stage: ObjectStageIds;
	disabled?: boolean;
	containScrollTarget?: boolean;
	showDivider?: boolean;
	canAddEntity?: boolean;
	acceptedItemsIds?: string[];
	onClick?: (item: ICheckItem) => void;
	isNested?: boolean;
}

const simpleArrowIcon = <i className="tz-simple-arrow-16" />;

export const CheckListItem = memo((props: ICheckListItemProps) => {
	const {
		disabled,
		item,
		onClick,
		mode,
		stage,
		containScrollTarget,
		showDivider,
		canAddEntity,
		acceptedItemsIds,
		isNested
	} = props;
	const {itemId, objectId, spaceId} = useStrictParams<{
		itemId: string;
		objectId: string;
		spaceId?: string;
	}>();

	const isNotScrollTarget = !!containScrollTarget && item.id !== itemId;
	const [isExpanded, toggle] = useToggle(isNotScrollTarget);
	const isChanging = useAppSelector(extractCheckRecordsIsChanging);
	const {changeCheckItemRecord, isLoading} = useChangeCheckRecord(objectId, spaceId, item);
	const {data: checkList} = useExtractCheckListById(item.checkListId);
	const record = useAppSelector((state: IState) => extractCheckRecordByItemId(state, item.id));

	const {canStatusEdit, availableStatuses} = useCheckListStatusPermission(
		checkList?.processId === ProcessIds.ACCEPTANCE_CONTROL
			? 'check-items-work-acceptance-status-edit'
			: 'check-items-space-status-edit',
		objectId
	);

	const handleClick = () => {
		if (onClick && !disabled) {
			onClick(item);
		}
	};

	const isActive = mode === 'main' && itemId === item.id;

	const wrapperConditions = {
		'check-list-items__item_active': isActive,
		'check-list-items__item_disabled': disabled,
		'check-list-items__item_nested': isNested
	};

	const [, startTransition] = useTransition();

	const toggleExpandablePanel = useCallback(() => {
		startTransition(() => {
			toggle();
		});
	}, [toggle]);

	// Если страница = помещение и процесс = "Приёмочный контроль",
	// то недоступны смена статуса и добавление новых сущностей чек листа:
	const isSpacePageAndAcceptanceProcess =
		!!spaceId && checkList?.processId === ProcessIds.ACCEPTANCE_CONTROL;
	const canAdd = canAddEntity && !isSpacePageAndAcceptanceProcess;

	return (
		<>
			{showDivider && <hr className="check-list-items__item__divider" />}
			<div
				id={item.id}
				className={classNames('check-list-items__item', wrapperConditions)}
				role="presentation"
				onClick={handleClick}
			>
				<ItemBullet item={item} />
				<ItemHeader
					item={item}
					stage={stage}
					record={record}
					canAddEntity={canAdd}
					disabled={disabled}
				/>

				<Indicators
					checkListId={item.checkListId}
					spaceId={spaceId}
					checkItemId={item.id}
					className="check-list-items__item-indicators"
				/>

				<div
					className={classNames('check-list-items__item-footer', {
						'check-list-items__item-footer--no-children': !item.children?.length
					})}
				>
					<ClickPreventWrap className="check-list-items__item-state">
						<RecordStatusSelect
							item={item}
							mutation={changeCheckItemRecord}
							showLoader={isLoading && isChanging}
							disabled={
								disabled ||
								isChanging ||
								!canStatusEdit ||
								isSpacePageAndAcceptanceProcess
							}
							availableStatuses={availableStatuses}
						/>
					</ClickPreventWrap>

					{checkList && canStatusEdit && !isSpacePageAndAcceptanceProcess && (
						<ItemActions
							active={itemId === item.id}
							item={item}
							record={record}
							stage={checkList.stage}
							changeCheckItemRecord={changeCheckItemRecord}
							disabled={disabled || isChanging}
							availableStatuses={availableStatuses}
						/>
					)}
				</div>
				{!disabled && mode === 'main' && item.children && item.children.length > 0 && (
					<div className="check-list-items__item-body">
						<LinkButton
							className={classNames('check-list-items__item-body-link', {
								'check-list-items__item-body-link_active': isExpanded
							})}
							leftIcon={simpleArrowIcon}
							label={getCheckItemsLabel(item.children.length, isExpanded)}
							onClick={toggleExpandablePanel}
						/>
					</div>
				)}
			</div>

			{mode === 'main' && item.children && item.children.length > 0 && (
				<ExpandablePanel expanded={isExpanded}>
					{item.children.map(subItem => (
						<Fragment key={subItem.id}>
							<CheckListItem
								mode="main"
								item={subItem}
								stage={stage}
								onClick={onClick}
								containScrollTarget={isNotScrollTarget}
								showDivider
								canAddEntity={canAddEntity}
								disabled={isDisabledCheckItem(subItem, acceptedItemsIds)}
								acceptedItemsIds={acceptedItemsIds}
								isNested
							/>
						</Fragment>
					))}
				</ExpandablePanel>
			)}
		</>
	);
});
