import {Dispatch} from 'react';
import styles from './EditablePresaleCheckAttachments.module.less';
import Attachments from '@src/components/editableFields/Attachments';
import {
	IUploadingFilesAction,
	IUploadingFilesState
} from '@src/core/hooks/states/useUploadingFilesState';
import {
	IEditablePresaleCheckAction,
	IEditablePresaleCheckState
} from '../../model/hooks/useEditablePresaleCheckState';

interface IEditablePresaleCheckAttachmentsProps {
	editingState: IEditablePresaleCheckState;
	editingDispatch: Dispatch<IEditablePresaleCheckAction>;
	uploadingFilesState: IUploadingFilesState;
	uploadingFilesDispatch: Dispatch<IUploadingFilesAction>;
	saving: boolean;
	label: string;
}

/**
 * Интерфейс с визуальными компонентами для редактирования полей
 */
export const EditablePresaleCheckAttachments = ({
	editingState,
	editingDispatch,
	uploadingFilesState,
	uploadingFilesDispatch,
	saving,
	label
}: IEditablePresaleCheckAttachmentsProps) => (
	<div>
		<Attachments
			className={{
				attachBtn: styles.attachBtn
			}}
			attachments={editingState.attachments}
			label={label}
			uploadingFiles={uploadingFilesState.value}
			editingDispatch={editingDispatch}
			uploadingFilesDispatch={uploadingFilesDispatch}
			disabled={saving}
			canDraw={false}
			hasError={editingState.errors.attachments && uploadingFilesState.error}
			showAttachBtn
			saveToS3
		/>
	</div>
);
