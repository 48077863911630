import {extractFieldSettingsListArray} from './extractFieldSettingsListArray';
import {IGetListFieldSettingsResponse} from '@/shared/api/fieldSettings/getFieldSettingsList';

export const extractFieldSettingsByFieldId = (
	data: IGetListFieldSettingsResponse,
	fieldId?: string
) => {
	const array = extractFieldSettingsListArray(data);
	return array?.find(settings => settings.fieldId === fieldId);
};
