import {IconMenu, MenuItem} from '@tehzor/ui-components';
import useAsyncFn from 'react-use/lib/useAsyncFn';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {IWorkAcceptance} from '@tehzor/tools/interfaces/workAcceptances/IWorkAcceptance';
import {deleteWorkAcceptance} from '@src/store/modules/entities/workAcceptance/actions';
import {useEditableWorkAcceptanceDialog} from '@src/components/EditableWorkAcceptanceDialog/hooks/useEditableWorkAcceptanceDialog';
import {IPermissions} from '@src/core/hooks/permissions/useWorkAcceptancesPermissions';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import {useCheckListPermissions} from '@src/core/hooks/permissions/useCheckListPermissions';
import {useCheckListHistoryDialog} from '@src/components/CheckListHistoryDialog/hooks/useCheckListHistoryDialog';
import {useExtractCheckListById} from '@src/core/hooks/queries/checkLists/hooks';
import {useWorkAcceptanceHistoryDialog} from '@src/components/WorkAcceptanceHistoryDialog/hooks/useWorkAcceptanceHistoryDialog';
import {useTranslation} from 'react-i18next';
import {useTranslatedConfirmDialog} from '@src/core/hooks/translations/useTranslatedConfirmDialog';

interface IDesktopMenuProps {
	workAcceptance: IWorkAcceptance;
	permissions?: IPermissions;
}

const historyIcon = <i className="tz-history-24" />;
const editIcon = <i className="tz-edit" />;
const deleteIcon = <i className="tz-delete" />;

export const DesktopMenu = ({workAcceptance, permissions}: IDesktopMenuProps) => {
	const {t} = useTranslation();

	const {goBack} = useChangePath();
	const dispatch = useAppDispatch();
	const {objectId, listId} = useStrictParams<{
		objectId: string;
		listId?: string;
	}>();
	const {data: checkList} = useExtractCheckListById(listId);
	const checkListPermissions = useCheckListPermissions();

	// Диалог редактирования приёмки работ
	const [workAcceptanceDialog, openEditDialog] = useEditableWorkAcceptanceDialog(
		workAcceptance.objectId,
		workAcceptance.stage,
		workAcceptance
	);
	const [historyDialog, openHistoryDialog] = useCheckListHistoryDialog(
		objectId,
		checkList?.stage,
		checkList?.id,
		undefined,
		workAcceptance.id
	);

	const [workAcceptanceHistoryDialog, openWorkAcceptanceHistoryDialog] =
		useWorkAcceptanceHistoryDialog(objectId, workAcceptance.id);

	const [deleteDialog, getDeleteConfirmation] = useTranslatedConfirmDialog({
		title: t('useConfirmDialog.workAcceptance.deleteTitle'),
		message: t('useConfirmDialog.workAcceptance.deleteMessage', {id: workAcceptance.number})
	});

	const [, handleDeleteClick] = useAsyncFn(async () => {
		if (await getDeleteConfirmation()) {
			await dispatch(deleteWorkAcceptance(workAcceptance.objectId, workAcceptance.id));
			goBack();
		}
	}, [workAcceptance.objectId, workAcceptance.id]);

	const items = [];

	if (workAcceptance && permissions?.canViewHistory) {
		items.push(
			<MenuItem
				key="work-acceptance-history"
				icon={historyIcon}
				onClick={openWorkAcceptanceHistoryDialog}
			>
				{t('workAcceptancePage.menu.workAcceptanceUpdateHistory')}
			</MenuItem>
		);
	}

	if (checkList && checkListPermissions.canViewHistory) {
		items.push(
			<MenuItem
				key="checklist-history"
				icon={historyIcon}
				onClick={openHistoryDialog}
			>
				{t('workAcceptancePage.menu.checkListsUpdateHistory')}
			</MenuItem>
		);
	}
	if (permissions?.canEdit) {
		items.push(
			<MenuItem
				key="edit"
				icon={editIcon}
				onClick={openEditDialog}
			>
				{t('workAcceptancePage.menu.edit')}
			</MenuItem>
		);
	}

	if (permissions?.canDelete) {
		items.push(
			<MenuItem
				key="delete"
				icon={deleteIcon}
				onClick={handleDeleteClick}
			>
				{t('workAcceptancePage.menu.delete')}
			</MenuItem>
		);
	}

	return items.length ? (
		<>
			<IconMenu type="accent-blue">{items}</IconMenu>
			{permissions?.canEdit && workAcceptanceDialog}
			{permissions?.canDelete && deleteDialog}
			{checkListPermissions.canViewHistory && checkList && historyDialog}
			{permissions?.canViewHistory && workAcceptanceHistoryDialog}
		</>
	) : null;
};
