import {fieldSettingsQueryKeys} from '@/entities/FieldSettings';
import {fieldSettingsSetsQueryKeys} from '@/entities/FieldsSettingsSets';
import {useIsMutating} from '@tanstack/react-query';
import {oneArgIsTrue} from '@/shared/utils/oneArgIsTrue';

export const useFieldsIsUpdating = () => {
	const settingsIsEdit = !!useIsMutating({
		mutationKey: fieldSettingsQueryKeys.edit()
	});
	const settingsIsAdd = !!useIsMutating({
		mutationKey: fieldSettingsQueryKeys.add()
	});
	const settingsIsDelete = !!useIsMutating({
		mutationKey: fieldSettingsQueryKeys.delete()
	});
	const setIsIsEdit = !!useIsMutating({
		mutationKey: fieldSettingsSetsQueryKeys.edit()
	});

	return {
		isUpdating: oneArgIsTrue(settingsIsEdit, settingsIsAdd, settingsIsDelete, setIsIsEdit)
	};
};
