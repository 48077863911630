import {createMutation} from 'react-query-kit';
import {IPresaleCheck} from '@tehzor/tools/interfaces/presaleChecks/IPresaleCheck';
import {presaleChecksQueryKeys} from '../../keys';
import {IEditPresaleCheckParams} from '@/features/PresaleCheck';

export const useEditPresaleCheckMutation = createMutation<IPresaleCheck, IEditPresaleCheckParams>({
	mutationKey: presaleChecksQueryKeys.edit(),
	meta: {
		entity: 'presaleCheck',
		type: 'editing'
	}
});
