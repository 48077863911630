import {Dispatch, SetStateAction, useEffect} from 'react';
import {IPresaleCheck} from '@tehzor/tools/interfaces/presaleChecks/IPresaleCheck';
import {usePresaleChecksAsArray} from '@/entities/PresaleCheck';
import {useExtractLatestPresaleCheckId} from '@/entities/PresaleCheck/model/cache/queries/PresaleChecksLatest/hooks/useExtractLatestPresaleCheckId';
import {useExtractAllLatestPresaleChecksAsArray} from '@/entities/PresaleCheck/model/cache/queries/PresaleChecksLatest/hooks/useExtractAllLatestPresaleChecksAsArray';
import {useFetchLatestPresaleCheck} from '@/entities/PresaleCheck/model/cache/queries/PresaleChecksLatest/hooks/useFetchLatestPresaleCheck';
import {useLocalPresaleChecks} from '@/entities/PresaleCheck';

export const useLatestPresaleChecks = (
	objectId: string,
	setSelectedPresaleCheckId: Dispatch<SetStateAction<string | undefined>>,
	selectedPresaleCheckId?: string,
	links?: IPresaleCheck['links']
) => {
	const {data: allPresaleChecks} = usePresaleChecksAsArray(objectId);
	const {data: latestPresaleCheckId} = useExtractLatestPresaleCheckId(objectId, links?.spaceId);
	const {data: localPresaleChecks} = useLocalPresaleChecks();
	const {data: allLatestPresaleChecks} = useExtractAllLatestPresaleChecksAsArray();

	const isPresaleCheck = !!allPresaleChecks?.find(item => item.id === selectedPresaleCheckId);
	const isLatestPresaleCheck = !!allLatestPresaleChecks?.find(
		item => item.id === selectedPresaleCheckId
	);
	const isLocalPresaleCheck = !!localPresaleChecks?.find(
		item => item.id === selectedPresaleCheckId
	);

	const {data: latestPresaleCheck} = useFetchLatestPresaleCheck(
		selectedPresaleCheckId as string,
		objectId,
		{
			enabled: !!(
				selectedPresaleCheckId &&
				objectId &&
				isPresaleCheck &&
				!isLatestPresaleCheck &&
				!isLocalPresaleCheck
			)
		}
	);
	// "Синхронизация состояния" - это антипаттерн, стоит переписать.
	useEffect(() => {
		if (latestPresaleCheckId) {
			setSelectedPresaleCheckId(latestPresaleCheckId);
		}
	}, [latestPresaleCheckId]);

	useEffect(() => {
		if (latestPresaleCheck) {
			setSelectedPresaleCheckId(latestPresaleCheck.id);
		}
	}, [latestPresaleCheck]);
};
