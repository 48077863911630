import {useMemo} from 'react';
import {ActionsMobile} from './Actions.mobile';
import {IStructure} from '@tehzor/tools/interfaces/structures/IStructure';
import {SelectionActions} from '../selection/SelectionActions';
import {useStructureEntities} from '@/entities/Structure';

interface IMobileRightButtonsProps {
	objectId: string;
	structure: IStructure;
	selectedRows?: string[];
}

export const MobileRightButtons = ({
	objectId,
	structure,
	selectedRows
}: IMobileRightButtonsProps) => {
	const entities = useStructureEntities(objectId, structure.id);

	const selectedEntities = useMemo(
		() => entities.filter(item => selectedRows?.includes(item.id)),
		[selectedRows, entities]
	);

	if (!structure) {
		return null;
	}

	if (selectedRows && selectedRows.length > 0) {
		return (
			<SelectionActions
				objectId={objectId}
				selectedEntities={selectedEntities}
			/>
		);
	}
	return (
		<ActionsMobile
			objectId={objectId}
			structure={structure}
		/>
	);
};
