import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import checkExpiration from '@tehzor/tools/utils/checkExpiration';
import {IState} from '@src/store/modules';
import {ApiAction, createApiAction} from '@src/store/middlewares/api';
import {AppThunkAction} from '@src/store/interfaces/thunk';
import {
	IGetProblemsCreationStatsResponse,
	requestProblemsCreationStats
} from '@src/api/backend/statistics/creation';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {addErrorToast} from '@tehzor/ui-components';
import {t} from 'i18next';

const request = () => ({type: types.GET_PROBLEMS_CREATION_REQUEST});

const success = (response: IGetProblemsCreationStatsResponse) => ({
	type: types.GET_PROBLEMS_CREATION_SUCCESS,
	payload: response
});

const failure = (error: IError) => {
	if (error.statusCode !== 401) {
		addErrorToast({title: t('toast.getStats.error.title')});
	}
	return {
		type: types.GET_PROBLEMS_CREATION_FAILURE,
		payload: error
	};
};

/**
 * Получает статистику по созданию нарушений для стадии приёмки
 */
export const getProblemsCreationStats =
	(): AppThunkAction<Promise<IGetProblemsCreationStatsResponse>> => (dispatch, getState) =>
		dispatch(
			checkExpiration<IState, IGetProblemsCreationStatsResponse, ApiAction>(
				state => state.statistics.acceptance.problemsCreation,
				createApiAction<IGetProblemsCreationStatsResponse>(
					request,
					success,
					failure,
					() => {
						const s = getState();
						const {objects, users, contractors} =
							s.settings.pages.statistics.acceptance.filters;
						return requestProblemsCreationStats(
							objects,
							ObjectStageIds.ACCEPTANCE,
							users,
							contractors
						);
					}
				)
			)
		);
