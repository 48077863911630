import {useChangePath} from '@src/core/hooks/useChangePath';
import {useCallback, ReactNode} from 'react';
import {useTranslation} from 'react-i18next';
import {IPresaleCheck} from '@tehzor/tools/interfaces/presaleChecks/IPresaleCheck';
import {useDeletePresaleCheck} from '../../model/hooks/useDeletePresaleCheck';
import {useTranslatedConfirmDialog} from '@src/core/hooks/translations/useTranslatedConfirmDialog';

export const useDeletePresaleCheckConfirmDialog = (
	objectId: string,
	presaleCheck: IPresaleCheck
): [ReactNode, () => void] => {
	const {t} = useTranslation();

	const {goBack} = useChangePath();

	const deleteCheck = useDeletePresaleCheck();
	const [deleteDialog, getDeleteConfirmation] = useTranslatedConfirmDialog({
		title: t('presaleChecksPage.actions.menu.delete.dialogTitle'),
		message: t('presaleChecksPage.actions.menu.delete.dialogBody', {
			number: presaleCheck.number
		})
	});

	const handleDelete = useCallback(async () => {
		if (await getDeleteConfirmation()) {
			deleteCheck({objectId, presaleCheckId: presaleCheck.id});
			goBack();
		}
	}, [getDeleteConfirmation, deleteCheck, objectId, presaleCheck.id, goBack]);

	return [deleteDialog, handleDelete];
};
