import {useCallback} from 'react';
import {deleteProblem} from '@src/store/modules/pages/problem/actions';
import {deleteInspection} from '@src/store/modules/entities/inspection/actions';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {IconButton} from '@tehzor/ui-components';
import {ISpaceEntity} from '@src/interfaces/ISpaceEntity';
import {deleteLocalCheck} from '@src/actions/local-entities/checks';
import {deleteLocalInternalAcceptance} from '@src/actions/local-entities/internalAcceptance';
import {deleteLocalOwnerAcceptance} from '@src/actions/local-entities/ownerAcceptance';
import {deleteLocalWarrantyClaim} from '@src/actions/local-entities/warrantyClaim';
import {deleteWorkAcceptance} from '@src/store/modules/entities/workAcceptance/actions';
import {queryClient} from '@src/api/QueryClient';
import {objectsQueryKeys} from '@src/api/cache/objects/keys';
import {useDeleteOwnerAcceptance} from '@src/core/hooks/mutations/ownerAcceptances/useDeleteOwnerAcceptance';
import {useDeleteCheck} from '@src/core/hooks/mutations/checks/useDeleteCheck';
import {useDeleteInternalAcceptance} from '@src/core/hooks/mutations/InternalAcceptances/useDeleteInternalAcceptance';
import {useDeleteWarrantyClaim} from '@src/core/hooks/mutations/warrantyClaims/useDeleteWarrantyClaim';
import {useDeletePresaleCheck} from '@/features/PresaleCheck/model/hooks/useDeletePresaleCheck';
import {useTranslatedConfirmDialog} from '@src/core/hooks/translations/useTranslatedConfirmDialog';
import {useTranslation} from 'react-i18next';
import {SelectedEntityType} from '../../enums/selectedEntityType';

interface ISelectedEntitiesDeletionProps {
	selectedEntities: ISpaceEntity[];
}

const SelectedEntitiesDeletion = ({selectedEntities}: ISelectedEntitiesDeletionProps) => {
	const {t} = useTranslation();
	const dispatch = useAppDispatch();
	const {mutateAsync: deleteOwnerAcceptance} = useDeleteOwnerAcceptance();
	const {mutate: deleteCheck} = useDeleteCheck();
	const deletePresaleCheck = useDeletePresaleCheck();
	const {mutate: deleteInternalAcceptance} = useDeleteInternalAcceptance();
	const {mutate: deleteWarrantyClaim} = useDeleteWarrantyClaim();
	const [deleteDialog, getDeleteConfirmation] = useTranslatedConfirmDialog({
		title: t('useConfirmDialog.selectedSomeEntities.deleteTitle'),
		message: t('useConfirmDialog.selectedSomeEntities.deleteMessage')
	});

	const handleClick = useCallback(async () => {
		if (await getDeleteConfirmation()) {
			let subRowsNumber = 0;
			const objectsIds: string[] = [];
			for (const {type, data, subRows} of selectedEntities) {
				switch (type) {
					case SelectedEntityType.PROBLEM:
						if (!subRowsNumber) {
							await dispatch(deleteProblem(data.objectId, data.id));
							objectsIds.push(data.objectId);
						}
						break;
					case SelectedEntityType.INSPECTION:
						if (!subRowsNumber) {
							await dispatch(deleteInspection(data.objectId, data.id));
						}
						break;
					case SelectedEntityType.CHECK:
						if (subRows !== undefined) {
							subRowsNumber = subRows.length + 1;
						}
						deleteCheck({objectId: data.objectId, checkId: data.id});
						break;
					case SelectedEntityType.PRESALE_CHECK:
						if (subRows !== undefined) {
							subRowsNumber = subRows.length + 1;
						}
						deletePresaleCheck({objectId: data.objectId, presaleCheckId: data.id});
						break;
					case SelectedEntityType.LOCAL_CHECK:
						if (subRows !== undefined) {
							subRowsNumber = subRows.length + 1;
						}
						void dispatch(deleteLocalCheck(data.id));
						break;
					case SelectedEntityType.LOCAL_PRESALE_CHECK:
						if (subRows !== undefined) {
							subRowsNumber = subRows.length + 1;
						}
						deletePresaleCheck({objectId: data.objectId, presaleCheckId: data.id});
						break;
					case SelectedEntityType.INTERNAL_ACCEPTANCE:
						if (subRows !== undefined) {
							subRowsNumber = subRows.length + 1;
						}
						deleteInternalAcceptance({
							objectId: data.objectId,
							internalAcceptanceId: data.id
						});
						break;
					case SelectedEntityType.LOCAL_INTERNAL_ACCEPTANCE:
						if (subRows !== undefined) {
							subRowsNumber = subRows.length + 1;
						}
						void dispatch(deleteLocalInternalAcceptance(data.id));
						break;
					case SelectedEntityType.WARRANTY_CLAIM:
						if (subRows !== undefined) {
							subRowsNumber = subRows.length + 1;
						}
						deleteWarrantyClaim({objectId: data.objectId, warrantyClaimId: data.id});
						break;
					case SelectedEntityType.LOCAL_WARRANTY_CLAIM:
						if (subRows !== undefined) {
							subRowsNumber = subRows.length + 1;
						}
						void dispatch(deleteLocalWarrantyClaim(data.id));
						break;
					case SelectedEntityType.OWNER_ACCEPTANCE:
						if (subRows !== undefined) {
							subRowsNumber = subRows.length + 1;
						}
						await deleteOwnerAcceptance({
							objectId: data.objectId,
							acceptanceId: data.id
						});
						break;
					case SelectedEntityType.LOCAL_OWNER_ACCEPTANCE:
						if (subRows !== undefined) {
							subRowsNumber = subRows.length + 1;
						}
						void dispatch(deleteLocalOwnerAcceptance(data.id));
						break;
					case SelectedEntityType.WORK_ACCEPTANCE:
						if (subRows !== undefined) {
							subRowsNumber = subRows.length + 1;
						}
						void dispatch(deleteWorkAcceptance(data.objectId, data.id));
						break;
				}
			}
			if (objectsIds.length > 0) {
				await queryClient.refetchQueries({
					queryKey: [...objectsQueryKeys.stats(), objectsIds]
				});
			}
		}
	}, [selectedEntities]);

	return (
		<>
			<IconButton onClick={handleClick}>
				<i className="tz-delete" />
			</IconButton>

			{deleteDialog}
		</>
	);
};

export default SelectedEntitiesDeletion;
