import {useCallback} from 'react';
import {IconButton} from '@tehzor/ui-components';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {changeSelected} from '../../../../store/modules/settings/pages/spaces/actions';

interface IMobileSelectionClearProps {
	objectId: string;
}

export const MobileSelectionClearing = ({objectId}: IMobileSelectionClearProps) => {
	const dispatch = useAppDispatch();

	const handleClick = useCallback(() => {
		dispatch(changeSelected(objectId, []));
	}, [objectId]);

	return (
		<IconButton onClick={handleClick}>
			<i className="tz-close-24" />
		</IconButton>
	);
};
