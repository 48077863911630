import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractPresaleCheckVisibility} from '../../../../slice/pageSettings/selectors';
import {useCallback} from 'react';
import {IGetListInspectionsResponse} from '@src/api/backend/inspections';
import {extractPresaleCheckInspectionsEntity} from '../selectors/extractPresaleCheckInspectionsEntity';
import {usePresaleCheckInspections} from './usePresaleCheckInspections';

export const usePresaleCheckInspectionsEntities = (objectId: string, presaleCheckId: string) => {
	const visibility = useAppSelector(extractPresaleCheckVisibility);
	const selector = useCallback(
		(data: IGetListInspectionsResponse) =>
			extractPresaleCheckInspectionsEntity(data, visibility),
		[visibility]
	);
	return usePresaleCheckInspections(objectId, presaleCheckId, selector);
};
