import {useCallback, useState} from 'react';
import {ActionButtons, Button, Dialog} from '@tehzor/ui-components';
import {makeTitle} from './utils/makeTitle';
import {IBankAccount} from '@tehzor/tools/interfaces/bankAccounts';
import {useEditableBankAccount} from '@src/components/EditableBankAccount/hooks/useEditableBankAccount';
import './EditableBankAccountDialog.less';
import {useTranslatedConfirmDialog} from '@src/core/hooks/translations/useTranslatedConfirmDialog';

interface IEditableBankAccountDialogProps {
	bankAccount?: IBankAccount;
	legalEntityId?: string;
	companyId?: string;
	isOpen: boolean;
	onClose: () => void;
}

export const EditableBankAccountDialog = (props: IEditableBankAccountDialogProps) => {
	const {bankAccount, legalEntityId, companyId, isOpen, onClose} = props;

	const [saving, setSaving] = useState(false);
	const {fields, reset, getSavingData, isBlocking} = useEditableBankAccount({
		bankAccount,
		legalEntityId,
		companyId
	});

	const save = useCallback(async () => {
		setSaving(true);

		if (!getSavingData) {
			onClose();
			setSaving(false);
			return;
		}

		const savingData = await getSavingData();

		if (savingData) {
			onClose();
		}

		setSaving(false);
	}, [getSavingData, onClose]);

	const [closingDialog, getClosingConfirmation] = useTranslatedConfirmDialog();

	const handleClose = useCallback(async () => {
		if (!isBlocking || (await getClosingConfirmation())) {
			onClose();
		}
	}, [isBlocking, onClose]);

	const footer = (
		<ActionButtons>
			<Button
				type="cancel"
				label="Отменить"
				disabled={saving}
				onClick={handleClose}
			/>
			<Button
				type="accent-blue"
				label="Сохранить"
				disabled={saving}
				onClick={save}
			/>
		</ActionButtons>
	);

	return (
		<Dialog
			className="editable-bank-account-dialog"
			isOpen={isOpen}
			title={makeTitle(bankAccount)}
			footer={footer}
			fullScreenOnTablet
			onRequestClose={handleClose}
			onAfterClose={reset}
		>
			{fields}
			{closingDialog}
		</Dialog>
	);
};
