import {memo, Suspense, useCallback, useMemo} from 'react';
import {Boards, MobilePagination, Schema} from '@tehzor/ui-components';
import {useDispatch} from 'react-redux';

import styles from './Schema.module.less';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractStructuresSchemaPageSettings} from '@/entities/Structures/model/slice/selectors';
import {
	useObjectsMap,
	useTargetObjectIdsHaveStructures
} from '@src/core/hooks/queries/objects/hooks';
import {structuresActions} from '@/entities/Structures';
import {useSchemaItemsByWorkAcceptancesAndProblems} from '../../model/hooks/useSchemaItemsByWorkAcceptancesAndProblems';
import {MobileObjectBoard} from '../ObjectBoard/ObjectBoards/MobileObjectBoard';
import {Skeleton} from '../ObjectBoard/Skeleton/Skeleton';
import {StructuresDesktopSchemaViewTabs} from '@/features/StructuresDesktopSchemaViewTabs.tsx';

interface ISchemaProps {
	objectId: string;
}

export const StructuresSchemaMobile = memo(({objectId}: ISchemaProps) => {
	const {currentObject, schemaStructureTypeId} = useAppSelector(s =>
		extractStructuresSchemaPageSettings(s, objectId)
	);
	const targetObjects = useTargetObjectIdsHaveStructures({
		objectId,
		filter: schemaStructureTypeId ? {type: schemaStructureTypeId} : undefined
	});

	const hasChildren = targetObjects.length > 0;

	const dispatch = useDispatch();
	const {changeCurrentObject} = structuresActions;

	const objectsMap = useObjectsMap();
	const currentIndex = useMemo(() => {
		if (currentObject) {
			const index = targetObjects.indexOf(currentObject);
			return index === -1 ? 0 : index;
		}
		return 0;
	}, [currentObject, targetObjects]);

	const getObjectTitle = useCallback(
		() => (objectsMap ? objectsMap[currentObject ?? targetObjects[0]]?.name ?? '' : null),
		[currentObject, targetObjects, objectsMap]
	);

	const handlePageChange = useCallback(
		(value: number) => {
			dispatch(changeCurrentObject({objectId, id: targetObjects[value]}));
		},
		[objectId, targetObjects, dispatch, changeCurrentObject]
	);
	const currentDisplayedObjectId = hasChildren ? targetObjects[currentIndex] : objectId;

	const preparedStructuresData =
		useSchemaItemsByWorkAcceptancesAndProblems(currentDisplayedObjectId);

	return (
		<Schema>
			<div>
				<StructuresDesktopSchemaViewTabs objectId={objectId} />
				{hasChildren && (
					<MobilePagination
						className={styles.mobilePagination}
						page={currentIndex}
						pageCount={targetObjects.length}
						renderTitle={getObjectTitle}
						onPageChange={handlePageChange}
					/>
				)}
				<Suspense fallback={<Skeleton />}>
					<Boards>
						<MobileObjectBoard
							objectId={currentDisplayedObjectId}
							preparedStructuresData={preparedStructuresData}
						/>
					</Boards>
				</Suspense>
			</div>
		</Schema>
	);
});

StructuresSchemaMobile.displayName = 'StructuresSchemaMobile';
