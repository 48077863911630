import {requestInspection} from '@src/api/backend/inspection';
import {requestInspectionStories} from '@src/api/backend/inspectionStories';
import {requestPaginateInspections} from '@src/api/backend/inspections';
import {ISavingInspectionQuery} from '@src/core/hooks/mutations/inspection/useAddInspection';
import {toListInspection} from '@src/core/hooks/mutations/inspection/utils/convertToLocalSave';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractOfflineModeAvailability} from '@src/store/modules/settings/offlineMode/selectors/availability';
import {Query, QueryFunctionContext, useQueryClient} from '@tanstack/react-query';
import IListInspection from '@tehzor/tools/interfaces/inspections/IListInspection';
import {IInspectionsListQueryKey, IInspectionStoriesQueryKey, inspectionsQueryKeys} from '../keys';

export const useInspectionsQueryDefaults = () => {
	const queryClient = useQueryClient();
	const isOfflineModeAvailable = useAppSelector(extractOfflineModeAvailability);
	queryClient.setQueryDefaults(inspectionsQueryKeys.list(), {
		queryFn: async ({queryKey}: QueryFunctionContext<IInspectionsListQueryKey>) => {
			const [, , objectId, filters, sort, offset, pageSize] = queryKey;
			return requestPaginateInspections(filters, sort, offset, pageSize, objectId);
		},
		staleTime: 1000 * 60 * 5, // 5 минут
		gcTime: 1000 * 60 * 60 * 24 // 1 День
	});

	queryClient.setQueryDefaults(inspectionsQueryKeys.details(), {
		queryFn: async ({queryKey}) =>
			requestInspection(queryKey[3] as string, queryKey[2] as string),
		meta: {
			persist: isOfflineModeAvailable
		},
		staleTime: 1000 * 60 * 5, // 5 минут
		gcTime: 1000 * 60 * 60 * 24 // 1 День
	});

	queryClient.setQueryDefaults(inspectionsQueryKeys.localList(), {
		queryFn: ({queryKey}) => {
			const [, , objectId] = queryKey;
			const queryMatch = queryClient.getQueriesData<ISavingInspectionQuery>({
				queryKey: inspectionsQueryKeys.savingDatas(),
				predicate: (query: Query<ISavingInspectionQuery>) => {
					const {data} = query.state;
					if (objectId === data?.object.id || !objectId || objectId === 'all') {
						return true;
					}
					return false;
				}
			});
			const savingDatas = queryMatch.map(([, problem]) => problem);
			const inspections: IListInspection[] = [];
			for (const savingData of savingDatas) {
				if (savingData) {
					const {object, links, stage, processId, key, fields, transferStatus, number} =
						savingData;
					inspections.push({
						...toListInspection(object, links, stage, processId, fields, key, number),
						transferStatus
					});
				}
			}
			return inspections;
		},
		staleTime: 0,
		gcTime: 0,
		networkMode: 'offlineFirst'
	});

	queryClient.setQueryDefaults(inspectionsQueryKeys.stories(), {
		queryFn: async ({queryKey}: QueryFunctionContext<IInspectionStoriesQueryKey>) => {
			const [, , objectId, inspectionId] = queryKey;
			return requestInspectionStories(objectId, inspectionId);
		},
		staleTime: 0,
		gcTime: 0
	});
};
