import {useMutation} from '@tanstack/react-query';
import {fieldSettingsQueryKeys} from '../keys/index';
import {queryClient} from '@src/api/QueryClient';
import {requestDeleteFieldSettings} from '@/shared/api/fieldSettings/deleteFieldSettings';
import {addErrorToast} from '@tehzor/ui-components';
import {useTranslation} from 'react-i18next';

export const useDeleteFieldSettings = () => {
	const {t} = useTranslation();

	return useMutation({
		mutationKey: fieldSettingsQueryKeys.delete(),
		mutationFn: (settingsId: string) => requestDeleteFieldSettings(settingsId),
		onError: () => addErrorToast({title: t('toast.deleteFieldSettings.error.title')}),
		onSuccess: () => {
			void queryClient.invalidateQueries({queryKey: fieldSettingsQueryKeys.list()});
		}
	});
};
