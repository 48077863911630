import {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {IWorkAcceptanceStatus} from '@tehzor/tools/interfaces/workAcceptances/IWorkAcceptanceStatus';
import {useExtractWorkAcceptanceStatusesAsArray} from '@src/core/hooks/queries/workAcceptanceStatuses/hooks';

export const useTranslatedWorkAcceptanceStatuses = () => {
	const {t} = useTranslation();
	const {data: workAcceptanceStatuses} = useExtractWorkAcceptanceStatusesAsArray();

	return useMemo<IWorkAcceptanceStatus[]>(
		() =>
			workAcceptanceStatuses
				? workAcceptanceStatuses.map(status => ({
						...status,
						name: t(`workAcceptanceStatuses.${status.id}`)
				  }))
				: [],
		[workAcceptanceStatuses, t]
	);
};
