import {memo} from 'react';
import {DesktopEntityAdding} from './EntityAdding.desktop';
import {useProblemsPermissions} from '@src/core/hooks/permissions/useProblemsPermissions';
import {useInspectionsAddingPermissions} from '@src/core/hooks/permissions/useInspectionsAddingPermissions';
import {useOwnerAcceptancesPermissions} from '@src/core/hooks/permissions/useOwnerAcceptancesPermissions';

interface IDesktopActionsProps {
	objectId?: string;
}

export const ActionsDesktop = memo(({objectId}: IDesktopActionsProps) => {
	const permissions = useOwnerAcceptancesPermissions(objectId);
	const problemsPermissions = useProblemsPermissions(objectId);
	const inspectionsPermissions = useInspectionsAddingPermissions(objectId);

	if (
		!objectId ||
		!permissions.canAdd ||
		!(problemsPermissions.canAdd || inspectionsPermissions.canAdd)
	) {
		return null;
	}

	return <DesktopEntityAdding objectId={objectId} />;
});
