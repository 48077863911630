import {httpRequests} from '@tehzor/tools/api/httpRequests';
import getTimezone from '@tehzor/tools/utils/getTimezone';
import {IExportResponse} from '@tehzor/tools/interfaces/IExportResponse';
import getEmailsArray from '@src/utils/getEmailsArray';

export const makeSpacesExportRequest = async (
	templateId: string,
	objectId: string,
	createDocument?: boolean,
	email?: string
): Promise<IExportResponse> => {
	const params = {
		templateId,
		createDocument,
		timezone: getTimezone(),
		email: email ? getEmailsArray(email) : undefined,
		objectId
	};
	const response = await httpRequests.exportWithToken.post<IExportResponse>(
		'objects/export',
		params
	);

	return response.data;
};
