import './PresaleCheckInfoDesktopCell.less';
import {TableRowSelect} from '@tehzor/ui-components';
import ClickPreventWrap from '../ClickPreventWrap';
import {CellProps} from 'react-table';
import {IPresaleCheck} from '@tehzor/tools/interfaces/presaleChecks/IPresaleCheck';
import {PresaleCheckInfo} from '@src/components/tableCells/PresaleCheckInfo';

export const PresaleCheckInfoDesktopCell = ({row}: CellProps<{data: IPresaleCheck}>) => (
	<div className="d--presale-check-info-cell">
		<ClickPreventWrap>
			<TableRowSelect
				{...row.getToggleRowSelectedProps()}
				style={{marginTop: '12px'}}
			/>
		</ClickPreventWrap>

		<div className="d-check-info-cell__info">
			<PresaleCheckInfo data={row.original.data} />
		</div>
	</div>
);
