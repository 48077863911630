import {EntityTypeId} from '@tehzor/tools/interfaces/fields';
import {useFieldSettingsBySetIds} from '@/entities/FieldSettings';
import {useMemo} from 'react';
import {oneArgIsTrue} from '@/shared/utils/oneArgIsTrue';
import {useBuiltinFieldsByEntityTypeIdAsArray} from '@/entities/Fields';
import {prepareBuiltInField} from '../../utils/prepareBuiltInField';

export const usePreparedBuiltInFields = (setId: string, entityTypeId?: EntityTypeId) => {
	const {data: fields, isLoading: fieldsIsLoading} =
		useBuiltinFieldsByEntityTypeIdAsArray(entityTypeId);
	const {data: fieldsSettings, isLoading: fieldsSettingsIsLoading} = useFieldSettingsBySetIds([
		setId
	]);

	const data = useMemo(() => {
		if (!fields || !fieldsSettings) return undefined;
		return prepareBuiltInField(fields, fieldsSettings, setId);
	}, [fields, fieldsSettings, setId]);

	return {data, isLoading: oneArgIsTrue(fieldsIsLoading, fieldsSettingsIsLoading)};
};
