import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {createApiAction} from '@src/store/middlewares/api';
import {ICopyPlanResponse, makePlanCopyRequest} from '@src/api/backend/plan';
import {queryClient} from '@src/api/QueryClient';
import {plansQueryKeys} from '@src/api/cache/plans/keys';
import {addErrorToast} from '@tehzor/ui-components';
import {t} from 'i18next';

export interface ICopyPlanPayload extends ICopyPlanResponse {
	objectId: string;
}

const request = () => ({type: types.COPY_REQUEST});

const success = (response: ICopyPlanResponse, objectId: string) => {
	void queryClient.invalidateQueries({queryKey: plansQueryKeys.list([objectId])});
	return {
		type: types.COPY_SUCCESS,
		payload: {...response, objectId}
	};
};

const failure = (error: IError) => {
	if (error.statusCode !== 401) {
		addErrorToast({title: t('toast.copyPlan.error.title')});
	}
	return {
		type: types.COPY_FAILURE,
		payload: error
	};
};

/**
 * Копирует план
 *
 * @param objectId id объекта
 * @param planId данные плана
 * @param objectDestinationId id объекта назначения
 * @param copies количество копий
 */
export const copyPlan = (
	objectId: string,
	planId: string,
	objectDestinationId: string,
	copies: number
) =>
	createApiAction<ICopyPlanResponse>(
		request,
		res => {
			return success(res, objectDestinationId);
		},
		failure,
		() => makePlanCopyRequest(objectId, planId, objectDestinationId, copies)
	);
