import * as React from 'react';
import './MobileEntitiesFilters.less';
import EntitiesFiltersProvider from '../EntitiesFilters/utils/EntitiesFiltersProvider';

interface IBaseFiltersState extends Record<string, unknown> {
	objects?: string[];
}

interface IMobileEntitiesFiltersProps<S extends IBaseFiltersState> {
	children?: React.ReactNode;
	objectId?: string;
	filters: S;
	fullClear?: boolean;

	onApply: (filters: S) => void;
	onClear?: () => void;
}

const MobileEntitiesFilters = <S extends IBaseFiltersState>(
	props: IMobileEntitiesFiltersProps<S>
) => <EntitiesFiltersProvider {...props}>{props.children}</EntitiesFiltersProvider>;

export default MobileEntitiesFilters;
