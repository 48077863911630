import {useIsFetching, useQuery} from '@tanstack/react-query';
import IError from '@tehzor/tools/interfaces/IError';
import {checkItemsQueryKeys} from '@src/api/cache/checkItems/keys';
import {IGetCheckItemsResponse} from '@src/api/backend/checkItems';
import {
	extractCheckItemById,
	extractCheckItems,
	extractCheckItemsAsTree,
	extractCheckItemsByListIdsAsArray,
	extractCheckItemsGroupedByLists,
	extractCheckItemsIncludingDeleted,
	extractIndexedCheckItems,
	extractRootCheckItems,
	extractTreeCheckItemById,
	getCurrentCheckItemIndex,
	getSiblings
} from './selectors';
import {useCallback} from 'react';
import {ICheckItem} from '@tehzor/tools/interfaces/checkItems/ICheckItem';
import {useCheckItemsPermissions} from '@src/core/hooks/permissions/useCheckItemsPermissions';

export const useCheckItems = <T = IGetCheckItemsResponse>(
	select?: (data: IGetCheckItemsResponse) => T
) => {
	const permissions = useCheckItemsPermissions();
	return useQuery<IGetCheckItemsResponse, IError, T>({
		queryKey: checkItemsQueryKeys.list(),
		meta: {
			error: 'при загрузке списка категорий'
		},
		enabled: permissions.canView,
		select
	});
};

export const useCheckItemsIsLoading = () => !!useIsFetching({queryKey: checkItemsQueryKeys.list()});

export const useExtractCheckItemsGroupedByLists = () =>
	useCheckItems(extractCheckItemsGroupedByLists);

export const useExtractCheckItemsByListIdsAsArray = (checkListIds: string[]) => {
	const selector = useCallback(
		(data: IGetCheckItemsResponse) => extractCheckItemsByListIdsAsArray(data, checkListIds),
		[checkListIds]
	);
	return useCheckItems(selector);
};

export const useExtractCheckItems = (checkListId?: string) => {
	const selector = useCallback(
		(data: IGetCheckItemsResponse) => extractCheckItems(data, checkListId),
		[checkListId]
	);
	return useCheckItems(selector);
};

export const useExtractCheckItemsIncludingDeleted = (checkListId?: string) => {
	const selector = useCallback(
		(data: IGetCheckItemsResponse) => extractCheckItemsIncludingDeleted(data, checkListId),
		[checkListId]
	);
	return useCheckItems(selector);
};

export const useExtractRootCheckItems = (checkListId?: string) => {
	const selector = useCallback(
		(data: IGetCheckItemsResponse) => extractRootCheckItems(data, checkListId),
		[checkListId]
	);
	return useCheckItems(selector);
};

export const useExtractCheckItemsAsTree = (checkListId?: string) => {
	const selector = useCallback(
		(data: IGetCheckItemsResponse) => extractCheckItemsAsTree(data, checkListId),
		[checkListId]
	);
	return useCheckItems(selector);
};

export const useExtractCheckItemById = (checkItemId?: string) => {
	const selector = useCallback(
		(data: IGetCheckItemsResponse) => extractCheckItemById(data, checkItemId),
		[checkItemId]
	);
	return useCheckItems(selector);
};

export const useExtractTreeCheckItemById = (checkListId?: string, checkItemId?: string) => {
	const selector = useCallback(
		(data: IGetCheckItemsResponse) => extractTreeCheckItemById(data, checkListId, checkItemId),
		[checkListId, checkItemId]
	);
	return useCheckItems(selector);
};

export const useCurrentCheckItemIndex = (currentCheckItem?: ICheckItem) => {
	const selector = useCallback(
		(data: IGetCheckItemsResponse) => getCurrentCheckItemIndex(data, currentCheckItem),
		[currentCheckItem]
	);
	return useCheckItems(selector);
};

export const useIndexedCheckItems = (checkListId?: string) => {
	const selector = useCallback(
		(data: IGetCheckItemsResponse) => extractIndexedCheckItems(data, checkListId),
		[checkListId]
	);
	return useCheckItems(selector);
};
export const useGetSiblings = (checkItem?: ICheckItem) => {
	const selector = useCallback(
		(data: IGetCheckItemsResponse) => getSiblings(data, checkItem),
		[checkItem]
	);
	return useCheckItems(selector);
};
