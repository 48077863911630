import {useMemo} from 'react';
import {getPreparedBuiltInField, getPreparedCustomField, IPreparedField} from '@/features/Fields';
import {useFieldSettingsByFieldId} from '@/entities/FieldSettings';
import {useBuiltinFieldById, useCustomFieldById} from '@/entities/Fields';

interface IUsePreparedFieldParams {
	setId: string;
	fieldId: string;
	isCustom: boolean;
}

export const usePreparedField = ({
	setId,
	fieldId,
	isCustom
}: IUsePreparedFieldParams): IPreparedField | undefined => {
	const {data: customField} = useCustomFieldById(fieldId);
	const {data: builtInField} = useBuiltinFieldById(fieldId);
	const {data: fieldSettings} = useFieldSettingsByFieldId(fieldId);

	return useMemo(() => {
		if (isCustom && customField) {
			return getPreparedCustomField(setId, customField, fieldSettings);
		}
		if (builtInField) {
			return getPreparedBuiltInField(setId, builtInField, fieldSettings);
		}
		return undefined;
	}, [setId, customField, builtInField, fieldSettings, isCustom]);
};
