import {FilterButton} from '@tehzor/ui-components';
import {useIsBeforeTablet} from '@tehzor/ui-components/src/utils/mediaQueries';
import {ReactNode, useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {ViewButtonsTypeId} from '../types/ViewButtonsTypeId';
import {ISuggestions} from '@tehzor/tools/utils/responsibilityRules/ISuggestions';

/**
 * Кнопки для фильтрации списка ответственных
 *
 * @param suggestions предложения по отобранным с помощью правил группам и пользователям
 * @param isWorkingGroupTypeFilterAvailable флаг отображения кнопок для фильтрации по типам рабочих групп
 * @returns
 * 	Компонент кнопок
 *  Активный тип кнопки
 */
export function useViewButtons(
	suggestions: ISuggestions,
	isWorkingGroupTypeFilterAvailable: boolean
): [ReactNode, ViewButtonsTypeId] {
	const {t} = useTranslation();
	const [activeButtonsTypeId, setActiveButtonsType] = useState<ViewButtonsTypeId>(
		ViewButtonsTypeId.ALL
	);
	const hasSuggestions = useMemo(() => Object.keys(suggestions).length > 0, [suggestions]);

	useEffect(() => {
		if (hasSuggestions) {
			setActiveButtonsType(ViewButtonsTypeId.SUGGESTED);
		} else if (!hasSuggestions && activeButtonsTypeId === ViewButtonsTypeId.SUGGESTED) {
			setActiveButtonsType(ViewButtonsTypeId.ALL);
		}
	}, [hasSuggestions]);

	const showSuggested = () => {
		setActiveButtonsType(ViewButtonsTypeId.SUGGESTED);
	};
	const showPerformers = () => {
		setActiveButtonsType(ViewButtonsTypeId.PERFORMERS);
	};
	const showInspectors = () => {
		setActiveButtonsType(ViewButtonsTypeId.INSPECTORS);
	};
	const showAcceptors = () => {
		setActiveButtonsType(ViewButtonsTypeId.ACCEPTORS);
	};
	const showSubmitters = () => {
		setActiveButtonsType(ViewButtonsTypeId.SUBMITTERS);
	};
	const showAll = () => {
		setActiveButtonsType(ViewButtonsTypeId.ALL);
	};

	const isMobile = useIsBeforeTablet();

	const buttons = (
		<div className="delegation-dialog__view-buttons">
			{hasSuggestions && (
				<FilterButton
					className="delegation-dialog__view-button"
					label={
						isMobile
							? t('components.delegationDialog.ViewButtons.mobileSuggestions')
							: t('components.delegationDialog.ViewButtons.desktopSuggestions')
					}
					active={activeButtonsTypeId === ViewButtonsTypeId.SUGGESTED}
					onClick={showSuggested}
				/>
			)}
			<FilterButton
				className="delegation-dialog__view-button"
				label={t('components.delegationDialog.ViewButtons.all')}
				active={activeButtonsTypeId === ViewButtonsTypeId.ALL}
				onClick={showAll}
			/>
			{isWorkingGroupTypeFilterAvailable && (
				<>
					<FilterButton
						className="delegation-dialog__view-button"
						label={t('components.delegationDialog.ViewButtons.performers')}
						active={activeButtonsTypeId === ViewButtonsTypeId.PERFORMERS}
						onClick={showPerformers}
					/>

					<FilterButton
						className="delegation-dialog__view-button"
						label={t('components.delegationDialog.ViewButtons.problemInspectors')}
						active={activeButtonsTypeId === ViewButtonsTypeId.INSPECTORS}
						onClick={showInspectors}
					/>

					<FilterButton
						className="delegation-dialog__view-button"
						label={t('components.delegationDialog.ViewButtons.workAcceptanceAcceptors')}
						active={activeButtonsTypeId === ViewButtonsTypeId.ACCEPTORS}
						onClick={showAcceptors}
					/>

					<FilterButton
						className="delegation-dialog__view-button"
						label={t(
							'components.delegationDialog.ViewButtons.workAcceptanceSubmitters'
						)}
						active={activeButtonsTypeId === ViewButtonsTypeId.SUBMITTERS}
						onClick={showSubmitters}
					/>
				</>
			)}
		</div>
	);

	return [buttons, activeButtonsTypeId];
}
