import {
	IEditableEntityAction,
	IEditableEntityState,
	isEntityEdited
} from '@tehzor/tools/core/states/editableEntityState';
import {ISavingStructure} from '@tehzor/tools/interfaces/structures/ISavingStructure';
import {IStructure} from '@tehzor/tools/interfaces/structures/IStructure';

export type IEditableStructureState = IEditableEntityState<{
	name?: string;
	type?: string;
	planIds: string[];
	externalId?: string;
	floor?: string;
}>;

export type IEditableStructureAction = IEditableEntityAction<IEditableStructureState, IStructure>;

const makeEmptyState = (): IEditableStructureState => ({
	name: '',
	type: undefined,
	planIds: [],
	externalId: undefined,
	floor: '',
	errors: {
		name: false,
		type: false,
		planIds: false,
		externalId: false
	}
});

export const init = (structure?: IStructure): IEditableStructureState => {
	const empty = makeEmptyState();
	return structure
		? {
				name: structure.name || '',
				type: structure.type || undefined,
				planIds: structure.planIds || [],
				errors: empty.errors,
				externalId: structure.externalId || undefined,
				floor: structure.floor || ''
		  }
		: empty;
};

const isNameEdited = (state: IEditableStructureState, original?: IStructure) =>
	original?.name ? original.name !== state.name : !!state.name;

const isFloorEdited = (state: IEditableStructureState, original?: IStructure) =>
	original?.floor ? original.floor !== state.floor : !!state.floor;

const isTypeEdited = (state: IEditableStructureState, original?: IStructure) =>
	original?.type ? original.type !== state.type : !!state.type;

const isPlanIdsEdited = (state: IEditableStructureState, original?: IStructure) =>
	original?.planIds
		? original.planIds?.length !== state.planIds?.length ||
		  original.planIds?.some(id => !state.planIds?.includes(id))
		: !!state.planIds?.length;

const isExternalIdEdited = (state: IEditableStructureState, original?: IStructure) =>
	original?.externalId ? original.externalId !== state.externalId : !!state.externalId;
/**
 * Возвращает значение, показывающее были ли отредактированы поля нарушения
 *
 * @param state состояние
 * @param original изначальные данные
 */
export const isEdited = (state: IEditableStructureState, original?: IStructure): boolean =>
	isEntityEdited(
		state,
		original,
		isNameEdited,
		isTypeEdited,
		isPlanIdsEdited,
		isExternalIdEdited,
		isFloorEdited
	);

/**
 * Функции проверки полей на ошибки
 */
export const errorsFns = {
	name: (state: IEditableStructureState) => !state.name,
	type: (state: IEditableStructureState) => !state.type,
	planIds: (state: IEditableStructureState) => !state.planIds.length,
	externalId: (state: IEditableStructureState) => !state.externalId
};

/**
 * Конвертирует данные в формат, пригодный для отправки на сервер
 *
 * @param state состояние
 * @param original изначальные данные
 * @param onlyEdited возвращать только изменённые поля
 */
export const convertToSave = (
	state: IEditableStructureState,
	original?: IStructure,
	onlyEdited?: boolean
): ISavingStructure => {
	if (!onlyEdited) {
		return {
			name: state.name,
			type: state.type,
			externalId: state.externalId,
			floor: state.floor
		};
	}
	const structure: ISavingStructure = {};
	if (isNameEdited(state, original)) {
		structure.name = state.name;
	}
	if (isTypeEdited(state, original)) {
		structure.type = state.type;
	}
	if (isPlanIdsEdited(state, original)) {
		structure.planIds = state.planIds;
	}
	if (isExternalIdEdited(state, original)) {
		structure.externalId = state.externalId;
	}
	if (isFloorEdited(state, original)) {
		structure.floor = state.floor;
	}

	return structure;
};
