import {Toggle} from '@tehzor/ui-components';
import {IPreparedField, useFieldsIsUpdating} from '@/features/Fields';
import {CellContext} from '@tanstack/react-table';
import {
	useAddFieldSettings,
	useDeleteFieldSettings,
	useFieldSettingsBySetIds
} from '@/entities/FieldSettings';

export const FieldEnabledCell = ({row}: CellContext<IPreparedField, IPreparedField>) => {
	const field = row.original;

	const {mutateAsync: addFieldSettings} = useAddFieldSettings();
	const {mutateAsync: deleteFieldSettings} = useDeleteFieldSettings();
	const {data: settingsList} = useFieldSettingsBySetIds(field.setId ? [field.setId] : undefined);

	const {isUpdating} = useFieldsIsUpdating();

	const handleEnabledChange = async () => {
		if (isUpdating) return;
		if (field.settingsId) {
			await deleteFieldSettings(field.settingsId);
			return;
		}
		if (field.setId && settingsList) {
			await addFieldSettings({
				setId: field.setId,
				fieldId: field.id,
				order: settingsList.length,
				canBeCopied: field.canBeCopied || false
			});
		}
	};

	return (
		<Toggle
			checked={field.enabled}
			onChange={handleEnabledChange}
			disabled={isUpdating}
		/>
	);
};
