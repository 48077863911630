import {useCallback} from 'react';
import {InfiniteScroller} from '@tehzor/ui-components';
import IComment from '@tehzor/tools/interfaces/comments/IComment';
import classNames from 'classnames';
import CommentMenu from './CommentMenu';
import {useCommentDeleting} from '../hooks/useCommentDeleting';
import {useReplyExport} from '../hooks/useReplyExport';
import {convertClassNames} from '@tehzor/ui-components/src/utils/convertClassNames';
import {useTranslation} from 'react-i18next';
import {useCommentsCtx} from '../utils/CommentsCtx';
import {TranslatedComment} from '../../TranslatedComment/TranslatedComment';

interface IListProps {
	className?:
		| string
		| {
				root?: string;
				content?: string;
				item?: string;
		  };
	objectId: string;
	selectedId?: string;

	onLoad?: () => void;
	onSubCommentAdd?: (comment: IComment) => void;
	onCommentEdit: (comment: IComment) => void;
	onReplyEdit: (comment: IComment) => void;
	onImageClick?: (id: string, index: number) => void;
}

const List = (props: IListProps) => {
	const {
		className,
		objectId,
		selectedId,
		onLoad,
		onSubCommentAdd,
		onCommentEdit,
		onReplyEdit,
		onImageClick
	} = props;
	const {t} = useTranslation();
	const {comments, hasMore} = useCommentsCtx();
	const [deleteDialog, handleDelete] = useCommentDeleting();
	const [exportDialog, handleExport] = useReplyExport(objectId);

	const handleEdit = useCallback(
		(comment: IComment, official?: boolean | undefined) => {
			(official ? onReplyEdit : onCommentEdit)(comment);
		},
		[onCommentEdit, onReplyEdit]
	);

	const classes = convertClassNames(className);

	const menu = (comment: IComment) => (
		<CommentMenu
			comment={comment}
			onExport={handleExport}
			onEdit={handleEdit}
			onDelete={handleDelete}
		/>
	);
	return (
		<>
			<InfiniteScroller
				className={{
					root: classNames('comments__list', classes.root),
					content: classes.content
				}}
				loader={
					<div className="comments__loader">{t('components.problemComments.loader')}</div>
				}
				enabled={hasMore}
				reversed
				onLoad={onLoad}
			>
				{comments.length > 0
					? comments.map(item => (
							<TranslatedComment
								key={item.id}
								className={{plate: classes.item}}
								data={item}
								isSelected={id => id === selectedId}
								controls={menu}
								onSubCommentAdd={onSubCommentAdd}
								onImageClick={onImageClick}
							/>
					  ))
					: [
							<div
								key="empty"
								className={classNames('comments__empty-placeholder')}
							>
								<i className="tz-comment-88 comments__empty-placeholder-icon" />
								<br />
								<span className="comments__empty-placeholder-text">
									{t('components.problemComments.emptyPlaceholder.1')}
									<br />
									{t('components.problemComments.emptyPlaceholder.2')}
								</span>
							</div>
					  ]}
			</InfiniteScroller>

			{deleteDialog}
			{exportDialog}
		</>
	);
};

export default List;
