import {queryClient} from '@src/api/QueryClient';
import {
	IBindSpaceToOwnersResponse,
	makeBindSpaceToOwnersRequest
} from '@src/api/backend/spaceOwners';
import {spaceOwnersQueryKeys} from '@src/api/cache/spaceOwners/keys';
import {useMutation} from '@tanstack/react-query';
import IError from '@tehzor/tools/interfaces/IError';
import {addErrorToast} from '@tehzor/ui-components';
import {useTranslation} from 'react-i18next';

/**
 * Привязывает собственников к помещению
 *
 * @param owners id собственников
 * @param spaceId id помещения
 */
interface IBindSpaceOwnersParams {
	owners: string[];
	spaceId: string;
}

export const useBindSpaceOwners = () => {
	const {t} = useTranslation();

	return useMutation<IBindSpaceToOwnersResponse, IError, IBindSpaceOwnersParams>({
		mutationKey: spaceOwnersQueryKeys.bind(),
		mutationFn: params => {
			const {owners, spaceId} = params;
			return makeBindSpaceToOwnersRequest(owners, spaceId);
		},
		onError: () => {
			addErrorToast({title: t('toast.bindSpaceToOwners.error.title')});
		},
		onSuccess: () => {
			void queryClient.invalidateQueries({queryKey: spaceOwnersQueryKeys.list()});
		}
	});
};
