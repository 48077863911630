import {useMutation} from '@tanstack/react-query';
import {tasksQueryKeys} from '@src/api/cache/tasks/keys';
import {
	IMakeTaskResponsibleRequestData,
	makeTaskResponsible
} from '@src/api/backend/task/editResponsible';
import {addErrorToast} from '@tehzor/ui-components';
import {queryClient} from '@src/api/QueryClient';
import {delegationHistoryQueryKeys} from '@src/api/cache/delegationHistory/keys';
import {useTranslation} from 'react-i18next';

export const useEditTaskResponsible = () => {
	const {t} = useTranslation();

	return useMutation({
		mutationKey: tasksQueryKeys.edit(),
		mutationFn: (data: IMakeTaskResponsibleRequestData) => makeTaskResponsible(data),
		onError: () => addErrorToast({title: t('toast.editTaskResponsible.error.title')}),
		onSuccess: data => {
			queryClient.setQueryData(
				tasksQueryKeys.detail(data.id),
				(task: IMakeTaskResponsibleRequestData | undefined) =>
					task ? {...task, ...data} : data
			);
			void queryClient.invalidateQueries({queryKey: tasksQueryKeys.all()});
			void queryClient.invalidateQueries({
				queryKey: delegationHistoryQueryKeys.histories({
					objectId: data.objectId,
					taskId: data.id
				})
			});
		}
	});
};
