import {queryClient} from '@src/api/QueryClient';
import {IGetProblemTagsResponse, makeProblemTagEditRequest} from '@src/api/backend/problemTags';
import {problemTagsQueryKeys} from '@src/api/cache/problemTags/keys';
import {useMutation} from '@tanstack/react-query';
import IError from '@tehzor/tools/interfaces/IError';
import {IProblemTag} from '@tehzor/tools/interfaces/problemTagsSets/IProblemTag';
import {ISavingProblemTag} from '@tehzor/tools/interfaces/problemTagsSets/ISavingProblemTag';
import {addErrorToast, addSuccessToast} from '@tehzor/ui-components';
import {useTranslation} from 'react-i18next';

export const useEditProblemTag = () => {
	const {t} = useTranslation();

	return useMutation<IProblemTag, IError, ISavingProblemTag>({
		mutationFn: (params: ISavingProblemTag) => makeProblemTagEditRequest(params),
		mutationKey: problemTagsQueryKeys.edit(),
		onSuccess: data => {
			queryClient.setQueryData<IGetProblemTagsResponse>(
				problemTagsQueryKeys.list(),
				oldData => {
					if (oldData) {
						if (oldData.allIds.includes(data.id)) {
							return {
								allIds: oldData.allIds,
								byId: {...oldData.byId, [data.id]: data}
							};
						}
					}
					return oldData;
				}
			);
			addSuccessToast({title: t('toast.editProblemTag.success.title')});
		},
		onError: () => {
			addErrorToast({title: t('toast.editProblemTag.error.title')});
		}
	});
};
