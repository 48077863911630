import {createMutation} from 'react-query-kit';
import {IPresaleCheck} from '@tehzor/tools/interfaces/presaleChecks/IPresaleCheck';
import {presaleChecksQueryKeys} from '../../keys';
import {IDeletePresaleCheckParams} from '@/features/PresaleCheck';

export const useDeletePresaleCheckMutation = createMutation<
	IPresaleCheck,
	IDeletePresaleCheckParams
>({
	mutationKey: presaleChecksQueryKeys.delete(),
	meta: {
		entity: 'presaleCheck',
		type: 'removing'
	}
});
