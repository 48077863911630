import {CSSProperties, ReactNode, useMemo} from 'react';
import '../MobileFilter/MobileFilter.less';
import classNames from 'classnames';
import {LinkButton, MobileTreeFilterButton, TreeButtonCondition} from '../../buttons';
import {ErrorBoundary, ErrorMessage} from '../../containers';
import {ITreeDataItem} from '../../Tree';
import {makeTree} from '@tehzor/tools/utils/tree/makeTree';
import {ITwoWayTreeItem} from '@tehzor/tools/interfaces/ITwoWayTreeItem';
import {getAllTreeKeys} from '@tehzor/tools/utils/tree/getAllTreeKeys';

export interface IMobileTreeFilterProps<T extends ITreeDataItem> {
	className?: string;
	style?: CSSProperties;
	children?: ReactNode;
	label?: ReactNode;
	data?: T[];
	selected?: string[];
	fullSize?: boolean;
	search?: ReactNode;
	linkBtnLabel?: string;
	onChange?: (value: string[] | undefined) => void;
	onExpand?: () => void;
}

export const MobileTreeFilter = <T extends ITreeDataItem>({
	className,
	style,
	label,
	data,
	selected = [],
	children,
	fullSize,
	search,
	linkBtnLabel = 'Все',
	onChange,
	onExpand
}: IMobileTreeFilterProps<T>) => {
	const treeData = useMemo(() => makeTree(data || []), [data]);
	const handleClick = (item: ITwoWayTreeItem<T>, condition: TreeButtonCondition) => {
		if (!onChange) return;
		const value = getAllTreeKeys([item]);
		if (
			condition === TreeButtonCondition.ACTIVE ||
			condition === TreeButtonCondition.PARTIAL_ACTIVE
		) {
			onChange(selected?.filter(id => !value.includes(id)));
		} else {
			onChange([...selected, ...value]);
		}
	};

	return (
		<ErrorBoundary
			label="MobileFilter"
			component={
				<div className="mobile-filter">
					<ErrorMessage />
				</div>
			}
		>
			<div className={classNames('mobile-filter', className)} style={style}>
				<div className="mobile-filter__info">
					<div className="mobile-filter__label">{label}</div>
					{onExpand ? <LinkButton label={linkBtnLabel} onClick={onExpand} /> : null}
				</div>
				{search && <div className="mobile-filter__search">{search}</div>}
				{children ?? (
					<div
						className={classNames('mobile-filter__tabs', {
							'mobile-filter__tabs_fullsize': fullSize
						})}
					>
						{treeData.map(item => (
							<MobileTreeFilterButton
								item={item}
								selected={selected}
								onClick={handleClick}
								className="mobile-filter__item"
								key={item.id}
							/>
						))}
					</div>
				)}
			</div>
		</ErrorBoundary>
	);
};
