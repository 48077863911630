import {queryClient} from '@src/api/QueryClient';
import {IGetMetersResponse, makeMeterAddRequest} from '@src/api/backend/meters';
import {metersQueryKeys} from '@src/api/cache/meters/keys';
import {useMutation} from '@tanstack/react-query';
import IError from '@tehzor/tools/interfaces/IError';
import {IMeter} from '@tehzor/tools/interfaces/meters/IMeter';
import {ISavingMeter} from '@tehzor/tools/interfaces/meters/ISavingMeter';
import {useCallback} from 'react';
import {addErrorToast, addSuccessToast} from '@tehzor/ui-components';
import {useTranslation} from 'react-i18next';

export const useAddMeter = () => {
	const {t} = useTranslation();

	const mutation = useMutation<IMeter, IError, ISavingMeter>({
		mutationFn: (params: ISavingMeter) => makeMeterAddRequest(params),

		onSuccess: data => {
			queryClient.setQueryData<IGetMetersResponse>(
				metersQueryKeys.list(data.spaceId),
				oldData => {
					if (oldData) {
						return {
							byId: {...oldData.byId, [data.id]: data},
							allIds: [...oldData.allIds, data.id]
						};
					}
					return {
						byId: {[data.id]: data},
						allIds: [data.id]
					};
				}
			);
			addSuccessToast({title: t('toast.addMeter.success.title')});
		},
		onError: () => {
			addErrorToast({title: t('toast.addMeter.error.title')});
		}
	});
	/**
	 * Возвращает функцию для создания прибора учёта
	 *
	 * @param item данные нового прибора учёта
	 */
	return useCallback(
		(item: ISavingMeter) => {
			mutation.mutate(item);
		},
		[mutation]
	);
};
