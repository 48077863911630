import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {IAddProblemResponse} from '@src/api/backend/problem';
import {addErrorToast} from '@tehzor/ui-components';
import {t} from 'i18next';

export type IAddProblemPayload = IAddProblemResponse;

const request = () => ({type: types.ADD_REQUEST});

const success = (response: IAddProblemResponse) => {
	return {
		type: types.ADD_SUCCESS,
		payload: response
	};
};

const failure = (error: IError) => {
	addErrorToast({title: t('toast.addProblem.error.title')});
	return {
		type: types.ADD_FAILURE,
		payload: error
	};
};

export const addProblemActions = {request, success, failure};
