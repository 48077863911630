import {queryClient} from '@src/api/QueryClient';
import {makeSpaceTypeDecorationAddRequest} from '@src/api/backend/spaceTypeDecoration';
import {spaceTypesDecorationsQueryKeys} from '@src/api/cache/spaceTypeDecorations/keys';
import {useMutation} from '@tanstack/react-query';
import {ISavingSpaceTypeDecoration} from '@tehzor/tools/interfaces/spaceTypeDecorationSets/ISavingSpaceTypeDecoration';
import {addErrorToast, addSuccessToast} from '@tehzor/ui-components';
import {useTranslation} from 'react-i18next';

interface ISpaceTypeDecorationParams {
	typeDecorationSetId: string;
	items: ISavingSpaceTypeDecoration[];
}

export const useAddSpaceTypeDecoration = () => {
	const {t} = useTranslation();

	return useMutation({
		mutationKey: spaceTypesDecorationsQueryKeys.add(),
		mutationFn: (params: ISpaceTypeDecorationParams) =>
			makeSpaceTypeDecorationAddRequest(params.typeDecorationSetId, params.items),
		onSuccess: () => {
			void queryClient.invalidateQueries({queryKey: spaceTypesDecorationsQueryKeys.list()});
			void queryClient.invalidateQueries({
				queryKey: spaceTypesDecorationsQueryKeys.allById()
			});
			addSuccessToast({title: t('toast.addSpaceTypeDecoration.success.title')});
		},
		onError: () => {
			addErrorToast({title: t('toast.addSpaceTypeDecoration.error.title')});
		}
	});
};
