import IUser from '@tehzor/tools/interfaces/IUser';
import {getPermissionsForWorkAcceptance} from './getPermissionsForWorkAcceptance';
import {IListWorkAcceptance} from '@tehzor/tools/interfaces/workAcceptances/IListWorkAcceptance';
import {dateTimeCommaSeparatedFormat} from '@tehzor/tools/utils/dateFormats';
import {format} from 'date-fns';
import {IWorkAcceptanceType} from '@tehzor/tools/interfaces/workAcceptances/IWorkAcceptanceType';
import {IWorkAcceptanceFrontType} from '@tehzor/tools/interfaces/workAcceptances/IWorkAcceptanceFrontType';
import {IBriefUser} from '@tehzor/tools/interfaces/users/IBriefUser';
import {IWorkingGroup} from '@tehzor/tools/interfaces/workingGroups/IWorkingGroup';
import ICompany from '@tehzor/tools/interfaces/companies/ICompany';
import {IStructure} from '@tehzor/tools/interfaces/structures/IStructure';
import {ISpace} from '@tehzor/tools/interfaces/spaces/ISpace';
import {IPreparedWorkAcceptance} from '@src/interfaces/IPreparedWorkAcceptance';

/**
 * Преобразовывает приёмку работ в необходимый для вывода формат
 *
 * @param workAcceptances приёмки работ
 * @param networkStatus статус соединения
 * @param user текущий пользователь
 * @param usersMap
 * @param structuresMap
 * @param spacesMap
 * @param companiesMap
 * @param groupsMap
 */
export const convertWorkAcceptances = (
	workAcceptances: IListWorkAcceptance[],
	typesMap: Record<string, IWorkAcceptanceType> | undefined,
	frontTypesMap: Record<string, IWorkAcceptanceFrontType> | undefined,
	networkStatus: boolean,
	usersMap: Record<string, IBriefUser>,
	groupsMap: Record<string, IWorkingGroup>,
	companiesMap: Record<string, ICompany> | undefined,
	structuresMap: Record<string, IStructure> | undefined,
	spacesMap: Record<string, ISpace> | undefined,

	user?: IUser
): IPreparedWorkAcceptance[] =>
	workAcceptances.map(item => ({
		id: item.id,
		object: item.object,
		number: item.number,
		type: item.type && typesMap && {name: typesMap[item.type]?.name || '', id: item.type},
		frontType: item.frontType &&
			frontTypesMap && {
				name: frontTypesMap[item.frontType]?.name || '',
				id: item.frontType
			},
		percent: item.percent,
		physicalWorkScope: item.physicalWorkScope,
		description: item.description,
		status: item.status,
		stage: item.stage,
		structures:
			item.structureIds?.length && structuresMap
				? item.structureIds.map(id => structuresMap[id])
				: undefined,
		spaces:
			item.spaceIds?.length && spacesMap ? item.spaceIds.map(id => spacesMap[id]) : undefined,
		categoryId: item.categoryId,
		acceptors: item.acceptors?.length
			? item.acceptors?.map(id => usersMap[id]?.displayName)
			: undefined,
		acceptorsActiveGroup: item.acceptorsActiveGroup
			? groupsMap[item.acceptorsActiveGroup]?.name
			: undefined,
		submitters: item.submitters?.length
			? item.submitters?.map(id => usersMap[id]?.displayName)
			: undefined,
		submittersActiveGroup: item.submittersActiveGroup
			? groupsMap[item.submittersActiveGroup]?.name
			: undefined,
		company:
			companiesMap &&
			item.acceptorsActiveGroup &&
			groupsMap[item.acceptorsActiveGroup]?.companyId
				? companiesMap[groupsMap[item.acceptorsActiveGroup].companyId]?.name
				: undefined,
		acceptanceDateOriginal: item.acceptanceDate,
		acceptanceDate: item.acceptanceDate
			? format(item.acceptanceDate, dateTimeCommaSeparatedFormat)
			: '',
		createdAt: item.createdAt
			? format(new Date(item.createdAt), dateTimeCommaSeparatedFormat)
			: '',
		createdBy: item.createdBy?.fullName || '',
		modifiedAt: item.modifiedAt
			? format(new Date(item.modifiedAt), dateTimeCommaSeparatedFormat)
			: '',
		modifiedBy: item.modifiedBy?.fullName || '',
		...getPermissionsForWorkAcceptance(item, networkStatus, item.object?.id, user)
	}));
