import {useMutation} from '@tanstack/react-query';
import {IAddingRespUser} from '@tehzor/tools/interfaces/users/IAddingRespUser';
import {makeAddRespUserRequest} from '@src/api/backend/users';
import {respUsersQueryKeys} from '@src/api/cache/users/keys';
import {addErrorToast, addSuccessToast} from '@tehzor/ui-components';
import {useTranslation} from 'react-i18next';

// Добавляет нового ответственного

export const useAddRespUser = () => {
	const {t} = useTranslation();

	return useMutation({
		mutationKey: respUsersQueryKeys.addRespUser(),
		mutationFn: (fields: IAddingRespUser) => makeAddRespUserRequest(fields),
		onSuccess: () => {
			addSuccessToast({
				title: t('toast.addRespUser.success.title'),
				description: t('toast.addRespUser.success.description')
			});
		},
		onError: () => addErrorToast({title: t('toast.addRespUser.error.title')})
	});
};
