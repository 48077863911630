import {UseQueryOptions} from '@tanstack/react-query';
import IError from '@tehzor/tools/interfaces/IError';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractUserId} from '@src/store/modules/auth/profile/selectors';
import {useCallback} from 'react';
import {IGetPresaleChecksResponse} from '@/shared/api/presaleChecks/getPresaleChecks';
import {extractLatestPresaleCheckId} from '../selectors/extractLatestPresaleCheckId';
import {useFetchLatestUserPresaleChecks} from './useFetchLatestUserPresaleChecks';

export const useExtractLatestPresaleCheckId = (
	objectId: string,
	spaceId?: string,
	options?: UseQueryOptions<IGetPresaleChecksResponse, IError, string>
) => {
	const userId = useAppSelector(extractUserId);
	const selector = useCallback(
		(data: IGetPresaleChecksResponse) =>
			extractLatestPresaleCheckId(data, userId, objectId, spaceId),
		[userId, objectId, spaceId]
	);
	return useFetchLatestUserPresaleChecks(selector, options);
};
