import {useMutation} from '@tanstack/react-query';
import {fieldSettingsQueryKeys} from '../keys/index';
import {
	IAddFieldSettingsRequest,
	requestAddFieldSettings
} from '@/shared/api/fieldSettings/addFieldSettings';
import {queryClient} from '@src/api/QueryClient';
import {addErrorToast} from '@tehzor/ui-components';
import {useTranslation} from 'react-i18next';

export const useAddFieldSettings = () => {
	const {t} = useTranslation();

	return useMutation({
		mutationKey: fieldSettingsQueryKeys.add(),
		mutationFn: (data: IAddFieldSettingsRequest) => requestAddFieldSettings(data),
		onSuccess: () => {
			void queryClient.invalidateQueries({queryKey: fieldSettingsQueryKeys.list()});
		},
		onError: () => addErrorToast({title: t('toast.addFieldSettings.error.title')})
	});
};
