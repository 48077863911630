import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {createApiAction} from '@src/store/middlewares/api';
import {makeReplyDeleteRequest} from '@src/api/backend/reply';
import {IDeleteWorkAcceptanceCommentPayload} from '../../workAcceptanceComment/actions';
import {addErrorToast, addSuccessToast} from '@tehzor/ui-components';
import {t} from 'i18next';

const request = () => ({type: types.DELETE_REQUEST});

const success = (objectId: string, workAcceptanceId: string, commentId: string) => {
	addSuccessToast({title: t('toast.deleteWorkAcceptanceReply.success.title')});
	return {
		type: types.DELETE_SUCCESS,
		payload: {
			objectId,
			workAcceptanceId,
			commentId
		} as IDeleteWorkAcceptanceCommentPayload
	};
};

const failure = (error: IError) => {
	addErrorToast({title: t('toast.deleteWorkAcceptanceReply.error.title')});
	return {
		type: types.DELETE_FAILURE,
		payload: {...error}
	};
};

/**
 * Удаляет ответ на приемку работ
 *
 * @param objectId id объекта
 * @param workAcceptanceId id нарушения
 * @param commentId id комментария
 */
export const deleteWorkAcceptanceReply = (
	objectId: string,
	workAcceptanceId: string,
	commentId: string
) =>
	createApiAction(
		request,
		() => success(objectId, workAcceptanceId, commentId),
		failure,
		() => makeReplyDeleteRequest(objectId, commentId)
	);
