import {queryClient} from '@src/api/QueryClient';
import {IProblemTarget, makeProblemsMoveRequest} from '@src/api/backend/problem';
import {checksQueryKeys} from '@src/api/cache/checks/keys';
import {internalAcceptancesQueryKeys} from '@src/api/cache/internalAcceptances/keys';
import {problemsQueryKeys} from '@src/api/cache/problems/keys';
import {createApiAction} from '@src/store/middlewares/api';
import IError from '@tehzor/tools/interfaces/IError';
import * as types from '../constants';
import {addErrorToast, addSuccessToast} from '@tehzor/ui-components';
import {t} from 'i18next';

export interface IMoveProblemsPayload {
	objectId: string;
	problemId: string;
}

const request = () => ({type: types.MOVE_REQUEST});

const success = (objectId: string, problemId: string) => {
	addSuccessToast({title: t('toast.moveProblem.success.title')});
	return {
		type: types.MOVE_SUCCESS,
		payload: {objectId, problemId} as IMoveProblemsPayload
	};
};

const failure = (error: IError) => {
	addErrorToast({title: t('toast.moveProblem.error.title')});
	return {
		type: types.MOVE_FAILURE,
		payload: error
	};
};

/**
 * Изменяет нарушение
 *
 * @param objectId id объекта
 * @param target id целевой сущности
 * @param problemId id нарушения
 */
export const moveProblem = (objectId: string, target: IProblemTarget, problemId: string) =>
	createApiAction(
		request,
		() => {
			void queryClient.invalidateQueries({queryKey: problemsQueryKeys.detail(problemId)});
			void queryClient.invalidateQueries({queryKey: checksQueryKeys.problems()});
			void queryClient.invalidateQueries({queryKey: internalAcceptancesQueryKeys.problems()});
			return success(objectId, problemId);
		},
		failure,
		() => makeProblemsMoveRequest(objectId, target, problemId)
	);
