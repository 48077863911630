import {onlineManager, useQueryClient} from '@tanstack/react-query';
import useAppSelector from '../../useAppSelector';
import {toInternalAcceptance, toListInternalAcceptance} from './utils/convertToLocalSave';
import {useAddInternalAcceptanceMutation} from '@src/api/cache/internalAcceptances/mutations';
import {IAddInternalAcceptanceParams} from '@src/api/cache/internalAcceptances/defaults/useInternalAcceptancesMutationDefaults';
import {internalAcceptancesQueryKeys} from '@src/api/cache/internalAcceptances/keys';
import {IObject} from '@tehzor/tools/interfaces/objects/IObject';
import {useNextLocalNumber} from '../../useNextLocalNumber';
import {IListInternalAcceptance} from '@tehzor/tools/interfaces/internalAcceptances/IListInternalAcceptance';
import {useLocalInternalAcceptances} from '../../queries/internalAcceptances/hooks';
import {addNewEntityToNormalizedData} from '@src/utils/addNewEntityToNormalizedData';
import {IGetInternalAcceptancesResponse} from '@src/api/backend/internalAcceptances';
import {IInternalAcceptance} from '@tehzor/tools/interfaces/internalAcceptances/IInternalAcceptance';
import {extractOfflineModeAvailability} from '@src/store/modules/settings/offlineMode/selectors/availability';
import {addWarningToast} from '@tehzor/ui-components';
import {useTranslation} from 'react-i18next';

export const useAddInternalAcceptance = (object?: IObject) => {
	const {t} = useTranslation();
	const queryClient = useQueryClient();
	const online = onlineManager.isOnline();
	const isOfflineModeAvailable = useAppSelector(extractOfflineModeAvailability);
	const {data: localInternalAcceptances} = useLocalInternalAcceptances();
	const user = useAppSelector(s => s.auth.profile);
	const nextNumber = useNextLocalNumber<IListInternalAcceptance>(
		localInternalAcceptances,
		'createdAt'
	);
	const mutation = useAddInternalAcceptanceMutation();

	return ({objectId, links, stage}: Omit<IAddInternalAcceptanceParams, 'key'>) => {
		const key = Date.now().toString(10);
		if (links?.checkRecordId && links?.checkRecordId.startsWith('local')) {
			links.checkRecordId = undefined;
		}
		const converted = toInternalAcceptance(user, objectId, links, stage, key, nextNumber);
		const convertedToList = object
			? toListInternalAcceptance(object, user, links, stage, key, nextNumber)
			: undefined;

		queryClient.setQueryData<IListInternalAcceptance | undefined>(
			[...internalAcceptancesQueryKeys.detail(key), objectId],
			convertedToList
		);
		if (!online && isOfflineModeAvailable) {
			addWarningToast({
				title: t('toast.addInternalAcceptanceLocal.warning.title')
			});
			void queryClient.refetchQueries({
				queryKey: internalAcceptancesQueryKeys.localList()
			});
			queryClient.setQueryData<IGetInternalAcceptancesResponse>(
				internalAcceptancesQueryKeys.latest(),
				oldData => {
					if (!oldData) return undefined;
					return addNewEntityToNormalizedData<
						IGetInternalAcceptancesResponse,
						IInternalAcceptance
					>(oldData, converted);
				}
			);
		}
		mutation.mutate({
			objectId,
			links,
			stage,
			key
		});
		return converted;
	};
};
