import {tasksQueryKeys} from '@src/api/cache/tasks/keys';
import {ISavingTask} from '@src/interfaces/saving/ISavingTask';
import {makeTaskAddRequest} from '@src/api/backend/task';
import {useMutation} from '@tanstack/react-query';
import {queryClient} from '@src/api/QueryClient';
import {addErrorToast, addSuccessToast} from '@tehzor/ui-components';
import {useTranslation} from 'react-i18next';

export const useAddTask = () => {
	const {t} = useTranslation();
	return useMutation({
		mutationKey: tasksQueryKeys.add(),
		mutationFn: (fields: ISavingTask) => makeTaskAddRequest(fields),
		onError: () => addErrorToast({title: t('toast.addTask.error.title')}),
		onSuccess: () => {
			void queryClient.invalidateQueries({queryKey: tasksQueryKeys.all()});
			addSuccessToast({
				title: t('toast.addTask.success.title')
			});
		}
	});
};
