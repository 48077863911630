import {IEntityProblemsData} from '@tehzor/tools/interfaces/entititesComputedData/IEntityProblemsData';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {ProcessIds} from '@tehzor/tools/interfaces/process/ProcessId';

/**
 * Возвращает количества нарушений по статусам для текущей стадии, если стадия не указана, то по всем стадиям
 *
 * @param problems данные по нарушениям из структуры
 * @param stage текущая стадия
 * @param processId текущий process
 */
export const getProblemsDataByProcess = (
	problems: IEntityProblemsData['problems'],
	stage?: ObjectStageIds,
	processId?: ProcessIds
): Record<string, {count: number; critical: boolean}> | undefined => {
	if (stage && processId) {
		return problems[stage]?.[processId];
	}

	return Object.values(problems).reduce<Record<string, {count: number; critical: boolean}>>(
		(prev, stageItem) => {
			for (const processItem of Object.values(stageItem)) {
				for (const status in processItem) {
					if (processItem.hasOwnProperty(status)) {
						if (prev[status]) {
							prev[status].count += processItem[status].count;
							prev[status].critical =
								prev[status].critical || processItem[status].critical;
						} else {
							prev = {
								...prev,
								[status]: {
									count: processItem[status].count,
									critical: processItem[status].critical
								}
							};
						}
					}
				}
			}
			return prev;
		},
		{}
	);
};
