import {BaseToast, closeToast, LinkButton, ToastsContainersIds} from '@tehzor/ui-components';
import {useChangePath} from '@src/core/hooks/useChangePath';

export interface ILinkToastProps {
	toastId: string | number;
	title: string;
	description?: string;
	link?: string;
}

export const LinkToast = ({toastId, title, description, link}: ILinkToastProps) => {
	const {pushPath} = useChangePath();

	const linkClickHandler = () => {
		pushPath(link);
		closeToast({containerId: ToastsContainersIds.MAIN_STACKED, id: toastId});
	};

	return (
		<BaseToast
			title={title}
			description={description}
			links={
				link && (
					<LinkButton
						onClick={linkClickHandler}
						label="Открыть"
					/>
				)
			}
		/>
	);
};
