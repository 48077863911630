import {httpRequests} from '@tehzor/tools/api/httpRequests';
import {IFieldSetting} from '@tehzor/tools/interfaces/fields';

export interface IEditFieldSettingsRequest {
	settingId: string;
	required?: boolean;
	copyable?: boolean;
	order?: number;
}

export const requestEditFieldSettings = async (data: IEditFieldSettingsRequest) => {
	const res = await httpRequests.withToken.patch<IFieldSetting>('field-settings', data);
	return res.data;
};
