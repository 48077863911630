import {useChecksPermissions} from '@src/core/hooks/permissions/useChecksPermissions';
import {useProblemsPermissions} from '@src/core/hooks/permissions/useProblemsPermissions';
import {useInspectionsAddingPermissions} from '@src/core/hooks/permissions/useInspectionsAddingPermissions';
import {DesktopCreateCheck} from './CreateCheck.desktop';
import {IEnrichedTask} from '@tehzor/tools/interfaces/tasks/IEnrichedTask';
import {TaskTypeId} from '@tehzor/tools/interfaces/tasks/ITaskType';

interface IDesktopActionsProps {
	task?: IEnrichedTask;
}

export const ActionsDesktop = ({task}: IDesktopActionsProps) => {
	const permissions = useChecksPermissions(task?.objectId);
	const problemsPermissions = useProblemsPermissions(task?.objectId);
	const inspectionsPermissions = useInspectionsAddingPermissions(task?.objectId);

	if (task === undefined) return null;

	return (
		<>
			{task.taskType?.id === TaskTypeId.AUDIT &&
				permissions.canAdd &&
				(problemsPermissions.canAdd || inspectionsPermissions.canAdd) && (
					<DesktopCreateCheck task={task} />
				)}
		</>
	);
};
