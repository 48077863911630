import {useQuery} from '@tanstack/react-query';
import {fieldSettingsSetsQueryKeys} from '../../keys';
import {IFieldSettingsSet} from '@tehzor/tools/interfaces/fields';
import {useFieldsPermissions} from '@/shared/hooks/permissions/useFieldsPermissions';

interface IUseFieldsSettingsSetParams<T = IFieldSettingsSet> {
	setId?: string;
	select?: (data: IFieldSettingsSet) => T;
}

export const useFieldsSettingsSet = ({setId, select}: IUseFieldsSettingsSetParams) => {
	const permissions = useFieldsPermissions();
	return useQuery({
		queryKey: [...fieldSettingsSetsQueryKeys.details(), setId],
		meta: {
			error: 'при загрузке набора настроек полей'
		},
		select,
		enabled: !!setId && permissions.canViewSettingsSets
	});
};
