import {FieldsSettingsSetsTable} from '@/widgets/FieldsSettingsSetsTable';
import {PageCounter} from '@/shared/ui/PageCounter/PageCounter';
import {useFieldSettingsSetsArray} from '@src/core/hooks/queries/fieldSettingsSets/hooks';
import {useAppHeader} from '@src/components/AppHeader/hooks/useAppHeader';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractFieldsSettingsSetsPageSettings} from '@/entities/FieldsSettingsSets';
import {ClearSelectionRowsButton} from '../components/ClearSelectionRowsButton/ClearSelectionRowsButton';
import styles from './FieldsSettingsSetsPage.module.less';
import {fieldsPagesLinks} from '@/shared/constants/fieldsPages/fieldsPagesLinks';
import {useIsDesktop} from '@/shared/hooks/dimensions/useIsDesktop/useIsDesktop';
import {FloatingActionButton} from '@tehzor/ui-components';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {DeleteSettingsSetsButton} from '@/features/FieldsSettingsSets';

const iconPlus = <i className="tz-plus-16" />;

export const FieldSettingsSetsPage = () => {
	const isDesktop = useIsDesktop();
	const {data: fieldsArray} = useFieldSettingsSetsArray();
	const {selectedRows} = useAppSelector(extractFieldsSettingsSetsPageSettings);
	const {pushPath} = useChangePath();
	const toSettingsSet = () => {
		pushPath('/manage/fields/fields-settings-set/add');
	};

	useAppHeader(
		{
			title: 'Поля',
			sectionsMenu: !isDesktop ? fieldsPagesLinks : undefined,
			mobileLeftButton: selectedRows.length ? <ClearSelectionRowsButton /> : undefined,
			mobileRightButtons: selectedRows.length ? <DeleteSettingsSetsButton /> : undefined
		},
		[selectedRows, isDesktop]
	);

	return (
		<>
			<div className={styles.counterContainer}>
				<PageCounter content={`Всего: ${fieldsArray ? fieldsArray.length : ''}`} />
			</div>
			<FieldsSettingsSetsTable />
			{!isDesktop && (
				<FloatingActionButton
					icon={iconPlus}
					label="Добавить"
					minifyOnScroll
					scrollContainer="scroll-container"
					onClick={toSettingsSet}
				/>
			)}
		</>
	);
};
