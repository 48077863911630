import {useEditingFieldsSettingsSetState} from './context/hook';
import {Fields} from './Fields/Fields';
import {IFieldSettingsSet} from '@tehzor/tools/interfaces/fields';
import {useCallback} from 'react';
import {hasErrors} from '@tehzor/tools/core/states/editableEntityState';
import {convertFieldsSettingsSetToSave, errorsFns, isEdited} from './context/formState';
import {Button} from '@tehzor/ui-components';
import {oneArgIsTrue} from '@/shared/utils/oneArgIsTrue';
import styles from './FormEditFieldsSettingsSet.module.less';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {useAddFieldsSettingsSet, useEditFieldsSettingsSet} from '@/entities/FieldsSettingsSets';

const fieldsSettings = {
	name: {fieldId: 'name', isRequired: true},
	description: {fieldId: 'description', isRequired: false},
	companyId: {fieldId: 'companyId', isRequired: true},
	objectIds: {fieldId: 'objectIds', isRequired: false},
	stageIds: {fieldId: 'objectIds', isRequired: true},
	entityTypeId: {fieldId: 'entityTypeId', isRequired: true}
};

interface IFormEditFieldsSettingsSetProps {
	fieldsSettingsSet?: IFieldSettingsSet;
}

export const FormEditFieldsSettingsSet = ({fieldsSettingsSet}: IFormEditFieldsSettingsSetProps) => {
	const {pushPath} = useChangePath();
	const [editingState, editingDispatch] = useEditingFieldsSettingsSetState(fieldsSettingsSet);
	const {mutateAsync: editFieldsSettingsSet, isPending: editPending} = useEditFieldsSettingsSet();
	const {mutateAsync: addFieldsSettingsSet, isPending: addPending} = useAddFieldsSettingsSet();

	const saving = oneArgIsTrue(editPending, addPending);

	const getSavingData = useCallback(() => {
		if (hasErrors(editingState, errorsFns, fieldsSettings)) {
			editingDispatch({type: 'update-errors'});
			return undefined;
		}
		if (!isEdited(editingState, fieldsSettingsSet)) {
			return undefined;
		}

		return convertFieldsSettingsSetToSave(editingState, fieldsSettingsSet);
	}, [editingDispatch, editingState, fieldsSettingsSet]);

	const handleSave = useCallback(async () => {
		const savingData = getSavingData();
		if (savingData) {
			if (fieldsSettingsSet) {
				await editFieldsSettingsSet({
					setId: fieldsSettingsSet.id,
					fields: savingData
				});
			} else {
				const settingsSet = await addFieldsSettingsSet(savingData);
				pushPath(`/manage/fields/fields-settings-set/${settingsSet.id}`);
			}
		}
	}, [addFieldsSettingsSet, editFieldsSettingsSet, fieldsSettingsSet, getSavingData]);

	const reset = useCallback(() => {
		editingDispatch({
			type: 'reset',
			entity: fieldsSettingsSet
		});
	}, [editingDispatch, fieldsSettingsSet]);

	return (
		<div className={styles.wrapper}>
			<div className={styles.container}>
				<Fields
					fieldsSettingsSet={fieldsSettingsSet}
					fieldsSettings={fieldsSettings}
					editingDispatch={editingDispatch}
					editingState={editingState}
					saving={saving}
				/>
			</div>
			<div className={styles.bar}>
				<Button
					type="accent-blue"
					label="Сохранить"
					disabled={
						saving ||
						(fieldsSettingsSet
							? !isEdited(editingState, fieldsSettingsSet) ||
							  hasErrors(editingState, errorsFns, fieldsSettings)
							: hasErrors(editingState, errorsFns, fieldsSettings))
					}
					onClick={handleSave}
				/>

				<Button
					type="cancel"
					label="Отменить"
					onClick={reset}
					disabled={!isEdited(editingState, fieldsSettingsSet)}
				/>
			</div>
		</div>
	);
};
