import classNames from 'classnames';
import styles from './FilterMenuLabel.module.less';

interface ProcessVisibilityLabelProps {
	fieldLabel: string;
	selectedOption: string;
	disabled?: boolean;
}

export const FilterMenuLabel = ({
	fieldLabel,
	selectedOption,
	disabled
}: ProcessVisibilityLabelProps) => (
	<p className={classNames(styles.wrapper, {[styles.disabled]: disabled})}>
		<span className={styles.fieldLabel}>{fieldLabel}: </span>
		<span className={styles.selectedOption}>{selectedOption}</span>
	</p>
);
