import {queryClient} from '@src/api/QueryClient';
import {IOfflineJournalEntity} from '../interfaces/IOfflineJournalEntity';
import {findMutation} from './findMutation';
import IError from '@tehzor/tools/interfaces/IError';
import {IPresaleCheck} from '@tehzor/tools/interfaces/presaleChecks/IPresaleCheck';
import {presaleChecksQueryKeys} from '@/entities/PresaleCheck';
import {IAddPresaleCheckParams} from '@/features/PresaleCheck/model/cache/defaults/usePresaleChecksMutationDefaults';

export const transferPresaleCheck = (entity: IOfflineJournalEntity['entity']) => {
	const {entityId} = entity;
	const mutationCache = queryClient.getMutationCache();
	const [[, cachedData]] = queryClient.getQueriesData<IPresaleCheck>({
		queryKey: presaleChecksQueryKeys.detail(entityId)
	});

	if (cachedData) {
		const {objectId, links, id} = cachedData;
		const currentMutation = findMutation(id);
		if (currentMutation) {
			void currentMutation.execute({objectId, links, key: id});
			return;
		}
		const mutation = mutationCache.build<unknown, IError, IAddPresaleCheckParams, unknown>(
			queryClient,
			{
				mutationKey: presaleChecksQueryKeys.add()
			}
		);
		void mutation.execute({objectId, links, key: id});
	}
};
