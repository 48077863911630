import {useMutation} from '@tanstack/react-query';
import {queryClient} from '@src/api/QueryClient';
import {problemTagsSetsQueryKeys} from '@src/api/cache/problemTagsSets/keys';
import {ISavingProblemTagsSet} from '@tehzor/tools/interfaces/problemTagsSets/ISavingProblemTagsSet';
import {makeProblemTagsSetEditRequest} from '@src/api/backend/problemTagsSet';
import {addErrorToast} from '@tehzor/ui-components';
import {useTranslation} from 'react-i18next';

interface IEditSpaceStatusesSetsParams {
	setId: string;
	fields: ISavingProblemTagsSet;
}

export const useEditProblemTagsSet = () => {
	const {t} = useTranslation();

	return useMutation({
		mutationKey: problemTagsSetsQueryKeys.add(),
		mutationFn: (params: IEditSpaceStatusesSetsParams) => {
			const {fields, setId} = params;
			return makeProblemTagsSetEditRequest(setId, fields);
		},
		onError: () => {
			addErrorToast({title: t('toast.editProblemTagsSe.error.title')});
		},
		onSuccess: () => {
			void queryClient.invalidateQueries({queryKey: problemTagsSetsQueryKeys.list()});
		}
	});
};
