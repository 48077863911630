import {useMutation} from '@tanstack/react-query';
import {queryClient} from '@src/api/QueryClient';
import {problemTagsSetsQueryKeys} from '@src/api/cache/problemTagsSets/keys';
import {ISavingProblemTagsSet} from '@tehzor/tools/interfaces/problemTagsSets/ISavingProblemTagsSet';
import {makeProblemTagsSetAddRequest} from '@src/api/backend/problemTagsSet';
import {addErrorToast} from '@tehzor/ui-components';
import {useTranslation} from 'react-i18next';

export const useAddProblemTagsSet = () => {
	const {t} = useTranslation();

	return useMutation({
		mutationKey: problemTagsSetsQueryKeys.add(),
		mutationFn: (fields: ISavingProblemTagsSet) => makeProblemTagsSetAddRequest(fields),
		onError: () => {
			addErrorToast({title: t('toast.addProblemTagsSet.error.title')});
		},
		onSuccess: () => {
			void queryClient.invalidateQueries({queryKey: problemTagsSetsQueryKeys.list()});
		}
	});
};
