import {makeTreeDataSpaceTypeDecoration} from '@src/components/editableFields/SpaceTypeDecorationTreeSelect/utils/makeTreeData';
import {useSpaceTypeDecorationsForSetsAsArray} from '@src/core/hooks/queries/spaceTypeDecorations/hooks';
import {useExtractSpaceTypeDecorationSetsForObjectAsArray} from '@src/core/hooks/queries/SpaceTypeDecorationSets/hooks';
import {useMemo} from 'react';

export const useTreeDataSpaceTypes = (objectId: string) => {
	const {data: typeDecorationSets} = useExtractSpaceTypeDecorationSetsForObjectAsArray(objectId);

	const typeDecorationSetIds = useMemo(
		() => typeDecorationSets?.map(set => set.id) || [],
		[typeDecorationSets]
	);

	const {data: typeDecorationsArray} =
		useSpaceTypeDecorationsForSetsAsArray(typeDecorationSetIds);

	const treeData = useMemo(() => {
		if (!typeDecorationsArray || !typeDecorationSets) {
			return [];
		}
		return makeTreeDataSpaceTypeDecoration(typeDecorationsArray, typeDecorationSets, true);
	}, [typeDecorationsArray, typeDecorationSets]);

	return treeData;
};
