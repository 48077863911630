import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {IEditProblemResponse} from '@src/api/backend/problem';
import {addErrorToast, addWarningToast} from '@tehzor/ui-components';
import {t} from 'i18next';

export type IEditProblemPayload = IEditProblemResponse;

const request = () => ({type: types.EDIT_REQUEST});

const optimistic = (response: IEditProblemResponse) => {
	addWarningToast({title: t('toast.addProblemLocal.warning.title')});
	return {
		type: types.EDIT_SUCCESS,
		payload: response
	};
};

const success = (response: IEditProblemResponse) => ({
	type: types.EDIT_SUCCESS,
	payload: response
});

const failure = (error: IError) => {
	addErrorToast({title: t('toast.editProblem.error.title')});
	return {
		type: types.EDIT_FAILURE,
		payload: error
	};
};

export const editProblemActions = {request, optimistic, success, failure};
