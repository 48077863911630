import styles from './NameCell.module.less';
import {IPreparedField} from '@/features/Fields';
import {CellProps} from 'react-table';

export const NameCell = ({row}: CellProps<IPreparedField>) => (
	<div className={styles.wrapper}>
		{row.original.enabled && <p className={styles.number}>{row.original.order}.</p>}
		<p className={styles.name}>{row.original.name}</p>
	</div>
);
