import {IGetFieldValueTypesResponse} from '@src/api/backend/fieldTypes';
import {useFieldsPermissions} from '@/shared/hooks/permissions/useFieldsPermissions';
import {useQuery} from '@tanstack/react-query';
import IError from '@tehzor/tools/interfaces/IError';
import {fieldsQueryKeys} from '../../../keys/index';

export const useFieldValueTypes = <T = IGetFieldValueTypesResponse>(
	select?: (data: IGetFieldValueTypesResponse) => T
) => {
	const {canViewFields} = useFieldsPermissions();

	return useQuery<IGetFieldValueTypesResponse, IError, T>({
		queryKey: fieldsQueryKeys.types.valueTypes(),
		meta: {
			error: 'при загрузке типов значений для полей'
		},
		select,
		enabled: canViewFields
	});
};
