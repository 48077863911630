import {useCallback, useMemo} from 'react';
import {TabLink, Tabs} from '@tehzor/ui-components';
import useAppSelector from '@src/core/hooks/useAppSelector';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {useCheckListPermissions} from '@src/core/hooks/permissions/useCheckListPermissions';
import {useTranslation} from 'react-i18next';
import styles from './StructuresDesktopSchemaViewTabs.module.less';
import {StructuresSchemaVariants} from '@/shared/types/StructuresSchemaVariants';
import {extractStructuresSchemaPageSettings} from '@/entities/Structures/model/slice/selectors';
import {structuresActions} from '@/entities/Structures';

const tabsIndexes = {
	[StructuresSchemaVariants.WORK_ACCEPTANCES]: 0,
	[StructuresSchemaVariants.CHECK_LISTS]: 1
};
const tabsKeys = [StructuresSchemaVariants.WORK_ACCEPTANCES, StructuresSchemaVariants.CHECK_LISTS];

interface IDesktopSchemaViewTabsProps {
	objectId: string;
}

export const StructuresDesktopSchemaViewTabs = ({objectId}: IDesktopSchemaViewTabsProps) => {
	const {t} = useTranslation();

	const {schemaView} = useAppSelector(s => extractStructuresSchemaPageSettings(s, objectId));
	const {changeSchemaView} = structuresActions;
	const dispatch = useAppDispatch();
	const permissions = useCheckListPermissions(objectId);

	const handleChange = useCallback(
		(index: number) => dispatch(changeSchemaView({objectId, schemaView: tabsKeys[index]})),
		[objectId, tabsKeys]
	);

	const tabs = useMemo(
		() => [<TabLink label={t('structuresPage.table.columns.workAcceptances.header')} />],
		[t]
	);

	return permissions.canView ? (
		<Tabs
			className={styles.wrapper}
			links={tabs}
			activeTab={tabsIndexes[schemaView]}
			onActiveTabChange={handleChange}
		/>
	) : null;
};
