import {IGetListInspectionsResponse} from '@src/api/backend/inspections';
import {PresaleCheckEntitiesVisibility} from '../../../../slice/pageSettings/interfaces';
import {extractPresaleCheckInspectionsArray} from './extractPresaleCheckInspectionsArray';

export const extractPresaleCheckInspectionsEntity = (
	data: IGetListInspectionsResponse,
	visibility: PresaleCheckEntitiesVisibility[]
) => {
	const inspections = extractPresaleCheckInspectionsArray(data);
	if (visibility.includes('inspections') && inspections?.length) {
		return inspections.map(item => ({
			id: `i_${item.id}`,
			type: 'inspection',
			data: item
		}));
	}
	return [];
};
