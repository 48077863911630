import {useCallback} from 'react';
import {useDeleteCustomFiled} from '@/entities/Fields';
import {useTranslation} from 'react-i18next';
import {useTranslatedConfirmDialog} from '@src/core/hooks/translations/useTranslatedConfirmDialog';

export const useDeleteCustomFieldsDialog = (setIds: string[]) => {
	const {t} = useTranslation();
	const {mutateAsync: deleteCustomField} = useDeleteCustomFiled();
	const [deleteDialog, getDeleteConfirmation] = useTranslatedConfirmDialog({
		title: t('useConfirmDialog.customFields.deleteTitle'),
		message: t('useConfirmDialog.customFields.deleteMessage')
	});

	const handleDelete = useCallback(async () => {
		const deletedFieldsIds = [];
		if (await getDeleteConfirmation()) {
			for (const fieldId of setIds) {
				const deletedField = await deleteCustomField(fieldId);
				deletedFieldsIds.push(deletedField.id);
			}
		}
		return deletedFieldsIds;
	}, [setIds, getDeleteConfirmation, deleteCustomField]);

	return {deleteDialog, handleDelete};
};
