import {useStrictParams} from '@src/core/hooks/useStrictParams';
import {NestedObjectsFilterPage} from './NestedObjectsFilterPage';
import {ObjectsFilterPage} from './ObjectsFilterPage';

export const ObjectsFilterRoute = () => {
	const {objectId} = useStrictParams<{objectId?: string}>();
	if (objectId) {
		return <NestedObjectsFilterPage />;
	}

	return <ObjectsFilterPage />;
};
