import {IGetOwnerAcceptancesResponse} from '@src/api/backend/ownerAcceptances';
import {ownerAcceptancesQueryKeys} from '@src/api/cache/ownerAcceptances/keys';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {useQuery} from '@tanstack/react-query';
import IError from '@tehzor/tools/interfaces/IError';
import {useCallback} from 'react';
import {extractLatestOwnerAcceptanceId, extractLatestOwnerAcceptancesAsArray} from '../selectors';
import {extractUserId} from '@src/store/modules/auth/profile/selectors';
import {useOwnerAcceptancesPermissions} from '@src/core/hooks/permissions/useOwnerAcceptancesPermissions';

export const useLatestOwnerAcceptances = <T = IGetOwnerAcceptancesResponse>(
	select?: (data: IGetOwnerAcceptancesResponse) => T
) => {
	const permissions = useOwnerAcceptancesPermissions();

	return useQuery<IGetOwnerAcceptancesResponse, IError, T>({
		queryKey: ownerAcceptancesQueryKeys.latest(),
		meta: {
			error: 'при загрузке последних передач собственнику'
		},
		enabled: permissions.canView,
		select
	});
};

export const useLatestOwnerAcceptancesAsArray = (objectId?: string, spaceId?: string) => {
	const userId = useAppSelector(extractUserId);

	const selector = useCallback(
		(data: IGetOwnerAcceptancesResponse) =>
			extractLatestOwnerAcceptancesAsArray(data, userId, objectId, spaceId),
		[userId, objectId, spaceId]
	);

	return useLatestOwnerAcceptances(selector);
};

export const useLatestOwnerAcceptanceId = (objectId?: string, spaceId?: string) => {
	const userId = useAppSelector(extractUserId);

	const selector = useCallback(
		(data: IGetOwnerAcceptancesResponse) =>
			extractLatestOwnerAcceptanceId(data, userId, objectId, spaceId),
		[userId, objectId, spaceId]
	);

	return useLatestOwnerAcceptances(selector);
};
