import {useCallback, useMemo, useState} from 'react';
import {useLocation} from 'react-router-dom';
import {useAppHeader} from '@src/components/AppHeader/hooks/useAppHeader';
import {LinkButton, Toggle, TreeSelect} from '@tehzor/ui-components';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {makeObjectsTreeData} from '@src/utils/makeObjectsTreeData';
import {isLocalStateEqual} from '@src/components/MobileEntitiesFilters/utils/isLocalStateEqual';
import {useRouting} from '@src/components/MobileEntitiesFilters/utils/pagesRouting';
import {useEntitiesFiltersCtx} from '@src/components/EntitiesFilters/utils/entitiesFiltersCtx';
import {treeFilter} from '@tehzor/ui-components/src/components/inputs/select/SelectSearch';
import {useUpdateEffect} from 'react-use';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import {useObjectsAsArray, useTargetObjects} from '@src/core/hooks/queries/objects/hooks';
import {useMainAndSubCompaniesAsArray} from '@src/core/hooks/queries/companies/hooks';
import {useTranslation} from 'react-i18next';
import {TranslatedFilterPage} from '@src/components/TranslatedFilterPage';
import {TranslatedSelectSearch} from '@src/components/TranslatedSelectSearch';

export const NestedObjectsFilterPage = () => {
	const {goBack} = useChangePath();
	const {objectId, entity} = useStrictParams<{objectId?: string; entity: string}>();
	const {t} = useTranslation();

	const {state, change} = useEntitiesFiltersCtx<{
		objects?: string[];
		excludeDescendantsObjects?: boolean;
	}>();
	const [selected, setSelected] = useState(state.objects);
	const [expanded, setExpanded] = useState<string[] | undefined>([]);
	const [search, setSearch] = useState('');
	const [excludeDescendantsObjects, setExcludeDescendantsObjects] = useState(
		state.excludeDescendantsObjects
	);
	const {data} = useMainAndSubCompaniesAsArray();
	const companies = data?.mainCompanies;
	const objects = useObjectsAsArray();
	const {data: targetObjects} = useTargetObjects(objectId, true);

	const {canGoBack} = useLocation().state as {canGoBack?: boolean};

	const treeData = useMemo(() => {
		if (!objects) return [];

		if (targetObjects?.length) {
			return makeObjectsTreeData(objects.filter(item => targetObjects.includes(item.id)));
		}
		return makeObjectsTreeData(objects, companies);
	}, [targetObjects, objects, companies]);

	const {filteredData, expanded: expandedValue} = useMemo(
		() => treeFilter(treeData, 'content', search),
		[search, treeData]
	);

	useUpdateEffect(() => {
		setExpanded(expandedValue?.map(item => item.id));
	}, [expandedValue]);

	const handleApply = useCallback(() => {
		change({objects: selected, excludeDescendantsObjects: excludeDescendantsObjects});
		goBack();
	}, [selected, change, goBack, excludeDescendantsObjects]);

	const handleClear = useCallback(() => {
		setSelected(undefined);
		setExcludeDescendantsObjects(undefined);
		setExpanded(undefined);
	}, []);
	const handleToggleChange = () => {
		setExcludeDescendantsObjects(!excludeDescendantsObjects);
		const currentValue = !excludeDescendantsObjects;
		if (objectId) {
			currentValue ? setSelected([objectId]) : setSelected([...(targetObjects || [])]);
		}
	};
	useAppHeader(
		{
			title: t('objectsFilterPage.title'),
			showBackBtn: true,
			mobileRightButtons: selected?.length ? (
				<LinkButton
					label={t('entitiesFilters.cancelButton.label')}
					onClick={handleClear}
				/>
			) : null
		},
		[selected]
	);

	useRouting(!canGoBack, objectId, entity);

	return (
		<TranslatedFilterPage
			applyDisabled={isLocalStateEqual(state.objects, selected)}
			onApplyClick={handleApply}
			search={
				<TranslatedSelectSearch
					value={search}
					onChange={setSearch}
				/>
			}
		>
			<>
				<Toggle
					checked={excludeDescendantsObjects}
					onChange={handleToggleChange}
					className={{
						root: 'entities-filters__toggle-root',
						content: 'entities-filters__toggle-content'
					}}
				>
					{t('entitiesFilters.objectsFilter.exclude')}
				</Toggle>
				<TreeSelect
					data={filteredData}
					multiple
					value={selected}
					onChange={setSelected}
					expandedValue={expanded}
					onExpand={setExpanded}
				/>
			</>
		</TranslatedFilterPage>
	);
};
