import {useState} from 'react';
import * as React from 'react';
import {ViewTabs, ViewTabsVariants} from './ViewTabs';
import {BankAccountItem} from '@src/pages/LegalPage/components/BankAccountItem';
import {AuthorizedPersonItem} from '@src/pages/LegalPage/components/AuthorizedPersonItem';
import {useBankAccounts, useBankAccountsArray} from '@src/core/hooks/queries/bankAccounts';
import {
	useAuthorizedPersons,
	useAuthorizedPersonsArray
} from '@src/core/hooks/queries/authorizedPersons';
import {Plate} from '@tehzor/ui-components';
import {AddButton} from './actions/AddButton';
import {ILegalEntity} from '@tehzor/tools/interfaces/legals-entity/ILegalEntity';

interface ISideBarMenuProps {
	legal: ILegalEntity;
}

export const SideBarMenu = ({legal}: ISideBarMenuProps) => {
	const [activeTab, setActiveTab] = useState<ViewTabsVariants>('bankAccounts');
	const bankAccounts = useBankAccounts();
	const bankAccountsArray = useBankAccountsArray(legal.id, bankAccounts);
	const authorizedPersons = useAuthorizedPersons();
	const authorizedPersonsArray = useAuthorizedPersonsArray(legal.id, authorizedPersons);

	const isBankAccountsMode = activeTab === 'bankAccounts';
	const isAuthorizedPersonsMode = activeTab === 'authorizedPersons';

	let items: React.ReactNode[] = [];

	if (isBankAccountsMode) {
		items = bankAccountsArray.map(el => <BankAccountItem bankAccount={el} />);
	}
	if (isAuthorizedPersonsMode) {
		items = authorizedPersonsArray.map(el => <AuthorizedPersonItem authorizedPerson={el} />);
	}

	return (
		<div className="legal-page__main-sidebar-menu-wrapper">
			<Plate>
				<div className="legal-page__main-sidebar-menu">
					<ViewTabs
						activeTab={activeTab}
						setActiveTab={setActiveTab}
						bankAccountsCount={bankAccountsArray.length}
						authorizedPersonsCount={authorizedPersonsArray.length}
					/>
					<div className="legal-page__main-sidebar-menu-items">{items}</div>
					<AddButton
						isAuthorizedPersonsMode={isAuthorizedPersonsMode}
						companyId={legal.companyId}
						legalEntityId={legal.id}
					/>
				</div>
			</Plate>
		</div>
	);
};
