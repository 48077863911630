import CategoriesSetsFiltersPage from '@src/pages/filters/CategoriesSetsFiltersPage/CategoriesSetsFiltersPage';
import CreatedByFilterPage from '@src/pages/filters/CreatedByFilterPage';
import CreationDateFilterPage from '@src/pages/filters/CreationDateFilterPage';
import {ObjectsFilterPage} from '@src/pages/filters/ObjectsFilterPage';
import StagesFilterPage from '@src/pages/filters/StagesFilterPage';

export const categoriesSetsFilterRoutes = [
	{
		path: 'objects',
		element: <ObjectsFilterPage />
	},
	{
		path: 'createdBy',
		element: <CreatedByFilterPage />
	},
	{
		path: 'creationdate',
		element: <CreationDateFilterPage />
	},
	{
		path: 'stages',
		element: <StagesFilterPage />
	},
	{
		path: '',
		element: <CategoriesSetsFiltersPage />
	}
];
