import {QueryFunctionContext, useQueryClient} from '@tanstack/react-query';
import {
	IWorkAcceptanceQueryKey,
	workAcceptancesQueryKeys,
	IWorkAcceptancePaginateQueryKey,
	IWorkAcceptanceListQueryKey
} from '../keys';
import {
	requestPaginateWorkAcceptances,
	requestWorkAcceptancesList
} from '@src/api/backend/workAcceptances';
import {requestWorkAcceptance} from '@src/api/backend/workAcceptance';
import {workAcceptancesGetRequest} from '@src/store/modules/settings/pages/workAcceptances/actions';
import useAppDispatch from '@src/core/hooks/useAppDispatch';

export const useWorkAcceptancesQueryDefaults = () => {
	const queryClient = useQueryClient();
	const dispatch = useAppDispatch();

	queryClient.setQueryDefaults(workAcceptancesQueryKeys.paginate(), {
		queryFn: ({queryKey}: QueryFunctionContext<IWorkAcceptancePaginateQueryKey>) => {
			const [, , objectId, filters, sort, offset, limit] = queryKey;
			dispatch(workAcceptancesGetRequest(objectId));
			return requestPaginateWorkAcceptances(filters, sort, offset, limit, objectId);
		}
	});
	queryClient.setQueryDefaults(workAcceptancesQueryKeys.list(), {
		queryFn: ({queryKey}: QueryFunctionContext<IWorkAcceptanceListQueryKey>) => {
			const [, , filter] = queryKey;
			return requestWorkAcceptancesList(filter);
		}
	});
	queryClient.setQueryDefaults(workAcceptancesQueryKeys.details(), {
		queryFn: ({queryKey}: QueryFunctionContext<IWorkAcceptanceQueryKey>) => {
			const [, , workAcceptanceId, objectId] = queryKey;

			return requestWorkAcceptance(objectId, workAcceptanceId);
		}
	});
};
