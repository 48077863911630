import {httpRequests} from '@tehzor/tools/api/httpRequests';
import getTimezone from '@tehzor/tools/utils/getTimezone';
import {IExportResponse} from '@tehzor/tools/interfaces/IExportResponse';
import getEmailsArray from '@src/utils/getEmailsArray';

/**
 * Отправляет запрос на экспорт передачи собственнику
 */
export const makeOwnerAcptExportRequest = async (
	templateId: string,
	objectId: string,
	acceptanceId: string,
	problems?: string[],
	createDocument?: boolean,
	email?: string
): Promise<IExportResponse> => {
	const params = {
		templateId,
		objectId,
		problems,
		timezone: getTimezone(),
		createDocument,
		email: email ? getEmailsArray(email) : undefined
	};
	const response = await httpRequests.exportWithToken.get<IExportResponse>(
		`owner-acceptances/${acceptanceId}/export`,
		{params}
	);
	return response.data;
};
