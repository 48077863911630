import {IListStructure} from '@tehzor/tools/interfaces/structures/IListStructure';
import {useAvailableCheckLists} from '@src/core/hooks/checkLists/useAvailableCheckLists';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {ICheckList} from '@tehzor/tools/interfaces/checkLists/ICheckList';
import {extractStructuresTypes} from '@/features/Structures/useEnrichedStructures/utils/extractStructuresTypes';
import {ProcessIds} from '@tehzor/tools/interfaces/process/ProcessId';

export const useStructuresCheckLists = ({
	objectId,
	spaceId,
	structures
}: {
	objectId: string;
	spaceId?: string;
	structures?: IListStructure[];
}) => {
	const structureTypes = extractStructuresTypes(structures);
	const availableCheckLists = useAvailableCheckLists(
		objectId,
		spaceId,
		ObjectStageIds.BUILDING,
		ProcessIds.ACCEPTANCE_CONTROL,
		structureTypes
	);
	return structureTypes.reduce<Record<string, ICheckList[]>>((acc, type) => {
		acc[type] = availableCheckLists.filter(checkList => checkList.structureType === type);
		return acc;
	}, {});
};
