import {useCallback} from 'react';
import {Button, ISelectionRowProps} from '@tehzor/ui-components';
import {IPresaleCheckEntity} from '@src/interfaces/IPresaleCheckEntity';
import {useTranslation} from 'react-i18next';
import {SelectionActionsPresaleCheck} from '@/features/PresaleCheck';
import {usePresaleCheck} from '@/entities/PresaleCheck';

interface ICustomSelectionRowProps extends ISelectionRowProps<IPresaleCheckEntity> {
	objectId: string;
	presaleCheckId: string;
}

export const SelectionRow = (props: ICustomSelectionRowProps) => {
	const {t} = useTranslation();
	const {objectId, presaleCheckId, selectedFlatRows, toggleAllRowsSelected} = props;
	const {data: presaleCheck} = usePresaleCheck(presaleCheckId);

	const clearSelection = useCallback(() => {
		toggleAllRowsSelected(false);
	}, [toggleAllRowsSelected]);

	return (
		<>
			<SelectionActionsPresaleCheck
				objectId={objectId}
				selectedEntities={selectedFlatRows.map(item => item.original)}
				clearSelection={clearSelection}
				presaleCheck={presaleCheck}
				presaleCheckId={presaleCheckId}
			/>
			<Button
				label={t('presaleChecksPage.table.selectionRow.cancel')}
				type="cancel"
				onClick={clearSelection}
			/>
		</>
	);
};
