import {useCallback} from 'react';
import {CheckListItem} from '@src/pages/WorkAcceptancePage/components/list/CheckListItems/components/CheckListItem';
import {ICheckList} from '@tehzor/tools/interfaces/checkLists/ICheckList';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {ICheckItem} from '@tehzor/tools/interfaces/checkItems/ICheckItem';
import {formCheckItemLink, formWorkAcceptanceCheckItemLink} from '@tehzor/tools/utils/links';
import {useIsDesktop} from '@tehzor/ui-components/src/utils/mediaQueries';
import {useSeparatedCheckItems} from '@src/pages/CheckListPage/hooks/useSeparatedCheckItems';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import {AcceptedItems} from '@src/pages/WorkAcceptancePage/components/list/CheckListItems/components/AcceptedItems';
import './CheckListItems.less';

interface ICheckListItemsProps {
	checkList: ICheckList;
}

export const CheckListItems = ({checkList}: ICheckListItemsProps) => {
	const {pushPath, replacePath} = useChangePath();
	const match = useStrictParams<{
		objectId: string;
		stage: string;
		listId: string;
		spaceId?: string;
		workAcceptanceId?: string;
	}>();
	const isDesktop = useIsDesktop();
	const {accepted, current, next, acceptedItemsIds} = useSeparatedCheckItems(checkList.id);

	const handleClick = useCallback(
		(item: ICheckItem) => {
			const navigate = isDesktop ? replacePath : pushPath;
			if (match.workAcceptanceId) {
				navigate(
					formWorkAcceptanceCheckItemLink({
						...match,
						listId: item.checkListId,
						itemId: item.id,
						workAcceptanceId: match.workAcceptanceId
					})
				);
			} else {
				const checkItemLink = formCheckItemLink({
					...match,
					listId: item.checkListId,
					itemId: item.id
				});

				if (!checkItemLink) {
					return;
				}
				navigate(checkItemLink);
			}
		},
		[isDesktop, match]
	);

	return (
		<div className="work-acceptance-page-check-list-items">
			{accepted.length > 0 && (
				<>
					<AcceptedItems
						items={accepted}
						stage={checkList.stage}
						onItemClick={handleClick}
					/>
					<hr className="work-acceptance-page__check-list__divider work-acceptance-page__check-list__divider_hr" />
				</>
			)}

			{current.map(item => (
				<CheckListItem
					mode="main"
					stage={checkList.stage}
					key={item.id}
					item={item}
					onClick={handleClick}
					acceptedItemsIds={acceptedItemsIds}
				/>
			))}

			{next.length > 0 && (
				<>
					<hr className="work-acceptance-page__check-list__divider work-acceptance-page__check-list__divider_hr" />

					<div className="work-acceptance-page-check-list-items__next">
						<div className="work-acceptance-page-check-list-items__next_disabled" />
						{next.map(item => (
							<CheckListItem
								disabled
								mode="main"
								stage={checkList.stage}
								key={item.id}
								item={item}
								onClick={handleClick}
							/>
						))}
					</div>
				</>
			)}
		</div>
	);
};
