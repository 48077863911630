export type IPresaleCheckDetailQueryKey = [] & {
	0: string;
	1: string;
	2: string; // presaleCheckId
};

export type IPresaleChecksListQueryKey = [] & {
	0: string;
	1: string;
	2: string; // objectId
};

export type IPresaleCheckInspectionsQueryKey = [] & {
	0: string;
	1: string;
	2: string; // objectId
	3: string; // presaleCheckId
};

export type IPresaleCheckProblemsQueryKey = [] & {
	0: string;
	1: string;
	2: string; // objectId
	3: string; // presaleCheckId
};

export type IPresaleCheckProblemRepliesQueryKey = [] & {
	0: string;
	1: string;
	2: string; // objectId
	3: string; // presaleCheckId objectId
};

export const presaleChecksQueryKeys = {
	all: () => ['presaleChecks'],
	one: () => ['presaleCheck'],
	latest: () => ['latest-presaleChecks'],
	details: () => [...presaleChecksQueryKeys.one(), 'detail'],
	detail: (presaleCheckId: string) => [...presaleChecksQueryKeys.details(), presaleCheckId],
	list: () => [...presaleChecksQueryKeys.all(), 'list'],
	localList: () => [...presaleChecksQueryKeys.all(), 'local-list'],
	add: () => [...presaleChecksQueryKeys.one(), 'add'],
	edit: () => [...presaleChecksQueryKeys.one(), 'edit'],
	delete: () => [...presaleChecksQueryKeys.one(), 'delete'],
	problems: () => [...presaleChecksQueryKeys.one(), 'problems'],
	presaleCheckProblems: (objectId: string, presaleCheckId: string) => [
		...presaleChecksQueryKeys.problems(),
		objectId,
		presaleCheckId
	],
	inspections: () => [...presaleChecksQueryKeys.one(), 'inspections'],
	presaleCheckInspections: (objectId: string, presaleCheckId: string) => [
		...presaleChecksQueryKeys.inspections(),
		objectId,
		presaleCheckId
	],
	problemReplies: () => [...presaleChecksQueryKeys.one(), 'problemReplies'],
	presaleCheckProblemReplies: (objectId: string, presaleCheckId: string) => [
		...presaleChecksQueryKeys.problemReplies(),
		objectId,
		presaleCheckId
	]
};
