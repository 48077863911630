import useAppSelector from '@src/core/hooks/useAppSelector';
import {IProblem} from '@tehzor/tools/interfaces/problems/IProblem';
import {useMemo} from 'react';
import {IPresaleCheck} from '@tehzor/tools/interfaces/presaleChecks/IPresaleCheck';
import {testPresaleCheckRestrictions} from '../../utils/testPresaleCheckRestrictions';

export interface IPermissions {
	canEdit?: boolean;
	canDelete?: boolean;
}

/**
 * Возвращает полномочия для предпродажной проверки
 *
 * @param objectId id объекта
 * @param presaleCheck предпродажная проверка
 * @param problems нарушения
 */
export const usePresaleCheckPermissions = (
	objectId: string,
	presaleCheck?: IPresaleCheck,
	problems?: IProblem[]
): IPermissions => {
	const user = useAppSelector(s => s.auth.profile);

	return useMemo(() => {
		if (!user || !user.roles) {
			return {};
		}
		return {
			canDelete: testPresaleCheckRestrictions(
				user.id,
				user.roles,
				'presale-checks-delete',
				objectId,
				presaleCheck?.createdBy,
				problems
			),
			canEdit: testPresaleCheckRestrictions(
				user.id,
				user.roles,
				'presale-checks-edit',
				objectId,
				presaleCheck?.createdBy,
				problems
			)
		};
	}, [objectId, presaleCheck, user]);
};
