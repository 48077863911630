export const fieldsSettings = {
	companyId: {fieldId: 'companyId', isRequired: true},
	key: {fieldId: 'key', isRequired: true},
	name: {fieldId: 'name', isRequired: true},
	valueType: {fieldId: 'valueType', isRequired: true},
	icon: {fieldId: 'icon', isRequired: true},
	values: {fieldId: 'values', isRequired: false},
	canBeCopied: {fieldId: 'canBeCopied', isRequired: false},
	defaultValue: {fieldId: 'defaultValue', isRequired: false},
	entityType: {fieldId: 'entityType', isRequired: false}
};
