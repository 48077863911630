import {Column} from 'react-table';
import {IEntitySettings} from '@src/core/hooks/settings/useEntitySettings';
import {ITableSettings} from '@src/interfaces/ITableSettings';
import {isEqual} from 'lodash';
import {useEffect, useMemo} from 'react';
import {getNewTableSettings} from '@src/core/hooks/settings/useColumnsWithSettings/helpers/getNewTableSettings';
import {getVisibleControlledColumnsLength} from '@src/core/hooks/settings/useColumnsWithSettings/helpers/getVisibleControlledColumnsLength';
import {ColumnWithSettings} from '@src/core/hooks/settings/useColumnsWithSettings/interfaces';

interface IUseColumnCustomizerProps<T extends object> {
	customizedColumns: Array<ColumnWithSettings<T>>;
	entitySettings?: IEntitySettings;
	tableSettings: ITableSettings;
	onChangeTableSettings: (tableSettings: ITableSettings) => void;
}

export const useColumnsWithSettings = <T extends object>({
	customizedColumns,
	entitySettings,
	tableSettings,
	onChangeTableSettings
}: IUseColumnCustomizerProps<T>): [
	visibleColumns: Array<Column<T>>,
	activeColumnsLength: number
] => {
	useEffect(() => {
		const newTableSettings = getNewTableSettings(
			customizedColumns,
			tableSettings,
			entitySettings
		);

		if (!isEqual(tableSettings, newTableSettings)) {
			onChangeTableSettings({...tableSettings, ...newTableSettings});
		}
	}, [customizedColumns, entitySettings, onChangeTableSettings]);

	const visibleColumns = useMemo(
		() =>
			customizedColumns.filter(({id, customizeSettings}) => {
				if (customizeSettings.isCustom) {
					return tableSettings[String(id)] ? tableSettings[String(id)].isVisible : false;
				}
				return !tableSettings[String(id)] || tableSettings[String(id)].isVisible;
			}),
		[customizedColumns, tableSettings]
	);

	const visibleControlledColumnsLength = getVisibleControlledColumnsLength(tableSettings);
	return [visibleColumns, visibleControlledColumnsLength];
};
