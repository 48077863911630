import {useMemo} from 'react';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {findPermission, hasPermission} from '@tehzor/tools/utils/findPermission';
import {UserRoleScopes} from '@tehzor/tools/interfaces/IUser';

export interface IOwnerAcceptancesPermissions {
	canAdd?: boolean;
	canEdit?: boolean;
	canEditStatus?: boolean;
	canDelete?: boolean;
	canExport?: boolean;
	canProblemsAdd?: boolean;
	canView?: boolean;
}

/**
 * Возвращает полномочия для приёмок собственниками
 *
 * @param objectId id объекта
 * @param targetComponent таргет
 */
export const useOwnerAcceptancesPermissions = (
	objectId?: string,
	targetComponent?: 'problems' | 'spaces' | 'space' | 'check-list'
): IOwnerAcceptancesPermissions => {
	const user = useAppSelector(s => s.auth.profile);

	return useMemo(() => {
		if (!user || !user.roles) {
			return {};
		}
		const addPermission = findPermission(
			user.roles,
			'owner-acceptances-add',
			UserRoleScopes.OBJECT,
			objectId
		);

		return {
			canAdd:
				addPermission !== undefined &&
				!(
					targetComponent === 'problems' &&
					addPermission.restrictions &&
					addPermission.restrictions['owner-acceptances-add-from-problems']
				) &&
				!(
					targetComponent === 'space' &&
					addPermission.restrictions &&
					addPermission.restrictions['owner-acceptances-add-from-space']
				) &&
				!(
					targetComponent === 'check-list' &&
					addPermission.restrictions &&
					addPermission.restrictions['owner-acceptances-add-from-check-list']
				),
			canEdit: hasPermission(
				user.roles,
				'owner-acceptances-edit',
				UserRoleScopes.OBJECT,
				objectId
			),
			canEditStatus: hasPermission(
				user.roles,
				'owner-acceptances-status-edit',
				UserRoleScopes.OBJECT,
				objectId
			),
			canDelete: hasPermission(
				user.roles,
				'owner-acceptances-delete',
				UserRoleScopes.OBJECT,
				objectId
			),
			canExport: hasPermission(
				user.roles,
				'owner-acceptances-export',
				UserRoleScopes.OBJECT,
				objectId
			),
			canProblemsAdd: hasPermission(
				user.roles,
				'problems-add',
				UserRoleScopes.OBJECT,
				objectId
			),
			canView: hasPermission(user.roles, 'owner-acceptances-view')
		};
	}, [objectId, user]);
};
