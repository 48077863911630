import {InspectionAddingDesktop} from './InspectionAdding.desktop';
import DesktopInspectionsExport from './InspectionsExport.desktop';
import {useProblemsPermissions} from '@src/core/hooks/permissions/useProblemsPermissions';
import {useInspectionsAddingPermissions} from '@src/core/hooks/permissions/useInspectionsAddingPermissions';
import {usePermissions} from '../../utils/usePermissions';
import useAppSelector from '@src/core/hooks/useAppSelector';

interface IDesktopActionsProps {
	objectId?: string;
}

const ActionsDesktop = ({objectId}: IDesktopActionsProps) => {
	const user = useAppSelector(s => s.auth.profile);
	const permissions = usePermissions(user, objectId);
	const problemsPermissions = useProblemsPermissions(objectId);
	const inspectionsPermissions = useInspectionsAddingPermissions(objectId);

	return (
		<>
			{objectId !== undefined &&
				permissions.canAddInspection &&
				(problemsPermissions.canAdd || inspectionsPermissions.canAdd) && (
					<InspectionAddingDesktop objectId={objectId} />
				)}
			{permissions.canExport && <DesktopInspectionsExport objectId={objectId} />}
		</>
	);
};

export default ActionsDesktop;
