import {queryClient} from '@src/api/QueryClient';
import {bankAccountsQueryKeys} from '@src/api/cache/bankAccounts/keys';
import {makeBankAccountDeleteRequest} from '@src/api/backend/bankAccount';
import {addErrorToast} from '@tehzor/ui-components';
import {useMutation} from '@tanstack/react-query';
import {useTranslation} from 'react-i18next';

export const useDeleteBankAccount = () => {
	const {t} = useTranslation();

	return useMutation({
		mutationKey: bankAccountsQueryKeys.delete(),
		mutationFn: (bankAccountId: string) => makeBankAccountDeleteRequest(bankAccountId),
		onSuccess: () => {
			void queryClient.invalidateQueries({queryKey: bankAccountsQueryKeys.list()});
			void queryClient.refetchQueries({queryKey: bankAccountsQueryKeys.list()});
		},
		onError: () => {
			addErrorToast({title: t('toast.deleteBankAccount.error.title')});
		}
	});
};
