import {Responsibles} from '@src/components/viewFields/Responsibles';
import {useWorkAcceptanceResponsiblePermissions} from '@src/core/hooks/permissions/useWorkAcceptanceResponsiblePermissions';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {editWorkAcceptanceSubmitters} from '@src/store/modules/entities/workAcceptance/actions/editSubmitters';
import {IWorkAcceptance} from '@tehzor/tools/interfaces/workAcceptances/IWorkAcceptance';
import {WorkingGroupTypeId} from '@tehzor/tools/interfaces/workingGroups/IWorkingGroupType';
import {IRuleParams} from '@tehzor/tools/utils/responsibilityRules/IRuleParams';
import {useCallback, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useDelegationHistoryAsArrayByWorkAcceptance} from '@src/core/hooks/queries/delegationHistory/hooks';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractUserProfile} from '@src/store/modules/auth/profile/selectors';

interface IWorkAcceptanceSubmittersProps {
	workAcceptance: IWorkAcceptance;
}

export const WorkAcceptanceSubmitters = ({workAcceptance}: IWorkAcceptanceSubmittersProps) => {
	const {t} = useTranslation();
	const dispatch = useAppDispatch();
	const user = useAppSelector(extractUserProfile);

	const {id, objectId, stage, submittersActiveGroup, submittersInitialGroup, submitters} =
		workAcceptance;

	const {data: histories} = useDelegationHistoryAsArrayByWorkAcceptance(
		objectId,
		id,
		WorkingGroupTypeId.SUBMITTERS
	);

	const {canEditResponsibles, onlyActiveGroup} = useWorkAcceptanceResponsiblePermissions(
		objectId,
		'work-acceptances-submitters-edit'
	);

	const ruleParams: IRuleParams = useMemo(
		() => ({
			categoryId: workAcceptance.categoryId,
			spaceIds: workAcceptance.spaces?.map(space => space.id),
			structureIds: workAcceptance.structures?.map(structure => structure.id)
		}),
		[workAcceptance]
	);

	const handleChange = useCallback(
		async (users: string[], group?: string) => {
			await dispatch(editWorkAcceptanceSubmitters(objectId, id, users, group));
		},
		[objectId, id]
	);

	return (
		<Responsibles
			author={user}
			objectId={objectId}
			stage={stage}
			workAcceptanceType={workAcceptance.type}
			workAcceptanceFrontType={workAcceptance.frontType}
			workingGroupType={WorkingGroupTypeId.SUBMITTERS}
			respUsers={submitters}
			activeGroup={submittersActiveGroup}
			initialGroup={submittersInitialGroup}
			canEditResponsibles={canEditResponsibles}
			onlyActiveGroup={onlyActiveGroup}
			ruleParams={ruleParams}
			histories={histories}
			label={t('workAcceptancePage.description.submitters.label')}
			delegationDialogLabel={t('components.delegationDialog.submitters.title')}
			historyAutomaticActionLabelIn={t(
				'workAcceptancePage.description.submitters.historyAutomaticActionLabelIn'
			)}
			historyAutomaticActionLabelTitle={t(
				'workAcceptancePage.description.submitters.historyAutomaticActionLabelTitle'
			)}
			historyUserActionLabelIn={t(
				'workAcceptancePage.description.submitters.historyUserActionLabelIn'
			)}
			historyUserActionLabelTitle={t(
				'workAcceptancePage.description.submitters.historyUserActionLabelTitle'
			)}
			onResponsiblesChange={handleChange}
		/>
	);
};
