import {Responsibles} from '@src/components/viewFields/Responsibles/Responsibles';
import {useEditTaskResponsible} from '@src/core/hooks/mutations/task/useEditTaskResponsible';
import {useTasksPermissions} from '@src/core/hooks/permissions/useTasksPermissions';
import {IEntitySettings} from '@src/core/hooks/settings/useEntitySettings';
import {useDelegationHistoryAsArrayByTaskResponsibles} from '@src/core/hooks/queries/delegationHistory/hooks';
import {IEnrichedTask} from '@tehzor/tools/interfaces/tasks/IEnrichedTask';
import {useCallback, useMemo} from 'react';
import {useTranslation} from 'react-i18next';

interface ITaskResponsiblesProps {
	task: IEnrichedTask;
	settings?: IEntitySettings;
}

export const TaskResponsibles = ({task, settings}: ITaskResponsiblesProps) => {
	const {
		id,
		objectId,
		activeGroupLeader,
		activeGroup,
		initialGroup,
		createdBy,
		watchers,
		planId
	} = task;
	const {mutateAsync: editTaskResponsible} = useEditTaskResponsible();
	const {t} = useTranslation();

	const {data: histories} = useDelegationHistoryAsArrayByTaskResponsibles(objectId, id);

	const ruleParams = useMemo(() => ({planId}), [planId]);

	const respUsers = task.respUsers?.map(user => user.id);

	const {canEditResponsible} = useTasksPermissions({
		objectId,
		activeGroupLeader: activeGroupLeader?.id,
		respUsers,
		createdBy: createdBy?.id,
		watchers
	});

	const handleChange = useCallback(
		async (users: string[], group: string | undefined) => {
			await editTaskResponsible({
				objectId,
				taskId: id,
				respUsers: users,
				activeGroup: group
			});
		},
		[objectId]
	);

	return (
		<Responsibles
			objectId={objectId}
			respUsers={respUsers}
			activeGroup={activeGroup}
			initialGroup={initialGroup}
			canEditResponsibles={canEditResponsible}
			ruleParams={ruleParams}
			histories={histories}
			required={settings?.builtin.respUsers.required}
			label={t('components.editableProblem.performers.label')}
			delegationDialogLabel={t('components.delegationDialog.performers.title')}
			historyAutomaticActionLabelIn={t(
				'taskPage.info.performers.historyAutomaticActionLabelIn'
			)}
			historyAutomaticActionLabelTitle={t(
				'taskViewPage.info.performers.historyAutomaticActionLabelTitle'
			)}
			historyUserActionLabelIn={t('taskViewPage.info.performers.historyUserActionLabelIn')}
			historyUserActionLabelTitle={t(
				'taskViewPage.info.performers.historyUserActionLabelTitle'
			)}
			onResponsiblesChange={handleChange}
		/>
	);
};
