import {TabLink} from '@tehzor/ui-components';
import {ITabLinkProps} from '@tehzor/ui-components/src/components/containers/Tabs/components/TabLink/TabLink';
import styles from './Tab.module.less';

export const Tab = (props: ITabLinkProps) => (
	<TabLink
		className={styles.wrapper}
		{...props}
	/>
);
