import {IBuiltinField, IFieldSetting} from '@tehzor/tools/interfaces/fields';

export const getPreparedBuiltInField = (
	setId: string,
	field: IBuiltinField,
	fieldSetting?: IFieldSetting
) => ({
	id: field.id,
	setId,
	settingsId: fieldSetting?.id,
	name: field.name,
	enabled: !!fieldSetting,
	canBeCopied: true,
	copyable: true,
	required: fieldSetting?.required,
	order: fieldSetting ? fieldSetting.order + 1 : 0
});
