import {useCallback} from 'react';
import {Outlet} from 'react-router-dom';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractProblemsPageSettings} from '@src/store/modules/settings/pages/problems/selectors';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {problemsActions} from '@src/store/modules/settings/pages/problems/slice';
import MobileEntitiesFilters from '@src/components/MobileEntitiesFilters';
import {IProblemsFiltersState} from '@src/store/modules/settings/pages/problems/interfaces';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import {useTargetObjects} from '@src/core/hooks/queries/objects/hooks';

const ProblemsFiltersRoutingPage = () => {
	const {changeOffset, changeFilters, clearFilters} = problemsActions;
	const {objectId} = useStrictParams<{objectId?: string}>();
	const {filters} = useAppSelector(s => extractProblemsPageSettings(s, objectId));
	const {data: descendants} = useTargetObjects(objectId, false);

	const dispatch = useAppDispatch();

	const applyFilters = useCallback(
		(value: IProblemsFiltersState) => {
			dispatch(changeFilters({objectId: objectId || 'all', filters: value}));
			dispatch(changeOffset({objectId: objectId || 'all', offset: 0}));
		},
		[changeFilters, changeOffset, dispatch, objectId]
	);

	const onClear = useCallback(() => {
		dispatch(clearFilters({objectId: objectId || 'all', isDescendants: !!descendants?.length}));
	}, [clearFilters, dispatch, objectId, descendants]);
	return (
		<MobileEntitiesFilters
			objectId={objectId}
			filters={filters}
			onApply={applyFilters}
			onClear={onClear}
			fullClear={!!descendants?.length}
		>
			<Outlet />
		</MobileEntitiesFilters>
	);
};

export default ProblemsFiltersRoutingPage;
