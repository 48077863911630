import {forwardRef, Ref, useCallback, useImperativeHandle} from 'react';
import {useEditableMeter} from '@src/components/EditableMeter/hooks/useEditableMeter';
import {useAddMeter} from '@src/core/hooks/mutations/meters/useAddMeter';
import {useTranslatedConfirmDialog} from '@src/core/hooks/translations/useTranslatedConfirmDialog';

interface IAddingMeterProps {
	companyId?: string;
	objectId?: string;
	spaceId?: string;
	saving: boolean;

	onClose: () => void;
	setSaving: (s: boolean) => void;
}

export interface IAddingMeterRefProps {
	save: () => void;
	cancel: () => void;
}

export const AddingMeter = forwardRef<IAddingMeterRefProps, IAddingMeterProps>(
	(props: IAddingMeterProps, ref?: Ref<IAddingMeterRefProps>) => {
		const {companyId, spaceId, objectId, saving, onClose, setSaving} = props;
		const addMeter = useAddMeter();

		const [fields, getSavingData, , isBlocking] = useEditableMeter(
			undefined,
			undefined,
			saving
		);

		const save = useCallback(() => {
			setSaving(true);
			if (companyId && objectId && spaceId) {
				const savingData = getSavingData();
				if (savingData) {
					try {
						savingData.spaceId = spaceId;
						savingData.companyId = companyId;
						savingData.objectId = objectId;
						addMeter(savingData);
						onClose();
					} catch (error) {
						setSaving(false);
						throw error;
					}
				}
			}
			setSaving(false);
		}, [companyId, spaceId, getSavingData]);

		const [closingDialog, getClosingConfirmation] = useTranslatedConfirmDialog();

		const cancel = useCallback(async () => {
			if (!isBlocking || (await getClosingConfirmation())) {
				onClose();
			}
		}, [isBlocking, onClose]);

		useImperativeHandle(ref, () => ({save, cancel}), [save, cancel]);

		return (
			<>
				{fields}
				{closingDialog}
			</>
		);
	}
);
