import {useCallback} from 'react';
import {IProblemTemplate} from '@tehzor/tools/interfaces/problemTemplatesSets/IProblemTemplate';
import {useExtractCategoriesSetsAsArray} from '@src/core/hooks/queries/categorySets/hook';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {useExtractAllCategoriesAsArray} from '@src/core/hooks/queries/categories/hook';
import {useFormContext} from 'react-hook-form';
import {editableProblemFields} from '../useEditableProblemForm/editableProblemFields';

interface IUseTemplateSelectHandlerOptions {
	objectId: string;
	stage: ObjectStageIds;
}

export const useTemplateSelectHandler = ({objectId, stage}: IUseTemplateSelectHandlerOptions) => {
	const {data: allCategories} = useExtractAllCategoriesAsArray();
	const {data: categoriesSets} = useExtractCategoriesSetsAsArray({objectId, stage, allCategories});
	const categories = categoriesSets?.map(set => set.categories).flat();

	const {setValue} = useFormContext();
	return useCallback(
		(selected?: IProblemTemplate) => {
			if (!selected) {
				return;
			}
			setValue(editableProblemFields.TEMPLATE_ID, selected.id);
			setValue(editableProblemFields.DESCRIPTION, selected.description, {
				shouldDirty: true,
				shouldValidate: true
			});

			if (selected.critical) {
				setValue(editableProblemFields.CRITICAL, true);
			}

			if (selected.fixDate || selected.critical) {
				// Фиксируем "Срок устранения" в 24 часа для случаев,
				// когда в шаблоне установлена галочка "Критично" без поля fixDate(plannedFixDate)
				const offset = selected.fixDate ? selected.fixDate : 1;
				setValue(
					editableProblemFields.PLANNED_FIX_DATE,
					new Date().getTime() + offset * 24 * 60 * 60 * 1000,
					{
						shouldDirty: true,
						shouldValidate: true
					}
				);
			}

			if (selected.prescription) {
				setValue(editableProblemFields.PRESCRIPTION, selected.prescription, {
					shouldDirty: true,
					shouldValidate: true
				});
			}

			if (selected.reason) {
				setValue(
					editableProblemFields.REASON,
					{value: selected.reason.value},
					{shouldDirty: true, shouldValidate: true}
				);
			}

			if (selected.categoryId) {
				const found = categories?.find(
					category => category.name.trim() === selected.categoryId?.trim()
				);
				if (found) {
					setValue(editableProblemFields.CATEGORY_ID, found.id, {
						shouldDirty: true,
						shouldValidate: true
					});
				}
			}
		},
		[categories, setValue]
	);
};
