import {memo, useCallback, useMemo, useTransition} from 'react';
import {TabLink, Tabs} from '@tehzor/ui-components';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {SpaceEntitiesTab} from '@src/interfaces/SpaceEntitiesTab';
import {changeEntitiesTab} from '@src/store/modules/settings/pages/space/slice';
import {useUpdateEffect} from 'react-use';
import {useTranslation} from 'react-i18next';
import {
	useExtractSpaceChecksAllIds,
	useExtractSpaceInternalAcceptancesAllIds,
	useExtractSpaceOwnerAcceptanceAllIds,
	useExtractSpaceWarrantyClaimsAllIds
} from '@src/core/hooks/queries/space';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractSpaceTabs} from '@src/store/modules/settings/pages/space/selectors';
import {extractOfflineModeAvailability} from '@src/store/modules/settings/offlineMode/selectors/availability';
import {extractNetworkStatus} from '@src/store/modules/offlineMode/selectors';
import {hasOnlineItems} from '@src/pages/SpacePage/utils/hasOnlineElements';
import {useExtractSpacePresaleChecksAllIds} from '@src/core/hooks/queries/space/presaleChecks/hooks';
import {usePresaleChecksPermissions} from '@/entities/PresaleCheck/model/hooks/usePresaleChecksPermissions';

interface IEntitiesTabsProps {
	objectId: string;
	spaceId: string;
}

interface ITabData {
	key: SpaceEntitiesTab;
	title: string;
	count?: number;
}

const classNames = {links: 'space-page__tabs', link: 'space-page__tab-link'};

export const EntitiesTabs = memo((props: IEntitiesTabsProps) => {
	const {t} = useTranslation();

	const {objectId, spaceId} = props;

	const dispatch = useAppDispatch();
	const [, startTransition] = useTransition();

	const offlineMode = useAppSelector(extractOfflineModeAvailability);
	const isOnline = useAppSelector(extractNetworkStatus);
	const appIsOffline = offlineMode && !isOnline;

	const activeTab = useAppSelector(extractSpaceTabs);

	const {data: checksAllIds} = useExtractSpaceChecksAllIds(objectId, spaceId);
	const hasOnlineChecks = hasOnlineItems(appIsOffline, checksAllIds?.length);

	const {canView: presaleChecksPermission} = usePresaleChecksPermissions();
	const {data: presaleChecksAllIds} = useExtractSpacePresaleChecksAllIds(
		objectId,
		spaceId,
		presaleChecksPermission
	);
	const hasOnlinePresaleChecks = hasOnlineItems(appIsOffline, presaleChecksAllIds?.length);

	const {data: internalAcceptancesAllIds} = useExtractSpaceInternalAcceptancesAllIds(
		objectId,
		spaceId
	);
	const hasOnlineInternalAcceptances = hasOnlineItems(
		appIsOffline,
		internalAcceptancesAllIds?.length
	);

	const {data: ownerAcceptancesAllIds} = useExtractSpaceOwnerAcceptanceAllIds(objectId, spaceId);
	const hasOnlineOwnerAcceptances = hasOnlineItems(appIsOffline, ownerAcceptancesAllIds?.length);

	const {data: warrantyClaimsAllIds} = useExtractSpaceWarrantyClaimsAllIds(objectId, spaceId);
	const hasOnlineWarrantyClaims = hasOnlineItems(appIsOffline, warrantyClaimsAllIds?.length);

	const [tabsData, tabsLinks] = useMemo<[ITabData[], JSX.Element[]]>(() => {
		const data: ITabData[] = [
			{key: SpaceEntitiesTab.ALL, title: t('spacePage.entitiesTabs.all.title')}
		];

		if (hasOnlineWarrantyClaims) {
			data.push({
				key: SpaceEntitiesTab.WARRANTY_CLAIMS,
				title: t('spacePage.entitiesTabs.warrantyClaims.title'),
				count: warrantyClaimsAllIds?.length
			});
		}

		if (hasOnlineOwnerAcceptances) {
			data.push({
				key: SpaceEntitiesTab.OWNER_ACCEPTANCES,
				title: t('spacePage.entitiesTabs.ownerAcceptances.title'),
				count: ownerAcceptancesAllIds?.length
			});
		}

		if (hasOnlinePresaleChecks) {
			data.push({
				key: SpaceEntitiesTab.PRESALECHECKS,
				title: t('spacePage.entitiesTabs.presaleChecks.title'),
				count: presaleChecksAllIds?.length
			});
		}
		if (hasOnlineInternalAcceptances) {
			data.push({
				key: SpaceEntitiesTab.INTERNAL_ACCEPTANCES,
				title: t('spacePage.entitiesTabs.internalAcceptances.title'),
				count: internalAcceptancesAllIds?.length
			});
		}

		if (hasOnlineChecks) {
			data.push({
				key: SpaceEntitiesTab.CHECKS,
				title: t('spacePage.entitiesTabs.checks.title'),
				count: checksAllIds?.length
			});
		}

		const links = data.map(item => (
			<TabLink
				key={item.key}
				label={item.title}
				count={item.count}
			/>
		));
		return [data, links];
	}, [
		hasOnlineWarrantyClaims,
		hasOnlineOwnerAcceptances,
		hasOnlineInternalAcceptances,
		hasOnlineChecks,
		hasOnlinePresaleChecks,
		warrantyClaimsAllIds?.length,
		ownerAcceptancesAllIds?.length,
		internalAcceptancesAllIds?.length,
		checksAllIds?.length,
		presaleChecksAllIds?.length,
		t
	]);

	const handleTabChange = useCallback(
		(index: number) => {
			if (tabsData[index]) {
				startTransition(() => {
					dispatch(changeEntitiesTab(tabsData[index].key));
				});
			}
		},
		[tabsData, dispatch]
	);

	useUpdateEffect(() => {
		handleTabChange(0);
	}, [
		warrantyClaimsAllIds?.length,
		ownerAcceptancesAllIds?.length,
		internalAcceptancesAllIds?.length,
		checksAllIds?.length,
		presaleChecksAllIds?.length
	]);

	const activeTabIndex = useMemo(() => {
		const index = tabsData.findIndex(tabData => tabData.key === activeTab);
		if (index === -1) return 0;
		return index;
	}, [activeTab, tabsData]);

	return (
		<Tabs
			className={classNames}
			activeTab={activeTabIndex}
			links={tabsLinks}
			onActiveTabChange={handleTabChange}
		/>
	);
});
