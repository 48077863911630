import {Wrapper} from '@/shared/ui/EntitiesPage/Wrapper/Wrapper';
import {Controls} from '@/shared/ui/EntitiesPage/Controls/Controls';
import {Outlet} from 'react-router';
import {FieldsTopBar} from './FieldsTopBar/FieldsTopBar';
import {FieldsCentralBar} from './FieldsCentralBar/FieldsCentralBar';
import styles from './FieldsPage.module.less';
import {useIsDesktop} from '@/shared/hooks/dimensions/useIsDesktop/useIsDesktop';

export const FieldsPage = () => {
	const isDesktop = useIsDesktop();

	return (
		<Wrapper>
			<Controls>
				<FieldsTopBar />
				{isDesktop && <FieldsCentralBar />}
			</Controls>
			<div className={styles.main}>
				<Outlet />
			</div>
		</Wrapper>
	);
};
