import {
	EditableFieldLabel,
	Select2,
	SelectOption,
	SelectPopup,
	TextFieldWithForwardedRef
} from '@tehzor/ui-components';
import {CSSProperties, Dispatch, useCallback} from 'react';
import {IEditableEntityAction} from '@tehzor/tools/core/states/editableEntityState';
import {IEditableFieldsSettingsSetState} from '@/features/FieldsSettingsSets/components/FormEditFieldsSettingsSet/context/formState';
import {useFieldEntityTypesAsArray} from '@/entities/Fields';

interface IFieldEntityTypeSelectProps<S, E> {
	field: keyof S;
	value?: string;
	className?: string;
	style?: CSSProperties;
	label?: string;
	errorMessage?: string;
	editingDispatch: Dispatch<IEditableEntityAction<S, E>>;
	required?: boolean;
	disabled?: boolean;
	hasError?: boolean;
}

export const FieldEntityTypeSelect = <S extends IEditableFieldsSettingsSetState, E>({
	field,
	value,
	className,
	style,
	label,
	errorMessage,
	editingDispatch,
	required,
	disabled,
	hasError
}: IFieldEntityTypeSelectProps<S, E>) => {
	const {data: fieldEntityTypes} = useFieldEntityTypesAsArray();

	const handleChange = useCallback(
		(v: string) => {
			const entityType = fieldEntityTypes?.find(({id}) => id === v);
			editingDispatch({type: 'update', field, value: entityType});
			if (required) {
				editingDispatch({type: 'update-error', field});
			}
		},
		[field, required, editingDispatch]
	);

	const fieldValue = fieldEntityTypes?.find(({id}) => id === value)?.name;

	return (
		<div
			className={className}
			style={style}
		>
			<EditableFieldLabel>{label}</EditableFieldLabel>
			<SelectPopup
				noHeader
				trigger={
					<TextFieldWithForwardedRef
						elementType="div"
						value={fieldValue}
						icon={<i className="tz-simple-arrow-20" />}
						error={required && hasError ? errorMessage : undefined}
						disabled={disabled}
					/>
				}
			>
				<Select2
					value={value}
					onChange={handleChange}
				>
					{fieldEntityTypes?.map(entityType => (
						<SelectOption
							key={entityType.id}
							itemKey={entityType.id}
							content={entityType.name}
							inputType="radio"
						/>
					))}
				</Select2>
			</SelectPopup>
		</div>
	);
};
