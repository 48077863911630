import {useTranslation} from 'react-i18next';
import {useIsMutating} from '@tanstack/react-query';
import {useUpdateEffect} from 'react-use';
import {addSuccessToast, closeToast} from '@tehzor/ui-components';

export const useExportMutating = (key: string[]) => {
	const {t} = useTranslation();
	const toastId = key.toString();

	const isMutating = !!useIsMutating({
		mutationKey: key
	});

	useUpdateEffect(() => {
		if (isMutating) {
			addSuccessToast({title: t('toast.processing.startExport.title'), options: {toastId}});
		} else {
			closeToast({id: toastId});
		}
	}, [isMutating]);

	return isMutating;
};
