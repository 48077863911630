import {EditableFieldLabel, SelectPopup, TextFieldWithForwardedRef} from '@tehzor/ui-components';
import {CSSProperties, Dispatch, useCallback} from 'react';
import {IEditableEntityAction} from '@tehzor/tools/core/states/editableEntityState';
import styles from './IconSelect.module.less';
import classNames from 'classnames';
import {IconItem} from '@src/components/editableFields/IconSelect/iconItem';

interface IIconSelectProps<S, E> {
	icons: string[];
	field: keyof S;
	value?: string;
	className?: string;
	style?: CSSProperties;
	label?: string;
	errorMessage?: string;
	editingDispatch: Dispatch<IEditableEntityAction<S, E>>;
	required?: boolean;
	disabled?: boolean;
	hasError?: boolean;
}

export const IconSelect = <S, E>({
	icons,
	field,
	value,
	className,
	style,
	label,
	errorMessage,
	editingDispatch,
	required,
	disabled,
	hasError
}: IIconSelectProps<S, E>) => {
	const handleChange = useCallback(
		(v: string) => {
			editingDispatch({type: 'update', field, value: v});
			if (required) {
				editingDispatch({type: 'update-error', field});
			}
		},
		[field, required, editingDispatch]
	);

	return (
		<div
			className={className}
			style={style}
		>
			<EditableFieldLabel>{label}</EditableFieldLabel>
			<SelectPopup
				className={styles.popup}
				noHeader
				trigger={
					<TextFieldWithForwardedRef
						elementType="div"
						className={{
							root: styles.trigger,
							element: styles.triggerContainer
						}}
						icon={<i className="tz-simple-arrow-20" />}
						error={required && hasError ? errorMessage : undefined}
						disabled={disabled}
						iconValue={value && <i className={classNames(value, styles.icon)} />}
					/>
				}
			>
				<div className={styles.iconsContainer}>
					{icons?.map(icon => (
						<IconItem
							onSelect={handleChange}
							icon={icon}
							isActive={icon === value}
							key={icon}
						/>
					))}
				</div>
			</SelectPopup>
		</div>
	);
};
