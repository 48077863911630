import {useMemo} from 'react';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {IPresaleCheckAddingEntityType} from '../../model/slice/presaleCheckAdding/interfaces';
import {extractPresaleCheckAddingSettings} from '../../model/slice/presaleCheckAdding/selectors';

export const useEntityPresaleCheckType = (
	objectId: string,
	types: IPresaleCheckAddingEntityType[]
) => {
	const {entityType} = useAppSelector(extractPresaleCheckAddingSettings);
	return useMemo<IPresaleCheckAddingEntityType | undefined>(() => {
		if (types.includes(entityType)) {
			return entityType;
		}
		return types.length ? types[0] : undefined;
	}, [entityType, types]);
};
