import {queryClient} from '@src/api/QueryClient';
import {makeResponsibilityRuleAddRequest} from '@src/api/backend/responsibilityRules';
import {responsibilityRulesQueryKeys} from '@src/api/cache/responsibilityRules/keys';
import {useMutation} from '@tanstack/react-query';
import {ISavingResponsibilityRule} from '@tehzor/tools/interfaces/responsibilityRules/ISavingResponsibilityRule';
import {addErrorToast, addSuccessToast} from '@tehzor/ui-components';
import {useTranslation} from 'react-i18next';

export interface IAddResponsibilityRulesParams {
	groupId: string;
	rule: ISavingResponsibilityRule;
}

export const useAddResponsibilityRules = () => {
	const {t} = useTranslation();

	return useMutation({
		mutationFn: async (params: IAddResponsibilityRulesParams) => {
			const {groupId, rule} = params;
			return makeResponsibilityRuleAddRequest(groupId, rule);
		},
		mutationKey: responsibilityRulesQueryKeys.add(),
		onSuccess: () => {
			void queryClient.invalidateQueries({queryKey: responsibilityRulesQueryKeys.list()});
			addSuccessToast({title: t('toast.addResponsibilityRules.success.title')});
		},
		onError: () => {
			addErrorToast({title: t('toast.addResponsibilityRules.error.title')});
		}
	});
};
