import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {ICustomFieldsPageSettingsState} from './interfaces';

export const getInitialStateForPage = (): ICustomFieldsPageSettingsState => ({
	selectedRows: [],
	pageSize: 20,
	offset: 0
});

const customFieldsSlice = createSlice({
	name: 'customFields',
	initialState: getInitialStateForPage,
	reducers: {
		changeOffset(state, action: PayloadAction<number>) {
			state.offset = action.payload;
		},
		changePageSize(state, action: PayloadAction<number>) {
			state.pageSize = action.payload;
		},
		changeSelectedRows(state, action: PayloadAction<string[]>) {
			state.selectedRows = action.payload;
		}
	}
});

export const {actions: customFieldsActions, reducer: customFieldsReducer} = customFieldsSlice;
