import {memo} from 'react';
import AppBreadcrumbs from '@src/components/AppBreadcrumbs';
import {useIsDesktop} from '@tehzor/ui-components/src/utils/mediaQueries';
import {IPresaleCheck} from '@tehzor/tools/interfaces/presaleChecks/IPresaleCheck';
import {MainActionsDesktop} from '@/features/PresaleCheck';
import {usePresaleCheckBreadcrumbs} from '@/entities/PresaleCheck/model/hooks/usePresaleCheckBreadcrumbs';
import styles from '../../ui/PresaleCheckPage.module.less';

interface IPageBreadcrumbsProps {
	objectId: string;
	presaleCheck: IPresaleCheck;
}

const PageBreadcrumbs = ({objectId, presaleCheck}: IPageBreadcrumbsProps) => {
	const breadcrumbs = usePresaleCheckBreadcrumbs(objectId, presaleCheck.id);
	const isDesktop = useIsDesktop();

	return (
		<AppBreadcrumbs
			className={styles.breadcrumbs}
			items={breadcrumbs}
		>
			{isDesktop && (
				<MainActionsDesktop
					objectId={objectId}
					presaleCheck={presaleCheck}
				/>
			)}
		</AppBreadcrumbs>
	);
};

export default memo(PageBreadcrumbs);
