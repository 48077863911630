import {memo, useCallback, useContext} from 'react';
import {IconButton} from '@tehzor/ui-components';
import {CellProps} from 'react-table';
import {DispatchActionCtx} from './ClaimsTable';
import ClickPreventWrap from '@src/components/tableCells/ClickPreventWrap';
import {IPreparedWarrantyClaimEntity} from '../../interfaces/IPreparedWarrantyClaimEntity';

const deleteIcon = <i className="tz-delete" />;

export const MenuCell = memo(({row}: CellProps<IPreparedWarrantyClaimEntity>) => {
	const {canDelete} = row.original.data;

	const dispatchAction = useContext(DispatchActionCtx);

	const deleteCheck = useCallback(() => {
		dispatchAction({type: 'delete', payload: row.original.data});
	}, [row.original, dispatchAction]);

	const menuActions = [];
	if (canDelete) {
		menuActions.push(
			<IconButton
				key="delete"
				type="inline-blue-accent"
				className="menu-item_red"
				onClick={deleteCheck}
			>
				{deleteIcon}
			</IconButton>
		);
	}

	return menuActions.length ? <ClickPreventWrap>{menuActions}</ClickPreventWrap> : null;
});
