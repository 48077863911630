import {IFieldSettingsSet} from '@tehzor/tools/interfaces/fields';
import styles from './Main.module.less';
import {FieldsSettingsTable} from '@/widgets/FieldsSettingsTable';
import {EditingFieldsSettingsSet} from '../../components/EditingFieldsSettingsSet/EditingFieldsSettingsSet';

interface IMainProps {
	fieldsSettingsSet?: IFieldSettingsSet;
}

export const Main = ({fieldsSettingsSet}: IMainProps) => (
	<div className={styles.wrapper}>
		<EditingFieldsSettingsSet fieldsSettingsSet={fieldsSettingsSet} />
		{fieldsSettingsSet && <FieldsSettingsTable fieldsSettingsSet={fieldsSettingsSet} />}
	</div>
);
