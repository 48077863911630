import {useMemo} from 'react';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractCheckRecordByListIds} from '@src/store/modules/pages/checkLists/selectors/records';
import {ICheckList} from '@tehzor/tools/interfaces/checkLists/ICheckList';
import {CheckRecordStatusId} from '@tehzor/tools/interfaces/checkRecords/ICheckRecordStatus';

interface CheckListStatusesAsMap {
	[key: string]: {
		id: CheckRecordStatusId;
		checkListIds: string[];
		count: number;
	};
}

export const useCheckListStatuses = (checkLists: ICheckList[], workAcceptanceId: string) => {
	// Получаем записи о чек-листах по актуальной приёмке.
	// Только запись чек-листа содержит его статус.
	const checkRecords = useAppSelector(s =>
		extractCheckRecordByListIds(
			s,
			checkLists.map(c => c.id),
			workAcceptanceId
		)
	);

	return useMemo(() => {
		// Создаем структуру включающую список статусов и количество
		// соответствующих чек-листов в каждом статусе из записей чек-листов
		const checkListStatusesAsMap = checkRecords.reduce<CheckListStatusesAsMap>((acc, next) => {
			const currentCount = acc[next.status]?.count || 0;

			const currentCheckLists = acc[next.status]?.checkListIds || [];
			currentCheckLists.push(next.links.checkListId);

			acc[next.status] = {
				id: next.status,
				checkListIds: currentCheckLists,
				count: currentCount + 1
			};
			return acc;
		}, {});

		// Поскольку может сложиться ситуация, что чек-лист есть, а записей
		// для данного чек-листа нет, проверяем соответствует ли количество
		// чек-листов и записей этих чек-листов.
		if (checkLists.length === checkRecords.length) {
			return checkListStatusesAsMap;
		}

		// Если чек-листы есть, а записей этих чек листов нет, сопоставляем эти сущности.
		for (const checkList of checkLists) {
			let isExistInMap = false;

			for (const checkListStatuse of Object.values(checkListStatusesAsMap)) {
				if (checkListStatuse.checkListIds.includes(checkList.id)) {
					isExistInMap = true;
				}
			}

			if (!isExistInMap) {
				if (checkListStatusesAsMap[CheckRecordStatusId.NOT_CHECKED]) {
					// Если объект для статуса `NOT_CHECKED` уже есть,
					// увеличиваем его счетчик.
					const currentCount =
						checkListStatusesAsMap[CheckRecordStatusId.NOT_CHECKED].count || 0;
					checkListStatusesAsMap[CheckRecordStatusId.NOT_CHECKED].count =
						currentCount + 1;
				} else {
					// Если объекта для статуса `NOT_CHECKED` нет,
					// создает новый объект.
					checkListStatusesAsMap[CheckRecordStatusId.NOT_CHECKED] = {
						id: CheckRecordStatusId.NOT_CHECKED,
						checkListIds: [checkList.id],
						count: 1
					};
				}
			}
		}

		return checkListStatusesAsMap;
	}, [checkLists, checkRecords]);
};
