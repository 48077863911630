import {useMutation} from '@tanstack/react-query';
import {tasksQueryKeys} from '@src/api/cache/tasks/keys';
import {IMakeTaskEditRequestData, makeTaskEditRequest} from '@src/api/backend/task';
import {queryClient} from '@src/api/QueryClient';
import {addErrorToast, addSuccessToast} from '@tehzor/ui-components';
import {useTranslation} from 'react-i18next';

export const useEditTask = () => {
	const {t} = useTranslation();
	return useMutation({
		mutationKey: tasksQueryKeys.edit(),
		mutationFn: (data: IMakeTaskEditRequestData) => makeTaskEditRequest(data),
		onError: () => addErrorToast({title: t('toast.editTask.error.title')}),
		onSuccess: data => {
			queryClient.setQueryData(tasksQueryKeys.detail(data.id), task =>
				task ? {...task, ...data} : data
			);
			void queryClient.invalidateQueries({queryKey: tasksQueryKeys.all()});
			addSuccessToast({
				title: t('toast.editTask.success.title')
			});
		}
	});
};
