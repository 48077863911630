import {useQuery, UseQueryOptions} from '@tanstack/react-query';
import IError from '@tehzor/tools/interfaces/IError';
import {delegationHistoryQueryKeys} from '@src/api/cache/delegationHistory/keys';
import {
	IGetDelegationHistoriesParams,
	IGetDelegationHistoriesResponse
} from '@src/api/backend/delegationHistories';
import {IDelegationHistory} from '@tehzor/tools/interfaces/delegationHistories/IDelegationHistory';
import {extractDelegationHistoriesAsArray} from './selectors';
import {useCallback} from 'react';
import {WorkingGroupTypeId} from '@tehzor/tools/interfaces/workingGroups/IWorkingGroupType';
import {useTranslation} from 'react-i18next';

export const useDelegationHistory = <T = IGetDelegationHistoriesResponse>(
	delegationHistoriesParams: IGetDelegationHistoriesParams,
	error: string,
	select?: (data: IGetDelegationHistoriesResponse) => T,
	options?: Partial<UseQueryOptions<IGetDelegationHistoriesResponse, IError, T>>
) =>
	useQuery<IGetDelegationHistoriesResponse, IError, T>({
		queryKey: delegationHistoryQueryKeys.histories(delegationHistoriesParams),
		meta: {
			error
		},
		select,
		...options
	});

export const useDelegationHistoryAsArray = (
	delegationHistoriesParams: IGetDelegationHistoriesParams,
	error: string,
	options?: Partial<
		UseQueryOptions<IGetDelegationHistoriesResponse, IError, IDelegationHistory[]>
	>
) => {
	const selector = useCallback(
		(data: IGetDelegationHistoriesResponse) => extractDelegationHistoriesAsArray(data),
		[]
	);

	return useDelegationHistory(delegationHistoriesParams, error, selector, {
		...options,
		enabled: !!delegationHistoriesParams.objectId
	});
};

export const useDelegationHistoryAsArrayByInspection = (objectId: string, inspectionId: string) => {
	const {t} = useTranslation();
	return useDelegationHistoryAsArray(
		{
			objectId,
			inspectionId,
			workingGroupType: WorkingGroupTypeId.PERFORMERS
		},
		t('delegationHistory.error.byPerformers')
	);
};

export const useDelegationHistoryAsArrayByWorkAcceptance = (
	objectId: string,
	workAcceptanceId: string,
	workingGroupType: WorkingGroupTypeId.ACCEPTORS | WorkingGroupTypeId.SUBMITTERS
) => {
	const {t} = useTranslation();
	return useDelegationHistoryAsArray(
		{
			objectId,
			workAcceptanceId,
			workingGroupType
		},
		workingGroupType === WorkingGroupTypeId.ACCEPTORS
			? t('delegationHistory.error.byAcceptors')
			: t('delegationHistory.error.bySubmitters')
	);
};

export const useDelegationHistoryAsArrayByProblemPerformers = (
	objectId: string,
	problemId: string
) => {
	const {t} = useTranslation();
	return useDelegationHistoryAsArray(
		{
			objectId,
			problemId,
			workingGroupType: WorkingGroupTypeId.PERFORMERS
		},
		t('delegationHistory.error.byPerformers')
	);
};

export const useDelegationHistoryAsArrayByProblemInspectors = (
	objectId: string,
	problemId: string
) => {
	const {t} = useTranslation();
	return useDelegationHistoryAsArray(
		{
			objectId,
			problemId,
			workingGroupType: WorkingGroupTypeId.INSPECTORS
		},
		t('delegationHistory.error.byInspectors')
	);
};

export const useDelegationHistoryAsArrayByTaskResponsibles = (objectId: string, taskId: string) => {
	const {t} = useTranslation();
	return useDelegationHistoryAsArray(
		{
			objectId,
			taskId
		},
		t('delegationHistory.error.byPerformers')
	);
};
