import {getDesktopColumns} from '@src/pages/InspectionsPage/utils/getDesktopColumns';
import {TFunction} from 'i18next';
import {getMobileColumns} from '@src/pages/InspectionsPage/utils/getMobileColumns';
import {useColumnsWithSettings} from '@src/core/hooks/settings/useColumnsWithSettings/useColumnsWithSettings';
import {IEntitySettings} from '@src/core/hooks/settings/useEntitySettings';
import {useCallback, useMemo} from 'react';
import {Column} from 'react-table';
import {IInspectionEntity} from '@src/interfaces/IInspectionsEntity';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {inspectionsActions} from '@src/store/modules/settings/pages/inspections/slice';
import {ITableSettings} from '@src/interfaces/ITableSettings';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractInspectionsPageSettings} from '@src/store/modules/settings/pages/inspections/selectors';
import {useObjectChildrenIds} from '@src/core/hooks/queries/objects/hooks';

interface IUseInspectionsTableColumnsProps {
	objectId: string;
	isDesktop: boolean;
	settings?: IEntitySettings;
	t: TFunction;
}
export const useInspectionsTableColumns = ({
	objectId,
	isDesktop,
	settings,
	t
}: IUseInspectionsTableColumnsProps): [Array<Column<IInspectionEntity>>, number] => {
	const dispatch = useAppDispatch();
	const isNestedObjects = useObjectChildrenIds(objectId);

	const {tableSettings} = useAppSelector(s => extractInspectionsPageSettings(s, objectId));
	const customizedColumns = useMemo(
		() =>
			isDesktop
				? getDesktopColumns(objectId, t, settings, !!isNestedObjects?.length)
				: getMobileColumns(objectId, t),
		[objectId, isDesktop, t, settings]
	);
	const {changeTableSettings} = inspectionsActions;

	const onChangeTableSettings = useCallback(
		(newTableSettings: ITableSettings) => {
			dispatch(changeTableSettings({objectId, tableSettings: newTableSettings}));
		},
		[dispatch, changeTableSettings, objectId]
	);

	return useColumnsWithSettings({
		customizedColumns,
		tableSettings,
		entitySettings: settings,
		onChangeTableSettings
	});
};
