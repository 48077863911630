import {onlineManager, useQueryClient} from '@tanstack/react-query';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractOfflineModeAvailability} from '@src/store/modules/settings/offlineMode/selectors/availability';
import {IObject} from '@tehzor/tools/interfaces/objects/IObject';
import {useNextLocalNumber} from '@src/core/hooks/useNextLocalNumber';
import {addNewEntityToNormalizedData} from '@src/utils/addNewEntityToNormalizedData';
import {IPresaleCheck} from '@tehzor/tools/interfaces/presaleChecks/IPresaleCheck';
import {toPresaleCheck} from '../../../utils/convertToLocalSave';
import {IGetPresaleChecksResponse} from '@/shared/api/presaleChecks/getPresaleChecks';
import {IAddPresaleCheckParams} from '../../cache/defaults/usePresaleChecksMutationDefaults';
import {addWarningToast} from '@tehzor/ui-components';
import {
	presaleChecksQueryKeys,
	useAddPresaleCheckMutation,
	useLocalPresaleChecks
} from '@/entities/PresaleCheck';
import {useTranslation} from 'react-i18next';

export const useAddPresaleCheck = (object?: IObject) => {
	const {t} = useTranslation();
	const queryClient = useQueryClient();
	const online = onlineManager.isOnline();
	const user = useAppSelector(s => s.auth.profile);
	const isOfflineModeAvailable = useAppSelector(extractOfflineModeAvailability);
	const {data: localPresaleChecks} = useLocalPresaleChecks();
	const nextNumber = useNextLocalNumber<IPresaleCheck>(localPresaleChecks, 'createdAt');
	const mutation = useAddPresaleCheckMutation();

	return ({objectId, links}: Omit<IAddPresaleCheckParams, 'key'>) => {
		const key = Date.now().toString(10);

		const converted = toPresaleCheck(user, objectId, links, key, nextNumber, object);
		queryClient.setQueryData<IPresaleCheck | undefined>(
			[...presaleChecksQueryKeys.detail(key), objectId],
			converted
		);

		if (!online && isOfflineModeAvailable) {
			addWarningToast({
				title: t('toast.addPresaleCheckLocal.warning.title')
			});
			void queryClient.refetchQueries({queryKey: presaleChecksQueryKeys.localList()});
			queryClient.setQueryData<IGetPresaleChecksResponse>(
				presaleChecksQueryKeys.latest(),
				oldData => {
					if (!oldData) return undefined;
					return addNewEntityToNormalizedData<IGetPresaleChecksResponse, IPresaleCheck>(
						oldData,
						converted
					);
				}
			);
		}
		mutation.mutate({
			objectId,
			links,
			key
		});
		return converted;
	};
};
