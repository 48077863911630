import _ from 'lodash';
import {IGetPresaleChecksResponse} from '@/shared/api/presaleChecks/getPresaleChecks';
import {extractLatestPresaleChecksAsArray} from './extractLatestPresaleChecksAsArray';

export const extractLatestPresaleCheckId = (
	data: IGetPresaleChecksResponse,
	userId: string,
	objectId: string,
	spaceId?: string
) => {
	const latestPresaleChecks = extractLatestPresaleChecksAsArray(data, userId, objectId, spaceId);
	if (latestPresaleChecks.length) {
		return _.maxBy(latestPresaleChecks, 'modifiedAt')?.id;
	}
	return undefined;
};
