import {useMemo} from 'react';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {hasPermission} from '@tehzor/tools/utils/findPermission';
import {UserRoleScopes} from '@tehzor/tools/interfaces/IUser';

export interface IProblemsPermissions {
	canView?: boolean;
	canAdd?: boolean;
	canEdit?: boolean;
	canDelete?: boolean;
}

/**
 * Возвращает полномочие на создание, просмотр, изменение, удаление нарушения
 *
 * @param objectId id объекта
 */
export const useProblemsPermissions = (objectId?: string): IProblemsPermissions => {
	const user = useAppSelector(s => s.auth.profile);

	return useMemo(() => {
		if (!user || !user.roles || !objectId) {
			return {};
		}
		return {
			canAdd: hasPermission(user.roles, 'problems-add', UserRoleScopes.OBJECT, objectId),
			canView: hasPermission(user.roles, 'problemsView'),
			canEdit: hasPermission(user.roles, 'problemsEdit', UserRoleScopes.OBJECT, objectId),
			canDelete: hasPermission(user.roles, 'problemsDelete', UserRoleScopes.OBJECT, objectId)
		};
	}, [objectId, user]);
};
