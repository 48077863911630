import {IFieldSettingsSet} from '@tehzor/tools/interfaces/fields';
import {IBreadcrumb} from '@tehzor/ui-components/src/components/navigation/Breadcrumbs';
import {
	fieldsPageBreadcrumb,
	fieldsPageUrl
} from '@/shared/constants/fieldsPages/fieldsPageBreadcrumb';

export const getFieldsSettingsSetPageBreadcrumbs = (fieldsSettingsSet?: IFieldSettingsSet) => {
	const breadcrumb: IBreadcrumb = {
		label: 'Набор настроек полей',
		url: `${fieldsPageUrl}/fields-settings-set/${
			fieldsSettingsSet ? fieldsSettingsSet.id : 'add'
		}`
	};
	return [fieldsPageBreadcrumb, breadcrumb];
};
