import {IInternalAcceptance} from '@tehzor/tools/interfaces/internalAcceptances/IInternalAcceptance';
import DesktopMenu from './Menu.desktop';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {useInternalAcceptancePermissions} from '@src/core/hooks/permissions/useInternalAcceptancePermissions';
import {changeEntitiesVisibility} from '@src/store/modules/settings/pages/internalAcceptance/actions';
import VisibilityFilter from '@src/components/VisibilityFilter';
import {useInternalAcceptanceProblemsArray} from '@src/core/hooks/queries/internalAcceptance/problems/hooks';
import {useMemo} from 'react';
import {useTranslation} from 'react-i18next';

interface IDesktopActionsProps {
	objectId: string;
	internalAcceptance: IInternalAcceptance;
}

const DesktopActions = ({objectId, internalAcceptance}: IDesktopActionsProps) => {
	const {t} = useTranslation();
	const {data: problems} = useInternalAcceptanceProblemsArray(objectId, internalAcceptance.id);
	const permissions = useInternalAcceptancePermissions(objectId, internalAcceptance, problems);
	const visibility = useAppSelector(s => s.settings.pages.internalAcceptance.entitiesVisibility);

	const visibilityTitles = useMemo(
		() => ({
			problems: t('internalAcceptancePage.visibilityFilter.problems'),
			inspections: t('internalAcceptancePage.visibilityFilter.inspections')
		}),
		[t]
	);

	return (
		<div className="internal-acceptance-page__d-controls">
			<div className="internal-acceptance-page__d-controls-left">
				<VisibilityFilter
					value={visibility}
					titlesMap={visibilityTitles}
					onChange={changeEntitiesVisibility}
				/>
			</div>

			<div className="internal-acceptance-page__d-controls-right">
				<DesktopMenu
					objectId={objectId}
					internalAcceptance={internalAcceptance}
					permissions={permissions}
				/>
			</div>
		</div>
	);
};

export default DesktopActions;
