import {OfflineDataItem} from '@tehzor/ui-components';
import {useCacheStates} from '../hooks/useCacheStates';
import {useTranslation} from 'react-i18next';
import {presaleChecksQueryKeys} from '@/entities/PresaleCheck';

export const LastUserPresaleChecksCacheStatus = () => {
	const {t} = useTranslation();
	const [isFetching, isError, isStale, isPaused, dataUpdatedAt] = useCacheStates(
		presaleChecksQueryKeys.latest()
	);
	return (
		<OfflineDataItem
			className="offline-mode-dialog__item"
			title={t(
				'components.offlineModeDialog.lastUserPresaleChecksCacheStatus.offlineDataItem.title'
			)}
			icon={<i className="tz-internal-acceptance-24" />}
			date={dataUpdatedAt}
			error={
				isError
					? t('cacheStatusErrors.error')
					: isStale
					? t('cacheStatusErrors.stale')
					: undefined
			}
			inProgress={isPaused || isFetching}
			progressTitle={t(
				'components.offlineModeDialog.objectsCacheStatus.offlineDataItem.progressTitle.default'
			)}
		/>
	);
};
