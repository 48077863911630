import {Toggle} from '@tehzor/ui-components';
import {IPreparedField, useFieldsIsUpdating} from '@/features/Fields';
import styles from './Toggles.module.less';
import {useEditFieldSettings} from '@/entities/FieldSettings';

interface IFieldEnabledToggleProps {
	field: IPreparedField;
}

export const FieldCopyableToggle = ({field}: IFieldEnabledToggleProps) => {
	const {mutateAsync: editFieldSettings} = useEditFieldSettings();
	const {isUpdating} = useFieldsIsUpdating();

	const handleCopyableChange = async () => {
		if (isUpdating) return;
		if (field.settingsId && field.canBeCopied) {
			await editFieldSettings({settingId: field.settingsId, copyable: !field.copyable});
		}
	};

	return (
		<div className={styles.wrapper}>
			<p className={styles.title}>Включить</p>
			<Toggle
				checked={field.enabled}
				onChange={handleCopyableChange}
			/>
		</div>
	);
};
