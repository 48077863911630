import {addToast, Button, ToastsContainersIds} from '@tehzor/ui-components';
import {TFunction} from 'i18next';

export const addEmailNotificationsToast = (
	handleChange: (toastId: number | string, value: boolean) => Promise<void>,
	t: TFunction
) => {
	const toastId = addToast({
		containerId: ToastsContainersIds.MAIN_STACKED,
		title: t('emailNotificationsToast.title'),
		description: t('emailNotificationsToast.description'),
		actions: (
			<>
				<Button
					onClick={() => handleChange(toastId, false)}
					type="cancel"
					label={t('emailNotificationsToast.button.cancel')}
				/>
				<Button
					onClick={() => handleChange(toastId, true)}
					type="accent-blue"
					label={t('emailNotificationsToast.button.accept')}
				/>
			</>
		),
		options: {
			autoClose: 5000
		}
	});
};
