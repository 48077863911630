import {IPreparedField} from '@/features/Fields';
import {flexRender, getCoreRowModel, useReactTable} from '@tanstack/react-table';
import styles from './BodyTable.module.less';
import {memo} from 'react';
import {getBodyColumns} from '../../model/utils/getBodyColumns';

interface IBodyTableProps {
	fields: IPreparedField[];
	withSettings: boolean;
	isBuiltIn: boolean;
}

export const BodyTable = memo(({fields, withSettings, isBuiltIn}: IBodyTableProps) => {
	const table = useReactTable({
		data: fields,
		columns: getBodyColumns({withInfo: !isBuiltIn, withSettings}),
		getCoreRowModel: getCoreRowModel()
	});

	return (
		<div className={styles.wrapper}>
			<table className={styles.table}>
				<thead>
					{table.getHeaderGroups().map(group => (
						<tr
							className={styles.headerRow}
							key={group.id}
						>
							{group.headers.map(header => (
								<th
									className={styles.headerCell}
									key={header.id}
									style={{width: `${header.column.columnDef.size}%`}}
								>
									{flexRender(
										header.column.columnDef.header,
										header.getContext()
									)}
								</th>
							))}
						</tr>
					))}
				</thead>
				<tbody className={styles.tableBody}>
					{table.getRowModel().rows.map(row => (
						<tr
							className={styles.row}
							key={row.id}
						>
							{row.getVisibleCells().map(cell => (
								<td
									className={styles.cell}
									key={cell.id}
								>
									{flexRender(cell.column.columnDef.cell, cell.getContext())}
								</td>
							))}
						</tr>
					))}
				</tbody>
			</table>
		</div>
	);
});
