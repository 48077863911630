import {
	IProblemsFiltersState,
	IProblemsSortState
} from '@src/store/modules/settings/pages/problems/interfaces';

export type IProblemsListQueryKey = [] & {
	0: string; // problems
	1: string; // list
	2: string; // objectId
	3: IProblemsFiltersState; // filter
	4: IProblemsSortState; // sort
	5: number; // offset
	6: number; // pageSize
};

export type IProblemsCommentsQueryKey = [] & {
	0: string; // problems
	1: string; // comments
	2: IProblemsFiltersState; // filter
	3: IProblemsSortState; // sort
	4: number; // offset
	5: number; // pageSize
};

export const problemsQueryKeys = {
	all: () => ['problems'],
	one: () => ['problem'],
	list: () => [...problemsQueryKeys.all(), 'list'],
	localList: () => [...problemsQueryKeys.all(), 'local-list'],
	add: () => [...problemsQueryKeys.one(), 'add'],
	savingDatas: () => [...problemsQueryKeys.one(), 'saving-data'],
	savingData: (key: string) => [...problemsQueryKeys.one(), 'saving-data', key],
	details: () => [...problemsQueryKeys.one(), 'detail'],
	detail: (problemId: string) => [...problemsQueryKeys.one(), 'detail', problemId],
	edit: () => [...problemsQueryKeys.one(), 'edit'],
	export: () => [...problemsQueryKeys.all(), 'export'],
	comments: () => [...problemsQueryKeys.all(), 'comments']
};
