import {useCallback} from 'react';
import {IconButton} from '@tehzor/ui-components';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {CacheStatus} from '@tehzor/tools/enums/CacheStatus';
import {ObjectsCacheStatus} from './ObjectsCacheStatus';
import {CategoriesCacheStatus} from './CategoriesCacheStatus';
import {PlansCacheStatus} from './PlansCacheStatus';
import {ReasonsCacheStatus} from './ReasonsCacheStatus';
import {CheckListsCacheStatus} from './CheckListsCacheStatus';
import {GroupsAndRulesCacheStatus} from './GroupsAndRulesCacheStatus';
import {ProblemTemplatesCacheStatus} from './ProblemTemplatesCacheStatus';
import {RestDictionariesCacheStatus} from './RestDictionariesCacheStatus';
import {usePermissions} from '../hooks/usePermissions';
import {LastUserChecksCacheStatus} from './LastUserChecksCacheStatus';
import {LastUserPresaleChecksCacheStatus} from './LastUserPresaleChecksCacheStatus';
import {SpacesCacheStatus} from './SpacesCacheStatus';
import {LastUserInternalAcceptancesCacheStatus} from './LastUserInternalAcceptancesCacheStatus';
import {startCachingResources} from '@src/core/offlineMode/startCachingResources';
import {CheckListsRecordsCacheStatus} from './CheckListsRecordsCacheStatus';
import {SpacesTypesCountCacheStatus} from './SpacesTypesCountCacheStatus';
import {FieldsCacheStatus} from './FieldsCacheStatus';
import {useTranslation} from 'react-i18next';

export const EntitiesCache = () => {
	const {t} = useTranslation();
	const {networkStatus, cacheStatus, dictionariesCacheState} = useAppSelector(s => s.offlineMode);
	const roles = useAppSelector(s => s.auth.profile.roles);
	const {objects} = useAppSelector(s => s.settings.offlineMode.cache);
	const permissions = usePermissions();

	const manualUpdate = useCallback(async () => {
		await startCachingResources(roles, true, objects);
	}, [roles, objects]);

	const {objectsFiles, plansFiles} = dictionariesCacheState;

	return (
		<>
			<div className="offline-mode-dialog__section-header">
				<div className="offline-mode-dialog__section-header-text-block">
					<div className="offline-mode-dialog__section-header-title">
						{t('components.offlineModeDialog.entitiesCache.title')}
					</div>
					{cacheStatus === CacheStatus.ERROR && (
						<div className="offline-mode-dialog__section-header-error">
							{t('components.offlineModeDialog.entitiesCache.error')}
						</div>
					)}
				</div>
				{networkStatus ? (
					<IconButton
						className="offline-mode-dialog__section-header-btn"
						onClick={manualUpdate}
					>
						<i className="tz-reload-24" />
					</IconButton>
				) : null}
			</div>
			<div>
				{permissions.canViewObjects ? (
					<ObjectsCacheStatus filesCacheState={objectsFiles} />
				) : null}
				{permissions.canViewSpaces ? <SpacesCacheStatus /> : null}
				{permissions.canViewSpaces ? <SpacesTypesCountCacheStatus /> : null}
				{permissions.canViewCategories ? <CategoriesCacheStatus /> : null}
				{permissions.canViewPlans ? (
					<PlansCacheStatus filesCacheState={plansFiles} />
				) : null}
				{permissions.canViewReasons ? <ReasonsCacheStatus /> : null}
				{permissions.canViewCheckLists ? <CheckListsCacheStatus /> : null}
				{permissions.canViewCheckLists ? <CheckListsRecordsCacheStatus /> : null}
				{permissions.canViewWorkingGroups ? <GroupsAndRulesCacheStatus /> : null}
				{permissions.canViewProblemTemplates ? <ProblemTemplatesCacheStatus /> : null}
				{permissions.canViewChecks ? <LastUserChecksCacheStatus /> : null}
				{permissions.canViewInternalAcceptances ? (
					<LastUserInternalAcceptancesCacheStatus />
				) : null}
				{permissions.canViewPresaleChecks ? <LastUserPresaleChecksCacheStatus /> : null}
				{permissions.canViewFields ? <FieldsCacheStatus /> : null}
				<RestDictionariesCacheStatus />
			</div>
		</>
	);
};
