import {
	IEditableEntityAction,
	IEditableEntityState,
	isEntityEdited,
	isPropEdited
} from '@tehzor/tools/core/states/editableEntityState';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {IEntityType, IFieldSettingsSet} from '@tehzor/tools/interfaces/fields';
import {ISavingFieldsSettingsSet} from '@src/interfaces/saving/ISavingFieldsSettingsSet';

export type IEditableFieldsSettingsSetState = IEditableEntityState<{
	name?: string;
	description?: string;
	companyId?: string;
	objectIds?: string[];
	stageIds?: ObjectStageIds[];
	entityType?: IEntityType;
}>;

export type IEditableFieldsSettingsSetAction = IEditableEntityAction<
	IEditableFieldsSettingsSetState,
	ISavingFieldsSettingsSet
>;

const makeEmptyState = (): IEditableFieldsSettingsSetState => ({
	name: undefined,
	description: undefined,
	companyId: undefined,
	objectIds: undefined,
	stageIds: undefined,
	entityType: undefined,
	errors: {
		name: false,
		description: false,
		companyId: false,
		objectIds: false,
		stageIds: false,
		entityType: false
	}
});

export const init = (set?: IFieldSettingsSet): IEditableFieldsSettingsSetState => {
	const empty = makeEmptyState();

	return set
		? {
				name: set.name,
				description: set.description,
				companyId: set.companyId,
				objectIds: set.objectIds,
				stageIds: set.stageIds,
				entityType: set.entityType,
				errors: empty.errors
		  }
		: empty;
};

export const isEdited = (
	state: IEditableFieldsSettingsSetState,
	original?: IFieldSettingsSet
): boolean =>
	isEntityEdited(
		state,
		original,
		isPropEdited.bind(null, 'name'),
		isPropEdited.bind(null, 'description'),
		isPropEdited.bind(null, 'companyId'),
		isPropEdited.bind(null, 'objectIds'),
		isPropEdited.bind(null, 'stageIds'),
		isPropEdited.bind(null, 'entityType')
	);

export const errorsFns = {
	name: (state: IEditableFieldsSettingsSetState) => !state.name,
	description: (state: IEditableFieldsSettingsSetState) => !state.description,
	companyId: (state: IEditableFieldsSettingsSetState) => !state.companyId,
	objectIds: (state: IEditableFieldsSettingsSetState) => !state.objectIds,
	stageIds: (state: IEditableFieldsSettingsSetState) => !state.stageIds?.length,
	entityType: (state: IEditableFieldsSettingsSetState) => !state.entityType
};

export const convertFieldsSettingsSetToSave = (
	state: IEditableFieldsSettingsSetState,
	original?: IFieldSettingsSet
) => {
	const fieldsSettingSet: ISavingFieldsSettingsSet = {};

	if (isPropEdited('name', state, original)) {
		fieldsSettingSet.name = state.name;
	}

	if (isPropEdited('description', state, original)) {
		fieldsSettingSet.description = state.description;
	}

	if (isPropEdited('companyId', state, original)) {
		fieldsSettingSet.companyId = state.companyId;
	}

	if (isPropEdited('objectIds', state, original)) {
		fieldsSettingSet.objectIds = state.objectIds;
	}

	if (isPropEdited('stageIds', state, original)) {
		fieldsSettingSet.stageIds = state.stageIds;
	}

	if (isPropEdited('entityType', state, original)) {
		fieldsSettingSet.entityTypeId = state.entityType?.id;
	}

	return fieldsSettingSet;
};
