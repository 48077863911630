import {ReactNode, useCallback, useEffect, useState, useMemo} from 'react';
import {
	convertToLocalSave as convertFilesToLocalSave,
	isEdited as isFilesExist,
	someFilesHaveError,
	useUploadingFilesState
} from '@src/core/hooks/states/useUploadingFilesState';
import {usePageReloadPrevent} from '@tehzor/tools/core/hooks/usePageReloadPrevent';
import useUpdateEffect from 'react-use/lib/useUpdateEffect';
import {hasErrors} from '@tehzor/tools/core/states/editableEntityState';
import {ISavingWorkAcceptance} from '@src/interfaces/saving/ISavingWorkAcceptance';
import {IWorkAcceptance} from '@tehzor/tools/interfaces/workAcceptances/IWorkAcceptance';
import {
	convertToSave,
	errorsFns,
	hasAttachmentsError,
	isEdited,
	useEditableWorkAcceptanceState
} from '@src/core/hooks/states/useEditableWorkAcceptanceState';
import {EditableWorkAcceptance} from '../EditableWorkAcceptance';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {useWorkAcceptanceSettings} from '@src/core/hooks/settings/useWorkAcceptanceSettings';
import {useIsFetching} from '@tanstack/react-query';
import useAppSelector from '@src/core/hooks/useAppSelector';

const getFieldsSettings = () => ({
	description: {fieldId: 'description', required: false},
	categoryId: {fieldId: 'categoryId', required: false},
	acceptanceDate: {fieldId: 'acceptanceDate', required: false},
	acceptors: {fieldId: 'acceptors', required: false},
	submitters: {fieldId: 'submitters', required: false},
	structureIds: {
		fieldId: 'structureIds',
		required: false
	},
	spaceIds: {fieldId: 'spaceIds', required: false},
	type: {fieldId: 'type', required: false},
	frontType: {fieldId: 'frontType', required: true},
	workScope: {fieldId: 'workScope', required: false},
	unit: {fieldId: 'unit', required: false},
	unitValue: {fieldId: 'unitValue', required: false},
	unitPlan: {fieldId: 'unitPlan', required: false},
	unitPlanValue: {fieldId: 'unitPlanValue', required: false},
	contractorLegalEntityId: {fieldId: 'contractorLegalEntityId', required: false},
	attachments: {fieldId: 'attachments', required: false}
});

/**
 * Логика редактирования и сохранения приёмки работ (новой или существующей)
 */
export const useEditableWorkAcceptance = (
	objectId: string,
	stage: ObjectStageIds,
	workAcceptance: IWorkAcceptance | undefined,
	saving: boolean
): [ReactNode, () => Promise<ISavingWorkAcceptance | undefined>, () => void, boolean] => {
	const fieldsSettings = useWorkAcceptanceSettings(objectId);
	const {custom} = fieldsSettings;
	const user = useAppSelector(s => s.auth.profile);
	// TODO сделать настройки полей Приёмки работ кастомизируемыми как у нарушений, осмотров и задач

	const builtin = getFieldsSettings();
	const defaultData = useMemo(
		() => workAcceptance || {submitters: [user.id]},
		[workAcceptance, user.id]
	);
	const [editingState, editingDispatch] = useEditableWorkAcceptanceState(defaultData);

	const [uploadingFilesState, uploadingFilesDispatch, waitUploading] = useUploadingFilesState();
	const [isBlocking, setIsBlocking] = useState(false);

	usePageReloadPrevent(isBlocking);

	useEffect(() => {
		setIsBlocking(
			isEdited(editingState, workAcceptance) || isFilesExist(uploadingFilesState.value)
		);
	}, [editingState, uploadingFilesState.value, workAcceptance]);

	const getSavingData = useCallback(async () => {
		const files = await waitUploading();
		// Проверка наличия ошибок в состояниях
		if (
			hasErrors(editingState, errorsFns, builtin) ||
			hasErrors(editingState, errorsFns, custom, true) ||
			(hasAttachmentsError(editingState, builtin) && !isFilesExist(files))
		) {
			editingDispatch({type: 'update-errors'});
			uploadingFilesDispatch({type: 'update-error'});
			return undefined;
		}
		// Проверка, были ли отредактированы поля
		if (
			!isEdited(editingState, workAcceptance) &&
			(!isFilesExist(files) || someFilesHaveError(files))
		) {
			return undefined;
		}
		const savingData = convertToSave(editingState, workAcceptance, true);

		return {
			...savingData,
			newAttachments: convertFilesToLocalSave(files)
		};
	}, [
		editingState,
		workAcceptance,
		editingDispatch,
		uploadingFilesDispatch,
		waitUploading,
		builtin,
		custom
	]);

	const reset = useCallback(() => {
		editingDispatch({
			type: 'reset',
			entity: defaultData
		});
		uploadingFilesDispatch({type: 'reset'});
	}, [defaultData, editingDispatch, uploadingFilesDispatch]);

	// Сброс данных для редактирования при изменении начальных данных
	useUpdateEffect(reset, [defaultData]);

	// TO-DO: Избавиться от этого безобразия, после переработки форм
	const isAllLoading = !!useIsFetching({});

	const fields = (
		<EditableWorkAcceptance
			objectId={objectId}
			editingState={editingState}
			editingDispatch={editingDispatch}
			fieldsSettings={{builtin, custom}}
			uploadingFilesState={uploadingFilesState}
			uploadingFilesDispatch={uploadingFilesDispatch}
			saving={saving || isAllLoading}
			stage={stage}
		/>
	);

	return [fields, getSavingData, reset, isBlocking];
};
