import {queryClient} from '@src/api/QueryClient';
import {requestAddWorkingGroup} from '@src/api/backend/workingGroup';
import {responsibilityRulesQueryKeys} from '@src/api/cache/responsibilityRules/keys';
import {workingGroupsQueryKeys} from '@src/api/cache/workingGroups/keys';
import {useMutation} from '@tanstack/react-query';
import {ISavingWorkingGroup} from '@tehzor/tools/interfaces/workingGroups/ISavingWorkingGroup';
import {addErrorToast, addSuccessToast} from '@tehzor/ui-components';
import {useTranslation} from 'react-i18next';

export const useAddWorkingGroup = () => {
	const {t} = useTranslation();

	return useMutation({
		mutationKey: workingGroupsQueryKeys.add(),
		mutationFn: (params: ISavingWorkingGroup) => requestAddWorkingGroup(params),
		onSuccess: () => {
			void queryClient.invalidateQueries({queryKey: workingGroupsQueryKeys.list()});
			void queryClient.invalidateQueries({queryKey: responsibilityRulesQueryKeys.list()});
			addSuccessToast({title: t('toast.addWorkingGroup.success.title')});
		},
		onError: () => {
			addErrorToast({title: t('toast.addWorkingGroup.error.title')});
		}
	});
};
