import InspectionsFiltersPage from '@src/pages/filters/InspectionsFiltersPage';
import CreationDateFilterPage from '@src/pages/filters/CreationDateFilterPage';
import CreatedByFilterPage from '@src/pages/filters/CreatedByFilterPage';
import {ObjectsFilterRoute} from '@src/pages/filters/ObjectsFilterPage';
import PlansFilterPage from '@src/pages/filters/PlansFilterPage/PlansFilterPage';
import CategoriesSetFilterPage from '@src/pages/filters/CategoriesSetFilterPage';
import {PerformersFilterPage} from '@src/pages/filters/PerformersFilterPage';
import {CustomFiltersPage} from '@src/pages/filters/CustomFiltersPages/CustoFiltersPage';

export const inspectionsFilterRoutes = [
	{
		path: 'plans',
		element: <PlansFilterPage />
	},
	{
		path: 'categories',
		element: <CategoriesSetFilterPage />
	},
	{
		path: 'objects',
		element: <ObjectsFilterRoute />
	},
	{
		path: 'performers',
		element: <PerformersFilterPage />
	},
	{
		path: 'createdBy',
		element: <CreatedByFilterPage />
	},
	{
		path: 'creationdate',
		element: <CreationDateFilterPage />
	},
	{
		path: 'custom/:fieldKey',
		element: <CustomFiltersPage />
	},
	{
		path: '',
		element: <InspectionsFiltersPage />
	}
];
