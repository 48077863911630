import {httpRequests} from '@tehzor/tools/api/httpRequests';
import {IFieldSetting} from '@tehzor/tools/interfaces/fields';

export interface IAddFieldSettingsRequest {
	setId: string;
	fieldId: string;
	order: number;
	canBeCopied: boolean;
}

export const requestAddFieldSettings = async (data: IAddFieldSettingsRequest) => {
	const res = await httpRequests.withToken.post<IFieldSetting>('field-settings', data);
	return res.data;
};
