import {useCallback} from 'react';
import * as React from 'react';
import {ISavingCheckItem} from '@tehzor/tools/interfaces/checkItems/ISavingCheckItem';
import {useSoftDeleteCheckItem} from '@src/core/hooks/mutations/checkItems/useSoftDeleteCheckItem';
import {useHardDeleteCheckItem} from '@src/core/hooks/mutations/checkItems/useHardDeleteCheckItem';
import {useRestoreCheckItem} from '@src/core/hooks/mutations/checkItems/useRestoreCheckItem';
import {useTranslation} from 'react-i18next';
import {useTranslatedConfirmDialog} from '@src/core/hooks/translations/useTranslatedConfirmDialog';

export const useItemArchivationHadler = (): [
	React.ReactNode,
	React.ReactNode,
	React.ReactNode,
	(item: ISavingCheckItem) => Promise<void>,
	(item: ISavingCheckItem) => Promise<void>,
	(item: ISavingCheckItem) => Promise<void>
] => {
	const {t} = useTranslation();
	const softDeleteItem = useSoftDeleteCheckItem();
	const hardDeleteItem = useHardDeleteCheckItem();
	const restoreItem = useRestoreCheckItem();

	const [softDeleteDialog, getSoftDeleteConfirmation] = useTranslatedConfirmDialog({
		title: t('useConfirmDialog.category.deleteTitle'),
		message: t('useConfirmDialog.category.deleteMessage')
	});
	const [hardDeleteDialog, getHardDeleteConfirmation] = useTranslatedConfirmDialog({
		title: t('useConfirmDialog.category.deleteTitle'),
		message: t('useConfirmDialog.category.hardDeleteMessage')
	});
	const [restoreDialog, getRestoreConfirmation] = useTranslatedConfirmDialog({
		title: t('useConfirmDialog.category.restoreTitle'),
		message: t('useConfirmDialog.category.restoreMessage')
	});
	const handleSoftDelete = useCallback(
		async (item: ISavingCheckItem) => {
			if (!item.deleted && (await getSoftDeleteConfirmation())) {
				softDeleteItem({checkListId: item.checkListId, id: item.id});
			}
		},
		[getSoftDeleteConfirmation, softDeleteItem]
	);

	const handleHardDelete = useCallback(
		async (item: ISavingCheckItem) => {
			if (item.deleted && (await getHardDeleteConfirmation())) {
				hardDeleteItem({checkListId: item.checkListId, id: item.id});
			}
		},
		[getHardDeleteConfirmation, hardDeleteItem]
	);

	const handleRestore = useCallback(
		async (item: ISavingCheckItem) => {
			if (item.deleted && (await getRestoreConfirmation())) {
				restoreItem({checkListId: item.checkListId, id: item.id});
			}
		},
		[getRestoreConfirmation, restoreItem]
	);
	return [
		softDeleteDialog,
		hardDeleteDialog,
		restoreDialog,
		handleSoftDelete,
		handleHardDelete,
		handleRestore
	];
};
