import {IDocument} from '../interfaces/documents/IDocument';

/**
 * Формирует заголовок документа исходя из его типа
 *
 * @param document документ
 */
export function formDocumentTitle(document: IDocument) {
	if (document.fileName) {
		return document.fileName;
	}
	return `${document.type?.name || 'Документ'} №${document.number}`;
}
