import {memo, startTransition, useCallback} from 'react';
import {ICheckList} from '@tehzor/tools/interfaces/checkLists/ICheckList';
import {CheckListDesktop} from '../CheckListDesktop/CheckListDesktop';
import {CheckListMobile} from '@src/pages/CheckListPage/components/CheckListMobile/CheckListMobile';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import {formCheckListLink} from '@tehzor/tools/utils/links';
import {ExpandablePanel, LinkButton} from '@tehzor/ui-components';
import {useToggle} from 'react-use';
import {ProcessLabel} from '@src/pages/CheckListPage/components/ProcessGroup/ProcessLabel/ProcessLabel';
import classNames from 'classnames';

interface IProcessGroupProps {
	processId: string;
	checkLists: ICheckList[];
	isFirstProcess: boolean;
	color?: string;
	mobileView?: boolean;
}

const simpleArrowIcon = <i className="tz-simple-arrow-16" />;

export const ProcessGroup = memo((props: IProcessGroupProps) => {
	const {processId, checkLists, isFirstProcess, mobileView, color} = props;
	const {pushPath} = useChangePath();

	const [isExpanded, toggle] = useToggle(isFirstProcess);

	const params = useStrictParams<{
		objectId: string;
		stage: string;
		listId: string;
		spaceId?: string;
		workAcceptanceId?: string;
	}>();

	const toggleExpandablePanel = useCallback(() => {
		startTransition(() => {
			toggle();
		});
	}, [toggle]);

	const redirectToCheckList = useCallback(
		(checkListId: string) => {
			const checkListLink = formCheckListLink({...params, listId: checkListId});
			if (params.listId !== checkListId && checkListLink) {
				pushPath(checkListLink);
			}
		},
		[params, pushPath]
	);

	const processGroupClass = classNames({
		'check-list-page-list__process-group': !mobileView,
		'check-list-page__m-list__process-group': mobileView
	});

	const processGroupHeaderClass = classNames({
		'check-list-page-list__process-group__header': !mobileView,
		'check-list-page-list__process-group__header_active': !mobileView && isExpanded,
		'check-list-page__m-list__process-group__header': mobileView ,
		'check-list-page__m-list__process-group__header_active': mobileView && isExpanded,
	});


	return (
		<div className={processGroupClass}>
			<LinkButton
				className={processGroupHeaderClass}
				leftIcon={simpleArrowIcon}
				label={
					<ProcessLabel
						processId={processId}
						color={color}
					/>
				}
				onClick={toggleExpandablePanel}
			/>
			<ExpandablePanel expanded={isExpanded}>
				{checkLists.map(checkList =>
					!mobileView ? (
						<CheckListDesktop
							key={checkList.id}
							checkList={checkList}
							onClick={redirectToCheckList}
						/>
					) : (
						<CheckListMobile
							className="check-list-page__m-list__stage-group__check-list"
							key={checkList.id}
							checkList={checkList}
							onClick={redirectToCheckList}
						/>
					)
				)}
			</ExpandablePanel>
		</div>
	);
});
