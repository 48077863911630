import {useQuery, UseQueryOptions} from '@tanstack/react-query';
import IError from '@tehzor/tools/interfaces/IError';
import {presaleChecksQueryKeys} from '../../../keys';
import {IGetPresaleChecksResponse} from '@/shared/api/presaleChecks/getPresaleChecks';
import {usePresaleChecksPermissions} from '../../../../hooks/usePresaleChecksPermissions';

export const useFetchLatestUserPresaleChecks = <T = IGetPresaleChecksResponse>(
	select?: (data: IGetPresaleChecksResponse) => T,
	options?: UseQueryOptions<IGetPresaleChecksResponse, IError, T>
) => {
	const permissions = usePresaleChecksPermissions();
	return useQuery<IGetPresaleChecksResponse, IError, T>({
		queryKey: presaleChecksQueryKeys.latest(),
		meta: {
			error: 'при загрузке последних предпродажных проверок'
		},
		enabled: permissions.canView,
		select,
		...options
	});
};
