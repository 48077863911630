import {usePresaleChecksQueryDefaults} from '@/entities/PresaleCheck/model/cache/defaults/usePresaleChecksQueryDefaults';
import {usePresaleChecksMutationDefaults} from '@/features/PresaleCheck/model/cache/defaults/usePresaleChecksMutationDefaults';

/**
 * Хук для передачи дефолтных функций в QueryClient
 */
export function usePresaleChecksCacheDefaults() {
	usePresaleChecksQueryDefaults();
	usePresaleChecksMutationDefaults();
}
