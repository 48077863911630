import {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useObjectStagesAsArray} from '@src/core/hooks/queries/objectStages/hooks';
import {useStagesAndProcessesMap} from '../processes/useProcessesMap';
import {IProcess} from '@tehzor/tools/interfaces/process/ProcessId';

export const useTranslatedObjectStagesArray = () => {
	const {t} = useTranslation();
	const {data: stages} = useObjectStagesAsArray();
	const stagesAndProcessesMap = useStagesAndProcessesMap();

	return useMemo(
		() =>
			stages
				? stages.map(stage => ({
						...stage,
						name: t(`objectStages.${stage.id}`),
						processes: stagesAndProcessesMap[stage.id].map(
							(p): IProcess => ({
								id: p,
								name: t(`processes.${p}`),
								color: stage.color,
								iconName: `${p}-process`
							})
						)
				  }))
				: [],
		[stages, stagesAndProcessesMap, t]
	);
};
