import {IGetListInspectionsResponse} from '@src/api/backend/inspections';
import {useQuery} from '@tanstack/react-query';
import {presaleChecksQueryKeys} from '../../../keys';

export const usePresaleCheckInspections = <T = IGetListInspectionsResponse>(
	objectId: string,
	presaleCheckId: string,
	select?: (data: IGetListInspectionsResponse) => T
) =>
	useQuery({
		queryKey: presaleChecksQueryKeys.presaleCheckInspections(objectId, presaleCheckId),
		select
	});
