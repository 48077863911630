import {MobileFiltersLinkBtn} from '@src/components/MobileFiltersLinkBtn';
import {DisplayModeSwitchMobile} from '../../components/DisplayModeSwitchMobile/DisplayModeSwitchMobile';
import {CentralBarMobile} from '@/shared/ui/EntitiesPage/CentralBarMobile/CentralBarMobile';
import {SchemaStructuresTypesMenu} from '@/pages/StructuresPage/components/SchemaStructuresTypesMenu/SchemaStructuresTypesMenu';
import {StructuresLegend} from '../../components/StructuresLegend/StructuresLegend';
import styles from './StructuresCentralBarMobile.module.less';
import {useEntitiesFiltersCtx} from '@/shared/lib/EntitiesFilters/hooks/useEntitiesFiltersCtx';

interface ICentralBarMobileProps {
	objectId: string;
}

export const StructuresCentralBarMobile = ({objectId}: ICentralBarMobileProps) => {
	const {state} = useEntitiesFiltersCtx();
	return (
		<CentralBarMobile>
			<DisplayModeSwitchMobile objectId={objectId} />
			<SchemaStructuresTypesMenu objectId={objectId} />
			<div className={styles.rightButtonsWrapper}>
				<StructuresLegend objectId={objectId} />
				<MobileFiltersLinkBtn
					state={state}
					objectId={objectId}
					entity="structures"
				/>
			</div>
		</CentralBarMobile>
	);
};
