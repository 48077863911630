import {Responsibles} from '@src/components/editableFields/Responsibles';
import {
	IEditableWorkAcceptanceAction,
	IEditableWorkAcceptanceState
} from '@src/core/hooks/states/useEditableWorkAcceptanceState';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractUserProfile} from '@src/store/modules/auth/profile/selectors';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {WorkingGroupTypeId} from '@tehzor/tools/interfaces/workingGroups/IWorkingGroupType';
import {IRuleParams} from '@tehzor/tools/utils/responsibilityRules/IRuleParams';
import {isEqual} from 'lodash';
import {Dispatch, useCallback, useMemo} from 'react';
import {useTranslation} from 'react-i18next';

interface IEditableSubmittersProps {
	objectId: string;
	stage?: ObjectStageIds;
	scope?: string;
	editingState: IEditableWorkAcceptanceState;
	editingDispatch: Dispatch<IEditableWorkAcceptanceAction>;
	required?: boolean;
	disabled?: boolean;
	hasError?: boolean;
	exception?: boolean;
}

export const EditableSubmitters = ({
	objectId,
	stage,
	scope,
	editingState,
	editingDispatch,
	required,
	disabled,
	hasError,
	exception
}: IEditableSubmittersProps) => {
	const {t} = useTranslation();
	const user = useAppSelector(extractUserProfile);
	const {
		categoryId,
		structureIds,
		spaceIds,
		submitters,
		submittersActiveGroup,
		type,
		frontType,
		initialRuleParams
	} = editingState;

	const ruleParams: IRuleParams = useMemo(
		() => ({categoryId: categoryId || undefined, structureIds, spaceIds}),
		[categoryId, structureIds, spaceIds]
	);

	const handleChange = useCallback(
		(users: string[], group?: string) => {
			editingDispatch({type: 'update', field: 'submitters', value: users});
			if (required) {
				editingDispatch({type: 'update-error', field: 'submitters'});
			}

			editingDispatch({type: 'update', field: 'submittersActiveGroup', value: group});
			if (required) {
				editingDispatch({type: 'update-error', field: 'submittersActiveGroup'});
			}
		},
		[required]
	);

	const handleUpdateException = useCallback(
		(value: boolean) => {
			if (exception) {
				return;
			}

			editingDispatch({type: 'update-exception', field: 'submitters', value});
		},
		[exception]
	);

	const checkDoSuggestions = useCallback(() => {
		if (isEqual(initialRuleParams, ruleParams)) {
			return false;
		}
		editingDispatch({type: 'update', field: 'initialRuleParams', value: undefined});
		return true;
	}, [initialRuleParams, ruleParams]);

	return (
		<Responsibles
			author={user}
			objectId={objectId}
			stage={stage}
			scope={scope}
			workAcceptanceType={type}
			workAcceptanceFrontType={frontType}
			workingGroupType={WorkingGroupTypeId.SUBMITTERS}
			respUsers={submitters}
			activeGroup={submittersActiveGroup}
			ruleParams={ruleParams}
			label={t('editableWorkAcceptance.submitters.label')}
			errorLabel={t('editableWorkAcceptance.submitters.errorLabel')}
			dialogTitle={t('components.delegationDialog.submitters.title')}
			required={required}
			disabled={disabled}
			hasError={hasError}
			onResponsiblesChange={handleChange}
			onUpdateException={handleUpdateException}
			checkDoSuggestions={checkDoSuggestions}
		/>
	);
};
