import styles from './ProcessingIcon.module.less';

export const ProcessingIcon = () => {
	return (
		<div className={styles.icon}>
			<div className={styles.lineContainer}>
				<div className={styles.line} />
			</div>
			<div className={styles.arrow}>
				<i className="tz-arrow-20" />
			</div>
		</div>
	);
};
