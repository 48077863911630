import {formCheckListsLink} from '@tehzor/tools/utils/links';
import {useAvailableCheckLists} from '@src/core/hooks/checkLists/useAvailableCheckLists';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useWorkAcceptancesForSpaceAsArray} from './queries/workAcceptances';
import {ProcessIds} from '@tehzor/tools/interfaces/process/ProcessId';

export const useSpaceSectionsMenu = (
	objectId: string,
	spaceId: string,
	stage: ObjectStageIds | 'all' = 'all',
	processId: ProcessIds | 'all' = 'all'
) => {
	const {t} = useTranslation();

	const checkListsLink = formCheckListsLink({objectId, spaceId, stage, processId});
	const checkLists = useAvailableCheckLists(objectId, spaceId, stage);
	const {data: workAcceptances} = useWorkAcceptancesForSpaceAsArray(objectId, spaceId);

	return useMemo(() => {
		const sections = [
			{
				id: 'Problems',
				url: `/objects/${objectId}/spaces/${spaceId}`,
				label: t('header.defects')
			}
		];
		if (checkLists.length) {
			sections.push({
				id: 'Checklists',
				url: checkListsLink ?? '',
				label: t('header.checkLists')
			});
		}
		if (workAcceptances?.length) {
			sections.push({
				id: 'WorkAcceptances',
				url: `/objects/${objectId}/spaces/${spaceId}/work-acceptances`,
				label: t('header.workAcceptances')
			});
		}
		return sections;
	}, [checkLists, checkListsLink, workAcceptances, t]);
};
