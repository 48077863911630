import {useMemo} from 'react';
import * as React from 'react';
import {usePermissions} from './usePermissions';
import {
	formCheckLink,
	formInternalAcceptanceLink,
	formSpaceLink,
	formTaskLink
} from '@tehzor/tools/utils/links';
import {ILinkedInspection} from '@tehzor/tools/interfaces/inspections/ILinkedInspection';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {formSpaceTitle} from '@tehzor/tools/utils/formSpaceTitle';
import {useExtractSpaceTypesById} from '@src/core/hooks/queries/spaceTypes/hooks';
import {useTask} from '@src/core/hooks/queries/task/hooks';
import {useTranslation} from 'react-i18next';
import {useTranslatedDictionaryMap} from '@src/core/hooks/translations/useTranslatedDictionaryMap';
import {dictionaryKeys} from '@src/constants/translations/dictionaryKeys';
import {formPresaleCheckLink} from '@tehzor/tools/utils/links/formPresaleCheckLink';
import {usePresaleCheck} from '@/entities/PresaleCheck';

interface IEntityLink {
	key: string;
	icon: React.ReactNode;
	label: string;
	url?: string;
	title: string;
}

export function useEntitiesLinks(objectId: string, inspection?: ILinkedInspection): IEntityLink[] {
	const permissions = usePermissions(objectId, inspection);
	const {t} = useTranslation();
	const {data: spaceTypes} = useExtractSpaceTypesById();
	const translatedSpaceTypes = useTranslatedDictionaryMap(dictionaryKeys.spaceTypes, spaceTypes);
	const {altNamesVisible} = useAppSelector(s => s.settings.pages.spaces.shared);
	const {data: task} = useTask(inspection?.links?.taskId);
	const {data: presaleCheck} = usePresaleCheck(inspection?.links?.presaleCheckId);

	return useMemo((): IEntityLink[] => {
		if (!inspection?.links) {
			return [];
		}
		const result: IEntityLink[] = [];
		if (inspection.space && permissions.canViewSpace) {
			result.push({
				key: 'space',
				icon: <i className="tz-space-24" />,
				label: formSpaceTitle(
					inspection.space.name,
					inspection.space.altName,
					translatedSpaceTypes?.[inspection.space.type],
					altNamesVisible,
					true
				),
				url: formSpaceLink(objectId, inspection.space.id),
				title: t('inspectionPage.entitiesLink.space.title.goto')
			});
		}
		if (inspection.check && permissions.canViewCheck) {
			result.push({
				key: 'check',
				icon: <i className="tz-check-24" />,
				label: `${t('inspectionPage.entitiesLink.check.label')} №${
					inspection.check.number
				}`,
				url: formCheckLink(objectId, inspection.check.id),
				title: t('inspectionPage.entitiesLink.check.title.goto')
			});
		}

		if (inspection.internalAcceptance && permissions.canViewInternalAcceptance) {
			result.push({
				key: 'internalAcceptance',
				icon: <i className="tz-internal-acceptance-24" />,
				label: `${t('inspectionPage.entitiesLink.internalAcceptance.label')} №${
					inspection.internalAcceptance.number
				}`,
				url: formInternalAcceptanceLink(objectId, inspection.internalAcceptance.id),
				title: t('inspectionPage.entitiesLink.internalAcceptance.title.goto')
			});
		}

		if (inspection?.links?.taskId !== undefined && permissions.canViewTask) {
			result.push({
				key: 'task',
				icon: <i className="tz-internal-acceptance-24" />,
				label: `${t('inspectionPage.entitiesLink.task.label')} ${task?.name?.slice(0, 9)}`,
				url: formTaskLink(inspection.links.taskId),
				title: t('inspectionPage.entitiesLink.task.title.goto')
			});
		}
		if (
			inspection?.links?.presaleCheckId !== undefined &&
			permissions.canViewPresaleCheck &&
			presaleCheck
		) {
			result.push({
				key: 'presaleCheck',
				icon: <i className="tz-presale-check-28" />,
				label: `${t('inspectionPage.entitiesLink.presaleCheck.label')} №${
					presaleCheck?.number || ''
				}`,
				url: formPresaleCheckLink(inspection.objectId, inspection?.links?.presaleCheckId),
				title: t('inspectionPage.entitiesLink.presaleCheck.title.goto')
			});
		}
		return result;
	}, [objectId, inspection, permissions, spaceTypes, task, presaleCheck, t, altNamesVisible]);
}
