import {makeCheckListDeleteRequest} from '@src/api/backend/checkList';
import {checkListsQueryKeys} from '@src/api/cache/checkLists/keys';
import {queryClient} from '@src/api/QueryClient';
import {useMutation} from '@tanstack/react-query';
import {ICheckList} from '@tehzor/tools/interfaces/checkLists/ICheckList';
import IError from '@tehzor/tools/interfaces/IError';
import {addErrorToast, addSuccessToast} from '@tehzor/ui-components';
import {useTranslation} from 'react-i18next';

export const useDeleteCheckList = () => {
	const {t} = useTranslation();
	return useMutation<ICheckList, IError, string>({
		mutationFn: (id: string) => makeCheckListDeleteRequest(id),
		mutationKey: checkListsQueryKeys.delete(),
		onSuccess: () => {
			void queryClient.invalidateQueries({queryKey: checkListsQueryKeys.list()});
			addSuccessToast({title: t('toast.deleteCheckList.success.title')});
		},
		onError: () => addErrorToast({title: t('toast.deleteCheckList.error.title')})
	});
};
