import {queryClient} from '@src/api/QueryClient';
import {IGetCategoriesResponse, requestEditCategory} from '@src/api/backend/categories';
import {categoriesQueryKeys} from '@src/api/cache/categories/keys';
import {useMutation} from '@tanstack/react-query';
import IError from '@tehzor/tools/interfaces/IError';
import ICategory from '@tehzor/tools/interfaces/categories/ICategory';
import ISavingCategory from '@tehzor/tools/interfaces/categories/ISavingCategory';
import {addErrorToast, addSuccessToast} from '@tehzor/ui-components';
import {useTranslation} from 'react-i18next';

export const useEditCategory = () => {
	const {t} = useTranslation();

	return useMutation<ICategory, IError, ISavingCategory>({
		mutationFn: (params: ISavingCategory) => requestEditCategory(params),

		onSuccess: data => {
			queryClient.setQueryData<IGetCategoriesResponse>(
				categoriesQueryKeys.list(),
				oldData => {
					if (oldData && oldData.allIds.includes(data.id)) {
						return {
							allIds: oldData.allIds,
							byId: {...oldData.byId, [data.id]: data}
						};
					}
					return oldData;
				}
			);
			addSuccessToast({title: t('toast.editCategory.success.title')});
		},
		onError: () => {
			addErrorToast({title: t('toast.editCategory.error.title')});
		}
	});
};
