import {httpRequests} from '@tehzor/tools/api/httpRequests';
import {IFieldSetting} from '@tehzor/tools/interfaces/fields';

export interface IEditFieldSettingsOrderRequest {
	setId: string;
	settings: Array<{
		settingId: string;
		order?: number;
	}>;
}

export const requestEditFieldSettingsOrder = async (data: IEditFieldSettingsOrderRequest) => {
	const res = await httpRequests.withToken.patch<IFieldSetting>('field-settings/order', data);
	return res.data;
};
