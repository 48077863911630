import {httpRequests} from '@tehzor/tools/api/httpRequests';
import {IFieldSettingsSet} from '@tehzor/tools/interfaces/fields';
import {ISavingFieldsSettingsSet} from '@src/interfaces/saving/ISavingFieldsSettingsSet';

export interface IMaceEditFieldsSettingsSetRequest {
	setId: string;
	fields: ISavingFieldsSettingsSet;
}

export const requestEditFieldsSettingsSet = async (data: IMaceEditFieldsSettingsSetRequest) => {
	const response = await httpRequests.withToken.patch<IFieldSettingsSet>(`field-settings-sets`, {
		setId: data.setId,
		...data.fields
	});
	return response.data;
};
