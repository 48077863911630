import {onlineManager, useQueryClient} from '@tanstack/react-query';
import useAppSelector from '../../useAppSelector';
import {IAddWarrantyClaimParams} from '@src/api/cache/warrantyClaims/defaults/useWarrantyClaimsMutationDefaults';
import {warrantyClaimsQueryKeys} from '@src/api/cache/warrantyClaims/keys';
import {toListWarrantyClaim, toWarrantyClaim} from './utils/convertToLocalSave';
import {useAddWarrantyClaimMutation} from '@src/api/cache/warrantyClaims/mutations';
import {IObject} from '@tehzor/tools/interfaces/objects/IObject';
import {useNextLocalNumber} from '../../useNextLocalNumber';
import {IListWarrantyClaim} from '@tehzor/tools/interfaces/warrantyClaims/IListWarrantyClaim';
import {useLocalWarrantyClaims} from '../../queries/warrantyClaims/hooks';
import {addNewEntityToNormalizedData} from '@src/utils/addNewEntityToNormalizedData';
import {IGetWarrantyClaimsResponse} from '@src/api/backend/warrantyClaims';
import {IWarrantyClaim} from '@tehzor/tools/interfaces/warrantyClaims/IWarrantyClaim';
import {addWarningToast} from '@tehzor/ui-components';
import {useTranslation} from 'react-i18next';

export const useAddWarrantyClaim = (object?: IObject) => {
	const {t} = useTranslation();
	const queryClient = useQueryClient();
	const online = onlineManager.isOnline();
	const user = useAppSelector(s => s.auth.profile);
	const {data: localClaims} = useLocalWarrantyClaims(object?.id);
	const nextNumber = useNextLocalNumber<IListWarrantyClaim>(localClaims, 'createdAt');
	const mutation = useAddWarrantyClaimMutation();

	return async ({
		objectId,
		links,
		fields
	}: Omit<IAddWarrantyClaimParams, 'key' | 'currentQueryFilter'>) => {
		const key = Date.now().toString(10);
		if (links?.checkRecordId && links?.checkRecordId.startsWith('local')) {
			links.checkRecordId = undefined;
		}
		const converted = toWarrantyClaim(user, objectId, links, fields, key, nextNumber);
		const convertedToList = object
			? toListWarrantyClaim(object, user, links, fields, key, nextNumber)
			: undefined;

		queryClient.setQueryData(
			[...warrantyClaimsQueryKeys.detail(key), objectId],
			convertedToList
		);

		if (!online) {
			addWarningToast({
				title: t('toast.addWarrantyClaimLocal.warning.title')
			});
			await queryClient.refetchQueries({queryKey: warrantyClaimsQueryKeys.localList()});
			queryClient.setQueryData<IGetWarrantyClaimsResponse>(
				warrantyClaimsQueryKeys.latest(),
				oldData => {
					if (!oldData) return undefined;
					return addNewEntityToNormalizedData<IGetWarrantyClaimsResponse, IWarrantyClaim>(
						oldData,
						converted
					);
				}
			);
		}
		mutation.mutate({
			key,
			objectId,
			links,
			fields
		});
		return converted;
	};
};
