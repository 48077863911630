import {useCallback} from 'react';
import {Outlet} from 'react-router-dom';
import useAppSelector from '@src/core/hooks/useAppSelector';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {changeFilters, clearFilters} from '@src/store/modules/settings/pages/spaces/actions';
import MobileEntitiesFilters from '@src/components/MobileEntitiesFilters';
import {extractSpacesPageSettings} from '@src/store/modules/settings/pages/spaces/selectors';
import {ISpacesFiltersState} from '@src/store/modules/settings/pages/spaces/reducers/byPage';
import {useStrictParams} from '@src/core/hooks/useStrictParams';

const SpacesFiltersRoutingPage = () => {
	const {objectId} = useStrictParams<{objectId: string}>();
	const {filters} = useAppSelector(s => extractSpacesPageSettings(s, objectId));
	const dispatch = useAppDispatch();

	const applyFilters = useCallback(
		(value: ISpacesFiltersState) => {
			if (objectId) {
				dispatch(changeFilters(objectId, value));
			}
		},
		[objectId]
	);

	const onClear = useCallback(() => {
		dispatch(clearFilters(objectId));
	}, [objectId]);

	return (
		<MobileEntitiesFilters
			objectId={objectId}
			filters={filters}
			onApply={applyFilters}
			onClear={onClear}
			fullClear
		>
			<Outlet />
		</MobileEntitiesFilters>
	);
};

export default SpacesFiltersRoutingPage;
