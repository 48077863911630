import useAppSelector from '@src/core/hooks/useAppSelector';
import {hasPermission} from '@tehzor/tools/utils/findPermission';
import {useMemo} from 'react';

export interface IPermissions {
	canView?: boolean;
	canAdd?: boolean;
	canEdit?: boolean;
	canDelete?: boolean;
}

export const useCategoriesPermissions = (): IPermissions => {
	const user = useAppSelector(s => s.auth.profile);
	return useMemo(() => {
		if (!user || !user.roles) {
			return {};
		}
		return {
			canView: hasPermission(user.roles, 'categories-view'),
			canAdd: hasPermission(user.roles, 'categories-add'),
			canEdit: hasPermission(user.roles, 'categories-edit'),
			canDelete: hasPermission(user.roles, 'categories-delete')
		};
	}, [user]);
};
