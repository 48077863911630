import {createContext, PropsWithChildren, useCallback} from 'react';

import {ITableContextMenuAction} from '@tehzor/tools/interfaces/table/ITableContextMenuAction';
import {IPreparedSpaceTypeDecoration} from '../interfaces/IPreparedSpaceTypeDecoration';
import {useDeleteSpaceTypeDecorationSet} from '@src/core/hooks/mutations/spaceTypeDecorationSets/useDeleteSpaceTypeDecorationSet';
import {useTranslatedConfirmDialog} from '@src/core/hooks/translations/useTranslatedConfirmDialog';
import {useTranslation} from 'react-i18next';

export const DispatchActionCtx = createContext<
	(action: ITableContextMenuAction<IPreparedSpaceTypeDecoration>) => void
>(() => ({}));

export const MenuActionCtxProvider = ({children}: PropsWithChildren) => {
	const {t} = useTranslation();
	const [deleteDialog, getDeleteConfirmation] = useTranslatedConfirmDialog({
		title: t('useConfirmDialog.spaceTypeDecoration.deleteMessage'),
		message: t('useConfirmDialog.data.deleteMessage')
	});
	const {mutateAsync: deleteSpaceTypeDecorationSet} = useDeleteSpaceTypeDecorationSet();
	const handleContextMenuAction = useCallback(
		async (action: ITableContextMenuAction<IPreparedSpaceTypeDecoration>) => {
			if (action.type === 'delete' && (await getDeleteConfirmation())) {
				await deleteSpaceTypeDecorationSet(action.payload.id);
			}
		},
		[getDeleteConfirmation, deleteSpaceTypeDecorationSet]
	);

	return (
		<DispatchActionCtx.Provider value={handleContextMenuAction}>
			{children}
			{deleteDialog}
		</DispatchActionCtx.Provider>
	);
};
