import {useCacheStates} from '../hooks/useCacheStates';
import {OfflineDataItem} from '@tehzor/ui-components';
import {useTranslation} from 'react-i18next';
import {fieldSettingsSetsQueryKeys} from '@/entities/FieldsSettingsSets';
import {fieldSettingsQueryKeys} from '@/entities/FieldSettings';
import {fieldsQueryKeys} from '@/entities/Fields';

const icon = <i className="tz-internal-acceptance-24" />;

export const getMaxDate = (...dates: Array<number | undefined>): number | undefined => {
	if (dates.find(date => date === undefined)) {
		return undefined;
	}

	return Math.max(...(dates as number[]));
};

export const FieldsCacheStatus = () => {
	const {t} = useTranslation();
	const [
		isBuiltinFetching,
		isBuiltinError,
		isBuiltinStale,
		isBuiltinPaused,
		builtinDateUpdatedAt
	] = useCacheStates(fieldsQueryKeys.builtinLists());
	const [isCustomFetching, isCustomError, isCustomStale, isCustomPaused, customDateUpdatedAt] =
		useCacheStates(fieldsQueryKeys.customLists());
	const [
		isEntityTypesFetching,
		isEntityTypesError,
		isEntityTypesStale,
		isEntityTypesPaused,
		entityTypesDateUpdatedAt
	] = useCacheStates(fieldsQueryKeys.types.entityTypes());
	const [
		isValueTypesFetching,
		isValueTypesError,
		isValueTypesStale,
		isValueTypesPaused,
		valueTypesDateUpdatedAt
	] = useCacheStates(fieldsQueryKeys.types.valueTypes());
	const [
		isSettingsFetching,
		isSettingsError,
		isSettingsStale,
		isSettingsPaused,
		settingsDateUpdatedAt
	] = useCacheStates(fieldSettingsQueryKeys.lists());
	const [isSetsFetching, isSetsError, isSetsStale, isSetsPaused, setsDateUpdatedAt] =
		useCacheStates(fieldSettingsSetsQueryKeys.lists());

	const isFetching =
		isBuiltinFetching ||
		isCustomFetching ||
		isEntityTypesFetching ||
		isValueTypesFetching ||
		isSettingsFetching ||
		isSetsFetching;

	const isError =
		isBuiltinError ||
		isCustomError ||
		isEntityTypesError ||
		isValueTypesError ||
		isSettingsError ||
		isSetsError;

	const isStale =
		isBuiltinStale ||
		isCustomStale ||
		isEntityTypesStale ||
		isValueTypesStale ||
		isSettingsStale ||
		isSetsStale;

	const isPaused =
		isBuiltinPaused ||
		isCustomPaused ||
		isEntityTypesPaused ||
		isValueTypesPaused ||
		isSettingsPaused ||
		isSetsPaused;

	const maxDate = getMaxDate(
		builtinDateUpdatedAt,
		customDateUpdatedAt,
		entityTypesDateUpdatedAt,
		valueTypesDateUpdatedAt,
		settingsDateUpdatedAt,
		setsDateUpdatedAt
	);

	return (
		<OfflineDataItem
			className="offline-mode-dialog__item"
			title={t('components.offlineModeDialog.fieldsCacheStatus.offlineDataItem.title')}
			icon={icon}
			date={maxDate}
			error={
				!isError
					? isStale
						? t('cacheStatusErrors.stale')
						: undefined
					: t('cacheStatusErrors.error')
			}
			inProgress={isPaused || isFetching}
			progressTitle={t(
				'components.offlineModeDialog.objectsCacheStatus.offlineDataItem.progressTitle.default'
			)}
		/>
	);
};
