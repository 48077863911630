import {SelectedEntitiesDeletionPresaleCheck} from './ui/SelectedEntitiesDeletionPresaleCheck';
import {usePresaleCheckPermissions} from '@/entities/PresaleCheck/model/hooks/usePresaleCheckPermissions';
import {usePresaleCheckProblemsArray} from '@/entities/PresaleCheck';
import {IPresaleCheckEntity} from '@src/interfaces/IPresaleCheckEntity';
import {IPresaleCheck} from '@tehzor/tools/interfaces/presaleChecks/IPresaleCheck';

interface ISelectionsActionsProps {
	objectId: string;
	selectedEntities: IPresaleCheckEntity[];
	presaleCheck?: IPresaleCheck;
	presaleCheckId: string;
	clearSelection?: () => void;
}

export const SelectionActionsPresaleCheck = ({
	objectId,
	selectedEntities,
	presaleCheck,
	presaleCheckId,
	clearSelection
}: ISelectionsActionsProps) => {
	const {data: problems} = usePresaleCheckProblemsArray(objectId, presaleCheckId);
	const permissions = usePresaleCheckPermissions(objectId, presaleCheck, problems);

	return permissions.canDelete ? (
		<SelectedEntitiesDeletionPresaleCheck
			selectedEntities={selectedEntities}
			clearSelection={clearSelection}
		/>
	) : null;
};
