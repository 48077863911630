import {makeChecksExportRequest} from '@src/api/backend/checks';
import {addExportToast} from '@/shared/lib/Toasts/ExportToast/addExportToast';
import {useMutation} from '@tanstack/react-query';
import {useCallback} from 'react';
import {addErrorToast, addWarningToast} from '@tehzor/ui-components';
import {checksQueryKeys} from '@src/api/cache/checks/keys';
import {queryClient} from '@src/api/QueryClient';
import {documentsQueryKeys} from '@src/api/cache/documents/keys';
import {checksDocumentsStatsQueryKeys} from '@src/api/cache/checksDocumentsStats/keys';
import {useTranslation} from 'react-i18next';

interface IExportCheckParams {
	templateId: string;
	createDocument: boolean | undefined;
	email: string | undefined;
	objectId?: string;
	filters?: Record<string, unknown>;
	sort?: Record<string, boolean>;
	selected?: string[];
}

export const useExportChecks = () => {
	const {t} = useTranslation();
	const mutation = useMutation({
		mutationKey: checksQueryKeys.export(),
		mutationFn: (params: IExportCheckParams) => {
			const {sort, email, objectId, selected, templateId, filters, createDocument} = params;
			return makeChecksExportRequest(
				templateId,
				createDocument,
				email,
				objectId,
				filters,
				sort,
				selected
			);
		},
		onSuccess: (data, params) => {
			if (data.warnings?.itemsLimit !== undefined) {
				addWarningToast({
					title: `${t('toast.export.warning.title')} (${String(
						data.warnings.itemsLimit
					)})`
				});
			}
			if (data.link) {
				addExportToast({
					title: params.email
						? t('toast.export.send.success.title')
						: t('toast.export.success.title'),
					url: data.link,
					fileName: data.file.name,
					document: data.document
				});
			}
			void queryClient.invalidateQueries({queryKey: documentsQueryKeys.lists()});
			void queryClient.invalidateQueries({queryKey: checksDocumentsStatsQueryKeys.lists()});
		},
		onError: error => {
			addErrorToast({title: t('toast.export.error.title')});
			console.error('export error:', error);
		}
	});

	/**
	 * Возвращает функцию для экспорта списка проверок
	 *
	 * @param templateId id шаблона экспорта
	 * @param createDocument флаг создания документа
	 * @param email email для отправки
	 * @param objectId id объекта
	 * @param filters фильтры
	 * @param sort сортировка
	 * @param selected выбранные элементы
	 */

	return useCallback(
		(
			templateId: string,
			createDocument: boolean | undefined,
			email: string | undefined,
			objectId?: string,
			filters?: Record<string, unknown>,
			sort?: Record<string, boolean>,
			selected?: string[]
		) => {
			mutation.mutate({
				templateId,
				createDocument,
				email,
				objectId,
				filters,
				sort,
				selected
			});
		},
		[mutation]
	);
};
