import {useQuery} from '@tanstack/react-query';
import {fieldSettingsQueryKeys} from '../../keys/index';
import {IGetListFieldSettingsResponse} from '@/shared/api/fieldSettings/getFieldSettingsList';
import {useFieldsPermissions} from '@/shared/hooks/permissions/useFieldsPermissions';

export const useFieldSettingsList = <T = IGetListFieldSettingsResponse>(
	select?: (data: IGetListFieldSettingsResponse) => T
) => {
	const permissions = useFieldsPermissions();

	return useQuery({
		queryKey: fieldSettingsQueryKeys.list(),
		meta: {
			error: 'при загрузке списка настроек полей'
		},
		select,
		enabled: permissions.canViewSettings
	});
};
