import {httpRequests} from '@tehzor/tools/api/httpRequests';
import {IPresaleCheck} from '@tehzor/tools/interfaces/presaleChecks/IPresaleCheck';

/**
 * Добавляет предпродажную проверку
 *
 * @param objectId id объекта
 * @param links ссылки на другие сущности
 * @param localId localId для синхронизации данных оффлайн сущностей и онлайн
 */
export const requestAddPresaleCheck = async (
	objectId: string,
	links: IPresaleCheck['links'] | undefined,
	localId?: string
) => {
	const response = await httpRequests.withToken.post<IPresaleCheck>('/presale-checks', {
		objectId,
		links,
		localId
	});

	return response.data;
};
