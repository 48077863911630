import {addToast, ToastsContainersIds} from '@tehzor/ui-components';
import {ExportToast} from '@/shared/lib/Toasts/ExportToast/ExportToast';
import {IDocument} from '@tehzor/tools/interfaces/documents/IDocument';

interface IAddExportToastParams {
	title: string;
	url: string;
	fileName: string;
	description?: string;
	document?: IDocument;
}

export const addExportToast = (params: IAddExportToastParams) => {
	addToast({
		containerId: ToastsContainersIds.MAIN_STACKED,
		toastNode: <ExportToast {...params} />,
		options: {
			autoClose: false
		}
	});
};
