import EntitiesFilters, {
	CreatedByFilter,
	// FloorsFilter,
	CreationDateFilter,
	// NumberFilter,
	ObjectsFilter,
	PlansFilter
} from '@src/components/EntitiesFilters';
import {useEntitiesFiltersCtx} from '@src/components/EntitiesFilters/utils/entitiesFiltersCtx';
import {IEntitySettings} from '@src/core/hooks/settings/useEntitySettings';
import {CategoriesSetFilter} from '@src/pages/ProblemsPage/components/filters/CategoriesSetFilter';
import {IInspectionsFiltersState} from '@src/store/modules/settings/pages/inspections/interfaces';
import {useTranslation} from 'react-i18next';
import {InspectionsPerformersFilter} from './InspectionsPerformersFilter';
import {CustomFilters} from '@src/components/CustomFilters/CustomFilters';
import {useObjectChildrenIds} from '@src/core/hooks/queries/objects/hooks';
import {NestedObjectsFilter} from '@src/components/EntitiesFilters/components/NestedObjectsFilter';

interface IFiltersProps {
	objectId?: string;
	settings: IEntitySettings;
}

export const InspectionsFilters = ({objectId = 'all', settings}: IFiltersProps) => {
	const {builtin, custom} = settings;
	const {state} = useEntitiesFiltersCtx<IInspectionsFiltersState>();
	const {t} = useTranslation();
	const nestedObjects = useObjectChildrenIds(objectId);
	return (
		<EntitiesFilters
			objectId={objectId}
			entity="inspections"
			isDescendants={!!nestedObjects?.length}
		>
			{/* <NumberFilter
				value={state?.number}
				placeholder="№ нарушения"
			/> */}

			{objectId === 'all' && (
				<ObjectsFilter
					value={state?.objects}
					label={t('entitiesFilters.objectsFilter.label')}
				/>
			)}

			{nestedObjects?.length ? (
				<NestedObjectsFilter
					value={state?.objects}
					label={t('problemsPage.objectsFilter.label')}
					objectId={objectId}
					excludeDescendantsValue={state?.excludeDescendantsObjects}
				/>
			) : null}
			{builtin.planId && objectId !== 'all' && (
				<PlansFilter
					objectId={objectId}
					plans={state?.plans}
					label={t('entitiesFilters.PlansFilter.label')}
				/>
			)}

			{builtin.categoryId && (
				<CategoriesSetFilter
					objectId={objectId}
					categories={state.categories}
					label={t('entitiesFilters.CategoriesSetFilter.label')}
				/>
			)}

			{builtin.performers && (
				<InspectionsPerformersFilter
					objectId={objectId}
					performers={state.performers}
					label={t('entitiesFilters.performers.label')}
				/>
			)}

			{/* <FloorsFilter value={state?.floors} /> */}

			<CreatedByFilter
				objectId={objectId}
				createdBy={state?.createdBy}
				label={t('entitiesFilters.CreatedByFilter.label')}
			/>

			<CreationDateFilter
				from={state?.createdAtFrom}
				to={state?.createdAtTo}
				label={t('entitiesFilters.CreationDateFilter.label')}
			/>

			<CustomFilters
				settings={custom}
				filtersData={state.custom}
			/>
		</EntitiesFilters>
	);
};
