import {forwardRef, memo, Ref, useCallback, useImperativeHandle} from 'react';
import {AddingEntities} from '@src/api/mutations';
import {useAddNewProblem} from '@src/core/hooks/mutations/problem/useAddProblem';
import {useCopyProblem} from '@src/core/hooks/mutations/problem/useCopyProblem';
import {useObject} from '@src/core/hooks/queries/objects/hooks';
import {ISavingProblem} from '@src/interfaces/saving/ISavingProblem';
import {onlineManager} from '@tanstack/react-query';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {IProblem} from '@tehzor/tools/interfaces/problems/IProblem';
import {useUpdateEffect} from 'react-use';
import {useEditableProblemForm} from '../Forms';
import {ProcessIds} from '@tehzor/tools/interfaces/process/ProcessId';
import {useTranslatedConfirmDialog} from '@src/core/hooks/translations/useTranslatedConfirmDialog';

interface IAddingProblemProps {
	objectId: string;
	links?: IProblem['links'];
	stage: ObjectStageIds;
	processId: ProcessIds;
	scope?: string;
	defaultData?: ISavingProblem;
	saving: boolean;
	problemToCopyId?: string;
	onClose: () => void;
	setSaving: (s: boolean) => void;
	isLoading?: boolean;
}

export interface IAddingProblemRefProps {
	getSavingData: (
		useLocalFiles?: boolean
	) => Promise<(ISavingProblem & {templateId?: string}) | undefined>;
	save: (
		savingData?: ISavingProblem & {templateId?: string},
		extraLinks?: IProblem['links'],
		cacheKey?: string
	) => Promise<string | undefined | void> | string | void;
	cancel: () => void;
	saveCache?: (
		savingData?: ISavingProblem & {templateId?: string},
		extraLinks?: IProblem['links']
	) => Promise<
		| {
				cacheKey: string;
				type: AddingEntities;
				links: IProblem['links'];
		  }
		| undefined
	>;
}

const AddingProblem = (
	{
		objectId,
		links,
		stage,
		processId,
		scope,
		defaultData,
		saving,
		problemToCopyId,
		onClose,
		isLoading,
		setSaving
	}: IAddingProblemProps,
	ref?: Ref<IAddingProblemRefProps>
) => {
	const networkStatus = onlineManager.isOnline();
	const {data: object} = useObject(objectId);
	const {createNewProblemCache, saveProblem} = useAddNewProblem(object);
	const copyProblem = useCopyProblem();
	const {form, isBlocking, getSavingData, resetForm} = useEditableProblemForm({
		objectId,
		stage,
		processId,
		scope,
		defaultData,
		saving,
		creating: true,
		isCopying: !!problemToCopyId,
		links,
		isLoading
	});

	useUpdateEffect(() => {
		if (!networkStatus) {
			setSaving(false);
		}
	}, [networkStatus]);
	const saveCache = useCallback(
		async (
			savingData?: ISavingProblem & {templateId?: string},
			extraLinks?: IProblem['links']
		) => {
			setSaving(true);
			if (savingData) {
				const {templateId, ...data} = savingData;
				const cache = await createNewProblemCache({
					objectId,
					links: {...links, ...extraLinks, templateId},
					stage,
					processId,
					fields: data
				});
				resetForm();
				return {
					cacheKey: cache.id,
					links: cache.links,
					type: AddingEntities.PROBLEM
				};
			}

			return undefined;
		},
		[setSaving, createNewProblemCache, objectId, links, stage, resetForm]
	);
	const save = useCallback(
		(
			savingData?: ISavingProblem & {templateId?: string},
			extraLinks?: IProblem['links'],
			cacheKey?: string
		) => {
			if (savingData) {
				const {templateId, ...data} = savingData;
				if (problemToCopyId) {
					copyProblem(
						objectId,
						problemToCopyId,
						{...links, ...extraLinks, templateId},
						stage,
						processId,
						data
					);
				} else {
					const key = cacheKey || '';
					data.localCreatedAt = Number(key);
					saveProblem({
						objectId,
						links: {...links, ...extraLinks, templateId},
						stage,
						processId,
						fields: data,
						key
					});
				}
				resetForm();
			}
		},
		[objectId, links, stage, saveProblem, problemToCopyId, copyProblem, processId, resetForm]
	);

	const [closingDialog, getClosingConfirmation] = useTranslatedConfirmDialog();

	const cancel = useCallback(async () => {
		if (!isBlocking || (await getClosingConfirmation())) {
			onClose();
		}
	}, [getClosingConfirmation, onClose, isBlocking]);

	useImperativeHandle(ref, () => ({save, saveCache, cancel, getSavingData}), [
		save,
		saveCache,
		cancel,
		getSavingData
	]);
	return (
		<>
			{form}
			{closingDialog}
		</>
	);
};

export default memo(forwardRef<IAddingProblemRefProps, IAddingProblemProps>(AddingProblem));
