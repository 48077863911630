import useMedia from 'react-use/lib/useMedia';
import * as variables from './variables';

export const useIsLargeMobile = () => useMedia(`(min-width: ${variables.largeMobileMin}px)`);
export const useIsBeforeLargeMobile = () =>
	useMedia(`(max-width: ${variables.largeMobileMin - 1}px)`);

export const useIsTablet = () => useMedia(`(min-width: ${variables.tabletMin}px)`);
export const useIsBeforeTablet = () => useMedia(`(max-width: ${variables.tabletMin - 1}px)`);

export const useIsLargeTablet = () => useMedia(`(min-width: ${variables.largeTabletMin}px)`);
export const useIsBeforeLargeTablet = () =>
	useMedia(`(max-width: ${variables.largeTabletMin - 1}px)`);
export const useIsLargeTabletHeight = () =>
	useMedia(`(min-height: ${variables.largeTabletMinHeight}px)`);
export const useIsBeforeLargeTabletHeight = () =>
	useMedia(`(max-height: ${variables.largeTabletMinHeight - 1}px)`);

export const useIsDesktop = () => useMedia(`(min-width: ${variables.desktopMin}px)`);
export const useIsBeforeDesktop = () => useMedia(`(max-width: ${variables.desktopMin - 1}px)`);
export const useIsDesktopHeight = () => useMedia(`(min-height: ${variables.desktopMinHeight}px)`);
export const useIsBeforeDesktopHeight = () =>
	useMedia(`(max-height: ${variables.desktopMinHeight - 1}px)`);

export const useIsLargeDesktop = () => useMedia(`(min-width: ${variables.largeDesktopMin}px)`);
export const useIsBeforeLargeDesktop = () =>
	useMedia(`(max-width: ${variables.largeDesktopMin - 1}px)`);
export const useIsLargeDesktopHeight = () =>
	useMedia(`(min-height: ${variables.largeDesktopMinHeight}px)`);
export const useIsBeforeLargeDesktopHeight = () =>
	useMedia(`(max-height: ${variables.largeDesktopMinHeight - 1}px)`);
