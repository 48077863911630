import {useCallback, useState} from 'react';
import './EditableWorkAcceptanceDialog.less';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {
	addWorkAcceptance,
	editWorkAcceptance
} from '@src/store/modules/entities/workAcceptance/actions';
import {ActionButtons, Button, ModalDialog} from '@tehzor/ui-components';
import {useEditableWorkAcceptance} from '@src/components/EditableWorkAcceptance/hooks/useEditableWorkAcceptance';
import {IWorkAcceptance} from '@tehzor/tools/interfaces/workAcceptances/IWorkAcceptance';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {addTempFiles} from '@src/api/cache/utils/addTempFiles';
import {useTranslation} from 'react-i18next';
import {useTranslatedConfirmDialog} from '@src/core/hooks/translations/useTranslatedConfirmDialog';

interface IEditableWorkAcceptanceDialogProps {
	objectId: string;
	stage: ObjectStageIds;
	workAcceptance?: IWorkAcceptance;
	isOpen: boolean;
	onClose: () => void;
	onSuccess?: () => void | Promise<void>;
}

/**
 * Окно редактирования приёмки работ
 */
export const EditableWorkAcceptanceDialog = ({
	objectId,
	stage,
	workAcceptance,
	isOpen,
	onClose,
	onSuccess
}: IEditableWorkAcceptanceDialogProps) => {
	const workAcceptanceId = workAcceptance?.id;
	const {t} = useTranslation();
	const dispatch = useAppDispatch();
	const [saving, setSaving] = useState(false);
	const [fields, getSavingData, reset, isBlocking] = useEditableWorkAcceptance(
		objectId,
		stage,
		workAcceptance || undefined,
		saving
	);

	const save = useCallback(async () => {
		setSaving(true);
		const savingData = await getSavingData();
		if (savingData && workAcceptanceId) {
			try {
				await dispatch(
					editWorkAcceptance(objectId, workAcceptanceId, {
						...savingData,
						newAttachments: await addTempFiles(savingData.newAttachments)
					})
				);
				reset();
				onClose();
			} catch (error) {
				console.log(error);
			}
		}
		if (savingData && !workAcceptanceId) {
			try {
				await dispatch(
					addWorkAcceptance(objectId, {
						...savingData,
						newAttachments: await addTempFiles(savingData.newAttachments)
					})
				);
				if (onSuccess) {
					void onSuccess();
				}
				reset();
				onClose();
			} catch (error) {
				console.log(error);
			}
		}
		setSaving(false);
	}, [getSavingData, workAcceptanceId, dispatch, objectId, onClose, onSuccess, reset]);

	const [closingDialog, getClosingConfirmation] = useTranslatedConfirmDialog();

	const handleClose = useCallback(async () => {
		if (!isBlocking || (await getClosingConfirmation())) {
			reset();
			onClose();
		}
	}, [isBlocking, onClose, getClosingConfirmation, reset]);

	return (
		<ModalDialog
			className={{root: 'editable-work-acceptance-dialog'}}
			open={isOpen}
			title={
				workAcceptanceId
					? t('workAcceptanceDialog.title.edit')
					: t('workAcceptanceDialog.title.adding')
			}
			footer={
				<ActionButtons>
					<Button
						type="cancel"
						label={t('actionButtons.button.cancel')}
						disabled={saving}
						onClick={handleClose}
					/>
					<Button
						type="accent-blue"
						label={t('actionButtons.button.save')}
						disabled={saving}
						onClick={save}
					/>
				</ActionButtons>
			}
			fullScreenOnTablet
			onRequestClose={handleClose}
		>
			{fields}
			{closingDialog}
		</ModalDialog>
	);
};
