import {deleteSpaceTypeDecorationSetRequest} from '@src/api/backend/spaceTypeDecorationSets';
import {spaceTypeDecorationSetsQueryKeys} from '@src/api/cache/spaceTypeDecorationsSets/keys';
import {queryClient} from '@src/api/QueryClient';
import {useMutation} from '@tanstack/react-query';
import {addErrorToast, addSuccessToast} from '@tehzor/ui-components';
import {useTranslation} from 'react-i18next';

export const useDeleteSpaceTypeDecorationSet = () => {
	const {t} = useTranslation();

	return useMutation({
		mutationKey: spaceTypeDecorationSetsQueryKeys.delete(),
		mutationFn: (id: string) => deleteSpaceTypeDecorationSetRequest(id),
		onSuccess: () => {
			void queryClient.invalidateQueries({
				queryKey: spaceTypeDecorationSetsQueryKeys.lists()
			});
			addSuccessToast({title: t('toast.deleteSpaceTypeDecoration.success.title')});
		},
		onError: () => addErrorToast({title: t('toast.deleteSpaceTypeDecoration.error.title')})
	});
};
