import {Suspense} from 'react';
import {useParams} from 'react-router';
import {CellProps} from 'react-table';
import {TableRowSelect, FilesCount} from '@tehzor/ui-components';
import {IListWorkAcceptance} from '@tehzor/tools/interfaces/workAcceptances/IListWorkAcceptance';
import {ProcessIds} from '@tehzor/tools/interfaces/process/ProcessId';
import {WorkAcceptanceScope} from '../WorkAcceptanceScope/WorkAcceptanceScope';
import ClickPreventWrap from '../ClickPreventWrap';
import WorkAcceptanceInfo from '../WorkAcceptanceInfo';
import {WorkAcceptanceCheckListStatuses} from '../WorkAcceptanceCheckListStatuses/WorkAcceptanceCheckListStatuses';
import {WorkAcceptanceAcceptors} from '../WorkAcceptanceAcceptors';
import {useAvailableCheckLists} from '@src/core/hooks/checkLists/useAvailableCheckLists';
import {useWorkAcceptanceAcceptors} from '@src/pages/SpacePage/hooks/useWorkAcceptanceAcceptors';
import {useWorkAcceptanceCompany} from '@src/pages/SpacePage/hooks/useWorkAcceptanceCompany';
import {useCheckListStatuses} from '@src/pages/SpacePage/hooks/useCheckListStatuses';
import {useSpace} from '@src/core/hooks/queries/space';
import './WorkAcceptanceDesktopCell.less';

const WorkAcceptanceDesktopCell = ({row}: CellProps<{data: IListWorkAcceptance}>) => {
	const {
		id,
		objectId,
		stage,
		acceptorsActiveGroup,
		acceptors,
		attachments,
		description,
		frontType,
		spaceIds,
		categoryId
	} = row.original.data;

	const {spaceId} = useParams();
	const {data: space} = useSpace(spaceId, objectId);

	const {acceptorsGroup, acceptors: currentAcceptors} = useWorkAcceptanceAcceptors(
		objectId,
		stage,
		acceptors,
		acceptorsActiveGroup
	);
	const company = useWorkAcceptanceCompany(acceptorsActiveGroup, acceptorsGroup);

	const checkLists = useAvailableCheckLists(
		objectId,
		undefined,
		stage,
		ProcessIds.ACCEPTANCE_CONTROL,
		undefined,
		categoryId,
		space?.type ? [space?.type] : []
	);

	const checkListsStatuses = useCheckListStatuses(checkLists, id);

	return (
		<div className="work-acceptance-info-cell">
			<ClickPreventWrap>
				<TableRowSelect
					{...row.getToggleRowSelectedProps()}
					style={{marginTop: '12px'}}
				/>
			</ClickPreventWrap>

			<div className="work-acceptance-info-cell__info">
				<WorkAcceptanceInfo data={row.original.data} />

				{description !== undefined && (
					<div className="work-acceptance-info-cell__desc">{description}</div>
				)}

				{acceptorsGroup && currentAcceptors && company && (
					<div className="work-acceptance-info-cell__acceptors">
						<WorkAcceptanceAcceptors
							acceptorsGroup={acceptorsGroup}
							currentAcceptors={currentAcceptors}
							company={company}
						/>
					</div>
				)}

				{spaceIds && (
					<Suspense>
						<WorkAcceptanceScope
							objectId={objectId}
							spaceIds={spaceIds}
							frontType={frontType}
						/>
					</Suspense>
				)}

				<div className="work-acceptance-info-cell__indicators">
					{attachments && <FilesCount count={attachments?.length} />}
					{checkListsStatuses && (
						<WorkAcceptanceCheckListStatuses checkListStatuses={checkListsStatuses} />
					)}
				</div>
			</div>
		</div>
	);
};

export default WorkAcceptanceDesktopCell;
