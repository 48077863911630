import {IGetPlansResponse} from '@src/api/backend/plans';
import {plansQueryKeys} from '@src/api/cache/plans/keys';
import {useQuery, UseQueryOptions} from '@tanstack/react-query';
import IError from '@tehzor/tools/interfaces/IError';
import {useCallback} from 'react';
import {
	extractPlan,
	extractPlansAsArray,
	extractPlansByIds,
	extractPlansByObjectIdsAsArray,
	extractShape,
	extractShapes
} from './selectors';
import {getCachedPlans} from '@src/store/persistentStorage/offlineActions/plans';
import {usePlansPermissions} from '@src/core/hooks/permissions/usePlansPermissions';

export const usePlansData = <T = IGetPlansResponse>(
	objectIds?: string[],
	select?: (data: IGetPlansResponse) => T,
	options?: Partial<UseQueryOptions<IGetPlansResponse, IError>>
) => {
	const permissions = usePlansPermissions();
	return useQuery<IGetPlansResponse, IError, T>({
		queryKey: plansQueryKeys.list(objectIds),
		initialData: getCachedPlans(objectIds),
		meta: {
			error: 'при загрузке планов'
		},
		enabled: permissions.canView,
		...options,
		select
	});
};

export const usePlans = (objectId?: string) => {
	const objectIds = objectId ? [objectId] : undefined;
	return usePlansData(objectIds);
};

export const usePlansAsArray = (objectId?: string) => {
	const objectIds = objectId && objectId !== 'all' ? [objectId] : undefined;
	return usePlansData(objectIds, extractPlansAsArray);
};

export const usePlansByObjectIds = (objectIds?: string[]) => usePlansData(objectIds);

export const usePlansByObjectIdsAsArray = (objectIds?: string[]) =>
	usePlansData(objectIds, extractPlansByObjectIdsAsArray);

export const usePlan = (
	objectId?: string,
	planId?: string,
	options?: Partial<UseQueryOptions<IGetPlansResponse, IError>>
) => {
	const selector = useCallback((data: IGetPlansResponse) => extractPlan(data, planId), [planId]);
	const objectIds = objectId ? [objectId] : undefined;
	return usePlansData(objectIds, selector, options);
};

export const usePlansByIds = (objectId?: string, planIds?: string[]) => {
	const selector = useCallback(
		(data: IGetPlansResponse) => extractPlansByIds(data, planIds),
		[planIds]
	);
	const objectIds = objectId ? [objectId] : undefined;
	return usePlansData(objectIds, selector);
};

export const useShapes = (objectId?: string, planId?: string) => {
	const selector = useCallback(
		(data: IGetPlansResponse) => extractShapes(data, planId),
		[planId]
	);
	const objectIds = objectId ? [objectId] : undefined;
	return usePlansData(objectIds, selector);
};

export const useShape = (objectId?: string, planId?: string, shapeId?: string) => {
	const selector = useCallback(
		(data: IGetPlansResponse) => extractShape(data, planId, shapeId),
		[planId, shapeId]
	);
	const objectIds = objectId ? [objectId] : undefined;
	return usePlansData(objectIds, selector);
};
