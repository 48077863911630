import {checksQueryKeys} from '@src/api/cache/checks/keys';
import {useMutation} from '@tanstack/react-query';
import {makeCheckMoveRequest} from '@src/api/backend/check';
import {queryClient} from '@src/api/QueryClient';
import {problemsQueryKeys} from '@src/api/cache/problems/keys';
import {inspectionsQueryKeys} from '@src/api/cache/inspections/keys';
import {addErrorToast, addSuccessToast} from '@tehzor/ui-components';
import {useTranslation} from 'react-i18next';

export interface IMakeTaskMoveRequestData {
	objectId: string;
	checkId: string;
	toObjectId: string;
	toSpaceId?: string;
}

export const useMoveCheck = () => {
	const {t} = useTranslation();

	return useMutation({
		mutationKey: checksQueryKeys.move(),
		mutationFn: ({objectId, checkId, toObjectId, toSpaceId}: IMakeTaskMoveRequestData) =>
			makeCheckMoveRequest(objectId, checkId, toObjectId, toSpaceId),
		onSuccess: () => {
			addSuccessToast({title: t('toast.moveCheck.success.title')});
			void queryClient.invalidateQueries({queryKey: problemsQueryKeys.list()});
			void queryClient.invalidateQueries({queryKey: inspectionsQueryKeys.list()});
			void queryClient.invalidateQueries({queryKey: checksQueryKeys.list()});
			void queryClient.invalidateQueries({queryKey: checksQueryKeys.latest()});
			void queryClient.invalidateQueries({queryKey: checksQueryKeys.details()});
			void queryClient.invalidateQueries({queryKey: problemsQueryKeys.details()});
			void queryClient.invalidateQueries({queryKey: inspectionsQueryKeys.details()});
		},
		onError: () => addErrorToast({title: t('toast.moveCheck.error.title')})
	});
};
