import {MenuItem, IconMenu} from '@tehzor/ui-components';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {ICategoriesSet} from '@tehzor/tools/interfaces/categoriesSets/ICategoriesSet';
import {useAsyncFn} from 'react-use';
import {useCategoriesSetPermissions} from '@src/core/hooks/permissions/useCategoriesSetPermissions';
import {useDeleteCategoriesSet} from '@src/core/hooks/mutations/categoriesSets/useDeleteCategoriesSet';
import {useTranslation} from 'react-i18next';
import {useTranslatedConfirmDialog} from '@src/core/hooks/translations/useTranslatedConfirmDialog';

interface ICategoriesSetMenuProps {
	set: ICategoriesSet;
}

const deleteIcon = <i className="tz-delete" />;

const CategoriesSetMenu = ({set}: ICategoriesSetMenuProps) => {
	const {t} = useTranslation();
	const {goBack} = useChangePath();
	const {mutateAsync: deleteCategoriesSet} = useDeleteCategoriesSet();
	const [deleteDialog, getDeleteConfirmation] = useTranslatedConfirmDialog({
		title: t('useConfirmDialog.categorySet.deleteTitle'),
		message: t('useConfirmDialog.categorySet.deleteMessageId', {id: set.name})
	});

	const {canDelete} = useCategoriesSetPermissions();

	const [, handleDelete] = useAsyncFn(async () => {
		if (await getDeleteConfirmation()) {
			await deleteCategoriesSet(set.id);
			goBack();
		}
	}, [set.id]);

	const items = [];

	if (canDelete) {
		items.push(
			<MenuItem
				key="delete"
				icon={deleteIcon}
				onClick={handleDelete}
			>
				Удалить
			</MenuItem>
		);
	}

	return items.length ? (
		<>
			<IconMenu>{items}</IconMenu>
			{deleteDialog}
		</>
	) : null;
};

export default CategoriesSetMenu;
