import {httpRequests} from '@tehzor/tools/api/httpRequests';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {IBuiltinField} from '@tehzor/tools/interfaces/fields';

export type IGetBuiltinFieldsResponse = INormalizedData<IBuiltinField>;

/**
 * Возвращает встроенные поля
 */
export const requestGetBuiltinFieldsList = async () => {
	const res = await httpRequests.withToken.get<IGetBuiltinFieldsResponse>('fields/get-builtin');
	return res.data;
};
