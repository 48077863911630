import {queryClient} from '@src/api/QueryClient';
import {
	IEditCategoriesOrderResponse,
	IGetCategoriesResponse,
	requestEditOrderCategories
} from '@src/api/backend/categories';
import {categoriesQueryKeys} from '@src/api/cache/categories/keys';
import {useMutation} from '@tanstack/react-query';
import IError from '@tehzor/tools/interfaces/IError';
import {ICategoryOrder} from '@tehzor/tools/interfaces/categories/ICategoryOrder';
import {addErrorToast, addSuccessToast} from '@tehzor/ui-components';
import {useTranslation} from 'react-i18next';

export interface IEditCategoriesOrderParams {
	categoriesSetId: string;
	order: ICategoryOrder[];
}

export const useEditOrderCategories = () => {
	const {t} = useTranslation();

	return useMutation<IEditCategoriesOrderResponse, IError, IEditCategoriesOrderParams>({
		mutationFn: (params: IEditCategoriesOrderParams) =>
			requestEditOrderCategories(params.categoriesSetId, params.order),

		onSuccess: data => {
			queryClient.setQueryData<IGetCategoriesResponse>(
				categoriesQueryKeys.list(),
				oldData => {
					if (oldData) {
						data.allIds.forEach(categoryId => {
							oldData[categoryId] = data.byId[categoryId];
						});
					}
					return data;
				}
			);
			addSuccessToast({title: t('toast.editOrderCategories.success.title')});
		},
		onError: () => {
			addErrorToast({title: t('toast.editOrderCategories.error.title')});
		}
	});
};
