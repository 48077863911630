import {useAddProblemMutation} from '../cache/problems/mutations';
import {useAddInspectionMutation} from '../cache/inspections/mutations';

export enum AddingEntities {
	PROBLEM = 'problem',
	INSPECTION = 'inspection'
}

export const useMutationsFn = () => ({
	problem: useAddProblemMutation(),
	inspection: useAddInspectionMutation()
});
