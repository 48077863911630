import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import IComment from '@tehzor/tools/interfaces/comments/IComment';
import {useQuery} from '@tanstack/react-query';
import {presaleChecksQueryKeys} from '../../../keys';

export type IGetPresaleCheckProblemRepliesPayload = INormalizedData<IComment>;

export const usePresaleCheckProblemsReplies = <T = IGetPresaleCheckProblemRepliesPayload>(
	objectId?: string,
	presaleCheckId?: string,
	select?: (data: IGetPresaleCheckProblemRepliesPayload) => T
) =>
	useQuery({
		queryKey: presaleChecksQueryKeys.presaleCheckProblemReplies(
			objectId as string,
			presaleCheckId as string
		),
		select,
		enabled: !!(presaleCheckId && objectId)
	});
