import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {createApiAction} from '@src/store/middlewares/api';
import {makeCommentDeleteRequest} from '@src/api/backend/comment';
import {addErrorToast, addSuccessToast} from '@tehzor/ui-components';
import {t} from 'i18next';

export interface IDeleteProblemCommentPayload {
	objectId: string;
	problemId: string;
	commentId: string;
}

const request = () => ({type: types.DELETE_REQUEST});

const success = (objectId: string, problemId: string, commentId: string) => {
	addSuccessToast({title: t('toast.deleteProblemComment.success.title')});
	return {
		type: types.DELETE_SUCCESS,
		payload: {
			objectId,
			problemId,
			commentId
		} as IDeleteProblemCommentPayload
	};
};

const failure = (error: IError) => {
	addErrorToast({title: t('toast.deleteProblemComment.error.title')});
	return {
		type: types.DELETE_FAILURE,
		payload: {...error}
	};
};

/**
 * Удаляет комментарий нарушения
 *
 * @param objectId id объекта
 * @param problemId id нарушения
 * @param commentId id комментария
 */
export const deleteProblemComment = (objectId: string, problemId: string, commentId: string) =>
	createApiAction(
		request,
		() => success(objectId, problemId, commentId),
		failure,
		() => makeCommentDeleteRequest(objectId, commentId)
	);
