import {useTranslation} from 'react-i18next';
import styles from './ProcessLabel.module.less';

interface ProcessLabelProps {
	processId: string;
	color?: string;
}

export const ProcessLabel = ({processId, color}: ProcessLabelProps) => {
	const {t} = useTranslation();

	return (
		<p className={styles.wrapper}>
			<span className={styles.process}>{t('processes.process')}: </span>
			<span
				className={styles.processName}
				style={{backgroundColor: color}}
			>
				{t(`processes.${processId}`)}
			</span>
		</p>
	);
};
