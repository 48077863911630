import {useMutation} from '@tanstack/react-query';
import {queryClient} from '@src/api/QueryClient';
import {problemTagsSetsQueryKeys} from '@src/api/cache/problemTagsSets/keys';
import {makeProblemTagsSetDeleteRequest} from '@src/api/backend/problemTagsSet';
import {addErrorToast, addSuccessToast} from '@tehzor/ui-components';
import {useTranslation} from 'react-i18next';

export const useDeleteProblemTagsSet = () => {
	const {t} = useTranslation();

	return useMutation({
		mutationKey: problemTagsSetsQueryKeys.delete(),
		mutationFn: (setId: string) => makeProblemTagsSetDeleteRequest(setId),
		onSuccess: () => {
			void queryClient.invalidateQueries({queryKey: problemTagsSetsQueryKeys.list()});
			addSuccessToast({title: t('toast.deleteProblemTagsSet.success.title')});
		},
		onError: () => {
			addErrorToast({title: t('toast.deleteProblemTagsSet.error.title')});
		}
	});
};
