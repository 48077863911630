import {queryClient} from '@src/api/QueryClient';
import {IGetCategoriesSetsResponse, requestAddCategoriesSet} from '@src/api/backend/categoriesSets';
import {categoriesSetsQueryKeys} from '@src/api/cache/categoriesSets/keys';
import {useMutation} from '@tanstack/react-query';
import IError from '@tehzor/tools/interfaces/IError';
import {ICategoriesSet} from '@tehzor/tools/interfaces/categoriesSets/ICategoriesSet';
import ISavingCategoriesSet from '@tehzor/tools/interfaces/categoriesSets/ISavingCategoriesSet';
import {addErrorToast, addSuccessToast} from '@tehzor/ui-components';
import {useTranslation} from 'react-i18next';

export const useAddCategoriesSet = () => {
	const {t} = useTranslation();

	return useMutation<ICategoriesSet, IError, ISavingCategoriesSet>({
		mutationFn: (params: ISavingCategoriesSet) => requestAddCategoriesSet(params),

		onSuccess: data => {
			queryClient.setQueryData<IGetCategoriesSetsResponse>(
				categoriesSetsQueryKeys.list(),
				oldData => {
					if (oldData) {
						return {
							byId: {...oldData.byId, [data.id]: data},
							allIds: [...oldData.allIds, data.id]
						};
					}
					return {
						byId: {[data.id]: data},
						allIds: [data.id]
					};
				}
			);
			addSuccessToast({title: t('toast.addCategoriesSet.success.title')});
		},
		onError: () => {
			addErrorToast({title: t('toast.addCategoriesSet.error.title')});
		}
	});
};
