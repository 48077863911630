import {httpRequests} from '@tehzor/tools/api/httpRequests';

export interface IDeleteStructureResponse {
	success: boolean;
}

/**
 * Удаляет структуру
 *
 * @param objectId id объекта
 * @param structureId id структуры
 */
export const makeStructureDeleteRequest = async (objectId: string, structureId: string) => {
	const response = await httpRequests.withToken.delete<IDeleteStructureResponse>(
		`/structures/${structureId}`,
		{data: {objectId}}
	);
	return response.data;
};
