import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {IPresaleCheckAddingEntityType, IPresaleCheckAddingSettingsState} from './interfaces';

const initialState: IPresaleCheckAddingSettingsState = {entityType: 'problem'};

export const presaleCheckAddingSlice = createSlice({
	name: 'presaleCheckAdding',
	initialState,
	reducers: {
		changePresaleCheckAddingEntityType(
			state,
			action: PayloadAction<IPresaleCheckAddingEntityType>
		) {
			state.entityType = action.payload;
		}
	}
});

export const {actions: presaleCheckAddingActions, reducer: presaleCheckAddingReducer} =
	presaleCheckAddingSlice;
