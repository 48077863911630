import {EntityAdding} from '../EntityAdding';
import {IPresaleCheck} from '@tehzor/tools/interfaces/presaleChecks/IPresaleCheck';
import {ActionsMenuDesktop} from '../ActionsMenuDesktop';
import {usePresaleChecksPermissions} from '@/entities/PresaleCheck/model/hooks/usePresaleChecksPermissions';

interface IMainActionsDesktopProps {
	objectId: string;
	presaleCheck: IPresaleCheck;
}

export const MainActionsDesktop = ({objectId, presaleCheck}: IMainActionsDesktopProps) => {
	const permissions = usePresaleChecksPermissions(objectId);

	return (
		<>
			<EntityAdding
				objectId={objectId}
				presaleCheck={presaleCheck}
			/>
			<ActionsMenuDesktop
				objectId={objectId}
				presaleCheck={presaleCheck}
				permissions={permissions}
			/>
		</>
	);
};
