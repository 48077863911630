import {IEnrichedTask} from '@tehzor/tools/interfaces/tasks/IEnrichedTask';
import {useTaskStatuses} from '@src/core/hooks/queries/taskStatuses/hook';
import {useTranslatedDictionary} from '@src/core/hooks/translations/useTranslatedDictionary';
import {dictionaryKeys} from '@src/constants/translations/dictionaryKeys';
import {useEditTaskStatus} from '@src/core/hooks/mutations/task/useEditTaskStatus';
import {useTasksPermissions} from '@src/core/hooks/permissions/useTasksPermissions';
import {TaskStatusId} from '@tehzor/tools/interfaces/tasks/ITaskStatus';
import ClickPreventWrap from '@src/components/tableCells/ClickPreventWrap';
import {StatusSelect} from '@tehzor/ui-components';
import {useCallback} from 'react';

interface IStatusProps {
	task: IEnrichedTask;
}

export const TaskStatus = ({task}: IStatusProps) => {
	const {data: statuses} = useTaskStatuses();
	const translatedStatuses = useTranslatedDictionary(dictionaryKeys.taskStatuses, statuses);
	const {mutateAsync: editTaskStatus} = useEditTaskStatus();

	const permissions = useTasksPermissions({
		objectId: task?.objectId,
		activeGroupLeader: task?.activeGroupLeader?.id,
		respUsers: task?.respUsers?.map(user => user.id),
		createdBy: task?.createdBy?.id,
		watchers: task?.watchers
	});

	const handleChange = useCallback(
		async (statusId: TaskStatusId) => {
			await editTaskStatus({taskId: task.id, statusId});
		},
		[task]
	);

	const availableStatuses = Object.values(TaskStatusId);

	return (
		<ClickPreventWrap>
			<StatusSelect
				statuses={translatedStatuses}
				availableStatuses={availableStatuses}
				disabled={!permissions.canEditStatus}
				value={task?.status?.id}
				onChange={handleChange}
			/>
		</ClickPreventWrap>
	);
};
