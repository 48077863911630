import {IconButton} from '@tehzor/ui-components';
import {useContext} from 'react';
import {useAsyncFn} from 'react-use';
import {IPreparedSpaceStatusesSet} from '../../interfaces/IPreparedSpaceStatusesSet';
import {DispatchActionCtx} from '../table/SpaceStatusesSetsTable';
import {useTranslatedConfirmDialog} from '@src/core/hooks/translations/useTranslatedConfirmDialog';
import {useTranslation} from 'react-i18next';

interface ISelectionActionsProps {
	selectedEntities: IPreparedSpaceStatusesSet[];
	onClear: () => void;
}

const deleteIcon = <i className="tz-delete" />;

export const SelectionActions = ({selectedEntities, onClear}: ISelectionActionsProps) => {
	const canDelete = selectedEntities.every(item => item.canDelete);

	const {t} = useTranslation();
	const dispatchAction = useContext(DispatchActionCtx);

	const [deleteDialog, getDeleteConfirmation] = useTranslatedConfirmDialog({
		title: t('useConfirmDialog.spaceStatusesSet.deleteTitle'),
		message: t('useConfirmDialog.spaceStatusesSet.deleteMessage')
	});

	const [, handleDelete] = useAsyncFn(async () => {
		if (await getDeleteConfirmation()) {
			for (const item of selectedEntities) {
				dispatchAction({type: 'delete', payload: item});
			}
			onClear();
		}
	}, [selectedEntities]);

	return (
		<>
			{canDelete && (
				<>
					<IconButton onClick={handleDelete}>{deleteIcon}</IconButton>

					{deleteDialog}
				</>
			)}
		</>
	);
};
