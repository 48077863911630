import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractOfflineModeAvailability} from '@src/store/modules/settings/offlineMode/selectors/availability';
import {extractNetworkStatus} from '@src/store/modules/offlineMode/selectors';
import {useExtractLatestPresaleChecksAsArray} from '../../PresaleChecksLatest/hooks/useExtractLatestPresaleChecksAsArray';
import {useMemo} from 'react';
import {useExtractLocalSpacePresaleChecks} from './useExtractLocalSpacePresaleChecks';

export const useMergedSpaceLocalAndLatestPresaleChecks = (objectId: string, spaceId: string) => {
	const offlineMode = useAppSelector(extractOfflineModeAvailability);
	const isOnline = useAppSelector(extractNetworkStatus);
	const appIsOffline = offlineMode && !isOnline;
	const {data: latestPresaleChecks} = useExtractLatestPresaleChecksAsArray(objectId, spaceId);
	const {data: localPresaleChecks} = useExtractLocalSpacePresaleChecks(objectId, spaceId);

	return useMemo(
		() =>
			appIsOffline
				? [...(latestPresaleChecks ?? []), ...(localPresaleChecks ?? [])]
				: localPresaleChecks,
		[latestPresaleChecks, localPresaleChecks, appIsOffline]
	);
};
