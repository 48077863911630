import {useCallback} from 'react';
import {Button, FloatingActionButton} from '@tehzor/ui-components';
import {useSpaceLocationForEntities} from '@src/core/hooks/spaces/useSpaceLocationForEntities';
import {BeforeDesktop, Desktop} from '@tehzor/ui-components/src/utils/mediaQueries';
import {useProblemsPermissions} from '@src/core/hooks/permissions/useProblemsPermissions';
import {useInspectionsAddingPermissions} from '@src/core/hooks/permissions/useInspectionsAddingPermissions';
import {useAggregateEntityPermissions} from '@src/core/hooks/permissions/useAggregateEntityPermissions';
import {useObject} from '@src/core/hooks/queries/objects/hooks';
import {queryClient} from '@src/api/QueryClient';
import {spacesQueryKeys} from '@src/api/cache/spaces/keys';
import {useTranslation} from 'react-i18next';
import {IPresaleCheck} from '@tehzor/tools/interfaces/presaleChecks/IPresaleCheck';
import {useAddingPresaleCheckDialog} from '../../hooks/useAddingPresaleCheckDialog';

interface IEntityAddingProps {
	objectId: string;
	presaleCheck: IPresaleCheck;
}

const mobileAddIcon = <i className="tz-plus-16" />;
const desktopAddIcon = <i className="tz-plus-20" />;

export const EntityAdding = ({objectId, presaleCheck}: IEntityAddingProps) => {
	const {t} = useTranslation();

	const {data: object} = useObject(objectId);

	const defaultData = useSpaceLocationForEntities(
		presaleCheck.objectId,
		presaleCheck.links?.spaceId
	);
	const [dialog, open] = useAddingPresaleCheckDialog({
		objectId,
		presaleCheckId: presaleCheck.id,
		links: presaleCheck.links,
		stage: object?.stage,
		defaultProblemData: defaultData,
		defaultInspectionData: defaultData,
		createdBy: presaleCheck.createdBy?.id
	});

	const handleClick = useCallback(() => {
		if (presaleCheck.links?.spaceId) {
			void queryClient.invalidateQueries({
				queryKey: spacesQueryKeys.detail(presaleCheck.links.spaceId)
			});
		}
		open();
	}, [presaleCheck, open]);

	const problemsPermissions = useProblemsPermissions(objectId);
	const presaleCheckProblemPermissions = useAggregateEntityPermissions(
		objectId,
		'presale-check-problems-add',
		{
			createdBy: presaleCheck.createdBy?.id
		}
	);
	const inspectionsPermissions = useInspectionsAddingPermissions(objectId);
	const presaleCheckInspectionPermissions = useAggregateEntityPermissions(
		objectId,
		'presale-check-inspections-add',
		{createdBy: presaleCheck.createdBy?.id}
	);

	return (problemsPermissions.canAdd && presaleCheckProblemPermissions.canAdd) ||
		(inspectionsPermissions.canAdd && presaleCheckInspectionPermissions.canAdd) ? (
		<>
			<BeforeDesktop>
				<FloatingActionButton
					icon={mobileAddIcon}
					label={t('presaleChecksPage.actions.add')}
					minifyOnScroll
					scrollContainer="scroll-container"
					onClick={handleClick}
				/>
			</BeforeDesktop>
			<Desktop>
				<Button
					leftIcon={desktopAddIcon}
					type="accent-blue"
					label={t('presaleChecksPage.actions.add')}
					onClick={handleClick}
				/>
			</Desktop>
			{dialog}
		</>
	) : null;
};
