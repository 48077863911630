import {useContext} from 'react';
import {IPreparedContract} from '../../interfaces/IPreparedContract';
import {DispatchActionCtx} from '../table/ContractsTable';
import {useAsyncFn} from 'react-use';
import {IconButton} from '@tehzor/ui-components';
import {useTranslation} from 'react-i18next';
import {useTranslatedConfirmDialog} from '@src/core/hooks/translations/useTranslatedConfirmDialog';

interface ISelectionActionsProps {
	selectedEntities: IPreparedContract[];
	onClear: () => void;
}

const deleteIcon = <i className="tz-delete" />;

export const SelectionActions = ({selectedEntities, onClear}: ISelectionActionsProps) => {
	const canDelete = selectedEntities.every(item => item.canDelete);

	const {t} = useTranslation();
	const dispatchAction = useContext(DispatchActionCtx);

	const [deleteDialog, getDeleteConfirmation] = useTranslatedConfirmDialog({
		title: t('useConfirmDialog.dialogs.deleteTitle'),
		message: t('useConfirmDialog.dialogs.deleteMessage')
	});

	const [, handleDelete] = useAsyncFn(async () => {
		if (await getDeleteConfirmation()) {
			for (const payload of selectedEntities) {
				dispatchAction({type: 'delete', payload});
			}
			onClear();
		}
	}, [selectedEntities]);

	return canDelete ? (
		<>
			<IconButton onClick={handleDelete}>{deleteIcon}</IconButton>

			{deleteDialog}
		</>
	) : null;
};
