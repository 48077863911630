import {useMutation} from '@tanstack/react-query';
import {queryClient} from '@src/api/QueryClient';
import {fieldSettingsSetsQueryKeys} from '../keys/index';
import {deleteFieldsSettingsSetRequest} from '@/shared/api/fieldsSettingsSets';
import {addErrorToast} from '@tehzor/ui-components';
import {useTranslation} from 'react-i18next';

export const useDeleteFieldsSettingsSet = () => {
	const {t} = useTranslation();

	return useMutation({
		mutationKey: fieldSettingsSetsQueryKeys.edit(),
		mutationFn: (setId: string) => deleteFieldsSettingsSetRequest(setId),
		onError: () => addErrorToast({title: t('toast.deleteFieldsSettingsSet.error.title')}),
		onSuccess: () => {
			void queryClient.invalidateQueries({queryKey: fieldSettingsSetsQueryKeys.all()});
		}
	});
};
