import {Suspense, memo} from 'react';
import {useObjectSectionsMenu} from '@src/core/hooks/useObjectSectionsMenu';
import {useObjectAppHeader} from '@src/components/AppHeader/hooks/useObjectAppHeader';
import useAppSelector from '@src/core/hooks/useAppSelector';
import PageBreadcrumbs from './PageBreadcrumbs';
import SpacesFilters from './filters/SpacesFilters';
import {SpacesDisplayMode} from '@src/interfaces/SpacesDisplayMode';
import {extractSpacesPageSettings} from '@src/store/modules/settings/pages/spaces/selectors';
import DesktopSchema from './schema/Schemas.desktop';
import DesktopTable from './table/Table.desktop';
import SettingsPopup from './settings/SettingsPopup';
import {DesktopDisplayModeSwitch} from './DisplayModeSwitch.desktop';
import {FloorSort} from './sort/FloorSort';
import {SpacesSchemaVariants} from '@src/interfaces/SpacesSchemaVariants';
import {ProcessVisibilityFilter} from '@src/pages/SpacesPage/components/filters/ProcessVisibilityFilter';

interface IDesktopProps {
	objectId: string;
}

const Desktop = memo(({objectId}: IDesktopProps) => {
	const pageSettings = useAppSelector(s => extractSpacesPageSettings(s, objectId));

	const sectionsMenu = useObjectSectionsMenu(objectId);
	useObjectAppHeader(objectId, {sectionsMenu});

	return (
		<div className="page-cont spaces-page">
			<PageBreadcrumbs objectId={objectId} />

			<SpacesFilters objectId={objectId} />
			<div className="spaces-page__d-controls-bar">
				<ProcessVisibilityFilter objectId={objectId} />
				{pageSettings.displayMode === SpacesDisplayMode.SCHEMA &&
					pageSettings.schemaView !== SpacesSchemaVariants.REPORTS && (
						<>
							<FloorSort objectId={objectId} />
							<SettingsPopup />
						</>
					)}
				<DesktopDisplayModeSwitch objectId={objectId} />
			</div>
			{pageSettings.displayMode === SpacesDisplayMode.SCHEMA && (
				<Suspense>
					<DesktopSchema objectId={objectId} />
				</Suspense>
			)}
			{pageSettings.displayMode === SpacesDisplayMode.LIST && (
				<DesktopTable objectId={objectId} />
			)}
		</div>
	);
});

export default Desktop;
