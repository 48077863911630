import {httpRequests} from '@tehzor/tools/api/httpRequests';
import {IPresaleCheck} from '@tehzor/tools/interfaces/presaleChecks/IPresaleCheck';

/**
 * Удаляет предпродажную проверку
 *
 * @param presaleCheckId id предпродажной проверки
 */
export const requestDeletePresaleCheck = async (objectId: string, presaleCheckId: string) => {
	const response = await httpRequests.withToken.delete<IPresaleCheck>(
		`presale-checks/${presaleCheckId}`,
		{params: {objectId}}
	);

	return response.data;
};
