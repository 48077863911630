import {IconMenu, MenuItem} from '@tehzor/ui-components';
import {useAsyncFn} from 'react-use';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {IStructure} from '@tehzor/tools/interfaces/structures/IStructure';
import {useAppUpdateMenuItem} from '@src/components/AppUpdateData';
import {useEditableStructureDialog} from '@src/components/EditableStructureDialog/hooks/useEditableStructureDialog';
import {useTranslation} from 'react-i18next';
import {useDeleteStructure} from '@/entities/Structure';
import {useTranslatedConfirmDialog} from '@src/core/hooks/translations/useTranslatedConfirmDialog';

interface IMobileMenuProps {
	structure: IStructure;
	canDeleteStructure?: boolean;
	canEditStructure?: boolean;
}

export const MobileMenu = ({structure, canDeleteStructure, canEditStructure}: IMobileMenuProps) => {
	const {t} = useTranslation();
	const {goBack} = useChangePath();
	const {mutateAsync: deleteStructure} = useDeleteStructure();
	const [deleteDialog, getDeleteConfirmation] = useTranslatedConfirmDialog({
		title: t('structurePage.actions.menu.confirmDialog.delete.title'),
		message: `${t('structurePage.actions.menu.confirmDialog.delete.message')} "${
			structure.name
		}"?`
	});

	const [, handleDeleteClick] = useAsyncFn(async () => {
		if (await getDeleteConfirmation()) {
			await deleteStructure({objectId: structure.objectId, structureId: structure.id});
			goBack();
		}
	}, [structure.objectId, structure.id]);

	// Диалог редактирования структуры
	const [editDialog, openEditDialog] = useEditableStructureDialog(
		structure.objectId,
		structure.id
	);
	// Кнопка обновления данных приложения
	const updateData = useAppUpdateMenuItem();

	const items = [];

	items.push(updateData);

	if (canEditStructure) {
		items.push(
			<MenuItem
				key="edit"
				icon={<i className="tz-edit" />}
				onClick={openEditDialog}
			>
				{t('structurePage.actions.menuItems.edit')}
			</MenuItem>
		);
	}
	if (canDeleteStructure) {
		items.push(
			<MenuItem
				key="delete"
				icon={<i className="tz-delete" />}
				onClick={handleDeleteClick}
			>
				{t('structurePage.actions.menuItems.delete')}
			</MenuItem>
		);
	}

	return items.length ? (
		<>
			<IconMenu>{items}</IconMenu>

			{canDeleteStructure && deleteDialog}
			{canEditStructure && editDialog}
		</>
	) : null;
};
