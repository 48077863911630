import {makeObjectAddRequest} from '@src/api/backend/object';
import {objectsQueryKeys} from '@src/api/cache/objects/keys';
import {queryClient} from '@src/api/QueryClient';
import {ISavingObject} from '@src/interfaces/saving/ISavingObject';
import {useMutation} from '@tanstack/react-query';
import {addErrorToast} from '@tehzor/ui-components';
import {useTranslation} from 'react-i18next';

export const useAddObject = () => {
	const {t} = useTranslation();

	return useMutation({
		mutationKey: objectsQueryKeys.add(),
		mutationFn: (object: ISavingObject) => makeObjectAddRequest(object),
		onSuccess: () => {
			void queryClient.invalidateQueries({queryKey: objectsQueryKeys.list()});
		},
		onError: () => addErrorToast({title: t('toast.addObject.error.title')})
	});
};
