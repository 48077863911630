import {ICheckList} from '@tehzor/tools/interfaces/checkLists/ICheckList';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {
	extractCheckRecordByItemId,
	extractCheckRecordByListId
} from '@src/store/modules/pages/checkLists/selectors/records';
import {usePercentInput} from '@src/pages/CheckListPage/hooks/usePercentInput';
import {memo, useCallback} from 'react';
import * as React from 'react';
import {CheckRecordStatusId} from '@tehzor/tools/interfaces/checkRecords/ICheckRecordStatus';
import {CircleLoader, StatusSelect} from '@tehzor/ui-components';
import {ICheckItem} from '@tehzor/tools/interfaces/checkItems/ICheckItem';
import {ICheckRecord} from '@tehzor/tools/interfaces/checkRecords/ICheckRecord';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {ApiAction} from '@src/store/middlewares/api';
import classNames from 'classnames';
import {IChangeCheckRecordStatusParams} from '@src/core/hooks/mutations/checkRecords/useChangeCheckRecord';
import {useExtractCheckListById} from '@src/core/hooks/queries/checkLists/hooks';
import {useExtractTreeCheckItemById} from '@src/core/hooks/queries/checkItems/hooks';
import {useExtractCheckRecordStatusesByEntityType} from '@src/core/hooks/queries/checkRecordStatuses/hooks';
import {dictionaryKeys} from '@src/constants/translations/dictionaryKeys';
import {useTranslatedDictionary} from '@src/core/hooks/translations/useTranslatedDictionary';
import {useTranslation} from 'react-i18next';
import {useTranslatedConfirmDialog} from '@src/core/hooks/translations/useTranslatedConfirmDialog';

export const isCheckItem = (arg: ICheckList | ICheckItem): arg is ICheckItem =>
	(arg as ICheckItem)?.checkListId !== undefined;

interface IRecordStatusSelectProps {
	item: ICheckList | ICheckItem;
	action?: (
		item: ICheckList | ICheckItem,
		objectId: string,
		spaceId: string | undefined,
		workAcceptanceId: string | undefined,
		stage: ObjectStageIds,
		status: CheckRecordStatusId
	) => ApiAction<ICheckRecord[]>;
	mutation?: (params: IChangeCheckRecordStatusParams) => void;
	showLoader?: boolean;
	setChangingState?: React.Dispatch<React.SetStateAction<boolean>>;
	disabled?: boolean;
	availableStatuses?: string[];
}

export const RecordStatusSelect = memo(
	({
		item,
		action,
		mutation,
		showLoader,
		setChangingState,
		disabled,
		availableStatuses
	}: IRecordStatusSelectProps) => {
		const {t} = useTranslation();
		const dispatch = useAppDispatch();
		const {objectId, spaceId, workAcceptanceId} = useStrictParams<{
			objectId: string;
			spaceId?: string;
			workAcceptanceId?: string;
		}>();

		const record = useAppSelector(s =>
			isCheckItem(item)
				? extractCheckRecordByItemId(s, item.id)
				: extractCheckRecordByListId(s, item.id)
		);

		const {data: statuses} = useExtractCheckRecordStatusesByEntityType(
			isCheckItem(item) ? 'check-item' : 'check-list'
		);
		const translatedStatuses = useTranslatedDictionary(
			dictionaryKeys.checkListStatuses,
			statuses
		);

		const {data: checkList} = useExtractCheckListById(
			isCheckItem(item) ? item.checkListId : item.id
		);
		const checkItemCheckListId = isCheckItem(item) ? item?.checkListId : undefined;
		const {data: treeItem} = useExtractTreeCheckItemById(checkItemCheckListId, item.id);

		const [, percentInput] = usePercentInput(record, objectId, checkList?.stage, disabled);

		const [closingDialog, getClosingConfirmation] = useTranslatedConfirmDialog({
			title: t('checkListPage.checkListItems.itemActions.confirmDialog.title'),
			message: t('checkListPage.checkListItems.itemActions.confirmDialog.message')
		});

		const handleSelectChange = useCallback(
			async (value: CheckRecordStatusId) => {
				let confirmationResult = true;

				if (
					value === CheckRecordStatusId.ACCEPTED &&
					(treeItem?.children?.length || !isCheckItem(item))
				) {
					confirmationResult = await getClosingConfirmation();
				}

				if (!confirmationResult) {
					return;
				}
				if (setChangingState) {
					setChangingState(true);
				}
				if (action) {
					await dispatch(
						action(item, objectId, spaceId, workAcceptanceId, checkList!.stage, value)
					);
				}
				if (mutation) {
					mutation({
						item,
						objectId,
						spaceId,
						workAcceptanceId,
						stage: checkList!.stage,
						status: value,
						record
					});
				}
			},
			[
				treeItem?.children?.length,
				item,
				setChangingState,
				action,
				mutation,
				getClosingConfirmation,
				dispatch,
				objectId,
				spaceId,
				workAcceptanceId,
				checkList,
				record
			]
		);
		const statusSelectClasses = {
			root: 'check-list-page__status-select-menu',
			btn: classNames('check-list-page__status-select', {
				'check-list-page__status-select_hidden': showLoader,
				'check-list-page__status-select_disabled': disabled
			})
		};
		return (
			<div className="check-list-page__status">
				<StatusSelect
					className={statusSelectClasses}
					statuses={translatedStatuses}
					value={record?.status || CheckRecordStatusId.NOT_CHECKED}
					onChange={handleSelectChange}
					availableStatuses={availableStatuses}
					disabled={disabled}
				/>

				{record?.status === CheckRecordStatusId.PARTIALLY_ACCEPTED && (
					<div className="check-list-page__status-percent">{percentInput}</div>
				)}

				{showLoader ? <CircleLoader className="check-list-page__status-loader" /> : null}

				{closingDialog}
			</div>
		);
	}
);
