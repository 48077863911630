import {NamesTable} from '../NamesTable/NamesTable';
import {BodyTable} from '../BodyTable/BodyTable';
import {IPreparedField} from '@/features/Fields';
import {Dispatch, memo, SetStateAction} from 'react';
import styles from './SettingsTable.module.less';
import {useIsBeforeTablet} from '@/shared/hooks/dimensions/useIsDesktop/useIsDesktop';

interface ITableProps {
	title: string;
	setId: string;
	fields: IPreparedField[];
	withSettings?: boolean;
	onChangeOrder?: Dispatch<SetStateAction<IPreparedField[]>>;
	dndContainerId: string;
	isBuiltIn: boolean;
}

export const SettingsTable = memo(
	({
		title,
		setId,
		fields,
		withSettings = false,
		onChangeOrder,
		dndContainerId,
		isBuiltIn
	}: ITableProps) => {
		const isBeforeTablet = useIsBeforeTablet();

		return (
			<div className={styles.wrapper}>
				<div className={styles.header}>
					<p className={styles.title}>{title}</p>
				</div>
				<div className={styles.container}>
					<NamesTable
						setId={setId}
						fields={fields}
						onChangeOrder={onChangeOrder}
						disabled={!withSettings}
						containerId={dndContainerId}
					/>
					{!isBeforeTablet && (
						<BodyTable
							isBuiltIn={isBuiltIn}
							withSettings={withSettings}
							fields={fields}
						/>
					)}
				</div>
			</div>
		);
	}
);
