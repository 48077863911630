import {httpRequests} from '@tehzor/tools/api/httpRequests';

export type IMoveProblemsResponse = true;

interface IProblemCheckTarget {
	checkId: string;
	spaceId?: string;
	internalAcceptanceId?: undefined;
}

interface IProblemInternalAcceptanceTarget {
	internalAcceptanceId: string;
	spaceId?: string;
	checkId?: undefined;
}
export type IProblemTarget = IProblemCheckTarget | IProblemInternalAcceptanceTarget;

/**
 * Перемещение нарушений в другую проверку
 *
 * @param objectId id объекта
 * @param target id целевой сущности
 * @param problemId id нарушения
 */
export const makeProblemsMoveRequest = async (
	objectId: string,
	target: IProblemTarget,
	problemId: string
) => {
	const response = await httpRequests.withToken.patch<IMoveProblemsResponse>(
		`problems/move-problem/${problemId}`,
		{target, objectId}
	);

	return response.data;
};
