import {memo} from 'react';
import {CellProps} from 'react-table';
import {IPreparedWarrantyClaimEntity} from '../../interfaces/IPreparedWarrantyClaimEntity';

export const ModifiedCell = memo(({row}: CellProps<IPreparedWarrantyClaimEntity>) => {
	const claim = row.original.data;

	return (
		<div className="warranty-claims-table__modified-cell">
			<div>{claim.modifiedAt}</div>
			<div>{claim.createdBy?.displayName}</div>
		</div>
	);
});
