import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {createApiAction} from '@src/store/middlewares/api';
import {makeProblemDeleteRequest} from '@src/api/backend/problem';
import {queryClient} from '@src/api/QueryClient';
import {problemsQueryKeys} from '@src/api/cache/problems/keys';
import {deleteLocalProblem} from '@src/cache/actions/problem/deleteLocalProblem';
import {invalidateLinkedProblemsQueries} from '@src/cache/actions/problem/invalidateLinkedProblemsQueries';
import {warrantyClaimsQueryKeys} from '@src/api/cache/warrantyClaims/keys';
import {structuresQueryKeys} from '@/entities/Structures/model/cache/keys';
import {addErrorToast, addSuccessToast} from '@tehzor/ui-components';
import {t} from 'i18next';

export interface IDeleteProblemPayload {
	objectId: string;
	problemId: string;
}

const request = () => ({type: types.DELETE_REQUEST});

const success = (objectId: string, problemId: string, quietly?: boolean) => {
	if (!quietly) {
		addSuccessToast({title: t('toast.deleteProblem.success.title')});
	}
	return {
		type: types.DELETE_SUCCESS,
		payload: {
			objectId,
			problemId
		} as IDeleteProblemPayload
	};
};

const failure = (error: IError) => {
	addErrorToast({title: t('toast.deleteProblem.error.title')});
	return {
		type: types.DELETE_FAILURE,
		payload: error
	};
};

/**
 * Удаляет нарушение
 *
 * @param objectId id объекта
 * @param problemId id нарушения
 */

export const deleteProblemActions = {request, success, failure};
export const deleteProblem = (objectId: string, problemId: string) =>
	createApiAction(
		request,
		() => {
			queryClient.removeQueries({queryKey: problemsQueryKeys.detail(problemId)});
			void queryClient.invalidateQueries({queryKey: problemsQueryKeys.list()});
			void invalidateLinkedProblemsQueries(objectId);
			void queryClient.invalidateQueries({queryKey: warrantyClaimsQueryKeys.problemsStats()});
			void queryClient.invalidateQueries({queryKey: structuresQueryKeys.problemsStatsAll()});
			void queryClient.invalidateQueries({queryKey: structuresQueryKeys.problems()});
			return success(objectId, problemId);
		},
		failure,
		async () => {
			if (queryClient.getQueryData(problemsQueryKeys.savingData(problemId))) {
				await deleteLocalProblem(problemId);
				return;
			}
			await makeProblemDeleteRequest(objectId, problemId);
		}
	);
