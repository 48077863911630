import {useCallback, memo} from 'react';
import * as React from 'react';
import {IOfflineJournalEntity} from '../../interfaces/IOfflineJournalEntity';
import {OfflineJournalStatus} from '../OfflineJournalStatus/OfflineJournalStatus';
import {format} from 'date-fns';
import {IconMenu, MenuItem} from '@tehzor/ui-components';
import {dateTimeCommaSeparatedFormat} from '@tehzor/tools/utils/dateFormats';
import './OfflineJournalTitle.less';
import {EntityLink} from '../EntityLink';
import {offlineJournalActionType} from '../../enums/actionsTypes';
import {deleteLocalCheck} from '@src/cache/actions/check/deleteLocalCheck';
import {deleteLocalOwnerAcceptance} from '@src/cache/actions/ownerAcceptance/deleteLocalOwnerAcceptance';
import {deleteLocalInternalAcceptance} from '@src/cache/actions/internalAcceptance/deleteLocalInternalAcceptance';
import {deleteLocalWarrantyClaim} from '@src/cache/actions/warrantyClaim/deleteLocalWarrantyClaim';
import {deleteLocalProblem} from '@src/cache/actions/problem/deleteLocalProblem';
import {deleteLocalInspection} from '@src/cache/actions/inspection/deleteLocalInspection';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {useTranslation} from 'react-i18next';
import {getEntityActionsType} from '@src/pages/OfflineJournalPage/utils/getEntityActionsType';
import {deleteLocalPresaleCheck} from '@src/cache/actions/presaleCheck/deleteLocalPresaleCheck';
import {useTranslatedConfirmDialog} from '@src/core/hooks/translations/useTranslatedConfirmDialog';

interface IOfflineJournalTitleProps {
	offlineJournalEntity: IOfflineJournalEntity;
}

export const OfflineJournalTitle = memo((props: IOfflineJournalTitleProps) => {
	const {t} = useTranslation();
	const {offlineJournalEntity} = props;
	const {entity, createdAt} = offlineJournalEntity;
	const {pushPath} = useChangePath();
	const formatedDate = format(createdAt || 0, dateTimeCommaSeparatedFormat);

	const handleGoToButton = useCallback(
		(url: string) => {
			pushPath(url);
		},
		[pushPath]
	);

	const [deletingDialog, getDeletingConfirmation] = useTranslatedConfirmDialog({
		title: t('offlineJournalPage.offlineJournalTitle.useConfirmDialog.title'),
		message: t('offlineJournalPage.offlineJournalTitle.useConfirmDialog.message')
	});

	const handleDeleteButton = useCallback(
		async (offlineJournalEntity: IOfflineJournalEntity) => {
			const {entityId, entityType} = offlineJournalEntity.entity;
			const objectId = offlineJournalEntity.object?.objectId;
			if (await getDeletingConfirmation()) {
				switch (entityType) {
					case offlineJournalActionType.CHECK_ADD:
						await deleteLocalCheck(entityId, objectId);
						break;
					case offlineJournalActionType.PRESALE_CHECK_ADD:
						await deleteLocalPresaleCheck(entityId);
						break;
					case offlineJournalActionType.INTERNAL_ACCEPTANCE_ADD:
						await deleteLocalInternalAcceptance(entityId, objectId);
						break;
					case offlineJournalActionType.OWNER_ACCEPTANCE_ADD:
						await deleteLocalOwnerAcceptance(entityId, objectId);
						break;
					case offlineJournalActionType.WARRANTY_CLAIM_ADD:
						await deleteLocalWarrantyClaim(entityId, objectId);
						break;
					case offlineJournalActionType.PROBLEM_ADD ||
						offlineJournalActionType.PROBLEM_CHECKLIST_ADD ||
						offlineJournalActionType.PROBLEM_SPACE_ADD:
						await deleteLocalProblem(entityId);
						break;
					case offlineJournalActionType.INSPECTION_ADD ||
						offlineJournalActionType.INSPECTION_CHECKLIST_ADD ||
						offlineJournalActionType.INSPECTION_SPACE_ADD:
						await deleteLocalInspection(entityId);
						break;
				}
			}
		},
		[getDeletingConfirmation]
	);

	const handleIconMenu = useCallback((event: React.MouseEvent<HTMLDivElement>) => {
		event.stopPropagation();
	}, []);

	return (
		<div className="offline-journal-title">
			<div className="offline-journal-title__content">
				<span className="offline-journal-title__content-title">
					{getEntityActionsType(entity.entityType, t)}
				</span>
				<span className="offline-journal-title__content-date">{formatedDate}</span>
				<OfflineJournalStatus
					className="offline-journal-title__content-status"
					offlineJournalEntity={offlineJournalEntity}
				/>
			</div>
			<div onClick={handleIconMenu}>
				{offlineJournalEntity.entity.entityType !==
					offlineJournalActionType.CHECK_ITEM_STATUS_CHANGE &&
					offlineJournalEntity.entity.entityType !==
						offlineJournalActionType.CHECK_LIST_STATUS_CHANGE &&
					offlineJournalEntity.entity.entityType !==
						offlineJournalActionType.SPACE_INDICATORS_CHANGE &&
					offlineJournalEntity.entity.entityType !==
						offlineJournalActionType.SPACE_STATUS_CHANGE && (
						<IconMenu className="offline-journal-title__menu">
							<EntityLink
								item={offlineJournalEntity}
								goTo={handleGoToButton}
							/>

							<MenuItem
								key="delete"
								icon={<i className="tz-delete" />}
								onClick={() => handleDeleteButton(offlineJournalEntity)}
							>
								{t('offlineJournalPage.offlineJournalTitle.menuItem.delete.text')}
							</MenuItem>
						</IconMenu>
					)}
				{deletingDialog}
			</div>
		</div>
	);
});
