import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {SpaceEntitiesVisibility} from '@src/interfaces/SpaceEntitiesVisibility';
import {SpaceEntitiesTab} from '@src/interfaces/SpaceEntitiesTab';
import {ISettingsPageSpaceState} from './interfaces';

const initialState: ISettingsPageSpaceState = {
	entitiesVisibility: [SpaceEntitiesVisibility.PROBLEMS, SpaceEntitiesVisibility.INSPECTIONS],
	entitiesTab: SpaceEntitiesTab.ALL
};

export const pageSpaceSlice = createSlice({
	name: 'pageSpace',
	initialState,
	reducers: {
		/**
		 * Изменяет фильтр отображения сущностей помещения
		 */
		changeEntitiesVisibility(state, action: PayloadAction<SpaceEntitiesVisibility[]>) {
			state.entitiesVisibility = action.payload;
		},
		/**
		 * Изменяет вкладку отображения сущностей помещения
		 */
		changeEntitiesTab(state, action: PayloadAction<SpaceEntitiesTab>) {
			state.entitiesTab = action.payload;
		}
	}
});

export const {
	actions: {changeEntitiesVisibility, changeEntitiesTab},
	reducer: pageSpaceReducer
} = pageSpaceSlice;
