import {ReactNode, useCallback, useState} from 'react';
import {EditingCustomFieldDialog} from '@/features/Fields/components/EditingCustomFieldDialog/EditingCustomFieldDialog';
import {ICustomField} from '@tehzor/tools/interfaces/fields';

interface IUseEditingCustomFieldDialogProps {
	field?: ICustomField;
}

export const useEditingCustomFieldDialog = ({
	field
}: IUseEditingCustomFieldDialogProps): [ReactNode, (data?: ICustomField) => void] => {
	const [currentField, setCurrentField] = useState<ICustomField | undefined>(field);
	const [isOpen, setOpen] = useState(false);

	const open = useCallback((data?: ICustomField) => {
		if (data) setCurrentField(data);
		setOpen(true);
	}, []);

	const close = useCallback(() => {
		setOpen(false);
	}, []);

	const dialog = (
		<EditingCustomFieldDialog
			field={currentField}
			isOpen={isOpen}
			close={close}
		/>
	);

	return [dialog, open];
};
