import React, {useCallback, useEffect, useState} from 'react';
import useConfirmDialog from '@tehzor/ui-components/src/hooks/useConfirmDialog';
import IComment from '@tehzor/tools/interfaces/comments/IComment';
import {useCommentsCtx} from '../utils/CommentsCtx';
import {useTranslation} from 'react-i18next';

export function useCommentDeleting(): [React.ReactNode, (comment: IComment) => Promise<void>] {
	const {t} = useTranslation();
	const {deleteReply, deleteComment} = useCommentsCtx();
	const [commentToDelete, setDeletingComment] = useState<IComment>();

	const [dialog, getConfirmation, changeDialogData] = useConfirmDialog('', '', {
		acceptBtnProps: {type: 'accent-red', label: t('dialog.yes')},
		rejectBtnProps: {label: t('dialog.no')}
	});

	useEffect(() => {
		if (commentToDelete) {
			changeDialogData({
				title: commentToDelete.official
					? t('useConfirmDialog.reply.deleteTitle')
					: t('useConfirmDialog.comment.deleteTitle'),
				message: commentToDelete.official
					? t('useConfirmDialog.reply.deleteMessage', {
							number: commentToDelete.number || ''
					  })
					: t('useConfirmDialog.comment.deleteMessage')
			});
		}
	}, [commentToDelete, t, changeDialogData]);

	const deletion = useCallback(
		async (comment: IComment) => {
			setDeletingComment(comment);
			if (await getConfirmation()) {
				comment.official ? deleteReply(comment.id) : deleteComment(comment.id);
			}
		},
		[deleteComment, deleteReply, getConfirmation]
	);

	return [dialog, deletion];
}
