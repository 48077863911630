import {Toggle} from '@tehzor/ui-components';
import {IPreparedField, useFieldsIsUpdating} from '@/features/Fields';
import {CellContext} from '@tanstack/react-table';
import {useEditFieldSettings} from '@/entities/FieldSettings';

export const FieldRequiredCell = ({row}: CellContext<IPreparedField, IPreparedField>) => {
	const field = row.original;
	const {mutateAsync: editFieldSettings} = useEditFieldSettings();
	const {isUpdating} = useFieldsIsUpdating();

	const handleRequiredChange = async () => {
		if (isUpdating) return;
		if (field.settingsId) {
			await editFieldSettings({settingId: field.settingsId, required: !field.required});
		}
	};

	return (
		<div>
			<Toggle
				checked={field.required}
				onChange={handleRequiredChange}
				disabled={isUpdating}
			/>
		</div>
	);
};
