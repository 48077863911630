import {useMutation} from '@tanstack/react-query';
import {makeSpaceExportRequest} from '@src/api/backend/space';
import {spacesQueryKeys} from '@src/api/cache/spaces/keys';
import IError from '@tehzor/tools/interfaces/IError';
import {IExportResponse} from '@tehzor/tools/interfaces/IExportResponse';
import {ISpaceEntityExport} from '@src/interfaces/ISpaceEntityExport';
import {addErrorToast} from '@tehzor/ui-components';
import {IExportSpacesParams} from '@src/api/cache/spaces/defaults/useSpacesMutationDefaults';
import {useTranslation} from 'react-i18next';
import {addExportToast} from '@/shared/lib/Toasts/ExportToast/addExportToast';

export const useExportSpace = () => {
	const {t} = useTranslation();

	return useMutation<IExportResponse, IError, ISpaceEntityExport>({
		mutationKey: spacesQueryKeys.edit(),
		mutationFn: (params: ISpaceEntityExport) => makeSpaceExportRequest(params),
		onSuccess: (response: IExportResponse, {email}: IExportSpacesParams) => {
			if (response.link) {
				addExportToast({
					title: email
						? t('toast.export.send.success.title')
						: t('toast.export.success.title'),
					url: response.link,
					fileName: response.file.name,
					document: response.document
				});
			}
		},
		onError: () => addErrorToast({title: t('toast.exportSpace.error.title')})
	});
};
