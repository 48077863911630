import {queryClient} from '@src/api/QueryClient';
import {
	IEditWorkAcceptanceAcceptorsResponse,
	makeWorkAcceptanceAcceptorsEditRequest
} from '@src/api/backend/workAcceptance';
import {workAcceptancesQueryKeys} from '@src/api/cache/workAcceptances/keys';
import {createApiAction} from '@src/store/middlewares/api';
import IError from '@tehzor/tools/interfaces/IError';
import * as types from '../constants';
import {delegationHistoryQueryKeys} from '@src/api/cache/delegationHistory/keys';
import {WorkingGroupTypeId} from '@tehzor/tools/interfaces/workingGroups/IWorkingGroupType';
import {t} from 'i18next';

export type IEditWorkAcceptanceResponsiblePayload = IEditWorkAcceptanceAcceptorsResponse;
import {addErrorToast} from '@tehzor/ui-components';

const request = () => ({type: types.EDIT_ACCEPTORS_REQUEST});

const success = (response: IEditWorkAcceptanceAcceptorsResponse) => {
	void queryClient.invalidateQueries({queryKey: workAcceptancesQueryKeys.lists()});
	void queryClient.invalidateQueries({queryKey: workAcceptancesQueryKeys.paginate()});
	void queryClient.invalidateQueries({queryKey: workAcceptancesQueryKeys.details()});
	void queryClient.invalidateQueries({
		queryKey: delegationHistoryQueryKeys.histories({
			objectId: response.objectId,
			workAcceptanceId: response.id,
			workingGroupType: WorkingGroupTypeId.ACCEPTORS
		})
	});
	return {
		type: types.EDIT_ACCEPTORS_SUCCESS,
		payload: response
	};
};

const failure = (error: IError) => {
	addErrorToast({title: t('toast.editWorkAcceptanceAcceptors.error.title')});
	return {
		type: types.EDIT_ACCEPTORS_FAILURE,
		payload: error
	};
};

/**
 * Изменяет принимающих приемки работ
 *
 * @param objectId id объекта
 * @param workAcceptanceId id приемки работ
 * @param acceptors пользователи
 * @param activeGroup текущая группа
 */
export const editWorkAcceptanceAcceptors = (
	objectId: string,
	workAcceptanceId: string,
	acceptors?: string[],
	activeGroup?: string
) =>
	createApiAction<IEditWorkAcceptanceAcceptorsResponse>(request, success, failure, () =>
		makeWorkAcceptanceAcceptorsEditRequest(objectId, workAcceptanceId, acceptors, activeGroup)
	);
