import {useMutation} from '@tanstack/react-query';
import {queryClient} from '@src/api/QueryClient';
import {fieldSettingsSetsQueryKeys} from '../keys/index';
import {ISavingFieldsSettingsSet} from '@src/interfaces/saving/ISavingFieldsSettingsSet';
import {requestAddFieldsSettingsSet} from '@/shared/api/fieldsSettingsSets/addFieldsSettingsSet';
import {addErrorToast} from '@tehzor/ui-components';
import {useTranslation} from 'react-i18next';

export const useAddFieldsSettingsSet = () => {
	const {t} = useTranslation();

	return useMutation({
		mutationKey: fieldSettingsSetsQueryKeys.add(),
		mutationFn: (fields: ISavingFieldsSettingsSet) => requestAddFieldsSettingsSet(fields),
		onError: () => addErrorToast({title: t('toast.addFieldsSettingsSet.error.title')}),
		onSuccess: () => {
			void queryClient.invalidateQueries({queryKey: fieldSettingsSetsQueryKeys.all()});
		}
	});
};
