import {makeOwnerAcptExportRequest} from '@src/api/backend/ownerAcceptance';
import {addExportToast} from '@/shared/lib/Toasts/ExportToast/addExportToast';
import {addErrorToast, addWarningToast} from '@tehzor/ui-components';
import {useMutation} from '@tanstack/react-query';
import {IExportResponse} from '@tehzor/tools/interfaces/IExportResponse';
import {ownerAcceptancesQueryKeys} from '@src/api/cache/ownerAcceptances/keys';
import {useTranslation} from 'react-i18next';

interface IExportOwnerAcceptanceParams {
	templateId: string;
	objectId: string;
	acceptanceId: string;
	problems?: string[];
	createDocument?: boolean;
	email?: string;
}

export const useExportOwnerAcceptance = () => {
	const {t} = useTranslation();

	return useMutation<IExportResponse, Error, IExportOwnerAcceptanceParams>({
		mutationKey: ownerAcceptancesQueryKeys.exportOne(),
		mutationFn: params => {
			const {templateId, objectId, acceptanceId, problems, createDocument, email} = params;
			return makeOwnerAcptExportRequest(
				templateId,
				objectId,
				acceptanceId,
				problems,
				createDocument,
				email
			);
		},
		onSuccess: response => {
			if (response.warnings?.itemsLimit !== undefined) {
				addWarningToast({
					title: `${t('toast.export.warning.title')} (${String(
						response.warnings.itemsLimit
					)})`
				});
			}
			addExportToast({
				title: t('toast.export.success.title'),
				url: response.link,
				fileName: response.file.name,
				document: response.document
			});
		},
		onError: () => {
			addErrorToast({title: t('toast.export.error.title')});
		}
	});
};
