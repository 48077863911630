import {memo, useCallback, useMemo, useState} from 'react';
import {makeObjectsTreeData} from '@src/utils/makeObjectsTreeData';
import {useUpdateEffect} from 'react-use';
import {makeFilterLabel} from '@src/components/EntitiesFilters/utils/makeFilterLabel';
import {FilterButton, TreeSelect} from '@tehzor/ui-components';
import {useEntitiesFiltersCtx} from '@/shared/lib/EntitiesFilters/hooks/useEntitiesFiltersCtx';
import SelectSearch, {
	treeFilter
} from '@tehzor/ui-components/src/components/inputs/select/SelectSearch';
import {useObjectsAsArray} from '@src/core/hooks/queries/objects/hooks';
import {useMainAndSubCompaniesAsArray} from '@src/core/hooks/queries/companies/hooks';
import {useTranslation} from 'react-i18next';
import {TranslatedSelectPopup} from '@src/components/TranslatedSelectPopup';
import classNames from 'classnames';
import styles from './ObjectsFilter.module.less';

export interface IObjectsFilterProps {
	label?: string;
	filterName?: string;
	value?: string[];
	targetObjects?: string[];
	className?: string;
}

export const ObjectsFilter = memo((props: IObjectsFilterProps) => {
	const {label, value, targetObjects, className, filterName = 'objects'} = props;
	const {t} = useTranslation();
	const translatedLabel = label ?? t('entitiesFilters.objectsFilter.label');

	const {dispatch} = useEntitiesFiltersCtx();
	const [selectedObjects, setSelectedObjects] = useState(value);
	const [expandedObjects, setExpandedObjects] = useState<string[] | undefined>([]);
	const [search, setSearch] = useState('');

	const clearSearch = useCallback(() => setSearch(''), []);

	const objects = useObjectsAsArray();
	const {data} = useMainAndSubCompaniesAsArray();
	const companies = data?.mainCompanies;

	const treeData = useMemo(() => {
		if (!objects) return [];

		if (targetObjects) {
			return makeObjectsTreeData(objects.filter(item => targetObjects.includes(item.id)));
		}
		return makeObjectsTreeData(objects, companies);
	}, [targetObjects, objects, companies]);

	const {filteredData, expanded} = useMemo(
		() => treeFilter(treeData, 'content', search),
		[search, treeData]
	);

	useUpdateEffect(() => {
		setExpandedObjects(expanded?.map(item => item.id));
	}, [expanded]);

	const handleApply = useCallback(() => {
		const changes = {[filterName]: selectedObjects};
		dispatch(changes);
		clearSearch();
	}, [selectedObjects, dispatch, clearSearch, filterName]);

	const handleExpand = useCallback((v?: string[]) => {
		setExpandedObjects(v);
	}, []);

	const handleClear = useCallback(() => {
		setSelectedObjects([]);
		clearSearch();
	}, [clearSearch]);

	const handleFullClear = useCallback(() => {
		dispatch({[filterName]: undefined});
		setSelectedObjects([]);
		setExpandedObjects([]);
		clearSearch();
	}, [dispatch, clearSearch, filterName]);

	const handleCancel = useCallback(() => {
		setSelectedObjects(value);
		clearSearch();
	}, [value, clearSearch]);

	const handleSelectAll = useCallback(() => {
		clearSearch();
		if (objects && companies) {
			const allObjects = objects.map(obj => obj.id);
			const allCompanies = companies.map(comp => comp.id);
			setSelectedObjects([...allObjects, ...allCompanies]);
		}
	}, [objects, companies, clearSearch]);

	useUpdateEffect(() => {
		setExpandedObjects([]);
		setSelectedObjects(value);
	}, [value]);

	return (
		<TranslatedSelectPopup
			onCancel={handleCancel}
			onApply={handleApply}
			onClear={handleClear}
			className={className}
			clearButton={!!selectedObjects?.length}
			footer
			count={selectedObjects?.length}
			onSelectAll={handleSelectAll}
			search={
				<SelectSearch
					value={search}
					placeholder={t('selectSearch.placeholder')}
					onChange={setSearch}
					type="popup"
				/>
			}
			trigger={
				<FilterButton
					className={classNames("entities-filters__item", styles.filterToggleButton)}
					label={makeFilterLabel(translatedLabel, value, treeData)}
					active={!!value?.length}
					onClear={handleFullClear}
				/>
			}
		>
			<TreeSelect
				data={filteredData}
				multiple
				value={selectedObjects}
				onChange={setSelectedObjects}
				expandedValue={expandedObjects}
				onExpand={handleExpand}
			/>
		</TranslatedSelectPopup>
	);
});
