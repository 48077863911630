import {memo, useCallback, useState} from 'react';
import {ActionButtons, Button, IconButton, TextField} from '@tehzor/ui-components';
import {IValueItem} from '../../interfaces';
import styles from './RenderItem.module.less';

interface RenderItemProps {
	item: IValueItem;
	onSave: (value: string, index: number) => void;
	onDelete: (index: number) => void;
}

const iconDelete = <i className="tz-delete" />;
export const RenderItem = memo(({item, onSave, onDelete}: RenderItemProps) => {
	const [value, setValue] = useState(item.value);

	const handleSave = () => {
		onSave(value, item.order - 1);
	};

	const handleReset = () => {
		setValue(item.value);
	};

	const handleDelete = useCallback(() => {
		onDelete(item.order - 1);
	}, [onDelete, item.order]);

	return (
		<>
			<TextField
				className={styles.input}
				value={value}
				onChange={setValue}
				elementType="input"
			/>
			{value === item.value ? (
				<IconButton onClick={handleDelete}>{iconDelete}</IconButton>
			) : (
				<ActionButtons>
					<Button
						type="accent-blue"
						label="Сохранить"
						onClick={handleSave}
					/>
					<Button
						type="cancel"
						label="Отменить"
						onClick={handleReset}
					/>
				</ActionButtons>
			)}
		</>
	);
});
