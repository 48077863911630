import useScrollRestoration from '@src/core/hooks/useScrollRestoration';
import {useIsDesktop} from '@tehzor/ui-components/src/utils/mediaQueries';
import {PresaleCheckPageDesktop} from './PresaleCheckPageDesktop';
import {PresaleCheckPageMobile} from './PresaleCheckPageMobile';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import {usePresaleCheck} from '@/entities/PresaleCheck';

export const PresaleCheckPage = () => {
	useScrollRestoration();

	const {objectId, presaleCheckId} = useStrictParams<{
		objectId: string;
		presaleCheckId: string;
	}>();

	const {data: presaleCheck} = usePresaleCheck(presaleCheckId);

	const isDesktop = useIsDesktop();

	if (isDesktop) {
		return (
			<PresaleCheckPageDesktop
				objectId={objectId}
				presaleCheckId={presaleCheckId}
				presaleCheck={presaleCheck}
			/>
		);
	}
	return (
		<PresaleCheckPageMobile
			objectId={objectId}
			presaleCheckId={presaleCheckId}
			presaleCheck={presaleCheck}
		/>
	);
};
