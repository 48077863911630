import {findPermission} from '@tehzor/tools/utils/findPermission';
import {checkRestrictions} from '@tehzor/tools/utils/permissions/restrictions/checkRestrictions';
import {useMemo} from 'react';
import useAppSelector from '../useAppSelector';
import {UserRoleScopes} from '@tehzor/tools/interfaces/IUser';

export interface IWorkAcceptanceResponsiblePermissions {
	canEditResponsibles?: boolean;
	onlyActiveGroup?: boolean;
}

export const useWorkAcceptanceResponsiblePermissions = (
	objectId: string,
	permissionId: string
): IWorkAcceptanceResponsiblePermissions => {
	const user = useAppSelector(s => s.auth.profile);

	return useMemo(() => {
		if (!user || !user.roles) {
			return {};
		}

		let onlyActiveGroup = false;

		const permission = findPermission(
			user.roles,
			permissionId,
			UserRoleScopes.OBJECT,
			objectId
		);

		if (!permission) {
			return {canEditResponsibles: false, onlyActiveGroup};
		}

		const restrictions = permission.restrictions;
		if (!restrictions) {
			return {canEditResponsibles: true, onlyActiveGroup};
		}

		if (restrictions?.onlyActiveGroup !== undefined) {
			onlyActiveGroup = typeof restrictions.onlyActiveGroup === 'boolean';
		}

		const canEditResponsibles = checkRestrictions(restrictions, {userId: user.id});

		return {canEditResponsibles, onlyActiveGroup};
	}, [objectId, permissionId, user]);
};
