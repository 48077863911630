export const convert = <T extends {id: string}, E extends string, S>(
	data: T,
	entityName: E,
	subRows?: S[]
) => ({
	id: `${entityName}_${data.id}`,
	type: entityName,
	data,
	expanded: !!subRows?.length,
	subRows
});
