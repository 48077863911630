import {IGetBuiltinFieldsResponse} from '@/shared/api/fields/getBuiltInFieldsList';
import {EntityTypeId, IBuiltinField} from '@tehzor/tools/interfaces/fields';

export const extractBuiltinFieldsByEntityTypeIdAsArray = (
	data: IGetBuiltinFieldsResponse,
	entityTypeId?: EntityTypeId
): IBuiltinField[] =>
	data.allIds.reduce((acc: IBuiltinField[], id) => {
		const field = data.byId[id];
		if (field.entityTypeId === entityTypeId) {
			acc.push(field);
		}
		return acc;
	}, []);
