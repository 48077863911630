import {Column} from 'react-table';
import {useColumnsWithSettings} from '../../../core/hooks/settings/useColumnsWithSettings/useColumnsWithSettings';
import {IEntitySettings} from '../../../core/hooks/settings/useEntitySettings';
import {TFunction} from 'i18next';
import useAppDispatch from '../../../core/hooks/useAppDispatch';
import useAppSelector from '../../../core/hooks/useAppSelector';
import {extractWarrantyClaimsPageSettings} from '../../../store/modules/settings/pages/warrantyClaims/selectors';
import {useCallback, useMemo} from 'react';
import {ITableSettings} from '../../../interfaces/ITableSettings';
import {changeTableSettings} from '../../../store/modules/settings/pages/warrantyClaims/actions';
import {getDesktopColumns} from '../utils/getDesktopColumns';
import {IPreparedWarrantyClaimEntity} from '../interfaces/IPreparedWarrantyClaimEntity';

interface IUseWarrantyClaimsTableColumnsProps {
	objectId: string;
	settings?: IEntitySettings;
	t: TFunction;
}
export const useWarrantyClaimsTableColumns = ({
	objectId,
	settings,
	t
}: IUseWarrantyClaimsTableColumnsProps): [Array<Column<IPreparedWarrantyClaimEntity>>, number] => {
	const dispatch = useAppDispatch();
	const {tableSettings} = useAppSelector(s => extractWarrantyClaimsPageSettings(s, objectId));

	const customizedColumns = useMemo(() => getDesktopColumns(t, settings), [t, settings]);
	const onChangeTableSettings = useCallback(
		(newTableSettings: ITableSettings) => {
			dispatch(changeTableSettings(objectId, newTableSettings));
		},
		[dispatch, objectId]
	);
	return useColumnsWithSettings({
		customizedColumns,
		entitySettings: settings,
		tableSettings,
		onChangeTableSettings
	});
};
