import {useQueryClient} from '@tanstack/react-query';
import useAppDispatch from '../../useAppDispatch';
import {useEditSpaceStatusMutation} from '@src/api/cache/spaces/mutations';
import {IEditSpaceStatusParams} from '@src/api/cache/spaces/defaults/useSpacesMutationDefaults';
import {spacesQueryKeys} from '@src/api/cache/spaces/keys';
import {IGetSpaceResponse} from '@src/api/backend/space';
import {ISpace} from '@tehzor/tools/interfaces/spaces/ISpace';
import {editSpaceStatusActions} from '@src/store/modules/entities/space/actions';
import {useObject} from '@src/core/hooks/queries/objects/hooks';
import {addWarningToast} from '@tehzor/ui-components';
import {useTranslation} from 'react-i18next';

export interface IEditSpaceStatusJournalData {
	objectName: string;
	locationName?: string;
	newStatus: string;
}

export const useEditSpaceStatus = (objectId: string) => {
	const {t} = useTranslation();
	const queryClient = useQueryClient();
	const dispatch = useAppDispatch();
	const mutation = useEditSpaceStatusMutation();
	const {data: object} = useObject(objectId);

	return async (params: Omit<IEditSpaceStatusParams, 'key'>) => {
		const {objectId, pageObjectId, spaceId, status, online} = params;
		const spaceDetailQueryKey = [...spacesQueryKeys.detail(spaceId), objectId];
		const space = queryClient.getQueryData<IGetSpaceResponse>(spaceDetailQueryKey);
		const key = Date.now().toString(10);
		const journalData = {
			objectName: object ? object.name : '',
			locationName: space?.name,
			newStatus: status
		};

		// Обновляем помещение в кеше, что бы отправить его в редакс
		const cashedSpace = queryClient.setQueryData(spaceDetailQueryKey, () => ({
			...space,
			status
		}));

		mutation.mutate({...params, key, journalData});

		if (!online) {
			dispatch(editSpaceStatusActions.success(cashedSpace as ISpace, pageObjectId));
			addWarningToast({title: t('toast.editSpaceStatusLocal.warning.title')});
		}
	};
};
