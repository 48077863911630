import {memo, useMemo} from 'react';
import {ErrorBoundary, ErrorMessage, LinkButton} from '@tehzor/ui-components';
import {useFilterPageTransition} from '../utils/pagesRouting';
import {makeObjectsTreeData} from '@src/utils/makeObjectsTreeData';
import {makeFilterLabel} from '@src/components/EntitiesFilters/utils/makeFilterLabel';
import {useObjectsAsArrayByIds, useTargetObjects} from '@src/core/hooks/queries/objects/hooks';
import {useCompaniesAsArray} from '@src/core/hooks/queries/companies/hooks';
import {useTranslation} from 'react-i18next';

interface INestedObjectsFilterMobileProps {
	entity: string;
	value?: string[];
	pageObjectId?: string;
	filterName?: string;
}

export const NestedObjectsFilterMobile = memo(
	({entity, value, pageObjectId, filterName = 'objects'}: INestedObjectsFilterMobileProps) => {
		const {t} = useTranslation();

		const {data: nestedObjects} = useTargetObjects(pageObjectId, true);
		const objects = useObjectsAsArrayByIds(nestedObjects);
		const {data: companies} = useCompaniesAsArray();

		const treeData = useMemo(() => {
			if (!objects) return [];

			return makeObjectsTreeData(objects, companies);
		}, [objects, companies]);

		const handleExpand = useFilterPageTransition(pageObjectId, entity, filterName);

		const label = makeFilterLabel(t('problemsPage.objectsFilter.label'), value, treeData, true);

		return (
			<ErrorBoundary
				label="ObjectsFilterMobile"
				component={
					<div className="mobile-entities-filters__objects-filter">
						<ErrorMessage />
					</div>
				}
			>
				<div className="mobile-entities-filters__objects-filter">
					<div className="mobile-entities-filters__objects-filter-header">
						<div className="mobile-entities-filters__objects-filter-title">
							{t('problemsPage.objectsFilter.label')}
						</div>
						<LinkButton
							label={t('problemsFiltersPage.allBtn.label')}
							onClick={handleExpand}
						/>
					</div>
					<div className="mobile-entities-filters__objects-filter-label">{label}</div>
				</div>
			</ErrorBoundary>
		);
	}
);
