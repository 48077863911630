import {useAppHeader} from '@src/components/AppHeader/hooks/useAppHeader';
import {useDocumentsPermissions} from '@src/core/hooks/permissions/useDocumentsPermissions';
import {useDocumentsPaginate} from '@src/core/hooks/queries/documents/hooks';
import {addErrorToast, LoadingPanel} from '@tehzor/ui-components';
import {memo, useEffect} from 'react';
import './DocumentsPage.less';
import {DocumentsFilters} from './components/DocumentsFilters';
import {DocumentsPageHeader} from './components/DocumentsPageHeader';
import {PageBreadcrumbs} from './components/PageBreadcrumbs';
import {Table} from './components/table/Table';
import {useTranslation} from 'react-i18next';

export const DocumentsPage = memo(() => {
	useAppHeader({title: 'Документы'});
	const {t} = useTranslation();

	const {data, isFetching} = useDocumentsPaginate();

	const {canView} = useDocumentsPermissions();

	useEffect(() => {
		if (!canView) {
			addErrorToast({title: t('toast.canView.error.title')});
		}
	}, [canView]);

	if (!canView) {
		return null;
	}

	return (
		<div className="page-cont documents-page">
			<PageBreadcrumbs />
			<div className="documents-page__actions">
				<DocumentsFilters />
				<DocumentsPageHeader total={data?.total} />
			</div>
			<LoadingPanel
				className="documents-page__loading-panel"
				active={isFetching}
			>
				<Table
					offset={data?.offset}
					total={data?.total}
				/>
			</LoadingPanel>
		</div>
	);
});
