import getEmailsArray from '@src/utils/getEmailsArray';
import {httpRequests} from '@tehzor/tools/api/httpRequests';
import {IExportResponse} from '@tehzor/tools/interfaces/IExportResponse';
import {convertSortParam} from '@tehzor/tools/utils/convertSortParam';
import getTimezone from '@tehzor/tools/utils/getTimezone';

/**
 * Отправляет запрос на экспорт нарушений
 */
export const makeProblemsExportRequest = async (
	templateId: string,
	objectId: string,
	createDocument: boolean | undefined,
	email: string | undefined,
	filters: Record<string, unknown> | undefined,
	sort: Record<string, boolean> | undefined,
	selected: string[] | undefined
): Promise<IExportResponse> => {
	const params = {
		templateId,
		objectId: objectId === 'all' ? undefined : objectId,
		createDocument,
		timezone: getTimezone(),
		email: email ? getEmailsArray(email) : undefined,
		filters: {
			...filters,
			selected
		},
		sort: convertSortParam(sort)
	};

	const response = await httpRequests.exportWithToken.post<IExportResponse>(
		'problems/export-problems',
		params
	);

	return response.data;
};
