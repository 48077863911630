import {makeCheckListCopyRequest} from '@src/api/backend/checkList';
import {checkItemsQueryKeys} from '@src/api/cache/checkItems/keys';
import {checkListsQueryKeys} from '@src/api/cache/checkLists/keys';
import {queryClient} from '@src/api/QueryClient';
import {useMutation} from '@tanstack/react-query';
import {ICheckList} from '@tehzor/tools/interfaces/checkLists/ICheckList';
import IError from '@tehzor/tools/interfaces/IError';
import {addErrorToast} from '@tehzor/ui-components';
import {useTranslation} from 'react-i18next';

export const useCopyCheckList = () => {
	const {t} = useTranslation();

	return useMutation<ICheckList, IError, string>({
		mutationKey: checkListsQueryKeys.copy(),
		mutationFn: (id: string) => makeCheckListCopyRequest(id),
		onSuccess: () => {
			void queryClient.invalidateQueries({queryKey: checkListsQueryKeys.list()});
			void queryClient.invalidateQueries({queryKey: checkItemsQueryKeys.list()});
		},
		onError: () => addErrorToast({title: t('toast.copyCheckList.error.title')})
	});
};
