import {useCallback} from 'react';
import MobileEntitiesFilters from '@src/components/MobileEntitiesFilters';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {
	changeFilters,
	clearFilters,
	changeOffset
} from '@src/store/modules/settings/pages/workAcceptances/actions';
import {IWorkAcceptancesFiltersState} from '@src/store/modules/settings/pages/workAcceptances/reducers/byPage';
import {extractWorkAcceptancesPageSettings} from '@src/store/modules/settings/pages/workAcceptances/selectors';
import {Outlet, useParams} from 'react-router-dom';
import {useTargetObjects} from '@src/core/hooks/queries/objects/hooks';

export const WorkAcceptancesFiltersRoutingPage = () => {
	const {objectId} = useParams<{objectId: string}>();
	const {filters} = useAppSelector(s => extractWorkAcceptancesPageSettings(s, objectId));
	const dispatch = useAppDispatch();
	const {data: descendants} = useTargetObjects(objectId, false);

	const applyFilters = useCallback(
		(value: IWorkAcceptancesFiltersState) => {
			dispatch(changeFilters(objectId, value, !!descendants?.length));
			dispatch(changeOffset(objectId || 'all', 0));
		},
		[objectId, dispatch]
	);
	const onClear = useCallback(() => {
		dispatch(clearFilters(objectId || 'all', !!descendants?.length));
	}, [clearFilters, dispatch, objectId, descendants]);

	return (
		<MobileEntitiesFilters
			objectId={objectId}
			filters={filters}
			onApply={applyFilters}
			onClear={onClear}
			fullClear={!!descendants?.length}
		>
			<Outlet />
		</MobileEntitiesFilters>
	);
};
