import useAppDispatch from '@src/core/hooks/useAppDispatch';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {deleteDevice} from '@src/store/modules/entities/devices/actions/delete';
import {Button, IconButton, ModalDialog} from '@tehzor/ui-components';
import './DevicesHistoryDialog.less';
import {format} from 'date-fns';
import {dateTimeCommaSeparatedFormat} from '@tehzor/tools/utils/dateFormats';
import {multipleLogout} from '@src/store/modules/auth/actions/multipleLogout';
import {extractFilteredDevicesAsArray} from '@src/store/modules/entities/devices/selectors';
import {logout} from '@src/store/modules/auth/actions';
import {useGetDeviceId} from '@src/core/hooks/useGetDeviceId';
import {useTranslation} from 'react-i18next';
import {getTranslatedPlatform} from '@src/utils/translation/getTranslatedPlattform';
import {useCallback} from 'react';
import {useTranslatedConfirmDialog} from '@src/core/hooks/translations/useTranslatedConfirmDialog';

interface IDevicesHistoryDialogProps {
	isOpen: boolean;
	onClose: () => void;
}

const deleteIcon = <i className="tz-delete" />;
const checkIcon = <i className="tz-notifications-status" />;

const DevicesHistoryDialog = ({isOpen, onClose}: IDevicesHistoryDialogProps) => {
	const {t} = useTranslation();
	const devices = useAppSelector(extractFilteredDevicesAsArray);
	const currentDeviceId = useGetDeviceId();

	const dispatch = useAppDispatch();

	const [logoutDialog, getLogoutConfirmation] = useTranslatedConfirmDialog({
		title: t('profilePage.devicesHistoryDialog.useConfirmDialog.title'),
		message: t('profilePage.devicesHistoryDialog.useConfirmDialog.message')
	});

	const [multipleLogoutDialog, getMultipleLogoutConfirmation] = useTranslatedConfirmDialog({
		title: t('profilePage.devicesHistoryDialog.useConfirmDialog.title'),
		message: t('profilePage.devicesHistoryDialog.useConfirmDialog.multiple.message')
	});

	const handleDelete = useCallback(
		async (id: string) => {
			if (await getLogoutConfirmation()) {
				await dispatch(deleteDevice(id));

				if (currentDeviceId === id) {
					await dispatch(logout());
				}
			}
		},
		[getLogoutConfirmation, dispatch, currentDeviceId]
	);

	const handleMultipleLogout = useCallback(async () => {
		if (await getMultipleLogoutConfirmation()) {
			await dispatch(multipleLogout());
		}
	}, [getMultipleLogoutConfirmation, dispatch]);

	return (
		<ModalDialog
			className="devices-history-dialog"
			open={isOpen}
			title={t('profilePage.devicesHistoryDialog.dialog.title')}
			fullScreenOnTablet
			onRequestClose={onClose}
			footer={
				<Button
					type="accent-red"
					label={t('profilePage.devicesHistoryDialog.dialog.button.accept.label')}
					onClick={handleMultipleLogout}
				/>
			}
		>
			{devices.map(({id, platform, browser, os, lastUsedAt, createdAt}) => (
				<div
					className="devices-history-dialog__plate-card"
					key={id}
				>
					<div className="devices-history-dialog__plate-card__content">
						<div className="devices-history-dialog__plate-card__content-item">
							<span>{t('profilePage.devicesHistoryDialog.platform')} </span>
							{getTranslatedPlatform(platform, t)}
						</div>
						<div className="devices-history-dialog__plate-card__content-item">
							<span>{t('profilePage.devicesHistoryDialog.operatingSystem')}</span>{' '}
							{os}
						</div>
						<div className="devices-history-dialog__plate-card__content-item">
							<span>{t('profilePage.devicesHistoryDialog.browser')}</span> {browser}
						</div>
						<div className="devices-history-dialog__plate-card__content-item">
							<span>{t('profilePage.devicesHistoryDialog.createdAt')}</span>{' '}
							{format(new Date(createdAt || 0), dateTimeCommaSeparatedFormat)}
						</div>
						<div className="devices-history-dialog__plate-card__content-item">
							<span>{t('profilePage.devicesHistoryDialog.lastUsedAt')}</span>{' '}
							{format(new Date(lastUsedAt || 0), dateTimeCommaSeparatedFormat)}
						</div>
						{id === currentDeviceId ? (
							<div className="devices-history-dialog__plate-card__content-item">
								{checkIcon}
								<span>{t('profilePage.devicesHistoryDialog.currentSession')}</span>
							</div>
						) : null}
					</div>
					<IconButton
						className="devices-history-dialog__plate-card-btn"
						type="inline-red-accent"
						onClick={() => handleDelete(id)}
					>
						{deleteIcon}
					</IconButton>
				</div>
			))}
			{logoutDialog}
			{multipleLogoutDialog}
		</ModalDialog>
	);
};

export default DevicesHistoryDialog;
