import {createContext, ReactNode, useCallback} from 'react';
import {ITableContextMenuAction} from '@tehzor/tools/interfaces/table/ITableContextMenuAction';
import {useDeleteTask} from '@src/core/hooks/mutations/task/useDeleteTask';
import {IEnrichedTask} from '@tehzor/tools/interfaces/tasks/IEnrichedTask';
import {useTranslatedConfirmDialog} from '@src/core/hooks/translations/useTranslatedConfirmDialog';
import {useTranslation} from 'react-i18next';

export const DispatchActionCtx = createContext<
	(action: ITableContextMenuAction<IEnrichedTask>) => void
>(() => ({}));

interface IMenuActionCtxProviderProps {
	children: ReactNode;
}
export const MenuActionCtxProvider = ({children}: IMenuActionCtxProviderProps) => {
	const {t} = useTranslation();
	const {mutateAsync: deleteTask} = useDeleteTask();
	const [deleteDialog, getDeleteConfirmation] = useTranslatedConfirmDialog({
		title: t('useConfirmDialog.task.deleteMessage'),
		message: t('useConfirmDialog.data.deleteMessage')
	});

	const handleContextMenuAction = useCallback(
		async (action: ITableContextMenuAction<IEnrichedTask>) => {
			if (action.type === 'delete' && (await getDeleteConfirmation())) {
				const deleteTaskParams = {
					taskId: action.payload.id,
					objectId: action.payload.objectId,
					workAcceptanceId: action.payload?.links?.workAcceptanceId
				};
				await deleteTask(deleteTaskParams);
			}
		},
		[deleteTask, getDeleteConfirmation]
	);

	return (
		<DispatchActionCtx.Provider value={handleContextMenuAction}>
			{children}
			{deleteDialog}
		</DispatchActionCtx.Provider>
	);
};
