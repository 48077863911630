import {useMutation} from '@tanstack/react-query';
import {queryClient} from '@src/api/QueryClient';
import {fieldsQueryKeys} from '../keys/index';
import {editCustomFieldRequest} from '@/shared/api/fields/editCustomField';
import {IEditCustomField} from '@tehzor/tools/interfaces/fields/IEditCustomFieldt';
import {addErrorToast} from '@tehzor/ui-components';
import {useTranslation} from 'react-i18next';

export const useEditCustomFiled = () => {
	const {t} = useTranslation();

	return useMutation({
		mutationKey: fieldsQueryKeys.edit(),
		mutationFn: (data: IEditCustomField) => editCustomFieldRequest(data),
		onError: () => addErrorToast({title: t('toast.editCustomFiled.error.title')}),
		onSuccess: () => {
			void queryClient.invalidateQueries({queryKey: fieldsQueryKeys.all()});
		}
	});
};
