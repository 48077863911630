import {
	IWorkAcceptancesFiltersState,
	IWorkAcceptancesSortState
} from '@src/store/modules/settings/pages/workAcceptances/reducers/byPage';

export type IWorkAcceptancePaginateQueryKey = [] & {
	0: string; // work-acceptances
	1: string; // paginate-list
	2: string; // objectId
	3: IWorkAcceptancesFiltersState; // filters
	4: IWorkAcceptancesSortState; // sort
	5: number; // offset
	6: number; // limit
};

interface IWorkAcceptancesFilter extends Record<string, unknown> {
	spaceIds: string[];
}

export type IWorkAcceptanceListQueryKey = [] & {
	0: string; // work-acceptances
	1: string; // list
	2: IWorkAcceptancesFilter; // filters
};
export type IWorkAcceptanceQueryKey = [] & {
	0: string; // work-acceptance
	1: string; // detail
	2: string; // workAcceptanceId
	3: string; // objectId
};

export const workAcceptancesQueryKeys = {
	all: () => ['work-acceptances'],
	one: () => ['work-acceptance'],
	lists: () => [...workAcceptancesQueryKeys.all(), 'list'],
	paginate: () => [...workAcceptancesQueryKeys.all(), 'paginate-list'],
	paginateList: (
		objectId = 'all',
		filters?: Record<string, unknown>,
		sort?: Record<string, boolean>,
		offset?: number,
		limit?: number
	) => [...workAcceptancesQueryKeys.paginate(), objectId, filters, sort, offset, limit],
	list: () => [...workAcceptancesQueryKeys.lists()],
	listWithObjectId: (objectId = 'all') => [...workAcceptancesQueryKeys.paginateList(), objectId],
	details: () => [...workAcceptancesQueryKeys.one(), 'detail'],
	detail: (workAcceptanceId?: string) => [
		...workAcceptancesQueryKeys.details(),
		workAcceptanceId
	],
	export: () => [...workAcceptancesQueryKeys.all(), 'export'],
	exportOne: () => [...workAcceptancesQueryKeys.one(), 'export']
};
