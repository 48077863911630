export const findExtraElement = (oldArray: string[], newArray: string[]) => {
	const oldSet = new Set(oldArray);
	const newSet = new Set(newArray);

	for (const id of oldSet) {
		if (!newSet.has(id)) {
			return {
				id,
				isAdded: false,
				isRemoved: true
			};
		}
	}

	for (const id of newSet) {
		if (!oldSet.has(id)) {
			return {
				id,
				isAdded: true,
				isRemoved: false
			};
		}
	}

	return {id: '', isAdded: false, isRemoved: false};
};
