import {useCallback, useMemo, useState} from 'react';
import {useLocation} from 'react-router-dom';
import {useAppHeader} from '@src/components/AppHeader/hooks/useAppHeader';
import {LinkButton, TreeSelect} from '@tehzor/ui-components';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {isLocalStateEqual} from '@src/components/MobileEntitiesFilters/utils/isLocalStateEqual';
import {useRouting} from '@src/components/MobileEntitiesFilters/utils/pagesRouting';
import {useEntitiesFiltersCtx} from '@src/components/EntitiesFilters/utils/entitiesFiltersCtx';
import {treeFilter} from '@tehzor/ui-components/src/components/inputs/select/SelectSearch';
import {useUpdateEffect} from 'react-use';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import {useTranslation} from 'react-i18next';
import {TranslatedFilterPage} from '@src/components/TranslatedFilterPage';
import {TranslatedSelectSearch} from '@src/components/TranslatedSelectSearch';
import {useTreeDataSpaceTypes} from './hooks/useTreeDataSpaceTypes';

export const SpaceTypeDecorationsFilterPage = () => {
	const {t} = useTranslation();
	const {goBack} = useChangePath();
	const {objectId, entity} = useStrictParams<{objectId: string; entity: string}>();

	const {state, change} = useEntitiesFiltersCtx<{typeDecoration?: string[]}>();
	const [selected, setSelected] = useState(state.typeDecoration);
	const [expanded, setExpanded] = useState<string[] | undefined>([]);
	const [search, setSearch] = useState('');
	const {canGoBack} = useLocation().state as {canGoBack?: boolean};

	const treeData = useTreeDataSpaceTypes(objectId);

	const {filteredData, expanded: expandedValue} = useMemo(() => {
		if (!treeData) return {filteredData: [], expanded: []};
		return treeFilter(treeData, 'content', search);
	}, [search, treeData]);

	useUpdateEffect(() => {
		setExpanded(expandedValue?.map(item => item.id));
	}, [expandedValue]);

	const handleApply = useCallback(() => {
		change({typeDecoration: selected});
		goBack();
	}, [change, selected, goBack]);

	const handleClear = useCallback(() => {
		setSelected(undefined);
	}, []);

	useAppHeader(
		{
			title: t(
				'spacesPage.spacesFilters.entitiesFilters.statusesFilter.typeDecoration.label'
			),
			showBackBtn: true,
			mobileRightButtons: selected?.length ? (
				<LinkButton
					label={t('entitiesFilters.cancelButton.label')}
					onClick={handleClear}
				/>
			) : null
		},
		[selected]
	);

	useRouting(!canGoBack, objectId, entity);

	return (
		<TranslatedFilterPage
			applyDisabled={isLocalStateEqual(state.typeDecoration, selected)}
			onApplyClick={handleApply}
			search={
				<TranslatedSelectSearch
					value={search}
					onChange={setSearch}
				/>
			}
		>
			<TreeSelect
				data={filteredData}
				multiple
				value={selected}
				onChange={setSelected}
				expandedValue={expanded}
				onExpand={setExpanded}
			/>
		</TranslatedFilterPage>
	);
};
