import {IGetListInspectionsResponse} from '@src/api/backend/inspections';
import {StructureEntitiesVisibility} from '@src/store/modules/settings/pages/structure/reducers/entitiesVisibility';
import {extractStructureInspections} from './extractStructureInspections';
import {IStructureEntity} from '@src/interfaces/IStructureEntity';
import {convert} from '../../../../utils/convertEntity';

/**
 * Извлекает осмотры с учётом фильтра
 */

export const extractStructureInspectionsEntity = (
	data: IGetListInspectionsResponse,
	visibility: StructureEntitiesVisibility[]
) => {
	const result: IStructureEntity[] = [];
	const inspections = extractStructureInspections(data);
	const inspectionsVisible = visibility.includes('inspections');
	if (inspectionsVisible) {
		for (const inspection of inspections) {
			if (inspection.links) {
				result.push(convert(inspection, 'inspection'));
			}
		}
	}

	return result;
};
