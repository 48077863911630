import {queryClient} from '@src/api/QueryClient';
import {
	IGetMeterConsumptionsResponse,
	makeMeterConsumptionAddRequest
} from '@src/api/backend/meterConsumptions';
import {meterConsumptionsQueryKeys} from '@src/api/cache/meterConsumptions/keys';
import {useMutation} from '@tanstack/react-query';
import IError from '@tehzor/tools/interfaces/IError';
import {IMeterConsumption} from '@tehzor/tools/interfaces/meterConsumptions/IMeterConsumption';
import {ISavingMeterConsumption} from '@tehzor/tools/interfaces/meterConsumptions/ISavingMeterConsumption';
import {useCallback} from 'react';
import {addErrorToast, addSuccessToast} from '@tehzor/ui-components';
import {useTranslation} from 'react-i18next';

export const useAddMeterConsumption = () => {
	const {t} = useTranslation();

	const mutation = useMutation<IMeterConsumption, IError, ISavingMeterConsumption>({
		mutationFn: (params: ISavingMeterConsumption) => makeMeterConsumptionAddRequest(params),

		onSuccess: data => {
			queryClient.setQueryData<IGetMeterConsumptionsResponse>(
				meterConsumptionsQueryKeys.list(data.meterId),
				oldData => {
					if (oldData) {
						return {
							byId: {...oldData.byId, [data.id]: data},
							allIds: [...oldData.allIds, data.id]
						};
					}
					return {
						byId: {[data.id]: data},
						allIds: [data.id]
					};
				}
			);
			addSuccessToast({title: t('toast.addMeterConsumption.success.title')});
		},
		onError: () => {
			addErrorToast({title: t('toast.addMeterConsumption.error.title')});
		}
	});
	/**
	 * Возвращает функцию для создания показания прибора учёта
	 *
	 * @param item данные нового показания прибора учёта
	 */
	return useCallback(
		(item: ISavingMeterConsumption) => {
			mutation.mutate(item);
		},
		[mutation]
	);
};
