import {useCallback, useState} from 'react';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {formDocumentLink} from '@tehzor/tools/utils/links';
import {downloadExportedFile} from '@/shared/lib/Toasts/utils/downloadExportedFile';
import {BaseToast, CircleLoader, LinkButton} from '@tehzor/ui-components';
import {IDocument} from '@tehzor/tools/interfaces/documents/IDocument';
import {useTranslation} from 'react-i18next';
import styles from './ExportToast.module.less';

interface IExportToastProps {
	title?: string;
	description?: string;
	documentRecord?: IDocument;
	url: string;
	fileName: string;
}

export const ExportToast = ({
	title,
	url,
	fileName,
	description,
	documentRecord
}: IExportToastProps) => {
	const {t} = useTranslation();
	const {pushPath} = useChangePath();
	const [downloadProgress, setDownloadProgress] = useState(0);
	const [isDownloading, setIsDownloading] = useState(false);

	const handleDocument = useCallback(() => {
		if (documentRecord) {
			pushPath(formDocumentLink(documentRecord.id));
		}
	}, [pushPath, documentRecord]);

	const handleDownload = useCallback(async () => {
		await downloadExportedFile(url, fileName, setIsDownloading, setDownloadProgress);
	}, [fileName, url]);

	return (
		<BaseToast
			icon={
				downloadProgress === 100 ? (
					<i className="tz-mark-24" />
				) : (
					<i className="tz-toast-success-32" />
				)
			}
			title={downloadProgress === 100 ? t('exportToast.title.downloadCompleted') : title}
			description={description}
			links={
				isDownloading ? (
					<CircleLoader percent={downloadProgress} />
				) : (
					downloadProgress !== 100 && (
						<>
							{documentRecord && (
								<LinkButton
									className={styles.link}
									onClick={handleDocument}
									label={t('exportToast.link.toDocument')}
								/>
							)}

							<LinkButton
								className={styles.link}
								onClick={handleDownload}
								label={t('exportToast.link.download')}
							/>
						</>
					)
				)
			}
		/>
	);
};
