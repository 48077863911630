import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {useCheckLists} from '@src/core/hooks/queries/checkLists/hooks';
import {useWorkAcceptancesList} from '@src/core/hooks/queries/workAcceptances';
import {useStructuresProblemsStats} from '@/entities/Structures/model/cache/queries/ProblemsData/hooks/useStructuresProblemsStats';
import {useEffect, useMemo} from 'react';
import {getCheckRecords} from '@src/store/modules/pages/checkLists/actions';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractCheckRecordsAsArray} from '@src/store/modules/pages/checkLists/selectors/records';
import {extractWorkAcceptancesByStructuresAsRecord} from '@src/core/hooks/queries/workAcceptances/selectors';
import {convertStructures} from '@/features/Structures/useEnrichedStructures/utils/convertStructures';
import {useProblemStatuses} from '@src/core/hooks/queries/problemStatuses/hooks';
import {useExtractWorkAcceptanceStatuses} from '@src/core/hooks/queries/workAcceptanceStatuses/hooks';
import {useExtractCheckRecordStatusesById} from '@src/core/hooks/queries/checkRecordStatuses/hooks';
import {enrichStructures} from '@/features/Structures/useEnrichedStructures/utils/enrichStructures';
import {oneArgIsTrue} from '@/shared/utils/oneArgIsTrue';
import {useStructuresCheckLists} from '@/features/Structures/useEnrichedStructures/hooks/useStructuresCheckLists';
import {useStructuresListData} from '@/entities/Structures';
import {useStructuresWorkAcceptancesStats} from '@/entities/Structures/model/cache/queries/WorkAcceptancesData/hooks/useStructuresWorkAcceptancesStats';

interface IUseEnrichedStructuresProps {
	objectId: string;
	spaceId?: string;
}

export const useEnrichedStructures = ({objectId, spaceId}: IUseEnrichedStructuresProps) => {
	const dispatch = useAppDispatch();
	const {data, isLoading: structuresDataIsLoading} = useStructuresListData(objectId);
	const {data: checkListsData, isLoading: checkListsDataIsLoading} = useCheckLists();

	const {data: structuresWorkAcceptances, isLoading: structuresWorkAcceptancesIsLoading} =
		useWorkAcceptancesList({
			filters: {objects: [objectId], structureIds: data?.allIds},
			options: {
				enabled: !!data
			}
		});

	const {
		data: structuresWorkAcceptancesStats,
		isLoading: structuresWorkAcceptancesStatsIsLoading
	} = useStructuresWorkAcceptancesStats({
		objectId,
		structuresIds: data?.allIds,
		options: {
			enabled: !!data
		}
	});
	const {data: structuresProblemsStats, isLoading: structuresProblemsStatsIsLoading} =
		useStructuresProblemsStats({
			objectId,
			structuresIds: data?.allIds,
			options: {
				enabled: !!data
			}
		});
	useEffect(() => {
		if (structuresWorkAcceptances && checkListsData) {
			void dispatch(
				getCheckRecords(
					checkListsData.allIds,
					[objectId],
					undefined,
					structuresWorkAcceptances.allIds
				)
			);
		}
	}, [objectId, checkListsData, dispatch, structuresWorkAcceptances]);

	const checkListsRecords = useAppSelector(s => extractCheckRecordsAsArray(s));

	const checkLists = useStructuresCheckLists({objectId, spaceId, structures: data?.structures});

	const {data: problemStatuses, isLoading: problemStatusesIsLoading} = useProblemStatuses();
	const {data: workAcceptanceStatuses, isLoading: workAcceptanceStatusesIsLoading} =
		useExtractWorkAcceptanceStatuses();
	const {data: checkRecordStatuses, isLoading: checkRecordStatusesIsLoading} =
		useExtractCheckRecordStatusesById();

	const enrichedStructures = useMemo(() => {
		if (!structuresWorkAcceptances || !data) return undefined;
		const workAcceptancesRecord = extractWorkAcceptancesByStructuresAsRecord(
			structuresWorkAcceptances,
			data.allIds
		);
		const convertStructuresData = convertStructures(
			data.structures,
			structuresProblemsStats,
			workAcceptancesRecord,
			structuresWorkAcceptancesStats
		);
		return enrichStructures(
			convertStructuresData,
			problemStatuses,
			workAcceptanceStatuses,
			checkLists,
			checkListsRecords,
			checkRecordStatuses ?? {}
		);
	}, [
		data,
		structuresProblemsStats,
		structuresWorkAcceptances,
		structuresWorkAcceptancesStats,
		problemStatuses,
		workAcceptanceStatuses,
		checkLists,
		checkListsRecords,
		checkRecordStatuses
	]);

	const allIsLoading = useMemo(
		() =>
			oneArgIsTrue(
				structuresDataIsLoading,
				structuresWorkAcceptancesIsLoading,
				structuresWorkAcceptancesStatsIsLoading,
				checkListsDataIsLoading,
				structuresProblemsStatsIsLoading,
				problemStatusesIsLoading,
				workAcceptanceStatusesIsLoading,
				checkRecordStatusesIsLoading
			),
		[
			structuresDataIsLoading,
			structuresWorkAcceptancesIsLoading,
			structuresWorkAcceptancesStatsIsLoading,
			checkListsDataIsLoading,
			structuresProblemsStatsIsLoading,
			problemStatusesIsLoading,
			workAcceptanceStatusesIsLoading,
			checkRecordStatusesIsLoading
		]
	);

	return {data: enrichedStructures, isLoading: allIsLoading};
};
