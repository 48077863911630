import {httpRequests} from '@tehzor/tools/api/httpRequests';
import getTimezone from '@tehzor/tools/utils/getTimezone';
import {IExportResponse} from '@tehzor/tools/interfaces/IExportResponse';
import {ISpaceEntityExport} from '@src/interfaces/ISpaceEntityExport';
import getEmailsArray from '@src/utils/getEmailsArray';

/**
 * Отправляет запрос на экспорт помещения
 */
export const makeSpaceExportRequest = async (
	params: ISpaceEntityExport
): Promise<IExportResponse> => {
	const extendedParams = {
		...params,
		timezone: getTimezone(),
		email: params.email ? getEmailsArray(params.email) : undefined
	};

	const response = await httpRequests.exportWithToken.post<IExportResponse>(
		`spaces/export/${params.spaceId}`,
		extendedParams
	);
	return response.data;
};
