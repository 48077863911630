import {IOfflineJournalEntity} from '../interfaces/IOfflineJournalEntity';
import {offlineJournalActionType} from '../enums/actionsTypes';
import {transferInspection} from './transferInspection';
import {transferProblem} from './transferProblem';
import {transferCheck} from './transferCheck';
import {transferInternalAcceptance} from './transferInternalAcceptance';
import {transferOwnerAcceptance} from './transferOwnerAcceptance';
import {transferWarrantyClaim} from './transferWarrantyClaim';
import {transferPresaleCheck} from './transferPresaleCheck';

export const transferEntity = (entity: IOfflineJournalEntity['entity']) => {
	switch (entity.entityType) {
		case offlineJournalActionType.PROBLEM_ADD:
		case offlineJournalActionType.PROBLEM_CHECKLIST_ADD:
		case offlineJournalActionType.PROBLEM_SPACE_ADD:
			transferProblem(entity);
			break;
		case offlineJournalActionType.INSPECTION_ADD:
		case offlineJournalActionType.INSPECTION_CHECKLIST_ADD:
		case offlineJournalActionType.INSPECTION_SPACE_ADD:
			transferInspection(entity);
			break;
		case offlineJournalActionType.CHECK_ADD:
			transferCheck(entity);
			break;
		case offlineJournalActionType.PRESALE_CHECK_ADD:
			transferPresaleCheck(entity);
			break;
		case offlineJournalActionType.INTERNAL_ACCEPTANCE_ADD:
			transferInternalAcceptance(entity);
			break;
		case offlineJournalActionType.OWNER_ACCEPTANCE_ADD:
			transferOwnerAcceptance(entity);
			break;
		case offlineJournalActionType.WARRANTY_CLAIM_ADD:
			transferWarrantyClaim(entity);
			break;
	}
};
