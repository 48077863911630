import {Id, toast} from 'react-toastify';
import {ToastsContainersIds} from '../interfaces';

interface IParams {
	id?: Id;
	containerId?: Id;
}

export const closeToast = ({id, containerId = ToastsContainersIds.MAIN_STACKED}: IParams) =>
	toast.dismiss({
		id,
		containerId
	});
