export const fieldsPagesLinks = [
	{
		id: 'fieldsSettingsSets',
		url: '/manage/fields',
		label: 'Наборы настроек'
	},
	{
		id: 'customFields',
		url: '/manage/fields/custom-fields',
		label: 'Пользовательские поля'
	},
	{
		id: 'buildInFields',
		url: '/manage/fields/builtin-fields',
		label: 'Встроенные поля'
	}
];
