import useAppSelector from '@src/core/hooks/useAppSelector';
import {customFieldsActions, extractCustomFieldsPageSettings} from '@/entities/Fields';
import {useIsDesktop, useIsTablet} from '@/shared/hooks/dimensions/useIsDesktop/useIsDesktop';
import {useCallback, useMemo} from 'react';
import {getDesktopColumns} from '../model/utils/getDesktopColumns';
import {getMobileColumns} from '../model/utils/getMobileColumns';
import {EntitiesTable, Pagination, PaginationAndSize, Plate} from '@tehzor/ui-components';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import styles from './CustomFieldsTable.module.less';
import {TranslatedPaginationPageSize} from '@src/components/TranslatedPaginationPageSize';
import {useEditingCustomFieldDialog, usePaginateCustomFields} from '@/features/Fields';
import {pageSizes} from '@/shared/constants/pageSizes';
import {SelectionRow} from '@/widgets/CustomFieldsTable/ui/SelectionRow/SelectionRow';
import {ICustomField} from '@tehzor/tools/interfaces/fields';
import {useChangePath} from '@src/core/hooks/useChangePath';

export const CustomFieldsTable = () => {
	const {pushPath} = useChangePath();
	const dispatch = useAppDispatch();
	const isTablet = useIsTablet();
	const isDesktop = useIsDesktop();
	const {selectedRows, pageSize, offset} = useAppSelector(s =>
		extractCustomFieldsPageSettings(s)
	);
	const {changeSelectedRows, changeOffset, changePageSize} = customFieldsActions;
	const {data: paginateCustomFields, pagesCount, currentPage} = usePaginateCustomFields();
	const columns = useMemo(
		() => (!isTablet ? getMobileColumns() : getDesktopColumns()),
		[isTablet]
	);

	const [dialog, open] = useEditingCustomFieldDialog({});

	const handleRowClick = (field: ICustomField) => {
		if (!isDesktop) {
			pushPath(`/manage/fields/custom-field/edit/${field.id}`);
			return;
		}
		open(field);
	};

	const handleSelectedRowsChange = useCallback(
		(value: string[]) => {
			dispatch(changeSelectedRows(value));
		},
		[changeSelectedRows, dispatch]
	);

	const handlePageChange = useCallback(
		({selected}: {selected: number}) => {
			const newOffset = selected * pageSize;
			if (offset !== newOffset) {
				dispatch(changeOffset(newOffset));
			}
		},
		[pageSize, offset, dispatch, changeOffset]
	);

	const handlePageSizeChange = useCallback(
		(value: number) => {
			const newOffset = Math.floor(offset / value);
			dispatch(changePageSize(value));
			dispatch(changeOffset(newOffset));
		},
		[offset, dispatch, changePageSize, changeOffset]
	);

	return (
		<>
			{dialog}
			<Plate withoutPadding>
				<EntitiesTable
					onRowClick={handleRowClick}
					columns={columns}
					data={paginateCustomFields}
					selectable
					selectedRows={selectedRows}
					headVisible={isTablet}
					onSelectedRowsChange={handleSelectedRowsChange}
					renderSelectionRow={props => <SelectionRow {...props} />}
					rowProps={() => ({
						className: styles.deleteCellHover
					})}
					rowWithHover
				/>
			</Plate>
			<PaginationAndSize
				pagination={
					<Pagination
						className={styles.containerPagination}
						pageCount={pagesCount}
						forcePage={currentPage}
						pageRangeDisplayed={3}
						marginPagesDisplayed={1}
						onPageChange={handlePageChange}
					/>
				}
				pageSize={
					<TranslatedPaginationPageSize
						className={styles.containerPageSize}
						pageSize={pageSize}
						pageSizeOptions={pageSizes}
						onPageSizeChange={handlePageSizeChange}
					/>
				}
			/>
		</>
	);
};
