import {useAppHeader} from '@src/components/AppHeader/hooks/useAppHeader';
import {PageCounter} from '@/shared/ui/PageCounter/PageCounter';
import {DeleteCustomFieldsButton, usePaginateCustomFields} from '@/features/Fields';
import {CustomFieldsTable} from '@/widgets/CustomFieldsTable';
import styles from './CustomFieldsPage.module.less';
import {fieldsPagesLinks} from '@/shared/constants/fieldsPages/fieldsPagesLinks';
import {useIsDesktop} from '@/shared/hooks/dimensions/useIsDesktop/useIsDesktop';
import {ClearSelectionRowsButton} from '@/pages/CustomFiledsPage/components/ClearSelectionRowsButton/ClearSelectionRowsButton';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractCustomFieldsPageSettings} from '@/entities/Fields';
import {FloatingActionButton} from '@tehzor/ui-components';
import {useChangePath} from '@src/core/hooks/useChangePath';

const iconPlus = <i className="tz-plus-16" />;

export const CustomFieldsPage = () => {
	const isDesktop = useIsDesktop();
	const {total} = usePaginateCustomFields();
	const {selectedRows} = useAppSelector(s => extractCustomFieldsPageSettings(s));

	const {pushPath} = useChangePath();
	const toAddCustomField = () => {
		pushPath('/manage/fields/custom-field/add');
	};

	useAppHeader(
		{
			title: 'Поля',
			sectionsMenu: !isDesktop ? fieldsPagesLinks : undefined,
			mobileLeftButton: selectedRows.length > 0 ? <ClearSelectionRowsButton /> : undefined,
			mobileRightButtons: selectedRows.length > 0 ? <DeleteCustomFieldsButton /> : undefined
		},
		[selectedRows, isDesktop]
	);

	return (
		<>
			<div className={styles.counterContainer}>
				<PageCounter content={`Всего: ${total}`} />
			</div>
			<CustomFieldsTable />
			{!isDesktop && (
				<FloatingActionButton
					icon={iconPlus}
					label="Добавить"
					minifyOnScroll
					scrollContainer="scroll-container"
					onClick={toAddCustomField}
				/>
			)}
		</>
	);
};
