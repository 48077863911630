import {IconMenu, MenuItem} from '@tehzor/ui-components';
import useConfirmDialog from '@tehzor/ui-components/src/hooks/useConfirmDialog';
import {useAsyncFn} from 'react-use';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {ILinkedOwnerAcceptance} from '@tehzor/tools/interfaces/ownerAcceptances/ILinkedOwnerAcceptance';
import {useDeleteOwnerAcceptance} from '@src/core/hooks/mutations/ownerAcceptances/useDeleteOwnerAcceptance';
import {useTranslation} from 'react-i18next';

interface IMobileMenuProps {
	ownerAcceptance: ILinkedOwnerAcceptance;
	canDeleteOwnerAcceptance?: boolean;
}

const MobileMenu = ({ownerAcceptance, canDeleteOwnerAcceptance}: IMobileMenuProps) => {
	const {t} = useTranslation();
	const {goBack} = useChangePath();

	const [deleteDialog, getDeleteConfirmation] = useConfirmDialog(
		t('ownerAcceptancePage.actions.menu.useConfirmDialog.title'),
		`${t('ownerAcceptancePage.actions.menu.useConfirmDialog.message')} №${
			ownerAcceptance.number
		}?`,
		{
			acceptBtnProps: {type: 'accent-red'},
			acceptBtnLabel: t('ownerAcceptancePage.actions.menu.useConfirmDialog.acceptBtnLabel'),
			rejectBtnLabel: t('ownerAcceptancePage.actions.menu.useConfirmDialog.rejectBtnLabel')
		}
	);

	const {mutateAsync: deleteOwnerAcceptance} = useDeleteOwnerAcceptance();

	const [, handleDeleteClick] = useAsyncFn(async () => {
		if (await getDeleteConfirmation()) {
			await deleteOwnerAcceptance({
				objectId: ownerAcceptance.objectId,
				acceptanceId: ownerAcceptance.id
			});
			goBack();
		}
	}, [ownerAcceptance.objectId, ownerAcceptance.id]);

	const items = [];

	if (canDeleteOwnerAcceptance) {
		items.push(
			<MenuItem
				key="delete"
				icon={<i className="tz-delete" />}
				onClick={handleDeleteClick}
			>
				{t('ownerAcceptancePage.actions.menu.menuItem.delete.text')}
			</MenuItem>
		);
	}

	return items.length ? (
		<>
			<IconMenu>{items}</IconMenu>
			{canDeleteOwnerAcceptance && deleteDialog}
		</>
	) : null;
};

export default MobileMenu;
