import {Tab} from '@/shared/ui/EntitiesPage/Tab/Tab';
import {Tabs} from '@tehzor/ui-components';
import styles from './TableTabs.module.less';

interface ITableTabsProps {
	activeTab: number;
	onActiveTabChange: (value: number) => void;
}

export const TableTabs = ({activeTab, onActiveTabChange}: ITableTabsProps) => {
	const links = [<Tab label="Встроенные поля" />, <Tab label="Пользовательские поля" />];

	return (
		<div className={styles.wrapper}>
			<Tabs
				onActiveTabChange={onActiveTabChange}
				links={links}
				activeTab={activeTab}
			/>
		</div>
	);
};
