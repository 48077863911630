import {useCustomFields} from './useCustomFields';
import {extractCustomFieldById} from '../selectors/extractCustomFieldById';
import {useCallback} from 'react';
import {IGetCustomFieldsResponse} from '@/shared/api/fields/getCustomFieldsList';

export const useCustomFieldById = (customFieldId: string) => {
	const selector = useCallback(
		(data: IGetCustomFieldsResponse) => extractCustomFieldById(data, customFieldId),
		[customFieldId]
	);

	return useCustomFields(selector);
};
