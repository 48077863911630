import Text from '@src/components/editableFields/Text';
import {Dispatch} from 'react';
import {IEditableCustomFieldAction, IEditableCustomFieldState} from './context/formState';
import IObjectFieldSetting from '@tehzor/tools/interfaces/objects/IObjectFieldSetting';
import {FieldValueTypeSelect} from '@src/components/editableFields/FieldValueTypeSelect/FieldValueTypeSelect';
import {IconSelect} from '@src/components/editableFields/IconSelect/IconSelect';
import CompanySelect from '@src/components/editableFields/CompanySelect';
import styles from './EditCustomFieldForm.module.less';
import {customIcons} from '@/shared/constants/fieldsPages/customIcons';
import {FieldValuesCreator} from './FieldValuesCreator/FieldValuesCreator';
import {IValueType} from '@tehzor/tools/interfaces/fields';
import {FieldEntityTypeSelect} from '@src/components/editableFields/FieldEntityTypeSelect/FieldEntityTypeSelect';
import {BooleanSelect} from '@src/components/editableFields/BooleanSelect';
import {useTranslatedConfirmDialog} from '@src/core/hooks/translations/useTranslatedConfirmDialog';
import {useTranslation} from 'react-i18next';

export const fieldValueTypesWithValues = ['multiple-select', 'select'];

interface IFieldsProps {
	editingState: IEditableCustomFieldState;
	editingDispatch: Dispatch<IEditableCustomFieldAction>;
	fieldsSettings: Record<string, IObjectFieldSetting>;
	saving?: boolean;
	isEditing?: boolean;
}

export const EditCustomFieldForm = ({
	editingState,
	editingDispatch,
	fieldsSettings,
	saving,
	isEditing = false
}: IFieldsProps) => {
	const {t} = useTranslation();
	const [confirmDialog, getClosingConfirmation] = useTranslatedConfirmDialog({
		title: t('useConfirmDialog.customField.editTitle'),
		message: t('useConfirmDialog.customField.editMessage'),
		props: {
			className: {overlay: styles.confirmDialogOverlay}
		}
	});
	const changeValueTypeHandler = async (valueType: IValueType) => {
		if (editingState.values?.length && !fieldValueTypesWithValues.includes(valueType.id)) {
			if (await getClosingConfirmation()) {
				editingDispatch({type: 'update', field: 'values', value: undefined});
				editingDispatch({type: 'update', field: 'valueType', value: valueType});
				editingDispatch({type: 'update-error', field: 'valueType'});
				return;
			}
			return;
		}
		editingDispatch({type: 'update', field: 'valueType', value: valueType});
		editingDispatch({type: 'update-error', field: 'valueType'});
	};

	return (
		<div className={styles.wrapper}>
			<div className={styles.container}>
				<Text
					field="name"
					label="Название поля *"
					elementType="input"
					value={editingState.name}
					editingDispatch={editingDispatch}
					required={fieldsSettings.name.isRequired}
					disabled={saving}
					hasError={editingState.errors.name}
				/>
				<Text
					field="key"
					label="Ключ поля *"
					elementType="input"
					value={editingState.key}
					editingDispatch={editingDispatch}
					required={fieldsSettings.key.isRequired}
					disabled={saving}
					hasError={editingState.errors.key}
				/>
				<FieldValueTypeSelect
					label="Тип данных *"
					value={editingState.valueType?.id}
					onChange={changeValueTypeHandler}
					disabled={saving || isEditing}
					hasError={editingState.errors.valueType}
				/>
				<IconSelect
					icons={customIcons}
					field="icon"
					label="Иконка *"
					value={editingState.icon}
					editingDispatch={editingDispatch}
					required={fieldsSettings.icon.isRequired}
					disabled={saving || isEditing}
					hasError={editingState.errors.icon}
				/>
				<CompanySelect
					field="companyId"
					label="Компания *"
					value={editingState.companyId}
					editingDispatch={editingDispatch}
					required={fieldsSettings.companyId.isRequired}
					disabled={saving || isEditing}
					hasError={editingState.errors.companyId}
				/>
				<FieldEntityTypeSelect
					field="entityType"
					label="Тип сущности *"
					value={editingState.entityType?.id}
					editingDispatch={editingDispatch}
					required={fieldsSettings.entityType.isRequired}
					disabled={saving || isEditing}
					hasError={editingState.errors.entityType}
				/>
				<Text
					field="defaultValue"
					label="Значение по умолчанию"
					elementType="input"
					value={editingState.defaultValue}
					editingDispatch={editingDispatch}
					required={fieldsSettings.defaultValue.isRequired}
					disabled={saving}
					hasError={editingState.errors.defaultValue}
				/>
				<BooleanSelect
					field="canBeCopied"
					label="Возможность копирования"
					value={editingState.canBeCopied}
					editingDispatch={editingDispatch}
					required={fieldsSettings.canBeCopied.isRequired}
					disabled={saving}
					hasError={editingState.errors.canBeCopied}
				/>
			</div>
			{editingState.valueType &&
				fieldValueTypesWithValues.includes(editingState.valueType.id) && (
					<FieldValuesCreator
						field="values"
						values={editingState.values}
						editingDispatch={editingDispatch}
					/>
				)}
			{confirmDialog}
		</div>
	);
};
