import {updateCachedState} from '@src/utils/updateCachedState';
import {useQueryClient} from '@tanstack/react-query';
import {OfflineDataTransferStatus} from '@tehzor/tools/contracts/dataTransferWebWorker/interfaces/IOfflineDataTransferStatuses';
import {ISavingAuthorizedPerson} from '@tehzor/tools/interfaces/authorizedPersons';
import {authorizedPersonsQueryKeys} from '../keys';
import {
	makeAuthorizedPersonAddRequest,
	makeAuthorizedPersonDeleteRequest,
	makeAuthorizedPersonEditRequest
} from '@src/api/backend/authorizedPerson';
import {IEditAuthorizedPersonResponse} from '@src/api/backend/authorizedPerson/edit';
import {IAddAuthorizedPersonResponse} from '@src/api/backend/authorizedPerson/add';
import {ILegalEntity} from '@tehzor/tools/interfaces/legals-entity/ILegalEntity';
import {addErrorToast} from '@tehzor/ui-components';
import {useTranslation} from 'react-i18next';

export interface IAddAuthorizedPersonParams {
	fields: ISavingAuthorizedPerson;
}

export interface IEditAuthorizedPersonParams {
	authorizedPersonId: string;
	fields: ISavingAuthorizedPerson;
}

export interface IDeleteAuthorizedPersonParams {
	authorizedPersonId: string;
}

export const useAuthorizedPersonsMutationDefaults = () => {
	const queryClient = useQueryClient();
	const key = Date.now().toString(10);
	const {t} = useTranslation();

	queryClient.setMutationDefaults(authorizedPersonsQueryKeys.add(), {
		onMutate: (variables: IAddAuthorizedPersonParams) => ({variables}),
		mutationFn: async (params: IAddAuthorizedPersonParams) => {
			await updateCachedState<ILegalEntity>(
				authorizedPersonsQueryKeys,
				key,
				OfflineDataTransferStatus.TRANSFER
			);
			const {fields} = params;
			return makeAuthorizedPersonAddRequest(fields);
		},
		onSuccess: async (data: IAddAuthorizedPersonResponse) => {
			await updateCachedState<ILegalEntity>(
				authorizedPersonsQueryKeys,
				key,
				OfflineDataTransferStatus.TRANSFER_COMPLETE
			);
			// Удаляем кешированные данные локального юрлица
			queryClient.removeQueries({queryKey: authorizedPersonsQueryKeys.savingData(key)});

			await queryClient.refetchQueries({
				queryKey: authorizedPersonsQueryKeys.localList()
			});
			await queryClient.invalidateQueries({queryKey: authorizedPersonsQueryKeys.list()});

			return data;
		},
		onError: async () => {
			addErrorToast({
				title: t('toast.addAuthorizedPersons.error.title')
			});
			await updateCachedState<ILegalEntity>(
				authorizedPersonsQueryKeys,
				key,
				OfflineDataTransferStatus.TRANSFER_ERROR
			);

			return undefined;
		},
		retry: 1,
		retryDelay: 10 * 1000
	});

	queryClient.setMutationDefaults(authorizedPersonsQueryKeys.edit(), {
		mutationFn: async ({authorizedPersonId, fields}: IEditAuthorizedPersonParams) => {
			await queryClient.cancelQueries({
				queryKey: authorizedPersonsQueryKeys.detail(authorizedPersonId)
			});
			return makeAuthorizedPersonEditRequest(authorizedPersonId, fields);
		},
		onSuccess: async (
			data: IEditAuthorizedPersonResponse,
			{authorizedPersonId}: IEditAuthorizedPersonParams
		) => {
			await queryClient.invalidateQueries({
				queryKey: authorizedPersonsQueryKeys.detail(authorizedPersonId)
			});
			await queryClient.invalidateQueries({queryKey: authorizedPersonsQueryKeys.list()});

			return data;
		},
		onError: async () => {
			addErrorToast({
				title: t('toast.editAuthorizedPersons.error.title')
			});
			await updateCachedState<ILegalEntity>(
				authorizedPersonsQueryKeys,
				key,
				OfflineDataTransferStatus.TRANSFER_ERROR
			);

			return undefined;
		}
	});

	queryClient.setMutationDefaults(authorizedPersonsQueryKeys.delete(), {
		mutationFn: async ({authorizedPersonId}: IDeleteAuthorizedPersonParams) => {
			await makeAuthorizedPersonDeleteRequest(authorizedPersonId);
		},
		onSuccess: () => {
			void queryClient.invalidateQueries({queryKey: authorizedPersonsQueryKeys.list()});
			void queryClient.refetchQueries({queryKey: authorizedPersonsQueryKeys.list()});
		},
		onError: () => {
			addErrorToast({
				title: t('toast.deleteAuthorizedPersons.error.title')
			});
		}
	});
};
