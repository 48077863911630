import {editSpaceTypeDecorationSetRequest} from '@src/api/backend/spaceTypeDecorationSets';
import {spaceTypeDecorationSetsQueryKeys} from '@src/api/cache/spaceTypeDecorationsSets/keys';
import {queryClient} from '@src/api/QueryClient';
import {useMutation} from '@tanstack/react-query';
import {ISpaceTypeDecorationSet} from '@tehzor/tools/interfaces/spaceTypeDecorationSets/ISpaceTypeDecorationSet';
import {addErrorToast} from '@tehzor/ui-components';
import {useTranslation} from 'react-i18next';

export const useEditSpaceTypeDecorationSet = () => {
	const {t} = useTranslation();

	return useMutation({
		mutationFn: (params: ISpaceTypeDecorationSet) => editSpaceTypeDecorationSetRequest(params),
		mutationKey: spaceTypeDecorationSetsQueryKeys.edit(),
		onSuccess: () => {
			void queryClient.invalidateQueries({
				queryKey: spaceTypeDecorationSetsQueryKeys.lists()
			});
			void queryClient.invalidateQueries({
				queryKey: spaceTypeDecorationSetsQueryKeys.one()
			});
		},
		onError: () => addErrorToast({title: t('toast.editSpaceTypeDecorationSet.error.title')})
	});
};
