import {useFieldSettingsList} from './useFieldSettingsList';
import {extractFieldSettingsListBySetIds} from '../selectors/extractFieldSettingsListBySetIds';
import {useCallback} from 'react';
import {IGetListFieldSettingsResponse} from '@/shared/api/fieldSettings/getFieldSettingsList';

export const useFieldSettingsBySetIds = (setIds?: string[]) => {
	const selector = useCallback(
		(data: IGetListFieldSettingsResponse) => extractFieldSettingsListBySetIds(data, setIds),
		[setIds]
	);

	return useFieldSettingsList(selector);
};
