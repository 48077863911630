import {useQuery, UseQueryOptions} from '@tanstack/react-query';
import IError from '@tehzor/tools/interfaces/IError';
import {extractLatestWarrantyClaimId, extractLatestWarrantyClaimsAsArray} from './selectors';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractUserId} from '@src/store/modules/auth/profile/selectors';
import {useCallback} from 'react';
import {IGetWarrantyClaimsResponse} from '@src/api/backend/warrantyClaims';
import {IListWarrantyClaim} from '@tehzor/tools/interfaces/warrantyClaims/IListWarrantyClaim';
import {warrantyClaimsQueryKeys} from '@src/api/cache/warrantyClaims/keys';
import {useLocalWarrantyClaims} from '@src/core/hooks/queries/warrantyClaims/hooks';
import {useWarrantyClaimsPermissions} from '@src/core/hooks/permissions/useWarrantyClaimsPermissions';

export const useFetchLatestWarrantyClaims = <T = IGetWarrantyClaimsResponse>(
	select?: (data: IGetWarrantyClaimsResponse) => T,
	options?: UseQueryOptions<IGetWarrantyClaimsResponse, IError, T>,
	objectId?: string
) => {
	const permissions = useWarrantyClaimsPermissions(objectId);
	return useQuery<IGetWarrantyClaimsResponse, IError, T>({
		queryKey: warrantyClaimsQueryKeys.latest(),
		meta: {
			error: 'при загрузке последних обращений'
		},
		select,
		enabled: permissions.canView,
		...options
	});
};

export const useExtractLatestWarrantyClaimsAsArray = (
	objectId: string,
	spaceId?: string,
	options?: UseQueryOptions<IGetWarrantyClaimsResponse, IError, IListWarrantyClaim[]>
) => {
	const userId = useAppSelector(extractUserId);
	const selector = useCallback(
		(data: IGetWarrantyClaimsResponse) =>
			extractLatestWarrantyClaimsAsArray(data, userId, objectId, spaceId),
		[userId, objectId, spaceId]
	);
	return useFetchLatestWarrantyClaims(selector, options);
};

export const useExtractLatestWarrantyClaimId = (
	objectId: string,
	spaceId?: string,
	options?: UseQueryOptions<IGetWarrantyClaimsResponse, IError, string>
) => {
	const userId = useAppSelector(extractUserId);
	const {data: localWarrantyClaimsAsArray} = useLocalWarrantyClaims(objectId);
	const selector = useCallback(
		(data: IGetWarrantyClaimsResponse) =>
			extractLatestWarrantyClaimId(
				data,
				userId,
				objectId,
				spaceId,
				localWarrantyClaimsAsArray
			),
		[userId, objectId, spaceId]
	);
	return useFetchLatestWarrantyClaims(selector, options);
};
