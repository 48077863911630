import {createReducer} from '@reduxjs/toolkit';
import * as types from '../constants';
import {IChangeFiltersPayload} from '@src/store/interfaces/IChangeFiltersPayload';
import {IClearFiltersPayload} from '@src/store/interfaces/IClearFiltersPayload';
import {IChangeSortPayload} from '@src/store/interfaces/IChangeSortPayload';
import {IChangePageSizePayload} from '@src/store/interfaces/IChangePageSizePayload';
import {IChangeFloorSortPayload} from '@src/store/interfaces/IChangeFloorSortPayload';
import {SpacesDisplayMode} from '@src/interfaces/SpacesDisplayMode';
import {IChangeSpacesDisplayModePayload} from '../actions';
import {SpacesSchemaVariants} from '@src/interfaces/SpacesSchemaVariants';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {IChangeSchemaOffsetPayload} from '@src/store/interfaces/IChangeSchemaOffsetPayload';
import {IChangeSchemaPageSizePayload} from '@src/store/interfaces/IChangeSchemaPageSizePayload';
import {IChangeListOffsetPayload} from '@src/store/interfaces/IChangeListOffsetPayload';
import * as spaceTypes from '@src/store/modules/entities/space/constants';
import * as problemTypes from '@src/store/modules/pages/problem/constants';
import {ProcessIds} from '@tehzor/tools/interfaces/process/ProcessId';

export interface ISpacesFiltersState extends Record<string, unknown> {
	ids?: string[];
	objects?: string[];
	statuses?: string[];
	problemStatuses?: string[];
	workAcceptanceStatuses?: string[];
	indicators?: string[];
	types?: string[];
	stages?: string[];
	checkListIds?: string[];
	typeDecoration?: string[];
	checkListStatuses?: string[];
	checkListCategoryStatuses?: string[];
	checkListCategory?: string[];
}

export type ISpacesSortState = Record<string, boolean>;

export interface ISpacesPageSettingsState {
	// Способ отображения помещений
	displayMode: SpacesDisplayMode;
	// Вариант шахматки (нарушения, чек-листы)
	schemaView: SpacesSchemaVariants;
	// Стадия
	stage?: ObjectStageIds;
	// Процесс
	processId?: ProcessIds;
	// Тип помещения для шахматки
	type?: string;
	// Фильтры
	filters: ISpacesFiltersState;
	// Сортировка
	sort: ISpacesSortState;
	// Количество отображаемых элементов в таблице
	pageSize: number;
	// Сортировка этажей на шахматке
	reverseFloorSort: boolean;
	// Количество отображаемых объектов на странице шахматки помещений
	schemaPageSize: number;
	// Смещение отображаемых объектов на странице шахматки помещений
	schemaOffset: number;
	// Смещение отображаемых помещений на странице списка помещений
	listOffset: number;
	// Выбранные помещения
	selected: string[];
	// количество всех помещений в списке
	total: number;
	// Отображаемый объект в пагинации мобильной версии
	currentObject?: string;
}

export type ISpacesPagesSettingsState = Record<string, ISpacesPageSettingsState>;

export const getInitialStateForPage = (): ISpacesPageSettingsState => ({
	displayMode: SpacesDisplayMode.SCHEMA,
	schemaView: SpacesSchemaVariants.PROBLEMS,
	type: undefined,
	filters: {},
	sort: {name: true},
	pageSize: 20,
	reverseFloorSort: false,
	schemaPageSize: 5,
	schemaOffset: 0,
	listOffset: 0,
	selected: [],
	total: 0
});

const checkPageExistence = (state: ISpacesPagesSettingsState, objectId: string) => {
	if (!state.hasOwnProperty(objectId)) {
		state[objectId] = getInitialStateForPage();
	}
};

const clearPage = (state: ISpacesPagesSettingsState, {payload}: {payload: {objectId: string}}) => {
	if (state[payload.objectId]) {
		state[payload.objectId].listOffset = 0;
		state[payload.objectId].total = 0;
	}
	if (state.all) {
		state.all.listOffset = 0;
		state.all.total = 0;
	}
};

export default createReducer<ISpacesPagesSettingsState>(
	{},
	{
		[types.GET_SCHEMA_REQUEST]: (state, {payload}: {payload: {objectId: string}}) => {
			checkPageExistence(state, payload.objectId);
		},
		[types.GET_LIST_REQUEST]: (state, {payload}: {payload: {objectId: string}}) => {
			checkPageExistence(state, payload.objectId);
		},
		[types.CHANGE_DISPLAY_MODE]: (
			state,
			{payload}: {payload: IChangeSpacesDisplayModePayload}
		) => {
			checkPageExistence(state, payload.objectId);
			state[payload.objectId].displayMode = payload.displayMode;
		},
		[types.CHANGE_SCHEMA_VIEW]: (
			state,
			{payload}: {payload: {objectId: string; schemaView: SpacesSchemaVariants}}
		) => {
			checkPageExistence(state, payload.objectId);
			state[payload.objectId].schemaView = payload.schemaView;
		},
		[types.CHANGE_STAGE]: (
			state,
			{payload}: {payload: {objectId: string; stage: ObjectStageIds | 'all'}}
		) => {
			checkPageExistence(state, payload.objectId);
			if (payload.stage === 'all') {
				state[payload.objectId].stage = undefined;
			} else {
				state[payload.objectId].stage = payload.stage;
			}
		},
		[types.CHANGE_PROCESS]: (
			state,
			{payload}: {payload: {objectId: string; processId: ProcessIds | 'all'}}
		) => {
			checkPageExistence(state, payload.objectId);
			if (payload.processId === 'all') {
				state[payload.objectId].processId = undefined;
			} else {
				state[payload.objectId].processId = payload.processId;
			}
		},
		[types.CHANGE_TYPE]: (state, {payload}: {payload: {objectId: string; type: string}}) => {
			checkPageExistence(state, payload.objectId);
			state[payload.objectId].schemaOffset = 0;
			state[payload.objectId].type = payload.type;
		},
		[types.CHANGE_FILTERS]: (
			state,
			{payload}: {payload: IChangeFiltersPayload<ISpacesFiltersState>}
		) => {
			checkPageExistence(state, payload.objectId);
			state[payload.objectId].filters = payload.filters;
			state[payload.objectId].listOffset = 0;
			state[payload.objectId].total = 0;

			const page = state[payload.objectId];
			if (page) {
				if (
					payload.filters.objects?.length &&
					payload.filters.objects.every(id => id !== page.currentObject)
				) {
					page.currentObject = payload.filters.objects[0];
				}
			}
		},
		[types.CLEAR_FILTERS]: (state, {payload}: {payload: IClearFiltersPayload}) => {
			checkPageExistence(state, payload.objectId);
			state[payload.objectId].filters = {} as ISpacesFiltersState;
			state[payload.objectId].listOffset = 0;
			state[payload.objectId].total = 0;
		},
		[types.CHANGE_SORT]: (
			state,
			{payload}: {payload: IChangeSortPayload<ISpacesSortState>}
		) => {
			checkPageExistence(state, payload.objectId);
			state[payload.objectId].sort = payload.sort;
		},
		[types.CHANGE_PAGE_SIZE]: (state, {payload}: {payload: IChangePageSizePayload}) => {
			checkPageExistence(state, payload.objectId);
			state[payload.objectId].pageSize = payload.pageSize;
		},
		[types.CHANGE_FLOOR_SORT]: (state, {payload}: {payload: IChangeFloorSortPayload}) => {
			checkPageExistence(state, payload.objectId);
			state[payload.objectId].reverseFloorSort = !state[payload.objectId].reverseFloorSort;
		},
		[types.CHANGE_SCHEMA_PAGE_SIZE]: (
			state,
			{payload}: {payload: IChangeSchemaPageSizePayload}
		) => {
			checkPageExistence(state, payload.objectId);
			state[payload.objectId].schemaPageSize = payload.schemaPageSize;
		},
		[types.CHANGE_SCHEMA_OFFSET]: (state, {payload}: {payload: IChangeSchemaOffsetPayload}) => {
			checkPageExistence(state, payload.objectId);
			state[payload.objectId].schemaOffset = payload.schemaOffset;
		},
		[types.CHANGE_LIST_OFFSET]: (state, {payload}: {payload: IChangeListOffsetPayload}) => {
			checkPageExistence(state, payload.objectId);
			state[payload.objectId].listOffset = payload.listOffset;
		},
		[types.CHANGE_SELECTED]: (
			state,
			{payload}: {payload: {objectId: string; value: string[]}}
		) => {
			checkPageExistence(state, payload.objectId);
			state[payload.objectId].selected = payload.value;
		},
		[types.CHANGE_CURRENT_OBJECT]: (
			state,
			{payload}: {payload: {objectId: string; id: string}}
		) => {
			if (!state.hasOwnProperty(payload.objectId)) {
				state[payload.objectId] = getInitialStateForPage();
			}
			state[payload.objectId].currentObject = payload.id;
		},
		[problemTypes.ADD_SUCCESS]: clearPage,
		[problemTypes.EDIT_SUCCESS]: clearPage,
		[problemTypes.EDIT_STATUS_SUCCESS]: clearPage,
		[problemTypes.DELETE_SUCCESS]: clearPage,
		[spaceTypes.ADD_SUCCESS]: clearPage
	}
);