import {queryClient} from '@src/api/QueryClient';
import {IGetCheckItemsResponse} from '@src/api/backend/checkItems';
import {makeCheckItemSoftDeleteRequest} from '@src/api/backend/checkItems/soft-delete';
import {checkItemsQueryKeys} from '@src/api/cache/checkItems/keys';
import {useMutation} from '@tanstack/react-query';
import IError from '@tehzor/tools/interfaces/IError';
import {useCallback} from 'react';
import {addErrorToast, addSuccessToast} from '@tehzor/ui-components';
import {useTranslation} from 'react-i18next';

interface ICheckItemSoftDeleteParams {
	checkListId: string;
	id: string;
}

export const useSoftDeleteCheckItem = () => {
	const {t} = useTranslation();
	const mutation = useMutation<IGetCheckItemsResponse, IError, ICheckItemSoftDeleteParams>({
		mutationFn: (params: ICheckItemSoftDeleteParams) =>
			makeCheckItemSoftDeleteRequest(params.checkListId, params.id),

		onSuccess: data => {
			queryClient.setQueryData<IGetCheckItemsResponse>(
				checkItemsQueryKeys.list(),
				oldData => {
					if (oldData) {
						const newById = oldData.allIds.reduce((prev, current) => {
							prev[current] =
								data.allIds.includes(current) && data.byId[current]
									? data.byId[current]
									: oldData.byId[current];
							return prev;
						}, {});

						return {
							allIds: oldData.allIds,
							byId: newById
						};
					}
					return oldData;
				}
			);
			addSuccessToast({title: t('toast.softDeleteCheckItem.success.title')});
		},
		onError: () => {
			addErrorToast({title: t('toast.softDeleteCheckItem.error.title')});
		}
	});
	/**
	 * Возвращает функцию для софт удаления категории внутри чек-листа
	 *
	 * @param item данные новой категории
	 */
	return useCallback(
		(params: ICheckItemSoftDeleteParams) => {
			mutation.mutate(params);
		},
		[mutation]
	);
};
