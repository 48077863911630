import {useDelegationHistoriesPermissions} from '@src/core/hooks/permissions/useDelegationHistoriesPermissions';
import {IDelegationHistory} from '@tehzor/tools/interfaces/delegationHistories/IDelegationHistory';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {WorkAcceptanceFrontTypeId} from '@tehzor/tools/interfaces/workAcceptances/IWorkAcceptanceFrontType';
import {WorkAcceptanceTypeId} from '@tehzor/tools/interfaces/workAcceptances/IWorkAcceptanceType';
import {WorkingGroupTypeId} from '@tehzor/tools/interfaces/workingGroups/IWorkingGroupType';
import {IRuleParams} from '@tehzor/tools/utils/responsibilityRules/IRuleParams';
import {EntityGridItem} from '@tehzor/ui-components';
import {ReactNode, memo} from 'react';
import {useTranslation} from 'react-i18next';
import './Responsibles.less';
import {ActiveDelegationInfo} from './components/ActiveDelegationInfo';
import {DelegationButton} from './components/DelegationButton';
import {DelegationHistoryButton} from './components/DelegationHistoryButton';
import {InitialDelegationInfo} from './components/InitialDelegationInfo';
import {Progress} from './components/Progress';
import {useWorkingGroupsAsMap} from '@src/core/hooks/queries/workingGroups/hooks';
import {IBriefUser} from '@tehzor/tools/interfaces/users/IBriefUser';

interface IResponsiblesProps {
	objectId: string;
	stage?: ObjectStageIds;
	scope?: string;
	workAcceptanceType?: WorkAcceptanceTypeId;
	workAcceptanceFrontType?: WorkAcceptanceFrontTypeId;
	author?: IBriefUser;

	workingGroupType?: WorkingGroupTypeId;
	respUsers?: string[];
	activeGroup?: string;
	initialGroup?: string;

	canEditResponsibles?: boolean;
	onlyActiveGroup?: boolean;

	ruleParams?: IRuleParams;
	histories?: IDelegationHistory[];
	icon?: ReactNode;
	required?: boolean;

	label?: string;
	delegationDialogLabel?: string;
	historyAutomaticActionLabelIn?: string;
	historyAutomaticActionLabelTitle?: string;
	historyUserActionLabelIn?: string;
	historyUserActionLabelTitle?: string;

	onResponsiblesChange?: (users: string[], group?: string) => void;
}

const userIcon = <i className="tz-user-20" />;

export const Responsibles = memo(
	({
		objectId,
		stage,
		scope,
		workAcceptanceType,
		workAcceptanceFrontType,
		author,
		workingGroupType,
		respUsers,
		activeGroup,
		initialGroup,
		canEditResponsibles,
		onlyActiveGroup,
		ruleParams,
		histories,
		icon,
		required,
		label,
		delegationDialogLabel,
		historyAutomaticActionLabelIn,
		historyAutomaticActionLabelTitle,
		historyUserActionLabelIn,
		historyUserActionLabelTitle,
		onResponsiblesChange
	}: IResponsiblesProps) => {
		const {t} = useTranslation();

		const {data: groupsMap} = useWorkingGroupsAsMap();

		const {canView: canViewHistory} = useDelegationHistoriesPermissions();

		const initial = initialGroup ? groupsMap?.[initialGroup] : undefined;
		const active = activeGroup ? groupsMap?.[activeGroup] : undefined;

		const delegationIsUsed = !!(initialGroup && activeGroup);
		const delegationInProgress = !respUsers?.length && activeGroup !== undefined;

		const showProgress =
			delegationIsUsed && !!(activeGroup !== initialGroup || respUsers?.length);

		return (
			<EntityGridItem
				className="responsibles-view-field"
				icon={icon ?? userIcon}
				label={label ?? t('components.viewFields.responsibles.label')}
				fullRow
			>
				<div className="responsibles-view-field__body">
					{showProgress && <Progress inProgress={delegationInProgress} />}

					<div className="responsibles-view-field__list">
						<InitialDelegationInfo
							respUsers={respUsers}
							activeGroup={active}
							initialGroup={initial}
						/>

						<ActiveDelegationInfo
							respUsersIds={respUsers}
							activeGroup={active}
						/>
					</div>
				</div>

				{delegationIsUsed && !!(canEditResponsibles || canViewHistory) && (
					<div className="responsibles-view-field__buttons">
						{canEditResponsibles && (
							<DelegationButton
								objectId={objectId}
								stage={stage}
								scope={scope}
								workAcceptanceType={workAcceptanceType}
								workAcceptanceFrontType={workAcceptanceFrontType}
								author={author}
								ruleParams={ruleParams}
								workingGroupType={workingGroupType}
								onlyActiveGroup={onlyActiveGroup}
								inProgress={delegationInProgress}
								activeGroup={activeGroup}
								respUsers={respUsers}
								onChange={onResponsiblesChange}
								title={delegationDialogLabel}
								required={required}
							/>
						)}

						{canViewHistory && histories && (
							<DelegationHistoryButton
								historyAutomaticActionLabelIn={historyAutomaticActionLabelIn}
								historyAutomaticActionLabelTitle={historyAutomaticActionLabelTitle}
								historyUserActionLabelIn={historyUserActionLabelIn}
								historyUserActionLabelTitle={historyUserActionLabelTitle}
								histories={histories}
							/>
						)}
					</div>
				)}
			</EntityGridItem>
		);
	}
);
