import {useMemo} from 'react';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {hasPermission} from '@tehzor/tools/utils/findPermission';

export function usePermissions() {
	const user = useAppSelector(s => s.auth.profile);

	return useMemo(() => {
		if (!user || !user.roles) {
			return {};
		}

		const canViewFields = hasPermission(user.roles, 'fields-view');
		const canViewFieldSettings = hasPermission(user.roles, 'field-settings-view');
		const canViewFieldSetttingsSets = hasPermission(user.roles, 'field-settings-sets-view');

		return {
			canViewObjects: hasPermission(user.roles, 'objectsView'),
			canViewSpaces: hasPermission(user.roles, 'spaces-view'),
			canViewCategories: hasPermission(user.roles, 'categories-view'),
			canViewPlans: hasPermission(user.roles, 'plansView'),
			canViewReasons: true,
			canViewCheckLists: hasPermission(user.roles, 'check-lists-view'),
			canViewWorkingGroups: hasPermission(user.roles, 'working-groups-view'),
			canViewProblemTemplates: hasPermission(user.roles, 'problem-templates-sets-view'),
			canViewChecks: hasPermission(user.roles, 'checksView'),
			canViewPresaleChecks: hasPermission(user.roles, 'presale-checks-view'),
			canViewInternalAcceptances: hasPermission(user.roles, 'internal-acceptances-view'),
			canViewFields: canViewFields || canViewFieldSettings || canViewFieldSetttingsSets
		};
	}, [user]);
}
