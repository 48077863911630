import './EditingTaskDialog.less';
import {useCallback, useState} from 'react';
import {ActionButtons, Button, ModalDialog} from '@tehzor/ui-components';
import {useEditableTask} from '../EditableTask/hooks/useEditableTask';
import {useTasksPermissions} from '@src/core/hooks/permissions/useTasksPermissions';
import {ITask} from '@tehzor/tools/interfaces/tasks/ITask';
import {useTask} from '@src/core/hooks/queries/task/hooks';
import {useAddTask} from '@src/core/hooks/mutations/task/useAddTask';
import {useEditTask} from '@src/core/hooks/mutations/task/useEditTask';
import {useTranslation} from 'react-i18next';
import {useTranslatedConfirmDialog} from '@src/core/hooks/translations/useTranslatedConfirmDialog';

interface IEditingTaskDialogProps {
	taskId?: string;
	objectId?: string;
	startTime?: string;
	endTime?: string;
	isOpen: boolean;

	close(): void;
}

export const EditingTaskDialog = ({
	taskId,
	objectId,
	startTime,
	endTime,
	isOpen,
	close
}: IEditingTaskDialogProps) => {
	const {t} = useTranslation();
	const {mutateAsync: addTask} = useAddTask();
	const {mutateAsync: editTask} = useEditTask();
	const [saving, setSaving] = useState(false);
	const {data: task} = useTask(taskId);
	const [taskFields, getSavingData, isBlocking, reset] = useEditableTask({
		task: taskId ? (task as ITask) : undefined,
		objectId,
		startTime,
		endTime,
		saving
	});

	const handleSave = useCallback(async () => {
		const savingData = await getSavingData();
		if (!savingData) {
			return;
		}
		setSaving(true);

		try {
			if (taskId) {
				await editTask({taskId, fields: savingData});
			} else {
				await addTask(savingData);
			}
		} finally {
			setSaving(false);
			reset();
			close();
		}
	}, [editTask, addTask, close, getSavingData, reset, taskId]);

	const [closingDialog, getClosingConfirmation] = useTranslatedConfirmDialog();

	const cancel = useCallback(async () => {
		if (!isBlocking || (await getClosingConfirmation())) {
			close();
		}
	}, [isBlocking, getClosingConfirmation, close]);

	const permissions = useTasksPermissions({
		objectId: task?.objectId,
		createdBy: task?.createdBy?.id,
		respUsers: task?.respUsers,
		watchers: task?.watchers,
		activeGroupLeader: task?.activeGroupLeader?.id
	});

	if ((!taskId && !permissions.canAdd) || (taskId && !permissions.canEdit)) return null;

	return (
		<ModalDialog
			className={{
				root: 'editing-task',
				content: 'adding-task__container'
			}}
			open={isOpen}
			title={t('editingTaskDialog.title')}
			footer={
				<ActionButtons>
					<Button
						type="cancel"
						label={t('editingTaskDialog.button.cancel')}
						disabled={saving}
						onClick={cancel}
					/>
					<Button
						type="accent-blue"
						label={t('editingTaskDialog.button.accent')}
						disabled={saving}
						onClick={handleSave}
					/>
				</ActionButtons>
			}
			fullScreenOnTablet
			onRequestClose={cancel}
			onAfterClose={reset}
		>
			{taskFields}
			{closingDialog}
		</ModalDialog>
	);
};
