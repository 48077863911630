import * as React from 'react';
import useAsyncFn from 'react-use/lib/useAsyncFn';
import {useUpdateContent} from '@src/components/EditableBankAccount/hooks/useUpdateContent';
import {useTranslation} from 'react-i18next';
import {useTranslatedConfirmDialog} from '@src/core/hooks/translations/useTranslatedConfirmDialog';

export function useBankAccountDelete(bankAccountId: string): [React.ReactNode, () => void] {
	const {t} = useTranslation();
	const {removeBankAccount} = useUpdateContent({});

	const [deleteDialog, getDeleteConfirmation] = useTranslatedConfirmDialog({
		title: t('useConfirmDialog.bankAccount.deleteTitle'),
		message: t('useConfirmDialog.bankAccount.deleteMessage')
	});

	const [, handleDelete] = useAsyncFn(async () => {
		if (await getDeleteConfirmation()) {
			await removeBankAccount(bankAccountId);
		}
	}, [bankAccountId]);

	return [deleteDialog, handleDelete];
}
