import {IPreparedField} from '@/features/Fields';
import {MobileCell} from '../../ui/Cells/MobileCell/MobileCell';
import {Column} from 'react-table';

export const getMobileColumns = (): Array<Column<IPreparedField>> => [
	{
		id: 'name',
		Cell: MobileCell
	}
];
