import {useChangePath} from '@src/core/hooks/useChangePath';
import {IPresaleCheckEntity} from '@src/interfaces/IPresaleCheckEntity';
import {formInspectionLink, formProblemLink} from '@tehzor/tools/utils/links';
import {EntityInnerTable} from '@tehzor/ui-components';
import {ReactElement, useCallback} from 'react';
import {SelectionRow} from './SelectionRow';
import {Column} from 'react-table';
import {usePresaleCheckEntities} from '@/entities/PresaleCheck';
import styles from './PresaleCheckEntitiesTable.module.less';

interface IPresaleCheckEntitiesTableProps {
	objectId: string;
	presaleCheckId: string;
	columns: Array<Column<IPresaleCheckEntity>>;
	hideHead?: boolean;
	selectedRows?: string[];
	onSelectedRowsChange?: (value: string[]) => void;
}

export const PresaleCheckEntitiesTable = ({
	objectId,
	presaleCheckId,
	columns,
	hideHead,
	selectedRows,
	onSelectedRowsChange
}: IPresaleCheckEntitiesTableProps): ReactElement => {
	const {pushPath} = useChangePath();
	const entities = usePresaleCheckEntities(objectId, presaleCheckId);

	const handleRowClick = useCallback(
		(entity: IPresaleCheckEntity) => {
			switch (entity.type) {
				case 'problem':
					return pushPath(formProblemLink(objectId, entity.data.id));
				case 'inspection':
					return pushPath(formInspectionLink(objectId, entity.data.id));
				default:
					return undefined;
			}
		},
		[objectId]
	);

	return (
		<EntityInnerTable
			className={styles.dEntities}
			columns={columns}
			data={entities}
			hideHead={hideHead}
			selectable
			selectedRows={selectedRows}
			onRowClick={handleRowClick}
			onSelectedRowsChange={onSelectedRowsChange}
			renderSelectionRow={props => (
				<SelectionRow
					{...props}
					objectId={objectId}
					presaleCheckId={presaleCheckId}
				/>
			)}
		/>
	);
};
