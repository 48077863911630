import {IGetBuiltinFieldsResponse} from '@/shared/api/fields/getBuiltInFieldsList';
import {useFieldsPermissions} from '@/shared/hooks/permissions/useFieldsPermissions';
import {useQuery} from '@tanstack/react-query';
import IError from '@tehzor/tools/interfaces/IError';
import {fieldsQueryKeys} from '../../../keys/index';

export const useBuiltinFields = <T = IGetBuiltinFieldsResponse>(
	select?: (data: IGetBuiltinFieldsResponse) => T
) => {
	const permissions = useFieldsPermissions();

	return useQuery<IGetBuiltinFieldsResponse, IError, T>({
		queryKey: fieldsQueryKeys.builtinLists(),
		meta: {
			error: 'при загрузке списка пользовательских полей'
		},
		select,
		enabled: permissions.canViewFields
	});
};
