import {memo} from 'react';
import './WorkingGroupEditingPage.less';
import {useAppHeader} from '@src/components/AppHeader/hooks/useAppHeader';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import {EditPageBreadcrumbs} from './components/EditPageBreadcrumbs';
import {EditingWorkingGroup} from '@src/components/EditingWorkingGroup/EditingWorkingGroup';
import AppUpdateMenu from '@src/components/AppUpdateData/AppUpdateMenu';

export const WorkingGroupEditingPage = memo(() => {
	const {workingGroupId} = useStrictParams<{workingGroupId?: string}>();

	useAppHeader(
		{
			title: workingGroupId ? 'Редактирование рабочей группы' : 'Создание рабочей группы',
			mobileRightButtons: <AppUpdateMenu />,
			showBackBtn: true
		},
		[workingGroupId]
	);


	return (
		<div className="page-cont manage-working-group-edit-page">
			<EditPageBreadcrumbs />
			<EditingWorkingGroup workingGroupId={workingGroupId} />
		</div>
	);
});

WorkingGroupEditingPage.displayName = 'WorkingGroupEditingPage';
