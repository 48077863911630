import IError from '@tehzor/tools/interfaces/IError';
import {createApiAction} from '@src/store/middlewares/api';
import {AppThunkAction} from '@src/store/interfaces/thunk';
import {IGetCheckRecordsResponse, requestCheckRecords} from '@src/api/backend/checkRecords';
import * as types from '../../constants/records';
import {addErrorToast} from '@tehzor/ui-components';
import {t} from 'i18next';

export type ICheckChangedRecordsPayload = IGetCheckRecordsResponse;

const request = () => ({
	type: types.CHECK_REQUEST
});

const success = (response: IGetCheckRecordsResponse) => ({
	type: types.CHECK_SUCCESS,
	payload: {...response}
});

const failure = (error: IError) => {
	if (error.statusCode !== 401) {
		addErrorToast({title: t('toast.checkChangedRecords.error.title')});
	}
	return {
		type: types.CHECK_FAILURE,
		payload: {...error}
	};
};

/**
 * Получает список записей по категории
 *
 * @param checkListIds идентификатор чек-листа
 * @param objectId идентификатор объекта
 * @param spaceId идентификатор помещения
 */
export const checkChangedRecords =
	(
		checkListIds: string[],
		objectId?: string,
		spaceId?: string
	): AppThunkAction<Promise<IGetCheckRecordsResponse>> =>
	dispatch => {
		const additionalParams: Record<string, string[]> = {};
		if (spaceId) {
			additionalParams.spaceId = [spaceId];
		}
		if (objectId) {
			additionalParams.objectId = [objectId];
		}
		return dispatch(
			createApiAction<IGetCheckRecordsResponse>(request, success, failure, () =>
				requestCheckRecords({checkListId: checkListIds, ...additionalParams})
			)
		);
	};
