import {useCallback, useState, ReactNode} from 'react';
import {EditablePresaleCheckAttachmentsDialog} from '../../components/EditablePresaleCheckAttachmentsDialog';
import {IPresaleCheck} from '@tehzor/tools/interfaces/presaleChecks/IPresaleCheck';

export const useEditablePresaleCheckAttachmentsDialog = (
	objectId: string,
	presaleCheckId: string,
	presaleCheck: IPresaleCheck
): [ReactNode, () => void] => {
	const [isOpen, setOpen] = useState<boolean>(false);

	const open = useCallback(() => {
		setOpen(true);
	}, []);

	const close = useCallback(() => {
		setOpen(false);
	}, []);

	const dialog = (
		<EditablePresaleCheckAttachmentsDialog
			objectId={objectId}
			presaleCheckId={presaleCheckId}
			presaleCheck={presaleCheck}
			isOpen={isOpen}
			onClose={close}
		/>
	);

	return [dialog, open];
};
