import {queryClient} from '@src/api/QueryClient';
import {IGetProblemTagsResponse, makeProblemTagAddRequest} from '@src/api/backend/problemTags';
import {problemTagsQueryKeys} from '@src/api/cache/problemTags/keys';
import {useMutation} from '@tanstack/react-query';
import IError from '@tehzor/tools/interfaces/IError';
import {IProblemTag} from '@tehzor/tools/interfaces/problemTagsSets/IProblemTag';
import {ISavingProblemTag} from '@tehzor/tools/interfaces/problemTagsSets/ISavingProblemTag';
import {addErrorToast, addSuccessToast} from '@tehzor/ui-components';
import {useTranslation} from 'react-i18next';

export const useAddProblemTag = () => {
	const {t} = useTranslation();

	return useMutation<IProblemTag, IError, ISavingProblemTag>({
		mutationFn: (params: ISavingProblemTag) => makeProblemTagAddRequest(params),
		mutationKey: problemTagsQueryKeys.add(),
		onSuccess: data => {
			queryClient.setQueryData<IGetProblemTagsResponse>(
				problemTagsQueryKeys.list(),
				oldData => {
					if (oldData) {
						return {
							byId: {...oldData.byId, [data.id]: data},
							allIds: [...oldData.allIds, data.id]
						};
					}
					return oldData;
				}
			);
			addSuccessToast({title: t('toast.addProblemTag.success.title')});
		},
		onError: () => {
			addErrorToast({title: t('toast.addProblemTag.error.title')});
		}
	});
};
