const domain = 'space';

export const ADD_SUCCESS = `${domain}/add/success`;
export const EDIT_SUCCESS = `${domain}/edit/success`;

export const EDIT_STATUS_REQUEST = `${domain}/status/edit/request`;
export const EDIT_STATUS_SUCCESS = `${domain}/status/edit/success`;
export const EDIT_STATUS_FAILURE = `${domain}/status/edit/failure`;

export const EDIT_INDICATORS_REQUEST = `${domain}/indicators/edit/request`;
export const EDIT_INDICATORS_SUCCESS = `${domain}/indicators/edit/success`;
export const EDIT_INDICATORS_FAILURE = `${domain}/indicators/edit/failure`;

export const DELETE_REQUEST = `${domain}/delete/request`;
export const DELETE_SUCCESS = `${domain}/delete/success`;
export const DELETE_FAILURE = `${domain}/delete/failure`;

export const EXPORT_REQUEST = `${domain}/export/request`;
export const EXPORT_SUCCESS = `${domain}/export/success`;
export const EXPORT_FAILURE = `${domain}/export/failure`;
