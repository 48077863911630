import {useStrictParams} from '@src/core/hooks/useStrictParams';
import styles from './MobileFieldSettingsEditPage.module.less';
import {useAppHeader} from '@src/components/AppHeader/hooks/useAppHeader';
import {FieldEnabledToggle} from '../components/Toggles/FieldEnabledToggle';
import {FieldRequiredToggle} from '../components/Toggles/FieldRequiredToggle';
import {FieldCopyableToggle} from '../components/Toggles/FieldCopybleToggle';
import {usePreparedField} from '@/features/Fields';
import useScrollRestoration from '@src/core/hooks/useScrollRestoration';

interface IMobileFieldSettingsEditPageProps {
	isCustom: boolean;
}

export const MobileFieldSettingsEditPage = ({isCustom}: IMobileFieldSettingsEditPageProps) => {
	useScrollRestoration();

	useAppHeader({
		title: 'Редактирование поля',
		showBackBtn: true
	});

	const {fieldId, setId} = useStrictParams<{fieldId: string; setId: string}>();
	const field = usePreparedField({setId, fieldId, isCustom});

	if (!field) return null;
	return (
		<div className={styles.wrapper}>
			<FieldEnabledToggle
				setId={setId}
				field={field}
			/>
			<FieldRequiredToggle field={field} />
			<FieldCopyableToggle field={field} />
		</div>
	);
};
