import {DownloadableFile} from '@tehzor/ui-components';
import {
	IPresaleCheck,
	IPresaleCheckAttachment
} from '@tehzor/tools/interfaces/presaleChecks/IPresaleCheck';
import saveOrOpenBlob from '@tehzor/tools/utils/saveOrOpenBlob';
import {requestGetPresaleCheckAttachment} from '@/shared/api/presaleChecks/getPresaleCheckAttachment';

interface IPresaleCheckDocumentsProps {
	presaleCheck?: IPresaleCheck;
}

export const PresaleCheckDocuments = ({presaleCheck}: IPresaleCheckDocumentsProps) => {
	const handleClick = async (attachment: IPresaleCheckAttachment) => {
		if (attachment.fileKey) {
			const res = await requestGetPresaleCheckAttachment(
				attachment.fileName,
				attachment.fileType,
				attachment.fileKey
			);
			saveOrOpenBlob(res.fileContent, attachment.fileName);
		}
	};

	return (
		<div>
			{presaleCheck?.attachments?.map(item => (
				<div key={item.id}>
					<DownloadableFile
						name={item.fileName}
						type={item.fileType}
						size={item.fileSize}
						onClick={() => handleClick(item)}
					/>
				</div>
			))}
		</div>
	);
};
