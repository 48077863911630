import {queryClient} from '@src/api/QueryClient';
import {IGetCheckItemsResponse, makeCheckItemAddRequest} from '@src/api/backend/checkItems';
import {checkItemsQueryKeys} from '@src/api/cache/checkItems/keys';
import {useMutation} from '@tanstack/react-query';
import IError from '@tehzor/tools/interfaces/IError';
import {ICheckItem} from '@tehzor/tools/interfaces/checkItems/ICheckItem';
import {ISavingCheckItem} from '@tehzor/tools/interfaces/checkItems/ISavingCheckItem';
import {useCallback} from 'react';
import {addErrorToast, addSuccessToast} from '@tehzor/ui-components';
import {useTranslation} from 'react-i18next';

export const useAddCheckItem = () => {
	const {t} = useTranslation();
	const mutation = useMutation<ICheckItem, IError, ISavingCheckItem>({
		mutationFn: (params: ISavingCheckItem) => makeCheckItemAddRequest(params),

		onSuccess: data => {
			queryClient.setQueryData<IGetCheckItemsResponse>(
				checkItemsQueryKeys.list(),
				oldData => {
					if (oldData) {
						return {
							byId: {...oldData.byId, [data.id]: data},
							allIds: [...oldData.allIds, data.id]
						};
					}
					return oldData;
				}
			);
			addSuccessToast({title: t('toast.addCheckItem.success.title')});
		},
		onError: () => {
			addErrorToast({title: t('toast.addCheckItem.error.title')});
		}
	});
	/**
	 * Возвращает функцию для создания категорию в чек-листе
	 *
	 * @param item данные новой категории
	 */
	return useCallback(
		(item: ISavingCheckItem) => {
			mutation.mutate(item);
		},
		[mutation]
	);
};
