import {memo} from 'react';
import {CellProps} from 'react-table';
import {IBriefUser} from '@tehzor/tools/interfaces/users/IBriefUser';
import styles from './MobileCell.module.less';
import {format} from 'date-fns';
import {dateTimeCommaSeparatedFormat} from '@tehzor/tools/utils/dateFormats';
import {ICustomField} from '@tehzor/tools/interfaces/fields';

export const MobileCell = memo(({row}: CellProps<ICustomField>) => {
	const {
		name,
		company,
		icon,
		key,
		valueType,
		entityType,
		createdAt,
		createdBy,
		modifiedAt,
		modifiedBy
	} = row.original;
	const dates: Array<{name: string; at?: number; by?: IBriefUser}> = [];
	if (createdAt || createdBy) {
		dates.push({name: 'Добавлено:', at: createdAt, by: createdBy});
	}
	if (modifiedAt || modifiedBy) {
		dates.push({name: 'Изменено:', at: modifiedAt, by: modifiedBy});
	}

	return (
		<div className={styles.wrapper}>
			<div className={styles.header}>
				<p className={styles.title}>{name}</p>
				{icon && <i className={icon} />}
			</div>
			<div className={styles.info}>
				<p className={styles.infoItem}>
					Ключ: <span>{key}</span>
				</p>
				<p className={styles.infoItem}>
					Тип данных: <span>{valueType.name}</span>
				</p>
				<p className={styles.infoItem}>
					Тип сущности: <span>{entityType.name}</span>
				</p>
			</div>
			<p className={styles.infoItem}>
				Компания: <span>{company?.name}</span>
			</p>
			<div className={styles.dates}>
				{dates.map(({at, by, name}) => (
					<div
						key={name}
						className={styles.date}
					>
						<div className={styles.dateName}>{name}</div>
						<div className={styles.dateValue}>
							{at && <div>{format(at, dateTimeCommaSeparatedFormat)}</div>}
							{by && <div>{by.displayName}</div>}
						</div>
					</div>
				))}
			</div>
		</div>
	);
});
