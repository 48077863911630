import {httpRequests} from '@tehzor/tools/api/httpRequests';
import {IStructuresStatsDataRequest} from '../../../../../interfaces/IStructuresStatsData';
import {IEntityWorkAcceptancesData} from '@tehzor/tools/interfaces/entititesComputedData/IEntityWorkAcceptancesData';

export type IGetStructuresWorkAcceptancesResponse = Record<string, IEntityWorkAcceptancesData>;

/**
 * Возвращает статистику нарушений по структурам
 */
export const getStructuresWorkAcceptancesDataRequest = async (
	requestData: IStructuresStatsDataRequest
) => {
	const response = await httpRequests.withToken.post<IGetStructuresWorkAcceptancesResponse>(
		'structures/get-work-acceptance-stats',
		{
			objectId: requestData.objectId,
			structuresIds: requestData.structuresIds
		}
	);
	return response.data;
};
