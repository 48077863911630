import {IconCell} from '../../ui/Cells/IconCell/IconCell';
import {ValueTypeCell} from '../../ui/Cells/ValueTypeCell/ValueTypeCell';
import {FieldEnabledCell} from '../../ui/Cells/FieldEnabledCell/FieldEnabledCell';
import {FieldRequiredCell} from '../../ui/Cells/FieldRequiredCell/FieldRequiredCell';
import {FieldCopyableCell} from '../../ui/Cells/FieldCopyableCell/FieldCopyableCell';
import {IPreparedField} from '@/features/Fields';
import {ColumnDef} from '@tanstack/react-table';

interface IGetBodyColumnsParams {
	withInfo: boolean;
	withSettings: boolean;
}

export const getBodyColumns = ({
	withInfo,
	withSettings
}: IGetBodyColumnsParams): Array<ColumnDef<IPreparedField, IPreparedField>> => {
	const infoColumns = [
		{
			id: 'icon',
			header: 'Иконка',
			cell: IconCell,
			size: 12
		},
		{
			id: 'valueType',
			header: 'Тип данных',
			cell: ValueTypeCell,
			size: 22
		}
	];

	const enabledColumn = {
		id: 'enabled',
		header: 'Включено',
		cell: FieldEnabledCell,
		size: withSettings ? 22 : 66
	};

	const settingsColumns = [
		{
			id: 'required',
			header: 'Обязательно',
			cell: FieldRequiredCell,
			size: 22
		},
		{
			id: 'copyable',
			header: 'Возможность копировать',
			cell: FieldCopyableCell,
			size: 22
		}
	];

	const result: Array<ColumnDef<IPreparedField, IPreparedField>> = [];
	if (withInfo) {
		result.push(...infoColumns);
	}
	result.push(enabledColumn);
	if (withSettings) {
		result.push(...settingsColumns);
	}

	return result;
};
