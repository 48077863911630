import {httpRequests} from '@tehzor/tools/api/httpRequests';
import {ISavingStructure} from '@tehzor/tools/interfaces/structures/ISavingStructure';
import {IStructure} from '@tehzor/tools/interfaces/structures/IStructure';

export type IEditStructureResponse = IStructure;

/**
 * Изменяет структуру
 *
 * @param objectId id объекта
 * @param structureId id структуры
 * @param fields данные структуры
 */
export const makeStructureEditRequest = async (
	objectId: string,
	structureId: string,
	fields: ISavingStructure
) => {
	const response = await httpRequests.withToken.put<IEditStructureResponse>(
		`structures/${structureId}`,
		{objectId, ...fields}
	);
	return response.data;
};
