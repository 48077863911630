import {Column} from 'react-table';
import {IPreparedFieldSettingsSet} from '@/shared/types/IPreparedFieldsSettingsSets';
import {FieldDescriptionCell} from '../../ui/cells/FieldDescriptionCell/FieldDescriptionCell';
import {FieldCompanyCell} from '../../ui/cells/FieldCompanyCell/FieldCompanyCell';
import {FieldObjectsCell} from '../../ui/cells/FieldObjectsCell/FieldObjectsCell';
import {FieldStagesCell} from '../../ui/cells/FieldStagesCell/FieldStagesCell';
import {FieldAddedCell} from '../../ui/cells/FieldAddedCell/FieldAddedCell';
import {FieldModifiedCell} from '../../ui/cells/FieldModifiedCell/FieldModifiedCell';
import {DeleteCell} from '../../ui/cells/DeleteCell/DeleteCell';

export const getDesktopColumns = (): Array<Column<IPreparedFieldSettingsSet>> => [
	{
		id: 'name',
		Header: 'Набор',
		Cell: FieldDescriptionCell,
		accessor: item => item.name,
		width: 150,
		minWidth: 150
	},
	{
		id: 'company',
		Header: 'Компания',
		Cell: FieldCompanyCell,
		accessor: item => item.company?.name,
		width: 120,
		minWidth: 80
	},
	{
		id: 'objectIds',
		Header: 'Объекты',
		accessor: item => item,
		Cell: FieldObjectsCell,
		width: 120,
		minWidth: 80
	},
	{
		id: 'stages',
		Header: 'Стадии',
		accessor: item => item.stageIds,
		Cell: FieldStagesCell,
		width: 120,
		minWidth: 80
	},
	{
		id: 'entityType',
		Header: 'Тип сущности',
		accessor: item => item.entityType.name,
		width: 120,
		minWidth: 80
	},
	{
		id: 'createdAt',
		accessor: item => item,
		Header: 'Добавлено',
		Cell: FieldAddedCell,
		width: 100
	},
	{
		id: 'modifiedAt',
		Header: 'Изменено',
		accessor: item => item,
		Cell: FieldModifiedCell,
		width: 100
	},
	{
		id: 'delete',
		Cell: DeleteCell,
		Header: '',
		width: 70,
		disableResizing: true,
		isNonClickable: true
	}
];
