import {IWorkAcceptance} from '@tehzor/tools/interfaces/workAcceptances/IWorkAcceptance';
import {wsConnector} from '../wsConnector';

export type IEditWorkAcceptanceAcceptorsResponse = IWorkAcceptance;

/**
 * Изменяет принимающих приёмки работ
 *
 * @param objectId id объекта
 * @param workAcceptanceId id приёмки работ
 * @param acceptors пользователи
 * @param acceptorsActiveGroup текущая группа
 */
export const makeWorkAcceptanceAcceptorsEditRequest = (
	objectId: string,
	workAcceptanceId: string,
	acceptors?: string[],
	acceptorsActiveGroup?: string
) => wsConnector.sendAuthorizedRequest<IEditWorkAcceptanceAcceptorsResponse>('editWorkAcceptanceAcceptors', {
	objectId,
	workAcceptanceId,
	acceptors,
	acceptorsActiveGroup
});
