import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {IListSpace} from '@tehzor/tools/interfaces/spaces/IListSpace';
import {convertSortParam} from '@tehzor/tools/utils/convertSortParam';
import {httpRequests} from '@tehzor/tools/api/httpRequests';

export interface IGetSpacesResponse extends INormalizedData<IListSpace> {
	offset: number;
	limit: number;
	total: number;
}
/**
 * Возвращает список помещений
 */
export const requestSpaces = async (
	filters?: Record<string, unknown>,
	sort?: Record<string, boolean>,
	offset?: number,
	limit?: number
) => {
	const params = {
		filters,
		sort: convertSortParam(sort),
		offset,
		limit
	};
	const response = await httpRequests.withToken.post<IGetSpacesResponse>('spaces/get', params);
	return response.data;
};
