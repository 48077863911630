import {IPresaleCheckEntity} from '@src/interfaces/IPresaleCheckEntity';
import CellWrap from '@src/components/tableCells/CellWrap';
import InspectionInfoDesktopCell from '@src/components/tableCells/InspectionInfoDesktopCell/InspectionInfoDesktopCell';
import HeaderCellWithRowSelectWrap from '@src/components/tableCells/HeaderCellWithRowSelectWrap';
import {ProblemInfoCellWrap} from '@src/components/tableCells/ProblemInfoCellWrap';
import ProblemInfoDesktopCell from '@src/components/tableCells/ProblemInfoDesktopCell';
import {CategoryCell} from '@src/components/tableCells/CategoryCell';
import {useTranslation} from 'react-i18next';
import {ProblemStatusCell} from '@src/components/tableCells/ProblemStatusCell';
import {PlannedFixDateCell} from '../../Cells/PlannedFixDateCell';
import {PerformersCell} from '../../Cells/PerformersCell';
import {Column} from 'react-table';
import IComment from '@tehzor/tools/interfaces/comments/IComment';

interface IPresaleCheckColumnsDesktopProps {
	replies: Record<string, IComment> | undefined;
}

export const PresaleCheckColumnsDesktop = ({
	replies
}: IPresaleCheckColumnsDesktopProps): Array<Column<IPresaleCheckEntity>> => {
	const {t} = useTranslation();

	return [
		{
			id: 'number',
			Header: HeaderCellWithRowSelectWrap(
				`№ ${t('presaleChecksPage.table.columns.number.header')}`
			),
			Cell: CellWrap({
				problem: ProblemInfoCellWrap(ProblemInfoDesktopCell, undefined, replies),
				inspection: InspectionInfoDesktopCell
			}),
			width: 200,
			minWidth: 200
		},
		{
			id: 'status',
			Header: t('presaleChecksPage.table.columns.status.header'),
			Cell: CellWrap({
				problem: ProblemStatusCell
			}),
			width: 80,
			minWidth: 80
		},
		{
			id: 'category',
			Header: t('presaleChecksPage.table.columns.category.header'),
			Cell: CellWrap({
				problem: CategoryCell,
				inspection: CategoryCell
			}),
			width: 120,
			minWidth: 120
		},
		{
			id: 'plannedFixDate',
			Header: t('presaleChecksPage.table.columns.plannedFixDate.header'),
			width: 130,
			minWidth: 130,
			Cell: CellWrap({problem: PlannedFixDateCell})
		},
		{
			id: 'performers',
			Header: t('presaleChecksPage.table.columns.performers.header'),
			Cell: CellWrap({problem: PerformersCell, inspection: PerformersCell}),
			width: 130,
			minWidth: 130
		}
	];
};
