import {memo} from 'react';
import {IconMenu, MenuItem} from '@tehzor/ui-components';
import {IPreparedLegal} from '../../interfaces/IPreparedLegal';
import {useAppUpdateMenuItem} from '@src/components/AppUpdateData';
import {useDeleteLegals} from '@src/core/hooks/mutations/legals/useDeleteLegals';
import {useTranslatedConfirmDialog} from '@src/core/hooks/translations/useTranslatedConfirmDialog';
import {useTranslation} from 'react-i18next';

interface ISelectionActionsProps {
	selectedEntities: IPreparedLegal[];
	onClear: () => void;
}

const deleteIcon = <i className="tz-delete" />;

export const LegalsActions = memo(({selectedEntities = [], onClear}: ISelectionActionsProps) => {
	const {t} = useTranslation();
	const canDelete = selectedEntities.every(item => item.canDelete);
	const {deleteLegals} = useDeleteLegals();
	const updateAppData = useAppUpdateMenuItem();

	const items = [];

	const [deleteDialog, getDeleteConfirmation] = useTranslatedConfirmDialog({
		title: t('useConfirmDialog.legals.deleteTitle'),
		message: t('useConfirmDialog.legals.deleteMessage')
	});

	const handleDelete = async () => {
		if (await getDeleteConfirmation()) {
			await deleteLegals(selectedEntities);
		}
		onClear();
	};

	items.push(updateAppData);

	if (canDelete && selectedEntities.length) {
		items.push(
			<>
				<MenuItem
					key="delete"
					icon={deleteIcon}
					onClick={handleDelete}
				>
					Удалить
				</MenuItem>
				{deleteDialog}
			</>
		);
	}

	return <IconMenu>{items}</IconMenu>;
});
