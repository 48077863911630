import {DndContext, DndTable, IDndEvent} from '@tehzor/ui-components';
import {Dispatch, memo, SetStateAction, useCallback, useMemo} from 'react';
import {IPreparedField} from '@/features/Fields';
import styles from './NamesTable.module.less';
import {getNamesColumn} from '../../model/utils/getNamesColumn';
import {useIsBeforeTablet} from '@/shared/hooks/dimensions/useIsDesktop/useIsDesktop';
import {getMobileColumns} from '../../model/utils/getMobileColumns';
import {useEditFieldSettingsOrder} from '@/entities/FieldSettings';

interface IDndNamesColumnProps {
	setId: string;
	fields: IPreparedField[];
	onChangeOrder?: Dispatch<SetStateAction<IPreparedField[]>>;
	containerId: string;
	disabled?: boolean;
}

export const NamesTable = memo(
	({setId, fields, onChangeOrder, containerId, disabled = false}: IDndNamesColumnProps) => {
		const isBeforeTablet = useIsBeforeTablet();
		const {mutateAsync: editFieldSettingsOrder} = useEditFieldSettingsOrder();

		const tableClassNames = {
			root: styles.wrapper,
			headerCell: styles.headerCell,
			bodyCell: styles.bodyCell,
			emptyRow: styles.emptyRow,
			header: styles.header,
			body: styles.body,
			bodyDragCell: styles.bodyDragCell
		};

		const handleDragEnd = useCallback(
			async ({items: changedFields}: IDndEvent<IPreparedField>) => {
				if (!onChangeOrder) return;
				onChangeOrder(changedFields);
				const savingData = changedFields.reduce(
					(acc: Array<{settingId: string; order: number}>, field) => {
						if (field.settingsId) {
							acc.push({settingId: field.settingsId, order: field.order - 1});
						}
						return acc;
					},
					[]
				);
				await editFieldSettingsOrder({setId, settings: savingData});
			},
			[setId, editFieldSettingsOrder, onChangeOrder]
		);

		const columns = useMemo(
			() => (!isBeforeTablet ? getNamesColumn() : getMobileColumns()),
			[isBeforeTablet]
		);

		if (disabled) {
			return (
				<DndTable
					container={containerId}
					items={fields}
					columns={columns}
					className={tableClassNames}
					draggableIds={[]}
				/>
			);
		}

		return (
			<DndContext>
				<DndTable
					container={containerId}
					items={fields}
					columns={columns}
					onDragEnd={handleDragEnd}
					className={tableClassNames}
				/>
			</DndContext>
		);
	}
);
