import {useQuery} from '@tanstack/react-query';
import {EntityTypeId} from '@tehzor/tools/interfaces/fields';
import {
	extractFieldSettingsSetById,
	extractFieldSettingsSetsByObjectId,
	extractFieldSettingsSetsListArray,
	extractFieldSettingsSetsListMap
} from './selectors';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {useObject} from '../objects/hooks';
import {useCompany} from '../companies/hooks';
import {useCallback} from 'react';
import {IGetListFieldSettingsSetsResponse} from '@/shared/api/fieldsSettingsSets/getFieldsSettingsSets';
import {useFieldsPermissions} from '@/shared/hooks/permissions/useFieldsPermissions';
import {fieldSettingsSetsQueryKeys} from '@/entities/FieldsSettingsSets';

export const useFieldSettingsSetsList = <T = IGetListFieldSettingsSetsResponse>(
	select?: (data: IGetListFieldSettingsSetsResponse) => T
) => {
	const permissions = useFieldsPermissions();

	return useQuery({
		queryKey: fieldSettingsSetsQueryKeys.list(),
		meta: {
			error: 'при загрузке списка наборов настроек полей'
		},
		select,
		enabled: permissions.canViewSettingsSets
	});
};

export const useFieldSettingsSetsArray = () =>
	useFieldSettingsSetsList(extractFieldSettingsSetsListArray);

export const useFieldSettingsSetsMap = () =>
	useFieldSettingsSetsList(extractFieldSettingsSetsListMap);

export const useFieldSettingsSetById = (setId: string) =>
	useFieldSettingsSetsList(data => extractFieldSettingsSetById(data, setId));

export const useFieldSettingsSetsByEntityType = (
	entityTypeId: EntityTypeId,
	objectId?: string,
	stage?: ObjectStageIds
) => {
	const {data: object} = useObject(objectId);
	const {data: company} = useCompany(object?.companyId);

	const selector = useCallback(
		(data: IGetListFieldSettingsSetsResponse) =>
			extractFieldSettingsSetsByObjectId(data, entityTypeId, company?.id, objectId, stage),
		[entityTypeId, company?.id, objectId, stage]
	);

	return useFieldSettingsSetsList(selector);
};
