import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {addErrorToast, addSuccessToast} from '@tehzor/ui-components';
import {t} from 'i18next';

const request = () => ({type: types.DELETE_REQUEST});

const success = (
	objectId: string,
	internalAcceptanceId: string,
	quietly?: boolean,
	callback?: () => void
) => {
	if (!quietly) {
		addSuccessToast({title: t('toast.deleteInternalAcceptance.success.title')});
	}
	if (callback) {
		callback();
	}

	return {
		type: types.DELETE_SUCCESS,
		payload: {
			objectId,
			internalAcceptanceId
		}
	};
};

const failure = (error: IError) => {
	addErrorToast({title: t('toast.deleteInternalAcceptance.error.title')});
	return {
		type: types.DELETE_FAILURE,
		payload: {...error}
	};
};

export const deleteInternalAcceptanceActions = {request, success, failure};
