import {memo, useCallback} from 'react';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {IconButton} from '@tehzor/ui-components';
import {deleteWorkAcceptance} from '@src/store/modules/entities/workAcceptance/actions';
import {IPreparedWorkAcceptance} from '@src/interfaces/IPreparedWorkAcceptance';
import {useTranslatedConfirmDialog} from '@src/core/hooks/translations/useTranslatedConfirmDialog';
import {useTranslation} from 'react-i18next';

interface ISelectedEntitiesDeletionProps {
	selectedEntities: IPreparedWorkAcceptance[];
	selectedClearing: () => void;
}

export const SelectedEntitiesDeletion = memo(
	({selectedEntities, selectedClearing}: ISelectedEntitiesDeletionProps) => {
		const {t} = useTranslation();
		const dispatch = useAppDispatch();
		const [deleteDialog, getDeleteConfirmation] = useTranslatedConfirmDialog({
			title: t('useConfirmDialog.selectedWorkAcceptances.deleteTitle'),
			message: t('useConfirmDialog.selectedWorkAcceptances.deleteMessage')
		});

		const handleClick = useCallback(async () => {
			if (await getDeleteConfirmation()) {
				for (const workAcceptance of selectedEntities) {
					await dispatch(
						deleteWorkAcceptance(workAcceptance?.object?.id || 'all', workAcceptance.id)
					);
				}
				selectedClearing();
			}
		}, [selectedEntities, selectedClearing, getDeleteConfirmation, dispatch]);

		return (
			<>
				<IconButton onClick={handleClick}>
					<i className="tz-delete" />
				</IconButton>

				{deleteDialog}
			</>
		);
	}
);
