import {useCallback, useState} from 'react';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {ActionButtons, Button, Dialog} from '@tehzor/ui-components';
import './AddingSpaceTypeDecorationDialog.less';
import {useEditableSpaceTypeDecoration} from '../EditableSpaceTypeDecoration/hooks/useEditableSpaceTypeDecoration';
import {useAddSpaceTypeDecorationSet} from '@src/core/hooks/mutations/spaceTypeDecorationSets/useAddSpaceTypeDecorationSet';
import {useAddSpaceTypeDecoration} from '@src/core/hooks/mutations/spaceTypeDecorations/useAddSpaceTypeDecoration';
import {useTranslatedConfirmDialog} from '@src/core/hooks/translations/useTranslatedConfirmDialog';

interface IAddingSpaceTypeDecorationDialogProps {
	isOpen: boolean;
	close(): void;
}

export const AddingSpaceTypeDecorationDialog = ({
	isOpen,
	close
}: IAddingSpaceTypeDecorationDialogProps) => {
	const dispatch = useAppDispatch();
	const [saving, setSaving] = useState(false);
	const {mutateAsync: addSpaceTypeDecoration} = useAddSpaceTypeDecoration();
	const {mutateAsync: addSpaceTypeDecorationSet} = useAddSpaceTypeDecorationSet();
	const [typeDecorationSetFields, typeDecorationFields, getSavingData, reset, isBlocking] =
		useEditableSpaceTypeDecoration({saving});

	const handleSave = useCallback(async () => {
		setSaving(true);
		const savingData = getSavingData();
		try {
			if (savingData && savingData.typeDecorationSet && savingData.typeDecoration) {
				const {typeDecorationSet, typeDecoration} = savingData;
				const result = await addSpaceTypeDecorationSet(typeDecorationSet);

				typeDecoration.forEach(t => {
					t.typeDecorationSetId = result.id;
				});
				const addParams = {
					typeDecorationSetId: result.id,
					items: typeDecoration
				};
				await addSpaceTypeDecoration(addParams);
			}
		} finally {
			setSaving(false);
			reset();
			close();
		}
	}, [getSavingData, addSpaceTypeDecoration, dispatch, close, reset, addSpaceTypeDecorationSet]);

	const [closingDialog, getClosingConfirmation] = useTranslatedConfirmDialog();

	const cancel = useCallback(async () => {
		if (!isBlocking || (await getClosingConfirmation())) {
			close();
		}
	}, [isBlocking, close, getClosingConfirmation]);

	return (
		<Dialog
			className={{
				root: 'adding-space-type-decoration',
				content: 'adding-space-type-decoration__container'
			}}
			isOpen={isOpen}
			title="Создать набор типов отделки"
			footer={
				<ActionButtons>
					<Button
						type="accent-blue"
						label="Сохранить"
						disabled={saving}
						onClick={handleSave}
					/>
					<Button
						type="cancel"
						label="Отменить"
						disabled={saving}
						onClick={cancel}
					/>
				</ActionButtons>
			}
			fullScreenOnTablet
			onRequestClose={cancel}
			onAfterClose={reset}
		>
			{typeDecorationSetFields}
			{typeDecorationFields}
			{closingDialog}
		</Dialog>
	);
};
