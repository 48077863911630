import {useMutation} from '@tanstack/react-query';
import {makeCheckExportRequest} from '@src/api/backend/check';
import {queryClient} from '@src/api/QueryClient';
import {documentsQueryKeys} from '@src/api/cache/documents/keys';
import {checksDocumentsStatsQueryKeys} from '@src/api/cache/checksDocumentsStats/keys';
import {addExportToast} from '@/shared/lib/Toasts/ExportToast/addExportToast';
import {addErrorToast, addWarningToast} from '@tehzor/ui-components';
import {checksQueryKeys} from '@src/api/cache/checks/keys';
import {useTranslation} from 'react-i18next';

interface IExportCheckParams {
	templateId: string;
	createDocument: boolean | undefined;
	email: string | undefined;
	objectId: string;
	checkId: string;
	problems?: string[];
	inspections?: string[];
}

export const useExportCheck = () => {
	const {t} = useTranslation();

	return useMutation({
		mutationKey: checksQueryKeys.exportOne(),
		mutationFn: (params: IExportCheckParams) => {
			const {templateId, createDocument, email, objectId, checkId, problems, inspections} =
				params;
			return makeCheckExportRequest(
				templateId,
				createDocument,
				email,
				objectId,
				checkId,
				problems,
				inspections
			);
		},
		onSuccess: (data, params) => {
			if (data.warnings?.itemsLimit !== undefined) {
				addWarningToast({
					title: `${t('toast.export.warning.title')} (${String(
						data.warnings.itemsLimit
					)})`
				});
			}
			if (data.link) {
				addExportToast({
					title: params.email
						? t('toast.export.send.success.title')
						: t('toast.export.success.title'),
					url: data.link,
					fileName: data.file.name,
					document: data.document
				});
			}
			void queryClient.invalidateQueries({queryKey: documentsQueryKeys.lists()});
			void queryClient.invalidateQueries({queryKey: checksDocumentsStatsQueryKeys.lists()});
		},
		onError: error => {
			addErrorToast({title: t('toast.export.error.title')});
			console.error('export error:', error);
		}
	});
};
