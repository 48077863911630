import {IconButton} from '@tehzor/ui-components';
import {IEnrichedTask} from '@tehzor/tools/interfaces/tasks/IEnrichedTask';
import {useDeleteTask} from '@src/core/hooks/mutations/task/useDeleteTask';
import {useTranslatedConfirmDialog} from '@src/core/hooks/translations/useTranslatedConfirmDialog';
import {useTranslation} from 'react-i18next';
import {useCallback} from 'react';

interface ISelectedEntitiesDeletionProps {
	selectedEntities: IEnrichedTask[];
	selectedClearing: () => void;
}

const deleteIcon = <i className="tz-delete" />;

const SelectedEntitiesDeletion = ({
	selectedEntities,
	selectedClearing
}: ISelectedEntitiesDeletionProps) => {
	const {t} = useTranslation();
	const {mutateAsync: deleteTask} = useDeleteTask();

	const [deleteDialog, getDeleteConfirmation] = useTranslatedConfirmDialog({
		title: t('useConfirmDialog.tasks.deleteTitle'),
		message: t('useConfirmDialog.tasks.deleteMessage')
	});

	const handleClick = useCallback(async () => {
		if (await getDeleteConfirmation()) {
			for (const task of selectedEntities) {
				const deleteTaskParams = {
					taskId: task.id,
					objectId: task.objectId,
					workAcceptanceId: task?.links?.workAcceptanceId
				};
				await deleteTask(deleteTaskParams);
			}
			selectedClearing();
		}
	}, [selectedEntities, selectedClearing, deleteTask, getDeleteConfirmation]);

	return (
		<>
			<IconButton onClick={handleClick}>{deleteIcon}</IconButton>

			{deleteDialog}
		</>
	);
};

export default SelectedEntitiesDeletion;
