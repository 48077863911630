import {httpRequests} from '@tehzor/tools/api/httpRequests';
import {IDocument} from '@tehzor/tools/interfaces/documents/IDocument';

export type IGetDocumentResponse = IDocument;

/**
 * Возвращает документ
 *
 * @param documentId id документа
 */
export const requestDocument = async (documentId: string) => {
	const response = await httpRequests.withToken.get<IGetDocumentResponse>(
		`documents/${documentId}`
	);
	return response.data;
};
