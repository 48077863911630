import {queryClient} from '@src/api/QueryClient';
import {objectsQueryKeys} from '@src/api/cache/objects/keys';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {deleteProblem} from '@src/store/modules/pages/problem/actions';
import {IProblem} from '@tehzor/tools/interfaces/problems/IProblem';
import {IconButton} from '@tehzor/ui-components';
import {memo} from 'react';
import {useTranslation} from 'react-i18next';
import {useTranslatedConfirmDialog} from '@src/core/hooks/translations/useTranslatedConfirmDialog';

interface ISelectedEntitiesDeletionProps {
	selectedEntities: IProblem[];
}
const deleteIcon = <i className="tz-delete" />;
const SelectedEntitiesDeletion = ({selectedEntities}: ISelectedEntitiesDeletionProps) => {
	const {t} = useTranslation();
	const dispatch = useAppDispatch();

	const [deleteDialog, getDeleteConfirmation] = useTranslatedConfirmDialog({
		title: t('checkListPage.selection.selectedEntitiesDeletion.confirmDialog.title'),
		message: t('checkListPage.selection.selectedEntitiesDeletion.confirmDialog.message')
	});

	const handleClick = async () => {
		if (await getDeleteConfirmation()) {
			const objectsIds: string[] = [];
			for (const problem of selectedEntities) {
				await dispatch(deleteProblem(problem.objectId, problem.id));
				objectsIds.push(problem.objectId);
			}
			await queryClient.refetchQueries({queryKey: [...objectsQueryKeys.stats(), objectsIds]});
		}
	};

	return (
		<>
			<IconButton onClick={handleClick}>{deleteIcon}</IconButton>
			{deleteDialog}
		</>
	);
};

export default memo(SelectedEntitiesDeletion);
