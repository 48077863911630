import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {IDelegationHistory} from '@tehzor/tools/interfaces/delegationHistories/IDelegationHistory';
import {WorkingGroupTypeId} from '@tehzor/tools/interfaces/workingGroups/IWorkingGroupType';
import {httpRequests} from '@tehzor/tools/api/httpRequests';

export type IGetDelegationHistoriesResponse = INormalizedData<IDelegationHistory>;

export interface IGetDelegationHistoriesParams {
	objectId?: string;
	workingGroupType?: WorkingGroupTypeId;
	taskId?: string;
	inspectionId?: string;
	problemId?: string;
	workAcceptanceId?: string;
}

/**
 * Получает записи из истории делегирования сущности (задача, осмотр, нарушение, приемка работ)
 *
 * @param params { id объекта, id сущности, тип рабочей группы }
 */
export const requestDelegationHistories = async (params: IGetDelegationHistoriesParams) => {
	const response = await httpRequests.withToken.get<IGetDelegationHistoriesResponse>(
		'delegation-histories',
		{params}
	);

	return response.data;
};
