import {useMemo} from 'react';
import {
	Plate,
	EditorInfo,
	EntityGrid,
	EntityGridItem,
	InlineButton,
	StatusSelect
} from '@tehzor/ui-components';
import useAsyncFn from 'react-use/lib/useAsyncFn';
import SpaceOwners from './SpaceOwners';
import Documents from './Documents';
import {IOwnerAcceptance} from '@tehzor/tools/interfaces/ownerAcceptances/IOwnerAcceptance';
import {OwnerAcceptanceStatusId} from '@tehzor/tools/interfaces/ownerAcceptances/IOwnerAcceptanceStatus';
import {useOwnerAcceptancesPermissions} from '@src/core/hooks/permissions/useOwnerAcceptancesPermissions';
import {useEditableOwnerAcceptanceAttachmentsDialog} from '@src/components/EditableOwnerAcceptanceAttachmentsDialog/hooks/useEditableOwnerAcceptanceAttachmentsDialog';
import Resolution from './Resolution';
import {OwnerAcceptanceResolutionId} from '@tehzor/tools/interfaces/ownerAcceptances/IOwnerAcceptanceResolution';
import {useEditOwnerAcceptanceStatus} from '@src/core/hooks/mutations/ownerAcceptances/useEditOwnerAcceptanceStatus';
import {useOwnerAcceptancesResolutionsArray} from '@src/core/hooks/queries/ownerAcceptancesResolutions/hooks';
import {useOwnerAcceptancesStatuses} from '@src/core/hooks/queries/ownerAcceptancesStatuses/hooks';
import {useTranslation} from 'react-i18next';
import {useTranslatedDictionary} from '@src/core/hooks/translations/useTranslatedDictionary';
import {dictionaryKeys} from '@src/constants/translations/dictionaryKeys';
import {useTranslatedDictionaryArray} from '@src/core/hooks/translations/useTranslatedDictionaryArray';

interface IDesktopInfoProps {
	objectId: string;
	ownerAcceptance: IOwnerAcceptance;
}

const statusIcon = <i className="tz-status-20" />;
const editIcon = <i className="tz-calendar-20" />;
const documentIcon = <i className="tz-document-20" />;

const DesktopInfo = ({objectId, ownerAcceptance}: IDesktopInfoProps) => {
	const {t} = useTranslation();
	const {data: statuses} = useOwnerAcceptancesStatuses();
	const translatedStatuses = useTranslatedDictionary(
		dictionaryKeys.ownerAcceptanceStatuses,
		statuses
	);

	const {data: resolutions} = useOwnerAcceptancesResolutionsArray();
	const translatedResolutions = useTranslatedDictionaryArray(
		dictionaryKeys.ownerAcceptanceResolutions,
		resolutions
	);
	const permissions = useOwnerAcceptancesPermissions(objectId);

	const isStatusHidden = useMemo(
		() => ownerAcceptance.resolution === OwnerAcceptanceResolutionId.ACCEPTED,
		[ownerAcceptance.resolution]
	);

	// Диалог редактирования вложений
	const [editingAttachmentsDialog, openEditAttachmentsDialog] =
		useEditableOwnerAcceptanceAttachmentsDialog(objectId, ownerAcceptance.id, ownerAcceptance);

	const editOwnerAcceptanceStatus = useEditOwnerAcceptanceStatus();

	const [, handleChange] = useAsyncFn(
		async (v: OwnerAcceptanceStatusId) => {
			if (ownerAcceptance) {
				await editOwnerAcceptanceStatus({
					objectId,
					acceptanceId: ownerAcceptance.id,
					statusId: v
				});
			}
		},
		[objectId, ownerAcceptance]
	);
	return (
		<Plate className={{content: 'owner-acceptance-page__info-plate'}}>
			<EntityGrid withBorders>
				{!isStatusHidden && (
					<EntityGridItem
						label={t('ownerAcceptancePage.info.status.label')}
						icon={statusIcon}
						fullRow
					>
						<StatusSelect
							className="owner-acceptance-page__d-entities-status"
							statuses={translatedStatuses}
							label={t('ownerAcceptanceStatuses.not-choosen.label')}
							preLabel={t('ownerAcceptanceStatuses.not-choosen.prelabel')}
							disabled={!permissions.canEditStatus}
							value={ownerAcceptance?.status}
							onChange={handleChange}
						/>
					</EntityGridItem>
				)}

				<Resolution
					objectId={objectId}
					ownerAcceptanceId={ownerAcceptance.id}
					value={ownerAcceptance.resolution}
					resolutions={translatedResolutions}
					canEditResolution={permissions.canEdit}
				/>

				{ownerAcceptance?.spaceOwner ? (
					<SpaceOwners
						name={ownerAcceptance.spaceOwner.name}
						phone={ownerAcceptance.spaceOwner.phone}
						email={ownerAcceptance.spaceOwner.email}
					/>
				) : null}
				<EntityGridItem
					label={t('ownerAcceptancePage.info.formalNote.label')}
					icon={documentIcon}
					fullRow
					buttons={
						permissions.canEdit ? (
							<InlineButton
								className="owner-acceptance-page__documents-edit-icon"
								type="accent"
								leftIcon={<i className="tz-edit-16" />}
								onClick={openEditAttachmentsDialog}
							/>
						) : null
					}
				>
					<Documents ownerAcceptance={ownerAcceptance} />
				</EntityGridItem>
				<EditorInfo
					icon={editIcon}
					label={t('ownerAcceptancePage.info.created.label')}
					date={ownerAcceptance?.createdAt}
					user={ownerAcceptance?.createdBy}
					fullRow
				/>

				{ownerAcceptance?.createdAt !== ownerAcceptance?.modifiedAt && (
					<EditorInfo
						icon={editIcon}
						label={t('ownerAcceptancePage.info.updated.label')}
						date={ownerAcceptance?.modifiedAt}
						user={ownerAcceptance?.modifiedBy}
						fullRow
					/>
				)}
			</EntityGrid>
			{permissions.canEdit && editingAttachmentsDialog}
		</Plate>
	);
};

export default DesktopInfo;
