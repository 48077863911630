import {useState} from 'react';
import Button from '@tehzor/ui-components/src/components/buttons/Button';
import {requestDocumentLink} from '@src/api/backend/document';
import {formDocumentTitle} from '@tehzor/tools/utils/formDocumentTitle';
import {IDocument} from '@tehzor/tools/interfaces/documents/IDocument';

interface IDownloadButtonProps {
	document: IDocument;
}

export const DownloadButton = ({document: doc}: IDownloadButtonProps) => {
	const [isDownloading, setIsDownloading] = useState(false);

	const handleClick = async () => {
		const link = await requestDocumentLink(doc.id);

		// TODO Отрефакторить, вынести в отдельный компонент
		setIsDownloading(true);
		const xhr = new XMLHttpRequest();
		xhr.open('GET', link, true);
		xhr.responseType = 'blob';

		// xhr.addEventListener('progress', event => {
		// 	if (event.lengthComputable) {
		// 		const percentComplete = (event.loaded / event.total) * 100;
		// 		setDownloadProgress(percentComplete);
		// 	}
		// });

		xhr.onload = () => {
			if (xhr.status === 200) {
				const blob = xhr.response;
				const downloadUrl = URL.createObjectURL(blob);
				const a = document.createElement('a');
				a.href = downloadUrl;
				a.download = formDocumentTitle(doc);
				a.click();
			}
			setIsDownloading(false);
		};

		xhr.send();
	};

	return (
		<Button
			type="accent-blue"
			leftIcon={<i className="tz-export-20" />}
			label="Скачать"
			disabled={isDownloading}
			onClick={handleClick}
		/>
	);
};
