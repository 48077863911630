import {IState} from '@src/store/modules';
import checkExpiration from '@tehzor/tools/utils/checkExpiration';
import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {AppThunkAction} from '@src/store/interfaces/thunk';
import {ApiAction, createApiAction} from '@src/store/middlewares/api';
import {
	IGetInternalAcceptanceResponse,
	requestInternalAcceptance
} from '@src/api/backend/internalAcceptance';
import {addErrorToast} from '@tehzor/ui-components';
import {t} from 'i18next';

export type IGetInternalAcceptancePayload = IGetInternalAcceptanceResponse;

const request = (internalAcceptanceId: string) => ({
	type: types.GET_REQUEST,
	payload: {internalAcceptanceId}
});

const success = (response: IGetInternalAcceptanceResponse) => ({
	type: types.GET_SUCCESS,
	payload: response
});

const failure = (error: IError) => {
	if (error.statusCode !== 401) {
		addErrorToast({title: t('toast.getInternalAcceptance.error.title')});
	}
	return {
		type: types.GET_FAILURE,
		payload: error
	};
};

/**
 * Получает внутреннюю приёмку по Id
 */
export const getInternalAcceptance =
	(
		objectId: string,
		internalAcceptanceId: string
	): AppThunkAction<Promise<IGetInternalAcceptanceResponse>> =>
	(dispatch, getState) => {
		const state = getState();

		const apiAction = createApiAction<IGetInternalAcceptanceResponse>(
			() => request(internalAcceptanceId),
			success,
			failure,
			() => requestInternalAcceptance(objectId, internalAcceptanceId)
		);

		return state.entities.internalAcceptance.data?.id === internalAcceptanceId
			? dispatch(
					checkExpiration<IState, IGetInternalAcceptanceResponse, ApiAction>(
						s => s.entities.internalAcceptance,
						apiAction
					)
			  )
			: dispatch(apiAction);
	};
