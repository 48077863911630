import {makeOwnerAcptDeleteRequest} from '@src/api/backend/ownerAcceptance';
import {queryClient} from '@src/api/QueryClient';
import {ownerAcceptancesQueryKeys} from '@src/api/cache/ownerAcceptances/keys';
import {problemsQueryKeys} from '@src/api/cache/problems/keys';
import {spacesQueryKeys} from '@src/api/cache/spaces/keys';
import {addErrorToast} from '@tehzor/ui-components';
import {useMutation} from '@tanstack/react-query';
import {useTranslation} from 'react-i18next';

export interface IUseDeleteOwnerAcceptanceParams {
	objectId: string;
	acceptanceId: string;
}

export const useDeleteOwnerAcceptance = () => {
	const {t} = useTranslation();

	return useMutation({
		mutationKey: ownerAcceptancesQueryKeys.delete(),
		mutationFn: ({objectId, acceptanceId}: IUseDeleteOwnerAcceptanceParams) => {
			return makeOwnerAcptDeleteRequest(objectId, acceptanceId);
		},
		onSuccess: (_, {objectId, acceptanceId}) => {
			void queryClient.invalidateQueries({queryKey: ownerAcceptancesQueryKeys.list()});
			void queryClient.invalidateQueries({queryKey: ownerAcceptancesQueryKeys.latest()});
			void queryClient.invalidateQueries({queryKey: problemsQueryKeys.list()});
			void queryClient.invalidateQueries({
				queryKey: [...spacesQueryKeys.ownerAcceptances(), objectId]
			});

			void queryClient.removeQueries({
				queryKey: [...ownerAcceptancesQueryKeys.details(), objectId, acceptanceId]
			});
		},
		onError: () => {
			addErrorToast({
				title: t('toast.deleteOwnerAcceptance.error.title')
			});
		}
	});
};
