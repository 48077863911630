import {useChangePath} from '@src/core/hooks/useChangePath';
import {formWorkAcceptanceLink} from '@tehzor/tools/utils/links';
import {LinkButton} from '@tehzor/ui-components';
import {useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {useWorkAcceptance} from '@src/core/hooks/queries/workAcceptance';

const leftIcon = <i className="tz-external-link-16" />;

export const WorkAcceptanceLink = ({workAcceptanceId, objectId}: Record<string, string>) => {
	const {pushPath} = useChangePath();
	const {data: workAcceptance} = useWorkAcceptance(workAcceptanceId, objectId);
	const {t} = useTranslation();
	const handleClick = useCallback(() => {
		if (workAcceptance?.objectId) {
			pushPath(formWorkAcceptanceLink(workAcceptance?.objectId, workAcceptanceId));
		}
	}, [workAcceptanceId, workAcceptance?.objectId]);

	if (!workAcceptance || !workAcceptance?.objectId) {
		return null;
	}

	return (
		<LinkButton
			className="entity-links__link"
			label={`${t('problemPage.links.toWorkAcceptance.label')} №${workAcceptance.number}`}
			leftIcon={leftIcon}
			onClick={handleClick}
			type="filled"
		/>
	);
};
