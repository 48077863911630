import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {IEntityProblemsData} from '@tehzor/tools/interfaces/entititesComputedData/IEntityProblemsData';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {IProblemStatus, ProblemStatusId} from '@tehzor/tools/interfaces/problems/IProblemStatus';
import {IConvertedProblemsData} from '@tehzor/tools/interfaces/spaces/IConvertedProblemsData';
import {getProblemsDataByProcess} from '@src/pages/SpacesPage/utils/getProblemsDataByProcess';
import {ProcessIds} from '@tehzor/tools/interfaces/process/ProcessId';

export const convertProblemsData = (
	problems: IEntityProblemsData | undefined,
	problemStatuses: INormalizedData<IProblemStatus> | undefined,
	selectedStage?: ObjectStageIds,
	selectedProcess?: ProcessIds
): IConvertedProblemsData[] => {
	if (!problems) {
		return [];
	}

	let total = 0;
	const result = [];

	const stageProblems = getProblemsDataByProcess(problems, selectedStage, selectedProcess);

	if (stageProblems && problemStatuses) {
		for (const statusId of problemStatuses.allIds) {
			if (stageProblems.hasOwnProperty(statusId) && stageProblems[statusId].count > 0) {
				result.push({
					key: statusId,
					value: stageProblems[statusId].count,
					percent: 0,
					color: problemStatuses.byId[statusId]?.color || '#00000000',
					startPoint: 0,
					critical:
						(statusId as ProblemStatusId) !== ProblemStatusId.FIXED
							? stageProblems[statusId].critical
							: undefined
				});
				total += stageProblems[statusId].count;
			}
		}
		let offset = 0;
		for (const stat of result) {
			stat.percent = (stat.value / total) * 100;
			stat.startPoint = -offset;
			offset += stat.percent;
		}
	}
	return result;
};
