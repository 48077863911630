const domain = 'settings/pages/warrantyClaims';

export const CHANGE_FILTERS = `${domain}/filters/change`;
export const CLEAR_FILTERS = `${domain}/filters/clear`;
export const CHANGE_SORT = `${domain}/sort/change`;
export const CHANGE_PAGE_SIZE = `${domain}/pageSize/change`;

export const CHANGE_TABLE_SETTINGS = `${domain}/tableSettings/change`;
export const CHANGE_COLUMN_VISIBLE = `${domain}/tableSettings/visibility/change`;
export const SHOW_ALL_COLUMNS = `${domain}/tableSettings/visibility/show-all`;


