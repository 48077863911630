import {EntitiesFiltersProvider} from '@/shared/lib/EntitiesFilters/context/EntitiesFiltersProvider';
import './MobileEntitiesFilters.less';
import {ReactNode} from 'react';

interface IBaseFiltersState extends Record<string, unknown> {
	objects?: string[];
}

interface IMobileEntitiesFiltersProps<S extends IBaseFiltersState> {
	children?: ReactNode;
	objectId?: string;
	filters: S;
	fullClear?: boolean; // TODO Убрать после вынесения обязательного objectId в фильтрах вложенных объектов

	onApply: (filters: S) => void;
	onClear?: () => void;
}

export const MobileEntitiesFilters = <S extends IBaseFiltersState>(
	props: IMobileEntitiesFiltersProps<S>
) => <EntitiesFiltersProvider {...props}>{props.children}</EntitiesFiltersProvider>;
