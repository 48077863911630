import {IGetSpacesResponse} from '@src/api/backend/spaces';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {ISpaceIndicator} from '@tehzor/tools/interfaces/spaceIndicatorsSets/ISpaceIndicator';
import {ISpaceStatus} from '@tehzor/tools/interfaces/spaceStatuses/ISpaceStatus';
import {IEnrichedSpace} from '@tehzor/tools/interfaces/spaces/IEnrichedSpace';
import {IListSpace} from '@tehzor/tools/interfaces/spaces/IListSpace';

export const extractSpacesAsArray = (response: IGetSpacesResponse): IListSpace[] =>
	response.allIds.map((id: string) => response.byId[id]).sort((a, b) => +a.name - +b.name);

export const enrichSpaces = (
	spaces: IListSpace[],
	spaceStatuses: Record<string, ISpaceStatus>,
	spaceIndicators: Record<string, ISpaceIndicator>
): IEnrichedSpace[] =>
	spaces.map(space => {
		const {status, indicators, ...restData} = space;
		return {
			...restData,
			status: spaceStatuses[status],
			indicators: indicators?.map(id => spaceIndicators[id]).filter(item => item)
		};
	});

export const extractSpacesAsArrayWithFilteredByObject = (
	response: IGetSpacesResponse,
	indicators?: INormalizedData<ISpaceIndicator>,
	spaceStatuses?: INormalizedData<ISpaceStatus>
): IEnrichedSpace[] => {
	const spaces = extractSpacesAsArray(response);
	return spaces && spaceStatuses && indicators
		? enrichSpaces(spaces, spaceStatuses.byId, indicators.byId)
		: [];
};

export const extractSpacesData = (response: IGetSpacesResponse): Record<string, IListSpace> =>
	response.byId;
