import {IUserRole, UserRoleScopes} from '@tehzor/tools/interfaces/IUser';
import {ILinkedProblem} from '@tehzor/tools/interfaces/problems/ILinkedProblem';
import {findPermission} from '@tehzor/tools/utils/findPermission';
import {IPresaleCheck} from '@tehzor/tools/interfaces/presaleChecks/IPresaleCheck';

export const testPresaleCheckRestrictions = (
	userId: string,
	userRoles: IUserRole[],
	permissionId: string,
	objectId: string,
	createdBy?: IPresaleCheck['createdBy'],
	problems?: ILinkedProblem[]
) => {
	const p = findPermission(userRoles, permissionId, UserRoleScopes.OBJECT, objectId);
	if (!p) {
		return false;
	}
	if (!p.restrictions) {
		return true;
	}

	return (
		(!!p.restrictions.presaleChecksCreatedByUser && createdBy && userId === createdBy.id) ||
		(!!p.restrictions.presaleChecksProblemsHavePerformerUser &&
			problems &&
			problems.some(item => item.performers && item.performers.includes(userId))) ||
		(!!p.restrictions.presaleChecksProblemsHaveInspectorUser &&
			problems &&
			problems.some(item => item.inspectors && item.inspectors.includes(userId)))
	);
};
