import styles from '@src/components/editableFields/IconSelect/IconSelect.module.less';
import classNames from 'classnames';

interface IIconItemProps {
	icon: string;
	onSelect: (value: string) => void;
	isActive: boolean;
}

export const IconItem = ({icon, onSelect, isActive}: IIconItemProps) => {
	const handleClick = () => {
		onSelect(icon);
	};

	return (
		<div
			className={classNames(styles.iconItem, isActive && styles.iconItemActive)}
			onClick={handleClick}
		>
			<i
				className={classNames(icon, styles.icon)}
				key={icon}
			/>
		</div>
	);
};
