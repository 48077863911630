import {useMemo} from 'react';
import {IWorkingGroup} from '@tehzor/tools/interfaces/workingGroups/IWorkingGroup';
import {IResponsibilityRule} from '@tehzor/tools/interfaces/responsibilityRules/IResponsibilityRule';
import {IBriefUser} from '@tehzor/tools/interfaces/users/IBriefUser';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {IEnrichedWorkingGroup} from '@tehzor/tools/interfaces/workingGroups/IEnrichedWorkingGroup';
import {Tree} from 'array-to-tree';
import ICategory from '@tehzor/tools/interfaces/categories/ICategory';
import IPlan from '@tehzor/tools/interfaces/plans/IPlan';
import {sortBy} from 'lodash';
import {IStructure} from '@tehzor/tools/interfaces/structures/IStructure';
import {usePlans} from '@src/core/hooks/queries/plans/hooks';
import {ISpace} from '@tehzor/tools/interfaces/spaces/ISpace';
import {useUsers} from '@src/core/hooks/queries/users/hooks';
import {useCategories} from '@src/core/hooks/queries/categories/hook';
import {ISuggestions} from '@tehzor/tools/utils/responsibilityRules/ISuggestions';
import {useSpacesList} from '../../../core/hooks/queries/spaces/hooks';
import {useStructuresList} from '@/entities/Structures';

const enrichRules = (
	rules: IResponsibilityRule[] | undefined,
	categories: INormalizedData<ICategory> | undefined,
	plans: INormalizedData<IPlan> | undefined,
	structures: INormalizedData<IStructure> | undefined,
	spaces: INormalizedData<ISpace> | undefined
) =>
	rules?.reduce<GroupedRules>((prev, rule) => {
		if (!prev[rule.groupId]) {
			prev[rule.groupId] = [];
		}
		prev[rule.groupId].push({
			target: rule.target,
			categories: rule.categories?.reduce<string[]>((p, id) => {
				if (categories && categories.byId[id]) {
					p.push(categories.byId[id].name);
				}
				return p;
			}, []),
			plans: rule.plans?.reduce<string[]>((p, id) => {
				if (plans && plans.byId[id]) {
					p.push(plans.byId[id].name);
				}
				return p;
			}, []),
			structures: rule.structureIds?.reduce<string[]>((p, id) => {
				if (structures && structures.byId[id]) {
					p.push(structures.byId[id].name);
				}
				return p;
			}, []),
			spaces: rule.spaceIds?.reduce<string[]>((p, id) => {
				if (spaces && spaces.byId[id]) {
					p.push(spaces.byId[id].name);
				}
				return p;
			}, [])
		});
		return prev;
	}, {});

const filterBySuggestions = (groups: Array<Tree<IWorkingGroup>>, suggestions: ISuggestions) =>
	groups.reduce<Array<Tree<IWorkingGroup>>>((prev, group) => {
		if (suggestions[group.id]) {
			const {children, ...rest} = group;
			prev.push({
				...rest,
				children: children ? filterBySuggestions(children, suggestions) : undefined
			});
		}
		return prev;
	}, []);

type GroupedRules = Record<
	string,
	Array<{
		target: IResponsibilityRule['target'];
		categories?: string[];
		plans?: string[];
		structures?: string[];
		spaces?: string[];
	}>
>;

const enrichGroups = (
	groups: Array<Tree<IWorkingGroup>>,
	rules: GroupedRules | undefined,
	users?: INormalizedData<IBriefUser>
): IEnrichedWorkingGroup[] =>
	users
		? groups.map(group => {
				const {
					stages,
					type,
					leader,
					performers,
					createdAt,
					createdBy,
					modifiedAt,
					modifiedBy,
					children,
					...rest
				} = group;

				return {
					...rest,
					leader: users.byId[leader],
					performers: sortBy(
						performers?.map(userId => users.byId[userId]).filter(user => user),
						['displayName'],
						['asc']
					),
					rules: rules?.[group.id],
					children: children ? enrichGroups(children, rules, users) : undefined
				};
		  })
		: [];

interface IUseEnrichedGroupsProps {
	objectId: string;
	workingGroups: Array<Tree<IWorkingGroup>>;
	rules: IResponsibilityRule[];
	suggestions: ISuggestions;
	activeGroup?: string;
	useSuggestions?: boolean;
}

export function useEnrichedGroups({
	objectId,
	workingGroups,
	rules,
	suggestions,
	activeGroup,
	useSuggestions
}: IUseEnrichedGroupsProps) {
	const {data: users} = useUsers();
	const {data: categories} = useCategories();
	const {data: plans} = usePlans(objectId);
	const {data: structures} = useStructuresList(objectId);
	const spaces = useSpacesList(objectId);

	return useMemo(() => {
		const filteredRules = activeGroup
			? rules?.filter(rule => rule.groupId === activeGroup)
			: rules;
		const rulesByGroup = enrichRules(filteredRules, categories, plans, structures, spaces);
		const filteredGroups = useSuggestions
			? filterBySuggestions(workingGroups, suggestions)
			: workingGroups;
		const enrichedGroupsData = enrichGroups(filteredGroups, rulesByGroup, users);

		return enrichedGroupsData;
	}, [
		activeGroup,
		suggestions,
		workingGroups,
		rules,
		useSuggestions,
		users,
		categories,
		plans,
		structures,
		spaces
	]);
}
