import {useLocation} from 'react-router-dom';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {fieldsPagesLinks} from '@/shared/constants/fieldsPages/fieldsPagesLinks';

export const useFieldsPageNavigation = (): [(index: number) => void, number] => {
	const {pathname} = useLocation();
	const {pushPath} = useChangePath();

	const navigate = (index: number) => {
		const link = fieldsPagesLinks[index];
		pushPath(link.url);
	};

	const currentLinkIndex = fieldsPagesLinks.findIndex(link => link.url === pathname);

	return [navigate, currentLinkIndex];
};
