export type IBankAccountsListQueryKey = [] & {
	0: string;
};

export const bankAccountsQueryKeys = {
	all: () => ['bankAccounts'],
	one: () => ['bankAccount'],
	list: () => [...bankAccountsQueryKeys.all(), 'list'],
	localList: () => [...bankAccountsQueryKeys.all(), 'local-list'],
	details: () => [...bankAccountsQueryKeys.one(), 'detail'],
	savingData: (key: string) => [...bankAccountsQueryKeys.one(), 'saving-data', key],
	detail: (bankAccountId: string) => [...bankAccountsQueryKeys.one(), 'detail', bankAccountId],
	add: () => [...bankAccountsQueryKeys.one(), 'add'],
	edit: () => [...bankAccountsQueryKeys.one(), 'edit'],
	delete: () => [...bankAccountsQueryKeys.one(), 'delete']
};
