/**
 * Компонент с кнопками и чекбоксом модального окна с функцией быстрого добавления нарушений
 */

import useAppDispatch from '@src/core/hooks/useAppDispatch';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractFastAdding} from '@src/store/modules/settings/modalDialog/selectors';
import {ActionButtons, addToast, Button, Checkbox, ToastContainer} from '@tehzor/ui-components';
import {useIsDesktop} from '@tehzor/ui-components/src/utils/mediaQueries';
import {useTranslation} from 'react-i18next';
import './ModalDialogButtons.less';
import {useEffect, useState} from 'react';
import {useIsMutating} from '@tanstack/react-query';
import {checksQueryKeys} from '@src/api/cache/checks/keys';
import {internalAcceptancesQueryKeys} from '@src/api/cache/internalAcceptances/keys';
import {useOnlineManager} from '@src/core/hooks/useOnlineManager';
import {ButtonLabel} from './components/ButtonLabel';
import {modalDialogActions} from '@src/store/modules/settings/modalDialog/slice';

const FAST_ADDING_CONTAINER_ID = 'fast-adding-notification-container';

const savingIcon = <i className="tz-choice-circle-24 fast-adding-toast__icon" />;

interface IActionButtons {
	saving: boolean;
	handleCancel: () => void;
	handleSave: () => Promise<void>;
	isProblemType: boolean;
}

export const ModalDialogButtons = ({
	isProblemType,
	saving,
	handleCancel,
	handleSave
}: IActionButtons) => {
	const dispatch = useAppDispatch();
	const {changeFastAddingMode, resetFastAddingMode} = modalDialogActions;
	const {t} = useTranslation();
	const isOnline = useOnlineManager();
	const isDesktop = useIsDesktop();
	const fastAdding = useAppSelector(extractFastAdding);
	const [isDisabled, setIsDisabled] = useState(false);
	const [isNotificationShown, setIsNotificationShown] = useState(false);

	const isCheckCreating = !!useIsMutating({mutationKey: checksQueryKeys.add()});
	const isAcceptanceCreating = !!useIsMutating({mutationKey: internalAcceptancesQueryKeys.add()});

	const handleCheckboxChange = () => dispatch(changeFastAddingMode(!fastAdding));

	useEffect(() => {
		setIsDisabled((isAcceptanceCreating || isCheckCreating) && isOnline);
	}, [isCheckCreating, isAcceptanceCreating, isOnline]);

	useEffect(() => {
		if ((isAcceptanceCreating || isCheckCreating) && isOnline) {
			return;
		}
		if (saving && isProblemType) {
			setIsDisabled(true);
			setIsNotificationShown(true);
			setTimeout(() => {
				setIsDisabled(false);
				setIsNotificationShown(false);
			}, 3000);
		}
	}, [isCheckCreating, isAcceptanceCreating, saving, isOnline, isProblemType]);

	useEffect(() => {
		dispatch(resetFastAddingMode());
	}, [dispatch]);

	useEffect(() => {
		if (!isDesktop) return;

		if (saving && isProblemType && fastAdding) {
			addToast({
				description: isOnline
					? t('globalAddingEntityDialog.fastAddingProblem.onlineToast')
					: t('globalAddingEntityDialog.fastAddingProblem.offlineToast'),
				containerId: FAST_ADDING_CONTAINER_ID,
				icon: savingIcon,
				options: {
					autoClose: 2000,
					hideProgressBar: true,
					closeButton: false,
					className: 'fast-adding-toast'
				}
			});
		}
	}, [saving, isProblemType, fastAdding, isOnline, isDesktop, t]);

	return (
		<>
			<div
				className={
					isNotificationShown && !isDesktop
						? 'mobile-notification__visible'
						: 'mobile-notification'
				}
			>
				{isOnline
					? t('globalAddingEntityDialog.fastAddingProblem.onlineToast')
					: t('globalAddingEntityDialog.fastAddingProblem.offlineToast')}
			</div>
			<ActionButtons>
				{isProblemType ? (
					<Checkbox
						checked={fastAdding}
						onChange={handleCheckboxChange}
						className="custom-action-buttons__checkbox"
					>
						<span>{t('globalAddingEntityDialog.fastAddingProblem.checkbox')}</span>
					</Checkbox>
				) : null}
				{(isDesktop || !isProblemType) && (
					<Button
						type="cancel"
						label={t('cancelBtn.label')}
						disabled={saving}
						onClick={handleCancel}
						className="custom-action-buttons__button"
					/>
				)}
				<Button
					type="accent-blue"
					label={
						<ButtonLabel
							isDesktop={isDesktop}
							isProblemType={isProblemType}
							isFastProblemAddingActive={fastAdding}
						/>
					}
					className="custom-action-buttons__button"
					disabled={saving || isDisabled}
					onClick={handleSave}
				/>
			</ActionButtons>
			<ToastContainer
				containerId={FAST_ADDING_CONTAINER_ID}
				limit={1}
				withLayout
				layoutClassName="fast-adding-toast-layout"
				position="bottom-center"
			/>
		</>
	);
};
