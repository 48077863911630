import CellWrap from '@src/components/tableCells/CellWrap';
import {IProblemEntity} from '@src/interfaces/IProblemEntity';
import LocalMobileProblemCell from '../components/table/LocalMobileProblemCell';
import MobileProblemCell from '../components/table/MobileProblemCell';
import {IEntitySettings} from '@src/core/hooks/settings/useEntitySettings';
import {ColumnWithSettings} from '@src/core/hooks/settings/useColumnsWithSettings/interfaces';

export const getMobileColumns = (
	objectId: string,
	settings?: IEntitySettings,
	isNestedObjects?: boolean
): Array<ColumnWithSettings<IProblemEntity>> => [
	{
		id: 'object',
		Cell: CellWrap({
			problem: props =>
				MobileProblemCell({
					...props,
					settings,
					withObject: objectId === 'all' || isNestedObjects
				}),
			local_problem: props =>
				LocalMobileProblemCell({
					...props,
					settings,
					withObject: objectId === 'all' || isNestedObjects
				})
		}),
		className: 'problems-page__mobile-cell',
		customizeSettings: {
			controlled: false
		}
	}
];
