/**
 * Заменяет префикс "Точка" в переданном имени на переведенное имя точки.
 *
 * @param name - Оригинальное имя, которое может начинаться с "Точка".
 * @param translatedPoint - Переведенное имя, которое будет заменять префикс "Точка".
 * @returns Имя с префиксом "Точка", замененным на translatedPoint.
 */
export const replacePointName = (name: string, translatedPoint: string): string => {
	const regex = /^Точка/;
	if (regex.test(name)) {
		return name.replace(regex, translatedPoint);
	}
	return name || '';
};
