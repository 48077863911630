import * as React from 'react';
import {changeEntitiesVisibility} from '@src/store/modules/settings/pages/space/slice';
import VisibilityFilter from '@src/components/VisibilityFilter';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {useMemo} from 'react';
import {useTranslation} from 'react-i18next';

interface IEntitiesVisibilityProps {
	className?: string;
	style?: React.CSSProperties;
}

const EntitiesVisibility = ({className, style}: IEntitiesVisibilityProps) => {
	const {t} = useTranslation();
	const visibility = useAppSelector(s => s.settings.pages.internalAcceptance.entitiesVisibility);

	const visibilityTitles = useMemo(
		() => ({
			problems: t('internalAcceptancePage.visibilityFilter.problems'),
			inspections: t('internalAcceptancePage.visibilityFilter.inspections')
		}),
		[t]
	);

	return (
		<VisibilityFilter
			className={className}
			style={style}
			value={visibility}
			titlesMap={visibilityTitles}
			onChange={changeEntitiesVisibility}
		/>
	);
};

export default EntitiesVisibility;
