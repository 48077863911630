import {
	IMigrateCategoriesSetData,
	IMigrateCategoriesSetResponse,
	requestMigrateCategoriesSet
} from '@src/api/backend/categoriesSets/migrate';
import {useMutation} from '@tanstack/react-query';
import IError from '@tehzor/tools/interfaces/IError';
import {addErrorToast} from '@tehzor/ui-components';
import {useTranslation} from 'react-i18next';

export const useMigrateCategoriesSets = () => {
	const {t} = useTranslation();

	return useMutation<IMigrateCategoriesSetResponse, IError, IMigrateCategoriesSetData>({
		mutationFn: (params: IMigrateCategoriesSetData) => requestMigrateCategoriesSet(params),
		onError: () => {
			addErrorToast({title: t('toast.migrateCategoriesSets.error.title')});
		}
	});
};
