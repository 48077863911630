import {Column} from 'react-table';
import {IBuiltinField} from '@tehzor/tools/interfaces/fields';
import {MobileCell} from '../../ui/cells/MobileCell/MobileCell';

export const getMobileColumns = (): Array<Column<IBuiltinField>> => [
	{
		id: 'mobileCell',
		Cell: MobileCell
	}
];
