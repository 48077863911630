import {useMutation} from '@tanstack/react-query';
import {spaceStatusesSetsQueryKeys} from '@src/api/cache/spaceStatusesSets/keys';
import {queryClient} from '@src/api/QueryClient';
import {spacesQueryKeys} from '@src/api/cache/spaces/keys';
import {requestDeleteSpaceStatusesSet} from '@src/api/backend/spaceStatusesSets/delete';
import {addErrorToast} from '@tehzor/ui-components';
import {useTranslation} from 'react-i18next';

interface IUseDeleteSpaceStatusesSetsParams {
	id: string;
}

export const useDeleteSpaceStatusesSets = () => {
	const {t} = useTranslation();

	return useMutation({
		mutationKey: spaceStatusesSetsQueryKeys.delete(),
		mutationFn: (params: IUseDeleteSpaceStatusesSetsParams) => {
			const {id} = params;
			return requestDeleteSpaceStatusesSet(id);
		},
		onError: () => {
			addErrorToast({
				title: t('toast.deleteSpaceStatusesSet.error.title')
			});
		},
		onSuccess: () => {
			void queryClient.invalidateQueries({queryKey: spaceStatusesSetsQueryKeys.list()});
			void queryClient.invalidateQueries({queryKey: spacesQueryKeys.list()});
		}
	});
};
