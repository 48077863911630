import {IEditSpaceOwnerParams} from '@src/api/cache/spaceOwners/defaults/useSpaceOwnersMutationDefaults';
import {spaceOwnersQueryKeys} from '@src/api/cache/spaceOwners/keys';
import {useEditSpaceOwnerMutation} from '@src/api/cache/spaceOwners/mutations';
import {useQueryClient} from '@tanstack/react-query';
import {useCallback} from 'react';
import {ISavingSpaceOwnerQuery} from './useAddSpaceOwner';
import {ISpaceOwner} from '@tehzor/tools/interfaces/spaceOwners/ISpaceOwner';
import {useOnlineManager} from '../../useOnlineManager';
import useAppSelector from '../../useAppSelector';
import {extractOfflineModeAvailability} from '@src/store/modules/settings/offlineMode/selectors/availability';
import {updateCachedOwnersList} from './utils/updateCachedOwnersList';
import {updateSpaceOwnerMutationState} from './utils/updateSpaceOwnerMutationState';
import {updateCachedOwner} from './utils/updateCachedOwner';
import {addWarningToast} from '@tehzor/ui-components';
import {useTranslation} from 'react-i18next';

export interface ICachedSpaceOwner extends Omit<ISavingSpaceOwnerQuery, 'savingData'> {
	savingData: ISpaceOwner;
}

export const useEditSpaceOwner = () => {
	const {t} = useTranslation();
	const queryClient = useQueryClient();
	const online = useOnlineManager();
	const mutation = useEditSpaceOwnerMutation();
	const offlineModeIsAvailable = useAppSelector(extractOfflineModeAvailability);

	const editSpaceOwner = useCallback(
		(params: IEditSpaceOwnerParams) => {
			const {spaceOwnerId, fields} = params;

			const payloadToSave = queryClient.getQueryData(
				spaceOwnersQueryKeys.detail(spaceOwnerId)
			);

			let newSpaceOwner: ICachedSpaceOwner | undefined;
			// Проверяем локальный собственник или нет
			if (payloadToSave) {
				// Если собственник локальный
				newSpaceOwner = updateCachedOwner(spaceOwnerId, fields);
				void queryClient.invalidateQueries({
					queryKey: spaceOwnersQueryKeys.localList()
				});

				// Ищем текущую мутацию на добавление и меняем ее
				updateSpaceOwnerMutationState(
					spaceOwnersQueryKeys.add,
					spaceOwnerId,
					newSpaceOwner?.savingData
				);

				// Если оффлайн, то нужно обновить кеш новыми данными
				if (!online && newSpaceOwner?.savingData) {
					const {savingData} = newSpaceOwner;
					queryClient.setQueryData<ICachedSpaceOwner>(
						spaceOwnersQueryKeys.detail(spaceOwnerId),
						data => {
							if (data) {
								return {...data, savingData};
							}
							return data;
						}
					);
					addWarningToast({title: t('toast.editSpaceOwnerLocal.warning.title')});
				}
			}

			// Если собственник не локальный
			if (!payloadToSave) {
				// Если мы оффлан редактируем имеющегося собственника, то надо модифицировать кеш
				let mutationExist = false;
				if (!online && offlineModeIsAvailable) {
					updateCachedOwnersList(spaceOwnerId, fields);

					// И если есть существующая мутация на редактрование, нужно её обновить
					mutationExist = updateSpaceOwnerMutationState(
						spaceOwnersQueryKeys.edit,
						spaceOwnerId,
						fields as ISpaceOwner
					);
				}
				// Иначе просто вызываем мутацию
				if (!mutationExist) {
					mutation.mutate({
						...params
					});
				}
			}
		},
		[mutation, offlineModeIsAvailable, online, queryClient]
	);
	return editSpaceOwner;
};
