/* eslint-disable no-undef */
export const config = {
	appId: 'web_client',
	appSecret: 'a202a2fab46598599dc7108bb991d404',
	appUrl: APP_URL,
	appName: APP_NAME_CLIENT,
	appVersion: APP_VERSION,
	apiUrl: API_URL,
	newApiUrl: NEW_API_URL,
	newWsApiUrl: NEW_WS_API_URL,
	filesStorageUrl: FILE_STORAGE_URL,
	authProviders: AUTH_PROVIDERS,
	entitiesCacheTime: 300,
	problemDefaultFixTime: 1080,
	regStandardExternalLink:
		'https://www.faufcc.ru/technical-regulation-in-constuction/formulary-list/?s='
};

export default config;
