import {memo, useMemo} from 'react';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {findPermission} from '@tehzor/tools/utils/findPermission';
import {UserRoleScopes} from '@tehzor/tools/interfaces/IUser';
import {StatusButton} from '@tehzor/ui-components';
import {ProblemStatusId} from '@tehzor/tools/interfaces/problems/IProblemStatus';
import {useProblemStatusesMap} from '@src/core/hooks/queries/problemStatuses/hooks';
import {useTranslation} from 'react-i18next';

interface IStatusTransitionProps {
	objectId: string;
	commentId?: string;
}

const StatusTransition = ({objectId, commentId}: IStatusTransitionProps) => {
	const user = useAppSelector(s => s.auth.profile);
	const {data: statuses} = useProblemStatusesMap();
	const {t} = useTranslation();
	const currentStatusId = useAppSelector(s => s.pages.problem.data?.status);
	const futureStatusId = useMemo(() => {
		if (user?.roles) {
			const p = findPermission(
				user.roles,
				commentId ? 'repliesEdit' : 'repliesAdd',
				UserRoleScopes.OBJECT,
				objectId
			);
			return (p?.params as {problemStatusTransition?: ProblemStatusId})
				?.problemStatusTransition;
		}
		return undefined;
	}, [objectId, commentId, user]);

	if (
		futureStatusId &&
		currentStatusId &&
		statuses &&
		statuses[currentStatusId]?.transitions?.includes(futureStatusId)
	) {
		return (
			<div className="editable-reply__status-transition">
				<div className="editable-reply__status-transition-label">
					{t('component.editableProblemReply.dialog.notification')}
				</div>

				<StatusButton
					className="editable-reply__status-transition-btn"
					label={t(`problemStatuses.${statuses[futureStatusId].id}`)}
					color={statuses[futureStatusId].color}
					disabled
				/>
			</div>
		);
	}
	return null;
};

export default memo(StatusTransition);
