import {CellProps} from 'react-table';
import {IPresaleCheckEntity} from '@src/interfaces/IPresaleCheckEntity';
import {useUsersAsMap} from '@src/core/hooks/queries/users/hooks';
import {useWorkingGroupsAsMap} from '@src/core/hooks/queries/workingGroups/hooks';
import styles from '../../PresaleCheckEntitiesTable.module.less';

export const PerformersCell = ({row}: CellProps<IPresaleCheckEntity>) => {
	const {data: usersMap} = useUsersAsMap();
	const {data: groupsMap} = useWorkingGroupsAsMap();

	const {performers, performersActiveGroup} = row.original.data;

	return (
		<div className={styles.dTablePerformers}>
			{performersActiveGroup && groupsMap && (
				<div className={styles.group}>{groupsMap[performersActiveGroup]?.name}</div>
			)}
			{performers?.[0] && usersMap && (
				<div className={styles.user}>
					<div className={styles.name}>{usersMap[performers?.[0]]?.fullName}</div>
					<div className={styles.company}>
						{usersMap[performers?.[0]]?.companies?.[0]?.name}
					</div>
				</div>
			)}
			{!!performers?.length && performers?.length > 1 && (
				<div className={styles.rest}>+{performers.length - 1}</div>
			)}
		</div>
	);
};
