import {
	IOwnerAcceptancesFiltersState,
	IOwnerAcceptancesSortState
} from '@src/store/modules/settings/pages/ownerAcceptances/interfaces';

export type IOwnerAcceptancesListQueryKey = [] & {
	0: string;
	1: string;
	2: string;
	3: IOwnerAcceptancesFiltersState;
	4: IOwnerAcceptancesSortState;
	5: number;
	6: number;
};

export type IOwnerAcceptancesDetailsQueryKey = [] & {
	0: string;
	1: string;
	2: string;
	3: string;
};

export type IOwnerAcceptanceProblemsQueryKey = [] & {
	0: string; // owner-acceptance
	1: string; // problems
	2: string; // objectId
	3: string; // acceptanceId
};

export const ownerAcceptancesQueryKeys = {
	all: () => ['owner-acceptances'],
	one: () => ['owner-acceptance'],
	latest: () => [...ownerAcceptancesQueryKeys.all(), 'latest'],
	list: () => [...ownerAcceptancesQueryKeys.all(), 'list'],
	listWithObjectId: (objectId = 'all') => [...ownerAcceptancesQueryKeys.list(), objectId],
	localList: () => [...ownerAcceptancesQueryKeys.all(), 'local-list'],
	add: () => [...ownerAcceptancesQueryKeys.one(), 'add'],
	edit: () => [...ownerAcceptancesQueryKeys.one(), 'edit'],
	editStatus: () => [...ownerAcceptancesQueryKeys.one(), 'editStatus'],
	details: () => [...ownerAcceptancesQueryKeys.one(), 'detail'],
	detail: (acceptanceId?: string, objectId?: string) => [
		...ownerAcceptancesQueryKeys.details(),
		acceptanceId,
		objectId
	],
	savingData: (id: string) => [...ownerAcceptancesQueryKeys.one(), 'saving-data', id],
	savingDatas: () => [...ownerAcceptancesQueryKeys.one(), 'saving-data'],
	problems: () => [...ownerAcceptancesQueryKeys.one(), 'problems'],
	acceptanceProblems: (objectId: string, acceptanceId: string) => [
		...ownerAcceptancesQueryKeys.problems(),
		objectId,
		acceptanceId
	],
	export: () => [...ownerAcceptancesQueryKeys.all(), 'export'],
	exportOne: () => [...ownerAcceptancesQueryKeys.one(), 'export'],
	delete: () => [...ownerAcceptancesQueryKeys.one(), 'delete']
};
