import {IPresaleCheck} from '@tehzor/tools/interfaces/presaleChecks/IPresaleCheck';
import {httpRequests} from '@tehzor/tools/api/httpRequests';

/**
 * Возвращает предпродажную проверку
 *
 * @param presaleCheckId id проверки
 */

export const requestGetPresaleCheck = async (presaleCheckId: string) => {
	const response = await httpRequests.withToken.get<IPresaleCheck>(
		`presale-checks/${presaleCheckId}`
	);
	return response.data;
};
