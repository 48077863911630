import {memo} from 'react';
import useScrollRestoration from '@src/core/hooks/useScrollRestoration';
import {addErrorToast, LoadingPanel} from '@tehzor/ui-components';
import AppBreadcrumbs from '@src/components/AppBreadcrumbs';
import {AddButton} from './components/actions/AddButton';
import {IBreadcrumb} from '@tehzor/ui-components/src/components/navigation/Breadcrumbs';
import {useLegalsBreadcrumbs} from '@src/core/hooks/breadcrumbs/useLegalsBreadcrumbs';
import {useLegalsPermissions} from '@src/core/hooks/permissions/useLegalsPermissions';
import {useLegals, useLegalsData} from '@src/core/hooks/queries/legals';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractLegalsPageSettings} from '@src/store/modules/settings/pages/legals/selectors';
import {LegalsFilters} from '@src/pages/LegalsPage/components/LegalsFilters';
import {LegalsPageHeader} from '@src/pages/LegalsPage/components/LegalsPageHeader';
import {LegalsTable} from '@src/pages/LegalsPage/components/table/LegalsTable';
import {useIsFetching} from '@tanstack/react-query';
import {legalsQueryKeys} from '@src/api/cache/legals/keys';
import './LegalsPage.less';
import {useTranslation} from 'react-i18next';

export const LegalsPage = memo(() => {
	useScrollRestoration();
	const {t} = useTranslation();

	const {canView, canAdd} = useLegalsPermissions();

	const settings = useAppSelector(extractLegalsPageSettings);

	const breadcrumbs: IBreadcrumb[] = useLegalsBreadcrumbs();

	const legals = useLegals();
	const legalsData = useLegalsData(settings, legals);

	const isFetching = !!useIsFetching({queryKey: legalsQueryKeys.list()});

	if (!canView) {
		addErrorToast({title: t('toast.canView.error.title')});
		return null;
	}

	return (
		<LoadingPanel active={isFetching}>
			<div className="legals">
				<div className="legals__breadcrumbs-wrapper">
					<AppBreadcrumbs
						className="legals__breadcrumbs"
						items={breadcrumbs}
					>
						<AddButton canAdd={canAdd} />
					</AppBreadcrumbs>
					<div className="legals__page-header-wrapper">
						<LegalsFilters />
						<LegalsPageHeader total={legalsData.total} />
					</div>
				</div>
				<LegalsTable
					settings={settings}
					{...legalsData}
				/>
			</div>
		</LoadingPanel>
	);
});
