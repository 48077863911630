import {fieldsPageBreadcrumb} from '@/shared/constants/fieldsPages/fieldsPageBreadcrumb';
import {Button} from '@tehzor/ui-components';
import {TopBar} from '@/shared/ui/EntitiesPage/TopBar/TopBar';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {useEditingCustomFieldDialog} from '@/features/Fields';
import {useFieldsPageNavigation} from '@/pages/FieldsPage/model/hooks/useFieldsPageNavigation';
import {useCallback} from 'react';

const iconPlus = <i className="tz-plus-20" />;

export const FieldsTopBar = () => {
	const {pushPath} = useChangePath();
	const [, currentLinkIndex] = useFieldsPageNavigation();

	const toSettingsSet = () => {
		pushPath('/manage/fields/fields-settings-set/add');
	};

	const [dialog, open] = useEditingCustomFieldDialog({});

	const handleAddCustomField = useCallback(() => {
		open();
	}, [open]);

	return (
		<TopBar breadcrumbs={[fieldsPageBreadcrumb]}>
			{currentLinkIndex === 0 && (
				<Button
					type="accent-blue"
					leftIcon={iconPlus}
					label="Добавить набор"
					onClick={toSettingsSet}
				/>
			)}
			{currentLinkIndex === 1 && (
				<Button
					type="accent-blue"
					leftIcon={iconPlus}
					label="Добавить поле"
					onClick={handleAddCustomField}
				/>
			)}
			{dialog}
		</TopBar>
	);
};
