import useAppSelector from '@src/core/hooks/useAppSelector';
import {structuresActions} from '@/entities/Structures';
import {useCallback} from 'react';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {SortChanger} from '@tehzor/ui-components';
import {extractStructuresSchemaPageSettings} from '@/entities/Structures/model/slice/selectors';
import {useTargetObjectIdsHaveStructures} from '@src/core/hooks/queries/objects/hooks';

interface ISchemaSortChangerProps {
	objectId: string;
}

export const SchemaSortChanger = ({objectId}: ISchemaSortChangerProps) => {
	const dispatch = useAppDispatch();
	const {changeSchemaSortRevers} = structuresActions;
	const {schemaSortRevers, schemaStructureTypeId} = useAppSelector(s =>
		extractStructuresSchemaPageSettings(s, objectId)
	);
	const targetObjects = useTargetObjectIdsHaveStructures({
		objectId,
		filter: schemaStructureTypeId ? {type: schemaStructureTypeId} : undefined
	});

	const changeSortReversHandler = useCallback(
		(value: boolean) => {
			dispatch(
				changeSchemaSortRevers({
					objectsIds: [...targetObjects, objectId],
					revers: value
				})
			);
		},
		[objectId, dispatch, changeSchemaSortRevers, targetObjects]
	);

	return (
		<SortChanger
			isRevers={schemaSortRevers}
			onChange={changeSortReversHandler}
		/>
	);
};
