import AppBreadcrumbs from '@src/components/AppBreadcrumbs';
import {useAppHeader} from '@src/components/AppHeader/hooks/useAppHeader';
import {useEditableContractDialog} from '@src/components/EditableContractDialog/hooks/useEditableContractDialog';
import {useContractsBreadcrumbs} from '@src/core/hooks/breadcrumbs/useContractsBreadcrumbs';
import {useContractsPermissions} from '@src/core/hooks/permissions/useContractsPermissions';
import {useAuthorizedPersonsMap} from '@src/core/hooks/queries/authorizedPersons';
import {useBankAccountsMap} from '@src/core/hooks/queries/bankAccounts';
import {useCompaniesAsArray} from '@src/core/hooks/queries/companies/hooks';
import {useContract} from '@src/core/hooks/queries/contracts/hooks';
import {useLegalsMap} from '@src/core/hooks/queries/legals';
import useScrollRestoration from '@src/core/hooks/useScrollRestoration';
import {addErrorToast, LoadingPanel} from '@tehzor/ui-components';
import {useIsLargeTablet} from '@tehzor/ui-components/src/utils/mediaQueries';
import {useEffect, useMemo} from 'react';
import {useParams} from 'react-router-dom';
import './ContractPage.less';
import {ContractDesktopInfo} from './components/ContractInfo.desktop';
import {ContractMobileInfo} from './components/ContractInfo.mobile';
import {ContractMain} from './components/ContractMain';
import {Menu} from './components/actions/Menu';
import {DesktopActions} from './components/actions/desktop.actions';
import {convertContract} from './utils/convertContract';
import {useTranslation} from 'react-i18next';

export const ContractPage = () => {
	useScrollRestoration();
	const {t} = useTranslation();

	const {contractId} = useParams<{contractId?: string}>();
	const {data: contract, isFetching} = useContract(contractId);

	const breadcrumbs = useContractsBreadcrumbs(contract);
	const isLargeTablet = useIsLargeTablet();

	const {data: companies} = useCompaniesAsArray();
	const legalsMap = useLegalsMap();
	const bankAccountsMap = useBankAccountsMap();
	const authorizedPersonsMap = useAuthorizedPersonsMap();
	const preparedContract = useMemo(
		() =>
			convertContract(contract, legalsMap, bankAccountsMap, authorizedPersonsMap, companies),
		[contract, companies, legalsMap, bankAccountsMap, authorizedPersonsMap]
	);

	const [contractDialog, openEditDialog] = useEditableContractDialog(contract);

	const permissions = useContractsPermissions();
	const {canAdministrate} = permissions;
	useEffect(() => {
		if (!canAdministrate) {
			addErrorToast({title: t('toast.canView.error.title')});
		}
	}, [canAdministrate]);

	useAppHeader(
		{
			title: preparedContract ? preparedContract.name : '',
			showBackBtn: true,
			mobileRightButtons: preparedContract ? (
				<Menu
					contract={preparedContract}
					permissions={permissions}
					openEditDialog={openEditDialog}
				/>
			) : null
		},
		[preparedContract]
	);

	if (!canAdministrate) {
		return null;
	}
	return (
		<LoadingPanel
			className="contract__loading-panel"
			active={isFetching}
		>
			{preparedContract && (
				<div className="contract">
					<AppBreadcrumbs
						className="contract__breadcrumbs"
						items={breadcrumbs}
					>
						{isLargeTablet && (
							<DesktopActions
								contract={preparedContract}
								permissions={permissions}
								openEditDialog={openEditDialog}
							/>
						)}
					</AppBreadcrumbs>
					<div className="contract__wrap">
						{isLargeTablet ? (
							<ContractDesktopInfo contract={preparedContract} />
						) : (
							<ContractMobileInfo contract={preparedContract} />
						)}
						<ContractMain contract={preparedContract} />
					</div>
					{permissions.canEdit && contractDialog}
				</div>
			)}
		</LoadingPanel>
	);
};
