import {useCallback, useEffect, useState, ReactNode, useMemo} from 'react';
import {
	convertToSave as convertFilesToSave,
	isEdited as isFilesExist,
	someFilesHaveError,
	useUploadingFilesState
} from '@src/core/hooks/states/useUploadingFilesState';
import useUpdateEffect from 'react-use/lib/useUpdateEffect';
import {
	useEditablePresaleCheckState,
	convertToSave,
	hasAttachmentsError,
	isEdited,
	errorsFns
} from '../../model/hooks/useEditablePresaleCheckState';
import {EditablePresaleCheckAttachments} from '../../components/EditablePresaleCheckAttachments';
import {hasErrors} from '@tehzor/tools/core/states/editableEntityState';
import {useTranslation} from 'react-i18next';
import {useNavigationPrevent} from '@src/core/hooks/useNavigationPrevent';
import {ISavingPresaleCheck} from '@src/interfaces/saving/ISavingPresaleCheck';
import {IPresaleCheck} from '@tehzor/tools/interfaces/presaleChecks/IPresaleCheck';

interface UseEditablePresaleCheckAttachmentsResult {
	fields: ReactNode;
	getSavingData: () => Promise<ISavingPresaleCheck | undefined>;
	reset: () => void;
	isBlocking: boolean;
	isSaveDisabled: boolean;
}

const fieldsSettings = {
	description: {fieldId: 'description', isRequired: false},
	attachments: {fieldId: 'attachments', isRequired: false}
};

/**
 * Логика редактирования вложений
 */
export const useEditablePresaleCheckAttachments = (
	objectId: string,
	presaleCheck: IPresaleCheck,
	saving: boolean
): UseEditablePresaleCheckAttachmentsResult => {
	const [editingState, editingDispatch] = useEditablePresaleCheckState(presaleCheck);
	const [uploadingFilesState, uploadingFilesDispatch, waitUploading] = useUploadingFilesState();
	const [isBlocking, setIsBlocking] = useState(false);
	const {t} = useTranslation();

	useNavigationPrevent(isBlocking);

	useEffect(() => {
		setIsBlocking(
			isEdited(editingState, presaleCheck) || isFilesExist(uploadingFilesState.value)
		);
	}, [editingState, uploadingFilesState.value, presaleCheck]);

	const getSavingData = useCallback(async () => {
		const files = await waitUploading();
		// Проверка наличия ошибок в состояниях
		if (
			hasErrors(editingState, errorsFns, fieldsSettings) ||
			(hasAttachmentsError(editingState, fieldsSettings) && !isFilesExist(files))
		) {
			editingDispatch({type: 'update-errors'});
			uploadingFilesDispatch({type: 'update-error'});
			return undefined;
		}
		// Проверка, были ли отредактированы поля
		if (
			!isEdited(editingState, presaleCheck) &&
			(!isFilesExist(files) || someFilesHaveError(files))
		) {
			return undefined;
		}
		const savingData = convertToSave(editingState, presaleCheck, true);
		savingData.newAttachments = convertFilesToSave(files);
		return savingData;
	}, [editingState, objectId, presaleCheck, fieldsSettings]);

	const reset = useCallback(() => {
		editingDispatch({
			type: 'reset',
			entity: presaleCheck
		});
		uploadingFilesDispatch({type: 'reset'});
	}, [presaleCheck]);

	// Сброс данных для редактирования при изменении начальных данных
	useUpdateEffect(reset, [presaleCheck]);

	const fields = (
		<EditablePresaleCheckAttachments
			editingState={editingState}
			editingDispatch={editingDispatch}
			uploadingFilesState={uploadingFilesState}
			uploadingFilesDispatch={uploadingFilesDispatch}
			saving={saving}
			label={t('editablePresaleCheck.attachments.label')}
		/>
	);

	const isSaveDisabled = useMemo(
		() =>
			!isEdited(editingState, presaleCheck) &&
			(!isFilesExist(uploadingFilesState.value) ||
				someFilesHaveError(uploadingFilesState.value)),
		[editingState, presaleCheck, uploadingFilesState.value]
	);

	return {fields, getSavingData, reset, isBlocking, isSaveDisabled};
};
