import {Locale} from 'date-fns';

import {ru, enUS, kk, tr} from 'date-fns/locale';
import {useTranslation} from 'react-i18next';

interface ILocales {
	[key: string]: Locale;
}

export const useDateFnsLocale = () => {
	const {i18n} = useTranslation();
	const locales: ILocales = {
		ru,
		en: enUS,
		kk,
		tr
	};
	return locales[i18n.language];
};
