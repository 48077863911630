import {useState} from 'react';
import {PresaleCheckInfoMobile} from '../PresaleCheckInfoMobile';
import {useAppHeader} from '@src/components/AppHeader/hooks/useAppHeader';
import {MobileSelectionClearing} from '@/features/PresaleCheck';
import {RightButtonsMobile} from '@/features/PresaleCheck';
import {Plate} from '@tehzor/ui-components';
import {EntitiesVisibility} from '../../components/EntitiesVisibility';
import {EntityAdding} from '@/features/PresaleCheck';
import {useTranslation} from 'react-i18next';
import {IPresaleCheck} from '@tehzor/tools/interfaces/presaleChecks/IPresaleCheck';
import {PresaleCheckEntitiesTable} from '@/widgets/PresaleCheckEntitiesTable';
import {usePresaleCheckEntitiesColumns} from '@/widgets/PresaleCheckEntitiesTable/hooks/usePresaleCheckEntitiesColumns';
import {PageBreadcrumbs} from '../../components/PageBreadcrumbs';
import styles from '../PresaleCheckPage.module.less';
import classNames from 'classnames';

interface IMobileProps {
	objectId: string;
	presaleCheckId: string;
	presaleCheck?: IPresaleCheck;
}

export const PresaleCheckPageMobile = ({objectId, presaleCheckId, presaleCheck}: IMobileProps) => {
	const [selectedRows, setSelectedRows] = useState<string[]>([]);
	const {t} = useTranslation();
	const columns = usePresaleCheckEntitiesColumns({isDesktop: false, objectId, presaleCheckId});
	useAppHeader(
		{
			title: presaleCheck
				? t('presaleChecksPage.header.title', {number: presaleCheck.number})
				: '',
			showBackBtn: true,
			mobileLeftButton:
				Object.keys(selectedRows).length > 0 ? (
					<MobileSelectionClearing onSelectedRowsChange={setSelectedRows} />
				) : undefined,
			mobileRightButtons: (
				<RightButtonsMobile
					objectId={objectId}
					presaleCheckId={presaleCheckId}
					presaleCheck={presaleCheck}
					selectedRows={selectedRows}
				/>
			)
		},
		[presaleCheck, selectedRows]
	);

	if (!presaleCheck) return null;

	return (
		<div className={classNames('page-cont', styles.presaleCheckPage)}>
			<PageBreadcrumbs
				objectId={objectId}
				presaleCheck={presaleCheck}
			/>
			<PresaleCheckInfoMobile
				objectId={objectId}
				presaleCheck={presaleCheck}
			/>

			<EntitiesVisibility className={styles.mVisibilityFilter} />

			<Plate withoutPadding>
				<PresaleCheckEntitiesTable
					objectId={objectId}
					columns={columns}
					hideHead
					selectedRows={selectedRows}
					onSelectedRowsChange={setSelectedRows}
					presaleCheckId={presaleCheckId}
				/>
			</Plate>

			<EntityAdding
				objectId={objectId}
				presaleCheck={presaleCheck}
			/>
		</div>
	);
};
