import {useAppHeader} from '@src/components/AppHeader/hooks/useAppHeader';
import {PageCounter} from '@/shared/ui/PageCounter/PageCounter';
import styles from './BuiltInFieldsPage.module.less';
import {fieldsPagesLinks} from '@/shared/constants/fieldsPages/fieldsPagesLinks';
import {useIsDesktop} from '@/shared/hooks/dimensions/useIsDesktop/useIsDesktop';
import {usePaginateBuiltInFields} from '@/features/Fields/hooks/usePaginate/usePaginateBuiltInFields';
import {BuiltInFieldTable} from '@/widgets/BuiltInFieldsTable';

export const BuiltInFieldsPage = () => {
	const isDesktop = useIsDesktop();
	const {total} = usePaginateBuiltInFields();

	useAppHeader(
		{
			title: 'Поля',
			sectionsMenu: !isDesktop ? fieldsPagesLinks : undefined
		},
		[isDesktop]
	);

	return (
		<>
			<div className={styles.counterContainer}>
				<PageCounter content={`Всего: ${total}`} />
			</div>
			<BuiltInFieldTable />
		</>
	);
};
