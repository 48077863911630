import CreatedByFilterPage from '@src/pages/filters/CreatedByFilterPage';
import CreationDateFilterPage from '@src/pages/filters/CreationDateFilterPage';
import {ObjectsFilterPage} from '@src/pages/filters/ObjectsFilterPage';
import {SpaceStatusesSetsFiltersPage} from '@src/pages/filters/SpaceStatusesSetsFiltersPage';

export const spaceStatusesSetsFilterRoutes = [
	{
		path: 'objects',
		element: <ObjectsFilterPage />
	},
	{
		path: 'createdBy',
		element: <CreatedByFilterPage />
	},
	{
		path: 'creationDate',
		element: <CreationDateFilterPage />
	},
	{
		path: '',
		element: <SpaceStatusesSetsFiltersPage />
	}
];
