import {onlineManager, useQueryClient} from '@tanstack/react-query';
import useAppSelector from '../../useAppSelector';
import {
	IOwnerAcceptanceSavingData,
	toListOwnerAcceptance,
	toOwnerAcceptance
} from './utils/convertToLocalSave';
import {ownerAcceptancesQueryKeys} from '@src/api/cache/ownerAcceptances/keys';
import {useAddOwnerAcceptanceMutation} from '@src/api/cache/ownerAcceptances/mutations';
import {IAddOwnerAcceptanceParams} from '@src/api/cache/ownerAcceptances/defaults/useOwnerAcceptancesMutationDefaults';
import {useLocalOwnerAcceptances} from '../../queries/ownerAcceptances/useGetOwnerAcceptances';
import {IOwnerAcceptance} from '@tehzor/tools/interfaces/ownerAcceptances/IOwnerAcceptance';
import {IObject} from '@tehzor/tools/interfaces/objects/IObject';
import {IListOwnerAcceptance} from '@tehzor/tools/interfaces/ownerAcceptances/IListOwnerAcceptance';
import {useNextLocalNumber} from '../../useNextLocalNumber';
import {spaceOwnersQueryKeys} from '@src/api/cache/spaceOwners/keys';
import {addNewEntityToNormalizedData} from '@src/utils/addNewEntityToNormalizedData';
import {IGetOwnerAcceptancesResponse} from '@src/api/backend/ownerAcceptances';
import {ICachedSpaceOwner} from '../spaceOwners/useEditSpaceOwner';
import {addWarningToast} from '@tehzor/ui-components';
import {useTranslation} from 'react-i18next';

export const useAddOwnerAcceptance = (object?: IObject) => {
	const {t} = useTranslation();
	const queryClient = useQueryClient();
	const online = onlineManager.isOnline();
	const user = useAppSelector(s => s.auth.profile);
	const nextNumber = useNextLocalNumber<IListOwnerAcceptance>(
		useLocalOwnerAcceptances(),
		'createdAt'
	);
	const mutation = useAddOwnerAcceptanceMutation();

	return async ({
		objectId,
		links,
		fields
	}: Omit<
		IAddOwnerAcceptanceParams,
		'key' | 'currentQueryFilter'
	>): Promise<IOwnerAcceptance> => {
		const key = Date.now().toString(10);
		if (links?.checkRecordId && links?.checkRecordId.startsWith('local')) {
			links.checkRecordId = undefined;
		}

		return new Promise(resolve => {
			const converted = toOwnerAcceptance(user, objectId, links, fields, key, nextNumber);
			const convertedToList = object
				? toListOwnerAcceptance(object, user, links, fields, key, nextNumber)
				: undefined;

			queryClient.setQueryData(
				[...ownerAcceptancesQueryKeys.savingData(key), objectId],
				convertedToList
			);

			if (!online) {
				addWarningToast({
					title: t('toast.addOwnerAcceptanceLocal.warning.title')
				});
				void queryClient.refetchQueries({queryKey: ownerAcceptancesQueryKeys.localList()});
				queryClient.setQueryData<IGetOwnerAcceptancesResponse>(
					ownerAcceptancesQueryKeys.latest(),
					oldData => {
						if (!oldData) return undefined;
						return addNewEntityToNormalizedData<
							IGetOwnerAcceptancesResponse,
							IOwnerAcceptanceSavingData
						>(oldData, converted);
					}
				);
			}

			const spaceOwnersLocalList = queryClient.getQueryData<ICachedSpaceOwner[]>(
				spaceOwnersQueryKeys.localList()
			);

			// Если id собственника помещения отсутствует среди ключей сохранения локально сохраненных собственников,
			// и значит можно выполнять мутацию.
			if (
				!spaceOwnersLocalList?.some(
					(item: ICachedSpaceOwner) => item && item.key === fields.spaceOwnerId
				)
			) {
				mutation.mutate({
					key,
					objectId,
					links,
					fields
				});
			}
			resolve(converted);
		});
	};
};
