import {Dispatch, memo, useMemo} from 'react';
import IObjectFieldSetting from '@tehzor/tools/interfaces/objects/IObjectFieldSetting';
import Text from '@src/components/editableFields/Text';
import CompanySelect from '@src/components/editableFields/CompanySelect';
import SpaceTypeSelect from '@src/components/editableFields/SpaceTypeSelect';
import {
	IEditableCheckListAction,
	IEditableCheckListState
} from '@src/core/hooks/states/useEditableCheckListState/state';
import './EditableCheckList.less';
import {ObjectStageSelect} from '@src/components/editableFields/ObjectStageSelect';
import {ObjectsSelect} from '../editableFields/ObjectsSelect';
import {SpaceTypeDecorationTreeSelect} from '../editableFields/SpaceTypeDecorationTreeSelect';
import {filterTypeDecorationsForCheckList} from './utils/filterTypeDecorations';
import {CategoryTreeSelect} from '../editableFields/CategoryTreeSelect';
import {StructureTypeSelect} from '../editableFields/StructureTypeSelect';
import {WorkAcceptanceFrontTypeSelect} from '../editableFields/WorkAcceptanceFrontTypeSelect';
import {WorkAcceptanceFrontTypeId} from '@tehzor/tools/interfaces/workAcceptances/IWorkAcceptanceFrontType';
import {useSpaceTypeDecorationsAsArray} from '@src/core/hooks/queries/spaceTypeDecorations/hooks';
import {useExtractSpaceTypeDecorationSetsAsArray} from '@src/core/hooks/queries/SpaceTypeDecorationSets/hooks';
import {CheckListProcessSelect} from '@src/components/editableFields/CheckListProcessSelect';
import {ProcessIds} from '@tehzor/tools/interfaces/process/ProcessId';
import {useFormFieldsReset} from '@src/components/EditableCheckList/hooks/useFormFieldsReset';
import {conditionalProcesses} from '@src/components/EditableCheckList/constants/conditionalProcesses';

interface IEditableCheckListProps {
	editingState: IEditableCheckListState;
	editingDispatch: Dispatch<IEditableCheckListAction>;
	fieldsSettings: Record<string, IObjectFieldSetting>;
	saving?: boolean;
}

export const EditableCheckList = memo((props: IEditableCheckListProps) => {
	const {editingState, editingDispatch, fieldsSettings, saving} = props;

	const {data: typeDecorationsArray} = useSpaceTypeDecorationsAsArray();
	const {data: typeDecorationSets} = useExtractSpaceTypeDecorationSetsAsArray();

	const externalTypeDecorations = useMemo(() => {
		if (!typeDecorationsArray || !typeDecorationSets) {
			return [];
		}
		return filterTypeDecorationsForCheckList(
			editingState,
			typeDecorationsArray,
			typeDecorationSets
		);
	}, [editingState, typeDecorationsArray, typeDecorationSets]);

	// Сброс полей формы, если изменились зависимые поля:
	useFormFieldsReset(editingState, editingDispatch);

	// Отображаем поля "Типы помещений" и "Типы отделки" по условию:
	const showSpaceTypeDecorationAndSpaceType = useMemo((): boolean => {
		if (!editingState.processId) return false;

		// Если выбран один из процессов:
		if (conditionalProcesses.includes(editingState.processId)) return true;

		// Если выбран процесс "Приёмочный контроль" и тип фронта приемки работ "Структуры"
		return (
			editingState.processId === ProcessIds.ACCEPTANCE_CONTROL &&
			editingState.frontType === WorkAcceptanceFrontTypeId.SPACES
		);
	}, [editingState.frontType, editingState.processId]);

	return (
		<div className="editable-check-list">
			{fieldsSettings.name !== undefined && (
				<Text
					field="name"
					label="Название"
					elementType="textarea"
					textAreaProps={{minRows: 2}}
					value={editingState.name}
					editingDispatch={editingDispatch}
					required={fieldsSettings.name.isRequired}
					disabled={saving}
					hasError={editingState.errors.name}
				/>
			)}

			{fieldsSettings.companyId !== undefined && (
				<CompanySelect
					field="companyId"
					label="Компания"
					value={editingState.companyId}
					editingDispatch={editingDispatch}
					required={fieldsSettings.companyId.isRequired}
					disabled={saving}
					hasError={editingState.errors.companyId}
				/>
			)}

			<ObjectsSelect
				field="objects"
				label="Объекты"
				value={editingState.objects}
				companyId={editingState.companyId}
				editingDispatch={editingDispatch}
				required={fieldsSettings.objects.isRequired}
				disabled={saving}
				hasError={editingState.errors.objects}
			/>

			{/* Стадия (только для объектов) */}
			{fieldsSettings.stage !== undefined && (
				<ObjectStageSelect
					field="stage"
					label="Стадия"
					errorMessage="Выберите стадию объекта"
					value={editingState.stage}
					editingDispatch={editingDispatch}
					required={fieldsSettings.stage.isRequired}
					disabled={saving}
					hasError={editingState.errors.stage}
				/>
			)}

			{fieldsSettings.processId && editingState.stage && (
				<CheckListProcessSelect
					field="processId"
					label="Процесс"
					value={editingState.processId}
					editingDispatch={editingDispatch}
					required={fieldsSettings.processId.isRequired}
					disabled={saving}
					hasError={editingState.errors.processId}
					stage={editingState.stage}
				/>
			)}

			{fieldsSettings.frontType &&
				editingState.processId === ProcessIds.ACCEPTANCE_CONTROL && (
					<WorkAcceptanceFrontTypeSelect
						field="frontType"
						label="Тип фронта приёмок работ"
						value={editingState.frontType}
						editingDispatch={editingDispatch}
						required={fieldsSettings.frontType.isRequired}
						disabled={saving}
						hasError={editingState.errors.frontType}
					/>
				)}

			{fieldsSettings.categories !== undefined &&
				editingState.processId === ProcessIds.ACCEPTANCE_CONTROL && (
					<CategoryTreeSelect
						field="categories"
						label="Вид работ"
						value={editingState.categories || []}
						editingDispatch={editingDispatch}
						required={fieldsSettings.categories.isRequired}
						disabled={saving}
						hasError={editingState.errors.categories}
					/>
				)}

			{/* "Тип Структур" отображаем, если процесс = "Приёмочный контроль" и "Тип фронта" = "Структуры" */}
			{fieldsSettings.structureType !== undefined &&
				editingState.processId === ProcessIds.ACCEPTANCE_CONTROL &&
				editingState.frontType === WorkAcceptanceFrontTypeId.STRUCTURES && (
					<StructureTypeSelect
						field="structureType"
						label="Тип Структур"
						value={editingState.structureType}
						editingDispatch={editingDispatch}
						required={fieldsSettings.structureType.isRequired}
						disabled={saving}
						hasError={editingState.errors.structureType}
					/>
				)}

			{showSpaceTypeDecorationAndSpaceType && (
				<>
					{fieldsSettings.spaceTypes !== undefined && (
						<SpaceTypeSelect
							field="spaceTypes"
							label="Типы помещений"
							value={editingState.spaceTypes || []}
							editingDispatch={editingDispatch}
							required={fieldsSettings.spaceTypes.isRequired}
							disabled={saving}
							hasError={editingState.errors.spaceTypes}
						/>
					)}
					{editingState.companyId !== undefined && externalTypeDecorations && (
						<SpaceTypeDecorationTreeSelect
							field="typeDecoration"
							label="Типы отделки"
							value={editingState.typeDecoration || []}
							externalTypeDecorations={externalTypeDecorations}
							editingDispatch={editingDispatch}
							required={fieldsSettings.typeDecoration.isRequired}
							disabled={saving}
							hasError={editingState.errors.typeDecoration}
						/>
					)}
				</>
			)}
		</div>
	);
});
