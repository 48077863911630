import {IStructuresStatsDataRequest} from '@src/interfaces/IStructuresStatsData';
import {useQuery, UseQueryOptions} from '@tanstack/react-query';
import IError from '@tehzor/tools/interfaces/IError';
import {structuresQueryKeys} from '../../../keys';
import {IGetStructuresWorkAcceptancesResponse} from '@/shared/api/structuresWorkAcceptancesData/getStructuresWorkAcceptancesData';

export const useStructuresWorkAcceptancesStats = ({
	objectId,
	structuresIds,
	options
}: IStructuresStatsDataRequest & {
	options?: Partial<UseQueryOptions<IGetStructuresWorkAcceptancesResponse, IError>>;
}) =>
	useQuery<IGetStructuresWorkAcceptancesResponse, IError>({
		queryKey: structuresQueryKeys.workAcceptancesStats(objectId, structuresIds),
		meta: {
			error: 'при загрузке статистики по приемкам работ структур'
		},
		...options
	});
