import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {createApiAction} from '@src/store/middlewares/api';
import {makeInspectionDeleteRequest} from '@src/api/backend/inspection';
import {queryClient} from '@src/api/QueryClient';
import {inspectionsQueryKeys} from '@src/api/cache/inspections/keys';
import {deleteLocalInspection} from '@src/cache/actions/inspection/deleteLocalInspection';
import {invalidateLinkedInspectionsQueries} from '@src/cache/actions/inspection/invalidateLinkedInspectionsQueries';
import {addErrorToast, addSuccessToast} from '@tehzor/ui-components';
import {structuresQueryKeys} from '@/entities/Structures/model/cache/keys';
import {t} from 'i18next';

export interface IDeleteInspectionPayload {
	objectId: string;
	inspectionId: string;
}

const request = () => ({type: types.DELETE_REQUEST});

const success = (objectId: string, inspectionId: string, quietly?: boolean) => {
	if (!quietly) {
		addSuccessToast({title: t('toast.deleteInspection.success.title')});
	}
	return {
		type: types.DELETE_SUCCESS,
		payload: {
			objectId,
			inspectionId
		} as IDeleteInspectionPayload
	};
};

const failure = (error: IError) => {
	addErrorToast({title: t('toast.deleteInspection.error.title')});
	return {
		type: types.DELETE_FAILURE,
		payload: error
	};
};

export const deleteInspectionActions = {request, success, failure};

/**
 * Удаляет осмотр
 *
 * @param objectId id объекта
 * @param inspectionId id осмотра
 */
export const deleteInspection = (objectId: string, inspectionId: string) =>
	createApiAction(
		request,
		() => {
			queryClient.removeQueries({queryKey: inspectionsQueryKeys.detail(inspectionId)});
			void queryClient.invalidateQueries({queryKey: inspectionsQueryKeys.list()});
			void queryClient.invalidateQueries({queryKey: structuresQueryKeys.inspections()});
			void invalidateLinkedInspectionsQueries(objectId);
			return success(objectId, inspectionId);
		},
		failure,
		async () => {
			if (queryClient.getQueryData(inspectionsQueryKeys.savingData(inspectionId))) {
				await deleteLocalInspection(inspectionId);
				return;
			}
			await makeInspectionDeleteRequest(objectId, inspectionId);
		}
	);
