import {NameCell} from '../../ui/Cells/NameCell/NameCell';
import {Column} from 'react-table';
import {IPreparedField} from '@/features/Fields';

export const getNamesColumn = (): Array<Column<IPreparedField>> => [
	{
		id: 'name',
		Header: 'Название поля',
		accessor: item => item?.name,
		Cell: NameCell
	}
];
