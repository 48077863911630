import {useMutation} from '@tanstack/react-query';
import {queryClient} from '@src/api/QueryClient';
import {exportTemplatesQueryKeys} from '@src/api/cache/exportTemplates/keys';
import {makeExportTemplateDeleteRequest} from '@src/api/backend/exportTemplates/delete';
import {addErrorToast, addSuccessToast} from '@tehzor/ui-components';
import {useTranslation} from 'react-i18next';

export const useDeleteExportTemplate = () => {
	const {t} = useTranslation();

	return useMutation({
		mutationKey: exportTemplatesQueryKeys.delete(),
		mutationFn: (templateId: string) => makeExportTemplateDeleteRequest(templateId),
		onSuccess: () => {
			void queryClient.invalidateQueries({queryKey: exportTemplatesQueryKeys.list()});
			addSuccessToast({title: t('toast.deleteExportTemplate.success.title')});
		},
		onError: () => {
			addErrorToast({title: t('toast.deleteExportTemplate.error.title')});
		}
	});
};
