export const fieldSettingsQueryKeys = {
	all: () => ['fieldSettings'],
	one: () => ['fieldSetting'],
	lists: () => [...fieldSettingsQueryKeys.all(), 'list'],
	list: () => [...fieldSettingsQueryKeys.lists()],
	localLists: () => [...fieldSettingsQueryKeys.all(), 'local-list'],
	localList: () => [...fieldSettingsQueryKeys.localLists()],
	edit: () => [...fieldSettingsQueryKeys.one(), 'edit'],
	add: () => [...fieldSettingsQueryKeys.one(), 'add'],
	delete: () => [...fieldSettingsQueryKeys.one(), 'delete']
};
