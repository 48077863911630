import {IPreparedLegal} from '@src/pages/LegalsPage/interfaces/IPreparedLegal';
import {useDeleteLegal} from './useDeleteLegal';
import {useCallback} from 'react';

export const useDeleteLegals = () => {
	const {mutateAsync: deleteLegal} = useDeleteLegal();

	const deleteLegals = useCallback(
		async (legals: IPreparedLegal[]) => {
			for (const legal of legals) {
				await deleteLegal({legalId: legal.id});
			}
		},
		[deleteLegal]
	);

	return {deleteLegals};
};
