import {useMutation} from '@tanstack/react-query';
import {queryClient} from '@src/api/QueryClient';
import {fieldsQueryKeys} from '../keys/index';
import {deleteCustomFieldRequest} from '@/shared/api/fields/deleteCustomField';
import {addErrorToast} from '@tehzor/ui-components';
import {useTranslation} from 'react-i18next';

export const useDeleteCustomFiled = () => {
	const {t} = useTranslation();

	return useMutation({
		mutationKey: fieldsQueryKeys.deleteCustom(),
		mutationFn: (fieldId: string) => deleteCustomFieldRequest(fieldId),
		onError: () => addErrorToast({title: t('toast.deleteCustomFiled.error.title')}),
		onSuccess: () => {
			void queryClient.invalidateQueries({queryKey: fieldsQueryKeys.all()});
		}
	});
};
