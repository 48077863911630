import {queryClient} from '@src/api/QueryClient';
import {requestDeleteWorkingGroup} from '@src/api/backend/workingGroup';
import {responsibilityRulesQueryKeys} from '@src/api/cache/responsibilityRules/keys';
import {workingGroupsQueryKeys} from '@src/api/cache/workingGroups/keys';
import {useMutation} from '@tanstack/react-query';
import {addErrorToast, addSuccessToast} from '@tehzor/ui-components';
import {useTranslation} from 'react-i18next';

export const useDeleteWorkingGroup = () => {
	const {t} = useTranslation();

	return useMutation({
		mutationKey: workingGroupsQueryKeys.delete(),
		mutationFn: (groupId: string) => requestDeleteWorkingGroup(groupId),
		onSuccess: () => {
			void queryClient.invalidateQueries({queryKey: workingGroupsQueryKeys.list()});
			void queryClient.invalidateQueries({queryKey: responsibilityRulesQueryKeys.list()});
			addSuccessToast({title: t('toast.deleteWorkingGroup.success.title')});
		},
		onError: () => {
			addErrorToast({title: t('toast.deleteWorkingGroup.error.title')});
		}
	});
};
