import {useCallback} from 'react';
import ProblemsPage from './ProblemsPage';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {IProblemsFiltersState} from '@src/store/modules/settings/pages/problems/interfaces';
import {problemsActions} from '@src/store/modules/settings/pages/problems/slice';
import {extractProblemsPageSettings} from '@src/store/modules/settings/pages/problems/selectors';
import EntitiesFiltersProvider from '@src/components/EntitiesFilters/utils/EntitiesFiltersProvider';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import {useTargetObjects} from '@src/core/hooks/queries/objects/hooks';
import {ProblemSearchField} from '@src/constants/filters/searchFields';

/**
 * Обёртка над страницей нарушений
 * необходима для того чтобы при переходе от одной страницы к другой отрабатывали все методы жизненного цикла
 *
 * @constructor
 */
const ProblemsPageWrap = () => {
	const dispatch = useAppDispatch();
	const {changeFilters, changeOffset, clearFilters, changeSearchBy} = problemsActions;
	const {objectId} = useStrictParams<{objectId: string}>();
	const {data: descendants} = useTargetObjects(objectId, false);
	const {filters} = useAppSelector(s => extractProblemsPageSettings(s, objectId));
	const applyFilters = useCallback(
		(value: IProblemsFiltersState) => {
			dispatch(changeFilters({objectId, filters: value}));
			dispatch(changeOffset({objectId, offset: 0}));
		},
		[dispatch, changeFilters, objectId, changeOffset]
	);
	const onClear = useCallback(() => {
		dispatch(clearFilters({objectId, isDescendants: !!descendants?.length}));
		dispatch(changeSearchBy({objectId, searchBy: ProblemSearchField.Description}));
	}, [dispatch, clearFilters, objectId, descendants]);

	return (
		<EntitiesFiltersProvider
			filters={filters}
			objectId={objectId}
			onApply={applyFilters}
			onClear={onClear}
		>
			<ProblemsPage key={objectId} />
		</EntitiesFiltersProvider>
	);
};

export default ProblemsPageWrap;
