import {ILocationMarker} from '@tehzor/tools/interfaces/ILocationMarker';
import ILayer from '@tehzor/tools/interfaces/plans/ILayer';
import IShape from '@tehzor/tools/interfaces/plans/IShape';
import classNames from 'classnames';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {Point} from './components/Point';
import {Sector} from './components/Sector';
import './PlanViewer.less';
import {getPointAttributes} from './utils/getPointAttributes';
import {markerStructurePath} from './utils/markerPath';
import {getDistance} from './utils/getDistance';

export type Mode = 'view' | 'edit';

export type InputType = 'sectors' | 'points';

export type Path = 'problem' | 'entity';

interface ILayerWithShapes extends Omit<ILayer, 'shapes'> {
	shapes: IShape[];
}

interface IPlanViewerProps {
	mode: Mode;
	inputType: InputType;
	image?: string;
	layers?: ILayer[];
	visibleLayers: string[];
	sectors: ILocationMarker[];
	points: ILocationMarker[];
	multiplePoints?: boolean; // TODO выяснить как это использовалось
	singleSelectedPoint?: number;
	pointsColor?: string;
	path: Path;
	entitySectors?: ILocationMarker[];
	entityPoints?: ILocationMarker[];
	selectPoint?: (point: number) => void;
	selectSector?: (sector: number) => void;
	onSectorsChange?(shapes: ILocationMarker[]): void;
	onPointsChange?(points: ILocationMarker[]): void;
}

/**
 * Компонент для просмотра плана и выбора секторов и точек
 */
export const PlanViewer = ({
	mode,
	inputType,
	image,
	layers,
	visibleLayers,
	sectors,
	points,
	// multiplePoints = false,
	singleSelectedPoint,
	pointsColor = '#FF8228',
	path,
	entitySectors,
	entityPoints,
	selectPoint,
	selectSector,
	onSectorsChange,
	onPointsChange
}: IPlanViewerProps) => {
	const [imageDimensions, setImageDimensions] = useState<{width: number; height: number}>({
		width: 0,
		height: 0
	});
	const [tempPoint, setTempPoint] = useState<{x: number; y: number} | null>(null);
	const [selectedPointIndex, setSelectedPointIndex] = useState<number | null>(null);
	const [initialClick, setInitialClick] = useState<{x: number; y: number} | null>(null);
	const [scale, setScale] = useState<number>(1);
	const [translate, setTranslate] = useState<{x: number; y: number}>({x: 0, y: 0});
	const [dragging, setDragging] = useState<boolean>(false);
	const [lastTouchPosition, setLastTouchPosition] = useState<{x: number; y: number} | null>(null);
	const [initialTouchDistance, setInitialTouchDistance] = useState<number | null>(null);
	const [initialScale, setInitialScale] = useState<number>(scale);

	const svgRef = useRef<SVGSVGElement | null>(null);
	const wrapperRef = useRef<HTMLDivElement | null>(null);
	const frameRef = useRef<HTMLDivElement | null>(null);
	const sectorIds = useMemo(() => sectors.map(sector => sector.sector), [sectors]);
	const visibleLayersWithShapes = useMemo(
		() =>
			layers?.filter((layer: ILayer) => visibleLayers.includes(layer.id) && layer.shapes) as
				| ILayerWithShapes[]
				| undefined,
		[layers, visibleLayers]
	);

	// Функция для проверки, отличается ли svg ctm.a и svg ctm.d от scale с учетом погрешности
	const isCtmScalingApplied = (ctm: DOMMatrix, currentScale: number, epsilon = 0.001): boolean =>
		Math.abs(ctm.a - currentScale) > epsilon && Math.abs(ctm.d - currentScale) > epsilon;

	useEffect(() => {
		const svgElement = svgRef.current;

		if (svgElement) {
			const handleTouchMoveNonPassive = (event: TouchEvent) => {
				if (event.touches.length === 2) {
					event.preventDefault();
				}
			};
			svgElement.addEventListener('touchmove', handleTouchMoveNonPassive, {passive: false});

			return () => {
				svgElement.removeEventListener('touchmove', handleTouchMoveNonPassive);
			};
		}
		return undefined;
	}, []);

	const handlePointDown = useCallback(
		(clientX: number, clientY: number, index: number) => {
			setSelectedPointIndex(index);
			if (mode === 'edit') {
				setInitialClick({x: clientX, y: clientY});
			}
		},
		[setSelectedPointIndex, setInitialClick, mode]
	);

	const handleTouchStart = useCallback(
		(event: React.TouchEvent<SVGSVGElement>) => {
			if (event.touches.length === 2) {
				const touch1 = event.touches[0];
				const touch2 = event.touches[1];
				const distance = getDistance(touch1, touch2);
				setInitialTouchDistance(distance);
				setInitialScale(scale);
				setLastTouchPosition(null);
			} else if (event.touches.length === 1) {
				const touch = event.touches[0];
				setLastTouchPosition({x: touch.clientX, y: touch.clientY});
				setDragging(true);
				setInitialClick({x: touch.clientX, y: touch.clientY});
			}
		},
		[
			scale,
			setInitialTouchDistance,
			setInitialScale,
			setLastTouchPosition,
			setDragging,
			setInitialClick
		]
	);

	const handleMove = useCallback(
		(clientX: number, clientY: number, movementX: number, movementY: number) => {
			if (selectedPointIndex !== null && mode === 'edit') {
				const svg = svgRef.current;
				if (svg) {
					const pointDOM = new DOMPoint(clientX, clientY);

					let ctm = svg.getScreenCTM();

					// Проверяем применён ли scale к SVG CTM, если нет то применяем,
					// т.к. Safari в настоящее время не учитывает преобразования (scale, rotate) родительских элементов
					if (ctm && isCtmScalingApplied(ctm, scale)) {
						ctm = ctm.scale(scale);
					}

					const {x, y} = pointDOM.matrixTransform(ctm?.inverse());

					setTempPoint({x, y});
				}
			} else if (dragging) {
				setTranslate({
					x: translate.x + movementX,
					y: translate.y + movementY
				});
			}
		},
		[dragging, mode, scale, selectedPointIndex, translate.x, translate.y]
	);

	const handleMouseMove = useCallback(
		(event: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
			handleMove(event.clientX, event.clientY, event.movementX, event.movementY);
		},
		[handleMove]
	);

	const handleTouchMove = useCallback(
		(event: React.TouchEvent<SVGSVGElement>) => {
			if (event.touches.length === 2 && initialTouchDistance) {
				const touch1 = event.touches[0];
				const touch2 = event.touches[1];
				const currentDistance = getDistance(touch1, touch2);
				const newScale = (currentDistance / initialTouchDistance) * initialScale;
				setScale(newScale);
			} else if (event.touches.length === 1) {
				const touch = event.touches[0];
				const currentX = touch.clientX;
				const currentY = touch.clientY;
				if (lastTouchPosition) {
					const deltaX = currentX - lastTouchPosition.x;
					const deltaY = currentY - lastTouchPosition.y;
					handleMove(currentX, currentY, deltaX, deltaY);
				}

				setLastTouchPosition({x: currentX, y: currentY});
			}
		},
		[handleMove, setLastTouchPosition, lastTouchPosition, initialScale, initialTouchDistance]
	);

	const createPoint = useCallback(
		(pointX: number, pointY: number, svg: SVGSVGElement) => {
			const pointDOM = new DOMPoint(pointX, pointY);

			let ctm = svg.getScreenCTM();

			// Проверяем применён ли scale к SVG CTM, если нет то применяем,
			// т.к. Safari в настоящее время не учитывает преобразования (scale, rotate) родительских элементов
			if (ctm && isCtmScalingApplied(ctm, scale)) {
				ctm = ctm.scale(scale);
			}

			const {x, y} = pointDOM.matrixTransform(ctm?.inverse());

			const {sector, name} = getPointAttributes(x, y, layers);
			const countBySector = points.filter(p => p.sector === sector).length;

			const newPoint: ILocationMarker = {
				name: countBySector ? `${name} (${countBySector})` : name,
				sector,
				x,
				y
			};
			if (onPointsChange) {
				onPointsChange([...points, newPoint]);
			}
		},
		[layers, onPointsChange, points, scale]
	);

	const handleMouseUp = useCallback(
		(event: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
			const {clientX, clientY} = event;
			if (selectedPointIndex !== null) {
				if (
					mode === 'view' ||
					(initialClick && initialClick.x === clientX && initialClick.y === clientY)
				) {
					if (selectPoint) {
						selectPoint(selectedPointIndex);
					}
				} else {
					const svg = svgRef.current;
					if (svg && tempPoint && mode === 'edit') {
						const {sector, name} = getPointAttributes(tempPoint.x, tempPoint.y, layers);

						const updatedPoints: ILocationMarker[] = points.map((p, index) =>
							index === selectedPointIndex
								? {...p, x: tempPoint.x, y: tempPoint.y, sector, name}
								: p
						);
						if (
							tempPoint.x >= 0 &&
							tempPoint.x <= imageDimensions.width &&
							tempPoint.y >= 0 &&
							tempPoint.y <= imageDimensions.height
						) {
							if (onPointsChange) {
								onPointsChange(updatedPoints);
							}
						}
					}
					setTempPoint(null);
				}
				setSelectedPointIndex(null);
			} else if (dragging) {
				if (initialClick && initialClick.x === clientX && initialClick.y === clientY) {
					const svg = svgRef.current;
					if (svg && mode === 'edit') {
						createPoint(clientX, clientY, svg);
					}
				}
			}
			setDragging(false);
		},
		[
			dragging,
			initialClick,
			mode,
			onPointsChange,
			points,
			selectPoint,
			selectedPointIndex,
			tempPoint,
			layers,
			createPoint
		]
	);

	const handleTouchEnd = useCallback(() => {
		const svg = svgRef.current;
		if (svg && tempPoint && mode === 'edit') {
			const {sector, name} = getPointAttributes(tempPoint.x, tempPoint.y, layers);

			if (
				tempPoint.x >= 0 &&
				tempPoint.x <= imageDimensions.width &&
				tempPoint.y >= 0 &&
				tempPoint.y <= imageDimensions.height
			) {
				const updatedPoints: ILocationMarker[] = points.map((p, index) =>
					index === selectedPointIndex
						? {...p, x: tempPoint.x, y: tempPoint.y, sector, name}
						: p
				);
				if (onPointsChange) {
					onPointsChange(updatedPoints);
				}
			}
		}
		setSelectedPointIndex(null);
		setTempPoint(null);
		setInitialTouchDistance(null);
		setInitialClick(null);
		setDragging(false);
	}, [
		setInitialTouchDistance,
		setInitialClick,
		layers,
		mode,
		onPointsChange,
		points,
		selectedPointIndex,
		tempPoint
	]);

	const handleSectorMouseDown = useCallback(
		(event: React.MouseEvent<SVGElement, MouseEvent>, sectorName: string, sectorId: string) => {
			event.stopPropagation();

			const index = sectorIds.findIndex(id => id === sectorId);

			if (index > -1) {
				if (selectSector) {
					selectSector(index);
				}
				if (onSectorsChange) {
					onSectorsChange(
						sectors.map(sector =>
							sector.sector === sectorId
								? {...sector, selected: true}
								: {...sector, selected: false}
						)
					);
				}
			} else if (onSectorsChange && mode === 'edit') {
				const newSector = {
					name: sectorName,
					sector: sectorId
				};
				onSectorsChange([...sectors, newSector]);
			}

			setInitialClick({x: event.clientX, y: event.clientY});
		},
		[selectSector, onSectorsChange, setInitialClick, sectorIds, sectors, mode]
	);

	const handleBackgroundMouseDown = useCallback(
		(event: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
			setDragging(true);
			setInitialClick({x: event.clientX, y: event.clientY});
		},
		[setDragging, setInitialClick]
	);

	const handleWheel = useCallback(
		(event: React.WheelEvent<SVGSVGElement>) => {
			const delta = event.deltaY > 0 ? 0.9 : 1.1;
			setScale(scale * delta);
		},
		[setScale, scale]
	);

	const handleImageLoad = (event: React.SyntheticEvent<SVGImageElement, Event>) => {
		const href = (event.currentTarget as SVGImageElement).getAttribute('href');
		if (!href) return;

		const img = new Image();
		img.src = href;
		img.onload = () => {
			const width = img.naturalWidth;
			const height = img.naturalHeight;

			if (wrapperRef.current && svgRef.current) {
				svgRef.current.style.width = `${width}px`;
				svgRef.current.style.height = `${height}px`;
			}

			const frame = frameRef.current;
			if (frame) {
				const frameWidth = frame.clientWidth;
				const frameHeight = frame.clientHeight;
				const scale = Math.min(frameWidth / width, frameHeight / height);

				setScale(scale);

				setTranslate({
					x: (frameWidth - width) / 2,
					y: (frameHeight - height) / 2
				});
			}
			setImageDimensions({width, height});
		};
	};

	const elements = useMemo(() => {
		const entitySectorIds = entitySectors?.map(sector => sector.sector);
		const commentedSectors = sectors.filter(el => el.description).map(el => el.sector);

		switch (inputType) {
			case 'points':
				return (
					<>
						{
							visibleLayersWithShapes
							? visibleLayersWithShapes
									.map(layer =>
										layer.shapes.map(shape => (
											<Sector
												shape={shape}
												sectorIds={sectorIds}
												commentedSectors={commentedSectors}
												entitySectorIds={entitySectorIds}
											/>
										))
									)
									.flat()
							: null
						}
						{
							points.map((point, i) => (
								<Point
									index={i}
									point={point}
									path={path}
									scale={scale}
									selectedPointIndex={selectedPointIndex}
									tempPoint={tempPoint}
									singleSelectedPoint={singleSelectedPoint}
									pointsColor={pointsColor}
									handlePointDown={handlePointDown}
								/>
							))
						}
					</>
				);
			case 'sectors':
				return visibleLayersWithShapes
					? visibleLayersWithShapes
							.map(layer =>
								layer.shapes.map(shape => (
									<Sector
										shape={shape}
										sectorIds={sectorIds}
										commentedSectors={commentedSectors}
										entitySectorIds={entitySectorIds}
										handleSectorMouseDown={handleSectorMouseDown}
										isActiveLayer
									/>
								))
							)
							.flat()
					: null;

			default:
				return null;
		}
	}, [
		inputType,
		points,
		scale,
		pointsColor,
		handlePointDown,
		path,
		selectedPointIndex,
		tempPoint,
		sectors,
		handleSectorMouseDown,
		sectorIds,
		singleSelectedPoint,
		visibleLayersWithShapes,
		entitySectors
	]);

	const entityElements = useMemo(
		() =>
			entityPoints?.map(point => {
				const d = markerStructurePath(0, 0, 0.5 / scale);
				const x = point.x;
				const y = point.y;
				const pointClassNames = ['plan-viewer__point_structure'];

				return (
					<path
						className={classNames(pointClassNames)}
						d={d}
						transform={`translate(${x}, ${y})`}
						key={point.id}
						strokeWidth={3 / scale}
						strokeDasharray={3.5 / scale}
					/>
				);
			}),

		[entityPoints, scale]
	);

	return (
		<div className="plan-viewer__frame" ref={frameRef}>
			<div className="plan-viewer__wrapper" ref={wrapperRef}>
				<svg
					ref={svgRef}
					className="plan-viewer__svg"
					style={{
						cursor: dragging ? 'auto' : 'grab',
						transform: `translate(${translate.x}px, ${translate.y}px) scale(${scale})`,
						transformOrigin: 'center'
					}}
					onMouseMove={handleMouseMove}
					onMouseUp={handleMouseUp}
					onMouseDown={handleBackgroundMouseDown}
					onTouchStart={handleTouchStart}
					onTouchMove={handleTouchMove}
					onTouchEnd={handleTouchEnd}
					onWheel={handleWheel}
				>
					<>
						<image onLoad={handleImageLoad} href={image} />
						{elements}
						{entityElements}
					</>
				</svg>
			</div>
		</div>
	);
};
