import {memo, useCallback} from 'react';
import {useAppUpdateMenuItem} from '@src/components/AppUpdateData';
import {useContractsPermissions} from '@src/core/hooks/permissions/useContractsPermissions';
import {IconMenu, MenuItem} from '@tehzor/ui-components';
import {IPreparedContract} from '../../interfaces/IPreparedContract';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {IDeleteContractParams} from '@src/api/cache/contracts/defaults/mutations/delete';
import {useTranslation} from 'react-i18next';
import {useTranslatedConfirmDialog} from '@src/core/hooks/translations/useTranslatedConfirmDialog';

interface IMenuProps {
	contracts: IPreparedContract[];
	deleteContract: (params: IDeleteContractParams) => void;
	handleSelectedRowsClear: () => void;
}

const deleteIcon = <i className="tz-delete" />;

export const Menu = memo(({contracts, deleteContract, handleSelectedRowsClear}: IMenuProps) => {
	const {t} = useTranslation();
	const {pushPath} = useChangePath();
	const {canDelete} = useContractsPermissions();

	const [deleteDialog, getDeleteConfirmation] = useTranslatedConfirmDialog({
		title: t('useConfirmDialog.dialog.deleteTitle'),
		message: t('useConfirmDialog.dialog.deleteMessage')
	});

	const updateData = useAppUpdateMenuItem();

	const handleDeleteClick = useCallback(async () => {
		if (await getDeleteConfirmation()) {
			for (const contract of contracts) {
				deleteContract({contractId: contract.id});
			}
			handleSelectedRowsClear();
			pushPath('/contracts/');
		}
	}, [contracts, deleteContract]);

	const items = [];

	items.push(updateData);

	if (canDelete && contracts.length) {
		items.push(
			<MenuItem
				key="delete"
				icon={deleteIcon}
				onClick={handleDeleteClick}
			>
				Удалить
			</MenuItem>
		);
	}

	return items.length ? (
		<>
			<IconMenu>{items}</IconMenu>

			{canDelete && deleteDialog}
		</>
	) : null;
});
