import {memo, useCallback} from 'react';
import {Button, ISelectionRowProps} from '@tehzor/ui-components';
import {IPreparedFieldSettingsSet} from '@/shared/types/IPreparedFieldsSettingsSets';
import {SelectionRowActions} from '../SelectionRowActions/SelectionRowActions';

export const SelectionRow = memo(
	({toggleAllRowsSelected}: ISelectionRowProps<IPreparedFieldSettingsSet>) => {
		const handleClear = useCallback(() => {
			toggleAllRowsSelected(false);
		}, [toggleAllRowsSelected]);

		return (
			<>
				<SelectionRowActions />
				<Button
					label="Сбросить"
					type="cancel"
					onClick={handleClear}
				/>
			</>
		);
	}
);
