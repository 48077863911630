import {queryClient} from '@src/api/QueryClient';
import {
	IGetCategoriesSetsResponse,
	requestEditCategoriesSet
} from '@src/api/backend/categoriesSets';
import {categoriesQueryKeys} from '@src/api/cache/categories/keys';
import {useMutation} from '@tanstack/react-query';
import IError from '@tehzor/tools/interfaces/IError';
import {ICategoriesSet} from '@tehzor/tools/interfaces/categoriesSets/ICategoriesSet';
import ISavingCategoriesSet from '@tehzor/tools/interfaces/categoriesSets/ISavingCategoriesSet';
import {addErrorToast, addSuccessToast} from '@tehzor/ui-components';
import {useTranslation} from 'react-i18next';

export const useEditCategoriesSet = () => {
	const {t} = useTranslation();

	return useMutation<ICategoriesSet, IError, ISavingCategoriesSet>({
		mutationFn: (params: ISavingCategoriesSet) => requestEditCategoriesSet(params),

		onSuccess: data => {
			queryClient.setQueryData<IGetCategoriesSetsResponse>(
				categoriesQueryKeys.list(),
				oldData => {
					if (oldData && oldData.allIds.includes(data.id)) {
						return {
							allIds: oldData.allIds,
							byId: {...oldData.byId, [data.id]: data}
						};
					}
					return oldData;
				}
			);
			addSuccessToast({title: t('toast.editCategoriesSet.success.title')});
		},
		onError: () => {
			addErrorToast({title: t('toast.editCategoriesSet.error.title')});
		}
	});
};
