import {IGetStructureResponse} from '@src/api/backend/structure';
import {useQuery} from '@tanstack/react-query';
import IError from '@tehzor/tools/interfaces/IError';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import {structuresQueryKeys} from '../../../../../Structures/model/cache/keys/index';

export const useStructure = (objectId: string) => {
	const {structureId} = useStrictParams<{
		structureId: string;
	}>();

	return useQuery<IGetStructureResponse, IError>({
		queryKey: structuresQueryKeys.oneById(objectId, structureId),
		meta: {
			error: 'при загрузке структуры'
		},
		enabled: !!structureId && !!objectId
	});
};
