import {useMutation} from '@tanstack/react-query';
import {ISavingExportTemplate} from '@src/interfaces/saving/ISavingExportTemplate';
import {queryClient} from '@src/api/QueryClient';
import {exportTemplatesQueryKeys} from '@src/api/cache/exportTemplates/keys';
import {makeExportTemplateEditRequest} from '@src/api/backend/exportTemplates/edit';
import {addErrorToast, addSuccessToast} from '@tehzor/ui-components';
import {useTranslation} from 'react-i18next';

interface IUseEditExportTemplateMutationParams {
	templateId: string;
	fields: ISavingExportTemplate;
}

export const useEditExportTemplate = () => {
	const {t} = useTranslation();

	return useMutation({
		mutationKey: exportTemplatesQueryKeys.edit(),
		mutationFn: (params: IUseEditExportTemplateMutationParams) => {
			const {templateId, fields} = params;
			return makeExportTemplateEditRequest(templateId, fields);
		},
		onSuccess: () => {
			void queryClient.invalidateQueries({queryKey: exportTemplatesQueryKeys.list()});
			addSuccessToast({title: t('toast.editExportTemplate.success.title')});
		},
		onError: () => {
			addErrorToast({title: t('toast.editExportTemplate.error.title')});
		}
	});
};
