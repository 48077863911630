import Text from '@src/components/editableFields/Text';
import IObjectFieldSetting from '@tehzor/tools/interfaces/objects/IObjectFieldSetting';
import {Dispatch} from 'react';
import {
	IEditableFieldsSettingsSetAction,
	IEditableFieldsSettingsSetState
} from '../context/formState';
import CompanySelect from '@src/components/editableFields/CompanySelect';
import {ObjectsSelect} from '@src/components/editableFields/ObjectsSelect';
import {ObjectStagesMultipleSelect} from '@src/components/ObjectStagesMultipleSelect';
import {FieldEntityTypeSelect} from '@src/components/editableFields/FieldEntityTypeSelect/FieldEntityTypeSelect';
import {IFieldSettingsSet} from '@tehzor/tools/interfaces/fields';

interface IFieldsProps {
	fieldsSettingsSet?: IFieldSettingsSet;
	editingState: IEditableFieldsSettingsSetState;
	editingDispatch: Dispatch<IEditableFieldsSettingsSetAction>;
	fieldsSettings: Record<string, IObjectFieldSetting>;
	saving?: boolean;
}

export const Fields = ({
	fieldsSettingsSet,
	editingState,
	editingDispatch,
	fieldsSettings,
	saving
}: IFieldsProps) => {
	const onChangeStages = (value?: string[]) => {
		editingDispatch({type: 'update', field: 'stageIds', value});
	};

	return (
		<>
			<Text
				field="name"
				label="Название набора"
				elementType="textarea"
				textAreaProps={{minRows: 2}}
				value={editingState.name}
				editingDispatch={editingDispatch}
				required={fieldsSettings.name.isRequired}
				disabled={saving}
				hasError={editingState.errors.name}
			/>
			<Text
				field="description"
				label="Описание"
				elementType="textarea"
				textAreaProps={{minRows: 1}}
				value={editingState.description}
				editingDispatch={editingDispatch}
				required={fieldsSettings.description.isRequired}
				disabled={saving}
				hasError={editingState.errors.description}
			/>
			<CompanySelect
				field="companyId"
				label="Компания"
				value={editingState.companyId}
				editingDispatch={editingDispatch}
				required={fieldsSettings.companyId.isRequired}
				disabled={saving}
				hasError={editingState.errors.companyId}
			/>
			<ObjectsSelect
				field="objectIds"
				label="Объекты"
				value={editingState.objectIds}
				companyId={editingState.companyId}
				editingDispatch={editingDispatch}
				required={fieldsSettings.objectIds.isRequired}
				disabled={!fieldsSettingsSet || saving}
				hasError={editingState.errors.objectIds}
			/>
			<FieldEntityTypeSelect
				field="entityType"
				label="Тип сущности"
				value={editingState.entityType?.id}
				editingDispatch={editingDispatch}
				required={fieldsSettings.entityTypeId.isRequired}
				disabled={!!fieldsSettingsSet || saving}
				hasError={editingState.errors.entityType}
			/>
			<ObjectStagesMultipleSelect
				label="Стадии"
				value={editingState.stageIds}
				onChange={onChangeStages}
				required={fieldsSettings.stageIds.isRequired}
				disabled={saving}
				hasError={editingState.errors.stageIds}
			/>
		</>
	);
};
