import {SpaceStatusSelect} from '@tehzor/ui-components';
import {CellProps} from 'react-table';
import {IConvertedSpace} from '@tehzor/tools/interfaces/spaces/IConvertedSpace';
import {useSpacesPermissions} from '@src/core/hooks/permissions/useSpacesPermissions';
import ClickPreventWrap from '@src/components/tableCells/ClickPreventWrap';
import {SpaceStatusHook} from '@tehzor/tools/interfaces/spaceStatuses/ISpaceStatus';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import {useObjectStagesMap} from '@src/core/hooks/queries/objectStages/hooks';
import {useExtractSpaceStatusesDataByObjectId} from '@src/core/hooks/queries/spaceStatusesSets/hooks';
import {useEditSpaceStatus} from '@src/core/hooks/mutations/spaces/useEditSpaceStatus';
import {useIsMutating} from '@tanstack/react-query';
import {spacesQueryKeys} from '@src/api/cache/spaces/keys';
import {useOnlineManager} from '@src/core/hooks/useOnlineManager';
import {useCallback} from 'react';
import {useTranslatedConfirmDialog} from '@src/core/hooks/translations/useTranslatedConfirmDialog';
import {useTranslation} from 'react-i18next';

const SpaceStatusCell = ({row}: CellProps<IConvertedSpace>) => {
	const {t} = useTranslation();
	const {objectId: pageObjectId} = useStrictParams<{objectId: string}>();
	const space = row.original;
	const {data: stagesMap} = useObjectStagesMap();
	const {data: statuses} = useExtractSpaceStatusesDataByObjectId(space.objectId);
	const online = useOnlineManager();
	const editSpaceStatus = useEditSpaceStatus(space.objectId);
	const actionInProgress = !!useIsMutating({
		mutationKey: spacesQueryKeys.status.edit(),
		predicate: mutation =>
			mutation.state.isPaused !== true && mutation.state.variables.spaceId === space.id
	});

	const permissions = useSpacesPermissions(space.objectId);
	const [closingDialog, getClosingConfirmation] = useTranslatedConfirmDialog({
		title: t('spacePage.info.status.confirmDialogTitle'),
		message: t('spacePage.info.status.confirmDialogMessage')
	});

	const handleChange = useCallback(
		async (v: string) => {
			const nextStatus = statuses?.byId[v];

			// если указан хук - запрашиваем подтверждение действия
			if (
				nextStatus?.hooks?.includes(SpaceStatusHook.ACCEPT_CHECK_LISTS) &&
				!(await getClosingConfirmation())
			) {
				return;
			}

			await editSpaceStatus({
				objectId: space.objectId,
				pageObjectId,
				spaceId: space.id,
				status: v,
				online
			});
		},
		[
			statuses?.byId,
			getClosingConfirmation,
			editSpaceStatus,
			space.objectId,
			space.id,
			pageObjectId,
			online
		]
	);
	if (!stagesMap || !statuses) return null;
	return (
		<ClickPreventWrap>
			<SpaceStatusSelect
				stagesMap={stagesMap}
				style={{maxWidth: '100%'}}
				statuses={statuses}
				disabled={!permissions.canEditStatus}
				value={space.status?.id}
				onChange={handleChange}
				actionInProgress={actionInProgress}
			/>
			{closingDialog}
		</ClickPreventWrap>
	);
};

export default SpaceStatusCell;
