import {useMemo} from 'react';
import {formDocumentTitle} from '@tehzor/tools/utils/formDocumentTitle';
import {IDocument} from '@tehzor/tools/interfaces/documents/IDocument';

export const useDocumentsBreadcrumbs = (document?: IDocument) =>
	 useMemo(
		() => {
			const items = [
				{
					label: 'Документы',
					url: '/documents'
				}
			];
			if (document) {
				items.push({
					label: formDocumentTitle(document),
					url: `/documents/${document.id}`
				});
			}
			return items;
		},
		[document]
	);
