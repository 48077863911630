import {useMutation} from '@tanstack/react-query';
import {IExportResponse} from '@tehzor/tools/interfaces/IExportResponse';
import {problemRepliesQueryKeys} from '@src/api/cache/problemReplies/keys';
import {makeProblemReplyExportRequest} from '@src/api/backend/reply';
import {addExportToast} from '@/shared/lib/Toasts/ExportToast/addExportToast';
import {addErrorToast, addWarningToast} from '@tehzor/ui-components';
import {useTranslation} from 'react-i18next';

interface IExportProblemRepliesParams {
	templateId: string;
	createDocument: boolean | undefined;
	email: string | undefined;
	objectId: string;
	commentId: string;
}

export const useExportProblemReplies = () => {
	const {t} = useTranslation();

	return useMutation<IExportResponse, Error, IExportProblemRepliesParams>({
		mutationKey: problemRepliesQueryKeys.export(),
		mutationFn: params => {
			const {templateId, createDocument, email, objectId, commentId} = params;
			return makeProblemReplyExportRequest(
				templateId,
				createDocument,
				email,
				objectId,
				commentId
			);
		},
		onSuccess: (response, params) => {
			if (response.warnings?.itemsLimit !== undefined) {
				addWarningToast({
					title: `${t('toast.export.warning.title')} (${String(
						response.warnings.itemsLimit
					)})`
				});
			}
			if (response.link) {
				addExportToast({
					title: params.email
						? t('toast.export.send.success.title')
						: t('toast.export.success.title'),
					url: response.link,
					fileName: response.file.name,
					document: response.document
				});
			}
		},
		onError: () => {
			addErrorToast({title: t('toast.export.error.title')});
		}
	});
};
