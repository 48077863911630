import {IContract} from '@tehzor/tools/interfaces/contracts';
import {QueryClient} from '@tanstack/react-query';
import {contractsQueryKeys} from '../../keys';
import {updateCachedState} from '@src/utils/updateCachedState';
import {OfflineDataTransferStatus} from '@tehzor/tools/contracts/dataTransferWebWorker/interfaces/IOfflineDataTransferStatuses';
import {IEditContractResponse, requestEditContract} from '@src/api/backend/contract';
import {invalidateEditQueries} from './invalidateQueries';
import {ISavingContract} from '@src/interfaces/saving/ISavingContract';
import {addErrorToast} from '@tehzor/ui-components';
import {useTranslation} from 'react-i18next';

export interface IEditContractParams {
	contractId: string;
	fields: ISavingContract;
}

export const useEditContractsMutationDefaults = (queryClient: QueryClient) => {
	const key = Date.now().toString(10);
	const {t} = useTranslation();

	queryClient.setMutationDefaults(contractsQueryKeys.edit(), {
		onMutate: (params: IEditContractParams) => ({variables: params}),
		mutationFn: async (params: IEditContractParams) => {
			const {contractId, fields} = params;

			await updateCachedState<IContract>(
				contractsQueryKeys,
				key,
				OfflineDataTransferStatus.TRANSFER
			);
			await queryClient.cancelQueries({
				queryKey: contractsQueryKeys.detail(contractId)
			});
			return requestEditContract(contractId, fields);
		},
		onSuccess: async (data: IEditContractResponse, {contractId}: IEditContractParams) => {
			await updateCachedState<IContract>(
				contractsQueryKeys,
				key,
				OfflineDataTransferStatus.TRANSFER_COMPLETE
			);
			await invalidateEditQueries(queryClient, contractId);

			return data;
		},
		onError: async () => {
			addErrorToast({title: t('toast.editContracts.error.title')});
			await updateCachedState<IContract>(
				contractsQueryKeys,
				key,
				OfflineDataTransferStatus.TRANSFER_ERROR
			);
			return undefined;
		},
		retry: false
	});
};
