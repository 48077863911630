import {useFieldSettingsList} from './useFieldSettingsList';
import {extractFieldSettingsByFieldId} from '../selectors/extractFieldSettingsByFieldId';
import {useCallback} from 'react';
import {IGetListFieldSettingsResponse} from '@/shared/api/fieldSettings/getFieldSettingsList';

export const useFieldSettingsByFieldId = (id: string) => {
	const selector = useCallback(
		(data: IGetListFieldSettingsResponse) => extractFieldSettingsByFieldId(data, id),
		[id]
	);

	return useFieldSettingsList(selector);
};
