import {queryClient} from '@src/api/QueryClient';
import {legalsQueryKeys} from '@src/api/cache/legals/keys';
import {makeLegalEntityDeleteRequest} from '@src/api/backend/legal-entity/delete';
import {addErrorToast} from '@tehzor/ui-components';
import {useMutation} from '@tanstack/react-query';
import {useTranslation} from 'react-i18next';

export const useDeleteLegal = () => {
	const {t} = useTranslation();

	return useMutation({
		mutationKey: legalsQueryKeys.delete(),
		mutationFn: ({legalId}: {legalId: string}) => makeLegalEntityDeleteRequest(legalId),
		onSuccess: () => {
			void queryClient.invalidateQueries({queryKey: legalsQueryKeys.list()});
		},
		onError: () => {
			addErrorToast({title: t('toast.deleteLegalEntity.error.title')});
		}
	});
};
