import {localAuthStorage} from '@tehzor/tools/api/localAuthStorage';

export const downloadExportedFile = async (
	url: string,
	fileName: string,
	setIsDownloading: (value: boolean) => void,
	setDownloadProgress: (value: number) => void
) => {
	const accessToken = await localAuthStorage.getAccessToken();
	setIsDownloading(true);
	const xhr = new XMLHttpRequest();
	xhr.open('GET', url, true);
	xhr.responseType = 'blob';
	xhr.setRequestHeader('Authorization', `Bearer ${accessToken}`);
	xhr.addEventListener('progress', event => {
		if (event.lengthComputable) {
			const percentComplete = (event.loaded / event.total) * 100;
			setDownloadProgress(percentComplete);
		}
	});

	xhr.onload = () => {
		if (xhr.status === 200) {
			const blob = xhr.response as Blob;
			const downloadUrl = URL.createObjectURL(blob);
			const a = document.createElement('a');
			a.href = downloadUrl;
			a.download = fileName;
			a.click();
		}
		setIsDownloading(false);
	};

	xhr.send();
};
