import {Column} from 'react-table';
import {IBuiltinField} from '@tehzor/tools/interfaces/fields';
import {NameCell} from '../../ui/cells/NameCell/NameCell';
import {TypeCell} from '../../ui/cells/TypeCell/TypeCell';
import {KeyCell} from '../../ui/cells/KeyCell/KeyCell';

export const getDesktopColumns = (): Array<Column<IBuiltinField>> => [
	{
		id: 'name',
		Header: 'Наименование поля',
		Cell: NameCell
	},
	{
		id: 'key',
		Header: 'Ключ',
		Cell: KeyCell
	},
	{
		id: 'entityType',
		Header: 'Тип сущности',
		Cell: TypeCell
	}
];
