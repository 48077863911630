import {IFieldSettingsSet} from '@tehzor/tools/interfaces/fields';
import styles from './FieldsSettingsTable.module.less';
import {Plate} from '@tehzor/ui-components';
import {useState} from 'react';
import {TableTabs} from './TableTabs/TableTabs';
import {usePreparedBuiltInFields, usePreparedCustomFields} from '@/features/Fields';
import {FieldsTable} from './FieldsTable/FieldsTable';

interface IFieldsTableProps {
	fieldsSettingsSet: IFieldSettingsSet;
}

export const FieldsSettingsTable = ({fieldsSettingsSet}: IFieldsTableProps) => {
	const [activeTub, setActiveTab] = useState(0);
	const {id, entityType} = fieldsSettingsSet;
	const {data: preparedCustomFields} = usePreparedCustomFields(id);
	const {data: preparedBuiltInFields} = usePreparedBuiltInFields(id, entityType.id);

	return (
		<Plate
			className={{
				content: styles.wrapper
			}}
			withoutPadding
		>
			<TableTabs
				activeTab={activeTub}
				onActiveTabChange={setActiveTab}
			/>
			{preparedBuiltInFields && activeTub === 0 && (
				<FieldsTable
					setId={id}
					fields={preparedBuiltInFields}
					isBuiltIn
				/>
			)}
			{preparedCustomFields && activeTub === 1 && (
				<FieldsTable
					setId={id}
					fields={preparedCustomFields}
				/>
			)}
		</Plate>
	);
};
