import IShape from '@tehzor/tools/interfaces/plans/IShape';
import classNames from 'classnames';

interface ISectorProps {
	shape: IShape;
	sectorIds: (string | undefined)[];
	commentedSectors: (string | undefined)[];
	entitySectorIds?: (string | undefined)[];
	isActiveLayer?: boolean;
	handleSectorMouseDown?: (
		event: React.MouseEvent<SVGElement, MouseEvent>,
		sectorName: string,
		sectorId: string
	) => void;
}

export const Sector = ({
	shape,
	sectorIds,
	commentedSectors,
	entitySectorIds,
	isActiveLayer,
	handleSectorMouseDown
}: ISectorProps) => {
	const layerClassNames = ['plan-viewer__sector'];

	if (isActiveLayer) {
		layerClassNames.push('plan-viewer__sector_hoverable');
	}
	if (sectorIds.includes(shape.id)) {
		layerClassNames.push('plan-viewer__sector_selected');
	}
	if (commentedSectors.includes(shape.id)) {
		layerClassNames.push('plan-viewer__sector_commented');
	}
	if (entitySectorIds?.includes(shape.id)) {
		layerClassNames.push('plan-viewer__sector_entity');
	}

	return isActiveLayer && handleSectorMouseDown
		? (
			<g
				key={shape.id}
				dangerouslySetInnerHTML={{__html: shape.svg}}
				className={classNames(layerClassNames)}
				id={shape.id}
				name={shape.name}
				onMouseDown={event => handleSectorMouseDown(event, shape.name, shape.id)}
			/>
		) : (
			<g
				key={shape.id}
				dangerouslySetInnerHTML={{__html: shape.svg}}
				className={classNames(layerClassNames)}
				id={shape.id}
				name={shape.name}
			/>
		);
};
