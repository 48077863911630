import LocationSelect, {
	ILocationSelectProps,
	ILocationSelectTranslationProps
} from '@tehzor/ui-components/src/components/LocationSelect/LocationSelect';

import {useTranslation} from 'react-i18next';

type TranslatedLocationSelectProps = Omit<ILocationSelectProps, 'translations'>;

export const TranslatedLocationSelect = (props: TranslatedLocationSelectProps) => {
	const {children, ...restProps} = props;
	const {t} = useTranslation();
	const translations: ILocationSelectTranslationProps = {
		addCommentPrompt: t('locationSelect.addCommentPrompt'),
		selectedSectors: t('locationSelect.selectedSectors'),
		selectionMethod: t('locationSelect.selectionMethod'),
		mapMode: t('locationSelect.mapMode'),
		sectors: t('locationSelect.sectors'),
		dialogAcceptBtnLabel: t('locationSelect.dialogAcceptBtnLabel'),
		dialogCloseBtnLabel: t('locationSelect.dialogCloseBtnLabel'),
		dialogRejectBtnLabel: t('locationSelect.dialogRejectBtnLabel'),
		planDialogAcceptBtnLabel: t('locationSelect.planDialogAcceptBtnLabel'),
		planDialogCloseBtnLabel: t('locationSelect.planDialogCloseBtnLabel'),
		planDialogTitle: t('locationSelect.planDialogTitle'),
		planDialogSearchPlaceholder: t('locationSelect.planDialogSearchPlaceholder'),
		display: t('locationSelect.display'),
		layersT: t('locationSelect.layersT'),
		paramsBtn: t('locationSelect.paramsBtn'),
		markedPoints: t('locationSelect.markedPoints'),
		pointsT: t('locationSelect.pointsT'),
		planMode: t('locationSelect.planMode'),
		result: t('locationSelect.result'),
		translatedPoint: t('locationSelect.point'),
		layersMap: {
			axes: t('planLayers.axes.name'),
			generated: t('planLayers.generated.name'),
			custom: t('planLayers.custom.name')
		}
	};
	return (
		<LocationSelect
			translations={translations}
			{...restProps}
		>
			{children}
		</LocationSelect>
	);
};
