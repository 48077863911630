import {IGetFieldEntityTypesResponse} from '@src/api/backend/fieldTypes';
import {useFieldsPermissions} from '@/shared/hooks/permissions/useFieldsPermissions';
import {useQuery} from '@tanstack/react-query';
import IError from '@tehzor/tools/interfaces/IError';
import {fieldsQueryKeys} from '../../../keys/index';

export const useFieldEntityTypes = <T = IGetFieldEntityTypesResponse>(
	select?: (data: IGetFieldEntityTypesResponse) => T
) => {
	const {canViewFields} = useFieldsPermissions();

	return useQuery<IGetFieldEntityTypesResponse, IError, T>({
		queryKey: fieldsQueryKeys.types.entityTypes(),
		meta: {
			error: 'при загрузке типов сущностей для полей'
		},
		select,
		enabled: canViewFields
	});
};
