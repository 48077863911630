import {useObjectAppHeader} from '@src/components/AppHeader/hooks/useObjectAppHeader';
import {useObjectSectionsMenu} from '@src/core/hooks/useObjectSectionsMenu';
import {useEffect} from 'react';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import ObjectPageBreadcrumbs from '../ProblemsPage/components/ObjectPageBreadcrumbs';
import {AddButton} from './components/AddButton';
import {PlansTable} from './components/table/PlansTable';
import './PlansPage.less';
import {addErrorToast} from '@tehzor/ui-components';
import {useTranslation} from 'react-i18next';

export const PlansPage = () => {
	const {t} = useTranslation();
	const {objectId} = useStrictParams<{objectId?: string}>();

	const sectionsMenu = useObjectSectionsMenu(objectId || '');
	useObjectAppHeader(objectId || '', {sectionsMenu});

	useEffect(() => {
		if (!objectId) {
			addErrorToast({
				title: t('toast.plansPage.error.title'),
				description: t('toast.plansPage.error.description')
			});
		}
	}, [objectId]);

	if (!objectId) {
		return null;
	}

	return (
		<div className="page-cont plans-page">
			<ObjectPageBreadcrumbs objectId={objectId}>
				<AddButton />
			</ObjectPageBreadcrumbs>
			<PlansTable />
		</div>
	);
};
