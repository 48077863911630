import {ExportDialog} from '@src/components/ExportDialog';
import {useExportProblems} from '@src/core/hooks/mutations/problems/useExportProblems';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {ICheckEntity} from '@src/interfaces/ICheckEntity';
import IExportTemplate, {
	ExportTemplatesDestinationId
} from '@tehzor/tools/interfaces/IExportTemplate';
import IInspection from '@tehzor/tools/interfaces/inspections/IInspection';
import {IProblem} from '@tehzor/tools/interfaces/problems/IProblem';
import {useCallback, useEffect, useState, ReactNode} from 'react';
import {useExportInspections} from '@src/core/hooks/mutations/inspections/useExportInspections';
import {useExportCheck} from '@src/core/hooks/mutations/checks/useExportCheck';
import {useExportInspection} from '@src/core/hooks/mutations/inspection/useExportInspection';

function useDestinations(selectedEntities: ICheckEntity[]) {
	const [destinations, setDestinations] = useState<ExportTemplatesDestinationId[]>([
		ExportTemplatesDestinationId.CHECK
	]);

	useEffect(() => {
		let hasProblems = false;
		let hasInspections = false;
		for (const item of selectedEntities) {
			if (item.type === 'problem') {
				hasProblems = true;
				if (hasInspections) {
					break;
				}
			}
			if (item.type === 'inspection') {
				hasInspections = true;
				if (hasProblems) {
					break;
				}
			}
		}
		// Только экспорт проверки
		if (hasProblems && hasInspections) {
			return setDestinations([ExportTemplatesDestinationId.CHECK]);
		}
		// Экспорт проверки и списка нарушений
		if (hasProblems) {
			return setDestinations([
				ExportTemplatesDestinationId.CHECK,
				ExportTemplatesDestinationId.PROBLEMS
			]);
		}
		// Экспорт проверки и списка осмотров
		if (hasInspections && selectedEntities.length > 1) {
			return setDestinations([
				ExportTemplatesDestinationId.CHECK,
				ExportTemplatesDestinationId.INSPECTIONS
			]);
		}
		if (hasInspections && selectedEntities.length === 1) {
			return setDestinations([
				ExportTemplatesDestinationId.CHECK,
				ExportTemplatesDestinationId.INSPECTION
			]);
		}
		return undefined;
	}, [selectedEntities]);

	return destinations;
}

const splitSelectedEntities = (selectedEntities: ICheckEntity[]) =>
	selectedEntities.reduce<{
		problems: string[];
		inspections: string[];
	}>(
		(prev, item) => {
			switch (item.type) {
				case 'problem':
					prev.problems.push(item.data.id);
					break;
				case 'inspection':
					prev.inspections.push(item.data.id);
			}
			return prev;
		},
		{problems: [], inspections: []}
	);

/**
 * Хук для экспорта проверки или его нарушений/осмотров в зависимости от того, что выбрано
 *
 * @param objectId id объекта
 * @param selectedEntities выбранные нарушения/осмотры
 */
export function useCheckCombinedExport(
	objectId: string,
	selectedEntities: ICheckEntity[]
): [ReactNode, (id: string) => void] {
	const [exportingId, setExportingId] = useState<string>();
	const destinations = useDestinations(selectedEntities);

	const [isOpen, setOpen] = useState<boolean>(false);
	const dispatch = useAppDispatch();
	const {mutateAsync: exportProblems} = useExportProblems();
	const {mutateAsync: exportInspections} = useExportInspections();
	const {mutateAsync: exportCheck} = useExportCheck();
	const {mutateAsync: exportInspection} = useExportInspection();

	const openDialog = useCallback((id: string) => {
		setExportingId(id);
		setOpen(true);
	}, []);

	const closeDialog = useCallback(() => {
		setExportingId(undefined);
		setOpen(false);
	}, []);

	const handleExport = useCallback(
		async (template: IExportTemplate, email?: string, createDocument?: boolean) => {
			if (template.destination === ExportTemplatesDestinationId.CHECK) {
				if (exportingId) {
					const {problems, inspections} = splitSelectedEntities(selectedEntities);
					await exportCheck({
						templateId: template.id,
						createDocument,
						email,
						objectId,
						checkId: exportingId,
						problems,
						inspections
					});
				}
			} else if (template.destination === ExportTemplatesDestinationId.PROBLEMS) {
				const selected = selectedEntities.map(item => {
					const {id} = item.data as IProblem;
					return id;
				});
				await exportProblems({
					templateId: template.id,
					objectId,
					createDocument,
					email,
					filters: undefined,
					sort: undefined,
					selected
				});
			} else if (template.destination === ExportTemplatesDestinationId.INSPECTIONS) {
				const selected = selectedEntities.map(item => {
					const {id} = item.data as IInspection;
					return id;
				});
				await exportInspections({
					templateId: template.id,
					createDocument,
					email,
					objectId,
					filters: undefined,
					sort: undefined,
					selected
				});
			} else if (template.destination === ExportTemplatesDestinationId.INSPECTION) {
				const {id} = selectedEntities[0].data as IInspection;
				await exportInspection({
					templateId: template.id,
					createDocument,
					email,
					objectId,
					inspectionId: id
				});
			}
		},
		[
			exportingId,
			selectedEntities,
			dispatch,
			objectId,
			exportProblems,
			exportInspections,
			exportCheck
		]
	);

	const dialog = (
		<ExportDialog
			pageKey="report"
			destinations={destinations}
			isOpen={isOpen}
			onExport={handleExport}
			onClose={closeDialog}
		/>
	);

	return [dialog, openDialog];
}
