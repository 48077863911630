import * as types from '../constants';
import {TempFileDestination} from '@tehzor/tools/enums/TempFileDestination';
import IError from '@tehzor/tools/interfaces/IError';
import {createApiAction} from '@src/store/middlewares/api';
import {IAddTempFilesResponse, makeTempFilesAddRequest} from '@src/api/backend/tempFile';
import {TempFileLocation} from '@tehzor/tools/enums/TempFileLocation';
import {addErrorToast} from '@tehzor/ui-components';
import {t} from 'i18next';

export type IAddTempFilesPayload = IAddTempFilesResponse;

const request = () => ({type: types.ADD_REQUEST});

const success = (response: IAddTempFilesResponse) => ({
	type: types.ADD_SUCCESS,
	payload: response
});

function failure(error: IError) {
	if (
		error.statusCode !== 401 &&
		(!(error instanceof Error) || error.name !== 'CancellationError')
	) {
		addErrorToast({title: t('toast.addTempFile.error.title')});
	}
	return {
		type: types.ADD_FAILURE,
		payload: {...error}
	};
}

/**
 * Добавляет временный файл
 *
 * @param file файл
 * @param destination назначение, где будет использоваться файл
 * @param onInitialized функция, вызываемая после инициализации запроса,
 * первым аргументом передаётся abort-функция
 * @param onProgress функция, вызываемая при обновлении процесса выгрузки
 */
export const addTempFile = (
	file: File,
	destination: TempFileDestination,
	onInitialized?: (abortRequest: () => void) => void,
	onProgress?: (progress: number) => void,
	original?: File,
	drawData?: string,
	location?: TempFileLocation
) =>
	createApiAction<IAddTempFilesResponse>(request, success, failure, () =>
		makeTempFilesAddRequest(
			file,
			destination,
			onInitialized,
			onProgress,
			original,
			drawData,
			location
		)
	);
