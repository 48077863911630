import {useMemo} from 'react';
import {sortByCreation} from '../../../../utils/sortByCreation';
import {useStructureInspectionsEntities} from '../../inspections/hooks/useStructureInspectionsEntities';
import {useStructureProblemsEntities} from '../../problems/hooks/useStructureProblemsEntities';

export const useStructureEntities = (objectId: string, structureId: string) => {
	const {data: inspections} = useStructureInspectionsEntities(objectId, structureId);
	const {data: problems} = useStructureProblemsEntities(objectId, structureId);

	return useMemo(
		() => [...(inspections || []), ...(problems || [])].sort(sortByCreation),
		[inspections, problems]
	);
};
