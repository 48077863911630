import {useCallback, useState} from 'react';
import {useEditableObject} from '../EditableObject/hooks/useEditableObject';
import {ActionButtons, Button, ModalDialog} from '@tehzor/ui-components';
import {makeDefaultData} from '@src/core/hooks/states/useEditableObjectState';
import {useTranslation} from 'react-i18next';
import {useAddObject} from '@src/core/hooks/mutations/object/useAddObject';
import {useTranslatedConfirmDialog} from '@src/core/hooks/translations/useTranslatedConfirmDialog';
import './AddingObjectDialog.less';

interface IAddingObjectDialogProps {
	objectId?: string;
	companyId?: string;

	isOpen: boolean;
	onClose: () => void;
	onAfterClose?: () => void;
}

export const AddingObjectDialog = ({
	objectId,
	companyId,
	isOpen,
	onClose,
	onAfterClose
}: IAddingObjectDialogProps) => {
	const {t} = useTranslation();
	const [saving, setSaving] = useState(false);
	const {mutateAsync: addObject} = useAddObject();

	const defaultData = makeDefaultData();
	const [fields, getSavingData, reset, isBlocking] = useEditableObject(
		objectId,
		companyId,
		defaultData,
		saving,
		true
	);

	const save = useCallback(async () => {
		setSaving(true);

		try {
			const savingData = await getSavingData();

			if (savingData) {
				if (objectId) {
					savingData.parentId = objectId;
				}
				await addObject(savingData);

				onClose();
			}
		} catch (e) {
			throw new Error(e);
		} finally {
			setSaving(false);
		}
	}, [getSavingData]);

	const [closingDialog, getClosingConfirmation] = useTranslatedConfirmDialog();

	const handleClose = useCallback(async () => {
		if (onClose && (!isBlocking || (await getClosingConfirmation()))) {
			onClose();
		}
	}, [isBlocking, onClose]);

	const handleAfterClose = useCallback(() => {
		reset();
		if (onAfterClose) {
			onAfterClose();
		}
	}, [reset, onAfterClose]);

	return (
		<ModalDialog
			className="adding-object-dialog"
			open={isOpen}
			title={t('addingObjectDialog.title')}
			footer={
				<ActionButtons>
					<Button
						type="cancel"
						label={t('cancelBtn.label')}
						disabled={saving}
						onClick={handleClose}
					/>
					<Button
						type="accent-blue"
						label={t('applyBtn.label')}
						disabled={saving}
						onClick={save}
					/>
				</ActionButtons>
			}
			fullScreenOnTablet
			onRequestClose={handleClose}
			onAfterClose={handleAfterClose}
		>
			{fields}
			{closingDialog}
		</ModalDialog>
	);
};
