import {IDeleteCategoryResponse} from '@src/api/backend/categories';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {
	convertToSave,
	IEditableCategoriesSetAction,
	IEditableCategoriesSetState,
	makeDefaultData
} from '@src/core/hooks/states/useCategoriesSetState';
import ICategory from '@tehzor/tools/interfaces/categories/ICategory';
import {ICategoriesSet} from '@tehzor/tools/interfaces/categoriesSets/ICategoriesSet';
import {IDeleteCategoriesSetResponse} from '@src/api/backend/categoriesSets';
import {Dispatch, useCallback} from 'react';
import {useParams} from 'react-router';
import {ICategoryOrder} from '@tehzor/tools/interfaces/categories/ICategoryOrder';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {useExtractFullCategoriesSetsAsArray} from '@src/core/hooks/queries/categorySets/hook';
import {useAddCategory} from '@src/core/hooks/mutations/categories/useAddCategory';
import {useDeleteCategory} from '@src/core/hooks/mutations/categories/useDeleteCategory';
import {useEditCategory} from '@src/core/hooks/mutations/categories/useEditCategory';
import {useEditOrderCategories} from '@src/core/hooks/mutations/categories/useEditOrderCategories';
import {useAddCategoriesSet} from '@src/core/hooks/mutations/categoriesSets/useAddCategoriesSet';
import {useDeleteCategoriesSet} from '@src/core/hooks/mutations/categoriesSets/useDeleteCategoriesSet';
import {useEditCategoriesSet} from '@src/core/hooks/mutations/categoriesSets/useEditCategoriesSet';
import {useDeleteCategories} from '@src/core/hooks/mutations/categories/useDeleteCategories';
import {addErrorToast} from '@tehzor/ui-components';
import {useTranslation} from 'react-i18next';

interface IUseUpdateContent {
	updateCategoriesSet: (isCreatable?: boolean) => Promise<ICategoriesSet | undefined>;
	removeCategoriesSet: (id: string) => Promise<IDeleteCategoriesSetResponse | undefined>;
	updateCategory: (id: string, index: number) => Promise<ICategory | undefined>;
	updateCategoriesOrder: (
		order: ICategoryOrder[]
	) => Promise<INormalizedData<ICategory> | undefined>;
	removeCategory: (id: string) => Promise<IDeleteCategoryResponse | undefined>;
}

const useUpdateContent = (
	editingState?: IEditableCategoriesSetState,
	editingDispatch?: Dispatch<IEditableCategoriesSetAction>,
	categoriesSet?: ICategoriesSet,
	categories?: ICategory[]
): IUseUpdateContent => {
	const {t} = useTranslation();
	const {replacePath} = useChangePath();
	const defaultData = makeDefaultData(categoriesSet, categories);
	const {objectId, categoriesSetId: paramCategoriesSetId} = useParams<{
		objectId: string;
		categoriesSetId: string;
	}>();
	const {data: fullCategoriesSets} = useExtractFullCategoriesSetsAsArray();

	const categoriesSetId = categoriesSet?.id || paramCategoriesSetId;

	const {mutateAsync: addCategory} = useAddCategory();
	const {mutateAsync: deleteCategories} = useDeleteCategories();
	const {mutateAsync: deleteCategory} = useDeleteCategory();
	const {mutateAsync: editCategory} = useEditCategory();
	const {mutateAsync: editCategoriesOrder} = useEditOrderCategories();
	const {mutateAsync: addCategoriesSet} = useAddCategoriesSet();
	const {mutateAsync: deleteCategoriesSet} = useDeleteCategoriesSet();
	const {mutateAsync: editCategoriesSet} = useEditCategoriesSet();

	const checkErrors = useCallback(
		(state: IEditableCategoriesSetState, dispatch: Dispatch<IEditableCategoriesSetAction>) => {
			if (!state.errors) {
				return false;
			}

			let hasError = false;
			const {shared, companies, objects, name} = state;

			if (!shared) {
				dispatch({type: 'update-error', field: 'companies'});
				dispatch({type: 'update-error', field: 'objects'});
				hasError = !companies.length && !objects.length;
			}

			if (!name) {
				dispatch({type: 'update-error', field: 'name'});
				hasError = true;
			}

			return hasError;
		},
		[]
	);

	const updateCategoriesSet = useCallback(
		async (isCreatable = false) => {
			if (
				!editingState ||
				!editingDispatch ||
				(!categoriesSetId && !isCreatable) ||
				checkErrors(editingState, editingDispatch)
			) {
				return undefined;
			}
			if (!categoriesSetId) {
				const {categories: editingCategories, ...rest} = editingState;

				try {
					const createdSet = await addCategoriesSet(rest);

					replacePath(
						objectId
							? `/objects/${objectId}/categories-sets/${createdSet.id}`
							: `/manage/categories-sets/${createdSet.id}`
					);

					return createdSet;
				} catch (e) {
					addErrorToast({
						title: t('toast.addCategoriesSet.error.title')
					});

					return undefined;
				}
			}

			const [savingCategoriesSet] = convertToSave(editingState, defaultData);

			try {
				const createdSet = await editCategoriesSet(savingCategoriesSet);

				replacePath(
					objectId
						? `/objects/${objectId}/categories-sets/${createdSet.id}`
						: `/manage/categories-sets/${createdSet.id}`
				);

				return createdSet;
			} catch (e) {
				addErrorToast({
					title: t('toast.editCategoriesSet.error.title')
				});

				return undefined;
			}
		},
		[editingState]
	);

	const removeCategoriesSet = useCallback(async (id: string) => {
		try {
			const set = fullCategoriesSets?.find(item => item.id === id);

			if (set?.categories?.length) {
				await deleteCategories(id);
			}
			const res = await deleteCategoriesSet(id);
			return res;
		} catch (e) {
			addErrorToast({
				title: t('toast.deleteCategoriesSet.error.title')
			});

			return undefined;
		}
	}, []);

	const updateCategory = useCallback(
		async (id: string, index: number) => {
			if (!categoriesSetId || !editingState) {
				return undefined;
			}

			const {categories: editingCategories} = editingState;

			if (index === -1) {
				return undefined;
			}
			const category = editingCategories[index];

			if (category.isLocal) {
				try {
					const res = await addCategory(category);
					return res;
				} catch (e) {
					addErrorToast({
						title: t('toast.addCategory.error.title')
					});
					return undefined;
				}
			}

			try {
				const res = await editCategory(category);
				return res;
			} catch (e) {
				addErrorToast({
					title: t('toast.editCategory.error.title')
				});
				return undefined;
			}
		},
		[editingState]
	);

	const updateCategoriesOrder = useCallback(
		async (order: ICategoryOrder[]) => {
			if (!categoriesSetId) {
				return undefined;
			}

			try {
				const res = await editCategoriesOrder({categoriesSetId, order});
				return res;
			} catch (e) {
				addErrorToast({
					title: t('toast.editCategoriesOrder.error.title')
				});
				return undefined;
			}
		},
		[categoriesSetId]
	);

	const removeCategory = useCallback(
		async (id: string) => {
			if (!categoriesSetId || !editingState) {
				return undefined;
			}

			const {categories: editingCategories} = editingState;
			const categoryIndex = editingCategories.findIndex(item => item.id === id);

			if (categoryIndex === -1 || id[0] === '_') {
				return undefined;
			}

			try {
				const res = await deleteCategory(id);
				return res;
			} catch (e) {
				addErrorToast({
					title: t('toast.deleteCategory.error.title')
				});
				return undefined;
			}
		},
		[editingState, categoriesSetId]
	);

	return {
		updateCategoriesSet,
		removeCategoriesSet,
		updateCategory,
		updateCategoriesOrder,
		removeCategory
	};
};

export default useUpdateContent;
