import {useCallback} from 'react';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {logout} from '@src/store/modules/auth/actions';
import {IconMenu, MenuItem} from '@tehzor/ui-components';
import {useTranslation} from 'react-i18next';
import {useTranslatedConfirmDialog} from '@src/core/hooks/translations/useTranslatedConfirmDialog';

interface IProfileMenuProps {
	onEdit?: () => void;
}

const editIcon = <i className="tz-edit-16" />;
const logoutIcon = <i className="tz-exit" />;

export const ProfileMenu = ({onEdit}: IProfileMenuProps) => {
	const {t} = useTranslation();

	const dispatch = useAppDispatch();
	const [logoutDialog, getLogoutConfirmation] = useTranslatedConfirmDialog({
		title: t('header.exitConfirmation'),
		message: ''
	});

	const handleEdit = useCallback(() => {
		if (onEdit) {
			onEdit();
		}
	}, [onEdit]);

	const handleLogout = useCallback(async () => {
		if (await getLogoutConfirmation()) {
			await dispatch(logout());
		}
	}, []);

	const items = [];

	items.push(
		<MenuItem
			key="edit"
			icon={editIcon}
			onClick={handleEdit}
		>
			{t('profilePage.profileMenu.menuItem.edit')}
		</MenuItem>
	);
	items.push(
		<MenuItem
			key="logout"
			icon={logoutIcon}
			onClick={handleLogout}
		>
			{t('profilePage.profileMenu.menuItem.logout')}
		</MenuItem>
	);

	return items.length ? (
		<>
			<IconMenu>{items}</IconMenu>
			{logoutDialog}
		</>
	) : null;
};
