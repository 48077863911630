import {EntitiesTable} from '@tehzor/ui-components';
import {useRef} from 'react';
import {Column} from 'react-table';
import '../../WarrantyClaimsPage.less';
import {IPreparedWarrantyClaimEntity} from '../../interfaces/IPreparedWarrantyClaimEntity';
import classNames from 'classnames';
import {useStickyBrowserScrollBar} from '@src/pages/WarrantyClaimsPage/hooks/useStickyBrowserScrollBar';

interface IClaimsEntitiesTable {
	isDesktop: boolean;
	className?: string;
	columns: Array<Column<IPreparedWarrantyClaimEntity>>;
	data: IPreparedWarrantyClaimEntity[];
	selectedRows?: string[];
	sort?: Record<string, boolean>;
	onRowClick?: (data: IPreparedWarrantyClaimEntity) => void;
	onSelectedRowsChange?: (value: string[]) => void;
	onSortChange?: (value: Record<string, boolean>) => void;
}
export const ClaimsEntitiesTable = (props: IClaimsEntitiesTable) => {
	const {
		isDesktop,
		className,
		columns,
		data,
		selectedRows,
		sort,
		onRowClick,
		onSelectedRowsChange,
		onSortChange
	} = props;

	const tableWrapperRef = useRef<HTMLDivElement | null>(null);
	const scrollbarRef = useRef<HTMLDivElement | null>(null);

	const {isCustomScrollbarVisible, customScrollWidth} = useStickyBrowserScrollBar(
		tableWrapperRef,
		scrollbarRef
	);

	const desktopClassName = isDesktop ? className : undefined;
	const horizontalScrollbarClass = classNames('w-claims-page__scrollbar-horizontal', {
		'w-claims-page__scrollbar-horizontal--hidden': !isCustomScrollbarVisible
	});

	return (
		<div
			ref={tableWrapperRef}
			className="w-claims-page__scrollbar-container"
		>
			<EntitiesTable
				className={desktopClassName}
				columns={columns}
				data={data}
				selectedRows={selectedRows}
				sort={sort}
				selectable={isDesktop}
				responsive={!isDesktop}
				onRowClick={onRowClick}
				onSelectedRowsChange={onSelectedRowsChange}
				onSortChange={onSortChange}
			/>
			<div
				className={horizontalScrollbarClass}
				ref={scrollbarRef}
			>
				<div
					style={{
						minWidth: `${customScrollWidth}px`
					}}
					className="w-claims-page__dummy-scrollbar"
				/>
			</div>
		</div>
	);
};
