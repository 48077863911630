import {CSSProperties, Dispatch, useCallback} from 'react';
import {IEditableEntityAction} from '@tehzor/tools/core/states/editableEntityState';
import {
	EditableFieldLabel,
	Select2,
	SelectPopup,
	SelectOption,
	TextFieldWithForwardedRef
} from '@tehzor/ui-components';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {useTranslatedObjectStagesArray} from '@src/core/hooks/translations/useTranslatedObjectStagesArray';
import {IProcess, ProcessIds} from '@tehzor/tools/interfaces/process/ProcessId';

const arrowIcon = <i className="tz-simple-arrow-20" />;

interface ICheckListProcessSelectProps<S, E> {
	field?: keyof S;
	value?: ProcessIds;
	className?: string;
	style?: CSSProperties;
	label?: string;
	editingDispatch: Dispatch<IEditableEntityAction<S, E>>;
	required?: boolean;
	disabled?: boolean;
	hasError?: boolean;
	stage: ObjectStageIds;
}

const getInputLabel = (
	value: ProcessIds | undefined,
	processes: IProcess[]
): string | undefined => {
	if (!value) {
		return undefined;
	}

	const selectedProcess = processes.find(process => process.id === value);
	return selectedProcess?.name;
};

export const CheckListProcessSelect = <
	S extends {
		processId?: ProcessIds;
	},
	E
>({
	className,
	style,
	field = 'processId',
	label = 'Процесс',
	value,
	editingDispatch,
	required,
	disabled,
	hasError,
	stage
}: ICheckListProcessSelectProps<S, E>) => {
	const translatedStages = useTranslatedObjectStagesArray();
	const selectedStageObject = translatedStages.find(stageObj => stageObj.id === stage);

	const handleChange = useCallback(
		(v: string) => {
			editingDispatch({type: 'update', field, value: v});
			if (required) {
				editingDispatch({type: 'update-error', field});
			}
		},
		[editingDispatch, field, required]
	);

	const isError = required && hasError ? 'Выберите процесс' : undefined;

	return (
		<div
			className={className}
			style={style}
		>
			<EditableFieldLabel>{label}</EditableFieldLabel>
			{selectedStageObject && (
				<SelectPopup
					noHeader
					trigger={
						<TextFieldWithForwardedRef
							elementType="div"
							value={getInputLabel(value, selectedStageObject.processes)}
							icon={arrowIcon}
							error={isError}
							disabled={disabled}
						/>
					}
				>
					<Select2
						value={value}
						onChange={handleChange}
					>
						{selectedStageObject.processes.map(process => (
							<SelectOption
								key={process.id}
								itemKey={process.id}
								content={process.name}
								inputType="radio"
							/>
						))}
					</Select2>
				</SelectPopup>
			)}
		</div>
	);
};
