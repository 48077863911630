import {useState} from 'react';
import {
	ColumnDef,
	useReactTable,
	getCoreRowModel,
	flexRender,
	ExpandedState,
	getExpandedRowModel
} from '@tanstack/react-table';
import './TasksScheduleTable.less';
import {ScheduleHeader} from '@src/pages/TasksPage/components/tabs/TasksTablePage/components/ScheduleHeader';

interface ITasksScheduleTableProps<D extends {id: string; subRows?: D[]}> {
	columns: Array<ColumnDef<D>>;
	bodyColumns: Array<ColumnDef<D>>;
	data: D[];
}

export const TasksScheduleTable = <D extends {id: string; subRows?: D[]}>(
	props: ITasksScheduleTableProps<D>
) => {
	const {data, columns, bodyColumns} = props;

	const [expanded, setExpanded] = useState<ExpandedState>({});
	const table = useReactTable<D>({
		data,
		columns,
		state: {
			expanded
		},
		onExpandedChange: setExpanded,
		getCoreRowModel: getCoreRowModel(),
		getExpandedRowModel: getExpandedRowModel(),
		getSubRows: row => row.subRows
	});

	const monthTable = useReactTable<D>({
		data,
		columns: bodyColumns,
		state: {
			expanded
		},
		onExpandedChange: setExpanded,
		getCoreRowModel: getCoreRowModel(),
		getExpandedRowModel: getExpandedRowModel(),
		getSubRows: row => row.subRows
	});

	return (
		<div className="tasks-shedule-table-wrap">
			<table className="tasks-shedule-table-info">
				<thead className="tasks-shedule-table-info_head">
					{table.getHeaderGroups().map(el => (
						<tr
							className="tasks-shedule-table-info_head-row"
							key={el.id}
						>
							{el.headers.map(header => (
								<th
									className="tasks-shedule-table-info_head-row_cell"
									key={header.id}
									colSpan={header.colSpan}
								>
									{flexRender(
										header.column.columnDef.header,
										header.getContext()
									)}
								</th>
							))}
						</tr>
					))}
				</thead>
				<tbody className="tasks-shedule-table-info_body">
					{table.getRowModel().rows.map(row => (
						<tr
							className="tasks-shedule-table-info_body-row"
							key={row.id}
						>
							{row.getVisibleCells().map(cell => (
								<td
									className="tasks-shedule-table-info_body-row_cell"
									key={cell.id}
								>
									{flexRender(cell.column.columnDef.cell, cell.getContext())}
								</td>
							))}
						</tr>
					))}
				</tbody>
			</table>

			<div className="tasks-shedule-table-wrapper-month">
				<ScheduleHeader />
				<div className="tasks-shedule-table-wrap-month">
					<table className="tasks-shedule-table-month">
						<thead className="tasks-shedule-table-month_head">
							{monthTable.getHeaderGroups().map(el => (
								<tr
									className="tasks-shedule-table-month_head-row"
									key={el.id}
								>
									{el.headers.map(header => (
										<th
											className="tasks-shedule-table-month_head-row_cell"
											key={header.id}
											colSpan={header.colSpan}
										>
											{flexRender(
												header.column.columnDef.header,
												header.getContext()
											)}
										</th>
									))}
								</tr>
							))}
						</thead>
						<tbody className="tasks-shedule-table-month_body">
							{monthTable.getRowModel().rows.map(row => (
								<tr
									className="tasks-shedule-table-month_body-row"
									key={row.id}
								>
									{row.getVisibleCells().map(cell => (
										<td
											className="tasks-shedule-table-month_body-row_cell"
											key={cell.id}
										>
											{flexRender(
												cell.column.columnDef.cell,
												cell.getContext()
											)}
										</td>
									))}
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</div>
		</div>
	);
};
