import {queryClient} from '@src/api/QueryClient';
import {IGetCheckItemsResponse} from '@src/api/backend/checkItems';
import {makeCheckItemCopyRequest} from '@src/api/backend/checkItems/copy';
import {checkItemsQueryKeys} from '@src/api/cache/checkItems/keys';
import {useMutation} from '@tanstack/react-query';
import IError from '@tehzor/tools/interfaces/IError';
import {useCallback} from 'react';
import {addErrorToast, addSuccessToast} from '@tehzor/ui-components';
import {useTranslation} from 'react-i18next';

interface ICheckItemCopyParams {
	checkListId: string;
	id: string;
	subIds?: string[];
}

export const useCopyCheckItem = () => {
	const {t} = useTranslation();
	const mutation = useMutation<IGetCheckItemsResponse, IError, ICheckItemCopyParams>({
		mutationFn: (params: ICheckItemCopyParams) =>
			makeCheckItemCopyRequest(params.checkListId, params.id, params.subIds),

		onSuccess: data => {
			queryClient.setQueryData<IGetCheckItemsResponse>(
				checkItemsQueryKeys.list(),
				oldData => {
					if (oldData) {
						const uniqueIdsSet = new Set([...oldData.allIds, ...data.allIds]);
						const uniqueIdsArray = Array.from(uniqueIdsSet);

						return {
							allIds: uniqueIdsArray,
							byId: {...oldData.byId, ...data.byId}
						};
					}
					return oldData;
				}
			);
			addSuccessToast({title: t('toast.copyCheckItem.success.title')});
		},
		onError: () => {
			addErrorToast({title: t('toast.copyCheckItem.error.title')});
		}
	});
	/**
	 * Возвращает функцию для копирования категории внутри чек-листа
	 *
	 * @param params данные для копирования
	 */
	return useCallback(
		(params: ICheckItemCopyParams) => {
			mutation.mutate(params);
		},
		[mutation]
	);
};
