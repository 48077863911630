import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {PresaleCheckEntitiesVisibility, PresaleCheckState} from './interfaces';

const getInitialState = (): PresaleCheckState => ({
	entitiesVisibility: ['problems', 'inspections']
});

export const presaleCheckSlice = createSlice({
	name: 'presaleCheck',
	initialState: getInitialState(),
	reducers: {
		changeEntitiesVisibility: (
			state: PresaleCheckState,
			action: PayloadAction<PresaleCheckEntitiesVisibility[]>
		) => {
			state.entitiesVisibility = action.payload;
		}
	}
});

export const {changeEntitiesVisibility} = presaleCheckSlice.actions;
export const presaleCheckReducer = presaleCheckSlice.reducer;
