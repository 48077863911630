import IError from '@tehzor/tools/interfaces/IError';
import * as types from '../constants';
import {addErrorToast, addSuccessToast} from '@tehzor/ui-components';
import {t} from 'i18next';

export interface IDeleteWarrantyClaimPayload {
	objectId: string;
	claimId: string;
}

const request = () => ({type: types.DELETE_REQUEST});

const success = (objectId: string, claimId: string, quietly?: boolean) => {
	if (!quietly) {
		addSuccessToast({title: t('toast.deleteWarrantyClaim.success.title')});
	}
	return {
		type: types.DELETE_SUCCESS,
		payload: {objectId, claimId} as IDeleteWarrantyClaimPayload
	};
};

const failure = (error: IError) => {
	addErrorToast({title: t('toast.deleteWarrantyClaim.error.title')});
	return {
		type: types.DELETE_FAILURE,
		payload: error
	};
};

export const deleteWarrantyClaimActions = {request, success, failure};
