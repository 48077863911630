import {queryClient} from '@src/api/QueryClient';
import {makeResponsibilityRuleDeleteRequest} from '@src/api/backend/responsibilityRules';
import {responsibilityRulesQueryKeys} from '@src/api/cache/responsibilityRules/keys';
import {useMutation} from '@tanstack/react-query';
import {addErrorToast, addSuccessToast} from '@tehzor/ui-components';
import {useTranslation} from 'react-i18next';

export interface IDeleteResponsibilityRulesParams {
	ruleId: string;
}

export const useDeleteResponsibilityRules = () => {
	const {t} = useTranslation();

	return useMutation({
		mutationFn: (params: IDeleteResponsibilityRulesParams) =>
			makeResponsibilityRuleDeleteRequest(params.ruleId),
		mutationKey: responsibilityRulesQueryKeys.delete(),
		onSuccess: () => {
			void queryClient.invalidateQueries({queryKey: responsibilityRulesQueryKeys.list()});
			addSuccessToast({title: t('toast.deleteResponsibilityRules.success.title')});
		},
		onError: () => {
			addErrorToast({title: t('toast.deleteResponsibilityRules.error.title')});
		}
	});
};
