import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {fieldsSettingsSetsAction} from '@/entities/FieldsSettingsSets';
import {useCallback} from 'react';
import {ClearSelectionRowsButton as Button} from '@/shared/ui/Buttons/ClearSelectitionRowsButton/ClearSelectionRowsButton';

export const ClearSelectionRowsButton = () => {
	const dispatch = useAppDispatch();
	const {changeSelectedRows} = fieldsSettingsSetsAction;

	const clearSelectionRowsHandler = useCallback(() => {
		dispatch(changeSelectedRows([]));
	}, [dispatch, changeSelectedRows]);

	return <Button onClear={clearSelectionRowsHandler} />;
};
