import {extractPresaleCheckProblemCommentsByProblem} from '../selectors/extractPresaleCheckProblemCommentsByProblem';
import {usePresaleCheckProblemsReplies} from './usePresaleCheckProblemsReplies';

export const usePresaleCheckProblemCommentsByProblem = (
	presaleCheckId?: string,
	objectId?: string
) =>
	usePresaleCheckProblemsReplies(
		objectId,
		presaleCheckId,
		extractPresaleCheckProblemCommentsByProblem
	);
