import {contractsQueryKeys} from '../../keys';
import {updateCachedState} from '@src/utils/updateCachedState';
import {OfflineDataTransferStatus} from '@tehzor/tools/contracts/dataTransferWebWorker/interfaces/IOfflineDataTransferStatuses';
import {requestDeleteContract} from '@src/api/backend/contract';
import {IContract} from '@tehzor/tools/interfaces/contracts';
import {QueryClient} from '@tanstack/react-query';
import {invalidateQueries} from './invalidateQueries';
import {addErrorToast} from '@tehzor/ui-components';
import {useTranslation} from 'react-i18next';

export interface IDeleteContractParams {
	contractId: string;
}

export const useDeleteContractsMutationDefaults = (queryClient: QueryClient) => {
	const key = Date.now().toString(10);
	const {t} = useTranslation();

	queryClient.setMutationDefaults(contractsQueryKeys.delete(), {
		onMutate: (params: IDeleteContractParams) => ({variables: params}),
		mutationFn: async (params: IDeleteContractParams) => {
			await updateCachedState<IContract>(
				contractsQueryKeys,
				key,
				OfflineDataTransferStatus.TRANSFER
			);

			const {contractId} = params;
			return requestDeleteContract(contractId);
		},
		onSuccess: async () => {
			await updateCachedState<IContract>(
				contractsQueryKeys,
				key,
				OfflineDataTransferStatus.TRANSFER_COMPLETE
			);

			await invalidateQueries(queryClient);
		},
		onError: async () => {
			addErrorToast({title: t('toast.deleteContracts.error.title')});
			await updateCachedState<IContract>(
				contractsQueryKeys,
				key,
				OfflineDataTransferStatus.TRANSFER_ERROR
			);
		}
	});
};
