import {ToastContainer as Container, ToastContainerProps as ContainerProps} from 'react-toastify';

interface ToastContainerProps extends ContainerProps {
	withLayout?: boolean;
	layoutClassName?: string;
	containerId: string | number;
}

export const ToastContainer = (props: ToastContainerProps) => {
	const {withLayout, layoutClassName, ...rest} = props;

	if (!withLayout) return <Container {...rest} />;

	return (
		<div className={layoutClassName}>
			<Container {...rest} />
		</div>
	);
};
