import {queryClient} from '@src/api/QueryClient';
import {makeCheckListAddRequest} from '@src/api/backend/checkList';
import {IGetCheckListsResponse} from '@src/api/backend/checkLists';
import {checkListsQueryKeys} from '@src/api/cache/checkLists/keys';
import {useMutation} from '@tanstack/react-query';
import IError from '@tehzor/tools/interfaces/IError';
import {ICheckList} from '@tehzor/tools/interfaces/checkLists/ICheckList';
import {ISavingCheckList} from '@tehzor/tools/interfaces/checkLists/ISavingCheckList';
import {useCallback} from 'react';
import {useChangePath} from '../../useChangePath';
import {addErrorToast, addSuccessToast} from '@tehzor/ui-components';
import {useTranslation} from 'react-i18next';

export const useAddCheckList = () => {
	const {t} = useTranslation();
	const {pushPath} = useChangePath();

	const mutation = useMutation<ICheckList, IError, ISavingCheckList>({
		mutationFn: (params: ISavingCheckList) => makeCheckListAddRequest(params),

		onSuccess: data => {
			queryClient.setQueryData<IGetCheckListsResponse>(
				checkListsQueryKeys.list(),
				oldData => {
					if (oldData) {
						return {
							byId: {...oldData.byId, [data.id]: data},
							allIds: [...oldData.allIds, data.id]
						};
					}
					return oldData;
				}
			);
			pushPath(`/manage/check-lists/${data.id}`);
			addSuccessToast({title: t('toast.addCheckList.success.title')});
		},
		onError: () => {
			addErrorToast({title: t('toast.addCheckList.error.title')});
		}
	});
	/**
	 * Возвращает функцию для создания чеклиста
	 *
	 * @param item данные нового чек-листа
	 */
	return useCallback(
		(item: ISavingCheckList) => {
			mutation.mutate(item);
		},
		[mutation]
	);
};
