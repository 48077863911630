import CellWrap from '@src/components/tableCells/CellWrap';
import {useTranslation} from 'react-i18next';
import {useMemo} from 'react';
import {Column} from 'react-table';
import {ISpaceEntity} from '@src/interfaces/ISpaceEntity';
import WarrantyClaimStatusCell from '@src/components/tableCells/WarrantyClaimStatusCell';
import {ProblemDeadlineCell} from '@src/components/tableCells/ProblemDeadlineCell';
import OwnerAcceptanceStatusCell from '@src/components/tableCells/OwnerAcceptanceStatusCell';
import CheckInfoDesktopCell from '@src/components/tableCells/CheckInfoDesktopCell';
import {PresaleCheckInfoDesktopCell} from '@src/components/tableCells/PresaleCheckInfoDesktopCell';
import OwnerAcceptanceInfoDesktopCell from '@src/components/tableCells/OwnerAcceptanceInfoDesktopCell';
import WarrantyClaimInfoDesktopCell from '@src/components/tableCells/WarrantyClaimInfoDesktopCell';
import InspectionInfoDesktopCell from '@src/components/tableCells/InspectionInfoDesktopCell';
import WorkAcceptanceDesktopCell from '@src/components/tableCells/WorkAcceptanceDesktopCell';
import {WorkAcceptanceStatusCellWrap} from '@src/components/tableCells/WorkAcceptanceStatusCell';
import {WorkAcceptanceDesktopStageCell} from '@src/components/tableCells/WorkAcceptanceDesktopStageCell';
import {WorkAcceptanceDeadlineCell} from '@src/components/tableCells/WorkAcceptanceDeadlineCell';
import HeaderCellWithRowSelectWrap from '@src/components/tableCells/HeaderCellWithRowSelectWrap';
import {ProblemInfoCellWrap} from '@src/components/tableCells/ProblemInfoCellWrap';
import ProblemInfoDesktopCell from '@src/components/tableCells/ProblemInfoDesktopCell';
import InternalAcceptanceInfoDesktopCell from '@src/components/tableCells/InternalAcceptanceInfoDesktopCell';
import {CategoryCell} from '@src/components/tableCells/CategoryCell';
import {useSpaceProblemRepliesAsArray} from '@src/core/hooks/queries/space';
import {InspectionFixCell} from '@src/components/tableCells/InspectionFixCell';
import {ProblemStatusCell} from '@src/components/tableCells/ProblemStatusCell';

export const useDesktopColumns = (objectId: string, spaceId: string) => {
	const {t} = useTranslation();

	const {data: replies} = useSpaceProblemRepliesAsArray(objectId, spaceId);

	return useMemo<Array<Column<ISpaceEntity>>>(
		() => [
			{
				id: 'number',
				Header: HeaderCellWithRowSelectWrap(t('spacePage.desktopTable.header.description')),
				Cell: CellWrap({
					check: CheckInfoDesktopCell,
					'presale-check': PresaleCheckInfoDesktopCell,
					'owner-acceptance': OwnerAcceptanceInfoDesktopCell,
					'internal-acceptance': InternalAcceptanceInfoDesktopCell,
					'warranty-claim': WarrantyClaimInfoDesktopCell,
					'work-acceptance': WorkAcceptanceDesktopCell,
					inspection: InspectionInfoDesktopCell,
					problem: ProblemInfoCellWrap(ProblemInfoDesktopCell, undefined, replies),
					'local-check': CheckInfoDesktopCell,
					'local-presale-check': PresaleCheckInfoDesktopCell,
					'local-owner-acceptance': OwnerAcceptanceInfoDesktopCell,
					'local-internal-acceptance': InternalAcceptanceInfoDesktopCell,
					'local-warranty-claim': WarrantyClaimInfoDesktopCell,
					'local-work-acceptance': WorkAcceptanceDesktopCell,
					'local-problem': ProblemInfoCellWrap(
						ProblemInfoDesktopCell,
						undefined,
						replies
					),
					'local-inspection': InspectionInfoDesktopCell
				}),
				width: 200,
				minWidth: 200
			},
			{
				id: 'status',
				Header: t('spacePage.desktopTable.header.status'),
				Cell: CellWrap({
					'owner-acceptance': OwnerAcceptanceStatusCell,
					'warranty-claim': WarrantyClaimStatusCell,
					'work-acceptance': WorkAcceptanceStatusCellWrap,
					problem: ProblemStatusCell,
					'local-owner-acceptance': OwnerAcceptanceStatusCell,
					'local-warranty-claim': WarrantyClaimStatusCell,
					'local-work-acceptance': WorkAcceptanceStatusCellWrap,
					'local-problem': ProblemStatusCell
				}),
				width: 80,
				minWidth: 80
			},
			{
				id: 'category',
				Header: t('spacePage.desktopTable.header.trade'),
				Cell: CellWrap({
					problem: CategoryCell,
					inspection: CategoryCell,
					'work-acceptance': WorkAcceptanceDesktopStageCell,
					'local-problem': CategoryCell,
					'local-work-acceptance': WorkAcceptanceDesktopStageCell
				}),
				width: 80,
				minWidth: 80
			},
			{
				id: 'deadline',
				Header: t('spacePage.desktopTable.header.deadline'),
				Cell: CellWrap({
					problem: ProblemDeadlineCell,
					'work-acceptance': WorkAcceptanceDeadlineCell,
					'local-problem': ProblemDeadlineCell,
					'local-work-acceptance': WorkAcceptanceDeadlineCell
				}),
				width: 80,
				minWidth: 80
			},
			{
				id: 'performers',
				Header: t('spacePage.desktopTable.header.performers'),
				Cell: CellWrap({
					problem: InspectionFixCell,
					inspection: InspectionFixCell,
					'local-problem': InspectionFixCell,
					'local-inspection': InspectionFixCell
				}),
				width: 80,
				minWidth: 80
			}
		],
		[replies]
	);
};
