import EntitiesFilters, {
	ActualFixDateFilter,
	BooleanFilter,
	CreatedByFilter,
	CreationDateFilter,
	PlannedFixDateFilter,
	ObjectsFilter,
	PlansFilter,
	StatusesFilter
} from '@src/components/EntitiesFilters';
import {ExpiredFilter} from '@src/components/EntitiesFilters/components/ExpiredFilter';
import {useEntitiesFiltersCtx} from '@src/components/EntitiesFilters/utils/entitiesFiltersCtx';
import {dictionaryKeys} from '@src/constants/translations/dictionaryKeys';
import {useObjectStagesAsArray} from '@src/core/hooks/queries/objectStages/hooks';
import {useProblemStatusesAsArray} from '@src/core/hooks/queries/problemStatuses/hooks';
import {IEntitySettings} from '@src/core/hooks/settings/useEntitySettings';
import {IProblemsFiltersState} from '@src/store/modules/settings/pages/problems/interfaces';
import {useTranslation} from 'react-i18next';
import {ProblemsSearch} from '../ProblemsSearch';
import {CategoriesSetFilter} from './CategoriesSetFilter';
import {InspectorsFilter} from './ProblemsInspectorsFilter';
import {ProblemPerformersFilter} from './ProblemsPerformersFilter';
import {ProblemTagsSetFilter} from './ProblemTagsSetFilter';
import {StagesFilter} from './StagesFilters';
import {CustomFilters} from '@src/components/CustomFilters/CustomFilters';
import {ReopenedTimesFilter} from '@src/components/EntitiesFilters/components/ReopenedTimesFilter';
import {useObjectChildrenIds} from '@src/core/hooks/queries/objects/hooks';
import {NestedObjectsFilter} from '@src/components/EntitiesFilters/components/NestedObjectsFilter';

const criticalOptions = [
	{
		id: 'true',
		name: 'Критично'
	},
	{
		id: 'false',
		name: 'Не критично'
	}
];

interface IProblemsFiltersProps {
	objectId?: string;
	settings?: IEntitySettings;
}

const ProblemsFilters = ({objectId = 'all', settings}: IProblemsFiltersProps) => {
	const {t} = useTranslation();
	const {state} = useEntitiesFiltersCtx<IProblemsFiltersState>();
	const {data: allStages} = useObjectStagesAsArray();
	const {data: allStatuses} = useProblemStatusesAsArray();
	const nestedObjects = useObjectChildrenIds(objectId);
	return (
		<EntitiesFilters
			objectId={objectId}
			entity="problems"
			isDescendants={!!nestedObjects?.length}
		>
			<div className="problems-page__search">
				<ProblemsSearch
					objectId={objectId}
					settings={settings}
				/>
			</div>

			{objectId === 'all' && (
				<ObjectsFilter
					label={t('problemsPage.objectsFilter.label')}
					value={state?.objects}
				/>
			)}
			{!!nestedObjects?.length ? (
				<NestedObjectsFilter
					label={t('problemsPage.objectsFilter.label')}
					value={state?.objects}
					objectId={objectId}
					excludeDescendantsValue={state?.excludeDescendantsObjects}
				/>
			) : null}

			{allStatuses && (
				<StatusesFilter
					allStatuses={allStatuses}
					label={t('problemsPage.statusesFilter.label')}
					statuses={state?.statuses}
					translationDictionaryKey={dictionaryKeys.problemStatuses}
				/>
			)}
			{settings?.builtin.plannedFixDate && (
				<PlannedFixDateFilter
					label={t('problemsPage.fixDateFilter.label')}
					from={state?.plannedFixDateFrom}
					to={state?.plannedFixDateTo}
				/>
			)}

			<ActualFixDateFilter
				label={t('problemsPage.actualFixDateFilter.label')}
				from={state?.actualFixDateFrom}
				to={state?.actualFixDateTo}
			/>

			{settings?.builtin.critical && (
				<BooleanFilter
					value={state.critical}
					label={t('problemsPage.booleanFilter.label')}
					filterName="critical"
					options={criticalOptions}
				/>
			)}

			<ExpiredFilter
				label={t('problemsPage.expiredFilter.label')}
				value={state.expired}
			/>

			{settings?.builtin.problemTags && (
				<ProblemTagsSetFilter
					objectId={objectId}
					label={t('problemsPage.problemTagsSetFilter.label')}
					problemTags={state.problemTags}
				/>
			)}

			<CreatedByFilter
				objectId={objectId}
				label={t('problemsPage.createdByFilter.label')}
				createdBy={state?.createdBy}
			/>

			<CreationDateFilter
				from={state?.createdAtFrom}
				label={t('problemsPage.creationDateFilter.label')}
				to={state?.createdAtTo}
			/>

			{settings?.builtin.categoryId && (
				<CategoriesSetFilter
					objectId={objectId}
					label={t('problemsPage.categoriesSetFilter.label')}
					categories={state.categories}
				/>
			)}

			{objectId !== 'all' && settings?.builtin.location && (
				<PlansFilter
					objectId={objectId}
					label={t('problemsPage.plansFilter.label')}
					plans={state?.plans}
				/>
			)}

			{allStages && (
				<StagesFilter
					allStages={allStages}
					label={t('problemsPage.stagesFilter.label')}
					stages={state?.stages}
				/>
			)}

			{settings?.builtin.performers && (
				<ProblemPerformersFilter
					label={t('entitiesFilters.performers.label')}
					performers={state?.performers}
					objectId={objectId}
				/>
			)}

			{settings?.builtin.inspectors && (
				<InspectorsFilter
					label={t('entitiesFilters.inspectors.label')}
					inspectors={state.inspectors}
					objectId={objectId}
				/>
			)}

			<ReopenedTimesFilter
				label={t('entitiesFilters.reopenedTimes.label')}
				reopenedTimes={state?.reopenedTimes}
			/>

			{settings?.custom && (
				<CustomFilters
					settings={settings.custom}
					filtersData={state.custom}
				/>
			)}
		</EntitiesFilters>
	);
};

export default ProblemsFilters;
