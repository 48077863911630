import {useStrictParams} from '@src/core/hooks/useStrictParams';
import {useMemo} from 'react';
import {LoadingPanel, Plate} from '@tehzor/ui-components';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {useCheckListsIsLoading} from '@src/core/hooks/queries/checkLists/hooks';
import {MobileInfoBlock} from '@src/pages/SpacePage/components/info/MobileInfoBlock';
import {getCheckListsWithStagesAndProcesses} from '../../utils/getCheckListsWithStages';
import {useSpace} from '@src/core/hooks/queries/space';
import {useHasUnfinished} from '@src/pages/SpacePage/hooks/useHasUnfinished';
import {useSpaceCheckListsHeader} from '../../hooks/useSpaceCheckListsHeader';
import {ProcessIds} from '@tehzor/tools/interfaces/process/ProcessId';
import {CheckListGroup} from '@src/pages/CheckListPage/components/CheckListGroup/CheckListGroup';
import {useSpaceCheckLists} from '@src/pages/CheckListPage/hooks/useSpaceCheckLists';

export const CheckLists = () => {
	const {objectId, spaceId, stage, processId} = useStrictParams<{
		objectId: string;
		spaceId: string;
		stage?: ObjectStageIds | 'all';
		processId?: ProcessIds | 'all';
	}>();
	useSpaceCheckListsHeader(spaceId, objectId, stage || 'all', processId || 'all');
	const checkLists = useSpaceCheckLists(objectId, spaceId, stage, processId);

	const checkListsIds = useMemo(() => checkLists?.map(cl => cl.id), [checkLists]);

	const [hasUnfixedProblems, hasHandlingOrWaitingProblems, hasUnacceptedCheckItem] =
		useHasUnfinished(checkListsIds, objectId, spaceId);

	const listsLoading = useCheckListsIsLoading();

	const {data: space} = useSpace(spaceId, objectId);

	const groupedCheckLists = useMemo(
		() => getCheckListsWithStagesAndProcesses(checkLists),
		[checkLists]
	);

	return (
		<LoadingPanel active={listsLoading}>
			<>
				<div className="check-list-page__m-lists__info-block">
					{space && (
						<MobileInfoBlock
							space={space}
							objectId={objectId}
							hasUnacceptedCheckItem={hasUnacceptedCheckItem}
							hasHandlingOrWaitingProblems={hasHandlingOrWaitingProblems}
							hasUnfixedProblems={hasUnfixedProblems}
						/>
					)}
				</div>
				<Plate
					withoutPadding
					className="check-list-page__m-lists"
				>
					{checkLists.length && (
						<Plate
							className={{content: 'check-list-page-list__container'}}
							withoutPadding
						>
							<CheckListGroup
								groupedCheckLists={groupedCheckLists}
								mobileView
							/>
						</Plate>
					)}
				</Plate>
			</>
		</LoadingPanel>
	);
};
