import {queryClient} from '@src/api/QueryClient';
import {useMutation} from '@tanstack/react-query';
import {ISavingStructure} from '@tehzor/tools/interfaces/structures/ISavingStructure';
import {makeStructureAddRequest} from '@/shared/api/structure/addStructure';
import {structuresQueryKeys} from '../../../Structures/model/cache/keys/index';
import {addErrorToast, addSuccessToast} from '@tehzor/ui-components';
import {useTranslation} from 'react-i18next';

interface IAddStructureRequestData {
	objectId: string;
	fields: ISavingStructure;
}

export const useAddStructure = () => {
	const {t} = useTranslation();

	return useMutation({
		mutationFn: ({objectId, fields}: IAddStructureRequestData) =>
			makeStructureAddRequest(objectId, fields),
		mutationKey: structuresQueryKeys.add(),
		onSuccess: () => {
			addSuccessToast({title: t('toast.addStructure.success.title')});
			void queryClient.invalidateQueries({queryKey: structuresQueryKeys.list()});
		},
		onError: () => addErrorToast({title: t('toast.addStructure.error.title')})
	});
};
