import {httpRequests} from '@tehzor/tools/api/httpRequests';
import {IFieldSettingsSet} from '@tehzor/tools/interfaces/fields';
import {ISavingFieldsSettingsSet} from '@src/interfaces/saving/ISavingFieldsSettingsSet';

export const requestAddFieldsSettingsSet = async (fields: ISavingFieldsSettingsSet) => {
	const response = await httpRequests.withToken.post<IFieldSettingsSet>(
		`field-settings-sets`,
		fields
	);
	return response.data;
};
