import {queryClient} from '@src/api/QueryClient';
import {IGetProblemTagsResponse} from '@src/api/backend/problemTags';
import {makeProblemTagDeleteRequest} from '@src/api/backend/problemTags/delete';
import {problemTagsQueryKeys} from '@src/api/cache/problemTags/keys';
import {useMutation} from '@tanstack/react-query';
import IError from '@tehzor/tools/interfaces/IError';
import {addErrorToast, addSuccessToast} from '@tehzor/ui-components';
import {useTranslation} from 'react-i18next';

interface IProblemTagDeleteParams {
	problemTagsSetId: string;
	id: string;
}

export const useDeleteProblemTag = () => {
	const {t} = useTranslation();

	return useMutation<{id: string}, IError, IProblemTagDeleteParams>({
		mutationFn: (params: IProblemTagDeleteParams) =>
			makeProblemTagDeleteRequest(params.problemTagsSetId, params.id),
		mutationKey: problemTagsQueryKeys.delete(),
		onSuccess: data => {
			queryClient.setQueryData<IGetProblemTagsResponse>(
				problemTagsQueryKeys.list(),
				oldData => {
					if (oldData) {
						const newAllIds = oldData.allIds.filter(id => data.id !== id);
						const newById = newAllIds.reduce((prev, current) => {
							prev[current] = oldData.byId[current];
							return prev;
						}, {});

						return {
							allIds: newAllIds,
							byId: newById
						};
					}
					return oldData;
				}
			);
			addSuccessToast({title: t('toast.deleteProblemTag.success.title')});
		},
		onError: () => {
			addErrorToast({title: t('toast.deleteProblemTag.error.title')});
		}
	});
};
