import {
	IEditableEntityAction,
	IEditableEntityState,
	isEntityEdited,
	isPropEdited
} from '@tehzor/tools/core/states/editableEntityState';
import {ITask} from '@tehzor/tools/interfaces/tasks/ITask';
import {ISavingTask} from '@src/interfaces/saving/ISavingTask';
import {TaskStatusId} from '@tehzor/tools/interfaces/tasks/ITaskStatus';
import {TaskTypeId} from '@tehzor/tools/interfaces/tasks/ITaskType';
import ILocation from '@tehzor/tools/interfaces/ILocation';
import IAttachment from '@tehzor/tools/interfaces/IAttachment';
import {TaskPriorityId} from '@tehzor/tools/interfaces/tasks/ITaskPriority';
import IObjectFieldSetting from '@tehzor/tools/interfaces/objects/IObjectFieldSetting';
import {IRuleParams} from '@tehzor/tools/utils/responsibilityRules/IRuleParams';

export type IEditableTaskState = IEditableEntityState<{
	name?: string;
	description?: string;
	objectId?: string;
	status?: TaskStatusId;
	taskType?: TaskTypeId;
	taskPriority?: TaskPriorityId;
	taskIntervalStart?: number;
	taskIntervalEnd?: number;
	planId?: string;
	location?: ILocation;
	floor?: string;
	respUsers?: string[];
	activeGroup?: string;
	watchers?: string[];
	attachments?: IAttachment[];
	initialRuleParams?: IRuleParams;
}>;

export type IEditableTaskAction = IEditableEntityAction<IEditableTaskState, ITask>;

const makeEmptyState = (): IEditableTaskState => ({
	name: undefined,
	description: undefined,
	objectId: undefined,
	status: undefined,
	taskType: undefined,
	taskPriority: undefined,
	taskIntervalStart: undefined,
	taskIntervalEnd: undefined,
	planId: undefined,
	location: undefined,
	floor: undefined,
	respUsers: undefined,
	activeGroup: undefined,
	watchers: [],
	attachments: [],
	errors: {
		name: false,
		description: false,
		objectId: false,
		status: false,
		taskType: false,
		taskPriority: false,
		taskIntervalStart: false,
		taskIntervalEnd: false,
		planId: false,
		location: false,
		floor: false,
		respUsers: false,
		activeGroup: false,
		watchers: false,
		attachments: false
	}
});

export const init = (task?: ITask): IEditableTaskState => {
	const empty = makeEmptyState();
	return task
		? {
				name: task.name,
				description: task?.description,
				objectId: task?.objectId,
				status: task?.status,
				taskType: task?.taskType,
				taskPriority: task?.taskPriority,
				taskIntervalStart: task?.taskIntervalStart,
				taskIntervalEnd: task?.taskIntervalEnd,
				planId: task?.planId,
				location: task?.location,
				floor: task?.floor,
				respUsers: task.respUsers?.map(u => u),
				activeGroup: task?.activeGroup,
				watchers: task?.watchers,
				attachments: task.attachments?.map(a => a),
				errors: empty.errors
		  }
		: empty;
};

export const isEdited = (state: IEditableTaskState, original?: ITask): boolean =>
	isEntityEdited(
		state,
		original,
		isPropEdited.bind(null, 'name'),
		isPropEdited.bind(null, 'description'),
		isPropEdited.bind(null, 'objectId'),
		isPropEdited.bind(null, 'status'),
		isPropEdited.bind(null, 'taskType'),
		isPropEdited.bind(null, 'taskPriority'),
		isPropEdited.bind(null, 'taskIntervalStart'),
		isPropEdited.bind(null, 'taskIntervalEnd'),
		isPropEdited.bind(null, 'planId'),
		isPropEdited.bind(null, 'location'),
		isPropEdited.bind(null, 'floor'),
		isPropEdited.bind(null, 'respUsers'),
		isPropEdited.bind(null, 'activeGroup'),
		isPropEdited.bind(null, 'watchers'),
		isPropEdited.bind(null, 'attachments')
	);

export const errorsFns = {
	name: (state: IEditableTaskState) => !state.name,
	description: (state: IEditableTaskState) => !state.description,
	objectId: (state: IEditableTaskState) => !state.objectId,
	status: (state: IEditableTaskState) => !state.status,
	taskType: (state: IEditableTaskState) => !state.taskType,
	taskPriority: (state: IEditableTaskState) => !state.taskPriority,
	taskIntervalStart: (state: IEditableTaskState) => !state.taskIntervalStart,
	taskIntervalEnd: (state: IEditableTaskState) => !state.taskIntervalEnd,
	planId: (state: IEditableTaskState) => !state.planId,
	location: (state: IEditableTaskState) => !state.location,
	floor: (state: IEditableTaskState) => !state.floor,
	respUsers: (state: IEditableTaskState) => !state.respUsers,
	activeGroup: (state: IEditableTaskState) => !state.activeGroup,
	watchers: (state: IEditableTaskState) => !state.watchers?.length,
	attachments: (state: IEditableTaskState) => !state.attachments?.length
};

/**
 * Проверяет, есть ли ошибка в сохраненных вложениях
 *
 * @param state состояние
 * @param settings настройки полей
 */
export const hasAttachmentsError = (
	state: IEditableTaskState,
	settings: {[k: string]: IObjectFieldSetting}
) => settings.attachments?.isRequired && errorsFns.attachments(state);

export const convertTaskToSave = (
	state: IEditableTaskState,
	original?: ITask,
	onlyEdited?: boolean
): ISavingTask => {
	if (!onlyEdited) {
		return state;
	}

	const task: ISavingTask = {};

	if (isPropEdited('name', state, original)) {
		task.name = state.name;
	}
	if (isPropEdited('description', state, original)) {
		task.description = state.description;
	}
	if (isPropEdited('objectId', state, original)) {
		task.objectId = state.objectId;
	}
	if (isPropEdited('status', state, original)) {
		task.status = state.status;
	}
	if (isPropEdited('taskType', state, original)) {
		task.taskType = state.taskType;
	}
	if (isPropEdited('taskPriority', state, original)) {
		task.taskPriority = state.taskPriority;
	}
	if (isPropEdited('taskIntervalStart', state, original)) {
		task.taskIntervalStart = state.taskIntervalStart;
	}
	if (isPropEdited('taskIntervalEnd', state, original)) {
		task.taskIntervalEnd = state.taskIntervalEnd;
	}
	if (isPropEdited('planId', state, original)) {
		task.planId = state.planId;
	}
	if (isPropEdited('location', state, original)) {
		task.location = state.location;
	}
	if (isPropEdited('floor', state, original)) {
		task.floor = state.floor;
	}
	if (isPropEdited('respUsers', state, original)) {
		task.respUsers = state.respUsers;
	}
	if (isPropEdited('activeGroup', state, original)) {
		task.activeGroup = state.activeGroup;
	}
	if (isPropEdited('watchers', state, original)) {
		task.watchers = state.watchers;
	}
	if (isPropEdited('attachments', state, original)) {
		task.attachments = state.attachments?.map(item => ({id: item.id}));
	}

	return task;
};
