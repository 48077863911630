import {updateCachedState} from '@src/utils/updateCachedState';
import {useQueryClient} from '@tanstack/react-query';
import {OfflineDataTransferStatus} from '@tehzor/tools/contracts/dataTransferWebWorker/interfaces/IOfflineDataTransferStatuses';
import {legalsQueryKeys} from '../keys';
import {ISavingLegalEntity} from '@tehzor/tools/interfaces/legals-entity/ISavingLegalEntity';
import {makeLegalEntityAddRequest} from '@src/api/backend/legal-entity/add';
import {ILegalEntity} from '@tehzor/tools/interfaces/legals-entity/ILegalEntity';
import {makeLegalEntityEditRequest} from '@src/api/backend/legal-entity';
import {addErrorToast} from '@tehzor/ui-components';
import {useTranslation} from 'react-i18next';

export interface IAddLegalParams {
	fields: ISavingLegalEntity;
}

export interface IEditLegalParams {
	legalId: string;
	fields: ISavingLegalEntity;
}

/**
 * Хук для предачи дефолтной функции мутации в QueryClient
 *  - Дефолтная функия нужна для того, чтобы не указывть её в самом хуке мутации явно
 *  - Если после запуска приложения в кэше будет лежать незаврешенная мутация для этого ключа,
 * 		то для повтра мутации будет использована mutationFn отсюда
 */
export const useLegalsMutationDefaults = () => {
	const queryClient = useQueryClient();
	const key = Date.now().toString(10);
	const {t} = useTranslation();

	queryClient.setMutationDefaults(legalsQueryKeys.add(), {
		onMutate: (variables: IAddLegalParams) => ({variables}),
		mutationFn: async (params: IAddLegalParams) => {
			await updateCachedState<ILegalEntity>(
				legalsQueryKeys,
				key,
				OfflineDataTransferStatus.TRANSFER
			);
			const {fields} = params;
			return makeLegalEntityAddRequest(fields);
		},
		onSuccess: async () => {
			await updateCachedState<ILegalEntity>(
				legalsQueryKeys,
				key,
				OfflineDataTransferStatus.TRANSFER_COMPLETE
			);
			// Удаляем кешированные данные локального юрлица
			queryClient.removeQueries({queryKey: legalsQueryKeys.savingData(key)});

			await queryClient.refetchQueries({
				queryKey: legalsQueryKeys.localList()
			});
			await queryClient.invalidateQueries({queryKey: legalsQueryKeys.list()});
		},
		onError: async () => {
			addErrorToast({title: t('toast.addLegalEntity.error.title')});
			await updateCachedState<ILegalEntity>(
				legalsQueryKeys,
				key,
				OfflineDataTransferStatus.TRANSFER_ERROR
			);
		},
		retry: 1,
		retryDelay: 10 * 1000
	});

	queryClient.setMutationDefaults(legalsQueryKeys.edit(), {
		mutationFn: async ({legalId, fields}: IEditLegalParams) =>
			makeLegalEntityEditRequest(legalId, fields),
		onSuccess: async (_, {legalId}: IEditLegalParams) => {
			await queryClient.invalidateQueries({queryKey: legalsQueryKeys.detail(legalId)});
			await queryClient.invalidateQueries({queryKey: legalsQueryKeys.list()});
		},
		onError: async () => {
			addErrorToast({title: t('toast.editLegalEntity.error.title')});
			await updateCachedState<ILegalEntity>(
				legalsQueryKeys,
				key,
				OfflineDataTransferStatus.TRANSFER_ERROR
			);
		}
	});
};
