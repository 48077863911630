import {useQuery} from '@tanstack/react-query';
import IError from '@tehzor/tools/interfaces/IError';
import {IGetObjectsResponse, IGetObjectStatsResponse} from '@src/api/backend/objects';
import {objectsQueryKeys} from '@src/api/cache/objects/keys';
import {IObject} from '@tehzor/tools/interfaces/objects/IObject';
import {useCallback} from 'react';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {getObjectsFilters} from '@src/store/modules/settings/pages/objects/selectors';
import {
	extractCurrentObjectByExternalId,
	extractCurrentTreeObject,
	extractFilteredTreeObjects,
	extractFilteredWithNewFiltersTreeObjects,
	extractObject,
	extractObjectAutoSelectRespRules,
	extractObjectById,
	extractObjectChildrenIds,
	extractObjectFieldsSettings,
	extractObjectIdsForCompany,
	extractObjectIdsForCompanyOrAllIds,
	extractObjectRespRules,
	extractObjectRespUsers,
	extractObjectsAsArray,
	extractObjectsAsArrayByIds,
	extractObjectsAsTree,
	extractObjectsCitiesAsArray,
	extractObjectsIds,
	extractObjectsMap,
	extractObjectsStartDate,
	extractParentTreeObject,
	extractTargetObjects
} from '@src/core/hooks/queries/objects/selectors';
import {useUsersAsMap} from '../users/hooks';
import {IObjectsWithStructuresRequest} from '@tehzor/tools/interfaces/objects/IObjectsWithStructuresRequest';

export const useObjects = <T = IGetObjectsResponse>(select?: (data: IGetObjectsResponse) => T) =>
	useQuery<IGetObjectsResponse, IError, T>({
		queryKey: [...objectsQueryKeys.list()],
		meta: {
			error: 'при загрузке списка объектов'
		},
		select
	});

export const useObjectsData = () => {
	const {data} = useObjects();
	return data;
};

export const useObjectsMap = () => {
	const {data} = useObjects(extractObjectsMap);
	return data;
};

export const useObjectsIds = () => useObjects(extractObjectsIds);

export const useObjectsAsArray = () => {
	const {data} = useObjects<IObject[]>(extractObjectsAsArray);
	return data;
};

export const useObjectsCitiesAsArray = () => {
	const {data} = useObjects(extractObjectsCitiesAsArray);
	return data;
};

export const useObjectsAsArrayByIds = (objectsIds?: string[]) => {
	const selector = useCallback(
		(data: IGetObjectsResponse) => extractObjectsAsArrayByIds(data, objectsIds),
		[objectsIds]
	);
	const {data} = useObjects(selector);
	return data;
};

export const useObject = (objectId?: string) => {
	const selector = useCallback(
		(data: IGetObjectsResponse) => extractObject(data, objectId),
		[objectId]
	);
	return useObjects(selector);
};

// Отличается от useObject тем, что если id === 'all' возвращает все объекты
export const useObjectById = (objectId?: string) => {
	const selector = useCallback(
		(data: IGetObjectsResponse) => extractObjectById(data, objectId),
		[objectId]
	);
	const {data} = useObjects(selector);
	return data;
};

export const useCurrentObjectByExternalId = (externalId?: string) => {
	const selector = useCallback(
		(data: IGetObjectsResponse) => extractCurrentObjectByExternalId(data, externalId),
		[externalId]
	);
	const {data} = useObjects(selector);
	return data;
};

export const useObjectsAsTree = () => {
	const {data} = useObjects(extractObjectsAsTree);
	return data;
};

export const useFilteredTreeObjects = () => {
	const filters = useAppSelector(getObjectsFilters);
	const selector = useCallback(
		(data: IGetObjectsResponse) => extractFilteredTreeObjects(data, filters),
		[filters]
	);
	const {data} = useObjects(selector);
	return data;
};

/**
 * Возвращает отфильтрованные объекты для отображения в табличном виде
 */
export const useFilteredWithNewFiltersTreeObjects = (objectFilterValue?: string) => {
	const filters = useAppSelector(getObjectsFilters);
	const selector = useCallback(
		(data: IGetObjectsResponse) =>
			extractFilteredWithNewFiltersTreeObjects(data, filters, objectFilterValue),
		[filters, objectFilterValue]
	);
	const {data} = useObjects(selector);
	return data;
};

export const useCurrentTreeObject = (objectId?: string) => {
	const selector = useCallback(
		(data: IGetObjectsResponse) => extractCurrentTreeObject(data, objectId),
		[objectId]
	);
	const {data} = useObjects(selector);
	return data;
};

export const useParentTreeObject = (objectId?: string) => {
	const selector = useCallback(
		(data: IGetObjectsResponse) => extractParentTreeObject(data, objectId),
		[objectId]
	);
	const {data} = useObjects(selector);
	return data;
};

export const useObjectRespRules = (objectId?: string) => {
	const selector = useCallback(
		(data: IGetObjectsResponse) => extractObjectRespRules(data, objectId),
		[objectId]
	);
	const {data} = useObjects(selector);
	return data;
};

export const useObjectRespUsers = (objectId?: string) => {
	const {data: users} = useUsersAsMap();
	const selector = useCallback(
		(data: IGetObjectsResponse) => extractObjectRespUsers(data, users, objectId),
		[objectId, users]
	);
	const {data} = useObjects(selector);
	return data;
};

export const useObjectAutoSelectRespRules = (objectId?: string) => {
	const selector = useCallback(
		(data: IGetObjectsResponse) => extractObjectAutoSelectRespRules(data, objectId),
		[objectId]
	);
	const {data} = useObjects(selector);
	return data;
};

export const useObjectFieldsSettings = (objectId?: string) => {
	const selector = useCallback(
		(data: IGetObjectsResponse) => extractObjectFieldsSettings(data, objectId),
		[objectId]
	);
	const {data} = useObjects(selector);
	return data;
};

export const useObjectsStartDate = () => {
	const {data} = useObjects(extractObjectsStartDate);
	return data;
};

export const useObjectChildrenIds = (objectId?: string) => {
	const selector = useCallback(
		(data: IGetObjectsResponse) => extractObjectChildrenIds(data, objectId),
		[objectId]
	);
	const {data} = useObjects(selector);
	return data;
};

/**
 * Возвращает массив id объектов, которые можно использовать в запросах к серверу.
 * Для конечного объекта вернётся его id, для родительского - id всех объектов-потомков.
 * withParentObject отвечает за то, будет ли включатся id родительского в итоговый массив потомков
 */
export const useTargetObjects = (objectId?: string, withParentObject?: boolean) => {
	const selector = useCallback(
		(data: IGetObjectsResponse) => extractTargetObjects(data, objectId, withParentObject),
		[objectId, withParentObject]
	);
	return useObjects(selector);
};

export const useObjectIdsForCompany = (companyId?: string) => {
	const selector = useCallback(
		(data: IGetObjectsResponse) => extractObjectIdsForCompany(data, companyId),
		[companyId]
	);
	const {data} = useObjects(selector);
	return data;
};

export const useObjectIdsForCompanyOrAllIds = (companyId?: string) => {
	const selector = useCallback(
		(data: IGetObjectsResponse) => extractObjectIdsForCompanyOrAllIds(data, companyId),
		[companyId]
	);
	const {data} = useObjects(selector);
	return data;
};

export const useObjectsStats = (objectsIds: string[]) =>
	useQuery<IGetObjectStatsResponse, IError>({
		queryKey: [...objectsQueryKeys.stats(), ...objectsIds],
		meta: {
			error: 'при загрузке статистики объекта'
		}
	});

/**
*возвращает массив id дочерних объектов по objectId, у которых есть структуры
 */
export const useTargetObjectIdsHaveStructures = ({
	objectId,
	filter,
	includingParentObjectId
}: IObjectsWithStructuresRequest) => {
	const {data} = useQuery<string[], IError>({
		queryKey: objectsQueryKeys.objectsWithStructures({
			objectId,
			filter,
			includingParentObjectId
		}),
		meta: {
			error: 'при загрузке дочерних объектов'
		}
	});

	return data ?? [objectId];
};
