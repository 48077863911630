import {ICustomField, IFieldSetting} from '@tehzor/tools/interfaces/fields';

export const getPreparedCustomField = (
	setId: string,
	field: ICustomField,
	fieldSetting?: IFieldSetting
) => ({
	id: field.id,
	setId,
	settingsId: fieldSetting?.id,
	name: field.name,
	icon: field.icon,
	valueTypeName: field.valueType.name,
	enabled: !!fieldSetting,
	canBeCopied: field.canBeCopied,
	copyable: field.canBeCopied ? fieldSetting?.copyable : false,
	required: fieldSetting?.required,
	order: fieldSetting ? fieldSetting.order : 0,
	isCustom: true
});
