import {requestComments, IGetCommentsResponse} from '@src/api/backend/comments';
import * as types from '../../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {createApiAction} from '@src/store/middlewares/api';
import {AppThunkAction} from '@src/store/interfaces/thunk';
import {ICommentTypeId, ICommentVariants} from '@tehzor/tools/interfaces/comments/ICommentType';
import {addErrorToast} from '@tehzor/ui-components';
import {t} from 'i18next';

export type IGetWorkAcceptanceCommentsPayload = IGetCommentsResponse;

const request = () => ({type: types.GET_COMMENTS_REQUEST});

const success = (response: IGetCommentsResponse) => ({
	type: types.GET_COMMENTS_SUCCESS,
	payload: response
});

const failure = (error: IError) => {
	addErrorToast({title: t('toast.getCommentsForWorkAcceptance.error.title')});
	return {
		type: types.GET_COMMENTS_FAILURE,
		payload: error
	};
};

/**
 * Получает список комментариев и ответов для приемки работ
 *
 * @param objectId id объекта
 * @param commentTypeId тип комментария
 * @param workAcceptanceId id приемки работ
 */
export const getCommentsForWorkAcceptance =
	(
		objectId: string,
		commentTypeId: ICommentTypeId,
		workAcceptanceId: string
	): AppThunkAction<Promise<IGetCommentsResponse>> =>
	(dispatch, getState) =>
		dispatch(
			createApiAction<IGetCommentsResponse>(request, success, failure, () => {
				const s = getState();
				const {offset, limit} = s.entities.workAcceptance.comments;
				const {visibility} = s.settings.pages.workAcceptance.comments;
				const official =
					visibility.length === 1
						? visibility[0] === ICommentVariants.REPLIES
						: undefined;
				return requestComments(
					{
						commentTypeId,
						objects: [objectId],
						workAcceptances: [workAcceptanceId],
						official
					},
					{createdAt: false},
					offset,
					limit
				);
			})
		);
