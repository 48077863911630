import {IGetSpaceTypesResponse} from '@src/api/backend/spaceTypes';
import {IGetUnitsResponse} from '@src/api/backend/units';
import {IGetWorkAcceptanceStatusesResponse} from '@src/api/backend/workAcceptanceStatuses';
import {useCategories} from '@src/core/hooks/queries/categories/hook';
import {useCompaniesContractors} from '@src/core/hooks/queries/companies/hooks';
import {useSpaceTypes} from '@src/core/hooks/queries/spaceTypes/hooks';
import {useSpacesData} from '@src/core/hooks/queries/spaces/hooks';
import {useExtractStructureTypesById} from '@src/core/hooks/queries/structureTypes/hooks';
import {useUnits} from '@src/core/hooks/queries/units/hook';
import {useUsers} from '@src/core/hooks/queries/users/hooks';
import {useExtractWorkAcceptanceFrontTypesById} from '@src/core/hooks/queries/workAcceptanceFrontTypes/hooks';
import {useWorkAcceptanceStatuses} from '@src/core/hooks/queries/workAcceptanceStatuses/hooks';
import {useWorkAcceptanceStoriesAsArray} from '@src/core/hooks/queries/workAcceptanceStories/hook';
import {useDateFnsLocale} from '@src/core/hooks/translations/useDateFnsLocales';
import {
	createAttachmentsDifference,
	createCategoryDifference,
	createDateDifference,
	createDescriptionDifference,
	createRespUsersDifference,
	createStatusDifference
} from '@src/utils/createHistoryData';
import {createContractorDifference} from '@src/utils/createHistoryData/createContractorDifference';
import {createFrontTypeDifference} from '@src/utils/createHistoryData/createFrontTypeDifference';
import {createPercentageDifference} from '@src/utils/createHistoryData/createPercentageDifference';
import {createSpacesDifference} from '@src/utils/createHistoryData/createSpacesDifference';
import {createStructuresDifference} from '@src/utils/createHistoryData/createStructuresDifference';
import {createWorkAmountDifference} from '@src/utils/createHistoryData/createWorkAmountDifference';
import {isArrayEqual} from '@src/utils/createHistoryData/isArrayEqual';
import {isAttachmentsEqual} from '@src/utils/createHistoryData/isAttachmentsEqual';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import ICategory from '@tehzor/tools/interfaces/categories/ICategory';
import ICompany from '@tehzor/tools/interfaces/companies/ICompany';
import {IHistoryData} from '@tehzor/tools/interfaces/history/IHistoryData';
import {IHistoryDifference} from '@tehzor/tools/interfaces/history/IHistoryDifference';
import {IListSpace} from '@tehzor/tools/interfaces/spaces/IListSpace';
import {IStructureType} from '@tehzor/tools/interfaces/structures/IStructureType';
import {IBriefUser} from '@tehzor/tools/interfaces/users/IBriefUser';
import {IWorkAcceptanceFrontType} from '@tehzor/tools/interfaces/workAcceptances/IWorkAcceptanceFrontType';
import {IWorkAcceptanceStory} from '@tehzor/tools/interfaces/workAcceptancesStories/IWorkAcceptanceStory';
import {IEntityState} from '@tehzor/tools/utils/reducersHandlers';
import {TFunction} from 'i18next';
import {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useStructuresList} from '@/entities/Structures';
import {IGetListStructuresResponse} from '@src/fsd/src/shared/api/structures/getStructuresList';
import {useWorkingGroups} from '@src/core/hooks/queries/workingGroups/hooks';
import {IWorkingGroup} from '@tehzor/tools/interfaces/workingGroups/IWorkingGroup';
import {useTranslatedDictionary} from '@src/core/hooks/translations/useTranslatedDictionary';
import {dictionaryKeys} from '@src/constants/translations/dictionaryKeys';

function compare(
	next: IWorkAcceptanceStory,
	prev: IWorkAcceptanceStory,
	users: INormalizedData<IBriefUser> | undefined,
	workingGroups: INormalizedData<IWorkingGroup> | undefined,
	categories: IEntityState<ICategory> | undefined,
	workAcceptanceStatuses: IGetWorkAcceptanceStatusesResponse | undefined,
	units: IGetUnitsResponse | undefined,
	contractors: INormalizedData<ICompany> | undefined,
	frontTypesMap:
		| {
				[id: string]: IWorkAcceptanceFrontType;
		  }
		| undefined,
	spacesMap: Record<string, IListSpace>,
	structuresMap: IGetListStructuresResponse,
	spaceTypes: IGetSpaceTypesResponse | undefined,
	structuresTypesMap: Record<string, IStructureType> | undefined,
	t: TFunction<'translation', undefined>,
	locale: Locale
): IHistoryDifference {
	const data: IHistoryData[] = [];
	if (next.data.contractorId !== prev.data.contractorId) {
		data.push(
			createContractorDifference(
				prev.data.contractorId,
				next.data.contractorId,
				contractors,
				t
			)
		);
	}
	if (next.data.frontType !== prev.data.frontType && frontTypesMap) {
		data.push(
			createFrontTypeDifference(prev.data.frontType, next.data.frontType, frontTypesMap, t)
		);
	}
	if (!isArrayEqual(next.data.spaceIds, prev.data.spaceIds)) {
		data.push(
			createSpacesDifference(prev.data.spaceIds, next.data.spaceIds, spacesMap, spaceTypes, t)
		);
	}

	if (!isArrayEqual(next.data.structureIds, prev.data.structureIds)) {
		data.push(
			createStructuresDifference(
				prev.data.structureIds,
				next.data.structureIds,
				structuresMap,
				structuresTypesMap,
				t
			)
		);
	}
	if (next.data.description !== prev.data.description) {
		data.push(createDescriptionDifference(prev.data.description, next.data.description, t));
	}
	if (next.data.percent !== prev.data.percent) {
		data.push(
			createPercentageDifference(
				prev.data.percent,
				next.data.percent,
				t,
				t('createDifference.createPercentageDifference.workAcceptance')
			)
		);
	}
	if (next.data.comment !== prev.data.comment) {
		data.push(
			createDescriptionDifference(
				prev.data.comment,
				next.data.comment,
				t,
				t('createDifference.createDescriptionDifference.workAcceptance')
			)
		);
	}
	if (
		next.data.physicalWorkScope?.value !== prev.data.physicalWorkScope?.value ||
		next.data.physicalWorkScope?.unitId !== prev.data.physicalWorkScope?.unitId
	) {
		data.push(
			createWorkAmountDifference(
				prev.data.physicalWorkScope,
				next.data.physicalWorkScope,
				units,
				t
			)
		);
	}
	if (next.data.status !== prev.data.status && workAcceptanceStatuses) {
		data.push(
			createStatusDifference(prev.data.status, next.data.status, workAcceptanceStatuses, t)
		);
	}
	if (next.data.categoryId !== prev.data.categoryId && categories) {
		data.push(
			createCategoryDifference(prev.data.categoryId, next.data.categoryId, categories, t)
		);
	}
	if (next.data.acceptanceDate !== prev.data.acceptanceDate) {
		data.push(
			createDateDifference(
				prev.data.acceptanceDate,
				next.data.acceptanceDate,
				t,
				locale,
				t('workAcceptancePage.workAcceptanceHistoryDialog.compare.workAcceptanceDate')
			)
		);
	}
	if (
		users &&
		workingGroups &&
		(next.data.acceptorsActiveGroup !== prev.data.acceptorsActiveGroup ||
			next.data.acceptors?.length !== prev.data.acceptors?.length ||
			(next.data.acceptors?.length === prev.data.acceptors?.length &&
				!isArrayEqual(next.data.acceptors, prev.data.acceptors)))
	) {
		data.push(
			createRespUsersDifference(
				prev.data.acceptorsActiveGroup,
				prev.data.acceptors,
				next.data.acceptorsActiveGroup,
				next.data.acceptors,
				users,
				workingGroups,
				t('createDifference.createAcceptorsDifference')
			)
		);
	}
	if (
		users &&
		workingGroups &&
		(next.data.submittersActiveGroup !== prev.data.submittersActiveGroup ||
			next.data.submitters?.length !== prev.data.submitters?.length ||
			(next.data.submitters?.length === prev.data.submitters?.length &&
				!isArrayEqual(next.data.submitters, prev.data.submitters)))
	) {
		data.push(
			createRespUsersDifference(
				prev.data.submittersActiveGroup,
				prev.data.submitters,
				next.data.submittersActiveGroup,
				next.data.submitters,
				users,
				workingGroups,
				t('createDifference.createSubmittersDifference')
			)
		);
	}
	if (
		next.data.attachments?.length !== prev.data.attachments?.length ||
		(next.data.attachments &&
			prev.data.attachments &&
			next.data.attachments.length === prev.data.attachments.length &&
			!isAttachmentsEqual(next.data.attachments, prev.data.attachments))
	) {
		data.push(createAttachmentsDifference(prev.data.attachments, next.data.attachments, t));
	}

	return {
		data,
		createdAt: next.createdAt,
		createdBy: next.createdBy && users ? users.byId[next.createdBy] : undefined
	};
}

export function useEnrichedHistories(
	objectId: string,
	workAcceptanceId: string
): IHistoryDifference[] {
	const {t} = useTranslation();
	const {data: histories} = useWorkAcceptanceStoriesAsArray(objectId, workAcceptanceId);
	const {data: units} = useUnits();
	const {data: contractors} = useCompaniesContractors();
	const {data: spacesMap} = useSpacesData(objectId);
	const {data: users} = useUsers();

	const {data: frontTypesMap} = useExtractWorkAcceptanceFrontTypesById();
	const {data: statuses} = useWorkAcceptanceStatuses();
	const translatedWorkAcceptanceStatuses = useTranslatedDictionary(
		dictionaryKeys.workAcceptanceStatuses,
		statuses
	);

	const {data: spaceTypes} = useSpaceTypes();

	const {data: categories} = useCategories();

	const {data: workingGroups} = useWorkingGroups();

	const {data: structuresMap} = useStructuresList(objectId);
	const {data: structureTypesMap} = useExtractStructureTypesById();
	const locale = useDateFnsLocale();

	return useMemo(() => {
		if (!structuresMap) return [];
		const result: IHistoryDifference[] = [];
		if (histories) {
			for (let i = 0; i < histories.length - 1; i++) {
				result.push(
					compare(
						histories[i + 1],
						histories[i],
						users,
						workingGroups,
						categories,
						translatedWorkAcceptanceStatuses,
						units,
						contractors,
						frontTypesMap,
						spacesMap,
						structuresMap,
						spaceTypes,
						structureTypesMap,
						t,
						locale
					)
				);
			}

			// добавляем запись о создании сущности
			if (histories[0]) {
				result.push({
					createdAt: histories[0].createdAt,
					createdBy:
						histories[0].createdBy && users
							? users.byId[histories[0].createdBy]
							: undefined
				});
			}
		}
		return result;
	}, [
		histories,
		users,
		workingGroups,
		categories,
		translatedWorkAcceptanceStatuses,
		units,
		contractors,
		frontTypesMap,
		spacesMap,
		structuresMap,
		spaceTypes,
		structureTypesMap
	]);
}
