import {Dispatch, useCallback} from 'react';
import * as React from 'react';
import {IEditableEntityAction} from '@tehzor/tools/core/states/editableEntityState';
import {
	EditableFieldLabel,
	Select2,
	SelectPopup,
	SelectOption,
	TextFieldWithForwardedRef
} from '@tehzor/ui-components';

interface BooleanOption {
	key: string;
	value: boolean;
	label: string;
}

const defaultOptions: [BooleanOption, BooleanOption] = [
	{key: 'yes', value: true, label: 'Да'},
	{key: 'no', value: false, label: 'Нет'}
];

interface IBooleanSelectProps<S, E> {
	field?: keyof S;
	value?: boolean;
	options?: [BooleanOption, BooleanOption];
	className?: string;
	style?: React.CSSProperties;
	label?: string;
	editingDispatch: Dispatch<IEditableEntityAction<S, E>>;
	required?: boolean;
	disabled?: boolean;
	hasError?: boolean;
}

export const BooleanSelect = <S extends Record<string, boolean>, E>(
	props: IBooleanSelectProps<S, E>
) => {
	const {
		className,
		style,
		field = 'autoSelect',
		label,
		options = defaultOptions,
		value,
		editingDispatch,
		required,
		disabled,
		hasError
	} = props;
	const handleChange = useCallback(
		(v: string) => {
			editingDispatch({
				type: 'update',
				field,
				value: options.find(option => option.key === v)?.value
			});

			if (required) {
				editingDispatch({type: 'update-error', field});
			}
		},
		[required, options]
	);

	const stringValue = options.find(option => option.value === value)?.key;

	return (
		<div
			className={className}
			style={style}
		>
			<EditableFieldLabel>{label}</EditableFieldLabel>
			<SelectPopup
				noHeader
				trigger={
					<TextFieldWithForwardedRef
						elementType="div"
						value={options?.find(option => option.value === value)?.label}
						icon={<i className="tz-simple-arrow-20" />}
						error={required && hasError ? 'Выберите значение' : undefined}
						disabled={disabled}
					/>
				}
			>
				<Select2
					value={stringValue}
					onChange={handleChange}
				>
					{(options || defaultOptions).map(option => (
						<SelectOption
							key={option.key}
							itemKey={option.key}
							content={option.label}
						/>
					))}
				</Select2>
			</SelectPopup>
		</div>
	);
};
