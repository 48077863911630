import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractPresaleCheckVisibility} from '../../../../slice/pageSettings/selectors';
import {useCallback} from 'react';
import {IGetListProblemsResponse} from '@src/api/backend/problems';
import {extractPresaleCheckProblemsEntities} from '../selectors/extractPresaleCheckProblemsEntities';
import {usePresaleCheckProblems} from './usePresaleCheckProblems';

export const usePresaleCheckProblemsEntities = (objectId: string, presaleCheckId: string) => {
	const visibility = useAppSelector(extractPresaleCheckVisibility);
	const selector = useCallback(
		(data: IGetListProblemsResponse) => extractPresaleCheckProblemsEntities(data, visibility),
		[visibility]
	);
	return usePresaleCheckProblems(objectId, presaleCheckId, selector);
};
