import {ReactNode} from 'react';
import styles from './Schema.module.less';
import {Plate} from '../containers';

interface ISchemaProps {
	children: ReactNode;
}

export const Schema = ({children}: ISchemaProps) => (
	<Plate className={{content: styles.wrapper}} withoutPadding>
		{children}
	</Plate>
);
