import {CellProps} from 'react-table';
import {FixDateTag} from '@src/components/FixDateTag/FixDateTag';
import {compareFixDate} from '@tehzor/tools/utils/compareFixDate';
import {IProblem} from '@tehzor/tools/interfaces/problems/IProblem';
import {useDateFnsLocale} from '@src/core/hooks/translations/useDateFnsLocales';
import {format} from 'date-fns';
import {longDateTimeFormat} from '@tehzor/tools/utils/dateFormats';
import {CriticalMarker} from '@src/components/CriticalMarker';
import styles from '../../PresaleCheckEntitiesTable.module.less';

export const PlannedFixDateCell = ({row}: CellProps<{data: IProblem}>) => {
	const {plannedFixDate, actualFixDate, critical} = row.original.data;
	const dateFnsLocale = useDateFnsLocale();
	return (
		<div className={styles.plannedFixDate}>
			{plannedFixDate
				? format(plannedFixDate, longDateTimeFormat, {locale: dateFnsLocale})
				: null}
			{critical && <CriticalMarker />}
			{compareFixDate(actualFixDate, plannedFixDate) && (
				<FixDateTag
					className={styles.dateTag}
					plannedFixDate={plannedFixDate!}
					actualFixDate={actualFixDate}
				/>
			)}
		</div>
	);
};
