import {queryClient} from '@src/api/QueryClient';
import {IGetCategoriesResponse, requestAddCategories} from '@src/api/backend/categories';
import {categoriesQueryKeys} from '@src/api/cache/categories/keys';
import {useMutation} from '@tanstack/react-query';
import IError from '@tehzor/tools/interfaces/IError';
import ICategory from '@tehzor/tools/interfaces/categories/ICategory';
import ISavingCategory from '@tehzor/tools/interfaces/categories/ISavingCategory';
import {addErrorToast, addSuccessToast} from '@tehzor/ui-components';
import {useTranslation} from 'react-i18next';

export const useAddCategory = () => {
	const {t} = useTranslation();
	return useMutation<ICategory, IError, ISavingCategory>({
		mutationFn: (params: ISavingCategory) => requestAddCategories(params),

		onSuccess: data => {
			queryClient.setQueryData<IGetCategoriesResponse>(
				categoriesQueryKeys.list(),
				oldData => {
					if (oldData) {
						return {
							byId: {...oldData.byId, [data.id]: data},
							allIds: [...oldData.allIds, data.id]
						};
					}
					return {
						byId: {[data.id]: data},
						allIds: [data.id]
					};
				}
			);
			addSuccessToast({title: t('toast.addCategory.success.title')});
		},
		onError: () => {
			addErrorToast({title: t('toast.addCategory.error.title')});
		}
	});
};
