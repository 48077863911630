import {
	IEditableEntityAction,
	IEditableEntityState,
	isEntityEdited,
	isPropEdited
} from '@tehzor/tools/core/states/editableEntityState';
import {ICustomField, IEntityType, IValueType} from '@tehzor/tools/interfaces/fields';
import {ISavingCustomField} from '@tehzor/tools/interfaces/fields/ISavingCustomFieldt';

export type IEditableCustomFieldState = IEditableEntityState<{
	companyId?: string;
	key?: string;
	name?: string;
	entityType?: IEntityType;
	valueType?: IValueType;
	values?: string[];
	defaultValue?: string;
	canBeCopied?: boolean;
	icon?: string;
}>;

export type IEditableCustomFieldAction = IEditableEntityAction<
	IEditableCustomFieldState,
	ISavingCustomField
>;

const makeEmptyState = (): IEditableCustomFieldState => ({
	companyId: undefined,
	key: undefined,
	name: undefined,
	entityType: undefined,
	valueType: undefined,
	values: undefined,
	defaultValue: undefined,
	canBeCopied: undefined,
	icon: undefined,
	errors: {
		companyId: false,
		key: false,
		name: false,
		entityType: false,
		valueType: false,
		values: false,
		defaultValue: false,
		canBeCopied: false,
		icon: false
	}
});

export const init = (field?: ICustomField): IEditableCustomFieldState => {
	const empty = makeEmptyState();

	return field
		? {
				companyId: field.companyId,
				key: field.key,
				name: field.name,
				entityType: field.entityType,
				valueType: field.valueType,
				values: field.values,
				defaultValue: field.defaultValue,
				canBeCopied: field.canBeCopied,
				icon: field.icon,
				errors: empty.errors
		  }
		: empty;
};

export const isEdited = (state: IEditableCustomFieldState, original?: ICustomField): boolean =>
	isEntityEdited(
		state,
		original,
		isPropEdited.bind(null, 'companyId'),
		isPropEdited.bind(null, 'key'),
		isPropEdited.bind(null, 'name'),
		isPropEdited.bind(null, 'entityTypeId'),
		isPropEdited.bind(null, 'valueTypeId'),
		isPropEdited.bind(null, 'values'),
		isPropEdited.bind(null, 'defaultValue'),
		isPropEdited.bind(null, 'canBeCopied'),
		isPropEdited.bind(null, 'icon')
	);

export const errorsFns = {
	companyId: (state: IEditableCustomFieldState) => !state.companyId,
	key: (state: IEditableCustomFieldState) => !state.key,
	name: (state: IEditableCustomFieldState) => !state.name,
	entityTypeId: (state: IEditableCustomFieldState) => !state.entityType,
	valueTypeId: (state: IEditableCustomFieldState) => !state.valueType,
	values: (state: IEditableCustomFieldState) => !state.values,
	defaultValue: (state: IEditableCustomFieldState) => !state.defaultValue,
	canBeCopied: (state: IEditableCustomFieldState) => !state.canBeCopied,
	icon: (state: IEditableCustomFieldState) => !state.icon
};

export const convertCustomFieldToSave = (
	state: IEditableCustomFieldState,
	original?: ICustomField
) => {
	const customField: ISavingCustomField = {};

	if (isPropEdited('companyId', state, original)) {
		customField.companyId = state.companyId;
	}

	if (isPropEdited('key', state, original)) {
		customField.key = state.key;
	}

	if (isPropEdited('name', state, original)) {
		customField.name = state.name;
	}

	if (isPropEdited('entityType', state, original)) {
		customField.entityTypeId = state.entityType?.id;
	}

	if (isPropEdited('valueType', state, original)) {
		customField.valueTypeId = state.valueType?.id;
	}

	if (isPropEdited('values', state, original)) {
		customField.values = state.values;
	}

	if (isPropEdited('defaultValue', state, original)) {
		customField.defaultValue = state.defaultValue;
	}

	if (isPropEdited('canBeCopied', state, original)) {
		customField.canBeCopied = state.canBeCopied;
	}

	if (isPropEdited('icon', state, original)) {
		customField.icon = state.icon;
	}

	return customField;
};
