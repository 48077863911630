import {makeInternalAcceptancesExportRequest} from '@src/api/backend/internalAcceptances';
import {addExportToast} from '@/shared/lib/Toasts/ExportToast/addExportToast';
import {useMutation} from '@tanstack/react-query';
import {useCallback} from 'react';
import {addErrorToast, addWarningToast} from '@tehzor/ui-components';
import {internalAcceptancesQueryKeys} from '@src/api/cache/internalAcceptances/keys';
import {useTranslation} from 'react-i18next';

interface IExportInternalAcceptancesParams {
	templateId: string;
	createDocument: boolean | undefined;
	email: string | undefined;
	objectId?: string;
	filters?: Record<string, unknown>;
	sort?: Record<string, boolean>;
	selected?: string[];
}

export const useExportInternalAcceptances = () => {
	const {t} = useTranslation();

	const mutation = useMutation({
		mutationKey: internalAcceptancesQueryKeys.export(),
		mutationFn: (params: IExportInternalAcceptancesParams) => {
			const {sort, email, objectId, selected, templateId, filters, createDocument} = params;
			return makeInternalAcceptancesExportRequest(
				templateId,
				email,
				objectId,
				filters,
				sort,
				selected,
				createDocument
			);
		},
		onSuccess: (data, params) => {
			if (data.warnings?.itemsLimit !== undefined) {
				addWarningToast({
					title: `${t('toast.export.warning.title')} (${String(
						data.warnings.itemsLimit
					)})`
				});
			}
			if (data.link) {
				addExportToast({
					title: params.email
						? t('toast.export.send.success.title')
						: t('toast.export.success.title'),
					url: data.link,
					fileName: data.file.name,
					document: data.document
				});
			}
		},
		onError: () => {
			addErrorToast({title: t('toast.export.error.title')});
		}
	});

	/**
	 * Возвращает функцию для экспорта списка внутренних приемок
	 *
	 * @param templateId id шаблона экспорта
	 * @param createDocument флаг создания документа
	 * @param email email для отправки
	 * @param objectId id объекта
	 * @param filters фильтры
	 * @param sort сортировка
	 * @param selected выбранные элементы
	 */

	return useCallback(
		(
			templateId: string,
			email: string | undefined,
			createDocument: boolean | undefined,
			objectId?: string,
			filters?: Record<string, unknown>,
			sort?: Record<string, boolean>,
			selected?: string[]
		) => {
			mutation.mutate({
				templateId,
				email,
				createDocument,
				objectId,
				filters,
				sort,
				selected
			});
		},
		[mutation]
	);
};
