import {createSelector} from 'reselect';
import {IState} from '@src/store/modules';

/**
 * Возвращает фильтр отображения сущностей помещения
 */
export const extractSpaceEntitiesVisibility = createSelector(
	(state: IState) => state.settings.pages.space.entitiesVisibility,
	data => data
);

/**
 * Возвращает вкладку отображения сущностей помещения
 */
export const extractSpaceTabs = createSelector(
	(state: IState) => state.settings.pages.space.entitiesTab,
	data => data
);
