import IPlan from '@tehzor/tools/interfaces/plans/IPlan';
import {useCallback, useEffect, useMemo, useState} from 'react';
import ActionButtons from '../../buttons/ActionButtons';
import Button from '../../buttons/Button';
import {Select2, SelectOption} from '../../inputs';
import useToggle from 'react-use/lib/useToggle';
import SelectSearch, {flatFilter} from '../../inputs/select/SelectSearch';
import {ModalDialog} from '../../dialogs/ModalDialog';

interface Props {
	plans: IPlan[];
	acceptBtnLabel?: string;
	closeBtnLabel?: string;
	title?: string;
	searchPlaceholder?: string;
	onPlanChange: (value: string) => void;
}

export const PlanChooseDialog = ({
	onPlanChange,
	plans,
	acceptBtnLabel,
	closeBtnLabel,
	title,
	searchPlaceholder
}: Props) => {
	const [planId, setPlanId] = useState<string | undefined>();
	const [isOpen, toggleOpen] = useToggle(false);
	const [search, setSearch] = useState<string>('');

	const filteredData = useMemo(() => flatFilter(plans, 'name', search), [plans, search]);

	useEffect(() => {
		toggleOpen(true);
	}, []);

	const handleSave = useCallback(() => {
		if (planId) {
			toggleOpen(false);
			setTimeout(() => onPlanChange(planId), 250);
		}
	}, [planId, onPlanChange]);

	return (
		<ModalDialog
			dataTestId="PlanChooseDialog"
			className={{body: 'loc-select__plan-choose-body'}}
			title={title || 'Выберите план:'}
			footer={
				<ActionButtons>
					<Button
						type="common"
						label={closeBtnLabel || 'Закрыть'}
						onClick={toggleOpen}
					/>
					<Button
						type="accent-blue"
						label={acceptBtnLabel || 'Применить'}
						onClick={handleSave}
					/>
				</ActionButtons>
			}
			open={isOpen}
			hideCloseButton
			useContentOpenAnimation
			useContentCloseAnimation
			onRequestClose={toggleOpen}
		>
			<Select2
				value={planId}
				onChange={setPlanId}
			>
				<SelectSearch
					value={search}
					placeholder={searchPlaceholder}
					onChange={setSearch}
				/>
				{filteredData.map(item => (
					<SelectOption
						dataTestId="MenuItem"
						key={item.id}
						itemKey={item.id}
						inputType="radio"
						content={item.name}
					/>
				))}
			</Select2>
		</ModalDialog>
	);
};
