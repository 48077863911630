import {IconMenu, MenuItem} from '@tehzor/ui-components';
import {memo, useCallback} from 'react';
import {hasPermission} from '@tehzor/tools/utils/findPermission';
import {useEditableMeterDialog} from '@src/components/EditableMeterDialog/hooks/useEditableMeterDialog';
import {IMeter} from '@tehzor/tools/interfaces/meters/IMeter';
import {useAddingMeterConsumptionDialog} from '@src/components/AddingMeterConsumptionDialog/hooks/useAddingMeterConsumptionDialog';
import {useDeleteMeter} from '@src/core/hooks/mutations/meters/useDeleteMeter';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractUserRoles} from '@src/store/modules/auth/profile/selectors';
import {useMeterConsumptionsAsArrayForMeter} from '@src/core/hooks/queries/meterConsumptions/hooks';
import {useTranslation} from 'react-i18next';
import {useTranslatedConfirmDialog} from '@src/core/hooks/translations/useTranslatedConfirmDialog';

interface IMeterActionsProps {
	meter: IMeter;
}

const plusIcon = <i className="tz-plus-20" />;
const editIcon = <i className="tz-edit-20" />;
const deleteIcon = <i className="tz-delete" />;

export const MeterActions = memo(({meter}: IMeterActionsProps) => {
	const {t} = useTranslation();
	const roles = useAppSelector(extractUserRoles);
	const permissions = {
		canAddMeterConsumption: hasPermission(roles, 'meter-consumptions-add'),
		canEditMeter: hasPermission(roles, 'meters-edit'),
		canDeleteMeter: hasPermission(roles, 'meters-delete')
	};

	const menuItems = [];

	const [editDialog, openEditDialog] = useEditableMeterDialog();
	const [addingMeterConsumptionDialog, openAddingMeterConsumptionDialog] =
		useAddingMeterConsumptionDialog(meter.companyId, meter.id);

	const deleteMeter = useDeleteMeter();

	const [deletingDialog, getDeletingConfirmation] = useTranslatedConfirmDialog({
		title: t('spacePage.actions.meterActions.useConfirmDialog.title'),
		message: t('spacePage.actions.meterActions.useConfirmDialog.message')
	});

	const handleDelete = useCallback(async () => {
		if (!meter) {
			return;
		}
		if (await getDeletingConfirmation()) {
			deleteMeter({id: meter.id});
		}
	}, [meter]);

	const {data: meterConsumptions} = useMeterConsumptionsAsArrayForMeter(meter.id);

	const handleOpenEditMeterDialog = () => {
		openEditDialog(meter, meterConsumptions);
	};

	const handleOpenAddingMeterConsumptionDialog = () => {
		openAddingMeterConsumptionDialog();
	};

	if (permissions.canAddMeterConsumption) {
		menuItems.push(
			<MenuItem
				icon={plusIcon}
				onClick={handleOpenAddingMeterConsumptionDialog}
				key={`add-consumption-${meter.id}`}
			>
				{t('spacePage.actions.meterActions.menuItem.add')}
			</MenuItem>
		);
	}
	if (permissions.canEditMeter) {
		menuItems.push(
			<MenuItem
				icon={editIcon}
				onClick={handleOpenEditMeterDialog}
				key={`edit-${meter.id}`}
			>
				{t('spacePage.actions.meterActions.menuItem.edit')}
			</MenuItem>
		);
	}
	if (permissions.canDeleteMeter) {
		menuItems.push(
			<MenuItem
				icon={deleteIcon}
				onClick={handleDelete}
				key={`delete-${meter.id}`}
			>
				{t('spacePage.actions.meterActions.menuItem.delete')}
			</MenuItem>
		);
	}

	return menuItems.length ? (
		<div>
			<IconMenu>{menuItems}</IconMenu>

			{permissions.canAddMeterConsumption && addingMeterConsumptionDialog}
			{permissions.canEditMeter && editDialog}
			{permissions.canDeleteMeter && deletingDialog}
		</div>
	) : null;
});
