import {TFunction} from 'i18next';

export function makeCommentTitle(
	comments: boolean | undefined,
	replies: boolean | undefined,
	t: TFunction
) {
	if (comments && replies) {
		return t('commentTitle.replyAndComments');
	}
	if (comments) {
		return t('commentTitle.comments');
	}
	if (replies) {
		return t('commentTitle.reply');
	}
	return undefined;
}
