import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractOfflineModeAvailability} from '@src/store/modules/settings/offlineMode/selectors/availability';
import {useQuery} from '@tanstack/react-query';
import IError from '@tehzor/tools/interfaces/IError';
import {IPresaleCheck} from '@tehzor/tools/interfaces/presaleChecks/IPresaleCheck';
import {presaleChecksQueryKeys} from '../../../keys';

export const useLocalPresaleChecks = <T = IPresaleCheck[]>(
	objectId = 'all',
	select?: (data: IPresaleCheck[]) => T
) => {
	const isOfflineModeAvailable = useAppSelector(extractOfflineModeAvailability);
	return useQuery<IPresaleCheck[], IError, T>({
		queryKey: [...presaleChecksQueryKeys.localList(), objectId],
		staleTime: 0,
		gcTime: Infinity,
		networkMode: 'offlineFirst',
		enabled: isOfflineModeAvailable,
		select
	});
};
