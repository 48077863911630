import {spacesQueryKeys} from '@src/api/cache/spaces/keys';
import {useQuery} from '@tanstack/react-query';
import {IGetSpacePresaleChecksResponse} from '../interfaces';
import {extractPresaleCheckAllIds, extractPresaleCheckAsArray} from './selectors';

export const useSpacePresaleChecks = <T = IGetSpacePresaleChecksResponse>(
	objectId: string,
	spaceId: string,
	permission = true,
	select?: (data: IGetSpacePresaleChecksResponse) => T
) =>
	useQuery({
		queryKey: spacesQueryKeys.spacePresaleChecks(objectId, spaceId),
		meta: {
			error: 'при загрузке предпродажных проверок'
		},
		select,
		enabled: permission
	});

export const useSpacePresaleChecksAsArray = (
	objectId: string,
	spaceId: string,
	permission = true
) => useSpacePresaleChecks(objectId, spaceId, permission, extractPresaleCheckAsArray);

export const useExtractSpacePresaleChecksAllIds = (
	objectId: string,
	spaceId: string,
	permission = true
) => useSpacePresaleChecks(objectId, spaceId, permission, extractPresaleCheckAllIds);
