import {useCallback, useMemo} from 'react';
import './VisibilityFilter.less';
import {FilterMenu, MenuItem, MultipleMenu} from '@tehzor/ui-components';
import {AnyAction} from 'redux';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import classNames from 'classnames';
import {useTranslation} from 'react-i18next';

const sortFn = (a: string, b: string) => (a > b ? -1 : 1);

interface IVisibilityFilterProps<T extends string> {
	className?: string;
	labelValuesClassName?: string;
	style?: React.CSSProperties;
	value: T[];
	titlesMap: Record<T, string>;

	onChange: (keys: T[]) => AnyAction;
	isMobileLabel?: boolean;
}

const VisibilityFilter = <T extends string>(props: IVisibilityFilterProps<T>) => {
	const {className, labelValuesClassName, style, value, titlesMap, onChange, isMobileLabel} =
		props;
	const {t} = useTranslation();
	const dispatch = useAppDispatch();

	const label = useMemo(() => {
		if (value.length) {
			const formattedValues = value.map(key => titlesMap[key]).join(', ');

			if (isMobileLabel) {
				return `${formattedValues.charAt(0).toUpperCase()}${formattedValues.slice(1)}`;
			}

			return (
				<>
					{t('spacePage.visibilityFilter.label')}:&nbsp;
					<span className={labelValuesClassName}>{formattedValues}</span>
				</>
			);
		}

		return t('spacePage.visibilityFilter.label.placeholder');
	}, [value, t, isMobileLabel, labelValuesClassName, titlesMap]);

	const handleChange = useCallback((v: T[]) => {
		dispatch(onChange(v.sort(sortFn)));
	}, []);

	return (
		<FilterMenu
			className={{
				root: classNames('visibility-filter', className),
				label: 'visibility-filter__label'
			}}
			style={style}
			label={label}
			active={value.length > 0}
		>
			<MultipleMenu
				value={value}
				onChange={handleChange}
			>
				{Object.entries<string>(titlesMap).map(([key, title]) => (
					<MenuItem
						key={key}
						itemKey={key}
					>
						{title}
					</MenuItem>
				))}
			</MultipleMenu>
		</FilterMenu>
	);
};

export default VisibilityFilter;
