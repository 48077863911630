import {RouteObject} from 'react-router-dom';
import {PrivateRoute} from '@src/containers/PrivateRoute';
import {FieldSettingsSetsPage} from '@/pages/FieldsSettingsSetsPage';
import {CustomFieldsPage} from '@/pages/CustomFiledsPage';
import {BuiltInFieldsPage} from '@/pages/BuiltInFiledsPage';

export const fieldsPagesRoutes: RouteObject[] = [
	{
		index: true,
		element: <PrivateRoute element={<FieldSettingsSetsPage />} />
	},
	{
		path: 'custom-fields',
		element: <PrivateRoute element={<CustomFieldsPage />} />
	},
	{
		path: 'builtin-fields',
		element: <PrivateRoute element={<BuiltInFieldsPage />} />
	}
];
