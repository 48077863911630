import {useIsDesktop} from '@tehzor/ui-components/src/utils/mediaQueries';
import {useAppHeader} from '@src/components/AppHeader/hooks/useAppHeader';
import AppUpdateMenu from '@src/components/AppUpdateData/AppUpdateMenu';
import {TasksPageControlBar} from '@src/pages/TasksPage/components/TasksPageControlBar';
import {TasksPageControlBarMobile} from '@src/pages/TasksPage/components/TasksPageControlBarMobile';
import {TasksTable} from '@src/pages/TasksPage/components/tabs/TasksTablePage/components';
import {TasksFilters} from '@src/pages/TasksPage/components/TasksFilters';
import {FiltersContextWrap} from '@src/pages/TasksPage/components/FiltersContextWrap';
import {useTranslation} from 'react-i18next';

export const TasksTablePage = () => {
	const {t} = useTranslation();
	useAppHeader({
		title: t('tasksPage.title'),
		mobileRightButtons: <AppUpdateMenu />
	});

	const isDesktop = useIsDesktop();

	return (
		<FiltersContextWrap>
			{isDesktop ? (
				<>
					<TasksPageControlBar />
					<TasksFilters />
				</>
			) : (
				<TasksPageControlBarMobile />
			)}
			<TasksTable />
		</FiltersContextWrap>
	);
};
