import {MobileDropDownMenu} from '@/shared/ui/MobileDropDownMenu/MobileDropDownMenu';
import {useActiveSchemaStructuresType} from '@/widgets/StructuresSchema/model/hooks/useActiveSchemaStructuresType';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {structuresActions} from '@/entities/Structures';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {useStructuresSchemaTypes} from '@src/core/hooks/queries/structureTypes/hooks';
import {memo, useCallback, useMemo} from 'react';
import {extractStructuresSchemaPageSettings} from '@/entities/Structures/model/slice/selectors';
import {getFilteredByObjectsTargetObjectIds} from '@/widgets/StructuresSchema/model/utils/getFilteredByObjectsTargetObjectIds';
import {useTargetObjectIdsHaveStructures} from '@src/core/hooks/queries/objects/hooks';

interface ISchemaStructuresTypesMenuProps {
	objectId: string;
}

export const SchemaStructuresTypesMenu = memo(({objectId}: ISchemaStructuresTypesMenuProps) => {
	const allTargetObjects = useTargetObjectIdsHaveStructures({
		objectId,
		includingParentObjectId: true
	});
	const isSingleSchema = allTargetObjects.length === 1;

	useActiveSchemaStructuresType(objectId, allTargetObjects, isSingleSchema);
	const dispatch = useAppDispatch();
	const {changeSchemaStructureType} = structuresActions;
	const {schemaStructureTypeId, filters} = useAppSelector(state =>
		extractStructuresSchemaPageSettings(state, objectId)
	);

	const filteredObjects = useMemo(
		() => getFilteredByObjectsTargetObjectIds(allTargetObjects, filters),
		[allTargetObjects, filters]
	);

	const modifiedIds = filteredObjects ?? allTargetObjects;

	const {data: schemaStructuresTypes, isLoading} = useStructuresSchemaTypes(
		objectId,
		modifiedIds
	);

	const preparedSchemaStructuresTypes = useMemo(
		() =>
			schemaStructuresTypes?.map(type => ({
				...type,
				name: `${type.name} (${type.structuresQuantity})`
			})),
		[schemaStructuresTypes]
	);

	const changeViewType = useCallback(
		(value: string) => {
			dispatch(
				changeSchemaStructureType({
					structureTypeId: value,
					objectIds: allTargetObjects
				})
			);
		},
		[dispatch, changeSchemaStructureType, allTargetObjects]
	);

	const label = preparedSchemaStructuresTypes?.find(({id}) => id === schemaStructureTypeId)?.name;

	return (
		<MobileDropDownMenu
			value={schemaStructureTypeId}
			label={label}
			onChange={changeViewType}
			items={preparedSchemaStructuresTypes}
			isLoading={isLoading}
		/>
	);
});
