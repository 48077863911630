import {requestListInspections} from '@src/api/backend/inspections';
import {createApiAction} from '@src/store/middlewares/api';
import IError from '@tehzor/tools/interfaces/IError';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import IListInspection from '@tehzor/tools/interfaces/inspections/IListInspection';
import * as types from '../../constants';
import {addErrorToast} from '@tehzor/ui-components';
import {t} from 'i18next';

export type IGetWorkAcceptanceInspectionsPayload = INormalizedData<IListInspection>;

const request = () => ({type: types.GET_INSPECTIONS_REQUEST});

const success = (response: IGetWorkAcceptanceInspectionsPayload) => ({
	type: types.GET_INSPECTIONS_SUCCESS,
	payload: response
});

const failure = (error: IError) => {
	addErrorToast({title: t('toast.getWorkAcceptanceInspections.error.title')});
	return {
		type: types.GET_INSPECTIONS_FAILURE,
		payload: error
	};
};

/**
 * Получает список осмотров для конкретной приёмки работ
 *
 * @param objectId id объекта
 * @param workAcceptanceId id приёмки работ
 */
export const getWorkAcceptanceInspections = (objectId: string, workAcceptanceId: string) =>
	createApiAction<IGetWorkAcceptanceInspectionsPayload>(request, success, failure, () =>
		requestListInspections(
			{
				objects: [objectId],
				workAcceptanceIds: [workAcceptanceId]
			},
			{createdAt: false}
		).then(({byId, allIds}) => ({
			byId,
			allIds
		}))
	);
